import { autorun, reaction } from 'mobx'
import { appStore } from 'src/store'
import CEFTransfer from 'src/CEFTransfer'

//操作系统阈值
const SYSTEM_THRESHOLD = 15
//cpu阈值
const CPU_THRESHOLD = 70
//内存阈值
const MEMORY_THRESHOLD = 2

export const checkHasErr = (index: number) => {
  const value = appStore.gameEnvStatus[index]
  if (typeof value === 'undefined') {
    return false
  }
  switch (index) {
    case 0:
      //操作系统低于win10
      return Number(value) < SYSTEM_THRESHOLD
    case 1:
      //是否wifi
      return Number(value) === 1
    case 2:
      //cpu大于70%
      return Math.floor(Number(value)) > CPU_THRESHOLD
    case 3:
      //可用内存小于2G
      return Math.floor(Number(value) * 100) / 100 < MEMORY_THRESHOLD
  }
}

const checkLoginGameEnv = () => {
  console.log('checkEnv 开始检测游戏环境 new')
  CEFTransfer.sendToClient('checkLoginGameEnv')
  // window.FuluApp!.JSNotifyCppFunc('check_login_game_env')
}

class GameStartStep {
  readonly allSteps = 4 //步骤总数
  readonly during = 500 //进度动画间隔
  readonly waitTimeout = 5000 //环境检测等待时间,客户端采样需要4s，比客户端采样时间多一点点
  readonly stepMinTime = [this.waitTimeout, 10000, 2000, 8000] //预计每步持续时间，用来计算动画幅度
  readonly autoContinueTimeout = 15 //环境检测异常后，自动继续游戏倒计时时间，单位s
  status: 'stop' | 'running' = 'stop' //当前进度条状态
  timer = 0 //进度条动画定时器
  waitTimer = 0 //环境检测等待定时器
  autoContinueTimer = 0 //环境检测异常，超时后自动下一步的定时器
  autoNextStepTimer = 0 //自动完整第3步定时器
  checkEnvPromise = (v: any) => {}

  constructor() {
    //监听AppStore.CheckModalVisible，重置状态
    reaction(
      () => appStore.CheckModalVisible,
      visible => {
        if (!visible) {
          this.reset()
        }
      },
    )
  }

  //当前第几步
  get currentStep() {
    return appStore.gameStartStep
  }
  set currentStep(value: number) {
    appStore.gameStartStep = value
  }

  //当前进度
  get progress() {
    return appStore.gameStartProgress
  }
  set progress(value: number) {
    appStore.gameStartProgress = value
  }

  //检测环境是否有异常
  get hasEnvError() {
    return appStore.gameStartHasEnvError
  }
  set hasEnvError(value: boolean) {
    appStore.gameStartHasEnvError = value
  }

  //检测环境是否有异常
  get autoContinueCountdown() {
    return appStore.autoContinueCountdown
  }
  set autoContinueCountdown(value: number) {
    appStore.autoContinueCountdown = value
  }

  //检测环境
  async checkEnv() {
    this.hasEnvError = false
    this.autoContinueCountdown = this.autoContinueTimeout
    //清空之前检测的数据
    appStore.gameEnvStatus = {}

    return new Promise(resolve => {
      // console.log('checkEnv 检测项全部通过，进入下一步')
      // resolve('success')
      // setTimeout(() => {
      //   this.setStep(2)
      // }, 500)
      // return
      /** 试玩暂时不需要环境检测，此处return，保留代码*/

      //有异常后的倒计时
      const onErrCountdown = () => {
        console.log('checkEnv 检测到异常，15s后自动下一步')
        //开启15s自动进入下一步的定时器
        this.autoContinueTimer = window.setInterval(() => {
          if (this.autoContinueCountdown <= 1) {
            console.log('checkEnv 异常，超时15s自动下一步')
            clearInterval(this.autoContinueTimer)
            resolve('auto')
            this.setStep(2)
            return
          }
          this.autoContinueCountdown--
        }, 1000)
      }

      this.checkEnvPromise = resolve
      //请求检测环境
      checkLoginGameEnv()
      //监听检测结果
      const disposer = autorun(() => {
        const keys = Object.keys(appStore.gameEnvStatus)
        this.progress = Math.max(this.progress, keys.length * (100 / 4 / 4))
        //4项检测都完成后才判断是否有异常
        if (keys.length < 4) {
          return
        }
        console.log('checkEnv 环境检测已完成')
        disposer()
        //清除5s自动进入下一步的定时器
        this.waitTimer && clearTimeout(this.waitTimer)
        this.hasEnvError = !!keys.find(value => checkHasErr(Number(value)))
        //是否有异常项，有异常项，15s后自动下一步
        if (this.hasEnvError) {
          onErrCountdown()
        } else {
          console.log('checkEnv 检测项全部通过，进入下一步')
          resolve('success')
          setTimeout(() => {
            this.setStep(2)
          }, 500)
        }
      })
      this.waitTimer = window.setTimeout(() => {
        console.log('checkEnv 环境检测等待5s超时')
        disposer()
        const keys = Object.keys(appStore.gameEnvStatus)
        this.hasEnvError = !!keys.find(value => checkHasErr(Number(value)))
        //等待5s,判断有没有异常，没有就进入下一步
        if (this.hasEnvError) {
          console.log('checkEnv 5s超时，有异常')
          appStore.gameEnvStatus = {
            '0': SYSTEM_THRESHOLD,
            '1': 0,
            '2': CPU_THRESHOLD,
            '3': MEMORY_THRESHOLD,
            ...appStore.gameEnvStatus,
          }
          onErrCountdown()
        } else {
          console.log('checkEnv 5s没有异常，自动下一步')
          appStore.gameEnvStatus = {
            '0': SYSTEM_THRESHOLD,
            '1': 0,
            '2': CPU_THRESHOLD,
            '3': MEMORY_THRESHOLD,
          }
          resolve('timeout')
          setTimeout(() => {
            this.setStep(2)
          }, 500)
        }
      }, this.waitTimeout)
    })
  }

  checkEnvStop() {
    this.checkEnvPromise('stop')
  }

  checkEnvNext() {
    console.log('checkEnv 手动点击继续下一步')
    clearInterval(this.autoContinueTimer)
    this.checkEnvPromise('click')
    this.setStep(2)
  }

  //开始进度条
  async start() {
    this.reset()
    this.status = 'running'
    this.setStep(1)
  }

  //进度条动画
  doAnimation(max: number) {
    this.timer && clearTimeout(this.timer)
    if (appStore.gameStartProgress >= max) {
      clearTimeout(this.timer)
      return
    }
    appStore.gameStartProgress =
      appStore.gameStartProgress +
      Math.random() * (100 / this.allSteps / (this.stepMinTime[this.currentStep - 1] / this.during))
    this.timer = window.setTimeout(() => {
      this.doAnimation(max)
    }, this.during)
  }

  //设置当前步骤
  setStep(step: number) {
    if (this.status === 'stop' || step <= this.currentStep) {
      return
    }
    if (step > this.allSteps) {
      this.progress = 100
      return
    }
    console.log('checkEnv setStep', step)
    const stepValue = 100 / this.allSteps
    const min = (step - 1) * stepValue
    //最后一步动画最多到96%
    const max = step === this.allSteps ? 96 : step * stepValue
    this.currentStep = step
    this.progress = Math.max(min, this.progress)
    this.doAnimation(max)
    //进入第2步，清除第1步环境检测异常标识
    if (step === 2 && this.hasEnvError) {
      this.hasEnvError = false
    }
    //第3步只持续2s
    if (step === 3) {
      this.autoNextStepTimer && clearTimeout(this.autoNextStepTimer)
      this.autoNextStepTimer = window.setTimeout(() => {
        console.log('checkEnv 第3步持续2s后自动下一步')
        this.setStep(4)
      }, 2000)
    }
  }

  //重置到初始状态
  reset() {
    this.status = 'stop'
    this.currentStep = 0
    this.progress = 0
    this.hasEnvError = false
    this.checkEnvPromise = (v: any) => {}
    this.timer && clearTimeout(this.timer)
    this.waitTimer && clearTimeout(this.waitTimer)
    this.autoContinueTimer && clearInterval(this.autoContinueTimer)
    this.autoNextStepTimer && clearTimeout(this.autoNextStepTimer)
    this.checkEnvPromise('stop')
  }
}

export const gameStartStep = new GameStartStep()
