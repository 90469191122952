import React, { FC, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { GameStore, ConfigStore } from 'src/store'
import { FadeinImage } from 'src/components/base-image'
import { analysisSupportTypeByGameLibraryMaster, analysisSupportTypeByGamePlatform } from 'src/tools/common'
import LeftArrowsIcon from 'src/assets/icons/home/left-arrows.svg'
import RightArrowsIcon from 'src/assets/icons/home/right-arrows.svg'
import styled from 'styled-components'
import classNames from 'classnames'
import tipsImg from 'src/assets/images/home-new/tips.png'
import rightArrowImg from 'src/assets/images/home-new/right-arrow.png'
import supportImg from 'src/assets/images/home-new/support.png'
import notSupportImg from 'src/assets/images/home-new/not-support.png'

const CONTAINER_PADDING = 0
const ITEM_MARGIN_RIGHT = 26

const FreePlayGame: FC = () => {
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [scrollOffset, setScrollOffset] = useState(0)
  const [scrollRightArrowIsDisabled, setScrollRightArrowIsDisabled] = useState(false)
  const [isViewScrollArrow, setIsViewScrollArrow] = useState(false)

  const jumpToDetail = (type: 'gameMaster' | 'platform', id: string | number, branchId?: string | number) => {
    if (type === 'gameMaster') {
      navigate(`/game-detail?type=game&gameLibraryBranchId=${id}&branchId=${branchId}&entry=trial`)
    } else {
      navigate(`/game-detail?type=platform&platformId=${id}`)
    }
  }

  const jumpToStore = () => {
    navigate('/main/game-search?supportTrial=1')
  }

  const computedLayout = () => {
    if (containerRef.current && scrollRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const scrollWidth = scrollRef.current.offsetWidth

      /** 滚动区域大于展示区域则展示滚动按钮 */
      const isScrollGTView = scrollWidth > containerWidth
      setIsViewScrollArrow(isScrollGTView)

      /** 展示区域加上偏移 大于 滚动区域， 则修改偏移为当前能偏移的最大值 */
      const scrollOffsetWithContainerWidthGTScrollWidth = scrollOffset + containerWidth > scrollWidth
      const scrollOffsetMaxValue = scrollWidth - containerWidth
      if (scrollOffsetWithContainerWidthGTScrollWidth) {
        setScrollOffset(value => Math.min(Math.max(0, value), scrollOffsetMaxValue))
      }
    }
  }

  const handleMouseWheelScroll = (event: WheelEvent) => {
    if (containerRef.current && scrollRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const scrollWidth = scrollRef.current.offsetWidth
      /** 根据滚轮 得出偏移 不小于0  不大于最高值 */
      const scrollOffsetMaxValue = scrollWidth - containerWidth

      setScrollOffset(value => {
        const currentValue = value + event.deltaY
        setScrollRightArrowIsDisabled(currentValue >= scrollOffsetMaxValue)
        return Math.min(Math.max(0, value + event.deltaY), scrollOffsetMaxValue)
      })
    }

    // 阻止默认的垂直滚动
    event.preventDefault()
  }

  const handleScrollArrowClick = (scrollValue: number) => {
    if (containerRef.current && scrollRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const currentValue = scrollOffset + scrollValue
      const scrollWidth = scrollRef.current.offsetWidth
      const scrollOffsetMaxValue = scrollWidth - containerWidth
      setScrollOffset(Math.min(Math.max(0, currentValue), scrollOffsetMaxValue))
      setScrollRightArrowIsDisabled(currentValue + scrollValue >= scrollOffsetMaxValue)
    }
  }

  useEffect(() => {
    computedLayout()
  }, [GameStore.freePlayGameList.length])

  useEffect(() => {
    computedLayout()
    window.addEventListener('resize', computedLayout)
    if (scrollRef.current) {
      scrollRef.current.addEventListener('wheel', handleMouseWheelScroll)
    }
    return () => {
      window.removeEventListener('resize', computedLayout)
      scrollRef.current?.removeEventListener('wheel', handleMouseWheelScroll)
    }
  }, [])

  if (GameStore.freePlayGameList.length === 0) {
    return null
  }

  return (
    <StyledFreePlayGameContainer ref={containerRef}>
      <div className='title'>
        可免费体验游戏推荐
        <Tooltip
          color={'rgb(45, 45, 45)'}
          overlayInnerStyle={{
            borderRadius: '4px',
            width: 300,
          }}
          overlay={
            <StyledFreePlayGameText>
              <div className='tips-title'>免费体验游戏说明</div>
              <div className='tips-desc'>
                免费体验游戏本质上是 MYBUFF 平台通过自动化工具分配给您一个拥有当前游戏的游戏运营平台账号
                并帮您自动化登录的过程，游戏的安装、下载、游戏 请在登录完成的游戏运营平台进行
              </div>
            </StyledFreePlayGameText>
          }
          placement='bottom'
        >
          <div style={{ marginLeft: 10 }}>
            <FadeinImage
              width={16}
              height={16.5}
              style={{ fontSize: 0, lineHeight: '16.5px', cursor: 'pointer' }}
              className='tips-img'
              type='notDefault'
              src={tipsImg}
            />
          </div>
        </Tooltip>
        <div className='extra' onClick={jumpToStore}>
          查看全部
          <FadeinImage
            width={16}
            height={16}
            style={{ fontSize: 0, lineHeight: '16.5px', cursor: 'pointer' }}
            type='notDefault'
            src={rightArrowImg}
          />
        </div>
      </div>
      <div className='content'>
        <StyledScrollBox
          ref={scrollRef}
          style={{
            transform: `translate(-${scrollOffset}px, -50%)`,
          }}
        >
          {GameStore.freePlayGameList.map(d => {
            return (
              <div
                className='scroll-item-container'
                key={`${d.gameLibraryMasterCnName}-${d.gameLibraryMasterId}`}
                onClick={() => jumpToDetail('gameMaster', d.gameLibraryMasterId, d.gameLibraryBrancheId)}
              >
                <FadeinImage
                  width={240}
                  height={112}
                  className='install-game-img'
                  type='notDefault'
                  src={d.gameLibraryMasterListImage}
                />
                <div className='scroll-item-text ellipsis'>
                  <FadeinImage
                    width={16}
                    height={16}
                    type='notDefault'
                    src={
                      d.gameLibraryBranches.find(f => f.gameLibraryBranchId === d.gameLibraryBrancheId)?.platformIcon
                    }
                  />
                  <span className='text'>{d.gameLibraryMasterCnName}</span>
                </div>
                <div className='scroll-item-desc'>{d.gameLibraryMasterEnName}</div>
              </div>
            )
          })}
        </StyledScrollBox>
        {isViewScrollArrow && (
          <>
            <div className='arrows left'>
              <LeftArrowsIcon
                className={classNames({ disabled: scrollOffset === 0 })}
                onClick={() => handleScrollArrowClick(-100)}
              />
            </div>
            <div className='arrows right'>
              <RightArrowsIcon
                className={classNames({ disabled: scrollRightArrowIsDisabled })}
                onClick={() => handleScrollArrowClick(100)}
              />
            </div>
          </>
        )}
      </div>
    </StyledFreePlayGameContainer>
  )
}

const StyledFreePlayGameText = styled.div`
  .tips-title {
    font-size: 16px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
  }
  .tips-desc {
    font-size: 14px;
    margin-top: 12px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: 400;
    color: #b5bac1;
    line-height: 22px;
  }
`

const StyledScrollBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  position: absolute;
  top: 50%;
  left: ${CONTAINER_PADDING}px;
  transition: transform 150ms ease 0s;
  padding: 0 16px;
  .scroll-item-container {
    width: 256px;
    height: 190px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 8px;
    padding: 8px;
    position: relative;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    .install-game-img {
      width: 240px;
      height: 112px;
      .true-img {
        border-radius: 4px;
      }
    }
    .scroll-item-text {
      ${props => props.theme.flexAlignCenter}
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: var(--text-color-FFFFFF);
      line-height: 14px;
      margin-top: 15px;
      .text {
        margin-left: 8px;
      }
    }
    .scroll-item-desc {
      margin-top: 8px;
      width: 240px;
      font-size: 12px;
      font-family: Noto Sans, Noto Sans;
      font-weight: normal;
      color: #b5bac1;
      line-height: 18px;
    }
    .scroll-item-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(23, 24, 25, 0.86);
      border-radius: 4px;
      opacity: 0;
      transition: opacity 150ms ease 0s;
      display: flex;
      flex-wrap: wrap;
      padding: 42px 21px;
      .scroll-item-support-item {
        display: flex;
        align-items: center;
        width: 50%;
        color: #ffffff;
        .scroll-item-support-text {
          font-size: 14px;
          font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
          font-weight: 400;
          line-height: 16px;
          margin-left: 11px;
        }
        &.isNotSupport {
          color: #96989d;
          text-decoration: line-through;
        }
      }
    }
    &:hover {
      .scroll-item-mask {
        opacity: 1;
      }
    }
  }
`

const StyledFreePlayGameContainer = styled.div`
  margin-top: 32px;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    line-height: 30px;
    margin-bottom: 13px;
    position: relative;
    .tips-img {
    }
    .extra {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      width: 94px;
      height: 32px;
      background: #232428;
      border-radius: 4px;
      padding: 0 8px;
      justify-content: space-between;
      font-size: 14px;
      font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
      font-weight: 400;
      color: #c38cff;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
  .content {
    height: 222px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background: #232428;
    .arrows {
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 10;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 150ms ease 0s;
      background: rgba(255, 255, 255, 0.12);
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
    svg {
      margin-right: 16px;
      color: rgba(255, 255, 255, 0.6);
      &:not(.disabled) {
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
      &.disabled {
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &:hover {
      .arrows {
        opacity: 1;
      }
    }
  }
`

export default observer(FreePlayGame)
