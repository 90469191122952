import React, { FC } from 'react'
import type { TableProps } from 'antd/lib/table'
import { Table } from 'antd'
import { observer } from 'mobx-react'
import { FadeinImage } from 'src/components/base-image'
import cdklistEmptyImg from 'src/assets/images/common/cdklist-empty.png'
import styled from 'styled-components'
import classNames from 'classnames'

export const SG_TABLE_STYLE = {
  SPEED_DETAIL_TABLE_HEADER_HEIGHT: 40,
  SPEED_DETAIL_TABLE_HEADER_MARGIN_BOTTOM: 16,
}

const SGTable: FC<TableProps<any> & {emptyHeiht?: number}> = ({ children, emptyHeiht = 400, ...props }) => {
  return <StyledTableContainer scroll={{ y: 400 }} emptyHeiht={emptyHeiht} {...props}>{children}</StyledTableContainer>
}

const StyledTableContainer = styled(Table)<{emptyHeiht: number}>`
  .ant-table {
    color: #fff;
    background: transparent;
    box-shadow: none;
    border-radius: 4px;
  }
  .ant-table-thead,
  .ant-table-header {
    height: ${SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_HEIGHT}px;
    margin-bottom: ${SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_MARGIN_BOTTOM}px;
  }
  .ant-table-body tr td,
  .ant-table-thead tr th {
    &:first-child {
      border-radius: 4px 0 0 4px !important;
    }
    &:last-child {
      border-radius: 0 4px 4px 0 !important;
    }
  }
  .ant-table-thead tr th {
    border: none;
  }
  tr.ant-table-placeholder td {
    border: none;
  }
  .ant-table-row.select-row {
    background: none;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: ${props => props.theme.color.text.$BBBBBB};
      background: ${props => props.theme.color.bg.$00000020};
    }
  }
  .ant-table-cell {
    color: ${props => props.theme.color.text.$F0F0F0};
    font-size: 13px;
    background-color: transparent;
    border-top: 1px solid ${props => props.theme.color.bor.$FFFFFF12};
    border-bottom: none;
    padding: 10px 10px;
    &.ant-table-cell-ellipsis {
      display: flex;
      align-items: center;
      span:nth-child(1) {
        padding-right: 10px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span:nth-child(1) {
        width: 60px;
      }
    }
    .speeding-tip {
      color: #1edf43;
      font-size: 12px;
    }
  }
  .ant-table-measure-row + .ant-table-row {
    .ant-table-cell {
      border: none;
    }
  }
  .ant-table-row:first {
    .ant-table-cell {
      border: none;
    }
  }
  .ant-table-row:not(.select-row):last-child {
    .ant-table-cell {
      border-bottom: 1px solid ${props => props.theme.color.bor.$FFFFFF12};
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: rgba(255, 255, 255, 0.08);
  }
  tr.ant-table-row.select-row {
  }
  tr.ant-table-row.select-row td.ant-table-cell {
    background: ${props => props.theme.color.bg.$864DFE24};
    border-color: transparent;
  }
  tr.ant-table-row.select-row + tr td {
    border-color: transparent;
  }
  .ant-table-cell.ant-table-cell-scrollbar {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: unset;
  }

  .ant-table-empty .ant-table-body {
    height: ${props => props.emptyHeiht}px;
  }
  /* .ant-table {
    background: transparent;
  }
  .ant-table-empty {
    td {
      border: none !important;
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-table-container table > tbody > tr:last-child td:first-child {

  }
  .ant-table-container table > tbody > tr:last-child td:last-child {
    
  }
  .ant-table-thead {
    .ant-table-cell {
      background: ${props => props.theme.color.bg.$FFFFFF12};
      font-size: 14px;
      color: #b9bdce;
      line-height: 14px;
      border-bottom: none;
      padding: 13px 16px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      background: transparent;
      font-size: 14px;
      color: #e2e2e2;
      line-height: 14px;
      border-top: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0px 16px;
      height: 48px;
    }
  }
  .ant-table-tbody > tr.ant-table-row {
    cursor: pointer;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgba(255, 255, 255, 0.03);
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: rgba(12, 13, 22, 0.4);
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
  }
  .ant-table-empty .ant-table-placeholder {
    height: 400px;
  } */
`

export default observer(SGTable)
