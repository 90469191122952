import { message } from 'antd'
import { CLOUD_GAME_EXIT_TYPE } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import { finalCloudGameStart, startGameHttp, stopCloudGame } from 'src/pages/game-detail/cloud-game/cloud-game-action'
import { appStore, CloudQueueStore, TrialQueueStore, VipStore } from 'src/store'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatTimeLocal } from 'src/tools/helper'
import {
  checkCloudArchiveTips,
  checkLocal,
  downloadArchive,
  gameStartingSuccess,
  getGamePlayStoreByGameMode,
  getQueueStoreByGameMode,
  HideCheckModal,
  showFeedback
} from 'src/tools/common'
import { IReactionDisposer, reaction } from 'mobx'
import { gameStartStep } from 'src/pages/game-detail/trial/game-start-step'
import { getProcessNameByOrder } from 'src/apis/game-api'
import { FadeinImage } from 'src/components/base-image'
import { useIntl } from 'react-intl'
import { $t } from 'src/locales'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import React from 'react'
import useQueue from 'src/hooks/use-queue'
import SocketStore from 'src/pages/game-detail/cloud-game/socket'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import moment from 'moment'
import CEFTransfer from 'src/CEFTransfer'
import cloudSave from 'src/CEFTransfer/CloudSave'
import styled from 'styled-components'
import ArrowIcon from 'src/assets/icons/common/arrow.svg'
import TrialGamePlayStore from 'src/store/trial-game-play-store'

let archiveSaveTimer: number | null = null
let disposer: IReactionDisposer
const waitArchiveDownSuccess = (archiveKey: string): Promise<boolean> => {
  return new Promise(resolve => {
    const stop = reaction(
      () => appStore.downComplete,
      () => {
        /** 监听完成的计数， 然后每次有完成的判断下 下载的队列里面还有没有这个key，假如没有了则代表下载完成了 */
        if (!appStore.archivesProgressObjMap.has(archiveKey)) {
          stop()
          resolve(true)
        }
      },
    )
  })
}


const StyledChangeGame=styled.div`
  padding-bottom: 10px;
  >p{
    margin-bottom: 0;
  }
  .img{
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    img{
      border-radius: 8px;
    }
  }
  .green{
    color:#10FAB8
  }
  .white{
    color: #fff;
    font-weight: bold;
  }
  .red{
    color: #f4254a;
  }
`

export const useHandleGameStart = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { enterQueue, exitQueue } = useQueue()
  const {formatMessage} = useIntl()


  const resetCheckStep = () => {
    appStore.GameCheckErrorStepMessage = undefined
    appStore.GameCheckErrorStep = -1
    appStore.GameCheckStep = 0
  }

  /**
   * 检测平台是否安装
   */
  const checkPlatformInstall = (platform: GamePlatform) => {
    console.log('checkEnv 开始检测平台是否安装')
    return new Promise(resolve => {
      appStore.platformInstallStatus[platform] = -1
      //检测设定5s超时，超时默认已安装
      const timer = setTimeout(() => {
        console.log('checkEnv 检测平台是否安装超时，默认已安装')
        disposer?.()
        resolve('timeout')
      }, 5000)

      disposer?.()
      disposer = reaction(
        () => appStore.platformInstallStatus[platform],
        status => {
          timer && clearTimeout(timer)
          disposer()
          if (status === 1) {
            resolve('installed')
          }
        },
      )
      // CEFTransfer.sendToClient('check_single_gamepaltform_install_status', platform, 0)
    })
  }

  /**
   * @description 本地模式启动游戏
   * @param gameStartPayload 启动游戏详情
   * @param archiveKey 存档key
   * @param archiveType 存档类型 0：本地存档 1：云存档
   */
  const finalStartGame = async (
    gameStartPayload: GameStartInfo,
    // launchParamDto: TLaunchParamDto,
    queueMainId: string = '',
  ) => {
    //前置检查测过平台，此处不需要
    //检测平台是否安装
    // await checkPlatformInstall(gameStartPayload.platform)
    // console.log('checkEnv 平台已安装')

    const GamePlayStore= TrialGamePlayStore

    /** 清除上一次启动游戏的定时器 */
    GamePlayStore.gameStartingTimer && clearTimeout(GamePlayStore.gameStartingTimer)

    /** 游戏启动弹框里面，假如这个状态为true，则第三步显示《平台更新中》的文案，否则显示《正在启动账号安全组件》文案，
     * 这里每次启动游戏前先把这个初始化一下
     * */
    appStore.gameUpdatedCompleted = false
    /** 重置游戏启动弹框的状态 */
    resetCheckStep()

    const startGameResult = await startGameHttp({
      gameLibraryBranchId: gameStartPayload.gameLibraryBranchId,
      //管家的gameId
      gameId: gameStartPayload.gameId,
      gameMode: gameStartPayload.gameMode,
      queueMainId,
      launchParamDto:{
        isUseArchive: gameStartPayload.archivePayload?.key ? 1 : 0,
        archiveKey: gameStartPayload.archivePayload?.key || '',
      },
    })

    //防止网络请求慢，切换游戏后导致状态不一致
    if (gameStartPayload.gameLibraryBranchId !== GamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId) {
      console.log(
        `start game userGameId changed, ${gameStartPayload.gameLibraryBranchId} => ${GamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId}, return`,
      )
      return
    }

    //游戏启动提示(暂时使用游戏使用帮助弹窗替换)
    // if (gameStartPayload.startHelp) {
    //   try {
    //     await checkStartTipsModal(gameStartPayload.gameLibraryId)
    //   } catch (e) {
    //     console.log('checkStartTipsModal err', e)
    //   }
    // }

    // 游戏使用帮助弹窗
    // if (gameStartPayload.gameLibraryId) {
    //   await checkGameHelpModal(gameStartPayload.gameLibraryId)
    // }

    //提示开启加速器弹窗检测
    // if (gameStartPayload.isEnableJsq) {
    //   try {
    //     const checkSpeedModalRes = await checkSpeedModal()
    //     // console.log('是否需要拉起加速器', checkSpeedModalRes)
    //     checkSpeedModalRes && runSpeedClient(defaultSpeed)
    //   } catch (e) {
    //     console.log('checkSpeedModal err', e)
    //   }
    // }

    GamePlayStore.currentTheRunningGameInfo = {
      ...gameStartPayload,
      startGameOrderNo: startGameResult.order_no,
      userGameId:startGameResult.userGameId
    }

    //记录上号相关的信息， 这里主要给存档弹窗拿图片用
    GamePlayStore.startGameInfoMap.set(startGameResult.order_no, {
      listImage: gameStartPayload?.listImage,
      gameName: gameStartPayload?.gameName
    })

    /** 记录启动游戏时间(分配到账号时间) */
    GamePlayStore.beginTime = Date.now()

    //开始游戏成功，跳转到试玩记录页面
    //海外版暂时注释
    // setTimeout(() => {
    //   if (location.pathname !== '/my-trial-record') {
    //     navigate('/my-trial-record')
    //   }
    // }, 100)

    appStore.CheckModalVisible = true
    /** 获取超时时间,暂时写死60s */
    GamePlayStore.gameStartingCountdown = (60 ||appStore.StartGameTimeoutConfig[gameStartPayload.platform!] || appStore.StartGameTimeoutConfig.default) * 1000

    //开始游戏启动进度条
    gameStartStep.start()
    //前置环境检测
    const result = await gameStartStep.checkEnv()
    if (result === 'stop') {
      return
    }
    //是否切换游戏
    if (gameStartPayload.gameLibraryBranchId !== GamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId) {
      console.log(
        `start game userGameId changed after checkEnv, ${gameStartPayload.gameLibraryBranchId} => ${GamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId}, return`,
      )
      return
    }
    if (!GamePlayStore.currentTheRunningGameInfo?.startGameOrderNo) {
      console.log('start game err, no startGameOrderNo', gameStartPayload.gameLibraryBranchId)
      return
    }
    console.log(gameStartPayload.archivePayload, '存档信息2')

    /** 通知云存档使用使用类型 */
    if (gameStartPayload.archivePayload?.type === 'local') {
      //cloudSaveInstance ApplyLocalArchives
      cloudSave.noticeClient('ApplyLocalArchives', {
        order_no: GamePlayStore.currentTheRunningGameInfo.startGameOrderNo!,
        archives_name: gameStartPayload.archivePayload.key || '',
      })
    } else if (gameStartPayload.archivePayload?.type !== undefined) {
      const isCheckArchivesClash = gameStartPayload.archivePayload.repelOther || false
      //cloudSaveInstance ApplyCloudArchives
      cloudSave.noticeClient('ApplyCloudArchives', {
        order_no: GamePlayStore.currentTheRunningGameInfo.startGameOrderNo!,
        oss_path: gameStartPayload.archivePayload?.key.split('#')[0] || '',
        is_check_archives_clash: isCheckArchivesClash ? '1' : '0',
      })
      if (isCheckArchivesClash) {
        await new Promise((res, rej) => {

          imperativeConfirmModal({
            title:'存档应用提示',
            content: (
              <div>
                <div>应用当前存档可能覆盖您之前的存档,请谨慎使用!</div>
                <div>【跳过】 - 不应用存档继续启动</div>
                <div>【继续】 - 应用存档继续启动</div>
              </div>
            ),
            okText: '继续启动',
            cancelText: '跳过',
            onOk: () => {
              //cloudSaveInstance ArchivesClashSelect
              cloudSave.noticeClient('ArchivesClashSelect', {
                on_select: '2',
              })
              res(true)
            },
            onCancel: () => {
              //cloudSaveInstance ArchivesClashSelect
              cloudSave.noticeClient('ArchivesClashSelect', {
                on_select: '1',
              })
              res(false)
            },
          })
        })
      }
    }
    /** 启动超时的定时器 */
    console.log('checkEnv 设置平台超时定时器 =', GamePlayStore.gameStartingCountdown / 1000)
    appStore.startProblemVisible = false
    ;(function timeCheck() {
      // console.log('GamePlayStore.gameStartingCountdown',GamePlayStore.gameStartingCountdown)
      if (GamePlayStore.gameStartingCountdown > 0) {
        GamePlayStore.gameStartingTimer = window.setTimeout(() => {
          GamePlayStore.gameStartingCountdown -= 1000
          timeCheck()
        }, 1000)
        return
      }
      /**
       * 原本逻辑超时之后会结束游戏 ,但是结束游戏可能导致平台更新没有完成, 从而造成需要多次更新
       * 现在改为： 超时之后隐藏检测弹窗, 维持原有游戏状态
       */
      console.log('checkEnv 平台超时定时器执行')
      gameStartingSuccess(true)
      appStore.startGameOrderInfo = gameStartPayload.gameLibraryBranchId
      appStore.startProblemVisible = true
    })()
    // 开始游戏前 通知云存档
    //cloudSaveInstance BeforeStartGame
    cloudSave.noticeClient('BeforeStartGame', {
      order_no: `${GamePlayStore.currentTheRunningGameInfo.startGameOrderNo}`,
      local_archives_size: Number(localStorage.getItem('localArchiveLen') || 0),
      cloud_archives_size: Number(localStorage.getItem('cloudArchiveLen') || 0),
    })

    //login_game
    CEFTransfer.sendToClient('loginGame', {
      gameID: Number(GamePlayStore.currentTheRunningGameInfo?.userGameId),
      steamId: gameStartPayload.appId,
      gameName: gameStartPayload.gameName,
      orderNo: GamePlayStore.currentTheRunningGameInfo.startGameOrderNo!,
      type: 0,
      gameMode: 1,
      platform: gameStartPayload.platform,
      key: gameStartPayload.archivePayload?.key,
      checkWifi: 0,
      checkMemory: 0
    })
  }


  const handleStartGameByGameStartInfo = async ({
     gameStartInfo,
     queueMainId,
     archive,
    }: {
    gameStartInfo: GameStartInfo | null
    queueMainId:string
    gameMode?:TGameMode
    archive?: (() => Promise<{ result: number, data: TFinalArchive | null }>) | TFinalArchive | null,
  }) => {

    if (!gameStartInfo) {
      return
    }

    const gameMode =gameStartInfo.gameMode

    /** 云游戏模式判断,时长是否充足 */
    if (gameMode === 'cloud') {
      const cloudGameTimeNotEnough = await checkCloudGameTime()
      console.log('cloudGameTimeNotEnough', cloudGameTimeNotEnough)
      if (cloudGameTimeNotEnough) {
        return false
      }
    }

    /** 云游戏模式判断,ws是否已连接 */
    if (gameMode === 'cloud') {
      if (SocketStore.readyState !== WebSocket.OPEN) {
        return (CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.WS_NOT_CONNECTED)
      }
    }

    const hasQueueingResult = await checkQueueing(gameStartInfo)
    if (hasQueueingResult === 'confirm') {
      //取消队列
      await exitQueue(gameMode)
    } else if (hasQueueingResult === 'cancel') {
      return false
    }

    /** 是否有保存中的游戏 */
    const hasGameInWaitClose = await checkHasGameInWaitClose(gameMode)
    if (hasGameInWaitClose) {
      message.warn(`游戏${getGamePlayStoreByGameMode(gameMode).currentTheRunningGameInfo?.gameName}正在保存中,请稍后再试`)
      return false
    }

    const hasGameRunning = await checkHasGameRunning(gameStartInfo)
    if (hasGameRunning) {
      return false
    }

    /** 试玩，不支持云存档提示 */
    if (gameMode === 'local' && !await checkCloudArchiveTips(gameStartInfo)) {
      return false
    }

    const GamePlayStore = getGamePlayStoreByGameMode(gameMode)
    /** 启动游戏前结束正在运行的游戏 */
    if (['starting', 'running'].includes(GamePlayStore.gameRunStatus)) {
      GamePlayStore.willStartGameId = gameStartInfo.gameLibraryBranchId || ''
      if(GamePlayStore.currentTheRunningGameInfo?.gameMode==='cloud'){
        await stopCloudGame({needRequest: true, reason: '启动游戏前结束正在云游戏的游戏', needArchive: false})
      }else {
        await handleStopGame(false)
      }
    }

    //获取选中的存档
    let archivePayload = null
    if (checkLocal(gameMode)) {
      if (typeof archive === 'function') {
        const d = await archive()
        if (d.result) {
          archivePayload = d.data
        } else {
          return
        }
      } else {
        archivePayload = archive
      }
    }

    /** 记录刚进来的时候游戏运行的状态，假如中途启动失败，则恢复到之前的状态 */
    const oldRunStatus = GamePlayStore.gameRunStatus
    const oldCurrentTheStartingGameInfo = GamePlayStore.currentTheStartingGameInfo
    const oldCurrentTheRunningGameInfo = GamePlayStore.currentTheRunningGameInfo
    GamePlayStore.willStartGameId = null
    GamePlayStore.currentTheStartingGameInfo = null
    GamePlayStore.currentTheRunningGameInfo = null
    GamePlayStore.gameRunStatus = 'starting'

    GamePlayStore.currentTheStartingGameInfo = {
      // img: gameStartInfo.list_img,
      // platform: gameStartInfo.platform,
      // gameId: gameStartInfo.game_id,
      // userGameId: gameStartInfo.id,
      // appid: gameStartInfo.appid,
      // gameName: gameStartInfo.product_name,
      // orderNo: gameStartInfo.order_no,
      // isSupportArchive: gameStartInfo.isSupportArchive,
      // isEnableJsq: gameStartInfo.is_enable_jsq,
      // allowExit: 'normal',
      ...gameStartInfo,
      // gameLibraryImg,
      // gameLibraryName,
      // collection,
      archivePayload,
      // gameMode,
      // terminal: 'pc',
      // startHelp: gameStartInfo.startHelp,
    }

    /** 重置状态 */
    GamePlayStore.beginTime = 0
    GamePlayStore.platformLogin = false
    GamePlayStore.gamePull = false

    /** 当前选中的存档正在下载 */
    if (
      checkLocal(gameMode) &&
      archivePayload &&
      archivePayload.type === 'cloud' &&
      appStore.archivesProgressObjMap.has(archivePayload.key)
    ) {
      message.warn('当前选中的存档正在下载中，无法启动游戏')
      return (GamePlayStore.gameRunStatus = oldRunStatus)
    }
    if (
      checkLocal(gameMode) &&
      archivePayload &&
      archivePayload.type === 'cloud' &&
      !appStore.archivesProgressObjMap.has(archivePayload.key)
    ) {
      console.log(`等待下载存档`)
      await downloadArchive(archivePayload)
      await waitArchiveDownSuccess(archivePayload.key)
      console.log(`存档下载完成`)
    }

    try {
      if(gameMode==='cloud'){
        const accountLoginMode= gameStartInfo.accountLoginMode
        await finalCloudGameStart(gameStartInfo, accountLoginMode??'', queueMainId)
      }else{
        await finalStartGame(GamePlayStore.currentTheStartingGameInfo, queueMainId)
      }
      if (queueMainId) {
        getQueueStoreByGameMode(gameMode).queueStatus = 'stop'
      }
    } catch (error: any) {
      console.error('start game handleStartGameByGameStartInfo err', error)
      const QueueStore = getQueueStoreByGameMode(gameMode)
      GamePlayStore.currentTheRunningGameInfo = null
      GamePlayStore.gameRunStatus = 'stop'

      if (error.status_code === 200103) {
        message.warning(error.message)
        return navigate(`/cloudgame-recharge`)
      }

      //账号、库存不足
      if (error.status_code === 1239) {
        imperativeConfirmModal({
          title: '启动云游戏失败提醒',
          content: `由于 ${gameStartInfo.gameName} 游戏账号不足，云游戏启动失败，建议使用自有账号模式启动或稍后再试！`,
          okText: '我知道了',
          btns: ['ok']
        })
        return
      }

      //非会员试玩
      if (error.status_code === 1240) {
        imperativeConfirmModal({
          title: '提示',
          content: <>
            使用共享账号为 MYBUFF 会员专属特权<br/>
            开通 MYBUFF 会员可无限使用 MYBUFF 的共享账号
          </>,
          okText: '开通会员',
          onOk() {
            // navigate('/vip-recharge')
            showUserCenter(NAV_KEY_MAPPING.VIP)
          }
        })
        return
      }

      if (error.status_code === 999999) {
        const gameNo = error.gameNo
        /** 有其他游戏正在排队 */
        if (
          (QueueStore.queueStatus === 'in' &&
            QueueStore.currentQueueInfo &&
            QueueStore.currentQueueInfo.gameNo !== gameNo)

        ) {
          imperativeConfirmModal({
            title: '提示',
            content: `您当前《${
              QueueStore.currentQueueInfo?.gameStartInfo.gameName
            }》正在队列中，如需排队其他游戏请先取消当前排队`,
            okText: '确定',
            btns:['ok']
          })
        } else {
          enterQueue({
            // gameStartPayload: CloudGameClientStore.currentTheStartingGameInfo,
            gameStartInfo,
            gameNo,
            // currentGameLibraryGame: { gameLibraryId, gameLibraryImg, gameLibraryName, collection },
            // launchParamDto,
          })
        }
      }else{
        message.warning(error.message === 'system exception' ? '网络波动异常，请重试' : error.message)
      }
    }
  }

  const checkCloudGameTime = async () => {
    if ((VipStore?.cloudGameRemainingTimeInRealTime ?? 0) < (VipStore.cloudGameGiveTime?.startCloudGameNeedTime ?? 0)) {
      imperativeConfirmModal({
        title: '云游戏时长余额不足提醒',
        content: (
          <>
            <p>
              当前剩余可用云游戏时长不足{formatTimeLocal(VipStore.cloudGameGiveTime?.startCloudGameNeedTime??0)}，无法启动游戏，请先进行云游戏时长充值
            </p>
            {
              !VipStore.memberinfo?.isMember && <p>开通MYBUFF会员每周赠送{formatTimeLocal(VipStore.cloudGameGiveTime?.vipUserGiveTime??0)}云游戏时长 &gt;&gt;</p>
            }
          </>
        ),
        okText: '充值时长',
        onOk(){
          // navigate('/cloudgame-recharge')
          showUserCenter(NAV_KEY_MAPPING.CLOUDGAME_RECHARGE)
        },
        cancelText: '取消',
      })
      return true
    }
    return false
  }

  const checkQueueing = async (gameStartInfo: GameStartInfo) => {
    const gameMode = gameStartInfo.gameMode
    const isLocal = checkLocal(gameMode)
    const queueStatus = isLocal ? TrialQueueStore.queueStatus : CloudQueueStore.queueStatus
    const currentQueueInfo = isLocal ? TrialQueueStore.currentQueueInfo : CloudQueueStore.currentQueueInfo
    if (queueStatus === 'in' && currentQueueInfo) {
      const duration = moment.duration(Date.now()-currentQueueInfo.enterTime);
      const h = duration.hours()
      const m = duration.minutes()
      const s = duration.seconds()
      let time = ``
      if(h===0 && m===0){
        time= `${s}${$t({id:'common.seconds'})}`
      }else if(h===0 && m!==0){
        time= `${m}${$t({id:'common.minutes'})}${s}${$t({id:'common.seconds'})}`
      }else{
        time =`${h}${m}${$t({id:'common.hours'})}${m}${$t({id:'common.minutes'})}${s}${$t({id:'common.seconds'})}`
      }
      const isConfirm =await imperativeConfirmModal({
        title: formatMessage({id:'common.notify'}),
        content: formatMessage({id: 'queue.change'}, {
          gameName: currentQueueInfo?.gameStartInfo.gameName,
          frontUser: (currentQueueInfo.frontUser || 0) + 1,
          time,
          gameName2: gameStartInfo.gameName,
        }),
        okText: formatMessage({id:'common.confirm'}),
        cancelText: formatMessage({id:'common.cancel'}),
      })
      return isConfirm.value ? 'confirm' : 'cancel'
    }
    return 'no-queue'
  }

  /** 返回false代表继续执行开始游戏逻辑， 返回true代表开始游戏中断 */
  const checkHasGameRunning = async (gameStartInfo: GameStartInfo) => {
    const GamePlayStore=getGamePlayStoreByGameMode(gameStartInfo.gameMode)
    if (!['starting', 'running'].includes(GamePlayStore.gameRunStatus)) {
      return false
    }
    const isLocal = checkLocal(gameStartInfo.gameMode)
    const gameName = GamePlayStore.gameRunStatus === 'starting' ?
      GamePlayStore.currentTheStartingGameInfo?.gameName :
      GamePlayStore.currentTheRunningGameInfo?.gameName
    const isConfirm = await imperativeConfirmModal({
      title: isLocal?formatMessage({id:'trial.change'}):'切换游戏提醒',
      width:523,
      closable:true,
      content: (
        <StyledChangeGame>
          <div className="img">
            <FadeinImage width={180} height={81} src={GamePlayStore.currentTheRunningGameInfo?.listImage}/>
            <ArrowIcon/>
            <FadeinImage width={180} height={81} src={gameStartInfo?.listImage}/>
          </div>
          {
            isLocal ?
              <p>
                {formatMessage({id: 'trial.change2'}, {
                  gameName,
                  nextGameName: gameStartInfo.gameName,
                  strong: str => <strong className="white">{str}</strong>,
                  // span: str => <span className="red">{str}</span>,
                  // br: () => <br/>,
                  save: GamePlayStore.currentTheRunningGameInfo?.isSupportArchive ?
                    formatMessage({id:'trial.change3'},{
                      br: () => <br/>,
                      span: str => <span className="red">{str}</span>,
                    }):''
                })}
              </p> :
              <p>
                游戏 <strong className="white">{gameName}</strong> 正在
                <span className="green">{GamePlayStore.gameRunStatus === 'starting' ? '启动中' : '运行中'}</span>，
                确认要退出该游戏并启动 <strong className="white">{gameStartInfo.gameName}</strong> 进行游戏么？
              </p>
          }
          {/*{GamePlayStore.currentTheRunningGameInfo?.isSupportArchive === 1 &&*/}
          {/*  GamePlayStore.gameRunStatus === 'running' && <p><span className='white'>直接结束游戏将不会进行云存档保存</span>，请注意！</p>}*/}
        </StyledChangeGame>
      ),
      okText: formatMessage({id: 'common.confirm'}),
      cancelText: formatMessage({id: 'common.cancel'}),
    })
    console.log('isConfirm', isConfirm)
    return !isConfirm.value
  }

  /** 返回false代表继续执行开始游戏逻辑， 返回true代表开始游戏中断 */
  const checkHasGameInWaitClose = async (gameMode:TGameMode) => {
    const GamePlayStore=getGamePlayStoreByGameMode(gameMode)
    if (!['waitClose'].includes(GamePlayStore.gameRunStatus)) {
      return false
    }
    return true
  }

  return {
    handleStartGameByGameStartInfo,
    handleStopGame,
  }

}

/** 处理主动关闭游戏 */
export const handleStopGame = async (needModal: boolean = true) => {
  const GamePlayStore = TrialGamePlayStore
  if (
    !['starting', 'running'].includes(GamePlayStore.gameRunStatus) ||
    (!GamePlayStore.currentTheRunningGameInfo && !GamePlayStore.currentTheStartingGameInfo)
    // || !GamePlayStore.currentTheRunningGameInfo?.startGameOrderNo
  ) {
    return
  }
  // if (GamePlayStore.currentTheRunningGameInfo?.gameMode === 'cloud') {
  //   return(GamePlayStore.visibleExitConfirmModal = true)
  // }
  const stopLocalGame = async () => {
    const gameInfo = GamePlayStore.currentTheRunningGameInfo
    const startGameOrderNo = gameInfo?.startGameOrderNo
    GamePlayStore.gameStartingTimer && window.clearTimeout(GamePlayStore.gameStartingTimer)
    GamePlayStore.trialVipTimeTimer && window.clearTimeout(GamePlayStore.trialVipTimeTimer)
    appStore.startProblemVisible = false

    gameStartStep.reset()
    if (needModal && gameInfo?.startGameOrderNo && gameInfo.isSupportArchive === 1) {
      //获取进程名
      const {result} = await getProcessNameByOrder({ orderNo: gameInfo.startGameOrderNo })
      // DWForceStopGame.Execute({startGameNo:gameInfo.startGameOrderNo, reason: '主动退出'})
      const waitCloudSave = GamePlayStore?.gameRunStatus === 'running' ? gameInfo.isSupportArchive : 0
      GamePlayStore.gameRunStatus = waitCloudSave ? 'waitClose' : 'stop'
      //退出游戏 ，exit_game
      CEFTransfer.sendToClient('exitGame', {
        waitCloudSave,
        processName:result.processName
      })
      if (waitCloudSave) {
        archiveSaveTimer && clearTimeout(archiveSaveTimer)
        archiveSaveTimer = window.setTimeout(() => {
          if (GamePlayStore.gameRunStatus === 'waitClose') {
            GamePlayStore.gameRunStatus = 'stop'
            // imperativeConfirmModal({
            //   title: '云存档服务超时',
            //   content: '云存档服务连接超时，存档保存失败!',
            //   btns: ['ok'],
            //   okText: '知道了',
            // })
            message.warn('存档保存超时')
          }
        }, 90 * 1000)
      }
    } else {
      HideCheckModal()
    }
    //需要确认弹窗，可认为是主动结束游戏，也需要弹反馈弹窗
    if(needModal){
      showFeedback('trial', {
        gameName: GamePlayStore.currentTheStartingGameInfo?.gameName,
        platform: GamePlayStore.currentTheStartingGameInfo?.gamePlatformName,
        //记录平台是否启动成功
        isLoginGamePlatform: GamePlayStore.platformLogin ? '1' : '0',
        //是否成功启动了游戏
        isStartGame: GamePlayStore.gamePull ? '1' : '0',
        accountUseTime: GamePlayStore.beginTime ? parseInt(String((Date.now() - GamePlayStore.beginTime) / 1000)) : 0,
        bizOrderNo: startGameOrderNo,
      },true)
    }
  }

  if (!needModal) {
    return stopLocalGame()
  }

  imperativeConfirmModal({
    title: $t({id:'trial.stop'}),
    content:(
      <p>{$t({id:'trial.stop2'},{
        save: GamePlayStore.currentTheRunningGameInfo?.isSupportArchive ? $t({id:'trial.stop3'}) :''
      })}</p>
    ),
    onOk: stopLocalGame,
    okText: $t({id:'common.confirm'}),
    cancelText:$t({id:'common.cancel'}),
  })
}