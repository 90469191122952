import React, { FC, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { Progress, Drawer, message, Input, Spin, Tooltip } from 'antd'
import { checkLocal, downloadArchive } from 'src/tools/common'
import { appStore } from 'src/store'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { deleteGameArchive, updateCloudArchiveName } from 'src/apis/game-api'
import { LoginStore } from 'src/store/modifier'
import { reaction } from 'mobx'
import { useIntl } from 'react-intl'
import DownIcon from 'src/assets/icons/trial/download.svg'
import CloseIcon from 'src/assets/icons/trial/del.svg'
import EditIcon from 'src/assets/icons/trial/edit.svg'
import classNames from 'classnames'
import moment from 'moment'
import styled from 'styled-components'
import cloudSave from 'src/CEFTransfer/CloudSave'

interface ArchivesProps {
  activeArchive: TFinalArchive | null | undefined
  setActiveArchive: (val: TFinalArchive | null) => void
  gameId: number
  gameMode: TGameMode
  onOk?: (v:any) => void
  onCancel?: (v?:any) => void
  loadAllArchivedList: TLoadAllArchivedList
  visible:boolean,
  finalLocalArchiveList?:TFinalArchive[]
  finalCloudArchiveList?:TFinalArchive[]
  cloudArchivedList?:TFinalArchive[]
}

const StartGameConfig: FC<ArchivesProps> = ({
  visible,
  activeArchive,
  setActiveArchive,
  gameMode,
  gameId,
  onCancel,
  onOk,
  loadAllArchivedList,
  finalLocalArchiveList,
  finalCloudArchiveList,
  cloudArchivedList
}) => {
  const [isUseCloud, setIsUseCloud] = useState<boolean>(true)
  const [loadArchivedListLoading, setLoadArchivedListLoading] = useState<boolean>(false)
  // const [finalLocalArchiveList, setFinalLocalArchiveList] = useState<TFinalArchive[]>()
  // const [finalCloudArchiveList, setFinalCloudArchiveList] = useState<TFinalArchive[]>()
  // const [cloudArchivedList, setCloudArchiveList] = useState<TFinalArchive[]>()
  const [editItem, setEditItem] = useState<TFinalArchive | null>(null)
  const [editNickName, setEditNickName] = useState<string>('')
  const userInfo =LoginStore.userinfoGJ
  const isUseCloudRef = useRef(isUseCloud)
  isUseCloudRef.current = isUseCloud
  const { archivesProgressObjMap } = appStore
  const {formatMessage} = useIntl()

  const handleCancel = () => {
    // if (activeArchive === undefined) {
    //   return
    // }
    setEditNickName('')
    setEditItem(null)
    setIsUseCloud(!!activeArchive)
    onCancel?.()
  }

  const getArchiveList = async (gameId: number) => {
    setLoadArchivedListLoading(true)
    await loadAllArchivedList(gameId)
    setLoadArchivedListLoading(false)
  }

  const changeArchive = (d: TFinalArchive) => {
    setActiveArchive(d)
    setIsUseCloud(true)
    onOk?.(d)
  }
  const removeArchive = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, d: TFinalArchive) => {
    e.stopPropagation()
    const { name, key, type } = d
    imperativeConfirmModal({
      zIndex:1001,
      title:formatMessage({id:'common.notify'}),
      content:
        type === 'local'
          ? formatMessage({id:'save.deleteLocal'})
          : type === 'localCloud'
            ? formatMessage({id:'save.deleteLocalCloud'})
            : d.presented
              ? '此存档为商城赠送，删除后若还想继续使用此存档将需要购买'
              : d.diy === 1
                ? formatMessage({id:'save.delete2'})
                : '此存档为商城购买，删除后若还想继续使用此存档将需要再次购买，请三思啊！',
      onOk: async () => {
        // const token = await isLogged()
        if (type === 'cloud') {
          /** 云存档删除 */
          //云存档删除接口
          await deleteGameArchive({ key: key || '' })
          message.success(formatMessage({id:'save.deleteSuccess'}))
        } else if (type === 'local') {
          /** 本地存档 */
            //删除本地存档
          const result = await cloudSave.noticeClient('DeleteLocalArchives', {
            game_id: `${gameId}`,
            user_id: `${userInfo!.cloudArchiveIdentify}`,
            archives_name: `${name}`,
          })
          if (result?.success) {
            message.success(formatMessage({id:'save.deleteSuccess'}))
          } else {
            message.warn(formatMessage({id:'save.deleteFail'}))
          }
        } else {
          //删除已经下载到本地的云存档
          /** 已经下载到本地的云存档 */
          const result = await cloudSave.noticeClient('DeleteLocaCloudlArchives', {
            game_id: `${gameId}`,
            user_id: `${userInfo?.cloudArchiveIdentify}`,
            archives_name: `${name}`,
            archives_type: d.diy === 1 ? 2 : 3,
            key: d.key,
          })
        }
        getArchiveList(gameId)
      },
    })
  }

  const editBtn = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, d: TFinalArchive) => {
    e.stopPropagation()
    setEditItem(d)
    setEditNickName(d.new_name || d.name)
  }
  const setEditNickNameBtn = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, d: TFinalArchive) => {
    e.stopPropagation()
    if (!editNickName) {
      setEditNickName('')
      setEditItem(null)
      return
    }
    //存档名称变更
    await updateCloudArchiveName({ name: editNickName, userId: userInfo!.cloudArchiveIdentify, id: editItem!.id || 0 })
    getArchiveList(gameId)
    message.success(formatMessage({id:'save.renameSuccess'}))
    setEditNickName('')
    setEditItem(null)
  }
  const setEditLocalNameBtn = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, d: TFinalArchive) => {
    e.stopPropagation()
    if (!editNickName) {
      setEditNickName('')
      setEditItem(null)
      return
    }
    //存档重命名，cloudSaveInstance AchivesRename
    const result = await cloudSave.noticeClient('AchivesRename', {
      game_id: `${gameId}`,
      user_id: `${userInfo!.cloudArchiveIdentify}`,
      archives_name: editItem!.name,
      archives_type: editItem?.diy === 1 ? 2 : editItem?.diy === 0 ? 3 : 1,
      new_archives_name: editNickName,
      key: editItem?.key || '',
    })
    if (result?.success) {
      getArchiveList(gameId)
      setEditNickName('')
      setEditItem(null)
      message.success(formatMessage({id:'save.renameSuccess'}))
    } else {
      setEditNickName('')
      setEditItem(null)
      message.warn((formatMessage({id:'save.renameFail'})))
    }
  }

  useEffect(() => {
    if (!isUseCloud) {
      setActiveArchive(null)
      setEditNickName('')
      setEditItem(null)
    }
  }, [isUseCloud])

  useEffect(() => {
    const disposer = reaction(()=>{
      return appStore.downComplete+appStore.saveComplete
    },(v)=>{
      getArchiveList(gameId)
    })
    return ()=>{
      disposer?.()
    }
  }, [gameId])


  useEffect(()=>{
    const temp = [...finalLocalArchiveList ?? [], ...finalCloudArchiveList ?? []]
    const active = temp.find(d => d.key === activeArchive?.key)
    if (isUseCloudRef.current && !active) {
      setActiveArchive(temp[0])
    }
  },[finalLocalArchiveList,finalCloudArchiveList])

  return (
    <StyledConfigBoxDrawer
      visible={visible}
      placement="bottom"
      closable={false}
      onClose={() => handleCancel()}
      maskClosable={true}
    >
      <StyledArchiverConfig>
        <div className="title">{formatMessage({id:'detail.trial.select'})}</div>

        {((finalLocalArchiveList && finalLocalArchiveList.length > 0) ||
          (finalCloudArchiveList && finalCloudArchiveList.length > 0)) && (
          <>
            <StyledRow>
              <StyledNavItem className={classNames({ active: !isUseCloud })} onClick={() => {
                setIsUseCloud(false)
                onOk?.(null)
              }}>
                <StyledCheck className={classNames({ active: !isUseCloud })} />
                {formatMessage({id:'detail.save.noUse'})}
              </StyledNavItem>
            </StyledRow>
            <Spin spinning={loadArchivedListLoading}>
              <StyledBox>
                <StyledHeader>
                  <div className="headerCell" style={{ width: 'var(--column-width-1)', paddingLeft: 24 }}>
                    {formatMessage({id:'detail.save.name'})}
                  </div>
                  <div className="headerCell" style={{ width: 'var(--column-width-2)' }}>
                    {formatMessage({id:'detail.save.type'})}
                  </div>
                  {/*<div className="headerCell" style={{ width: 'var(--column-width-1)' }}>*/}
                  {/*  存档来源*/}
                  {/*</div>*/}
                  <div className="headerCell" style={{ width: 'var(--column-width-3)' }}>
                    {formatMessage({id:'detail.save.time'})}
                  </div>
                  <div className="headerCell" style={{ width: 'var(--column-width-4)' }}>
                    {formatMessage({id:'detail.save.size'})}
                  </div>
                  <div className="headerCell" style={{ flex: 'auto', minWidth: 'var(--column-width-5)' }}>
                    {formatMessage({id:'detail.save.operate'})}
                  </div>
                </StyledHeader>
                {checkLocal(gameMode) ? (
                  <>
                    {finalLocalArchiveList &&
                      finalLocalArchiveList.length > 0 &&
                      finalLocalArchiveList.map((d, i) => {
                        return (
                          <StyledContentItem
                            key={i}
                            className={classNames({ active: activeArchive?.key === d.key })}
                            onClick={() => !(!!editItem && editItem.key === d.key) && changeArchive(d)}
                          >
                            <div className="headerCell flex" style={{ width: 'var(--column-width-1)' }}>
                              <StyledCheck className={classNames({ active: activeArchive?.key === d.key })} />
                              {!!editItem && editItem.key === d.key ? (
                                <StyledInput
                                  maxLength={24}
                                  value={editNickName}
                                  onChange={e => setEditNickName(e.target.value.trim())}
                                  autoComplete="off"
                                />
                              ) : (
                                <span className="online-two"><Tooltip title={d.new_name}>{d.new_name}</Tooltip></span>
                              )}
                            </div>
                            <div className="headerCell" style={{ width: 'var(--column-width-2)' }}>
                              {d.type === 'local' ? formatMessage({id:'save.local'}) : formatMessage({id:'save.cloud'}) }
                            </div>
                            {/*<div className="headerCell" style={{ width: 95 }}>*/}
                            {/*  {d.type === 'local'*/}
                            {/*    ? d.new_name === '自动保存'*/}
                            {/*      ? '系统自动保存'*/}
                            {/*      : '用户保存'*/}
                            {/*    : d.presented*/}
                            {/*      ? '活动赠送'*/}
                            {/*      : d.diy === 1*/}
                            {/*        ? '用户保存'*/}
                            {/*        : '存档购买'}*/}
                            {/*</div>*/}
                            <div className="headerCell" style={{ width: 'var(--column-width-3)' }}>
                              {d.time !== 0 && `${moment(d.time).format('YYYY-MM-DD HH:mm:ss')}`}
                            </div>
                            <div className="headerCell" style={{ width: 'var(--column-width-4)' }}>
                              {d.fileSize}
                            </div>
                            <div className="headerCell icons" style={{ flex: 'auto', minWidth: 'var(--column-width-5)' }}>
                              {d.new_name !== formatMessage({id:'save.auto'}) && (
                                <>
                                  {editItem?.key === d.key ? (
                                    <StyledSaveText onClick={e => setEditLocalNameBtn(e, d)}>{formatMessage({id:'detail.save.save'})}</StyledSaveText>
                                  ) : (
                                    <StyledIcon onClick={e => editBtn(e, d)}>
                                      <EditIcon />
                                    </StyledIcon>
                                  )}
                                  <StyledIcon onClick={e => removeArchive(e, d)}>
                                    <CloseIcon />
                                  </StyledIcon>
                                </>
                              )}
                            </div>
                          </StyledContentItem>
                        )
                      })}
                    {finalCloudArchiveList &&
                      finalCloudArchiveList.length > 0 &&
                      finalCloudArchiveList.map((d, i) => {
                        return (
                          <StyledContentItem
                            key={i}
                            className={classNames({ active: activeArchive?.key === d.key })}
                            onClick={() => !(!!editItem && editItem.key === d.key) && changeArchive(d)}
                          >
                            <div className="headerCell flex" style={{ width: 'var(--column-width-1)' }}>
                              <StyledCheck className={classNames({ active: activeArchive?.key === d.key })} />
                              {!!editItem && editItem.key === d.key ? (
                                <StyledInput
                                  maxLength={24}
                                  value={editNickName}
                                  onChange={e => setEditNickName(e.target.value.trim())}
                                  autoComplete="off"
                                />
                              ) : (
                                <span className="online-two"><Tooltip title={d.name}>{d.name}</Tooltip></span>
                              )}
                            </div>
                            <div className="headerCell" style={{ width: 'var(--column-width-2)' }}>
                              {formatMessage({id:'save.cloud'})}
                            </div>
                            {/*<div className="headerCell" style={{ width: 95 }}>*/}
                            {/*  {d.presented ? '活动赠送' : d.diy === 1 ? '用户保存' : '存档购买'}*/}
                            {/*</div>*/}
                            <div className="headerCell" style={{ width: 'var(--column-width-3)' }}>
                              {d.time !== 0 && `${moment(d.time).format('YYYY-MM-DD HH:mm:ss')}`}
                            </div>
                            <div className="headerCell" style={{ width: 'var(--column-width-4)' }}>
                              {d.fileSize}
                            </div>
                            <div className="headerCell icons" style={{ flex: 'auto', minWidth: 'var(--column-width-5)' }}>
                              {editItem?.key === d.key ? (
                                <StyledSaveText onClick={e => setEditNickNameBtn(e, d)}>{formatMessage({id:'detail.save.save'})}</StyledSaveText>
                              ) : (
                                <StyledIcon onClick={e => editBtn(e, d)}>
                                  <EditIcon />
                                </StyledIcon>
                              )}
                              {d.diy === 1 && (
                                <StyledIcon onClick={e => removeArchive(e, d)}>
                                  <CloseIcon />
                                </StyledIcon>
                              )}
                              <StyledIcon onClick={e => downloadArchive(d, e)}>
                                <DownIcon />
                              </StyledIcon>
                            </div>
                            {archivesProgressObjMap.has(d.key) && (
                              <div className="progressBox">
                                <Progress
                                  percent={
                                    archivesProgressObjMap.get(d.key)?.current
                                      ? Math.round(
                                        (archivesProgressObjMap!.get(d.key)!.current /
                                          archivesProgressObjMap.get(d.key)!.total!) *
                                        100,
                                      )
                                      : 0
                                  }
                                  status="active"
                                  strokeColor="var(--main-color)"
                                  trailColor="#424246"
                                />
                              </div>
                            )}
                          </StyledContentItem>
                        )
                      })}
                  </>
                ) : (
                  <>
                    {cloudArchivedList &&
                      cloudArchivedList.length > 0 &&
                      cloudArchivedList.map((d, i) => {
                        return (
                          <StyledContentItem
                            key={i}
                            className={classNames({ active: activeArchive?.key === d.key })}
                            onClick={() => changeArchive(d)}
                          >
                            <div className="headerCell flex" style={{ width: 210 }}>
                              <StyledCheck className={classNames({ active: activeArchive?.key === d.key })} />
                              {!!editItem && editItem.key === d.key ? (
                                <StyledInput
                                  maxLength={24}
                                  value={editNickName}
                                  onChange={e => setEditNickName(e.target.value.trim())}
                                  autoComplete="off"
                                />
                              ) : (
                                <span className="online-two">{d.name}</span>
                              )}
                            </div>
                            <div className="headerCell" style={{ width: 95 }}>
                              云存档
                            </div>
                            <div className="headerCell" style={{ width: 95 }}>
                              {d.presented ? '活动赠送' : d.diy === 1 ? '用户保存' : '存档购买'}
                            </div>
                            <div className="headerCell" style={{ width: 180 }}>
                              {d.time !== 0 && `${moment(d.time).format('YYYY-MM-DD HH:mm:ss')}`}
                            </div>
                            <div className="headerCell" style={{ width: 100 }}>
                              {d.fileSize}
                            </div>
                            <div className="headerCell icons" style={{ flex: 'auto', minWidth: 100 }}>
                              {editItem?.key === d.key ? (
                                <StyledSaveText onClick={e => setEditNickNameBtn(e, d)}>保存</StyledSaveText>
                              ) : (
                                <StyledIcon onClick={e => editBtn(e, d)}>
                                  {/*<EditIcon />*/}
                                  编辑
                                </StyledIcon>
                              )}
                              {d.diy === 1 && (
                                <StyledIcon onClick={e => removeArchive(e, d)}>
                                  {/*<CloseIcon />*/}
                                  删除
                                </StyledIcon>
                              )}
                            </div>
                          </StyledContentItem>
                        )
                      })}
                  </>
                )}
              </StyledBox>
            </Spin>
          </>
        )}
      </StyledArchiverConfig>
    </StyledConfigBoxDrawer>

    // <ImperativeConfirmModal options={{
    //   visible,
    //   title: '游戏存档选择 - 本地模式',
    //   width:846,
    //   key:'archive-config',
    //   showIcon:false,
    //   // closable:true,
    //   okText: '确认启动',
    //   cancelText:'取消',
    //   btns: ['cancel', 'ok',],
    //   zIndex:999,
    //   _ok: ()=>{
    //     onOk?.(activeArchive)
    //   },
    //   _cancel:()=>{
    //     handleCancel()
    //   },
    //   content: ,
    // } } />
  )
}

const StyledArchiverConfig=styled.div`
  --column-width-1: 35%;
  --column-width-2: 15%;
  --column-width-3: 20%;
  --column-width-4: 15%;
  --column-width-5: 15%;
  
  padding-bottom: 24px;
  max-height: 70vh;
  overflow: auto;
  .title{
    height: 56px;
    line-height: 56px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding-left: 24px;
    background: #1C1C1F;
  }
  
  &::-webkit-scrollbar{
    display: none;
  }
  .ant-spin-container::after{
    background: #000;    
  }
  .ant-spin-blur::after{
    opacity: .1;    
  }
`

const StyledInput = styled(Input)`
  width: calc(100% - 40px);
  height: 26px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  color: #f5f5f7;
`
const StyledSaveText = styled.span`
  font-size: 13px;
  color: var(--main-color);
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  width: 37px;
  position: relative;
  top: -2px;
`
const StyledIcon = styled.div`
  cursor: pointer;
  margin-right: 22px;
  &:hover {
    color: var(--main-color);
  }
`
const StyledCheck = styled.div`
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  background: rgba(216, 216, 216, 0.1);
  border: 1px solid #696969;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 12px;
  &.active {
    background: transparent;
    border: 1px solid var(--main-color);
    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: var(--main-color);
      border-radius: 50%;
      position: relative;
      left: 4px;
      top: 4px;
    }
  }
`
const StyledContentItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(43,45,49);
  font-size: 14px;
  color: #F3F3F3;
  height: 53px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid rgba(50,53,58,1);
  .headerCell {
    /* text-align: center; */
  }
  .online-two {
    overflow: hidden;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
  }
  .headerCell.flex {
    display: flex;
    align-items: center;
    //padding: 0 12px;
    padding-left: 24px;
    ${StyledCheck} {
      flex: 0 0 18px;
    }
  }
  .icons {
    display: flex;
    align-items: center;
  }
  &:hover,
  &.active {
    background: var(--bg-color-2);
  }
  &.active {
    .headerCell img {
      transition: all 200ms ease;
      opacity: 1;
    }
  }
  .progressBox {
    width: 100%;
    height: 38px;
    background: rgba(31, 31, 31, 0.93);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 0 46px;
    .ant-progress-text {
      font-size: 13px;
      color: #ffffff;
    }
  }
`
const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #96989D;
  height: 62px;
`

const StyledBox = styled.div`
  margin: 0 24px;
`
const StyledNavItem = styled.div`
  width: 100%;
  height: 52px;
  color: #F0F0F0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 24px;
  svg {
    margin-right: 6px;
    position: relative;
    top: -1px;
  }
`
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 24px 0;
  background: #2B2D31;
  border-radius: 4px;
`
const StyledTitle1 = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
  background: linear-gradient(90deg, rgba(255, 191, 40, 0.12) 0%, rgba(255, 191, 40, 0) 100%);
  padding-left: 12px;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: #ffbf28;
    border-radius: 2px;
  }
`
const StyledConfigBoxDrawer = styled(Drawer)`
  &.ant-drawer-bottom{
    
  }
  .ant-drawer-content {
    background: #26282B;
  }
  .ant-drawer-content-wrapper {
    width: calc(100% - 72px - 240px - 32px - 32px);
    height: auto !important;
    min-height: 100px !important;
    transition: all 0.3s ease;
    background: #26282B;
    border-radius: 4px;
    right: 32px;
  }
  .ant-drawer-body {
    max-height: calc(100vh - 155px);
    overflow-y: scroll;
    padding: 0;
  }
  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      bottom: 77px;
    }
  }
  .ant-drawer-mask {
    //background: none;
    //pointer-events: none !important;
  }
  .ant-spin-container:after {
    background: #202020;
  }
`

export default observer(StartGameConfig)
