import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Form, Input, message, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { appStore, archiveStore } from 'src/store'
import { exchangeSize } from 'src/tools/common'
import { ImperativeConfirmModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { deleteGameArchive, saveCloudArchive } from 'src/apis/game-api'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import moment from 'moment'
import TrialGamePlayStore from 'src/store/trial-game-play-store'
import cloudSave from 'src/CEFTransfer/CloudSave'


type SaveArchiveModalProps = {}
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: { span: 16 },
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const SaveArchiveModal: FunctionComponent<SaveArchiveModalProps> = () => {
  const [form] = Form.useForm()
  const { validateFields, resetFields } = form
  const [SaveTime, setSaveTime] = useState(moment())
  const [loading, setLoading] = useState<boolean>(false)
  const { formatMessage } = useIntl()

  const img = TrialGamePlayStore.startGameInfoMap.get(appStore.newSaveArchive?.newSaveArchiveGameOrderNo ?? '')?.listImage

  const archiveSave = async (type: 'local' | 'cloud') => {
    setLoading(true)
    try {
      const data = await validateFields(['ArchiveName'])
      if (!data.ArchiveName || data.ArchiveName.includes(' ')) {
        setLoading(false)
        return
      }

      // if (appStore.cloudSaveInstance) {
      const finalArchiveName = `${data.ArchiveName}-_-${SaveTime.valueOf()}`
      let result
      try {
        archiveStore.cloudArchiveSave = true
        if (type === 'local') {
          //cloudSaveInstance SaveLocalArchives
          result = await cloudSave.noticeClient('SaveLocalArchives', {
            save_name: finalArchiveName,
            order_no: appStore.newSaveArchive.newSaveArchiveGameOrderNo!,
          })
        } else {
          //cloudSaveInstance UploadCloudArchives
          result = await cloudSave.noticeClient('UploadCloudArchives', {
            upload_name: finalArchiveName,
            order_no: appStore.newSaveArchive.newSaveArchiveGameOrderNo!,
          })
        }
      } catch (error) {
      } finally {
        archiveStore.cloudArchiveSave = false
      }
      if (result?.success) {
        resetFields()
        appStore.newSaveArchive.newSaveArchiveVisible = false
        appStore.saveComplete = appStore.saveComplete + 1
        message.success(formatMessage({id:'save.success'}))
      } else {
        appStore.newSaveArchive.newSaveArchiveVisible = false
        message.warn(formatMessage({id:'save.fail'}))
      }
      // }
    } catch (error) {
      console.log(6546)
    }
    setLoading(false)
  }
  const onSaveCLoudGameArchive = async () => {
    if (appStore.newSaveArchive.newSaveArchiveSaveId) {
      try {
        const data = await validateFields(['ArchiveName'])
        if (!data.ArchiveName || data.ArchiveName.includes(' ')) {
          return
        }
        //保存云存档
        await saveCloudArchive({
          id: appStore.newSaveArchive.newSaveArchiveSaveId,
          name: data.ArchiveName,
        })
        resetFields()
        message.success(formatMessage({id:'save.success'}))
        appStore.saveComplete = appStore.saveComplete + 1
        appStore.newSaveArchive.newSaveArchiveVisible = false
      } catch (error) {
        console.error(error)
      }
    }
  }
  const OnHide = async () => {
    if (appStore.newSaveArchive.newSaveArchiveSaveKey) {
      try {
        // const token = await isLogged()
        //删除存档
        await deleteGameArchive({ key: appStore.newSaveArchive.newSaveArchiveSaveKey })
      } catch (error) {
        console.error(error)
      }
    }
    appStore.newSaveArchive.newSaveArchiveVisible = false
  }
  const onAfterClose = () => {
    // TrialGamePlayStore.gameCloudNo = ''
  }

  const archiveSaveModalVisible = useMemo(() => {
    // return true
    return appStore.modalVisibleList[0] === 'archive'
  }, [appStore.modalVisibleList])

  useEffect(() => {
    if (appStore.newSaveArchive.newSaveArchiveVisible) {
      appStore.modalVisibleList = [...appStore.modalVisibleList, 'archive']
    } else {
      resetFields()
      appStore.modalVisibleList = appStore.modalVisibleList.filter(s => s !== 'archive')
    }
  }, [appStore.newSaveArchive.newSaveArchiveVisible])

  useEffect(() => {
    if (appStore.newSaveArchive.newSaveArchiveVisible) {
      const date = moment(appStore.newSaveArchive.newSaveArchiveSaveTime || undefined, 'YYYY-MM-DD HH:mm:SS')
      setSaveTime(date.isValid() ? date : moment())
    }
  }, [appStore.newSaveArchive.newSaveArchiveVisible, appStore.newSaveArchive.newSaveArchiveSaveTime])

  return (<ImperativeConfirmModal options={archiveSaveModalVisible ? {
      title: formatMessage({id:'save.title'}),
      width: 513,
      key: 'save-archive',
      showIcon: false,
      // closable:true,
      okText: formatMessage({id:'save.yes'}),
      cancelText:formatMessage({id:'save.no'}),
      afterClose:onAfterClose,
      _ok: async () => {
        await archiveSave('cloud')
      },
      _cancel: () => {
        OnHide()
      },
      content: <StyledSaveArchive>
        <div className="meta">
          <div className="img">
            <FadeinImage width={200} height={92} src={img}/>
          </div>
          <div className="info">
            <p><label htmlFor="">{formatMessage({id:'save.gameName'})}</label>{appStore.newSaveArchive.newSaveArchiveGameName}</p>
            <p><label htmlFor="">{formatMessage({id:'save.time'})}</label>{SaveTime.format('YYYY-MM-DD HH:mm:SS')}</p>
            <p> <label htmlFor="">{formatMessage({id:'save.size'})}</label>{exchangeSize(appStore.newSaveArchive.newSaveArchiveSaveSize||0)}</p>
          </div>
        </div>

        <Form layout={'vertical'} form={form}>
          <Form.Item
            label={formatMessage({id:'save.name'})}
            name="ArchiveName"
            required={true}
            validateFirst={true}
            rules={[
              {
                validator: (rule: any, value: string, callback: (message?: string) => void) => {
                  console.log('test', value)
                  if (!value) {
                    return Promise.reject(formatMessage({id:'save.notBlank'}))
                  }
                  if (/[\s<>:"'/\\|?*]/g.test(value)) {
                    return Promise.reject(formatMessage({id:'save.nameRule'}))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input placeholder={formatMessage({id:'save.placeholder'})}/>
          </Form.Item>
        </Form>
      </StyledSaveArchive>,
    } : null}
    />
  )
}

const StyledSaveArchive=styled.div`
  padding-top: 24px;
  .meta{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .img{
      width: 200px;
      height: 92px;
      margin-right: 24px;
      border-radius: 8px;
      overflow: hidden;
      img{
        object-fit: cover;
      }
    }
    .info{
      color: #fff;
      line-height: 20px;
      >p{
        margin-bottom: 8px;
        >label{
          color: #bbbbbb;
        }
      }
      
    }
  }
  .ant-form-item-label > label{
    color: #fff;
    font-size: 16px;
  }
  .ant-input{
    background: none !important;
    border: 1px solid #52525B;
    border-radius: 4px;
    padding: 5px 11px;
    color: #F4F4F5;
  }
  .ant-input:focus, .ant-input-focused{
    box-shadow: none;
  }
  .ant-form-item-label{
    margin-bottom: 4px;
  }
  
`

const StyledNewSaveArchiveModal = styled(Modal)`
  button[type='submit'] {
    height: 40px;
    line-height: 40px;
    padding: 0 36px;
  }
  &.custom .ant-modal-header {
    padding-top: 20px;
  }
  .form-save {
    background: #1a1e22;
    padding: 20px 0 10px;
    margin-bottom: 24px;
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-input {
      border: 1px solid #575757;
    }
  }
  .btn-group {
    display: flex;
    justify-content: center;
    .btn-save {
      width: 124px;
      border: 1px solid var(--main-color);
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
      h3 {
        font-size: 14px;
        color: var(--main-color);
        font-weight: normal;
        line-height: 30px;
        margin-bottom: 0;
        transition: all 0.1s;
      }
      p {
        line-height: 18px;
        background: #32373b;
        margin-bottom: 0;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
          color: #a1a1a1;
          transform: scale(0.92) translateY(-1px);
        }
        .icon {
          color: #fff;
          transform: translateY(0);
          font-size: 11px;
        }
      }
      &:hover {
        h3 {
          background: var(--main-button-bg-color);
          color: var(--main-button-text-color);
        }
      }
      &:nth-child(2) {
        margin-left: 25px;
      }
    }
  }
  .save-size {
    line-height: 32px;
    display: block;
  }
  .save-size-tips {
    font-size: 12px;
    color: var(--embellish-color-1);
    transform: translateY(-5px);
  }
  .save-tips-pop {
    font-size: 11px;
    color: #ffffff;
    letter-spacing: 0.32px;
    line-height: 17px;
    transform: scale(0.92);
    display: block;
  }
`
export default observer(SaveArchiveModal)
