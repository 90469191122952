import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom'
import { BaseModal } from 'src/components/base-modal'
import { LoginStore, ProtocolStore } from 'src/store/modifier'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { getWechatLoginCode, getWechatLoginStatus } from 'src/apis/user-api'
import { checkOversea } from 'src/tools/common'
import { useIntl } from 'react-intl'
import QRcode from 'qrcode.react'
import EmailLogin from 'src/components/login-modal/email-login'
import ForgetPw from 'src/components/login-modal/forget-pw'
// import PWLogin from 'src/components/login-modal/pw-login'
import EmailRegistry from 'src/components/login-modal/email-registry'
// import PWRegistry from 'src/components/login-modal/pw-registry'
import styled from 'styled-components'
import classNames from 'classnames'

import ComputerIcon from 'src/assets/icons/login/computer.svg'
import QrcodeIcon from 'src/assets/icons/login/qrcode.svg'
import singleLogoImg from 'src/assets/images/common/single-logo.png'


let timer: number | null = null

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const LoginModal: FC = () => {
  const [refreshCode, setRefreshCode] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [qrcodestring, setQrcodestring] = useState('')
  const [isWechatCode, setIsWechatCode] = useState(!checkOversea())
  const { formatMessage } = useIntl()

  const TABS = [{ title: formatMessage({id:'login.login'}) }, { title: formatMessage({id:'login.reg'}) }]

  const changeActiveKey = (key: typeof LoginStore.modalActiveKey) => {
    LoginStore.modalActiveKey = key
  }
  const wechatCodeLogin = (
    <StyledWechatCodeLoginContainer>
      <FadeinImage type='notDefault' src={singleLogoImg} />
      <div className='app-name'>MYBUFF</div>
      <div className='wechat-qrcode-desc'>请用微信扫描二维码登录/注册</div>
      <div className='wechat-qrcode-box'>
        <div className='wechat-qrcode-bg'>
          {qrcodestring && <FadeinImage type='notDefault' src={qrcodestring} style={{ width: 194, height: 194 }} />}
          {/* <QRcode size={174} value={qrcodestring} /> */}
          {/* <QRcode size={174} src={qrcodestring} /> */}
          {loading && (
            <div className='qrcode-mark'>
              <span>
                <Spin indicator={antIcon} />
              </span>
            </div>
          )}
          {refreshCode && (
            <div className='qrcode-mark' onClick={() => getWechatQrCode()}>
              请点击刷新
            </div>
          )}
        </div>
      </div>
      <div className='wechat-qrcode-protocol'>
        注册即代表同意并遵守
        <TextButton
          onClick={e => {
            e.preventDefault()
            ProtocolStore.openProtocolModal('zhuce')
          }}
        >
          《{ProtocolStore.protocolConfig['zhuce']?.title}》
        </TextButton>
      </div>
    </StyledWechatCodeLoginContainer>
  )
  const emailLogin = (
    <>
      <div className='tabs-row'>
        {LoginStore.forgetPWVisible && <div className={classNames('tabs-item', { active: true })}>{formatMessage({id:'login.forgetPwd'})}</div>}
        {!LoginStore.forgetPWVisible &&
          TABS.map(d => {
            return (
              <div
                className={classNames('tabs-item', { active: LoginStore.modalActiveKey === d.title })}
                key={d.title}
                onClick={() => changeActiveKey(d.title as typeof LoginStore.modalActiveKey)}
              >
                {d.title}
              </div>
            )
          })}
      </div>
      <div className='login-modal-content'>
        {!LoginStore.forgetPWVisible && LoginStore.modalActiveKey === formatMessage({id:'login.login'})  && <EmailLogin />}
        {!LoginStore.forgetPWVisible && LoginStore.modalActiveKey === formatMessage({id:'login.reg'})  && <EmailRegistry />}
        {LoginStore.forgetPWVisible && <ForgetPw />}
      </div>
    </>
  )
  /** 获取微信登录二维码 */
  const getWechatQrCode = async () => {
    try {
      setLoading(true)
      setQrcodestring('')
      const {
        result: { sceneId, url },
      } = await getWechatLoginCode()
      fetchWechatLoginStatus(sceneId, 90)
      unstableBatchedUpdates(() => {
        setRefreshCode(false)
        setQrcodestring(url)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
      unstableBatchedUpdates(() => {
        setRefreshCode(true)
        setLoading(false)
      })
      return Promise.reject(error)
    }
  }

  /** 查询微信登录状态 */
  const fetchWechatLoginStatus = (sceneId: string, count: number) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = window.setTimeout(async () => {
      if (count <= 0 || !sceneId) {
        return setRefreshCode(true)
      }
      try {
        const { result } = await getWechatLoginStatus({ sceneId })
        if (result) {
          await LoginStore.loginSuccess({ ...result, loginType: 'wechat' })
          LoginStore.cancelLoginModal()
          message.success('登录成功')
          // handleHideLogin()
          // loginSuccess(result)
        } else {
          fetchWechatLoginStatus(sceneId, --count)
        }
      } catch (error: any) {
        console.log('err', error)
        //拉黑用户，不用轮询
        if (error?.code === '110') {
          return
        }
        fetchWechatLoginStatus(sceneId, --count)
      }
    }, 1000)
  }

  useEffect(() => {
    if (LoginStore.modalVisible && isWechatCode) {
      getWechatQrCode()
    } else {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }
  }, [LoginStore.modalVisible, isWechatCode])

  return (
    <StyledLoginModalContainer
      placement='left'
      width={460}
      maskStyle={{ background: 'transparent' }}
      footer={null}
      onClose={() => LoginStore.cancelLoginModal()}
      forceRender={true}
      zIndex={1008}
      visible={LoginStore.modalVisible}
    >
      { !checkOversea() &&  <>
          <StyledSwitchLoginText onClick={() => setIsWechatCode(value => !value)} className="no-drag">{isWechatCode ? '账号密码登录' : '扫码快捷登录'}</StyledSwitchLoginText>
          <StyledSwitchLoginMethodContainer onClick={() => setIsWechatCode(value => !value)} className='no-drag'>
            {isWechatCode ? <ComputerIcon className='computer-icon' /> : <QrcodeIcon className='qrcode-icon' />}
          </StyledSwitchLoginMethodContainer>
      </>
    }
      <StyledFixedContainer>{isWechatCode ? wechatCodeLogin : emailLogin}</StyledFixedContainer>
    </StyledLoginModalContainer>
  )
}

const StyledSwitchLoginText = styled.div`
  position: absolute;
  top: 9px;
  right: 60px;
  width: 98px;
  height: 32px;
  background: rgba(195, 140, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 500;
  color: #c38cff;
  line-height: 16px;
  cursor: pointer;
  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border: 5px rgba(195, 140, 255, 0.3) solid;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }
`

const StyledWechatCodeLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .app-name {
    font-size: 24px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    margin-top: 12px;
  }
  .wechat-qrcode-desc {
    font-size: 20px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: #ffffff;
    line-height: 23px;
    margin-top: 62px;
  }
  .wechat-qrcode-box {
    width: 222px;
    height: 222px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    margin-top: 16px;
    padding: 14px;
    .wechat-qrcode-bg {
      width: 194px;
      height: 194px;
      background: #fff;
      position: relative;
      .qrcode-mark {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(4px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-color-FFFFFF);
        cursor: pointer;
      }
    }
  }
  .wechat-qrcode-protocol {
    font-size: 14px;
    font-family: Poppins-Light, Poppins;
    font-weight: 300;
    color: #bbbbbb;
    line-height: 14px;
    margin-top: 24px;
  }
`

const StyledSwitchLoginMethodContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.12);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  font-size: 40px;
  cursor: pointer;
  .computer-icon {
    position: absolute;
    top: 5px;
    right: 7px;
  }
  .qrcode-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const StyledFixedContainer = styled.div`
  position: absolute;
  top: 16%;
  width: 340px;
`

const StyledLoginModalContainer = styled(Drawer)`
  .ant-drawer-content {
    background: rgba(29, 28, 39, 0.9);
  }
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    backdrop-filter: blur(10px);
    position: relative;
    padding: 60px;
  }
  .tabs-row {
    display: flex;
    align-items: baseline;
    height: 40px;
    margin-bottom: 28px;
    .tabs-item {
      font-size: 20px;
      font-weight: bold;
      color: ${props => props.theme.color.text.$999999};
      line-height: 20px;
      cursor: pointer;
      margin-right: 24px;
      position: relative;
      &:hover {
        color: var(--text-color-FFFFFF);
      }
      &.active {
        font-size: 28px;
        font-weight: bold;
        color: var(--text-color-FFFFFF);
        line-height: 28px;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
`

// const StyledLoginModalContainer = styled(BaseModal)`
//   .ant-modal-body {
//     padding: 40px 40px 60px;
//   }
//   .login-modal-content {
//     margin-top: 24px;
//   }
//   .tabs-row {
//     display: flex;
//     align-items: baseline;
//     height: 40px;
//     .tabs-item {
//       font-size: 20px;
//       font-weight: bold;
//       color: ${props => props.theme.color.text.$999999};
//       line-height: 20px;
//       cursor: pointer;
//       margin-right: 24px;
//       position: relative;
//       &:hover {
//         color: var(--text-color-FFFFFF);
//       }
//       &.active {
//         font-size: 28px;
//         font-weight: bold;
//         color: var(--text-color-FFFFFF);
//         line-height: 28px;
//       }
//     }
//   }
// `

export default observer(LoginModal)
