import DEFAULTS from "./defaults";

const loop = () => {}

interface HearCheckOption extends NsWfSocket.BaseHeartOption{
  onCheck?: (instance: HeartCheck) => void;
  onTimeOut?: (instance: HeartCheck) => void;
}
class HeartCheck {
  heartTimer: number | null = null;
  serverTimer: number | null = null;
  timeOutTime: number = 0;
  option: HearCheckOption;
  constructor(option: HearCheckOption) {
    this.option = Object.assign({}, DEFAULTS.heartOption, option);
  }
  run() {
    this.stop();
    const {
      interval,
      checkInterval,
      delayInterval,
      maxTimeOutTime = 0,
      onCheck = loop,
      onTimeOut = loop
    } = this.option;

    if (maxTimeOutTime > 0) {
      this.heartTimer = window.setTimeout(() => {
        /** start check */
        onCheck(this)
        this.serverTimer = window.setTimeout(() => {
          /** 超时 */
          this.timeOutTime++;
          console.warn(`${this.timeOutTime} time timeout ! maxTimeOutTime : ${maxTimeOutTime}`);
          if (this.timeOutTime >= maxTimeOutTime) {
            onTimeOut(this)
          } else {
            this.run();
          }
        }, delayInterval || interval);
      }, checkInterval || interval);
    } else {
      console.warn(`maxTimeOutTime : ${maxTimeOutTime}`);
    }
  }
  stop() {
    this.timeOutTime = 0
    if (this.heartTimer) {
      window.clearTimeout(this.heartTimer);
      this.heartTimer = null;
    }
    if (this.serverTimer) {
      window.clearTimeout(this.serverTimer);
      this.serverTimer = null;
    }
  }
}

export default HeartCheck;
