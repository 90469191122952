import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { Form } from 'antd'
import { BaseInput } from 'src/components/base-input'
import { PW_REG_LOWER_LEVEL, PW_REG_MEDIUM_LEVEL } from 'src/constants/reg'
import type { FormInstance } from 'antd/es/form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'


type TPWVerification = {
  form: FormInstance<any>
  isConfirm?: boolean

  PWPlaceholder?: string
  PWEmptyMsg?: string
  PWErrorMsg?: string

  PWConfirmPlaceholder?: string
  PWConfirmEmptyMsg?: string
  PWConfirmErrorMsg?: string

}

const PWVerification: FC<TPWVerification> = ({
  // pw input 相关配置
  PWPlaceholder = '请填写当前密码',
  PWEmptyMsg = '请填写当前密码',
  PWErrorMsg = '请输入正确的当前密码',

  PWConfirmPlaceholder = '请重复输入密码',
  PWConfirmEmptyMsg = '请重复输入密码',
  PWConfirmErrorMsg = '请重复输入正确的密码',

  isConfirm,
  form
}) => {
  const { getFieldValue, validateFields } = form
  const { formatMessage } = useIntl()
  
  return (
    <StyledPhoneVerificationContainer>
      <Form.Item
        name='pw'
        validateFirst={true}
        rules={[
          // {
          //   async validator(rules, value, callback) {
          //     isConfirm && await validateFields(['pwConfirm'])
          //     callback()
          //   },
          // },
          { required: true, message: PWEmptyMsg },
          {
            pattern: PW_REG_MEDIUM_LEVEL,
            message: PWErrorMsg,
          },
        ]}
      >
        <BaseInput placeholder={PWPlaceholder} maxLen={16} />
      </Form.Item>
      {isConfirm && (
        <Form.Item
          name='pwConfirm'
          validateFirst={true}
          rules={[
            { required: true, message: PWConfirmEmptyMsg },
            {
              pattern: PW_REG_MEDIUM_LEVEL,
              message: PWConfirmErrorMsg,
            },
            {
              validator(rules, value, callback) {
                if (getFieldValue('pw') !== value) {
                  return callback(formatMessage({id:'change.pwd.notSame'}))
                }
                callback()
              },
            },
          ]}
        >
          <BaseInput placeholder={PWConfirmPlaceholder} maxLen={16} />
        </Form.Item>
      )}
    </StyledPhoneVerificationContainer>
  )
}
const StyledPhoneVerificationContainer = styled.div`
  .sg-base-input-warpper {
    width: 100%;
    height: 44px;
    border-radius: 8px;;
  }
  .verification-code-input {
    .sg-base-button-dark {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 118px;
      height: 36px;
    }
  }
`

export default observer(PWVerification)
