import React, { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import CompleteIcon from 'src/assets/icons/steps/complete.svg'
import styled from 'styled-components'
import classNames from 'classnames'

type TSteps = {
  current: number
  list: {text: string}[]
}

const Steps: FC<TSteps> = ({
  list,
  current
}) => {

  return (
    <StyledPhoneVerificationContainer>
      {list.map((d, i) => {
        const isComplete = current > i
        const isActive = current === i
        return (
          <div className={classNames('sg-step-box', {active: isActive, complete: isComplete})}>
            <div className='sg-step-content'>
              <span>{d.text}</span>
              <div className='sg-step-symbol'>
                {isComplete ? (
                  <CompleteIcon style={{marginTop: 1}} />
                ) : (
                  <div className='sg-step-dot' />
                )}
              </div> 
            </div>
          </div>
        )
      })}
    </StyledPhoneVerificationContainer>
  )
}

const StyledPhoneVerificationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .sg-step-box {
    flex: none;
    display: flex;
    align-items: center;
    position: relative;
    .sg-step-content {
      position: relative;
      width: 70px;
      text-align: center;
      font-size: 12px;
      line-height: 12px;
      color: ${props => props.theme.color.text.$999999};
    }
    .sg-step-symbol {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 9px;
      border-radius: 50%;
      .sg-step-dot {
        width: 8px;
        height: 8px;
        background: ${props => props.theme.color.bg.$4C4C50};
        border-radius: 50%;
      }
    }
    &:not(:last-child) {
      flex: 1;
      &::before {
        content: ' ';
        position: absolute;
        left: 49px;
        top: 30px;
        width: calc(100% - 28px);
        height: 1px;
        background: ${props => props.theme.color.bg.$4C4C50};
      }
    }
    &.complete,
    &.active {
      .sg-step-symbol {
        border: 1px solid ${props => props.theme.color.mainColor};
      }
      .sg-step-content {
        color: var(--text-color-FFFFFF);
      }
    }
    &.active {
      .sg-step-symbol {
        .sg-step-dot {
          width: 10px;
          height: 10px;
          background: ${props => props.theme.color.mainColor};
        }
      }
    }
  }
`

export default observer(Steps)
