import { observable } from 'mobx'

class Queue {
  @observable
  /** 是否在排队中    in: 正在排队中   stop: 没有排队   success: 排队成功 */
  queueStatus: 'in' | 'stop' | 'success' = 'stop'
  @observable
  /** 子任务状态 */
  subTaskStatus: 'none' | 'success' = 'none'
  @observable
  currentQueueInfo: GameQueuePayload | null = null
  @observable
  queueVisible:boolean=false
  @observable
  queueSuccessVisible:boolean=false
}

export const CloudQueueStore = new Queue()
