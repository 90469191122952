import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { vipGoodsListHttp } from 'src/apis/game-api'
import { useNavigate } from 'react-router-dom'
import { appStore, VipStore } from 'src/store'
import { $t } from 'src/locales'
import { Button, message } from 'antd'
import { BaseModalV3 } from 'src/components/base-modal'
import styled from 'styled-components'
import vipBg from 'src/assets/images/user-center/vipbg.png'
import p1 from 'src/assets/images/user-center/p1.png'
import p2 from 'src/assets/images/user-center/p2.png'
import p3 from 'src/assets/images/user-center/p3.png'
import MonthIcon from 'src/assets/images/user-center/month.svg'
import SeasonIcon from 'src/assets/images/user-center/season.svg'
import YearIcon from 'src/assets/images/user-center/year.svg'
import PayModal from 'src/components/pay-modal'
import SuccessIcon from 'src/assets/images/user-center/success.svg'

/** 根据商品时长(单位：h), 计算单价 */
const getUnitPrice = (price: number, duration: number) => {
  let p = (price / duration).toFixed(2)
  let unit = $t({id:'vip.hours'})
  let n = duration
  if (duration > 24) {
    // 天
    p = ((price / duration) * 24).toFixed(2)
    unit = $t({id:'vip.days'})
    n = Math.floor(duration / 24)
  }
  return { number: n, price: p, unit }
}

const VipIcons=[<MonthIcon/>, <SeasonIcon/>, <YearIcon/>]

const VipRecharge: FC = () => {
  const navigate = useNavigate()
  const [vipGoodsList, setVipGoodsList] = useState<Http.TVipGoods[]>([])
  const [currentActiveVipGoods, setCurrentActiveVipGoods] = useState<Http.TVipGoods>()
  const [buyVisible , setBuyVisible] = useState(false)
  const [successVisible, setSuccessVisible] = useState(false)

  const handleVipGoodsList = async () => {
    const { result } = await vipGoodsListHttp()
    const finalVipGoodsList = result.filter((f, i) => i < 3)
    setVipGoodsList(finalVipGoodsList)
    // let defaultIndex = null
    // if (appStore.vipgoodsRecommendConfig) {
    //   const findIndex = finalVipGoodsList.findIndex(f => f.id === appStore.vipgoodsRecommendConfig)
    //   defaultIndex = findIndex > -1 ? findIndex : null
    // }
    // if (!defaultIndex) {
    //   let min = finalVipGoodsList[0]?.salePrice || 0
    //   defaultIndex = 0
    //   finalVipGoodsList.forEach((f, i) => {
    //     if (f.salePrice < min) {
    //       defaultIndex = i
    //       min = f.salePrice
    //     }
    //   })
    // }
    /** 博文要求 默认选中权重最高的 也就是第一个 */
    finalVipGoodsList?.[0] && setCurrentActiveVipGoods(finalVipGoodsList[0])
  }

  const onSuccess = ()=>{
    setSuccessVisible(true)
    VipStore.handleLoadVipInfo()
  }

  const currentDesc=useMemo(()=>{
    if(!currentActiveVipGoods){
      return ''
    }
    const { unit, number } = getUnitPrice(currentActiveVipGoods.salePrice, currentActiveVipGoods.vipDuration)
    return  `${number} ${unit}`
  },[currentActiveVipGoods])

  useEffect(() => {
    handleVipGoodsList()
  }, [])

  useEffect(() => {
    VipStore.handleLoadVipInfo()
  }, [])

  return (
    <StyledVipRechargeBox>
    <StyledVipRechargeContainer>
      <h3>Unleash more fun with mybuff</h3>
      <p>Subscribe to MYBUFF，enjioy free access to over <span>2500+</span> games</p>

      <StyledGoods>
        {
          vipGoodsList.map((d,index) => {
            const { price, unit, number } = getUnitPrice(d.salePrice, d.vipDuration)
            return <li key={index} onClick={()=>{
              setCurrentActiveVipGoods(d)
              setBuyVisible(true)
            }}>
              <div className="title">{VipIcons[index]} {d.nameEn}</div>
              <div className="desc">only <strong>${price}</strong> per {unit===$t({id:'vip.hours'})?$t({id:'vip.hour'}):$t({id:'vip.day'})}</div>
              <div className="price">{d.currencySymbol ||'$'} {d.salePrice}</div>
              <div className="time">{number} {unit}</div>
              <div className="btn">Subscribe</div>
            </li>
          })
        }
      </StyledGoods>

      <StyledPrivilege>
        <h3>Members privilege</h3>

        <ul>
          <li>
            <img src={p1} alt=""/>
            <div className="info">
              <h4>Try thousand of games for free on pc</h4>
              <p>Free trial of popular games</p>
            </div>
          </li>
          <li>
            <img src={p2} alt=""/>
            <div className="info">
              <h4>Exclusive customer service</h4>
              <p>One-to-one exclusive game service</p>
            </div>
          </li>
          <li>
            <img src={p3} alt=""/>
            <div className="info">
              <h4>The game library updated regularly</h4>
              <p>New content added every week, free to play as soon as possible</p>
            </div>
          </li>
        </ul>

      </StyledPrivilege>

      <PayModal
        visible={buyVisible}
        goodsInfo={{
          goodsName: currentActiveVipGoods?.nameEn,
          desc: currentDesc,
          goodsId: currentActiveVipGoods?.id,
          price: currentActiveVipGoods?.salePrice
        }}
        currencySymbol={currentActiveVipGoods?.currencySymbol}
        onSuccess={onSuccess}
        onClose={()=>setBuyVisible(false)}
      />

    </StyledVipRechargeContainer>
      <StyledSuccessModal
        visible={successVisible}
        width={350}
        title={null}
        footer={[<Button type={'primary'} onClick={()=>{
          setSuccessVisible(false)
          appStore.userCenterVisible = false
          // navigate('/')
        }}>Play Now</Button> ]}
        onCancel={()=>setSuccessVisible(false)}
      >
        <StyledSuccess>
          <SuccessIcon/>
          <h3>Purchase</h3>
        </StyledSuccess>
      </StyledSuccessModal>
    </StyledVipRechargeBox>
  )
}

const StyledSuccessModal =styled(BaseModalV3)`
    .ant-modal-footer{
      justify-content: center;
      .ant-btn{
        width: 233px;
      }
    }
`

const StyledSuccess=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  >h3{
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 20px;
    margin-top: 24px;
  }
  >p{
    color: #c6c6c6;
    margin-bottom: 0;
  }
`


const StyledVipRechargeBox=styled.div`
  height: 100%;
  overflow: auto;
`

const StyledPrivilege = styled.div`
  >h3{
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-weight: bold;
  }
  >ul{
    >li{
      display: flex;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid rgba(78,80,88,0.48);
      &:last-child{
        border-bottom: none;
      }
      .info{
        margin-left: 24px;
        >h4{
          color: #fff;
          font-size: 20px;
          line-height: 24px;
        }
        >p{
          margin-bottom: 0;
          color: #96989d;
          line-height: 16px;
        }
      }
    }
  }

`

const StyledGoods = styled.ul`
  display: flex;
  margin-bottom: 60px;
  height: 290px;
  >li{
    --color1: #8E7BF8;
    --color2: rgba(142,123,248,0.2);
    --color3: linear-gradient(313deg, #202024 0%, #2E2951 100%);    
    --color4: #8E7BF8;    
    width: 206px;
    height: 290px;
    background: var(--color3);
    border-radius: 12px;
    border: 1px solid var(--color1);
    margin-right: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px; 
    cursor: pointer;
    
    &:hover{
      .btn{
        background: var(--color4);
        color: #000;
      }
    }
    
    &:nth-child(2){
      --color1: #52B0ED;
      --color2:  rgba(82,176,237,0.2);
      --color3: linear-gradient(313deg, #202024 0%, #233D54 100%);
      --color4: #52B0ED;
    }
    &:nth-child(3){
      --color1: #A0F223;
      --color2: rgba(160,242,35,0.2);
      --color3: linear-gradient(313deg, #202024 0%, #273712 100%);
      --color4: #A0F223;
    }
    
    &:last-child{
      margin-right: 0;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      display: flex;
      align-items: center;
      >svg{
        margin-right: 9px;
        font-size: 24px;
      }      
    }
    .desc{      
      font-size: 14px;
      line-height: 20px;
      color: #A3AEB6;
      margin-top: 13px;
      >strong{
        color: var(--color1);
      }
    }
    .price{
      margin-top: 30px;
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
    }
    .time{
      margin-top: 10px;
      line-height: 17px;
    }
    .btn{
      width: 140px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color2);
      border-radius: 8px;
      margin-top: 32px;
      color: var(--color1);
      transition: all .3s;
    }
  }
`

const StyledVipRechargeContainer = styled.div`
  background: url("${vipBg}") center top no-repeat;  
  color: #fff;
  padding:80px 24px;
  >h3{
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 16px;
    color: #fff;
    font-weight: bold;
  }
  >p{
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 99px;
    >span{
      color: #F1E044;
    }
  }
`

export default observer(VipRecharge)
