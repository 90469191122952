import { CLOUD_GAME_EXIT_TYPE } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import CEFTransfer from 'src/CEFTransfer'
import useCloudGameStart from 'src/pages/game-detail/cloud-game/cloud-game-action'

type listener = (data: any) => void

interface Subscriptions {
  [key: string]: listener[]
}

interface PostMessage {
  code: string
  group?: string
  [key: string]: any
}

type WindowController = WindowProxy | null

interface PostMessageControllerOption {
  postWin: WindowController
  postOrigin: string
  group?: string
}

export default class PostMessageController {
  postOrigin: string = ''
  postWin: WindowProxy | null = null
  subscriptions: Subscriptions = {}
  group = 'cloud'

  constructor() {
    CEFTransfer.subscribeClientMsg('recvCloudGameMsg', ({params}) => {
      try {
        const data= JSON.parse(params)
        console.log('recvCloudGameMsg', params)
        this.subscriptions[data.code]?.forEach?.(f => f(data))
      }catch (e){

      }
    })

    //云游戏窗口关闭
    CEFTransfer.subscribeClientMsg('recvCloudGameClosed',data=>{
      console.log('recvCloudGameClosed')
      //关闭云游戏
      const { stopCloudGame } = useCloudGameStart()
      stopCloudGame({ needRequest: true, reason: CLOUD_GAME_EXIT_TYPE.USER_OPTED_OUT.reason, needCloseCloudGameWindow:false })
    })
  }

  post(message: PostMessage) {
    try {
      const finalMessage = Object.assign({ group: this.group }, message)
      // this.postWin?.postMessage(finalMessage, this.postOrigin)
      CEFTransfer.sendToClient('sendCloudGameMsg', finalMessage)
    } catch (error) {
      console.warn(error)
    }
  }

  subscribe(code: string, listener: listener) {
    this.subscriptions[code] = (this.subscriptions[code] || []).filter(c => c !== listener).concat(listener)
  }
}
