import { baseFetch, persistentFetch } from 'src/fetch'
import { javaResultFilter } from 'src/fetch/resInterceptors'

/** 页面打开上报 */
export const clientOpenReport = baseFetch<Http.JavaApiResult<any>>({
  url: '/anon/client/open',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})
/** 启动steam++时上报 */
export const startSteamPPReport = baseFetch<Http.JavaApiResult<any>>({
  url: '/game-speed/anon/action/dot',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_ORIGIN_API_HOST,
  },
})

/** 获取上传文件证书 */
export const getUploadPolicy = baseFetch<Http.JavaApiResult<UploadPolicy>, { type: TUploadType }>({
  url: '/anon/uploadPolicy',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
  },
})

/** 获取服务端时间 */
export const getCurrentServerTime = baseFetch<Http.JavaApiResult<number>, { type: TUploadType }>({
  url: '/anon/currenttime',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
  },
})

/** 获取活动配置 */
export const getActivityConfig = baseFetch<
  Http.JavaApiResult<Http.IActivityConfig<{ benefitValidity: string; givingMemberDay: string }>>,
  { activityNo: string }
>({
  url: '/activity/config',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
  },
})

export const getExtendsByDownloadId = baseFetch<Http.JavaApiResult<string>, { downloadId: string }>({
  url: '/fulu-sgt-server/anon/user/download/log/getByDownloadId',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_ORIGIN_API_HOST,
  },
})
