import React, { FC, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react'
import { ImperativeConfirmModal } from 'src/components/base-modal'
import { CLOUD_GAME_EXIT_TYPE } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import styled from 'styled-components'
import useCloudGameStart from 'src/pages/game-detail/cloud-game/cloud-game-action'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'

const ConfirmExitCloudGame: FC = () => {
  const { stopCloudGame } = useCloudGameStart()

  const doExitCloudGame = () => {
    CloudGamePlayStore.visibleExitConfirmModal = false
    stopCloudGame({
      needRequest: true,
      reason: CLOUD_GAME_EXIT_TYPE.USER_OPTED_OUT.reason,
      needArchive:false,
      needFeedBack: true,
    })
  }

  return (
    <ImperativeConfirmModal options={ CloudGamePlayStore.visibleExitConfirmModal ? {
      title: '停止云游戏提示',
      content: <div>请确认您要结束本次游戏，确认后我们将直接关闭您正在运行的游戏进程</div>,
      key: 'confirm-modal-force-exit',
      okText: '确认',
      cancelText:'取消',
      btns: ['cancel', 'ok',],
      _ok: doExitCloudGame,
      _cancel:() => (CloudGamePlayStore.visibleExitConfirmModal = false),
    }:null } />
  )
}

const StyledModalInfoP = styled.p`
  margin: 0;
  font-size: 14px;
  color: #cbc5c5;
`

const StyledModalInfoItem = styled(StyledModalInfoP)`
  text-indent: 1em;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background: #cbc5c5;
    margin-right: 0.5em;
  }
`

export default observer(ConfirmExitCloudGame)
