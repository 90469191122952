import { observer } from 'mobx-react'
import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { appStore } from 'src/store'
import { useIntl } from 'react-intl'
import { checkOversea } from 'src/tools/common'
import classNames from 'classnames'
import SelfInfo from 'src/pages/user-center/self-info'
// import SelfAssets from 'src/pages-new/user-center/self-assets'
import SystemSetting from 'src/pages/user-center/system-setting'
import GiftKey from 'src/pages/user-center/cdk'
import VipRechargeEN from 'src/pages/vip-recharge/vip-en'
import VipRechargeCN from 'src/pages/vip-recharge'
import CloseIcon from 'src/assets/images/user-center/close.svg'
import styled from 'styled-components'
import DurationRecharge from 'src/pages/duration-recharge'
import CloudGameRecharge from 'src/pages/cloudgame-recharge'
import SelfAssets from 'src/pages/user-center/self-assets'

export enum NAV_KEY_MAPPING {
  SELF_INFO,
  SELF_ASSETS,
  SYSTEM_SETTING,
  CDK_EXCHANGE,
  VIP,
  DURATION_RECHARGE,
  CLOUDGAME_RECHARGE,
}

/**
 * 跳转个人中心页面
 * @param key 页面key
 * @param referer 来源
 */
export const showUserCenter = (key: NAV_KEY_MAPPING, referer: string = 'showUserCenter') => {
  appStore.userCenterActive = key
  appStore.userCenterVisible = true
  appStore.userCenterReferer = referer
}

const UserCenterModal =()=>{

  const {formatMessage} = useIntl()
  const isOversea = checkOversea()

  const NavMapping = [
    {
      key: NAV_KEY_MAPPING.SELF_INFO,
      Component: SelfInfo,
      // Icon: SelfInfoIcon,
      name: formatMessage({id:'userCenter.user'}),
    },
    ...(isOversea ? []: [
      {
        key: NAV_KEY_MAPPING.SELF_ASSETS,
        name: '用户资产',
        // Icon: SelfAssetsIcon,
        Component: SelfAssets,
      }
    ]),
    {
      key: NAV_KEY_MAPPING.SYSTEM_SETTING,
      name: formatMessage({id:'userCenter.sysSetting'}),
      Component: SystemSetting,
    },
    {
      key: NAV_KEY_MAPPING.CDK_EXCHANGE,
      name: formatMessage({id:'userCenter.cdk'}),
      Component: GiftKey,
    },
    {
      key: NAV_KEY_MAPPING.VIP,
      name: formatMessage({id:'userCenter.vip'}),
      Component: isOversea ? VipRechargeEN : VipRechargeCN,
    },
    {
      key: NAV_KEY_MAPPING.DURATION_RECHARGE,
      name: '加速器时长',
      Component: DurationRecharge,
      hidden:true
    },
    {
      key: NAV_KEY_MAPPING.CLOUDGAME_RECHARGE,
      name: '云游戏时长',
      Component: CloudGameRecharge,
      hidden:true
    },
  ]

  const onAfterClose=()=>{
    appStore.userCenterActive = null
  }

  useEffect(() => {
    if(appStore.userCenterVisible &&  !appStore.userCenterActive){
      appStore.userCenterActive = NavMapping[0].key
    }
  }, [appStore.userCenterVisible, appStore.userCenterActive])


  return <StyleUserCenterModal
    width='100%'
    visible={appStore.userCenterVisible}
    footer={null}
    keyboard={true}
    closable={true}
    afterClose={onAfterClose}
    onCancel={()=>{
      appStore.userCenterVisible = false
      appStore.userCenterReferer = ''
    }}
    transitionName=''
  >
    <StyledUserCenter>
      <StyledAside>
        <ul>
          {NavMapping.map(value => {
            if(value.hidden){
              return null
            }
            return <li
              onClick={()=> {
                appStore.userCenterActive = value.key
                appStore.userCenterReferer = ''
              }}
              key={value.key}
              className={classNames({
                active: value.key === appStore.userCenterActive ||
                  (value.key===NAV_KEY_MAPPING.SELF_ASSETS &&  appStore.userCenterActive &&
                    [NAV_KEY_MAPPING.DURATION_RECHARGE, NAV_KEY_MAPPING.CLOUDGAME_RECHARGE].includes(appStore.userCenterActive))
              })}
            >
              {value.name}
            </li>
          })}
        </ul>
      </StyledAside>
      <StyledRight>
        {NavMapping.map(d => {
          return d.key === appStore.userCenterActive && !!d.Component && <d.Component key={`component-${d.key}`} />
        })}
      </StyledRight>
      <StyledClose onClick={()=>appStore.userCenterVisible=false}>
        <div className="close"><CloseIcon /></div>
        <span>ESC</span>
      </StyledClose>
    </StyledUserCenter>
    </StyleUserCenterModal>

}

const StyledClose=styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(500px);
  color: #72767d;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: all .3s;
  .close{
    width: 36px;
    height: 36px;
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    border: 2px solid #72767D;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    transition: all .3s;
    >svg{
      color: #dcddde;
    }
  }
  &:hover{
    color: #dcddde;
    .close{
      border-color:#dcddde;
    }
  }
`

const StyledRight=styled.div`
  width: 708px;
  height: 100vh;
  overflow: auto;
  padding-top: 60px;
`

const StyledAside=styled.div`
  width: 192px;
  margin-right: 35px;
  padding-top: 60px;
  >ul{
    margin-bottom: 0;
    >li{
      height: 52px;
      line-height: 52px;
      margin-bottom: 4px;
      padding-left: 10px;
      color: #f2f3f5;
      font-size: 15px;
      border-radius: 4px;      
      cursor: pointer;
      &:hover,&.active{
        background: rgba(78,80,88,0.4);
      }
    }    
  }
`
const StyledUserCenter=styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 1;
  &:after{
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    background: #2B2D31;
    width: 100%;
    transform: translateX(-260px);
    z-index: -1;
  }
`

const StyleUserCenterModal=styled(Modal)`
  max-width: 100%;  
  top: 0;
  padding-bottom: 0;
  .ant-modal-close{
    display: none;
  }
  .ant-modal-content{
    background: #26282B;
  }
  .ant-modal-body{
    padding: 0;
  }
`


export default observer(UserCenterModal)

