
/** 兑换CDK， CDK正则 */
export const CDKReg = /^[0-9a-zA-Z]{5}-[0-9a-zA-Z]{5}-[0-9a-zA-Z]{5}$/

//姓名
export const NamePattern = /^(([a-zA-Z+\.?·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+·?\u4e00-\u9fa5+]{2,30}$))/

//身份证
export const CardIdPattern = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

// 邮箱
export const EMAIL_REG = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/

// 手机号
export const PHONE_REG = /^1\d{2}\s?\d{4}\s?\d{4}\s?$/

// 账号
export const ACCOUNT_REG = /^[a-zA-Z0-9]{4,16}$/

// 支持数字、字母、邮箱的账号
export const ACCOUNT_REG_LOWER_LEVEL = /^[0-9a-zA-Z@.]*$/

// 密码
export const PW_REG_LOWER_LEVEL = /^[a-zA-Z0-9]{4,16}$/

// 密码 必须包含一个字母和一个数字
export const PW_REG_MEDIUM_LEVEL = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,16}$/

// 客户端安装包 包名的正确格式    WeBuffSetup-g1_bdbuffth01_1.0.3@ (10).exe | WeBuffSetup_bdbuffth01_1.0.3@ (10).exe
export const CORRECT_PACKAGE_NAME = /^([0-9a-zA-Z]{1,})(-g[0-9]{1,})?_([0-9a-zA-Z]{1,})_([0-9]{1,}.[0-9]{1,}.[0-9]{1,})@([\s\S]*)@([\s\S]*).exe$/

// CDK
export const CDK_REG = /^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}$/

export const verifyEmailFormat = (email: any, callback: any, msg: string = '请输入正确的邮箱地址') => {
  if (!email) {
    return callback('请填写您作为登录账号的邮箱地址')
  }
  if (!EMAIL_REG.test(email)) {
    return callback(msg)
  }
  callback()
}