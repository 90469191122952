interface Handle<T, R, S> extends InterceptorOption<S, R> {
  handler: (r: T) => R | void;
}
interface InterceptorOption<S, R> {
  when?: (r: S, data: { raw: any; data: R }) => boolean;
}

class InterceptorManager<T, R, S> {
  handler: Handle<T, R, S> | null = null;
  use(handler: (r: T) => R | void, option?: InterceptorOption<S, R>) {
    this.handler = {
      handler: handler,
      when: option ? option.when : undefined,
    };
  }
  remove() {
    this.handler = null;
  }
}

export default InterceptorManager;
