import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { CloudQueueStore, ConfigStore, VipStore } from 'src/store'
import { LoginStore } from 'src/store/modifier'
import { Carousel, Tooltip } from 'antd'
import { fetchGameInfo, fetchThirdGameInfo } from 'src/apis/game-api'
import { TextButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { useNavigate } from 'react-router-dom'
import { useHandleGameStart } from 'src/hooks/use-handle-game-start'
import { FadeinImage } from 'src/components/base-image'
import { formatTimeLocal } from 'src/tools/helper'
import {
  StyledArchive,
  StyledBottom,
  StyledContent,
  StyledLeftContent,
  StyledLeftSectionBox,
  StyledMainButton,
  StyledMainButtonContent,
  StyledRightContent
} from 'src/pages/game-detail/trial'
import { StyledFQContent, StyledFQItem, StyledSectionBox, StyledSpeedBalanceBox } from 'src/pages/game-detail/speed'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import RemainingTime from 'src/components/remaining-time'
import QueueAnim from 'rc-queue-anim'
import styled from 'styled-components'
import Selector from 'src/components/base-selector'
import TipsIcon from 'src/assets/icons/common/tips.svg'
import ArrowsIcon from 'src/assets/icons/vip/arrows.svg'
import ArrowsHoverIcon from 'src/assets/icons/vip/arrows-hover.svg'
import CloudgameStartBtnIcon from 'src/assets/icons/my-game/play.svg'
import TagIcon from 'src/assets/icons/cloud-game/tag.svg'
import classNames from 'classnames'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import useQueue from 'src/hooks/use-queue'
import PauseBtnIcon from 'src/assets/icons/my-game/pause-btn.svg'
import LoadingIcon from 'src/assets/icons/my-game/spinner.svg'

type TBtnState = 'hover' | 'leave' | 'start' | 'speeding' | 'speeded' | 'normal'
type TDetailStatus = 'change-area-server'
const mocklist = ['云游戏模式介绍？', '什么是免账号模式？', '云游戏能使用 MOD 和 存档么？', '云游戏为什么有时候会很卡？']

enum AccountLoginMode {
  'free_login' = '免账号模式',
  'own_account_login' = '自有账号模式',
}

const CloudGame: FC<{
  cloudGame: Http.TCloudGameByGameBranch
  changeSearch: (state: Record<string, string>) => void
  gameLibraryName: string
  gamePlatformName: string
  branchId: number
  gameLibraryMasterDetail?: Game.GameLibraryMasterDetail
}> = ({ cloudGame, branchId, changeSearch, gameLibraryName, gameLibraryMasterDetail, gamePlatformName }) => {
  const navigate = useNavigate()
  const [startMethodValue, setStartMethodValue] = useState<number>(Number(ConfigStore.cloudGameAccountMode?.defaultMode ?? '0'))
  const [btnState, setBtnState] = useState<TBtnState>('normal')
  const [thirdInfo, setThirdInfo] = useState<Http.ThirdGameInfo>()
  const [gameInfo, setGameInfo] = useState<Http.GameInfo>()
  const [detailStatus, setDetailStatus] = useState<TDetailStatus>()

  const {handleStartGameByGameStartInfo} = useHandleGameStart()
  const {cancenQueue} = useQueue()

  const isLogin = !!LoginStore.userinfo

  const loadInitData = async (relateIden: string, speedGameNo: string) => {
    const [thirdInfo, gameInfo] = await Promise.all([
      fetchThirdGameInfo({ speedGameNo }),
      fetchGameInfo({ speedGameNo: relateIden }),
    ])
    setThirdInfo(thirdInfo.result)
    setGameInfo(gameInfo.result)
  }

  const handleStartCloudGame = async () => {
    //是否登录
    if (!LoginStore.userinfo) {
      return LoginStore.openLoginModal()
    }
    //启动云游戏
    await handleStartGameByGameStartInfo({
      gameStartInfo: {
        gameLibraryMasterId: gameLibraryMasterDetail?.gameLibraryMasterId,
        gameLibraryBranchId: branchId,
        gameName: gameLibraryName,
        accountLoginMode: cloudGame.accountLoginMode[startMethodValue],
        listImage:gameLibraryMasterDetail?.listImage,
        squareImage:gameLibraryMasterDetail?.squareImage,
        gameMode:'cloud',
        isSupportSpeed: cloudGame.isSupportSpeed,
        isSupportTrainer: cloudGame.isSupportTrainer,
        gamePlatformName,
      },
      // accountLoginMode: cloudGame.accountLoginMode[startMethodValue],
      queueMainId: ''
    })
  }

  const changeBtnState = (state: TBtnState) => {
    setTimeout(() => {
      setBtnState(state)
    }, 10)
  }

  // const handleStopSpeed = () => {
  //   imperativeConfirmModal({
  //     title: '停止加速提醒',
  //     content: '停止加速前请确认您已结束正在加速的游戏，直接停止可能造成您正在加速的游戏掉线！',
  //     cancelText: '取消',
  //     okText: '确认停止',
  //     onOk() {
  //       const speedInfo = SpeedStore.hasSpeeding()
  //       SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_STOP' })
  //       message.success('停止加速成功')
  //       if (
  //         speedInfo &&
  //         appStore.feedbackController.counter.speed === 0 &&
  //         appStore.feedbackController.visible === false
  //       ) {
  //         appStore.feedbackController.counter.speed = 1
  //         appStore.feedbackController.type = 'speed'
  //         appStore.feedbackController.visible = true
  //         appStore.feedbackController.context.platform = speedInfo.gamePlatformName || ''
  //         appStore.feedbackController.context.gameName = speedInfo.gameLibraryName || ''
  //         appStore.feedbackController.context.speedArea = speedInfo.speedAreaName || ''
  //         appStore.feedbackController.context.speedNode = speedInfo.speedNodeName || ''
  //         appStore.feedbackController.context.speedOperate = speedInfo.speedGameName || ''
  //       }
  //     },
  //   })
  // }

  const mainButtonProps = useMemo(() => {
    if (
      CloudGamePlayStore.currentTheStartingGameInfo?.gameMode==='cloud' &&
     ( (CloudGamePlayStore.gameRunStatus === 'starting' && CloudGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId === branchId) ||
      (CloudQueueStore.queueStatus==='success' && CloudQueueStore.currentQueueInfo?.gameStartInfo?.gameLibraryBranchId === branchId))
    ) {
      return {
        children: (
          <StyledMainButtonContent>
            <LoadingIcon className='icon-loading' />
            <span>启 动 中...</span>
          </StyledMainButtonContent>
        ),
        click: () => {},
        className: `start-btn-speeding`,
        disabled: true
      }
    }

    if (CloudGamePlayStore.gameRunStatus === 'running' && CloudGamePlayStore.currentTheRunningGameInfo?.gameLibraryBranchId === branchId &&
    CloudGamePlayStore.currentTheRunningGameInfo?.gameMode==='cloud') {
      return {
        children: (
          <StyledMainButtonContent>
            <PauseBtnIcon className='icon' />
            <span>结 束 游 戏</span>
          </StyledMainButtonContent>
        ),
        click: () => {
          console.log('结束游戏')
          CloudGamePlayStore.visibleExitConfirmModal = true
        },
        className: `start-btn-speeded`,
      }
    }

    if (CloudQueueStore.queueStatus === 'in' && CloudQueueStore.currentQueueInfo &&
      CloudQueueStore.currentQueueInfo.gameStartInfo?.gameLibraryBranchId === branchId &&
      CloudQueueStore.currentQueueInfo.gameStartInfo?.gameMode==='cloud'
    ) {
      return {
        children: (
          <StyledMainButtonContent>
            <LoadingIcon className='icon-loading' />
            <span>排队中...</span>
          </StyledMainButtonContent>
        ),
        click: () => {
          CloudQueueStore.queueVisible=true
        },
        className: `start-btn-speeding`,
      }
    }

    return {
      children: (
        <StyledMainButtonContent>
          <CloudgameStartBtnIcon className='icon' />
          <span>启 动 游 戏</span>
        </StyledMainButtonContent>
      ),
      click: () => handleStartCloudGame(),
      className: `start-btn-normal`,
    }
  }, [handleStartCloudGame, btnState, CloudGamePlayStore.gameRunStatus,CloudGamePlayStore.currentTheRunningGameInfo,
    CloudGamePlayStore.currentTheStartingGameInfo, branchId,CloudQueueStore.queueStatus, CloudQueueStore.currentQueueInfo])

  // useEffect(() => {
  //   if (currentRelateIden && currentSpeedGameNo) {
  //     loadInitData(currentRelateIden, currentSpeedGameNo)
  //   }
  // }, [currentRelateIden, currentSpeedGameNo])

  // useEffect(() => {
  //   if (gameInfo?.image) {
  //     appStore.layoutBg = gameInfo.image
  //     return () => {
  //       appStore.layoutBg = null
  //     }
  //   }
  // }, [gameInfo])

  const inProgress = useMemo(() => {
    return (CloudGamePlayStore.gameRunStatus === 'running' && CloudGamePlayStore.currentTheRunningGameInfo?.gameLibraryBranchId === branchId) ||
        (CloudGamePlayStore.gameRunStatus === 'starting' && CloudGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId === branchId)
  }, [branchId, CloudGamePlayStore.gameRunStatus, CloudGamePlayStore.currentTheRunningGameInfo, CloudGamePlayStore.currentTheStartingGameInfo])

  const faqList= useMemo(()=>{
    return ConfigStore.cloudGameFaq.filter((v)=>{
      return !v.goodsId || v.goodsId.split(',').includes(String(branchId))
    }).slice(0,4)
  },[ConfigStore.cloudGameFaq, branchId])


  useEffect(() => {
    if (isLogin) {
      /** 单纯的刷新一下用户的会员状态 */
      VipStore.handleLoadVipInfo()
    }
  }, [isLogin])

  //设置默认启动模式
  useEffect(() => {
    const gameInfo = CloudGamePlayStore.currentTheRunningGameInfo || CloudGamePlayStore.currentTheStartingGameInfo
    const setDefault=()=>{
      const index = Number(ConfigStore.cloudGameAccountMode?.defaultMode ?? '0')
      setStartMethodValue(cloudGame.accountLoginMode?.[index] ? index : 0)
    }
    if (!cloudGame || gameInfo?.gameLibraryBranchId !== branchId) {
      //设置默认模式
      setDefault()
      return
    }
    const index = cloudGame.accountLoginMode.findIndex(v => v === gameInfo.accountLoginMode)
    if (index > -1) {
      setStartMethodValue(index)
      return
    }
    //设置默认模式
    setDefault()
  }, [CloudGamePlayStore.currentTheStartingGameInfo, CloudGamePlayStore.currentTheRunningGameInfo, cloudGame, ConfigStore.cloudGameAccountMode, branchId])

  return (
    <StyledCloudGameContainer>
      <StyledContent>
        <StyledLeftContent>
          <StyledLeftSectionBox>
            <div className='title'>游戏简介</div>
            <div className='content'>
              {cloudGame?.introduction||'暂无简介'}
            </div>
          </StyledLeftSectionBox>
          <StyledLeftSectionBox>
            <div className='title'>游戏标签</div>
            <div className='content'>
              <StyledTags>
                {
                  cloudGame?.tag?.split(',').map((v,i)=>{
                    return <div className="item" key={i}>
                      <TagIcon/>{v}
                    </div>
                  })
                }
              </StyledTags>
            </div>
          </StyledLeftSectionBox>
          <StyledLeftSectionBox>
            <div className='title long'>游戏图片/视频</div>
            <div className='content'>
              {cloudGame?.detailImg?.length>0 && <StyledCarousel autoplay={true}>
                {
                  cloudGame?.detailImg?.map((v, i) => {
                    return <StyledCarouselItem key={i}>
                      {
                        v.type === 'video' ?
                          <video src={v.url} poster={v.shortImg} controls={true}></video> :
                          <FadeinImage src={v.url} width="100%" height="100%"/>
                      }
                    </StyledCarouselItem>
                  })
                }
              </StyledCarousel>}
            </div>
          </StyledLeftSectionBox>
        </StyledLeftContent>
        <QueueAnim component={StyledRightContent}>
          <StyledSectionBox key='StyledSectionBox-1'>
              <div className='title'>
                可用云游戏时长
                <Tooltip
                  color={'rgb(45, 45, 45)'}
                  overlayInnerStyle={{
                    borderRadius: '4px',
                    width: 300,
                  }}
                  overlay={
                    <StyledVipFreeText>
                      <div className='vip-free-text'>
                        <TextButton onClick={() => showUserCenter(NAV_KEY_MAPPING.VIP)}>MyBuff会员</TextButton>
                        每周可获赠{formatTimeLocal(VipStore.cloudGameGiveTime?.vipUserGiveTime??0)}云游戏时长
                      </div>
                    </StyledVipFreeText>
                  }
                  placement='bottom'
                >
                  <TipsIcon />
                </Tooltip>
              </div>
              <div className='content'>
                <StyledSpeedBalanceBox>
                  <div className='balance'>
                    <RemainingTime timeType='cloudgame' />
                  </div>
                </StyledSpeedBalanceBox>
              </div>

            <div
              className='buy-btn'
              onClick={() => showUserCenter(NAV_KEY_MAPPING.CLOUDGAME_RECHARGE)}
            >
              充值时长
              <ArrowsIcon className='arrows-icon' />
              <ArrowsHoverIcon className='arrows-icon arrows-hover-icon' />
            </div>
          </StyledSectionBox>
          <StyledSectionBox
            key='StyledSectionBox-2'
          >
            <div className='title' style={{ lineHeight: '22px' }}>
              常见问题
              <div className='title-extra'>
                <TextButton onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>查看更多</TextButton>
              </div>
            </div>
            <div className='content'>
              <StyledFQContent>
                {faqList.map((d, i) => {
                  return (
                    <StyledFQItem key={i} onClick={() => cefOpenUrl(d.url)}>
                      <span>{d.title}</span>
                    </StyledFQItem>
                  )
                })}
              </StyledFQContent>
            </div>
          </StyledSectionBox>
        </QueueAnim>
      </StyledContent>
      <StyledBottom>
        <StyledAccountType>
          <label>启动模式选择</label>
          <Selector disabled={cloudGame.accountLoginMode?.length<=1 || inProgress} options={cloudGame.accountLoginMode.map(d => AccountLoginMode[d])} value={startMethodValue} onChange={setStartMethodValue} />
        </StyledAccountType>

        <StyledMainButton
          onClick={mainButtonProps.click}
          className={classNames(mainButtonProps.className,{
            disabled:mainButtonProps.disabled
          })}
        >
          {mainButtonProps.children}
        </StyledMainButton>
      </StyledBottom>
    </StyledCloudGameContainer>
  )
}

const StyledAccountType=styled(StyledArchive)`
  .ant-select{
    width: 338px;
    &:not(.ant-select-customize-input) .ant-select-selector{
      background: #25282C !important;
      height: 32px;
      border: none !important;
      .ant-select-selection-item{
        line-height: 32px;
        font-size: 13px;
      }     
    }
    .ant-select-arrow{
      font-size: 13px;
      width: auto;
      height: auto;
      color: #fff !important;
    }
  }
  
`

const StyledCarouselItem=styled.div`
  aspect-ratio:16/9;
  video{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const StyledCarousel=styled(Carousel)`
  aspect-ratio:16/9;
  border-radius: 8px;
  overflow: hidden;
  .slick-dots li{
    width: 10px;
    height: 10px;
    &.slick-active{
      width: 10px;
    }
    button{
      height: 100%;
      border-radius: 50%;      
    }
  }
`

const StyledTags=styled.div`
  display: flex;
  flex-wrap: wrap;
  .item{
    display: flex;
    align-items: center;
    margin-right: 16px;
    svg{
      margin-right: 4px;
    }
  }
`

const StyledSectionFlexBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  margin-bottom: 24px;
  .buy-btn {
    ${props => props.theme.flexCenter}
    position: relative;
    width: 100px;
    height: 35px;
    background: rgba(195, 140, 255, 0.12);
    border-radius: 35px;
    font-size: 14px;
    color: var(--text-color-FFFFFF);
    padding-right: 10px;
    cursor: pointer;
    .arrows-icon {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      margin-left: 14px;
      &.arrows-hover-icon {
        opacity: 0;
        transition: right 300ms ease 0s;
      }
    }
    &:hover {
      color: ${props => props.theme.color.mainColor};
      .arrows-icon {
        margin-left: 14px;
        opacity: 0;
        &.arrows-hover-icon {
          opacity: 1;
          right: 10px;
        }
      }
    }
  }
`

const StyledVipFreeText = styled.div`
  .main {
    color: ${props => props.theme.color.mainColor};
  }
`

export const StyledCloudGameContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledCloudGameContainer = styled.div`
  height: 100%;
`

export default observer(CloudGame)
