import { observable, action } from 'mobx'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'

type TTrainerExe = Record<string, string>

// 客户端维度的本地缓存
class ClientLocalStorageStore {
  @observable
  // 上次启动成功的进程名的缓存
  trainerExe: TTrainerExe | null = localStorageTool.get(LOCALSTORAGE_KEYS.TRAINER_EXE)

  @action
  setTrainerExe = async (id: number, value: string) => {
    if (this.trainerExe === null) {
      this.trainerExe = {}
    }
    this.trainerExe[id] = value
    localStorageTool.set(LOCALSTORAGE_KEYS.TRAINER_EXE, this.trainerExe)
  }

  getTrainerExe = (id: number) => {
    return this.trainerExe ? this.trainerExe[id] : null
  }

  // 热门游戏缓存
  @observable
  hotgames: number[] | null = localStorageTool.get(LOCALSTORAGE_KEYS.HOTGAMES)

  @action
  setHotgames = async (list: number[]) => {
    this.hotgames = list
    localStorageTool.set(LOCALSTORAGE_KEYS.HOTGAMES, this.hotgames)
  }

  getHotgames = (id: number) => {
    return this.hotgames
  }

  // 最近启动的游戏id缓存
  @observable
  latelyLaunchGameIds: number[] | null = localStorageTool.get(LOCALSTORAGE_KEYS.LATELY_LAUNCH_GAME_IDS)

  @action
  setLatelyLaunchGameIds = async (id: number) => {
    const temp = new Set(this.latelyLaunchGameIds || [])
    if (temp.has(id)) {
      temp.delete(id)
    }
    temp.add(id)
    this.latelyLaunchGameIds = [...temp]
    localStorageTool.set(LOCALSTORAGE_KEYS.LATELY_LAUNCH_GAME_IDS, this.latelyLaunchGameIds)
  }

  getLatelyLaunchGameIds = () => {
    return this.latelyLaunchGameIds || []
  }

  // 最近启动的修改器游戏id缓存
  @observable
  recordInstallModifierGameIds: number[] | null = localStorageTool.get(
    LOCALSTORAGE_KEYS.RECORD_INSTALL_MODIFIER_GAME_IDS,
  )

  @action
  setRecordInstallModifierGameIds = async (ids: number[]) => {
    this.recordInstallModifierGameIds = ids
    localStorageTool.set(LOCALSTORAGE_KEYS.RECORD_INSTALL_MODIFIER_GAME_IDS, this.recordInstallModifierGameIds)
  }

  getRecordInstallModifierGameIds = () => {
    // 这里返回null 是用于判断， 是否为 第一次扫描
    // 为null 就代表之前没有进行过扫描已安装的游戏
    return this.recordInstallModifierGameIds
  }

  // 最近启动的游戏id缓存
  @observable
  recordInstallGameIds: number[] | null = localStorageTool.get(LOCALSTORAGE_KEYS.RECORD_INSTALL_GAME_IDS)

  @action
  setRecordInstallGameIds = async (ids: number[]) => {
    this.recordInstallGameIds = ids
    localStorageTool.set(LOCALSTORAGE_KEYS.RECORD_INSTALL_GAME_IDS, this.recordInstallGameIds)
  }

  getRecordInstallGameIds = () => {
    // 这里返回null 是用于判断， 是否为 第一次扫描
    // 为null 就代表之前没有进行过扫描已安装的游戏
    return this.recordInstallGameIds
  }

  // 最近启动的平台id缓存
  @observable
  recordInstallPlatformIds: number[] | null = localStorageTool.get(LOCALSTORAGE_KEYS.RECORD_INSTALL_PLATFORM_IDS)

  @action
  setRecordInstallPlatformIds = async (ids: number[]) => {
    this.recordInstallPlatformIds = ids
    localStorageTool.set(LOCALSTORAGE_KEYS.RECORD_INSTALL_PLATFORM_IDS, this.recordInstallPlatformIds)
  }

  getRecordInstallPlatformIds = () => {
    // 这里返回null 是用于判断， 是否为 第一次扫描
    // 为null 就代表之前没有进行过扫描已安装的平台
    return this.recordInstallPlatformIds
  }

  // 免责协议缓存
  @observable
  holdHarmlessProtocolCache: '1' | null = localStorageTool.get(LOCALSTORAGE_KEYS.HOLD_HARMLESS_PROTOCOL_CACHE)

  @action
  setHoldHarmlessProtocolCache = async (value: '1' | null) => {
    this.holdHarmlessProtocolCache = value
    localStorageTool.set(LOCALSTORAGE_KEYS.HOLD_HARMLESS_PROTOCOL_CACHE, value)
  }

  getHoldHarmlessProtocolCache = () => {
    return !!this.holdHarmlessProtocolCache
  }

  // 不再提示提前阅读notes的游戏id
  @observable
  notTipsPrereadGameIds: number[] | null = localStorageTool.get(LOCALSTORAGE_KEYS.NOT_TIPS_PREREAD_GAME_IDS)

  @action
  setNotTipsPrereadGameIds = async (id: number) => {
    const temp = this.notTipsPrereadGameIds || []
    if (temp.includes(id)) {
      return
    }
    temp.push(id)
    this.notTipsPrereadGameIds = temp
    localStorageTool.set(LOCALSTORAGE_KEYS.NOT_TIPS_PREREAD_GAME_IDS, this.notTipsPrereadGameIds)
  }

  getNotTipsPrereadGameIds = () => {
    return this.notTipsPrereadGameIds || []
  }

  // 是否第一次打开
  @observable
  notFirstOpen: '1' | null = localStorageTool.get(LOCALSTORAGE_KEYS.NOT_FIRST_OPEN)

  @action
  setNotFirstOpen = async (value: '1' | null) => {
    this.notFirstOpen = value
    localStorageTool.set(LOCALSTORAGE_KEYS.NOT_FIRST_OPEN, value)
  }

  getIsFirstOpen = () => {
    return !this.notFirstOpen
  }

  // semid
  @observable
  semId: string | null = localStorageTool.get(LOCALSTORAGE_KEYS.SEM_ID)

  @action
  setSemId = async (value: string | null) => {
    this.semId = value
    localStorageTool.set(LOCALSTORAGE_KEYS.SEM_ID, value)
  }

  getSemId = () => {
    return this.semId || ''
  }

  // 系统设置 开机自动启动
  @observable
  systemAutoOpen: boolean | null = localStorageTool.get(LOCALSTORAGE_KEYS.STSTEM_AUTO_OPEN)

  @action
  setSystemAutoOpen = async (systemAutoOpen: boolean) => {
    this.systemAutoOpen = systemAutoOpen
    localStorageTool.set(LOCALSTORAGE_KEYS.STSTEM_AUTO_OPEN, this.systemAutoOpen)
  }

  getSystemAutoOpen = (): boolean => {
    return !!this.systemAutoOpen
  }

  // 系统设置 加速器设置
  @observable
  systemAutoStop: boolean | null = localStorageTool.get(LOCALSTORAGE_KEYS.STSTEM_AUTO_STOP)

  @action
  setSystemAutoStop = async (systemAutoStop: boolean) => {
    this.systemAutoStop = systemAutoStop
    localStorageTool.set(LOCALSTORAGE_KEYS.STSTEM_AUTO_STOP, this.systemAutoStop)
  }

  getSystemAutoStop = (): boolean => {
    return this.systemAutoStop === null ? true : !!this.systemAutoStop
  }

  // 系统设置 资源下载目录
  @observable
  systemResourceFilePath: string | null = localStorageTool.get(LOCALSTORAGE_KEYS.STSTEM_RESOURCE_FILE_PATH)

  @action
  setSystemResourceFilePath = async (resourceFilePath: string | null) => {
    this.systemResourceFilePath = resourceFilePath
    localStorageTool.set(LOCALSTORAGE_KEYS.STSTEM_RESOURCE_FILE_PATH, this.systemResourceFilePath)
  }

  getSystemResourceFilePath = (): string => {
    return this.systemResourceFilePath || ''
  }

  // 系统弹框是否展示
  @observable
  systemModalVisible: boolean = false

  // 系统弹框是否展示
  @observable
  systemModalType: 'close' | 'normal' = 'normal'

  // 系统弹框缓存
  @observable
  systemConfig: Modifier.TSystemConfig | null = localStorageTool.get(LOCALSTORAGE_KEYS.SYSTEM_CONFIG)

  @action
  setSystemConfig = async (systemConfig: Modifier.TSystemConfig) => {
    this.systemConfig = systemConfig
    localStorageTool.set(LOCALSTORAGE_KEYS.SYSTEM_CONFIG, this.systemConfig)
  }

  getSystemConfig = (): Modifier.TSystemConfig => {
    return (
      this.systemConfig || {
        windowClose: '1',
        windowCloseTips: true,
      }
    )
  }
  @observable
  reloadPageStatusFnArr: Function[] = []
}

export default new ClientLocalStorageStore()
