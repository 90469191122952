import React, { FC, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import styled from 'styled-components'

const NotFound: FC<{ img: string;
  type: 'platform' | 'game';
  tool?:string;
  gameName?:string
  platformName?:string
  platformIcon?:string
}> = ({ img, type,tool,gameName, platformName, platformIcon }) => {
  return (
    <StyledDetailContainer>
      <FadeinImage width={236} height={110} src={img} style={{ borderRadius: 4, overflow: 'hidden' }} />
      <div className='info'>
        <div className='title'>
          MYBUFF <span className='main'>暂未提供</span> {
          tool ? <>
              <FadeinImage width={16} height={16} src={platformIcon} style={{display:'inline-block'}} /> <span className='main'>{platformName} - {gameName}</span> 的 <span className='main'>{tool}</span>
            </>:
            <>
            针对此{type === 'platform' ? '平台' : '游戏'}的任何工具/服务
            </>
        }
        </div>
        <div className='text'>
          如果针对此游戏您有第三方工具或服务诉求，欢迎前往{' '}
          <TextButton onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>MYBUFF论坛</TextButton>{' '}
          进行反馈
        </div>
      </div>
    </StyledDetailContainer>
  )
}

const StyledDetailContainer = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  padding-top: 120px;
  // ${props => props.theme.flexAlignCenter}
  .info {
    margin-left: 32px;
    .title {
      font-size: 20px;
      font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.76);
      line-height: 23px;
      .main {
        color: var(--text-color-FFFFFF);
      }
    }
    .text {
      font-size: 16px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 19px;
      margin-top: 20px;
    }
  }
`

export default observer(NotFound)
