import React, { FC } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const NotFound: FC = () => {
  return <StyledNotFoundView>NotFound</StyledNotFoundView>
}

export default observer(NotFound)

const StyledNotFoundView = styled.div``
