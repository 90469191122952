import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import { appStore } from 'src/store'
import zhCNMessage from 'src/locales/zh-CN'
import enUSMessage from 'src/locales/en-US'

export const messageMap = {
  'zh-CN': zhCNMessage,
  'en-US': enUSMessage
}

class IntlLocale {
  intl: IntlShape

  constructor(locale: TLanguageCode) {
    this.configIntl = this.configIntl.bind(this)
    this.$t = this.$t.bind(this)
    this.intl = this.configIntl(locale)
  }

  $t({id}: { id: string }, values = {}) {
    return this.intl.formatMessage({id}, values)
  }

  configIntl(locale: TLanguageCode) {
    const cache = createIntlCache()
    const messages = messageMap[locale] || {}
    this.intl = createIntl({
      locale: locale,
      messages
    }, cache)
    return this.intl
  }
}

const intlLocale = new IntlLocale(appStore.languageCode)

export const $t = intlLocale.$t

export default intlLocale