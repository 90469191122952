import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModalV2 } from 'src/components/base-modal'
import { ClientLocalStorageStore } from 'src/store/modifier'
// import { handleMinimize, handleQuit } from 'src/tools/common'
import { ConfirmButton, CancelButton } from 'src/components/base-button'
import { handleMiniClient } from 'src/CEFTransfer/cefController'
import { requestExitApp } from 'src/tools/speed'
import WeBuffCheckbox from 'src/components/base-checkbox'
import styled from 'styled-components'
import classNames from 'classnames'

const SystemModal: FC = () => {
  const [windowClose, setWindowClose] = useState<Modifier.TSystemConfig['windowClose']>('1')
  const [windowCloseTips, setWindowCloseTips] = useState<Modifier.TSystemConfig['windowCloseTips']>(true)
  const cancel = () => {
    const currentSystemConfig = ClientLocalStorageStore.getSystemConfig()
    ClientLocalStorageStore.systemModalVisible = false
    setWindowClose(currentSystemConfig.windowClose)
    setWindowCloseTips(currentSystemConfig.windowCloseTips)
  }
  const ok = () => {
    ClientLocalStorageStore.systemModalVisible = false
    ClientLocalStorageStore.setSystemConfig({
      windowClose,
      windowCloseTips,
    })
    setTimeout(() => {
      if (ClientLocalStorageStore.systemModalType === 'close') {
        if (windowClose === '1') {
          handleMiniClient()
        } else {
          requestExitApp()
        }
      }
    }, 100)
  }

  useEffect(() => {
    if (ClientLocalStorageStore.systemModalVisible) {
      const currentSystemConfig = ClientLocalStorageStore.getSystemConfig()
      setWindowClose(currentSystemConfig.windowClose)
      setWindowCloseTips(currentSystemConfig.windowCloseTips)
    }
  }, [ClientLocalStorageStore.systemModalVisible])

  return (
    <StyledSystemModalContainer
      width={450}
      visible={ClientLocalStorageStore.systemModalVisible}
      onCancel={() => cancel()}
    >
      <div className='system-config-title'>系统设置</div>
      <div className='system-config-windowclose-tips'>
        <WeBuffCheckbox checked={!windowCloseTips} onChange={e => setWindowCloseTips(!e.target.checked)}>
          不再提示
        </WeBuffCheckbox>
      </div>
      <div className='system-config-item-box'>
        <div className='system-config-item-title'>当关闭窗口时：</div>
        <div className='system-config-item-value'>
          <WeBuffCheckbox shape='circle' checked={windowClose === '1'} onClick={() => setWindowClose('1')}>
            最小化窗体
          </WeBuffCheckbox>
          <WeBuffCheckbox shape='circle' checked={windowClose === '2'} onClick={() => setWindowClose('2')}>
            退出主程序
          </WeBuffCheckbox>
        </div>
      </div>
      <div className='system-config-btns'>
        <CancelButton className='system-config-btn' onClick={cancel}>
          取消
        </CancelButton>
        <ConfirmButton className='system-config-btn' onClick={ok}>
          {ClientLocalStorageStore.systemModalType === 'normal' ? '我知道了' : '确定'}
        </ConfirmButton>
      </div>
    </StyledSystemModalContainer>
  )
}

const StyledSystemModalContainer = styled(BaseModalV2)`
  .ant-modal-body {
    padding: 30px 40px;
  }
  .system-config-title {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    margin-bottom: 40px;
  }
  .system-config-windowclose-tips {
    position: absolute;
    top: 30px;
    right: 40px;

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
    .ant-checkbox {
      top: 0;
    }
    .ant-checkbox + span {
      font-size: 12px;
      line-height: 12px;
    }
    .ant-checkbox-inner {
      width: 14px;
      height: 14px;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 2px;
      top: 5px;
    }
  }
  .system-config-item-box {
    display: flex;
    align-items: center;
    .system-config-item-title {
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$999999};
      margin-right: 30px;
    }
  }
  .system-config-btns {
    margin-top: 30px;
    display: flex;
    .system-config-btn {
      width: 100px;
      height: 30px;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
`

export default observer(SystemModal)
