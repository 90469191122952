import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseInput } from 'src/components/base-input'
import AddIcon from 'src/assets/icons/number-selector/add.svg'
import SubIcon from 'src/assets/icons/number-selector/sub.svg'
import styled from 'styled-components'
import classNames from 'classnames'

type TNumberSelector = {
  value: number
  onChange: (type: 'add' | 'sub' | 'direct', value?: number) => void 
  max: number
  min: number
  hasSet: boolean
  disabled: boolean
}

const NumberSelector: FC<TNumberSelector> = ({value, onChange, max, min, hasSet, disabled}) => {
  const [isInput, setIsInput] = useState<boolean>(false)
  const [currentInputValue, setCurrentInputValue] = useState<string>('')
  const handleChange = (type: 'add' | 'sub') => {
    onChange(type)
  }

  const handleInput = (value: string) => {
    let finalValue: string | number = value
    if (!finalValue) {
      setCurrentInputValue(`${min}`)
      return
    }
    finalValue = parseInt(finalValue)
    if (isNaN(finalValue)) {
      return
    }

    if (finalValue < min) {
      setCurrentInputValue(`${min}`)
      return
    }

    if (finalValue > max) {
      setCurrentInputValue(`${max}`)
      return
    }

    finalValue = `${finalValue}`.trim()
    setCurrentInputValue(finalValue)
  }

  const handleInputComplete = () => {
    if (disabled) {
      return
    }
    if (!isInput) {
      setIsInput(true)
      setCurrentInputValue(`${value}`)
      return
    }
    onChange('direct', Number(currentInputValue))
    setCurrentInputValue('')
    setIsInput(false)
  }

  useEffect(() => {
    // 当前value被其他方式修改了 则直接隐藏input 展示真实的数值
    setIsInput(false)
    setCurrentInputValue('')
  }, [value])

  return (
    <StyledNumberSelectorContainer>
      <AddIcon className={classNames('sg-number-selector-btn', { disabled: true })} onClick={() => handleChange('sub')} />
      {isInput && hasSet ? (
        <BaseInput value={currentInputValue} onChange={e => handleInput(e.target.value)} className='sg-number-selector-input' styled='edit' />
      ) : (
        <div className='sg-number-selector-value' style={{width: hasSet ? 68 : 100}}>{value}</div>
      )}
      <SubIcon className={classNames('sg-number-selector-btn', { disabled: false })} onClick={() => handleChange('add')} />
      {hasSet && (<div className='sg-number-selector-text-btn' onClick={() => handleInputComplete()}>{isInput ? '保存' : '设置'}</div>)}
    </StyledNumberSelectorContainer>
  )
}

const StyledNumberSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  .sg-number-selector-input {
    width: 48px;
    height: 26px;
    margin: 0 10px;
    .sg-base-input {
      padding: 0 4px;
      text-align: center;
    }
  }
  .sg-number-selector-btn {
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
  }
  .sg-number-selector-text-btn {
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.color.text.$C598FF};
    margin-left: 12px;
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
  }
  .sg-number-selector-value {
    font-size: 14px;
    line-height: 14px;
    color: var(--text-color-FFFFFF);
    text-align: center;
    padding: 0 8px;
  }
`

export default observer(NumberSelector)
