import React, { FC, useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Input } from 'antd'
import styled from 'styled-components'
import classNames from 'classnames'
// import ClearIcon from 'src/assets/icon/layout-header/clearIcon.svg'

type TSearchInput = {
  value?: string
  onChange?: (value: string) => void
  onSearch?: () => void
  addonBefore?: React.ReactNode
  placeholder?: string
  className?: string
  onLoaded?: (dom: any) => void
}

export const SearchInput: FC<TSearchInput> = observer(
  ({ value, onChange, onSearch, addonBefore, placeholder, className, onLoaded }) => {
    const ref = useRef(null)
    const [bgColor, setBgColor] = useState('rgba(255,255,255,0.12)')
    useEffect(() => {
      if (ref.current && onLoaded) {
        onLoaded(ref.current)
      }
    }, [])
    return (
      <StyledSearchInput
        ref={ref}
        onFocus={() => setBgColor('rgba(195,140,255,0.16)')}
        onBlur={() => setBgColor('rgba(255,255,255,0.12)')}
        bgColor={bgColor}
        className={classNames('no-drag', className)}
        placeholder={placeholder || ''}
        allowClear
        value={value}
        onChange={e => onChange?.(e.target.value)}
        onPressEnter={onSearch}
        addonBefore={addonBefore}
      />
    )
  },
)

const StyledSearchInput = styled(Input)<{ bgColor: string }>`
  width: 452px;
  height: 36px;
  padding: 0;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none;
    box-shadow: none;
  }
  &.ant-input-affix-wrapper {
    border: none;
    box-shadow: none;
    background-color: ${props => props.bgColor};
    border-radius: 4px;
    padding-left: 16px;
    .ant-input-suffix {
      margin: 0 4px;
      .anticon.ant-input-clear-icon {
        color: ${props => props.theme.color.text.$BBBBBB};
      }
    }
  }

  .ant-input-group-addon {
    background: ${props => props.bgColor};
    border-radius: 8px 0 0 8px;
    border: none;
    border-right: none;
    padding: 0;
    color: ${props => props.theme.color.text.$BBBBBB};
  }

  .ant-input-affix-wrapper {
    height: 36px;
    background: ${props => props.bgColor};
    border: none;
    border-left: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0px 8px 8px 0;

    .ant-input-suffix {
      margin: 0 4px;
      .anticon.ant-input-clear-icon {
        color: ${props => props.theme.color.text.$BBBBBB};
      }
    }

    &:hover {
      border: none;
      border-left: none;
    }

    &.ant-input-affix-wrapper-focused {
      .ant-input {
        &::placeholder {
          opacity: 0;
        }
      }
    }
  }

  .ant-input {
    width: 400px;
    height: 36px;
    background: transparent;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    font-size: 12px;
    color: var(--text-color-FFFFFF);
    padding: 0 10px 0 0;
    &::placeholder {
      color: ${props => props.theme.color.text.$BBBBBB};
    }
  }
`
