import React from 'react'
import { notification } from 'antd'
import { generatorUUID } from 'src/tools/common'
import ModalCancelIcon from 'src/assets/icons/notification/cancel.svg'
import styled, { createGlobalStyle } from 'styled-components'

export const baseNotification = async (
  title: React.ReactNode,
  content?: React.ReactNode,
  icon?: React.ReactNode,
  options?: {
    onClose?: () => void
    styled?: React.CSSProperties
  },
) => {
  const key = generatorUUID('xxxx')
  notification.success({
    icon,
    key,
    closeIcon: (
      <StyledModalCancelBox>
        <ModalCancelIcon />
      </StyledModalCancelBox>
    ),
    message: (
      <>
        <ResetSGNotificationStyled />
        <div className='sg-notification-title'>{title}</div>
      </>
    ),
    description: <div className='sg-notification-content'>{content}</div>,
    duration: null,
    onClose: options?.onClose,
    className: 'sg-notification-base',
    style: {
      right: 19,
      background: '#303033',
      borderRadius: 8,
      border: '1px solid #4C4C50',
      padding: 20,
      ...options?.styled,
    },
  })
  const cancelNotification = () => {
    notification.close(key)
  }
  return {
    key,
    cancelInstance: cancelNotification,
  }
}

export const openNotification = async (
  title: React.ReactNode,
  content?: React.ReactNode,
  options?: {
    onClose?: () => void
    styled?: React.CSSProperties
  },
  duration?: number,
) => {
  const key = generatorUUID('xxxx')
  notification.open({
    key,
    closeIcon: (
      <StyledModalCancelBox>
        <ModalCancelIcon />
      </StyledModalCancelBox>
    ),
    message: (
      <>
        <ResetSGOpenNotificationStyled />
        <div className='sg-notification-title'>{title}</div>
      </>
    ),
    description: <div className='sg-notification-content'>{content}</div>,
    duration: duration || null,
    onClose: options?.onClose,
    className: 'sg-notification-base',
    style: {
      right: 9,
      background: '#303033',
      borderRadius: 8,
      border: '1px solid #4C4C50',
      padding: 20,
      ...options?.styled,
    },
  })
  const cancelNotification = () => {
    notification.close(key)
  }
  return {
    key,
    cancelInstance: cancelNotification,
  }
}

const ResetSGOpenNotificationStyled = createGlobalStyle`
  .sg-notification-base {
    height: 258px;
    padding: 40px 40px 32px !important;
    background: rgba(0, 0, 0, 0.85) !important;
    backdrop-filter: blur(7px);
    border: none !important;
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
    }
    .ant-notification-notice-icon {
      margin-left: 0;
    }
    /* .ant-notification-notice-message {
      margin-left: 34px;
    } */
    .ant-notification-notice-description {
      margin-left: 0;
    }
    .sg-notification-title {
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
    .sg-notification-content {
      
    }
  }
`

const ResetSGNotificationStyled = createGlobalStyle`
  .sg-notification-base {
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
    }
    .ant-notification-notice-icon {
      margin-left: 0;
    }
    .ant-notification-notice-message {
      margin-left: 34px;
    }
    .ant-notification-notice-description {
      margin-left: 0;
    }
    .sg-notification-title {
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
    .sg-notification-content {
      
    }
  }
`

const StyledModalCancelBox = styled.div`
  color: #727272;
  &:hover {
    color: #fff;
  }
`

notification.config({
  placement: 'bottomRight',
  maxCount: 6,
  bottom: 9,
})
