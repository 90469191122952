import React, { FC } from 'react'
import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/es/checkbox'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import classNames from 'classnames'

const SGCheckbox: FC<CheckboxProps & { shape?: 'default' | 'circle' }> = ({
  children,
  shape = 'default',
  className,
  ...props
}) => {
  return (
    <StyledSGCheckboxContainer
      {...props}
      className={classNames(className, { 'sg-checkbox-circle': shape === 'circle' })}
    >
      {children}
    </StyledSGCheckboxContainer>
  )
}

const StyledSGCheckboxContainer = styled(Checkbox)`
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$BBBBBB};
  position: relative;
  &:hover {
    color: var(--text-color-FFFFFF);
    .ant-checkbox {
      &::before {
        border: 1px solid rgba(255, 255, 255, 1);
      }
    }
    .ant-checkbox-inner {
      background-color: rgba(255, 255, 255, 1);
      border-color: ${props => props.theme.color.bor.$FFFFFF20} !important;
      border-radius: 2px;
    }
  }
  .ant-checkbox {
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: 20px;
      height: 20px;
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 4px;
    }
    &.ant-checkbox-checked {
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 20px;
        height: 20px;
        background: transparent;
        border: 1px solid var(--bor-color-9A72CA);
        border-radius: 4px;
      }
      &::after {
        border-color: var(--bor-color-9A72CA);
        border-radius: 2px;
      }
      .ant-checkbox-inner {
        transition: none;
        border-color: var(--bor-color-9A72CA) !important;
        background-color: var(--bor-color-9A72CA);
        &::after {
          border-color: #17023b;
        }
      }
    }
  }
  .ant-checkbox-disabled + span {
    color: var(--text-color-FFFFFF);
  }
  .ant-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.4);
    border-color: ${props => props.theme.color.bor.$FFFFFF20} !important;
    border-radius: 2px;
  }
  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--bor-color-9A72CA);
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${props => props.theme.color.bor.$FFFFFF20};
  }

  &.ant-checkbox-wrapper-checked {
    color: ${props => props.theme.color.text.$BBBBBB};
  }

  // 圆角样式
  &.sg-checkbox-circle .ant-checkbox-inner {
    border-radius: 50%;
  }
  &.sg-checkbox-circle .ant-checkbox-checked::after {
    border-radius: 50%;
  }
  &.sg-checkbox-circle .ant-checkbox-checked .ant-checkbox-inner::after {
    border: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--text-color-FFFFFF);
  }
`

export default observer(SGCheckbox)
