import React, { FC } from 'react'
import { Button, ButtonProps } from 'antd'
import { useThrottleFn, useDebounceFn } from 'ahooks'
import styled from 'styled-components'
import classNames from 'classnames'

interface TConfirmButton extends ButtonProps {
  delayType?: 'debounce' | 'throttle'
  delay?: number
}
const ConfirmButton: FC<TConfirmButton> = ({ delayType, delay, children, ...props }) => {
  const { run: _throttleClick } = useThrottleFn(
    e => {
      !props.disabled && !props.loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  const { run: _debounceClick } = useDebounceFn(
    e => {
      !props.disabled && !props.loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )
  return (
    <StyledConfirmButton
      onClick={delayType === 'debounce' ? _debounceClick : delayType === 'throttle' ? _throttleClick : props.onClick}
      {...props}
      className={classNames('sg-confirm-btn', props.className)}
    >
      {children}
    </StyledConfirmButton>
  )
}

export default ConfirmButton

const StyledConfirmButton = styled(Button)`
  &,
  &:focus,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    width: 108px;
    height: 36px;
    border: none;
    font-size: 13px;
    color: var(--text-color-FFFFFF);
    background: var(--main-color);
    box-shadow: none;
    border-radius: 4px;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background: #565656 !important;
    box-shadow: none !important;
    border-color: #565656 !important;
    color: var(--text-color-FFFFFF);
  }

  &:before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }
  &.ant-btn-loading,
  &:hover {
    color: #fff;
    background: var(--bg-color-9A72CA);
    &::after {
      box-shadow: none !important;
    }
  }
`
