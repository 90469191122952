import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Spin, message, Rate, Input } from 'antd'
import { FullContentModal, BaseModal, BaseModalV2 } from 'src/components/base-modal'
import { appStore } from 'src/store'
import { LoadingOutlined } from '@ant-design/icons'
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom'
import { LoginStore, ProtocolStore } from 'src/store/modifier'
import { FadeinImage } from 'src/components/base-image'
import { TextButton, ConfirmButton } from 'src/components/base-button'
import { getWechatLoginCode, getWechatLoginStatus, userFeedbackHttp } from 'src/apis/user-api'
import QRcode from 'qrcode.react'
import styled from 'styled-components'
import classNames from 'classnames'

const defaultValues = {
  score: 0,
  content: '',
  contactInformation: '',
}

const feedbackMap = {
  speed: {
    title: '加速效果',
    type: '1'
  },
  modifier: {
    title: '修改器效果',
    type: '2',
  },
  trial: {
    title: '共享账号使用',
    type: '3',
  },
  cloudGame: {
    title: '云游戏效果',
    type: '4',
  },
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const FeedBack: FC = () => {
  const [values, setValues] = useState(defaultValues)
  const [loading, setLoading] = useState(false)
  const handleFeedbackConfirm = async () => {
    setLoading(true)
    try {
      await userFeedbackHttp({
        effectScore: values.score,
        content: values.content,
        contactInformation: values.contactInformation,
        type: feedbackMap[appStore.feedbackController.type!].type,
        ...appStore.feedbackController.context,
      })
      close()
      setValues({ ...defaultValues })
      message.success('感谢您的反馈')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const close = () => {
    appStore.feedbackController.visible = false
    appStore.feedbackController.context = {}
    appStore.feedbackController.type = null
  }

  return (
    <StyledFeedbackModalContainer
      width={390}
      onCancel={() => !loading && close()}
      visible={appStore.feedbackController.visible}
    >
      <div className='feedback-title'>
        {appStore.feedbackController.type && feedbackMap[appStore.feedbackController.type].title}反馈
      </div>
      <div className='feedback-score-box'>
        <div className='feedback-score-label feedback-common-label'>效果评价：</div>
        <div className='feedback-score-value'>
          <Rate
            className='feedback-score-component'
            value={values.score}
            onChange={e => setValues(value => ({ ...value, score: e }))}
          />
        </div>
      </div>
      <div className='feedback-description-box'>
        <div className='feedback-description-label feedback-common-label'>意见建议：</div>
        <div className='feedback-description-value'>
          <Input.TextArea
            className='feedback-description-component'
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={100}
            value={values.content}
            onChange={e => setValues(value => ({ ...value, content: e.target.value }))}
            placeholder='我们非常期待您提出宝贵的意见，帮助我们改善我们的产品，使用上存在的问题也可以反馈给我们'
          />
        </div>
      </div>
      <div className='feedback-contactInfo-box'>
        <div className='feedback-contactInfo-label feedback-common-label'>联系方式：</div>
        <div className='feedback-contactInfo-value'>
          <Input
            maxLength={32}
            value={values.contactInformation}
            onChange={e => setValues(value => ({ ...value, contactInformation: e.target.value }))}
            className='feedback-contactInfo-component'
            placeholder='请填写您的手机、邮箱、QQ或微信'
          />
        </div>
      </div>
      <div className='feedback-community-entry-box'>
        <div className='feedback-community-left-box'>
          <div className='feedback-community-left-label'>欢迎加入意见反馈QQ群</div>
          <div className='feedback-community-left-value'>897143222</div>
        </div>
        <div className='feedback-community-right-box'>
          <QRcode
            size={92}
            value={
              'http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=VozTwHRimMYoFwOmlJYDjfMTqlGMBNjG&authKey=%2BElU1AJr6%2FxT7qC4GBedKoiitjpFaq89pZAKTLUDdyN4D8GXFKkPgS7XXehedEB3&noverify=0&group_code=897143222'
            }
          />
        </div>
      </div>
      <ConfirmButton className='feedback-submit-btn' onClick={handleFeedbackConfirm} loading={loading}>
        提交反馈
      </ConfirmButton>
    </StyledFeedbackModalContainer>
  )
}

const StyledFeedbackModalContainer = styled(BaseModal)`
  .feedback-submit-btn {
    margin-top: 24px;
    width: 327px;
    height: 36px;
    border-radius: 4px;
  }
  .feedback-common-label {
    font-size: 14px;
    font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
    font-weight: 400;
    color: #bbbbbb;
    line-height: 24px;
  }
  .feedback-title {
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
  }
  .feedback-score-box {
    margin-top: 24px;
    .feedback-score-component {
      svg {
        color: rgba(255, 255, 255, 0.24);
      }
      .ant-rate-star-full {
        svg {
          color: #e8b409;
        }
      }
    }
  }
  .feedback-description-box {
    margin-top: 16px;
    .feedback-description-component {
      margin-top: 6px;
      background: transparent;
      border-color: #52525b;
      color: #fff;
      box-shadow: none;
      border-radius: 4px;
      &:focus {
        border-color: rgba(255, 255, 255, 0.4);
        &::placeholder {
          color: transparent;
        }
      }
      &::placeholder {
        color: #bbbbbb;
      }
      &::-webkit-scrollbar {
        width: 4px;
        height: 0px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        width: 4px;
      }
    }
  }
  .feedback-contactInfo-box {
    margin-top: 16px;
    .feedback-contactInfo-component {
      margin-top: 8px;
      height: 34px;
      background: transparent;
      border-color: #52525b;
      color: #fff;
      box-shadow: none;
      border-radius: 4px;
      &:focus {
        border-color: rgba(255, 255, 255, 0.4);
        &::placeholder {
          color: transparent;
        }
      }
      &::placeholder {
        color: #bbbbbb;
      }
    }
  }
  .feedback-community-entry-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .feedback-community-left-box {
      .feedback-community-left-label {
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #f4f4f5;
        line-height: 18px;
      }
      .feedback-community-left-value {
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 24px;
        margin-top: 12px;
      }
    }
    .feedback-community-right-box {
      width: 106px;
      height: 106px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.12);
      padding: 6px;
    }
  }
`

export default observer(FeedBack)
