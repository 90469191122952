import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { BaseModal } from 'src/components/base-modal'
import { getResourceDetailByResourceId } from 'src/apis/game-api'
import { ControllerStore } from 'src/store/resource'
import styled from 'styled-components'
import dayjs from 'dayjs'

const InformationModal: FC = () => {
  const navigate = useNavigate()
  const [resourceDetail, setResourceDetail] = useState<Resource.TResourceDetail | null>(null)

  const handleLoadResourceDetail = async (resourceId: number) => {
    const { result } = await getResourceDetailByResourceId({ resourceId })
    setResourceDetail(result)
  }

  useEffect(() => {
    if (ControllerStore.informationModalResourceId) {
      handleLoadResourceDetail(ControllerStore.informationModalResourceId)
    }
    return () => {
      setResourceDetail(null)
    }
  }, [ControllerStore.informationModalResourceId])

  return (
    <StyledDetailModal
      width={733}
      visible={ControllerStore.informationModalResourceId !== null}
      onCancel={ControllerStore.cancelInformationModal}
    >
      <div className='resource-information-title'>{resourceDetail?.resourceName}</div>
      <div className='resource-information-desc-row'>
        <div className='resource-information-desc-item' style={{ marginRight: 30 }}>
          <div className='resource-information-desc-label'>累计浏览：</div>
          <div className='resource-information-desc-value'>{resourceDetail?.views || 0}</div>
        </div>
        <div className='resource-information-desc-item'>
          <div className='resource-information-desc-label'>发布时间：</div>
          <div className='resource-information-desc-value'>{dayjs(resourceDetail?.createTime).format('YYYY-MM-DD')}</div>
        </div>
      </div>
      <div className='resource-information-content'>
        <div dangerouslySetInnerHTML={{ __html: resourceDetail?.description || '' }} />
      </div>
    </StyledDetailModal>
  )
}

const StyledDetailModal = styled(BaseModal)`
  .ant-modal-body {
    /* padding: 0; */
  }
  .resource-information-content {
    width: 100%;
    margin-top: 30px;
    max-height: 560px;
    overflow: overlay;
    font-size: 14px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: 400;
    color: #bbbbbb;
    line-height: 22px;
    img {
      max-width: 100% !important;
    }
  }
  .resource-information-title {
    font-size: 20px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
  }
  .resource-information-desc-row {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .resource-information-desc-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
      font-weight: 400;
      line-height: 16px;
      .resource-information-desc-label {
        color: #bbbbbb;
      }
      .resource-information-desc-value {
        color: #ffffff;
      }
    }
  }
`

export default observer(InformationModal)
