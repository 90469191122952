import { observable, action } from 'mobx'

class DownloadStore {
  @observable
  fileDownloadProgress: { [key: string]: Http.IFileDownloadInfo & { resourceName: string; resourceTypeName: string } } =
    {}

  @observable
  fileDownloadLinkCache: { [key: string]: string } = {}
  // promise: {
  //   _res: Function
  //   _rej: Function
  // } | null = null

  hasOtherDownloadTasks() {
    return !!Object.values(this.fileDownloadProgress).find(f => f.status === 2)
  }

  currentDownloadTask() {
    const temp = Object.entries(this.fileDownloadProgress).find(([key, value]) => value.status === 2)

    return temp
      ? {
          key: temp[0],
          value: temp[1],
        }
      : null
  }
}

export default new DownloadStore()
