import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { throttle } from 'lodash'
import { GameStore, appStore } from 'src/store'
import { SearchInput } from 'src/components/base-input'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { analysisSupportTypeByGamePlatform } from 'src/tools/common'
import {
  StyledAll,
  StyledGameLibraryHeaderRow,
  StyledGameLibraryView,
  StyledGameListContent,
} from 'src/pages/game-search'
import { ToolsTypes } from 'src/constants/common'
import SGCheckbox from 'src/components/base-checkbox'
import GameItem from 'src/pages/platform-search/game-item'
import styled from 'styled-components'
import classNames from 'classnames'
import useUrlState from '@ahooksjs/use-url-state'
import emptyImg from 'src/assets/images/common/empty.png'
import SearchIcon from 'src/assets/icons/layout/search-icon.svg'
import TopIcon from 'src/assets/icons/common/top.svg'

type TFilterIsInstalled = 'all' | 'none' | 'installed' | 'notInstalled'
type TSortKey = 'normal' | 'updatetime'
type TSortRule = 'desc' | 'asc' | ''

export type TElementSizeObj = {
  blockWidth: number
  blockHeight: number
  blockImgHeight: number
  blockNumByRow: number
}

// export type TVirtualListObj = {
//   startIndex: number
//   endIndex: number
// }

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const filterKeywords = (k: string, list: Game.GamePlatform[]) => {
  return list.filter(f => {
    const originName = f.platformName?.toLowerCase() || ''
    return originName.includes(k)
  })
  // return list.filter(f => {
  //   const aliasList = f.alias.split(',').map(d => d.toLowerCase())
  //   const originName = f.name?.toLowerCase() || ''
  //   const originZhCnName = f.zhCnName?.toLowerCase() || ''
  //   return aliasList.find(alias => alias.includes(k)) || originName.includes(k) || originZhCnName.includes(k)
  // })
}

const filterIsInstalledByPlatform = (type: '1' | '0') => {
  if (['1'].includes(type)) {
    return [...GameStore.installedPlatformList]
  }
  return [...GameStore.installedPlatformList, ...GameStore.notInstalledPlatformList]
}

// const filterIsInstalled = (type: TFilterIsInstalled) => {
//   // return list
//   // const { installedTitleList, notInstalledTitleList, finalTitleList } = InstalledGameStore.parsingInstalledGameList(
//   //   list,
//   //   InstalledGameStore.installedGameIdsList,
//   // )
//   if (['all', 'none'].includes(type)) {
//     // GameStore.platformList
//     return [...GameStore.installedPlatformList, ...GameStore.notInstalledPlatformList]
//   }
//   if (type === 'installed') {
//     return GameStore.installedPlatformList
//   }
//   return GameStore.notInstalledPlatformList
// }

const computedKeySortWeight = (d: Game.GamePlatform, key: string) => {
  const cName = d.platformName?.toLocaleLowerCase() || ''
  const eName = d.platformEnName?.toLocaleLowerCase() || ''
  /**
   * 0 中文名精确匹配
   * 1 英文名精确匹配
   * 2 别名精确匹配
   * 3 中文名模糊匹配
   * 4 英文模糊匹配
   * 5 别名模糊匹配
   */
  if (cName === key) {
    return 0
  }
  if (eName === key) {
    return 1
  }
  let res = 5
  if (eName.includes(key)) {
    res = 4
  }
  if (cName.includes(key)) {
    res = 3
  }
  return res
}

const sortListByKey = (list: Game.GamePlatform[], key: string): Game.GamePlatform[] => {
  const keywords = key?.trim().toLocaleLowerCase()
  return list.sort((a, b) => computedKeySortWeight(a, keywords) - computedKeySortWeight(b, keywords))
}

const CONTENT_PADDING_LEFT = 0
const ITEM_MARGIN_RIGHT = 26
const ITEM_MIN_WIDTH = 158 + ITEM_MARGIN_RIGHT
const ITEM_MAX_WIDTH = 197 + ITEM_MARGIN_RIGHT
const ITEM_MARGIN_BOTTOM = 20
const ITEM_IMAGE_ASPECT_RATIO = 1 / 1.5

const PlatformSearch: FC = () => {
  const navigate = useNavigate()
  const [state, setState] = useUrlState(
    {
      isInstalled: '0',
      keywords: '',
      sortKey: 'normal',
      sortRule: '',
      supportPlatformWelfare: '0',
      supportSpeed: '0',
    },
    {
      navigateMode: 'replace',
    },
  )
  const ListContentRef = useRef<HTMLDivElement>(null)
  const [itemSizeObj, setItemSizeObj] = useState<TElementSizeObj>()
  // const [virtualListObj, setVirtualListObj] = useState<TVirtualListObj>()
  const [virtualListStartIndex, setVirtualListStartIndex] = useState<number | null>(null)
  const [virtualListEndIndex, setVirtualListEndIndex] = useState<number | null>(null)
  const [viewTopButon, setViewTopButton] = useState<boolean>(false)

  const filterOtherCondByPlatform = (s: typeof state, list: Game.GamePlatform[]) => {
    const key = (state.keywords?.trim() || '').toLowerCase()
    const needFilter = !!key || [s.supportPlatformWelfare, s.supportSpeed].includes('1')
    const finalList = !needFilter
      ? list
      : list.filter(f => {
          if (key) {
            const originName = f.platformEnName?.toLowerCase() || ''
            const originZhCnName = f.platformName?.toLowerCase() || ''
            const valid = originName.includes(key) || originZhCnName.includes(key)
            if (!valid) {
              return false
            }
          }
          let speedValid = s.supportSpeed === '1' ? false : true
          let platformWelfareValid = s.supportPlatformWelfare === '1' ? false : true
          for (let relate of f.relates) {
            if (!platformWelfareValid && relate.targetRelateType === ToolsTypes.PLATFORM_WELFARE) {
              platformWelfareValid = true
            }
            if (!speedValid && relate.targetRelateType === ToolsTypes.SPEED_GAME) {
              speedValid = true
            }
          }
          return speedValid && platformWelfareValid
        })

    let supportPlatformWelfareNum = 0
    let supportSpeedNum = 0
    let installedNum = 0
    finalList.forEach(f => {
      const { supportSpeed, supportWelfare } = analysisSupportTypeByGamePlatform(f)
      let isInstalled = false
      if (!!GameStore.installedPlatformList.find(d => d.platformId === f.platformId)) {
        isInstalled = true
      }
      if (supportWelfare) {
        supportPlatformWelfareNum += 1
      }
      if (supportSpeed) {
        supportSpeedNum += 1
      }
      if (isInstalled) {
        installedNum += 1
      }
    })
    return {
      list: finalList,
      supportPlatformWelfareNum,
      supportSpeedNum,
      installedNum,
    }
  }

  const { data: finalTitleList, total } = useMemo(() => {
    if (GameStore.gameMasterList) {
      let list = filterIsInstalledByPlatform(state.isInstalled)
      const {
        list: finalList,
      } = filterOtherCondByPlatform(state, list)
      list = sortListByKey(finalList, state.keywords)
      const start = 0
      const end = list.length
      return {
        data: list.slice(start, end),
        total: list.length,
      }
    }
    return {
      data: [],
      total: 0,
    }
  }, [
    GameStore.platformList,
    GameStore.installedPlatformList,
    GameStore.notInstalledPlatformList,
    state.keywords,
    state.isInstalled,
    state.supportPlatformWelfare,
    state.supportSpeed
  ])

  const scrollToTop = () => {
    if (ListContentRef.current) {
      ListContentRef.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }
  }

  const changeSortKey = (sort: TSortKey, rule: TSortRule) => {
    setState(value => ({ ...value, sortKey: sort, sortRule: rule }))
    scrollToTop()
  }

  const handleChangeIsInstalled = (type: TFilterIsInstalled) => {
    if (state.isInstalled === type) {
      setState(value => ({ ...value, isInstalled: 'none' }))
      scrollToTop()
      return
    }
    if (state.isInstalled === 'none') {
      setState(value => ({ ...value, isInstalled: type }))
      scrollToTop()
      return
    }
    if (state.isInstalled === 'all') {
      if (type === 'installed') {
        setState(value => ({ ...value, isInstalled: 'notInstalled' }))
        scrollToTop()
      }
      if (type === 'notInstalled') {
        setState(value => ({ ...value, isInstalled: 'installed' }))
        scrollToTop()
      }
      return
    }
    setState(value => ({ ...value, isInstalled: 'all' }))
    scrollToTop()
  }

  const changeKeyword = (keywords: string) => {
    setState(value => ({ ...value, keywords }))
    scrollToTop()
  }

  useEffect(() => {
    if (!itemSizeObj || !ListContentRef.current) {
      return
    }
    const handleListContentScroll = throttle(
      () => {
        if (!ListContentRef.current) {
          return
        }
        let virtualListStartIndex = 0
        let virtualListEndIndex = total
        const content = ListContentRef.current
        const scrollTop = ListContentRef.current.scrollTop
        const trueBlockPlaceholderHeight = itemSizeObj.blockHeight + ITEM_MARGIN_BOTTOM
        /** 当前可视区域高度 */
        const viewContentHeight = ListContentRef.current.offsetHeight
        /**
         * 上面隐藏行数
         */
        const topHideRowNum = Math.max(Math.floor(scrollTop / trueBlockPlaceholderHeight) - 1, 0)
        setViewTopButton(topHideRowNum > 0)
        /**
         * 显示行数
         * 先计算可视区域高度可以显示多少行，然后再上下各多渲染1行
         */
        const viewRowNum = Math.ceil(viewContentHeight / trueBlockPlaceholderHeight) + (topHideRowNum === 0 ? 0 : 1) + 3
        /** 应该渲染元素的数量，假如total少于需要渲染的元素数量可以就不用进行下面额度计算了 */
        const renderElementNum = Math.floor(viewRowNum * itemSizeObj.blockNumByRow)
        if (total <= renderElementNum) {
          content.style.setProperty('--virtualList-box-height', `0px`)
          setVirtualListStartIndex(virtualListStartIndex)
          setVirtualListEndIndex(virtualListEndIndex)
          return
        }
        const sumScrollHeight = Math.ceil(total / itemSizeObj.blockNumByRow) * trueBlockPlaceholderHeight
        content.style.setProperty('--virtualList-box-height', `${sumScrollHeight}px`)
        virtualListStartIndex = topHideRowNum * itemSizeObj.blockNumByRow
        virtualListEndIndex = virtualListStartIndex + viewRowNum * itemSizeObj.blockNumByRow
        setVirtualListStartIndex(virtualListStartIndex)
        setVirtualListEndIndex(virtualListEndIndex)
        return
      },
      100,
      { trailing: true },
    )

    ListContentRef.current.addEventListener('scroll', handleListContentScroll)
    handleListContentScroll()
    return () => {
      ListContentRef.current?.removeEventListener('scroll', handleListContentScroll)
    }
  }, [itemSizeObj, total])

  useEffect(() => {
    const handleResize = throttle(
      () => {
        if (!ListContentRef.current) {
          return
        }
        const content = ListContentRef.current
        const contentTrueWidth = content.offsetWidth - CONTENT_PADDING_LEFT + ITEM_MARGIN_RIGHT
        const maxElementNum = Math.floor(contentTrueWidth / ITEM_MAX_WIDTH)
        const minElementNum = Math.floor(contentTrueWidth / ITEM_MIN_WIDTH)
        const trueElementNum = Math.max(maxElementNum, minElementNum, 1)
        const trueElementWidth = contentTrueWidth / trueElementNum - ITEM_MARGIN_RIGHT
        const trueElementImgHeight = trueElementWidth / ITEM_IMAGE_ASPECT_RATIO
        const trueElementItemHeight = trueElementImgHeight + 70
        const itemSizeObj = {
          blockWidth: trueElementWidth,
          blockHeight: trueElementItemHeight,
          blockImgHeight: trueElementImgHeight,
          blockNumByRow: trueElementNum,
        }
        content.style.setProperty('--block-width', `${itemSizeObj.blockWidth}px`)
        content.style.setProperty('--block-height', `${itemSizeObj.blockHeight}px`)
        content.style.setProperty('--block-img-height', `${itemSizeObj.blockImgHeight}px`)
        setItemSizeObj(itemSizeObj)
      },
      100,
      { trailing: true },
    )
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    GameStore.getAllData()
  }, [])

  return (
    <StyledGameLibraryView>
      <StyledAll>目前我们提供 {GameStore.platformList?.length || '--'} 个游戏平台的工具&服务</StyledAll>
      <StyledGameLibraryHeaderRow>
        <SearchInput
          className='search-input'
          placeholder='搜索平台'
          value={state.keywords}
          onChange={changeKeyword}
          addonBefore={
            <StyledSearchButton className='pointer'>
              <SearchIcon className='search-icon' />
            </StyledSearchButton>
          }
        />
      </StyledGameLibraryHeaderRow>
      <StyledGameListContent ref={ListContentRef} bodySizeHeight={appStore.bodySize?.height || 0}>
        <div
          className='box'
          style={{
            height: 'var(--virtualList-box-height)',
          }}
        >
          {finalTitleList.length === 0 && (
            <StyledEmptyContainer>
              <FadeinImage width={91} height={91.5} type='notDefault' src={emptyImg} />
              <div>MYBUFF 暂未提供针对此平台的任何工具/服务</div>
              <div>
                如果针对此平台您有第三方工具或服务诉求，欢迎前往
                <TextButton onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>MYBUFF论坛</TextButton>
                进行反馈
              </div>
            </StyledEmptyContainer>
          )}
          {finalTitleList.map((d, i) => {
            if (!itemSizeObj || virtualListStartIndex === null || virtualListEndIndex === null) {
              return null
            }
            if (i < virtualListStartIndex) {
              return null
            }
            if (i >= virtualListEndIndex) {
              return null
            }
            const rowNum = Math.floor(i / itemSizeObj.blockNumByRow)
            const colNum = i % itemSizeObj.blockNumByRow
            return (
              <GameItem
                isInstall={!!GameStore.installedPlatformList.find(f => f.platformId === d.platformId)}
                style={{ top: rowNum * (itemSizeObj.blockHeight + 20), left: colNum * (itemSizeObj.blockWidth + 26) }}
                key={`platform-search-item-${d.platformId}`}
                game={d}
              />
            )
          })}
        </div>
      </StyledGameListContent>
      {viewTopButon && (
        <StyledViewTopButton className='pointer' onClick={scrollToTop}>
          <TopIcon />
        </StyledViewTopButton>
      )}
    </StyledGameLibraryView>
  )
}

const StyledGameItemSkeletonContainer = styled.div`
  width: var(--block-width);
  height: var(--block-height);
  margin-right: 26px;
  margin-bottom: 20px;
  position: relative;
  .ant-skeleton-input {
    background: linear-gradient(
      90deg,
      rgba(190, 190, 190, 0.06) 25%,
      rgba(129, 129, 129, 0.1) 37%,
      rgba(190, 190, 190, 0.06) 63%
    );
    background-size: 400% 100%;
  }
  .box {
    height: var(--block-height);
    width: var(--block-width);
  }
  .top {
    width: var(--block-width);
    height: var(--block-img-height);
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.06);
  }
  .bottom-1 {
    position: absolute;
    left: 12px;
    bottom: 38px;
    height: 17px;
    width: 83px;
    background: rgba(255, 255, 255, 0.06);
  }
  .bottom-2 {
    position: absolute;
    left: 12px;
    bottom: 18px;
    height: 12px;
    width: 115px;
    background: rgba(255, 255, 255, 0.06);
  }

  /* .bottom-box {
    height: 70px;
    width: var(--block-width);
  } */
`

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$C6C6C6};
  div {
    margin-top: 20px;
  }
`

const StyledFreeTips = styled.div`
  ${props => props.theme.flexAlignCenter}
  min-width: 229px;
  height: 30px;
  border-radius: 30px;
  background: ${props => props.theme.color.bg.$FFFFFF12};
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
  padding: 0 10px 0 4px;
  font-size: 13px;
  color: ${props => props.theme.color.text.$FFFFFF86};
  .main {
    ${props => props.theme.flexCenter}
    width: 38px;
    height: 22px;
    background: linear-gradient(136deg, #3faeff 0%, #9b29ff 100%);
    border-radius: 24px;
    margin-right: 8px;
    font-size: 13px;
    color: var(--text-color-FFFFFF);
    font-weight: 700;
  }
  .primary {
    color: ${props => props.theme.color.mainColor};
  }
`

const StyledSearchButton = styled.div`
  ${props => props.theme.flexCenter}
  height: 100%;
  padding: 0 8px 0 10px;
`

const StyledHeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 20px;
  padding-left: 26px;
  position: relative;
  .tabs-row {
    display: flex;
    align-items: baseline;
    height: 34px;
    .tabs-item {
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$BBBBBB};
      cursor: pointer;
      margin-right: 24px;
      position: relative;
      &:hover {
        color: var(--text-color-FFFFFF);
      }
      &.active {
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        color: var(--text-color-FFFFFF);
        &::before {
          content: ' ';
          bottom: -10px;
          left: 0;
          position: absolute;
          width: 20px;
          height: 2px;
          background: ${props => props.theme.color.mainColor};
        }
      }
    }
  }
  .filter-extra {
    position: absolute;
    right: 26px;
    top: 0;
  }
`

const StyledViewTopButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 0.5em;
  bottom: 0.5em;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  color: #fff;
  font-size: 28px;
  border-radius: 50%;
  svg {
    width: 15px;
    height: 18px;
  }
  &:hover {
    background: #2777ff;
  }
`

export default observer(PlatformSearch)
