import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseInput } from 'src/components/base-input'
import { ConfirmButton } from 'src/components/base-button'
import { message, Skeleton, Table } from 'antd'
import { redeemCDKHttp, redeemCDKRecordHttp } from 'src/apis/user-api'
import { LoginStore } from 'src/store/modifier'
import { DOMID } from 'src/constants/common'
import { VipStore } from 'src/store'
import { FadeinImage } from 'src/components/base-image'
import { useIntl } from 'react-intl'
import RedeemTimeIcon from 'src/assets/icons/gift-key/redeem-time.svg'
import RedeemGoodsIcon from 'src/assets/icons/gift-key/redeem-goods.svg'
import RedeemKeyIcon from 'src/assets/icons/gift-key/redeem-key.svg'
import SGPagination from 'src/components/base-pagination'
import SGTable, { SG_TABLE_STYLE } from 'src/components/base-table'
import emptyImg from 'src/assets/images/common/empty.png'
import styled from 'styled-components'
import moment from 'moment'

const GIFT_KEY_TABLE_Y = {
  CONTAINER_PADDING: 44,
  H1_LINE_HEIGHT: 20,
  H1_MARGIN_BOTTOM: 24,
  CONTENT_MARGIN_TOP: 60,
  CONTENT_PADDING_BOTTOM: 50,
  INPUT_HEIGHT: 36,
  PAGINATION_HEIGHT: 32,
  PAGINATION_MAEGIN_TOP: 32
}

const { Column } = Table
const GiftKey: FC = () => {
  const [cdkValue, setCDKValue] = useState<string>('')
  const [speedDetailTableY, setSpeedDetailTableY] = useState(0)
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [redeemCDKRecordList, setRedeemCDKRecordList] = useState<Modifier.TRedeemCDKRecordResult[]>([])
  const [redeemCDKRecordTotal, setRedeemCDKRecordTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const isInitFetching = tableLoading && redeemCDKRecordList.length === 0
  const { formatMessage } = useIntl()
  const handleRedeemCDK = async () => {
    if (!LoginStore.userinfo) {
      return LoginStore.openLoginModal()
    }
    if (!cdkValue) {
      return message.warn(formatMessage({id:'cdk.empty'}))
    }
    setBtnLoading(true)
    try {
      await redeemCDKHttp({ cdk: cdkValue })
      setCDKValue('')
      VipStore.handleLoadUserRemainingSpeedBalance()
      VipStore.handleLoadUserRemainingCloudGameBalance()
      VipStore.handleLoadVipInfo()
      setPage(1)
      handleGetRedeemCDKRecord()
      message.success(formatMessage({id:'cdk.success'}))
    } catch (error) {
      // const msg = (error as Error).message
      // message.warn(msg)
    }
    setBtnLoading(false)
  }

  const handleGetRedeemCDKRecord = async () => {
    setTableLoading(true)
    try {
      const { result: redeemCDKRecord } = await redeemCDKRecordHttp({
        pageNo: page,
        pageSize: 12,
      })
      const { data, totalRows } = redeemCDKRecord
      setRedeemCDKRecordList(data || [])
      setRedeemCDKRecordTotal(totalRows)
    } catch (error) {
    } finally {
      setTableLoading(false)
    }
  }

  useEffect(() => {
    if (LoginStore.userinfo) {
      handleGetRedeemCDKRecord()
      return () => {
        setRedeemCDKRecordList([])
        setRedeemCDKRecordTotal(0)
      }
    }
  }, [page, LoginStore.userinfo])

  useEffect(() => {
    let timer: number | null = null
    const handleComputeHeight = () => {
      const layoutContentDOM = document.querySelector(`#${DOMID.LAYOUT_CONTENT}`)
      if (!layoutContentDOM) {
        return
      }

      const layoutContentHeight = layoutContentDOM.clientHeight
      /**     *2   是因为有2个标题 */
      const h1TotalHeight = (GIFT_KEY_TABLE_Y.H1_LINE_HEIGHT + GIFT_KEY_TABLE_Y.H1_MARGIN_BOTTOM) * 2
      const finalValue =
        layoutContentHeight -
        GIFT_KEY_TABLE_Y.CONTAINER_PADDING -
        GIFT_KEY_TABLE_Y.CONTENT_PADDING_BOTTOM -
        h1TotalHeight -
        GIFT_KEY_TABLE_Y.CONTENT_MARGIN_TOP -
        GIFT_KEY_TABLE_Y.INPUT_HEIGHT -
        SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_HEIGHT -
        SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_MARGIN_BOTTOM -
        GIFT_KEY_TABLE_Y.PAGINATION_HEIGHT -
        GIFT_KEY_TABLE_Y.PAGINATION_MAEGIN_TOP
      setSpeedDetailTableY(Math.max(finalValue, 358))
    }
    const handleSetTableY = () => {
      timer && window.clearTimeout(timer)
      handleComputeHeight()
    }
    handleSetTableY()
    window.addEventListener('resize', handleSetTableY)
    return () => {
      timer && window.clearTimeout(timer)
      window.addEventListener('resize', handleSetTableY)
    }
  }, [])

  return (
    <StyledGiftKeyContainer>
      <div className='giftkey-content'>
        <div className='redeem-box'>
          <div className='giftkey-h1'>MyBuff-{formatMessage({id:'cdk.redeem'})}</div>
          <div className='redeem-input-box'>
            <BaseInput
              placeholder={formatMessage({id:'cdk.placeholder'})}
              value={cdkValue}
              onChange={e => setCDKValue(e.target.value?.trim() || '')}
              className={'input'}
            />
            <ConfirmButton onClick={() => handleRedeemCDK()} loading={btnLoading}>
              {formatMessage({id:'cdk.btn'})}
            </ConfirmButton>
          </div>
        </div>
        <div className='redeem-record-container'>
          <div className='giftkey-h1'>{formatMessage({id:'cdk.record'})}</div>
          <div
            className='redeem-record-box'
            // style={{
            //   height:
            //     speedDetailTableY +
            //     SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_HEIGHT +
            //     SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_MARGIN_BOTTOM,
            // }}
          >
            <SGTable
              rowKey='exchangeTime'
              scroll={{ y: 'calc(100vh - 380px)' }}
              pagination={false}
              dataSource={redeemCDKRecordList}
              locale={{
                emptyText: () => {
                  if (isInitFetching) {
                    const skeletonList = new Array(4).fill(null)
                    return skeletonList.map(d => {
                      return (
                        <StyledSkeletonContainer>
                          <Skeleton.Input className='skeleton-1' active={true} />
                          <Skeleton.Input className='skeleton-2' active={true} />
                          <Skeleton.Input className='skeleton-3' active={true} />
                        </StyledSkeletonContainer>
                      )
                    })
                  }
                  return (
                    <StyledEmptyContainer>
                      <FadeinImage width={91} height={91.5} type='notDefault' src={emptyImg} />
                      <div>{formatMessage({id:'cdk.noRecord'})}</div>
                    </StyledEmptyContainer>
                  )
                },
              }}
            >
              <Column
                width='33%'
                title={
                  <StyledTabledHeader>
                    <RedeemTimeIcon />
                    {formatMessage({id:'cdk.time'})}
                  </StyledTabledHeader>
                }
                dataIndex='exchangeTime'
                key='exchangeTime'
                render={text => moment(text).format('YYYY-MM-DD HH:mm:ss')}
              />
              <Column
                width='33%'
                title={
                  <StyledTabledHeader>
                    <RedeemKeyIcon />
                    {formatMessage({id:'cdk.key'})}
                  </StyledTabledHeader>
                }
                render={d => (<div className='cdk-box' draggable={false}>{d}</div>)}
                dataIndex='cdk'
                key='cdk'
              />
              <Column
                width='33%'
                title={
                  <StyledTabledHeader>
                    <RedeemGoodsIcon />
                    {formatMessage({id:'cdk.goods'})}
                  </StyledTabledHeader>
                }
                dataIndex='remark'
                key='remark'
              />
            </SGTable>
          </div>
          {redeemCDKRecordTotal > 12 && (
            <StyledPaginationContainer>
              <SGPagination current={page} pageSize={12} total={redeemCDKRecordTotal} onChange={e => setPage(e)} />
            </StyledPaginationContainer>
          )}
        </div>
      </div>
    </StyledGiftKeyContainer>
  )
}

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$C6C6C6};
  div {
    margin-top: 20px;
  }
`

const StyledSkeletonContainer = styled.div`
  width: 100%;
  ${props => props.theme.flexAlignCenter}
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0;
  }
  .skeleton-1 {
    width: 33%;
    height: 24px;
    border-radius: 2px;
    text-align: left;
  }
  .skeleton-2 {
    width: 33%;
    min-width: 59px;
    height: 24px;
    border-radius: 2px;
    text-align: left;
  }
  .skeleton-3 {
    width: 33%;
    min-width: 59px;
    height: 24px;
    border-radius: 2px;
    text-align: left;
  }
`

const StyledTabledHeader = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 6px;
  }
`

const StyledPaginationContainer = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
  height: ${GIFT_KEY_TABLE_Y.PAGINATION_HEIGHT}px;
  margin-top: 24px;
`

const StyledGiftKeyContainer = styled.div`
  padding-top: 0px;
  .redeem-box{
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(78,80,88,0.48);
  }
  .giftkey-content {
    
    .redeem-record-container {
      margin-top: 40px;
      .redeem-record-box {
        .ant-table-header{
          margin-bottom: 0;
        }
        .ant-table-cell{
          border-top-color: #4E5058;
        }
        .ant-table-thead .ant-table-cell{
          background: #303338;
          padding: 8px 16px;
        }
        .ant-table-body .ant-table-cell {
          padding: 15px 16px;
          background: #26282B;
        }
        .ant-table-body tr td:last-child{
          border-radius: 0 !important;
        }
        .ant-table-body tr td:first-child{
          border-radius: 0 !important;
        }
        .cdk-box {
          user-select: text;
        }
      }
    }
    .redeem-input-box {
      display: flex;
      align-items: center;      
      .sg-base-input-warpper {
        flex: 1;
        height: 36px;
        border-radius: 4px;
        background: #202024 !important;
      }
      .ant-btn {
        width: 100px;
        height: 36px;
        border-radius: 4px;
        margin-left: 20px;
        font-weight: normal;
        color: #fff;
        background: #7044CD;
      }
    }
  }
  .giftkey-h1 {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    margin-bottom: 16px;
  }
  .returns {
    padding: 0 50px;
    display: flex;
    .returns-text {
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.color.text.$999999};
      cursor: pointer;
      .icon {
        transform: rotate(90deg);
        margin-right: 1px;
      }
      &:hover {
        color: var(--text-color-FFFFFF);
      }
    }
  }
`

export default observer(GiftKey)
