import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useVipRemainingTime } from 'src/hooks'
import { useNavigate } from 'react-router-dom'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center/index'
import { StyledBox, StyledBtn, StyledItemBox, StyledSafetyInfoContainer } from 'src/pages/user-center/self-info'
import SubBlock from 'src/pages/user-center/sub-block'
import QueueAnim from 'rc-queue-anim'
import styled from 'styled-components'

const SelfAssets: FC = () => {
  const navigate = useNavigate()
  const { remainingTimeStringArr: speedRemainingTimeStringArr } = useVipRemainingTime('speed')
  const { remainingTimeStringArr: cloudGameRemainingTimeStringArr } = useVipRemainingTime('cloudgame')
  return (
    <StyledSelfAssetsContainer>
      <StyledBox>
        <div className='title'>加速器</div>
        <StyledSafetyInfoContainer>
          <div className='safety-row' key='safety-row-phone'>
            <StyledItemBox>
              <div className='user-header-text'>可用加速器时长</div>
              <div
                className='user-header-title'>{speedRemainingTimeStringArr.join(':')}</div>
            </StyledItemBox>
            <div className='action-box'>
              <StyledBtn type={'primary'} onClick={() => showUserCenter(NAV_KEY_MAPPING.DURATION_RECHARGE)} >
                充值时长
              </StyledBtn>
            </div>
          </div>

        </StyledSafetyInfoContainer>
      </StyledBox>
      <StyledBox>
        <div className='title'>云游戏</div>
        <StyledSafetyInfoContainer>
          <div className='safety-row' key='safety-row-phone'>
            <StyledItemBox>
              <div className='user-header-text'>可用云游戏时长</div>
              <div
                className='user-header-title'>{cloudGameRemainingTimeStringArr.join(':')}</div>
            </StyledItemBox>
            <div className='action-box'>
              <StyledBtn type={'primary'} onClick={() => showUserCenter(NAV_KEY_MAPPING.CLOUDGAME_RECHARGE)} >
                充值时长
              </StyledBtn>
            </div>
          </div>

        </StyledSafetyInfoContainer>
      </StyledBox>
    </StyledSelfAssetsContainer>
  )
}

const StyledSelfAssetsContainer = styled.div`
  width: 100%;
`

export default observer(SelfAssets)
