import { ConfigStore } from 'src/store'
import { getExtendsByDownloadId } from 'src/apis/common-api'
import axios from 'axios'

enum BaiduOcpcNewTypeMapping {
  // 服务购买成功
  SERVICES_BUY_SUCCESS = 10,

  // 注册
  REGISTRY = 25
}

export const initBaiduOcpcConfig = async (
  downloadId: string,
  config: { token: string; type: 'baidu' | 'haoduofen'; channel: string },
) => {
  const { result } = await getExtendsByDownloadId({ downloadId })
  let finalResult = null
  try {
    if (result) {
      const temp = JSON.parse(result)
      if (!!temp.bdvid && !!temp.hostname) {
        finalResult = temp
      }
    }
  } catch (error) {}
  if (finalResult) {
    ConfigStore.baiduOcpcConfig = {
      token: config.token,
      type: config.type,
      bdvid: finalResult.bdvid,
      channel: config.channel,
      logidUrl: `http://${finalResult.hostname}?bd_vid=${finalResult.bdvid}`,
    }
    // reportBaiduOcpcByBuySuccess()
  }
}

export const reportBaiduOcpcByRegistry = (userId: number) => {
  if (!ConfigStore.baiduOcpcConfig) {
    return
  }
  reportBaiduOcpc(
    ConfigStore.baiduOcpcConfig.type,
    ConfigStore.baiduOcpcConfig.token,
    `${ConfigStore.baiduOcpcConfig.logidUrl}&userId=${userId}&channel=${ConfigStore.baiduOcpcConfig.channel}`,
    BaiduOcpcNewTypeMapping.REGISTRY,
  )
}

export const reportBaiduOcpcByBuySuccess = (userId: number) => {
  if (!ConfigStore.baiduOcpcConfig) {
    return
  }
  reportBaiduOcpc(
    ConfigStore.baiduOcpcConfig.type,
    ConfigStore.baiduOcpcConfig.token,
    `${ConfigStore.baiduOcpcConfig.logidUrl}&userId=${userId}&channel=${ConfigStore.baiduOcpcConfig.channel}`,
    BaiduOcpcNewTypeMapping.SERVICES_BUY_SUCCESS,
  )
}

export const reportBaiduOcpc = (
  target: 'baidu' | 'haoduofen',
  token: string,
  logidUrl: string,
  newType: BaiduOcpcNewTypeMapping,
) => {
  // 暂时不支持haoduofen  所以不管咋样 都是发送到百度ocpc
  let targetUrl =
    target === 'haoduofen'
      ? 'https://ocpc.baidu.com/ocpcapi/api/uploadConvertData'
      : 'https://ocpc.baidu.com/ocpcapi/api/uploadConvertData'
  let params = {
    token,
    conversionTypes: [{ logidUrl, newType }],
  }
  axios.post(targetUrl, params)
}
