import React, { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { OutlineLoading } from 'src/components/base-loading'
import { useThrottleFn } from 'ahooks'
import specialBtnBgImg from 'src/assets/images/common/special-btn-bg.jpg'
import styled from 'styled-components'
import classNames from 'classnames'

export type TSpecialButton = {
  style?: React.CSSProperties
  className?: string
  onFocus?: Function
  onBlur?: Function
  type?: 'normal' | 'primary' | 'dark' | 'gold' | 'brownness' | 'blue' | 'violet' | 'special'
  shape?: 'default' | 'circle'
  disabled?: boolean
  onClick?: () => void
  loading?: boolean
}

const SpecialButton: FC<TSpecialButton> = ({
  children,
  onFocus,
  onBlur,
  className,
  type = 'normal',
  shape = 'default',
  disabled,
  loading,
  onClick,
  ...props
}) => {
  const { run: _click } = useThrottleFn(
    () => {
      !disabled && !loading && onClick?.()
    },
    { leading: true, trailing: false, wait: 300 },
  )
  return (
    <StyledSpecialButtonContainer
      className={classNames('webuff-base-button', className || '', {
        'webuff-base-button-loading': !!loading,
        'webuff-base-button-primary': type === 'primary',
        'webuff-base-button-dark': type === 'dark',
        'webuff-base-button-gold': type === 'gold',
        'webuff-base-button-brownness': type === 'brownness',
        'webuff-base-button-blue': type === 'blue',
        'webuff-base-button-violet': type === 'violet',
        'webuff-base-button-special': type === 'special',
        'webuff-base-button-circle': shape === 'circle',
        'webuff-base-button-disabled': !!disabled
      })}
      onClick={() => _click()}
      {...props}
    >
      {children}
      {loading && (
        <div className='loading-box'>
          <OutlineLoading loading={loading} />
        </div>
      )}
    </StyledSpecialButtonContainer>
  )
}

const StyledSpecialButtonContainer = styled.div`
  width: 110px;
  height: 34px;
  background: #56565c;
  border-radius: 4px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  .loading-box {
    margin-left: 10px;
  }
  &.webuff-base-button-primary {
    background: linear-gradient(270deg, #C598FF 0%, #C998FF 100%);
    color: #17023B;
  }
  &.webuff-base-button-circle {
    border-radius: 100px;
  }
  &.webuff-base-button-dark {
    background: #2E2E32;
    color: #FFFFFF;
  }
  &.webuff-base-button-gold {
    background: linear-gradient(270deg, #FFC875 0%, #FFDC98 100%);
    color: #1E1E20;
  }
  &.webuff-base-button-brownness {
    background: linear-gradient(270deg, #AD7647 0%, #E09D4A 100%);
    color: #FFFFFF;
  }
  &.webuff-base-button-blue {
    background: linear-gradient(270deg, #2C5CD1 0%, #306CDF 100%);
    color: #FFFFFF;
  }
  &.webuff-base-button-violet {
    background: linear-gradient(270deg, #652C80 0%, #8E62B8 100%);
    color: #FFFFFF;
  }
  &.webuff-base-button-special {
    background: url(${specialBtnBgImg}) no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 3px 1px #8C4FB5;
    color: #FFFFFF;
    transition: .2s;
    &:hover {
      box-shadow: inset 0 0 10px 2px #8C4FB5;
    }
  }
  &.webuff-base-button-loading {
    filter: brightness(0.85) !important;
  }
  &.webuff-base-button-disabled {
    cursor: not-allowed;
    background: #7C7C7C;
    color: #D7D7D7;
    box-shadow: none !important;
  }
  &:hover {
    filter: brightness(1.1);
  }
`

export default observer(SpecialButton)
