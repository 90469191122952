import { observable, action } from 'mobx'

class UpdateStore {
  @observable
  promise: {
    _res: Function
    _rej: Function
  } | null = null
}

export default new UpdateStore()
