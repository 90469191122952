import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, message } from 'antd'
import { ConfirmButton, CancelButton } from 'src/components/base-button'
import { LoginStore } from 'src/store/modifier'
import { verificationIdentity, userUpdatePassword, loginHttp } from 'src/apis/user-api'
import { EmailVerification, PhoneVerification, PWVerification } from 'src/components/base-verification'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { useIntl } from 'react-intl'
import { checkOversea } from 'src/tools/common'
import Selector from 'src/components/base-selector'
import Step from 'src/components/base-steps'
import styled from 'styled-components'

const ForgetPWModal: FC = () => {
  const [form] = Form.useForm()
  const [step, setStep] = useState<number>(0)
  const [verificationMethodValue, setVerificationMethodValue] = useState<number>(0)
  const [identity, setIdentity] = useState<string | null>(null)
  const [userAccount, setUserAccount] = useState<string | null>(null)
  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { resetFields } = form
  const { formatMessage } = useIntl()
  const verificationMethods = [
    { title: formatMessage({id:'bind.email'}), key: 'email' },
    ...(checkOversea() ?[] :[{ title: formatMessage({id:'bind.mobile'}), key: 'mobile' }]),
  ]
  const currentVerificationMethod = verificationMethods[verificationMethodValue]
  const handleVerificationStep1 = async () => {
    const values = await form.validateFields()
    setLoading(true)
    let params: Modifier.TVerificationIdentity
    if (currentVerificationMethod.title === formatMessage({id:'bind.password'})) {
      params = {
        type: 'password',
        password: values.pw,
      }
    }
    if (currentVerificationMethod.title === formatMessage({id:'bind.email'})) {
      params = {
        type: 'email',
        account: values.email,
        code: values.emailVerificationCode,
        action: 'UP_PASS'
      }
    }
    if (currentVerificationMethod.title === formatMessage({id:'bind.mobile'})) {
      params = {
        type: 'mobile',
        account: values.phone,
        code: values.phoneVerificationCode,
        action: 'UP_PASS'
      }
    }
    try {
      const { result: identity } = await verificationIdentity(params!, {}, { warnToast: false })
      setIdentity(identity.ticket)
      setUserAccount(identity.account)
      setStep(1)
      form.resetFields()
    } catch (error) {
      const err = error as Error
      err.message && message.warn({ content: err.message, className: 'login-message' })
    } finally {
      setLoading(false)
    }
  }

  const handleVerificationStep2 = () => {
    setStep(2)
  }

  const handleVerificationStep3 = async () => {
    if (!identity) {
      return
    }
    const values = await form.validateFields()
    setLoading(true)
    try {
      await userUpdatePassword(
        {
          ticket: identity,
          newPassword: values.pw,
        },
        {},
        { warnToast: false },
      )
      setStep(3)
      setNewPassword(values.pw)
      // 假如当前记住的账号是用户要修改的这个账号， 则帮他把记住的密码给清掉
      if (localStorageTool.get(LOCALSTORAGE_KEYS.REMEMBER_ACCOUNT) === userAccount) {
        localStorageTool.remove(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD)
        LoginStore.reloadRemember += 1
      }
      form.resetFields()
    } catch (error) {
      const err = error as Error
      err.message && message.warn({ content: err.message, className: 'login-message' })
    } finally {
      setLoading(false)
    }
  }

  const handleVerificationStep4 = async () => {
    if (!userAccount || !newPassword) {
      return
    }
    setLoading(true)
    try {
      const { result } = await loginHttp({ account: userAccount, password: newPassword, accountType: 'email' })
      await LoginStore.loginSuccess(result)
      LoginStore.cancelLoginModal()
      LoginStore.cancelForgetPWModal()
      localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_ACCOUNT, userAccount)
      localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD, newPassword)
    } catch (error) {
      const err = error as Error
      message.warn({ content: err.message, className: 'login-message' })
    } finally {
      setLoading(false)
    }
  }

  const handleChangedValues = async (changedValues: any) => {
    if (step === 2 && Object.keys(changedValues).includes('pw')) {
      await form.validateFields(['pwConfirm'])
    }
  }

  useEffect(() => {
    if (!LoginStore.forgetPWVisible) {
      resetFields()
      setStep(0)
      setVerificationMethodValue(0)
      setIdentity(null)
      setUserAccount(null)
      setNewPassword(null)
    }
  }, [LoginStore.forgetPWVisible])

  return (
    <StyledForgetPwContainer>
      <Step
        current={step}
        list={[
          { text: formatMessage({id:'bind.auth'}) },
          { text: formatMessage({id:'reset.pwd.confirm'}) },
          { text: formatMessage({id:'reset.pwd.reset'}) },
          { text: formatMessage({id:'bind.end'}) }
        ]}
      />
      <StyledForgetPWModalContent
        form={form}
        style={{ marginTop: 30 }}
        onValuesChange={changedValues => handleChangedValues(changedValues)}
      >
        {step === 0 && (
          <>
            <Selector
              style={{ marginBottom: 30 }}
              placeholder={formatMessage({id:'bind.authSelect'})}
              options={verificationMethods.map(d => formatMessage({id:'reset.pwd.authType'},{type:d.title}))}
              value={verificationMethodValue}
              onChange={setVerificationMethodValue}
            />
            {currentVerificationMethod.title === formatMessage({id:'bind.mobile'}) && (
              <PhoneVerification
                form={form}
                codeType='UP_PASS'
                phonePlaceholder='请输入您要找回密码账号绑定的手机号'
                phoneEmptyMsg='手机号不能为空'
                phoneErrorMsg='请填写正确格式的手机号'
                verificationCodePlaceholder='请输入您收到的手机验证码'
                verificationCodeEmptyMsg='验证码不能为空'
                verificationCodeErrorMsg='请填写正确格式的验证码'
                verificationExistOptions={{
                  hasExist: false,
                  field: 'phone',
                  msg: '当前手机号未绑定任何MyBuff账号，请重新填写',
                }}
              />
            )}
            {currentVerificationMethod.title === formatMessage({id:'bind.email'}) && (
              <EmailVerification
                form={form}
                codeType='UP_PASS'
                emailPlaceholder={formatMessage({id:'reset.pwd.email'})}
                emailEmptyMsg={formatMessage({id:'login.email.emptyMsg'})}
                emailErrorMsg={formatMessage({id:'login.email.errMsg'})}
                verificationCodePlaceholder={formatMessage({id:'bind.email.placeholder'})}
                verificationCodeEmptyMsg={formatMessage({id:'bind.email.emptyMsg'})}
                verificationCodeErrorMsg={formatMessage({id:'bind.email.errorMsg'})}
                verificationExistOptions={{
                  hasExist: false,
                  field: 'email',
                  msg: formatMessage({id:'reset.pwd.noAccount'}),
                }}
              />
            )}
            <div className='bind-btns-row'>
              <CancelButton onClick={() => LoginStore.cancelForgetPWModal()}>{formatMessage({id:'common.cancel'})}</CancelButton>
              <ConfirmButton
                type='primary'
                style={{ marginLeft: 10 }}
                onClick={() => handleVerificationStep1()}
                loading={loading}
              >
                {formatMessage({id:'bind.next'})}
              </ConfirmButton>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <StyledReadOnlyRow>
              <div className='readonly-label'>{formatMessage({id:'reset.pwd.account'})}：</div>
              <div className='readonly-value'>{userAccount}</div>
            </StyledReadOnlyRow>
            <div className='bind-btns-row'>
              <CancelButton onClick={() => LoginStore.cancelForgetPWModal()}>{formatMessage({id:'common.cancel'})}</CancelButton>
              <ConfirmButton
                type='primary'
                style={{ marginLeft: 10 }}
                onClick={() => handleVerificationStep2()}
                loading={loading}
              >
                {formatMessage({id:'bind.next'})}
              </ConfirmButton>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <PWVerification
              form={form}
              isConfirm={true}
              PWPlaceholder={formatMessage({id:'change.pwd.placeholder'})}
              PWEmptyMsg={formatMessage({id:'change.pwd.emptyMsg'})}
              PWErrorMsg={formatMessage({id:'change.pwd.errMsg'})}
              PWConfirmPlaceholder={formatMessage({id:'change.pwd.placeholder2'})}
              PWConfirmEmptyMsg={formatMessage({id:'change.pwd.emptyMsg2'})}
              PWConfirmErrorMsg={formatMessage({id:'change.pwd.errMsg2'})}
            />
            <div className='bind-btns-row'>
              <CancelButton onClick={() => LoginStore.cancelForgetPWModal()}>{formatMessage({id:'common.cancel'})}</CancelButton>
              <ConfirmButton
                type='primary'
                style={{ marginLeft: 10 }}
                onClick={() => handleVerificationStep3()}
                loading={loading}
              >
                {formatMessage({id:'reset.pwd.confirmReset'})}
              </ConfirmButton>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <StyledReadOnlyRow>
              <div className='readonly-label'>{formatMessage({id:'reset.pwd.loginAccount'})}：</div>
              <div className='readonly-value'>{userAccount}</div>
            </StyledReadOnlyRow>
            <StyledReadOnlyRow>
              <div className='readonly-label'>{formatMessage({id:'reset.pwd.newPwd'})}：</div>
              <div className='readonly-value'>{newPassword}</div>
            </StyledReadOnlyRow>
            <div className='bind-btns-row'>
              <CancelButton onClick={() => LoginStore.cancelForgetPWModal()}>{formatMessage({id:'common.cancel'})}</CancelButton>
              <ConfirmButton
                type='primary'
                style={{ marginLeft: 10 }}
                onClick={() => handleVerificationStep4()}
                loading={loading}
              >
                {formatMessage({id:'reset.pwd.login'})}
              </ConfirmButton>
            </div>
          </>
        )}
      </StyledForgetPWModalContent>
    </StyledForgetPwContainer>
  )
}

const StyledReadOnlyRow = styled.div`
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  background-color: ${props => props.theme.color.bg.$FFFFFF12};
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 30px;
  .readonly-label {
    color: ${props => props.theme.color.text.$C6C6C6};
  }
  .readonly-value {
    color: ${props => props.theme.color.text.$BBBBBB};
  }
`

const StyledForgetPWModalContent = styled(Form)`
  .ant-select {
    width: 100%;
    .ant-select-selector {
      height: 52px;
      border-radius: 8px;
      padding: 0 20px;
      border: none;
      .ant-select-selection-search-input {
        height: 50px;
      }
      .ant-select-selection-item {
        line-height: 50px;
      }
      .ant-select-selection-placeholder {
        line-height: 50px;
      }
    }
  }
  .ant-select-dropdown {
    .ant-select-item {
      padding: 5px 20px;
    }
  }
  .ant-form-item {
    min-height: 68px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bind-btns-row {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .sg-confirm-btn,
    .sg-cancel-btn {
      width: 166px;
      height: 52px;
    }
  }
  .verification-code-input{
    .sg-base-input{
      width: 50%;
    }
  }
`

const StyledForgetPwContainer = styled.div`
  padding-top: 10px;
  width: 340px;
  .sg-base-input-warpper {
    height: 52px;
  }
  .sg-step-box{
    &:not(:last-child)::before{
      left: 55px;
      top: 43px;
    }
    .sg-step-content{
      width: 80px;
      > span{
        min-height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }    
  }
`

export default observer(ForgetPWModal)
