import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { Form, message } from 'antd'
import { ConfirmButton } from 'src/components/base-button'
import { EmailVerification, PhoneVerification } from 'src/components/base-verification'
import { userBindIdentity } from 'src/apis/user-api'
import Selector from 'src/components/base-selector'
import styled from 'styled-components'
import classNames from 'classnames'

type TBindModal = {
  visible: { callback: Function } | null
  onCancel: () => void
  onSuccess: () => void
}
const verificationMethods = [
  { title: '邮箱', key: 'email' },
  { title: '手机', key: 'mobile' },
]
const ForceBindModal: FC<TBindModal> = ({ visible, onCancel, onSuccess }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [verificationMethodValue, setVerificationMethodValue] = useState<number>(0)
  const currentVerificationMethod = verificationMethods[verificationMethodValue]
  const handleCancelModal = () => {
    onCancel()
    form.resetFields()
  }

  const handleBind = async () => {
    const values = await form.validateFields()
    setLoading(true)
    let params: Modifier.TUserBindIdentityPayload
    if (currentVerificationMethod.title === '手机') {
      params = {
        type: 'mobile',
        account: values.phone,
        code: values.phoneVerificationCode,
      }
    }
    if (currentVerificationMethod.title === '邮箱') {
      params = {
        type: 'email',
        account: values.email,
        code: values.emailVerificationCode,
      }
    }
    try {
      await userBindIdentity(params!)
      onSuccess()
      form.resetFields()
      message.success(`${currentVerificationMethod.title}绑定成功`)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledBindModalContainer
      visible={!!visible}
      title={`账号安全信息认证`}
      width={474}
      onCancel={() => handleCancelModal()}
    >
      <StyledBindModalForm form={form}>
        <div className='forcebind-description' style={{ marginBottom: 20, marginTop: -10 }}>
          系统检测到您未绑定任何账号安全资料，这将会导致您当前登陆密码遗失后无法找回当前账号，请先完成信息补齐后再进行游戏修改
        </div>
        <Selector
          style={{ marginBottom: 30 }}
          placeholder='选择绑定信息类型'
          options={verificationMethods.map(d => `个人${d.title}`)}
          value={verificationMethodValue}
          onChange={setVerificationMethodValue}
        />
        {currentVerificationMethod.title === '邮箱' && (
          <EmailVerification
            // verificationExistOptions={{
            //   hasExist: true,
            //   field: 'email',
            //   msg: '当前邮箱已被其他账号绑定，确认绑定后系统将迁移绑定关系至当前账号',
            // }}
            form={form}
            codeType='BIND'
          />
        )}
        {currentVerificationMethod.title === '手机' && (
          <PhoneVerification
            // verificationExistOptions={{
            //   hasExist: true,
            //   field: 'phone',
            //   msg: '当前手机号已被其他账号绑定，确认绑定后系统将迁移绑定关系至当前账号',
            // }}
            form={form}
            codeType='BIND'
          />
        )}
        <div className='bind-btns-row'>
          <ConfirmButton shape='circle' onClick={() => handleCancelModal()}>
            取消
          </ConfirmButton>
          <ConfirmButton
            type='primary'
            shape='circle'
            style={{ marginLeft: 10 }}
            onClick={() => handleBind()}
            loading={loading}
          >
            确认绑定
          </ConfirmButton>
        </div>
      </StyledBindModalForm>
    </StyledBindModalContainer>
  )
}

const StyledBindModalForm = styled(Form)`
  padding: 0 10px 10px;
  .forcebind-description {
    font-size: 12px;
    line-height: 22px;
    color: ${props => props.theme.color.text.$FFFFFF86};
  }
  .ant-form-item {
    height: 68px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bind-btns-row {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
`

const StyledBindModalContainer = styled(BaseModal)`
  .ant-select {
    width: 100%;
    .ant-select-selector {
      height: 44px;
      border-radius: 8px;
      padding: 0 20px;
      border: none;
      .ant-select-selection-search-input {
        height: 42px;
      }
      .ant-select-selection-item {
        line-height: 42px;
      }
      .ant-select-selection-placeholder {
        line-height: 42px;
      }
    }
  }
  .ant-select-dropdown {
    .ant-select-item {
      padding: 5px 20px;
    }
  }
`

export default observer(ForceBindModal)
