import { observable, action, computed, reaction } from 'mobx'
import { PLATFORM_MAPPING } from 'src/constants/common'
import { getActivityConfig } from 'src/apis/common-api'
import { queryActivityHasReceiveDurationHttp } from 'src/apis/user-api'
import { LoginStore } from 'src/store/modifier'
import { appStore } from 'src/store'
import { message } from 'antd'
import dayjs from 'dayjs'

class Activity230817 {
  ACTIVITY_NO = 'SGT5561384122804135816'

  @observable
  timerObj: { timer: number; countdown: number } | null = null

  @observable
  config: Http.IActivityConfig<{ benefitValidity: string; givingMemberDay: string }> | null = null

  @observable
  modalVisible: boolean = false

  @observable
  hasReceiveDuration: boolean | null = null

  @action
  loadConfig = async () => {
    const result = await getActivityConfig({ activityNo: this.ACTIVITY_NO })
    this.config = result.result
  }

  @action
  loadUserHasReceiveDuration = async () => {
    const hasReceiveDuration = await queryActivityHasReceiveDurationHttp({ activityNo: this.ACTIVITY_NO })
    this.hasReceiveDuration = hasReceiveDuration.result
  }

  @computed
  get activity230817Valid() {
    const now = Date.now()
    return (
      !!this.config &&
      appStore.serverTimeOffset !== null &&
      this.config.activityState === 1 &&
      dayjs(this.config.endTime).valueOf() > dayjs(now + appStore.serverTimeOffset).valueOf() &&
      dayjs(now + appStore.serverTimeOffset).valueOf() > dayjs(this.config.beginTime).valueOf()
    )
  }

  @computed
  get formatCountdown() {
    if (!this.timerObj) {
      return null
    }
    const HOUR = 60 * 60 * 1000
    const MINUTE = 60 * 1000
    const countdown = Math.max(this.timerObj.countdown, 0)
    const hour = Math.floor(countdown / HOUR)
      .toString()
      .padStart(2, '0')
    const minute = Math.floor((countdown % HOUR) / MINUTE)
      .toString()
      .padStart(2, '0')
    const second = Math.floor((countdown % MINUTE) / 1000)
      .toString()
      .padStart(2, '0')
    return [
      { type: 'number', value: hour },
      { type: 'symbol', value: ':' },
      { type: 'number', value: minute },
      { type: 'symbol', value: ':' },
      { type: 'number', value: second },
    ]
  }

  @action
  handleUserLogin = async (modal: boolean) => {
    /** 等待加载用户基本信息 */
    const userBaseInfoPromise = new Promise<Modifier.IBaseUserInfo>((res, rej) => {
      if (LoginStore.userBaseInfo) {
        res(LoginStore.userBaseInfo)
      }
      const disposer = reaction(
        () => LoginStore.userBaseInfo,
        userBaseInfo => {
          if (userBaseInfo) {
            res(userBaseInfo)
            disposer && disposer()
          }
        },
      )
    })

    /** 等待加载活动配置 */
    const configPromise = new Promise<
      Http.IActivityConfig<{
        benefitValidity: string
        givingMemberDay: string
      }>
    >((res, rej) => {
      if (Activity230817Store.config) {
        res(Activity230817Store.config)
      }
      const disposer = reaction(
        () => Activity230817Store.config,
        config => {
          if (config) {
            res(config)
            disposer && disposer()
          }
        },
      )
    })
    const [userBaseInfo, config] = await Promise.all([userBaseInfoPromise, configPromise])
    if (this.activity230817Valid) {
      const serverNow = Date.now() + appStore.serverTimeOffset!
      const benefitValidity = Number(config.activityConfig.benefitValidity) * 60 * 60 * 1000
      /** 用户注册时间 + 有效期 大于 服务器时间 +  */
      const countdown = dayjs(userBaseInfo.registerTime).valueOf() + benefitValidity - serverNow
      if (countdown > 0) {
        await this.loadUserHasReceiveDuration()
        if (this.hasReceiveDuration === false) {
          this.modalVisible = modal && true
          this.timerObj = {
            timer: window.setInterval(() => {
              if (this.timerObj) {
                this.timerObj.countdown = this.timerObj.countdown - 1000
                if (this.timerObj.countdown < 0) {
                  this.clearCountdown()
                }
              }
            }, 1000),
            countdown: countdown,
          }
        }
      }
    }
  }

  clearCountdown() {
    this.timerObj && clearInterval(this.timerObj.timer)
    this.timerObj = null
    this.modalVisible = false
    this.hasReceiveDuration = null
  }
}

export const Activity230817Store = new Activity230817()
