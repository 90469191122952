import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, ButtonProps } from 'antd'
import { useThrottleFn, useDebounceFn } from 'ahooks'
import { emitter } from 'src/tools/emitter'
// import { appStore } from 'src/store'
import speedingAnimateImg from 'src/assets/images/common/speeding-animate.png'
import specialBtnBgAnimateImg from 'src/assets/images/common/special-btn-bg-animate.png'
import specialBtnBgImg from 'src/assets/images/common/special-btn-bg.jpg'
import styled from 'styled-components'

function randomEase() {
  let target = 1 // 随机生成目标进度值
  let current = 0 // 当前进度值
  let easing = 0.2 + Math.random() * 0.2 // 缓动系数，可以随机生成
  return function (progress: number) {
    if (progress >= 1) {
      return 1
    }
    let increment = Math.random() * 0.1 - 0.05 // 随机增量，可以为负数
    current += (increment + (target - current) * easing) * progress // 逐渐逼近目标进度值
    current = Math.max(Math.min(current, target), 0) // 确保进度值不超出 0 到目标进度值之间的范围
    return current
  }
}

function animate(callback: (progress: number, type: 'running' | 'over') => void) {
  let start = Date.now()
  let duration = 2000
  let ease = randomEase()

  function update() {
    let timeElapsed = Date.now() - start
    let timeProgress = timeElapsed / duration
    let easedProgress = ease(timeProgress)

    callback(easedProgress, 'running')
    if (timeElapsed < duration) {
      requestAnimationFrame(update)
    } else {
      callback(easedProgress, 'over')
    }
  }
  requestAnimationFrame(update)
}

const gradientList = ['gradient1', 'gradient2', 'gradient3', 'gradient4', 'gradient5']

const generateUUID = () => {
  const uuid = []
  for (let i = 0; i < 4; i++) {
    uuid.push(
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1),
    )
  }
  return uuid.join('')
}

const handleGenRandomFilter = () => {
  const id = 'filter' + generateUUID()
  /** 1 - 10 */
  const start = Math.random() * 5 + 1
  /** 1 - 50, 一定大于start */
  const end = Math.random() * 5 + start
  const scribbles = document.querySelector('#scribbles')
  const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter')
  filter.setAttribute('color-interpolation-filters', `sRGB`)
  filter.setAttribute('id', id)
  filter.setAttribute('x', `-50`)
  filter.setAttribute('y', `-50`)
  filter.setAttribute('width', `200`)
  filter.setAttribute('height', `200`)
  filter.setAttribute('filterUnits', `userSpaceOnUse`)
  const feTurbulence = document.createElementNS('http://www.w3.org/2000/svg', 'feTurbulence')
  feTurbulence.setAttribute('type', `fractalNoise`)
  feTurbulence.setAttribute('baseFrequency', `0.2 0.2`)
  feTurbulence.setAttribute('numOctaves', `1`)
  feTurbulence.setAttribute('result', `warp`)
  const feDisplacementMap = document.createElementNS('http://www.w3.org/2000/svg', 'feDisplacementMap')
  feDisplacementMap.setAttribute('xChannelSelector', `R`)
  feDisplacementMap.setAttribute('yChannelSelector', `G`)
  feDisplacementMap.setAttribute('scale', `${start}`)
  feDisplacementMap.setAttribute('in', `SourceGraphic`)
  feDisplacementMap.setAttribute('in2', `warp`)
  filter.appendChild(feTurbulence)
  filter.appendChild(feDisplacementMap)
  scribbles?.appendChild(filter)
  return {
    id,
    start,
    end,
    dom: filter,
    fdDom: feDisplacementMap,
  }
}

const handleGenRect = (filterId: string, gradientId: string, type: 'down' | 'up') => {
  const id = 'rect' + generateUUID()
  const lightning = document.querySelector('#lightning')
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
  // const strokeWidth = Math.random() * 1 + 0.5
  const strokeWidth = Math.random() * 0.5 + 1
  const upPoints = [
    { x: 0, y: 20 },
    { x: 0, y: 0 },
    { x: 99, y: 0 },
    { x: 99, y: 25 },
    // { x: 85, y: 35 },
    // { x: 80, y: 25 },
  ]
  const downPoints = [
    { x: 0, y: 20 },
    { x: 0, y: 50 },
    { x: 99, y: 50 },
    { x: 99, y: 25 },
    // { x: 80, y: 45 },
    // { x: 80, y: 25 },
  ]
  const points = (type === 'down' ? downPoints : upPoints).map((d, i) => {
    if (i === 2) {
      return d
    }
    const random = Math.random() * 2
    const x = random > 1 ? d.x + random : d.x - random
    const y = random > 1 ? d.y + random : d.y - random
    return {
      x,
      y,
    }
  })
  rect.setAttribute(
    'points',
    `${points[0].x},${points[0].y} ${points[1].x},${points[1].y} ${points[2].x},${points[2].y} ${points[3].x},${points[3].y}`,
  )
  rect.setAttribute('filter', `url(#${filterId})`)
  rect.setAttribute('id', id)
  rect.setAttribute('class', 'strike')
  rect.setAttribute('stroke', `url(#${gradientId})`)
  rect.setAttribute('x', '0')
  rect.setAttribute('y', '0')
  rect.setAttribute('width', '102')
  rect.setAttribute('height', '52')
  rect.setAttribute('rx', '0')
  rect.setAttribute('fill', 'none')
  rect.setAttribute('stroke-miterlimit', '10')
  rect.setAttribute('stroke-width', `${strokeWidth}`)
  rect.style.position = 'absolute'
  rect.style.left = '0'
  rect.style.top = '0'
  rect.classList.add(type)
  lightning?.appendChild(rect)
  return {
    id,
    dom: rect,
  }
}

const handleGenLightningItem = (type: 'down' | 'up') => {
  const filterConfig = handleGenRandomFilter()
  const gradientNum = Math.floor(Math.random() * (gradientList.length - 1) + 1)
  const linearGradientId = `gradient${gradientNum}`
  const rectConfig = handleGenRect(filterConfig.id, linearGradientId, type)
  animate((process, type) => {
    if (type === 'over') {
      filterConfig.dom && filterConfig.dom.remove()
      rectConfig.dom && rectConfig.dom.remove()
      return
    }
    const total = filterConfig.end - filterConfig.start
    const finalValue = Math.floor(filterConfig.end - process * total)
    filterConfig.fdDom.setAttribute('scale', `${finalValue}`)
  })
}

interface TConfirmButton extends ButtonProps {
  delayType?: 'debounce' | 'throttle'
  delay?: number
}
const MainButton: FC<TConfirmButton> = ({ delayType, delay, children, ...props }) => {
  const [btnProcess, setBtnProcess] = useState(0)
  // const process = useRef(0)
  const { run: _throttleClick } = useThrottleFn(
    e => {
      !props.disabled && !props.loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  const { run: _debounceClick } = useDebounceFn(
    e => {
      !props.disabled && !props.loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  return (
    <StyledMainButton
      process={btnProcess}
      onClick={delayType === 'debounce' ? _debounceClick : delayType === 'throttle' ? _throttleClick : props.onClick}
      {...props}
    >
      {children}
      <div className='animate-normal-mark' />
      <div className='animate-starting-mark' />
      <div className='animate-speeding-mark' />
    </StyledMainButton>
  )
}

export default MainButton

const StyledMainButton = styled(Button)<{ process: number }>`
  &,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active,
  &:focus {
    width: 396px;
    height: 46px;
    box-shadow: inset 0px 4px 8px 0px #73368a;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #8b41a5;
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    background: transparent;
    transition: none;
    overflow: hidden;
  }
  
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background: #565656 !important;
    box-shadow: none !important;
    border-color: #565656 !important;
    .animate-normal-mark,
    .animate-starting-mark,
    .animate-speeding-mark {
      display: none;
    }
  }

  &:before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover {
    color: #fff;
    background: transparent;
    border: 1px solid #8b41a5;
    filter: brightness(1.2);
  }

  .animate-normal-mark {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: opacity 1s ease 0s;
    pointer-events: none;
    background-image: url(${specialBtnBgImg});
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 396px 46px;
  }
  .animate-starting-mark {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease 0s;
    pointer-events: none;
    background-image: url(${specialBtnBgAnimateImg});
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    animation: starting-animate 3s steps(89, start) 0s infinite;
  }

  .animate-speeding-mark {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease 0s;
    pointer-events: none;
    background-image: url(${speedingAnimateImg});
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    animation: speeding-animate 3s steps(89, start) 0s infinite;
  }

  &.ant-btn.start-btn-speeding {
    position: relative;
    .animate-normal-mark {
      opacity: 0.2;
    }
    .animate-starting-mark {
      opacity: 1;
    }
  }
  &.ant-btn.start-btn-speeded {
    position: relative;
    .animate-normal-mark {
      opacity: 0.2;
    }
    .animate-speeding-mark {
      opacity: 1;
    }
  }

  @keyframes speeding-animate {
    0% {
      background-position: -0px -0px;
    }
    100% {
      background-position: -35244px -0px;
    }
  }

  @keyframes starting-animate {
    0% {
      background-position: -0px -0px;
    }
    100% {
      background-position: -35244px 0px;
    }
  }
`
