import React, { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { TrialQueueStore, VipStore } from 'src/store'
import { ConfirmButton } from 'src/components/base-button'
import { LoginStore } from 'src/store/modifier'
import { useIntl } from 'react-intl'
import { checkOversea, openServiceView } from 'src/tools/common'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import styled from 'styled-components'
import useQueue from 'src/hooks/use-queue'


const TrialQueueInModal: FC = () => {
  const { cancenQueue, exitQueue } = useQueue()
  const {formatMessage} = useIntl()
  const isOversea = checkOversea()
  const close = ()=>{
    TrialQueueStore.queueVisible=false
  }

  const cancel=()=>{
    close()
    cancenQueue('local')
  }

  const fontUserDom=useMemo(()=>{
    return String((TrialQueueStore.currentQueueInfo?.frontUser||0) +1).split('').map((n, i)=>{
      return <div className='cloudgame-notify-footer-number-item' key={i}>{n}</div>
    })
  },[TrialQueueStore.currentQueueInfo?.frontUser])

  useEffect(()=>{
    //退出时清除排队信息
    !LoginStore.userinfo && useQueue().exitQueue('local')
  },[LoginStore.userinfo])

  return (
    <StyledNotifyModal width={330} visible={TrialQueueStore.queueVisible} onCancel={close}>
      <StyledNotifyContainer>
        <div className='cloudgame-notify-title'>{formatMessage({id:'queue.notification'})}</div>
        <FadeinImage
          width={268}
          height={124}
          src={TrialQueueStore.currentQueueInfo?.gameStartInfo?.listImage}
        />
        <div className='cloudgame-notify-footer-box'>
          <div className='cloudgame-notify-footer-text'>
            {formatMessage({id:'queue.tips', description:'游戏太火爆啦！暂无可用共享账号'})}
          </div>
          <div className='cloudgame-notify-footer-desc'>
            {formatMessage({
              id: 'queue.fontUser',
              description: '当前处于队列第xx位',
            },{
              fontUserDom,
              div: str => <div className="cloudgame-notify-footer-number-box">
                {str}
              </div>
            })}
          </div>
          <div className='cloudgame-notify-footer-actions'>
            {
              !isOversea && (VipStore.memberinfo?.isMember ?
                <ConfirmButton
                  style={{width: 268, height: 36}}
                  onClick={() => {
                    close()
                    openServiceView()
                  }}
                >联系客服补号</ConfirmButton> :
                <ConfirmButton
                  style={{width: 268, height: 36}}
                  onClick={() => {
                    exitQueue('local')
                    close()
                    showUserCenter(NAV_KEY_MAPPING.VIP)
                  }}
                >开通会员享专属账号无需排队 &gt;</ConfirmButton>)
            }
            <ConfirmButton style={{
              width: 268,
              height: 36,
              ...(isOversea ? {} : {
                background: 'none',
                color: '#c38cff',
              })
            }} onClick={cancel}>{formatMessage({id:'queue.cancel', description:'取消排队'})}</ConfirmButton>
          </div>
        </div>
      </StyledNotifyContainer>
    </StyledNotifyModal>
  )
}

const StyledNotifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  //height: 387px;
  box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.14);
  padding-top: 30px;
  padding-bottom: 30px;
  .cloudgame-notify-footer-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cloudgame-notify-footer-text {
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #bbbbbb;
      line-height: 24px;
      padding: 0 30px;
    }
    .cloudgame-notify-footer-desc {
      line-height: 32px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #f4f4f5;
      margin-top: 10px;
      padding: 0 30px;
      //display: flex;
      //flex-wrap: wrap;
      .cloudgame-notify-footer-number-box {
        display: inline-flex;
        align-items: center;
        margin: 0 8px;
        .cloudgame-notify-footer-number-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 32px;
          background: #0a0a0a;
          border-radius: 4px;
          font-size: 24px;
          font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
          font-weight: bold;
          color: #c38cff;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .cloudgame-notify-footer-actions {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .ant-btn {
        font-size: 14px;
        //margin-right: 16px;
        &:last-child {
          margin-top: 16px;
          margin-right: 0;
        }
      }
    }
  }
  .true-img {
    border-radius: 8px;
  }
  .cloudgame-notify-title {
    //width: 197px;
    height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 24px;
  }
  .actions-box {
    position: absolute;
    bottom: 32px;
    right: 50px;
    display: flex;
    align-items: center;
    .resource-button {
      ${props => props.theme.flexCenter}
      width: 100px;
      height: 36px;
    }
  }
`

const StyledNotifyModal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
  }
`

export default observer(TrialQueueInModal)
