import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { appStore, ConfigStore, GameStore, TrialQueueStore, VipStore } from 'src/store'
import { checkOversea, loginGJ, PlatformText } from 'src/tools/common'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { message, Tooltip } from 'antd'
import { handleStopGame } from 'src/hooks/use-handle-game-start'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoginStore } from 'src/store/modifier'
import { gameStartStep } from 'src/pages/game-detail/trial/game-start-step'
import styled from 'styled-components'
import QueueAnim from 'rc-queue-anim'
import dot from 'src/assets/images/trial/dot2.png'
import StepIcon1 from 'src/assets/images/trial/step1.svg'
import StepIcon2 from 'src/assets/images/trial/step2.svg'
import StepIcon3 from 'src/assets/images/trial/step3.svg'
import StepIcon4 from 'src/assets/images/trial/step4.svg'
import CloudSaveIcon from 'src/assets/images/trial/save.svg'
import SelectIcon from 'src/assets/images/trial/down.svg'
import classNames from 'classnames'
import CloudgameStartBtnIcon from 'src/assets/icons/my-game/play.svg'
import ArchiveConfig from 'src/pages/game-detail/trial/archive-config'
import useStartTrial from 'src/pages/game-detail/trial/use-start-trial'
import CEFTransfer from 'src/CEFTransfer'
import PauseBtnIcon from 'src/assets/icons/my-game/pause.svg'
import LoadingIcon from 'src/assets/icons/my-game/spinner.svg'
import TrialGamePlayStore from 'src/store/trial-game-play-store'
import GameStartProgress from './game-start-progress'
import ServiceIcon1 from 'src/assets/images/trial/s1.svg'
import ServiceIcon2 from 'src/assets/images/trial/s2.svg'
import ServiceIcon3 from 'src/assets/images/trial/s3.svg'
import ServiceIcon4 from 'src/assets/images/trial/s4.svg'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'

const Trial:FC<{
  branchId:number
  trialGame:Http.TTrialGameByGameBranch
  gameLibraryMasterDetail?: Game.GameLibraryMasterDetail
}>=({branchId,trialGame,gameLibraryMasterDetail})=>{
  const [platformInstallInfo, setPlatformInstalledInfo] = useState('')
  const {formatMessage} = useIntl()

  const isOversea = checkOversea()

  const service = [
    {
      name: formatMessage({id: 'service.name1'}),
      icon: <ServiceIcon1/>,
      desc: formatMessage({id: 'service.desc1'}),

    },
    {
      name: formatMessage({id: 'service.name2'}),
      icon: <ServiceIcon2/>,
      desc: formatMessage({id: 'service.desc2'}) === 'null' ? null : formatMessage({id: 'service.desc2'}),

    },
    {
      name: formatMessage({id: 'service.name3'}),
      icon: <ServiceIcon3/>,
      desc: formatMessage({id: 'service.desc3'}) === 'null' ? null : formatMessage({id: 'service.desc3'}),
    },
    {
      name: formatMessage({id: 'service.name4'}),
      icon: <ServiceIcon4/>,
      desc: formatMessage({id: 'service.desc4'}),
    }
  ]

  const {
    startTrial,
    finalLocalArchiveList,
    finalCloudArchiveList,
    showArchiveConfig,
    archiveConfigVisible,
    activeArchive,
    setActiveArchive,
    loadAllArchivedList,
    platformTipsVisible,
    setPlatformTipsVisible,
    loadPlayConfig
  } = useStartTrial()

  const platformName= trialGame?.platformName || PlatformText[trialGame.platform]


  //检测平台是否安装
  // const checkPlatformInstalled = async () => {
  //   const result: {
  //     installParams: { id: number; launcherPath: string }[]
  //   } = await CEFTransfer.sendToClient('getPlatformInstallStatus', {
  //     installParams: [{
  //       id: trialGame.platformId,
  //       install: trialGame.platformInstallDiscovery,
  //     }],
  //   })
  //   setPlatformInstalledInfo(result?.installParams?.[0]?.launcherPath)
  // }

  //选择并识别平台
  const selectPlatformPath = async () => {
    try {
      const {status, platformPath} = await CEFTransfer.sendToClient('selectPlatformPath', JSON.parse(trialGame.platformInstallDiscovery))
      //点击取消
      if (status === -1) {
        return
      }
      if (status) {
        message.success(formatMessage({id:'platform.success'}))
        setPlatformInstalledInfo(platformPath)
      } else {
        message.warn(formatMessage({id:'platform.err'}))
      }
    } catch (e) {
      console.log('selectPlatformPath err', e)
    }
  }

  const onDownloadPlatform = () => {
    const link = platformConfig?.downloadUrl
    link && cefOpenUrl(link)
  }

  //继续启动
  const continueStart = () => {
    gameStartStep.checkEnvNext()
  }

  const platformConfig = useMemo(() => {
    try {
      return JSON.parse(trialGame.platformInstallDiscovery)
    } catch (e) {
      return {}
    }
  }, [trialGame])

  const loadArchive = async (gameId:number)=>{
    if(!gameId){
      return
    }
    await loginGJ()
    loadAllArchivedList(gameId)
    loadPlayConfig(gameId)
  }

  const platformPath=useMemo(()=>{
    return platformInstallInfo || GameStore.installedPlatformList.find(value => value.platformId===trialGame.platformId)?.launcherPath
  },[platformInstallInfo, GameStore.installedPlatformList,trialGame])

  const mainButtonProps = useMemo(() => {

    if (TrialGamePlayStore.gameRunStatus === 'waitClose' && TrialGamePlayStore.currentTheStartingGameInfo?.gameMode==='local' &&
      TrialGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId === branchId
    ) {
      return {
        children: (
          <StyledMainButtonContent>
            {/*<CloudgameStartBtnIcon className='icon' />*/}
            <span>{formatMessage({id:'detail.trial.save'})}</span>
          </StyledMainButtonContent>
        ),
        click: () => {},
        className: `start-btn-speeding`,
        disabled: false
      }
    }

    if (appStore.startProblemVisible && TrialGamePlayStore.gameRunStatus === 'starting' &&
      TrialGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId === branchId &&
      TrialGamePlayStore.currentTheStartingGameInfo?.gameMode==='local') {
      return {
        children: (
          <StyledMainButtonContent>
            <PauseBtnIcon className='icon' />
            <span>{formatMessage({id:'detail.trial.stop'})}</span>
          </StyledMainButtonContent>
        ),
        click: () => {
          handleStopGame()
        },
        className: `start-btn-speeded`,
        disabled: false
      }
    }


    if (
      TrialGamePlayStore.currentTheStartingGameInfo?.gameMode==='local' &&
      ((TrialGamePlayStore.gameRunStatus === 'starting' && TrialGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId === branchId) ||
      (TrialQueueStore.queueStatus==='success' && TrialQueueStore.currentQueueInfo?.gameStartInfo?.gameLibraryBranchId === branchId))
    ) {
      if(appStore.gameStartHasEnvError){
        return {
          children: (
            <StyledMainButtonContent>
              <PauseBtnIcon className='icon' />
              <span>{formatMessage({id:'detail.trial.skip'})}</span>
            </StyledMainButtonContent>
          ),
          click: () => {
            continueStart()
          },
          className: `start-btn-speeding continue`,
          disabled: false
        }
      }
      return {
        children: (
          <StyledMainButtonContent>
            <LoadingIcon className='icon-loading' />
            <span>{formatMessage({id:'detail.trial.starting'})}</span>
          </StyledMainButtonContent>
        ),
        click: () => {},
        className: `start-btn-speeding`,
        disabled: true
      }
    }

    if (TrialGamePlayStore.currentTheRunningGameInfo?.gameMode==='local' && TrialGamePlayStore.gameRunStatus === 'running' &&
      TrialGamePlayStore.currentTheRunningGameInfo?.gameLibraryBranchId === branchId) {
      return {
        children: (
          <StyledMainButtonContent>
            <PauseBtnIcon className='icon' />
            <span>{formatMessage({id:'detail.trial.stop2'})}</span>
          </StyledMainButtonContent>
        ),
        click: () => {
          handleStopGame()
        },
        className: `start-btn-speeded`,
      }
    }

    if (TrialQueueStore.queueStatus === 'in' && TrialQueueStore.currentQueueInfo &&
      TrialQueueStore.currentQueueInfo.gameStartInfo?.gameLibraryBranchId === branchId &&
      TrialQueueStore.currentQueueInfo.gameStartInfo?.gameMode==='local'
    ) {
      return {
        children: (
          <StyledMainButtonContent>
            {/*<CloudgameStartBtnIcon className="icon"/>*/}
            <span>{formatMessage({id:'detail.trial.queueing'})}</span>
          </StyledMainButtonContent>
        ),
        click: () => {
          TrialQueueStore.queueVisible=true
        },
        className: `start-btn-speeding`,
      }
    }

    return {
      children: (
        <StyledMainButtonContent>
          <CloudgameStartBtnIcon className='icon' />
          <span>{formatMessage({id:'detail.trial.start'})}</span>
        </StyledMainButtonContent>
      ),
      click: ()=>{
        startTrial({
          gameLibraryMasterId:gameLibraryMasterDetail?.gameLibraryMasterId,
          gameId:trialGame?.boxGameId,
          gameLibraryBranchId:branchId,
          appId:trialGame?.appId,
          platform:trialGame?.platform,
          gameName:checkOversea()? gameLibraryMasterDetail?.enName :gameLibraryMasterDetail?.cnName,
          gameMode:'local',
          isSupportArchive:trialGame?.isSupportArchive,
          listImage:gameLibraryMasterDetail?.listImage,
          squareImage:gameLibraryMasterDetail?.squareImage,
          gamePlatformName:trialGame?.platformName,
          enableShareTractor:trialGame?.enableShareTractor
        }, platformPath)
      },
      className: `start-btn-normal`,
      disabled:false
    }


  }, [appStore.startProblemVisible, platformPath, startTrial,trialGame,gameLibraryMasterDetail,
    TrialGamePlayStore.gameRunStatus,TrialGamePlayStore.currentTheRunningGameInfo,
    TrialGamePlayStore.currentTheStartingGameInfo, branchId,TrialQueueStore.queueStatus, TrialQueueStore.currentQueueInfo,
    appStore.gameStartHasEnvError
  ])

  const faqList= useMemo(()=>{
    return ConfigStore.trialFaq.filter((v)=>{
      return !v.goodsId || v.goodsId.split(',').includes(String(branchId))
    })
  },[ConfigStore.trialFaq])

  // useEffect(()=>{
  //   trialGame && checkPlatformInstalled()
  // },[trialGame])

  const startProgressVisible = useMemo(() => {
    //判断是否显示进度条
    return appStore.CheckModalVisible && ['starting'].includes(TrialGamePlayStore.gameRunStatus) &&
      ((TrialGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId || TrialGamePlayStore.willStartGameId) === branchId)
  }, [appStore.CheckModalVisible, TrialGamePlayStore.gameRunStatus, branchId,
    TrialGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId, TrialGamePlayStore.willStartGameId])


  useEffect(() => {
    if(!LoginStore.userinfo){
      return
    }
    loadArchive(trialGame.boxGameId)
  }, [LoginStore.userinfo, trialGame.boxGameId])

  return <StyledTrial>
    <StyledContent>

      <StyledLeftContent>
        <StyledLeftSectionBox>

          <div className='title'>{formatMessage({id:'detail.trial.description'},{gameName:''})}</div>
          <div className='content'>
            {formatMessage({id:'detail.trial.description2'},{
              platformName,
              gameName:gameLibraryMasterDetail?.enName,
              span:str=><span>{str}</span>
            })}
          </div>

          <div className="step">
            <div className="step-item">
              <StepIcon1/>
              <strong>{formatMessage({id: 'detail.trial.step1'})}</strong>
              <span>{formatMessage({id: 'detail.trial.step1Content'})}</span>
            </div>
            <div className="step-item">
              <StepIcon2/>
              <strong>{formatMessage({id: 'detail.trial.step2'})}</strong>
              <span>{formatMessage({id: 'detail.trial.step2Content'})}</span>
            </div>
            <div className="step-item">
              <StepIcon3/>
              <strong>{formatMessage({id: 'detail.trial.step3'})}</strong>
              <span>{formatMessage({id: 'detail.trial.step3Content'},{platformName:trialGame?.platformName})}</span>
            </div>
            <div className="step-item">
              <StepIcon4/>
              <strong>{formatMessage({id: 'detail.trial.step4'})}</strong>
              <span>{formatMessage({id: 'detail.trial.step4Content'},{platformName:trialGame?.platformName})}</span>
            </div>
          </div>

        </StyledLeftSectionBox>
        <StyledLeftSectionBox>
          <div className="title">{formatMessage({id: 'service.title'})}</div>
          <div className="content">
            <StyledService>
              {
                service.map((value, index) => {
                  return <Tooltip title={value.desc} placement={'bottom'}  key={index} overlayStyle={{maxWidth:'61.8vw'}} overlayInnerStyle={{color:'#F3F3F3'}} color='#121314'>
                    <div className="service-item">{value.icon}{value.name}</div></Tooltip>
                })
              }
            </StyledService>
          </div>
        </StyledLeftSectionBox>
        <StyledLeftSectionBox>
          <div className="title">{formatMessage({id: 'detail.trial.tipsTitle'})}</div>
          <div className="content">
            <StyledTips>
              <FormattedMessage
                tagName='li'
                id='detail.trial.tipsContent1'
              />
              <FormattedMessage
                tagName='li'
                id='detail.trial.tipsContent2'
                values={{
                  span: (str:string) => <span className='strong'>{str}</span>
                }}
              />
            </StyledTips>
          </div>
        </StyledLeftSectionBox>
        <StyledLeftSectionBox>
          <div className='title'>{formatMessage({id: 'detail.trial.tags'})}</div>
          <div className='content'>
            <StyledTags>
              <Tooltip
                title={ !isOversea && (trialGame.isSupportArchive ? '游戏进度保存至 MYBUFF 云端，一键应用；换机、换号不用愁，存档、进度随身走':'当前游戏暂不支持云存档，二次游戏时可能由于使用账号更换而造成游戏进度丢失')}
                 placement={'bottom'}
                 overlayStyle={{maxWidth:'50vw'}}
                 overlayInnerStyle={{color:'#F3F3F3'}}
                 color='#121314'
              >
                <div className="item"><CloudSaveIcon/>{formatMessage({id: trialGame.isSupportArchive? 'detail.trial.supportArchive':'detail.trial.notSupportArchive'})}</div>
              </Tooltip>
            </StyledTags>
          </div>
        </StyledLeftSectionBox>
        {!isOversea && <StyledLeftSectionBox>
          <div className='title'>常见问题：</div>
          <div className='content'>
            <StyledFaq>
              {
                faqList.map((value, index) => {
                  return <li key={index} onClick={() => cefOpenUrl(value.url)}>{value.title}</li>
                })
              }
            </StyledFaq>
          </div>
        </StyledLeftSectionBox>}
      </StyledLeftContent>

      <QueueAnim component={StyledRightContent}>

        <StyledRequirementBox key='StyledSectionBox-1'>
          <div className="title">{formatMessage({id: 'detail.trial.minimum'})}</div>
          <StyledRequirement>
            <div className="item">
              <label>{formatMessage({id: 'detail.trial.os'})}</label>
              <div className='content'>
                {trialGame.lowestOs}
              </div>
            </div>
            <div className="item">
              <label>{formatMessage({id: 'detail.trial.cpu'})}</label>
              <div className='content'>
                {trialGame.lowestCpu}
              </div>
            </div>
            <div className="item">
              <label>{formatMessage({id: 'detail.trial.ram'})}</label>
              <div className='content'>
                {trialGame.lowestRam}
              </div>
            </div>
            <div className="item">
              <label>{formatMessage({id: 'detail.trial.gpu'})}</label>
              <div className='content'>
                {trialGame.lowestGpu}
              </div>
            </div>
          </StyledRequirement>
        </StyledRequirementBox>

        <StyledPlatform key='StyledSectionBox-2'>
          <h4>{formatMessage({id: 'detail.trial.directory'})}</h4>
          <div className="file" onClick={selectPlatformPath}>
            <div className='path'>{platformPath}</div>
            <div className='more'><span/><span/><span/></div>
          </div>
          <div className="tips"
               style={{display:(platformTipsVisible && !platformPath)?'block':'none' }}
          >
           {
             formatMessage({id: 'detail.trial.directoryTips'},{
             platformName,
             span:(str)=> {
                return <span onClick={onDownloadPlatform}>{str}</span>
              }
            })
           }
          </div>
        </StyledPlatform>
      </QueueAnim>

    </StyledContent>
    <StyledBottom>
      {
        startProgressVisible ?
          <GameStartProgress /> :
          (!!finalLocalArchiveList?.length || !!finalCloudArchiveList?.length) && <StyledArchive>
            <label>{formatMessage({id:'detail.trial.select'})}</label>
            <div
              className={classNames('select',{
                disabled: TrialGamePlayStore.gameRunStatus!=='stop' && TrialGamePlayStore.currentTheStartingGameInfo?.gameLibraryBranchId === branchId
              })}
              onClick={()=>showArchiveConfig(()=>null, ()=>null)}
            >
              <span>{activeArchive?.new_name|| activeArchive?.name || formatMessage({id:'detail.save.noUse'})}</span>
              {/*<SelectIcon/>*/}
              <DownOutlined />
            </div>
          </StyledArchive>
      }

      <StyledMainButton
        onClick={mainButtonProps.click}
        className={classNames(mainButtonProps.className,{
          disabled:mainButtonProps.disabled,
          disabled1:mainButtonProps.disabled
        })}
      >
        {mainButtonProps.children}
      </StyledMainButton>
    </StyledBottom>

    <ArchiveConfig
      visible={!!archiveConfigVisible}
      activeArchive={activeArchive}
      setActiveArchive={setActiveArchive}
      onOk={archiveConfigVisible?.resolve}
      onCancel={archiveConfigVisible?.reject}
      loadAllArchivedList={loadAllArchivedList}
      gameId={trialGame.boxGameId??0}
      gameMode='local'
      finalLocalArchiveList={finalLocalArchiveList}
      finalCloudArchiveList={finalCloudArchiveList}
    />

  </StyledTrial>

}

export const StyledContent = styled.div`
  display: flex;
  height: calc(100% - 77px);
`

export const StyledRightContent = styled.div`
  width: 422px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  &:before{
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, #42464D 0%, rgba(66,70,77,0) 100%);
  }
`

export const StyledLeftContent = styled.div`
  flex: 1;  
  overflow: auto;
`


export const StyledLeftSectionBox = styled.div`
  position: relative;
  padding: 32px;
  &:after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: linear-gradient(180deg, #42464D 0%, rgba(66,70,77,0) 100%);
  }
  &:last-child{
    &:after{
      display: none;
    }
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 16px;
    font-weight: bold;
  }
  .content {
    font-size: 14px;
    color: #C6C6C6;
    line-height: 24px;
    span{
      color: #fff;
    }
  }
  .step{
    margin-top: 32px;
    .step-item{
      font-size: 14px;
      color: #96989d;
      line-height: 16px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 8px;
        top: 24px;
        height: 25px;
        width: 1px;
        background: #373737;
      }
      &:last-child{
        margin-bottom: 0;
        &:after{
          display: none;
        }
      }
      >svg{
        margin-right: 9px;
      }
      >strong{
        color: #fff;
        margin-right: 8px;
      }
    }
  }
`

export const StyledArchive = styled.div`
  margin-left: 32px;
  label{
    display: block;
    font-size: 13px;
    line-height: 1;
    color: #96989d;
    margin-bottom: 8px;
  }
  .select{
    height: 32px;
    background: #25282C;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    color: #fff;
    min-width: 338px;
    cursor: pointer;
    >svg{
      margin-left: 12px;
    }
    &.disabled{
      opacity: .5;
      cursor: default;
      pointer-events: none;
    }
  }
  
  
`

export const StyledBottom=styled.footer`
  width: 100%;  
  height: 77px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(255,255,255,0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledMainButton = styled.div`
  cursor: pointer;
  width: 358px;
  height: 53px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7044CD;
  border-radius: 3px 3px 3px 3px;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s;  
  &.disabled{
    background: #473077;
    cursor: default;
    color: rgba(255,255,255,0.5);
  }
  &.disabled1{
    background: none;
  }
  &:not(.disabled):hover {
    background: #7a4cdc;
  }
  &.continue{
    animation-timing-function: ease-in-out;
    animation-name: breathe;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0.5s;
  }
  @keyframes breathe {
    0% {
      //opacity: 0.95;
      //box-shadow: 0 0 2px #ffde56;
      transform: scale(1) translateY(-50%);
    }
    100% {
      //opacity: 1;
      //font-size: 16px;
      transform: scale(1.05) translateY(-50%);
      //box-shadow: 0 0 30px var(--main-btn-gradient-light-color);
    }
  }
`

export const StyledMainButtonContent = styled.div`
  ${props => props.theme.flexAlignCenter}
  >svg{
    margin-right: 10px;
  }
  .icon-loading{
    animation: rotating 2s linear infinite;
  }
  @keyframes rotating {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
   }
  }
`

const StyledPlatform=styled.div`
  color: #96989D;
  padding: 32px 32px 10px;
  >h4{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #fff;    
  }
  .file{
    height: 32px;
    background: #25282C;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    cursor: pointer;
  }
  .path{
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
  }
  .more{
    display: flex;
    align-items: center;
    >span{
      width: 4px;
      height: 4px;
      background: #96989D;
      margin-left: 2px;
      border-radius: 50%;
    }
  }
  .tips{
    font-size: 12px;
    line-height: 22px;
    margin-top: 16px;
    color: #96989d;
    >strong{
      color: #fff;
      font-weight: normal;
    }
    >span{
      text-decoration: underline;
      color: #c38cff;
      cursor: pointer;      
    }
  }
  
`

const StyledRequirementBox=styled.div`
  flex: 1;
  overflow: auto;
  padding: 32px;
  .title{
    line-height: 24px;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
`

const StyledRequirement=styled.div`
  font-size: 14px;
  color: #C6C6C6;
  line-height: 22px;
  .item{    
    margin-top: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    >label{
      display: block;
      color: #96989d;
    }
    
  }
  
`

const StyledFaq=styled.ul`
  padding-left: 20px;
  >li{
    line-height: 32px;
    cursor: pointer;
    height: 34px;
    font-size: 12px;
    color: #F0F0F0;
    position: relative;    
    &:hover{
      text-decoration: underline;
    }
    &:before{
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #C300DD;
    }
  }
`

const StyledTags= styled.div`
  display: flex;
  .item{
    display: flex;
    align-items: center;
    margin-right: 16px;
    height: 44px;
    background: rgba(255,255,255,0.08);
    border-radius: 3px;
    color: #c6c6c6;
    font-size: 14px;
    padding: 0 12px;
    >svg{
      margin-right: 8px;
    }
  }
  
`

const StyledTips=styled.ul`
  li{
    line-height: 21px;
    margin-bottom: 18px;
    background: url("${dot}") left 7px no-repeat;
    text-indent: 12px;
    color: #c6c6c6;
    .strong{
      color: #fff;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }  
`

const StyledService=styled.div`
  display: flex;
  flex-wrap: wrap;
  .service-item{
    margin-right: 8px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 44px;
    background: rgba(255,255,255,0.08);
    border-radius: 3px 3px 3px 3px;
    color: #C6C6C7;
    font-size: 14px;
    margin-bottom: 8px;
    >svg{
      margin-right: 8px;
    }
  }  
`

const StyledTrial=styled.div`
  height: 100%;
`


export default observer(Trial)