import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { Collapse } from 'antd'
import CEFTransfer from 'src/CEFTransfer'
import styled from 'styled-components'
import classNames from 'classnames'

const { Panel } = Collapse
const PreRead: FC<{ notes: string | null }> = ({ notes }) => {
  if (!notes) {
    return null
  }
  const handleEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
      CEFTransfer.sendToClient('openUrl', { url: e.target.href })
    }
  }
  const handleDragStartEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
    }
  }
  return (
    <StyledPreReadContainer onClick={handleEventDelegation} onDragStart={handleDragStartEventDelegation}>
      <Collapse bordered={false} defaultActiveKey={['1']}>
        <Panel header='运行游戏前阅读' key='1'>
          <div dangerouslySetInnerHTML={{ __html: notes || '' }} />
        </Panel>
      </Collapse>
    </StyledPreReadContainer>
  )
}

const StyledPreReadContainer = styled.div`
  background-color: transparent;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  a {
    color: ${props => props.theme.color.text.$C598FF} !important;
    &:hover {
      filter: brightness(1.1) !important;
    }
  }
  .ant-collapse-borderless {
    background: ${props => props.theme.color.bg.$FFFFFF12};
    .ant-collapse-item {
      border: none;
    }
    .ant-collapse-header {
      color: var(--text-color-FFFFFF);
      font-size: 18px;
      line-height: 20px;
      font-weight: bold;
      padding: 15.5px 22px;
      display: flex;
      align-items: center;
      .ant-collapse-arrow {
        font-size: 15px;
        line-height: 15px;
        margin-right: 8px;
        vertical-align: baseline;
      }
    }
    .ant-collapse-content-box {
      color: var(--text-color-FFFFFF);
      padding-left: 22px;
      padding-right: 22px;
      font-size: 14px;
      line-height: 24px;
      img {
        max-width: 100% !important;
        height: auto !important;
        width: auto !important;
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        color: var(--text-color-FFFFFF);
      }
    }
  }
`

export default observer(PreRead)
