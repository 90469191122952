import { LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS } from 'src/constants/storage'
import moment from 'moment'
class StorageTool<T extends 'local' | 'session'> {
  storage: Storage

  constructor(storageType: 'local' | 'session' = 'local') {
    this.storage = storageType === 'local' ? window.localStorage : window.sessionStorage
  }

  get(key: T extends 'local' ? LOCALSTORAGE_KEYS : SESSIONSTORAGE_KEYS) {
    const value = this.storage.getItem(String(key))
    try {
      const data = value ? JSON.parse(value) : value
      if (data !== null) {
        if (data.cache) {
          if (moment(data.cache).isAfter(moment())) {
            return data.data
          }
          this.remove(key)
          return null
        }
        return data.data
      }
      return data
    } catch (error) {
      return value
    }
  }

  set(
    key: T extends 'local' ? LOCALSTORAGE_KEYS : SESSIONSTORAGE_KEYS,
    data: any,
    option?: { expires: string | Date },
  ) {
    let cache: string | Date = ''
    if (option?.expires) {
      try {
        cache = new Date(option.expires)
      } catch (error) {
        console.warn(`set storage ${key} expires error`)
      }
    }
    return this.storage.setItem(String(key), JSON.stringify({ data, cache }))
  }

  remove(key: T extends 'local' ? LOCALSTORAGE_KEYS : SESSIONSTORAGE_KEYS) {
    return this.storage.removeItem(String(key))
  }

  clear() {
    return this.storage.clear()
  }
}

export const localStorageTool = new StorageTool<'local'>('local')

export const sessionStorageTool = new StorageTool<'session'>('session')
