import { observer } from 'mobx-react'
import { NavLink, RouteObject, useRoutes, useLocation } from 'react-router-dom'
import { appStore, GameStore } from 'src/store'
import { analysisSupportTypeByGameLibraryMaster, analysisSupportTypeByGamePlatform } from 'src/tools/common'
import { ToolsTypes } from 'src/constants/common'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useUrlState from '@ahooksjs/use-url-state'
import useLayoutLeft from 'src/hooks/use-layout-left'
import GameSearch from 'src/pages/game-search'
import PlatformSearch from 'src/pages/platform-search'
import SGCheckbox from 'src/components/base-checkbox'
import Index from 'src/pages/home-cn'
import HomeIcon from 'src/assets/icons/common/home.svg'
import GameIcon from 'src/assets/icons/common/game.svg'
import PlatformIcon from 'src/assets/icons/common/platform.svg'
import RightArrowsIcon from 'src/assets/icons/home/right-arrows.svg'
import classNames from 'classnames'

const routes: RouteObject[] = [
  { path: '/', element: <Index /> },
  { path: '/game-search', element: <GameSearch /> },
  { path: '/platform-search', element: <PlatformSearch /> },
]

const filterIsInstalledByGame = (type: '1' | '0') => {
  if (['1'].includes(type)) {
    return [...GameStore.installedGameList]
  }
  return [...GameStore.installedGameList, ...GameStore.notInstalledGameList]
}

const filterIsInstalledByPlatform = (type: '1' | '0') => {
  if (['1'].includes(type)) {
    return [...GameStore.installedPlatformList]
  }
  return [...GameStore.installedPlatformList, ...GameStore.notInstalledPlatformList]
}

const Main = () => {
  const location = useLocation()
  const [state, setState] = useUrlState(
    {
      isInstalled: '0',
      supportSpeed: '0',
      supportResource: '0',
      supportModifier: '0',
      supportCloudGame: '0',
      supportPlatformWelfare: '0',
      supportTrial: '0',
      keywords: '',
      sortKey: 'normal',
      sortRule: '',
    },
    {
      navigateMode: 'replace',
    },
  )
  type TStateKey = keyof typeof state
  const currentPathKey = useMemo(() => {
    if (location.pathname === '/main/game-search') {
      return 'game'
    }
    if (location.pathname === '/main/platform-search') {
      return 'platform'
    }
    return ''
  }, [location.pathname])
  const filterOtherCondByPlatform = (s: typeof state, list: Game.GamePlatform[]) => {
    const key = (state.keywords?.trim() || '').toLowerCase()
    const needFilter = !!key || [s.supportPlatformWelfare, s.supportSpeed].includes('1')
    const finalList = !needFilter
      ? list
      : list.filter(f => {
          if (key) {
            const originName = f.platformEnName?.toLowerCase() || ''
            const originZhCnName = f.platformName?.toLowerCase() || ''
            const valid = originName.includes(key) || originZhCnName.includes(key)
            if (!valid) {
              return false
            }
          }
          let speedValid = s.supportSpeed === '1' ? false : true
          let platformWelfareValid = s.supportPlatformWelfare === '1' ? false : true
          for (let relate of f.relates) {
            if (!platformWelfareValid && relate.targetRelateType === ToolsTypes.PLATFORM_WELFARE) {
              platformWelfareValid = true
            }
            if (!speedValid && relate.targetRelateType === ToolsTypes.SPEED_GAME) {
              speedValid = true
            }
          }
          return speedValid && platformWelfareValid
        })

    let supportPlatformWelfareNum = 0
    let supportSpeedNum = 0
    let installedNum = 0
    finalList.forEach(f => {
      const { supportSpeed, supportWelfare } = analysisSupportTypeByGamePlatform(f)
      let isInstalled = false
      if (!!GameStore.installedPlatformList.find(d => d.platformId === f.platformId)) {
        isInstalled = true
      }
      if (supportWelfare) {
        supportPlatformWelfareNum += 1
      }
      if (supportSpeed) {
        supportSpeedNum += 1
      }
      if (isInstalled) {
        installedNum += 1
      }
    })
    return {
      supportPlatformWelfareNum,
      supportSpeedNum,
      installedNum,
    }
  }
  const filterOtherCondByGame = (s: typeof state, list: Game.GameLibraryMaster[]) => {
    const key = (state.keywords?.trim() || '').toLowerCase()
    const needFilter =
      !!key || [s.supportModifier, s.supportResource, s.supportSpeed, s.supportCloudGame, s.supportTrial].includes('1')
    const finalList = !needFilter
      ? list
      : list.filter(f => {
          if (key) {
            const originName = f.gameLibraryMasterEnName?.toLowerCase() || ''
            const originZhCnName = f.gameLibraryMasterCnName?.toLowerCase() || ''
            const valid =
              originName.includes(key) ||
              originZhCnName.includes(key) ||
              f.gameLibraryMasterAlias?.split(',').find(v => v.trim().toLowerCase().includes(key))
            if (!valid) {
              return false
            }
          }
          let modifierValid = s.supportModifier === '1' ? false : true
          let speedValid = s.supportSpeed === '1' ? false : true
          let resourceValid = s.supportResource === '1' ? false : true
          let cloudGameValid = s.supportCloudGame === '1' ? false : true
          let trialValid = s.supportTrial === '1' ? false : true
          const valid = f.gameLibraryBranches.some(e => {
            if (!resourceValid && e.resources.length !== 0) {
              resourceValid = true
            }
            for (let relate of e.relates) {
              if (modifierValid && speedValid && cloudGameValid && trialValid) {
                break
              }
              if (!modifierValid && relate.targetRelateType === ToolsTypes.TRAINER_GAME) {
                modifierValid = true
              }
              if (!cloudGameValid && relate.targetRelateType === ToolsTypes.CLOUD_GAME) {
                cloudGameValid = true
              }
              if (!trialValid && relate.targetRelateType === ToolsTypes.TRIAL_GAME) {
                trialValid = true
              }
              if (!speedValid && relate.targetRelateType === ToolsTypes.SPEED_GAME) {
                speedValid = true
              }
            }
            return modifierValid && speedValid && resourceValid && cloudGameValid && trialValid
          })
          return valid
        })

    let supportModifierNum = 0
    let supportCloudGameNum = 0
    let supportTrialNum = 0
    let supportResourceNum = 0
    let supportSpeedNum = 0
    let installedNum = 0
    finalList.forEach(f => {
      const { supportSpeed, supportResource, supportTrial, supportCloudGame, supportModifier } =
        analysisSupportTypeByGameLibraryMaster(f)
      let isInstalled = false
      if (!!GameStore.installedGameList.find(d => d.gameLibraryMasterId === f.gameLibraryMasterId)) {
        isInstalled = true
      }
      if (supportModifier) {
        supportModifierNum += 1
      }
      if (supportCloudGame) {
        supportCloudGameNum += 1
      }
      if (supportTrial) {
        supportTrialNum += 1
      }
      if (supportResource) {
        supportResourceNum += 1
      }
      if (supportSpeed) {
        supportSpeedNum += 1
      }
      if (isInstalled) {
        installedNum += 1
      }
    })
    return {
      supportModifierNum,
      supportCloudGameNum,
      supportTrialNum,
      supportResourceNum,
      supportSpeedNum,
      installedNum,
    }
  }
  const {
    supportModifierNum,
    supportCloudGameNum,
    supportTrialNum,
    supportResourceNum,
    supportSpeedNum: supportSpeedNumByGame,
    installedNum: installedNumByGame,
  } = useMemo(() => {
    if (GameStore.gameMasterList) {
      let list = filterIsInstalledByGame(state.isInstalled)
      const {
        supportModifierNum,
        supportResourceNum,
        supportSpeedNum,
        supportCloudGameNum,
        supportTrialNum,
        installedNum,
      } = filterOtherCondByGame(state, list)
      return {
        supportModifierNum,
        supportResourceNum,
        supportSpeedNum,
        supportCloudGameNum,
        supportTrialNum,
        installedNum,
      }
    }
    return {}
  }, [
    GameStore.gameMasterList,
    state.keywords,
    state.isInstalled,
    state.supportModifier,
    state.supportCloudGame,
    state.supportTrial,
    state.supportResource,
    state.supportSpeed,
    GameStore.installedGameList,
    GameStore.notInstalledGameList,
  ])

  const { supportSpeedNumByPlatform, supportPlatformWelfareNum, installedNumByPlatform } = useMemo(() => {
    if (GameStore.platformList) {
      let list = filterIsInstalledByPlatform(state.isInstalled)
      const {
        supportSpeedNum: supportSpeedNumByPlatform,
        installedNum: installedNumByPlatform,
        supportPlatformWelfareNum,
      } = filterOtherCondByPlatform(state, list)
      return {
        supportSpeedNumByPlatform,
        supportPlatformWelfareNum,
        installedNumByPlatform,
      }
    }
    return {}
  }, [
    GameStore.platformList,
    GameStore.installedPlatformList,
    GameStore.notInstalledPlatformList,
    state.keywords,
    state.isInstalled,
    state.supportSpeed,
    state.supportPlatformWelfare,
  ])

  const filterList = [
    {
      key: 'isInstalled',
      text: '本地已安装',
      num: currentPathKey === 'game' ? installedNumByGame : installedNumByPlatform,
      checkHidden: null,
    },
    {
      key: 'supportSpeed',
      text: '支持加速',
      num: currentPathKey === 'game' ? supportSpeedNumByGame : supportSpeedNumByPlatform,
      checkHidden: null,
    },
    {
      key: 'supportModifier',
      text: '支持修改',
      num: supportModifierNum,
      checkHidden: () => {
        return !appStore.isInWhiteList || currentPathKey !== 'game'
      },
    },
    {
      key: 'supportTrial',
      text: '提供共享账号',
      num: supportTrialNum,
      checkHidden: () => {
        return currentPathKey !== 'game'
      },
    },
    {
      key: 'supportCloudGame',
      text: '支持云游戏',
      num: supportCloudGameNum,
      checkHidden: () => {
        return currentPathKey !== 'game'
      },
    },
    {
      key: 'supportResource',
      text: '提供资源',
      num: supportResourceNum,
      checkHidden: () => {
        return currentPathKey !== 'game'
      },
    },
    {
      key: 'supportPlatformWelfare',
      text: '喜加一',
      num: supportPlatformWelfareNum,
      checkHidden: () => {
        return currentPathKey !== 'platform'
      },
    },
  ].filter(f => !f.checkHidden || !f.checkHidden()) as { key: TStateKey; [p: string]: any }[]

  const handleChangeIsInstalled = (key: TStateKey, name?: string) => {
    const isCheck = state[key] === '1'
    setState(value => ({ ...value, [key]: isCheck ? '0' : '1' }))
  }

  const renderRoutes = useRoutes(routes)

  return (
    <>
      {useLayoutLeft(
        <StyledMainLeft>
          <h3>分类</h3>
          <StyledNav>
            <NavLink className='item' to='/'>
              <div className='item-title'>
                <HomeIcon />
                首页
              </div>
            </NavLink>
            <NavLink className='item' to='/main/game-search'>
              <div className='item-title'>
                <GameIcon />
                游戏
              </div>
              <RightArrowsIcon className={classNames('arrows-icon', { active: currentPathKey === 'game' })} />
            </NavLink>
            <StyledFilterContainer
              style={{
                height: currentPathKey === 'game' ? filterList.length * 64 - 8 : 0,
                opacity: currentPathKey === 'game' ? 1 : 0,
              }}
            >
              {filterList.map(d => {
                return (
                  <StyledFilterItemContainer>
                    <SGCheckbox
                      checked={['1'].includes(state[d.key])}
                      onChange={e => handleChangeIsInstalled(d.key, d.text)}
                    >
                      {d.text}
                    </SGCheckbox>
                    <div className='filter-item'>{d.num || 0}</div>
                    <div className='mask' onClick={() => handleChangeIsInstalled(d.key, d.text)} />
                  </StyledFilterItemContainer>
                )
              })}
            </StyledFilterContainer>
            <NavLink className='item' to='/main/platform-search'>
              <div className='item-title'>
                <PlatformIcon />
                平台
              </div>
              <RightArrowsIcon className={classNames('arrows-icon', { active: currentPathKey === 'platform' })} />
            </NavLink>
            <StyledFilterContainer
              style={{
                height: currentPathKey === 'platform' ? filterList.length * 64 - 8 : 0,
                opacity: currentPathKey === 'platform' ? 1 : 0,
              }}
            >
              {filterList.map(d => {
                return (
                  <StyledFilterItemContainer>
                    <SGCheckbox
                      checked={['1'].includes(state[d.key])}
                      onChange={e => handleChangeIsInstalled(d.key, d.text)}
                    >
                      {d.text}
                    </SGCheckbox>
                    <div className='filter-item'>{d.num || 0}</div>
                    <div className='mask' onClick={() => handleChangeIsInstalled(d.key, d.text)} />
                  </StyledFilterItemContainer>
                )
              })}
            </StyledFilterContainer>
          </StyledNav>
        </StyledMainLeft>,
      )}
      {renderRoutes}
    </>
  )
}

const StyledFilterItemContainer = styled.div`
  height: 56px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .filter-item {
    font-size: 12px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: 400;
    color: #96989d;
    line-height: 16px;
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
  }
`

const StyledFilterContainer = styled.div`
  width: 224px;
  /* height: 0px; */
  opacity: 0;
  background: #212226;
  border-radius: 4px;
  transition: all 150ms ease 0s;
  overflow: hidden;
  /* &.active { */
  /* height: 376px; */
  /* } */
`

const StyledNav = styled.div`
  .item {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px;
    color: #96989d;
    font-size: 16px;
    padding: 0 12px;
    .arrows-icon {
      transform: rotate(0deg);
      transition: all 150ms ease 0s;
      &.active {
        transform: rotate(90deg);
      }
    }
    .item-title {
      display: flex;
      align-items: center;
      > svg {
        margin-right: 12px;
      }
    }
    &.active,
    &:hover {
      color: #fff;
    }
    &.active {
      background: #7044cd;
    }
  }
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
`

const StyledMainLeft = styled.div`
  width: 240px;
  padding: 8px;
  > h3 {
    height: 40px;
    color: #96989d;
    font-size: 13px;
    padding-left: 12px;
    display: flex;
    align-items: center;
  }
`

const StyledMainRight = styled.div``

const StyledMain = styled.div``

export default observer(Main)
