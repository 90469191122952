import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import { Tag } from 'antd'
import { observer } from 'mobx-react'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { throttle } from 'lodash'
import { GameStore, appStore } from 'src/store'
import { analysisSupportTypeByGameLibraryMaster } from 'src/tools/common'
import { SearchInput } from 'src/components/base-input'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { ToolsTypes } from 'src/constants/common'
import { TweenOneGroup } from 'rc-tween-one'
import SGCheckbox from 'src/components/base-checkbox'
import GameItem from 'src/pages/game-search/game-item'
import styled from 'styled-components'
import classNames from 'classnames'
import useUrlState from '@ahooksjs/use-url-state'

import emptyImg from 'src/assets/images/common/empty.png'
import SearchIcon from 'src/assets/icons/layout/search-icon.svg'
import TopIcon from 'src/assets/icons/common/top.svg'

import SpeedIcon from 'src/assets/icons/game-search/speed.svg'
import ModifyIcon from 'src/assets/icons/game-search/modify.svg'
import CloudIcon from 'src/assets/icons/game-search/cloud.svg'
import InstalledIcon from 'src/assets/icons/game-search/installed.svg'
import ResourceIcon from 'src/assets/icons/game-search/resource.svg'
import ShareIcon from 'src/assets/icons/game-search/share.svg'

import CheckedIcon from 'src/assets/icons/game-search/checked.svg'

type TSortKey = 'normal' | 'updatetime'
type TSortRule = 'desc' | 'asc' | ''

export type TElementSizeObj = {
  blockWidth: number
  blockHeight: number
  blockImgHeight: number
  blockNumByRow: number
}

// export type TVirtualListObj = {
//   startIndex: number
//   endIndex: number
// }

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

// const filterKeywords = (k: string, list: Game.GameLibraryMaster[]) => {
//   if (!k) {
//     return list
//   }
//   return list.filter(f => {
//     const originName = f.gameLibraryMasterEnName?.toLowerCase() || ''
//     const originZhCnName = f.gameLibraryMasterCnName?.toLowerCase() || ''
//     return originName.includes(k) || originZhCnName.includes(k)
//   })
//   // return list.filter(f => {
//   //   const aliasList = f.alias.split(',').map(d => d.toLowerCase())
//   //   const originName = f.name?.toLowerCase() || ''
//   //   const originZhCnName = f.zhCnName?.toLowerCase() || ''
//   //   return aliasList.find(alias => alias.includes(k)) || originName.includes(k) || originZhCnName.includes(k)
//   // })
// }

const filterIsInstalled = (type: '1' | '0') => {
  if (['1'].includes(type)) {
    // return GameStore.gameMasterList
    return [...GameStore.installedGameList]
  }
  return [...GameStore.installedGameList, ...GameStore.notInstalledGameList]
}

const computedKeySortWeight = (d: Game.GameLibraryMaster, key: string) => {
  const cName = d.gameLibraryMasterCnName.toLocaleLowerCase() || ''
  const eName = d.gameLibraryMasterEnName.toLocaleLowerCase() || ''
  /**
   * 0 中文名精确匹配
   * 1 英文名精确匹配
   * 2 别名精确匹配
   * 3 中文名模糊匹配
   * 4 英文模糊匹配
   * 5 别名模糊匹配
   */
  if (cName === key) {
    return 0
  }
  if (eName === key) {
    return 1
  }
  let res = 5
  if (eName.includes(key)) {
    res = 4
  }
  if (cName.includes(key)) {
    res = 3
  }
  if (d.gameLibraryMasterAlias) {
    for (let z of d.gameLibraryMasterAlias.split(',')) {
      if (z === key) {
        res = 2
        return res
      }
      if (z.includes(key)) {
        res = Math.min(res, 5)
      }
    }
  }
  return res
}

const sortListByKey = (list: Game.GameLibraryMaster[], key: string): Game.GameLibraryMaster[] => {
  const keywords = key?.trim().toLocaleLowerCase()
  return list.sort((a, b) => computedKeySortWeight(a, keywords) - computedKeySortWeight(b, keywords))
}

const CONTENT_PADDING_LEFT = 0
const ITEM_MARGIN_RIGHT = 26
const ITEM_MIN_WIDTH = 158 + ITEM_MARGIN_RIGHT
const ITEM_MAX_WIDTH = 197 + ITEM_MARGIN_RIGHT
const ITEM_MARGIN_BOTTOM = 20
const ITEM_IMAGE_ASPECT_RATIO = 1 / 1.5

const entryMap = {
  supportSpeed: 'speed',
  supportResource: 'resource',
  supportModifier: 'modifier',
  supportCloudGame: 'branch',
  supportTrial: 'trial',
}

const GameSearch: FC = () => {
  const navigate = useNavigate()
  const [state, setState] = useUrlState(
    {
      isInstalled: '0',
      supportSpeed: '0',
      supportResource: '0',
      supportModifier: '0',
      supportCloudGame: '0',
      supportTrial: '0',
      keywords: '',
      sortKey: 'normal',
      sortRule: '',
    },
    {
      navigateMode: 'replace',
    },
  )
  type TStateKey = keyof typeof state
  const ListContentRef = useRef<HTMLDivElement>(null)
  const [itemSizeObj, setItemSizeObj] = useState<TElementSizeObj>()
  const tags = useMemo<{ key: TStateKey; name: string }[]>(() => {
    let temp = []
    state.supportSpeed === '1' &&
      temp.push({
        key: 'supportSpeed' as TStateKey,
        name: '支持加速',
      })
    state.supportModifier === '1' &&
      temp.push({
        key: 'supportModifier' as TStateKey,
        name: '支持修改',
      })
    state.supportCloudGame === '1' &&
      temp.push({
        key: 'supportCloudGame' as TStateKey,
        name: '支持云游戏',
      })
    state.supportTrial === '1' &&
      temp.push({
        key: 'supportTrial' as TStateKey,
        name: '提供共享账号',
      })
    state.supportResource === '1' &&
      temp.push({
        key: 'supportResource' as TStateKey,
        name: '提供资源',
      })
    state.isInstalled === '1' &&
      temp.push({
        key: 'isInstalled' as TStateKey,
        name: '本地已安装',
      })
    return temp
  },[state.supportSpeed, state.supportModifier, state.supportCloudGame, state.supportTrial, state.supportResource, state.isInstalled])
  // const [virtualListObj, setVirtualListObj] = useState<TVirtualListObj>()
  const [virtualListStartIndex, setVirtualListStartIndex] = useState<number | null>(null)
  const [virtualListEndIndex, setVirtualListEndIndex] = useState<number | null>(null)
  const [viewTopButon, setViewTopButton] = useState<boolean>(false)

  const filterOtherCond = (s: typeof state, list: Game.GameLibraryMaster[]) => {
    const key = (state.keywords?.trim() || '').toLowerCase()
    const needFilter =
      !!key || [s.supportModifier, s.supportResource, s.supportSpeed, s.supportCloudGame, s.supportTrial].includes('1')
    const finalList = !needFilter
      ? list
      : list.filter(f => {
          if (key) {
            const originName = f.gameLibraryMasterEnName?.toLowerCase() || ''
            const originZhCnName = f.gameLibraryMasterCnName?.toLowerCase() || ''
            const valid =
              originName.includes(key) ||
              originZhCnName.includes(key) ||
              f.gameLibraryMasterAlias?.split(',').find(v => v.trim().toLowerCase().includes(key))
            if (!valid) {
              return false
            }
          }
          let modifierValid = s.supportModifier === '1' ? false : true
          let speedValid = s.supportSpeed === '1' ? false : true
          let resourceValid = s.supportResource === '1' ? false : true
          let cloudGameValid = s.supportCloudGame === '1' ? false : true
          let trialValid = s.supportTrial === '1' ? false : true
          const valid = f.gameLibraryBranches.some(e => {
            if (!resourceValid && e.resources.length !== 0) {
              resourceValid = true
            }
            for (let relate of e.relates) {
              if (modifierValid && speedValid && cloudGameValid && trialValid) {
                break
              }
              if (!modifierValid && relate.targetRelateType === ToolsTypes.TRAINER_GAME) {
                modifierValid = true
              }
              if (!cloudGameValid && relate.targetRelateType === ToolsTypes.CLOUD_GAME) {
                cloudGameValid = true
              }
              if (!trialValid && relate.targetRelateType === ToolsTypes.TRIAL_GAME) {
                trialValid = true
              }
              if (!speedValid && relate.targetRelateType === ToolsTypes.SPEED_GAME) {
                speedValid = true
              }
            }
            return modifierValid && speedValid && resourceValid && cloudGameValid && trialValid
          })
          return valid
        })

    let supportModifierNum = 0
    let supportCloudGameNum = 0
    let supportTrialNum = 0
    let supportResourceNum = 0
    let supportSpeedNum = 0
    let installedNum = 0
    finalList.forEach(f => {
      const { supportSpeed, supportResource, supportTrial, supportCloudGame, supportModifier } =
        analysisSupportTypeByGameLibraryMaster(f)
      let isInstalled = false
      if (!!GameStore.installedGameList.find(d => d.gameLibraryMasterId === f.gameLibraryMasterId)) {
        isInstalled = true
      }
      if (supportModifier) {
        supportModifierNum += 1
      }
      if (supportCloudGame) {
        supportCloudGameNum += 1
      }
      if (supportTrial) {
        supportTrialNum += 1
      }
      if (supportResource) {
        supportResourceNum += 1
      }
      if (supportSpeed) {
        supportSpeedNum += 1
      }
      if (isInstalled) {
        installedNum += 1
      }
    })
    return {
      list: finalList,
      supportModifierNum,
      supportCloudGameNum,
      supportTrialNum,
      supportResourceNum,
      supportSpeedNum,
      installedNum,
    }
  }

  const allGameList = useMemo(() => {
    return [...GameStore.installedGameList, ...GameStore.notInstalledGameList]
  }, [GameStore.installedGameList, GameStore.notInstalledGameList])

  const {
    data: finalTitleList,
    total,
  } = useMemo(() => {
    if (GameStore.gameMasterList) {
      let list = filterIsInstalled(state.isInstalled)
      const {
        list: finalList,
        supportModifierNum,
        supportResourceNum,
        supportSpeedNum,
        supportCloudGameNum,
        supportTrialNum,
        installedNum,
      } = filterOtherCond(state, list)
      list = sortListByKey(finalList, state.keywords)
      const start = 0
      const end = list.length
      return {
        data: list.slice(start, end),
        total: list.length,
        supportModifierNum,
        supportResourceNum,
        supportSpeedNum,
        supportCloudGameNum,
        supportTrialNum,
        installedNum,
      }
    }
    return {
      data: [],
      total: 0,
    }
  }, [
    GameStore.gameMasterList,
    state.keywords,
    state.isInstalled,
    state.supportModifier,
    state.supportCloudGame,
    state.supportTrial,
    state.supportResource,
    state.supportSpeed,
    GameStore.installedGameList,
    GameStore.notInstalledGameList,
  ])

  // const filterList: { key: TStateKey; [p: string]: any }[] = [
  //   {
  //     key: 'isInstalled',
  //     text: '本地已安装',
  //     icon: <InstalledIcon />,
  //     num: installedNum,
  //     checkHidden: null,
  //   },
  //   {
  //     key: 'supportSpeed',
  //     text: '支持加速',
  //     icon: <SpeedIcon />,
  //     num: supportSpeedNum,
  //     checkHidden: null,
  //   },
  //   {
  //     key: 'supportModifier',
  //     text: '支持修改',
  //     icon: <ModifyIcon />,
  //     num: supportTrialNum,
  //     checkHidden: () => {
  //       return !appStore.isInWhiteList
  //     },
  //   },
  //   {
  //     key: 'supportTrial',
  //     text: '提供共享账号',
  //     icon: <ShareIcon />,
  //     num: supportTrialNum,
  //     checkHidden: null,
  //   },
  //   {
  //     key: 'supportCloudGame',
  //     text: '支持云游戏',
  //     icon: <CloudIcon />,
  //     num: supportCloudGameNum,
  //     checkHidden: null,
  //   },
  //   {
  //     key: 'supportResource',
  //     text: '提供资源',
  //     icon: <ResourceIcon />,
  //     num: supportResourceNum,
  //     checkHidden: null,
  //   },
  // ]

  const scrollToTop = () => {
    if (ListContentRef.current) {
      ListContentRef.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }
  }

  // const changeSortKey = (sort: TSortKey, rule: TSortRule) => {
  //   setState(value => ({ ...value, sortKey: sort, sortRule: rule }))
  //   scrollToTop()
  // }

  // const handleChangeIsInstalled = (key: TStateKey, name?: string) => {
  //   const isCheck = state[key] === '1'
  //   setState(value => ({ ...value, [key]: isCheck ? '0' : '1' }))
  //   if (isCheck) {
  //     setTags(tags.filter(f => f.key !== key))
  //   } else {
  //     setTags([
  //       ...tags,
  //       {
  //         key,
  //         name: name || '',
  //       },
  //     ])
  //   }
  //   scrollToTop()
  // }

  const changeKeyword = (keywords: string) => {
    setState(value => ({ ...value, keywords }))
    scrollToTop()
  }

  // console.log(virtualListObj, 'virtualListObj')
  useEffect(() => {
    if (!itemSizeObj || !ListContentRef.current) {
      return
    }
    const handleListContentScroll = throttle(
      () => {
        if (!ListContentRef.current) {
          return
        }
        let virtualListStartIndex = 0
        let virtualListEndIndex = total
        const content = ListContentRef.current
        const scrollTop = ListContentRef.current.scrollTop
        const trueBlockPlaceholderHeight = itemSizeObj.blockHeight + ITEM_MARGIN_BOTTOM
        /** 当前可视区域高度 */
        const viewContentHeight = ListContentRef.current.offsetHeight
        /**
         * 上面隐藏行数
         */
        const topHideRowNum = Math.max(Math.floor(scrollTop / trueBlockPlaceholderHeight) - 1, 0)
        setViewTopButton(topHideRowNum > 0)
        /**
         * 显示行数
         * 先计算可视区域高度可以显示多少行，然后再上下各多渲染1行
         */
        const viewRowNum = Math.ceil(viewContentHeight / trueBlockPlaceholderHeight) + (topHideRowNum === 0 ? 0 : 1) + 3
        /** 应该渲染元素的数量，假如total少于需要渲染的元素数量可以就不用进行下面额度计算了 */
        const renderElementNum = Math.floor(viewRowNum * itemSizeObj.blockNumByRow)
        if (total <= renderElementNum) {
          content.style.setProperty('--virtualList-box-height', `0px`)
          setVirtualListStartIndex(virtualListStartIndex)
          setVirtualListEndIndex(virtualListEndIndex)
          return
        }
        const sumScrollHeight = Math.ceil(total / itemSizeObj.blockNumByRow) * trueBlockPlaceholderHeight
        content.style.setProperty('--virtualList-box-height', `${sumScrollHeight}px`)
        virtualListStartIndex = topHideRowNum * itemSizeObj.blockNumByRow
        virtualListEndIndex = virtualListStartIndex + viewRowNum * itemSizeObj.blockNumByRow
        setVirtualListStartIndex(virtualListStartIndex)
        setVirtualListEndIndex(virtualListEndIndex)
        return
      },
      100,
      { trailing: true },
    )
    ListContentRef.current.addEventListener('scroll', handleListContentScroll)
    handleListContentScroll()
    return () => {
      ListContentRef.current?.removeEventListener('scroll', handleListContentScroll)
    }
  }, [itemSizeObj, total])

  useEffect(() => {
    const handleResize = throttle(
      () => {
        if (!ListContentRef.current) {
          return
        }
        const content = ListContentRef.current
        const contentTrueWidth = content.offsetWidth - CONTENT_PADDING_LEFT + ITEM_MARGIN_RIGHT
        const maxElementNum = Math.floor(contentTrueWidth / ITEM_MAX_WIDTH)
        const minElementNum = Math.floor(contentTrueWidth / ITEM_MIN_WIDTH)
        const trueElementNum = Math.max(maxElementNum, minElementNum, 1)
        const trueElementWidth = contentTrueWidth / trueElementNum - ITEM_MARGIN_RIGHT
        const trueElementImgHeight = trueElementWidth / ITEM_IMAGE_ASPECT_RATIO
        const trueElementItemHeight = trueElementImgHeight + 70
        const itemSizeObj = {
          blockWidth: trueElementWidth,
          blockHeight: trueElementItemHeight,
          blockImgHeight: trueElementImgHeight,
          blockNumByRow: trueElementNum,
        }
        content.style.setProperty('--block-width', `${itemSizeObj.blockWidth}px`)
        content.style.setProperty('--block-height', `${itemSizeObj.blockHeight}px`)
        content.style.setProperty('--block-img-height', `${itemSizeObj.blockImgHeight}px`)
        setItemSizeObj(itemSizeObj)
      },
      100,
      { trailing: true },
    )
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    GameStore.getAllData()
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [state.supportCloudGame, state.supportModifier, state.supportResource, state.supportTrial, state.isInstalled])

  return (
    <StyledGameLibraryView>
      <StyledAll>目前我们提供 {allGameList.length || '--'} 款游戏的服务&工具</StyledAll>
      <StyledGameLibraryHeaderRow>
        <SearchInput
          className='search-input'
          placeholder='搜索游戏'
          value={state.keywords}
          onChange={changeKeyword}
          addonBefore={
            <StyledSearchButton className='pointer'>
              <SearchIcon className='search-icon' />
            </StyledSearchButton>
          }
        />
      </StyledGameLibraryHeaderRow>
      {/* <StyledHeaderRow>
        {filterList.map((value, index) => {
          if (value.checkHidden?.()) {
            return null
          }
          const key = value.key
          return (
            <div
              className={classNames('tabs-item', {
                active: ['1'].includes(state[key]),
              })}
              key={value.key}
              onClick={() => handleChangeIsInstalled(key, value.text)}
            >
              {value.icon}
              {value.text}({value.num ?? '--'})
              <CheckedIcon className='checked-icon' />
            </div>
          )
        })}
      </StyledHeaderRow> */}
      <StyledGameListContent ref={ListContentRef} bodySizeHeight={appStore.bodySize?.height || 0}>
        <div
          className='box'
          style={{
            height: 'var(--virtualList-box-height)',
          }}
        >
          {finalTitleList.length === 0 && (
            <StyledEmptyContainer>
              <FadeinImage width={91} height={91.5} type='notDefault' src={emptyImg} />
              <div>MYBUFF 暂未提供针对此游戏的任何工具/服务</div>
              <div>
                如果针对此游戏您有第三方工具或服务诉求，欢迎前往
                <TextButton onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>MYBUFF论坛</TextButton>
                进行反馈
              </div>
            </StyledEmptyContainer>
          )}
          {finalTitleList.map((d, i) => {
            if (!itemSizeObj || virtualListStartIndex === null || virtualListEndIndex === null) {
              return null
            }
            if (i < virtualListStartIndex) {
              return null
            }
            if (i >= virtualListEndIndex) {
              return null
            }
            const rowNum = Math.floor(i / itemSizeObj.blockNumByRow)
            const colNum = i % itemSizeObj.blockNumByRow
            return (
              <GameItem
                isInstall={!!GameStore.installedGameList.find(f => f.gameLibraryMasterId === d.gameLibraryMasterId)}
                style={{ top: rowNum * (itemSizeObj.blockHeight + 20), left: colNum * (itemSizeObj.blockWidth + 26) }}
                key={`game-search-item-${d.gameLibraryMasterId}`}
                game={d}
                entry={tags?.[0] && entryMap[tags[0].key as keyof typeof entryMap]}
              />
            )
          })}
        </div>
      </StyledGameListContent>
      {viewTopButon && (
        <StyledViewTopButton className='pointer' onClick={scrollToTop}>
          <TopIcon />
        </StyledViewTopButton>
      )}
    </StyledGameLibraryView>
  )
}

export const StyledAll = styled.div`
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  line-height: 30px;
`

const StyledGameItemSkeletonContainer = styled.div`
  width: var(--block-width);
  height: var(--block-height);
  margin-right: 26px;
  margin-bottom: 20px;
  position: relative;
  .ant-skeleton-input {
    background: linear-gradient(
      90deg,
      rgba(190, 190, 190, 0.06) 25%,
      rgba(129, 129, 129, 0.1) 37%,
      rgba(190, 190, 190, 0.06) 63%
    );
    background-size: 400% 100%;
  }
  .box {
    height: var(--block-height);
    width: var(--block-width);
  }
  .top {
    width: var(--block-width);
    height: var(--block-img-height);
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.06);
  }
  .bottom-1 {
    position: absolute;
    left: 12px;
    bottom: 38px;
    height: 17px;
    width: 83px;
    background: rgba(255, 255, 255, 0.06);
  }
  .bottom-2 {
    position: absolute;
    left: 12px;
    bottom: 18px;
    height: 12px;
    width: 115px;
    background: rgba(255, 255, 255, 0.06);
  }

  /* .bottom-box {
    height: 70px;
    width: var(--block-width);
  } */
`

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$C6C6C6};
  div {
    margin-top: 20px;
  }
`

const StyledFreeTips = styled.div`
  ${props => props.theme.flexAlignCenter}
  min-width: 229px;
  height: 30px;
  border-radius: 30px;
  background: ${props => props.theme.color.bg.$FFFFFF12};
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
  padding: 0 10px 0 4px;
  font-size: 13px;
  color: ${props => props.theme.color.text.$FFFFFF86};
  .main {
    ${props => props.theme.flexCenter}
    width: 38px;
    height: 22px;
    background: linear-gradient(136deg, #3faeff 0%, #9b29ff 100%);
    border-radius: 24px;
    margin-right: 8px;
    font-size: 13px;
    color: var(--text-color-FFFFFF);
    font-weight: 700;
  }
  .primary {
    color: ${props => props.theme.color.mainColor};
  }
`

const StyledSearchButton = styled.div`
  ${props => props.theme.flexCenter}
  height: 100%;
  padding: 0 8px 0 10px;
`

export const StyledGameListContent = styled.div<{ bodySizeHeight: number }>`
  --block-width: 0px;
  --block-height: 0px;
  --block-img-height: 0px;
  display: flex;
  flex-wrap: wrap;
  /* height: 420px; */
  margin-top: 24px;
  //padding-left: 26px;
  //height: calc(${props => props.bodySizeHeight}px - 210px);
  flex: 1;
  overflow: auto;
  position: relative;
  .box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
  }
  .spin-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    color: #fff;
    font-size: 12px;
  }
  .empty-box {
    width: 100%;
    height: 476px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.color.text.$C6C6C6};
    div {
      margin-top: 20px;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledHeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  .tabs-item {
    cursor: pointer;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #232428;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    margin-right: 26px;
    color: rgba(255, 255, 255, 0.75);
    flex: 1;
    position: relative;
    transition: all 0.2s;
    > svg {
      margin-right: 8px;
    }
    .checked-icon {
      display: none;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &.active {
      color: #fff;
    }
    &.active {
      background: rgba(112, 68, 205, 0.2);
      .checked-icon {
        display: block;
      }
    }
  }
`

const StyledViewTopButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 0.5em;
  bottom: 0.5em;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  color: #fff;
  font-size: 28px;
  border-radius: 50%;
  svg {
    width: 15px;
    height: 18px;
  }
  &:hover {
    background: #2777ff;
  }
`

export const StyledGameLibraryHeaderRow = styled.div`
  ${props => props.theme.flexAlignCenter}
  position: relative;
  margin-top: 24px;
  .search-input {
    width: 560px;
    height: 42px;
    .ant-input-group-addon {
      border-radius: 3px 0 0 3px;
    }
    .ant-input-affix-wrapper {
      border-radius: 0 3px 3px 0;
    }
    .ant-input-affix-wrapper,
    .ant-input {
      height: 42px;
    }
    .ant-input {
      font-size: 14px;
      flex: 1;
      width: auto;
    }
    .ant-input-suffix {
      margin-right: 10px;
    }
  }
`

export const StyledGameLibraryView = styled.div`
  padding: 32px 32px 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export default observer(GameSearch)
