import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { setupSDK } from 'src/pages/game-detail/cloud-game/setupSDK'
import { CLOUD_GAME_EXIT_TYPE } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import { applyCloudGameConnect, applyCloudGameReport } from 'src/apis/cloud-game-api'
import { fetchUserGameCloudInfo } from 'src/apis/user-api'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { formatTime } from 'src/tools/helper'
import { TcgDataChannel } from 'src/pages/game-detail/cloud-game/tcg-data-channel'
import { Drawer, message, Radio, Switch } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { sleep } from 'src/tools/common'
import { useDebounceFn } from 'ahooks'
import { imperativeConfirmModal, ImperativeConfirmModalPoll } from 'src/components/base-modal'
import styled from 'styled-components'
import bg from 'src/assets/images/cloud-game/bg.png'
import HomeIcon from 'src/assets/icons/cloud-game/home.svg'
import LogoutIcon from 'src/assets/icons/cloud-game/logout.svg'
import ModifyIcon from 'src/assets/icons/cloud-game/modify.svg'
import SpeedIcon from 'src/assets/icons/cloud-game/speed.svg'
import ArrowIcon from 'src/assets/icons/cloud-game/arrow.svg'
import SignalIcon from 'src/assets/icons/cloud-game/signal.svg'
import RestartIcon from 'src/assets/icons/cloud-game/restart.svg'
import logo from 'src/assets/images/cloud-game/logo.png'
import CloseIcon from 'src/assets/icons/cloud-game/close.svg'
import HelpTips from 'src/pages/game-detail/cloud-game/help-tips'
import CloudGamePagePostMessage from 'src/pages/cloudgame-page/cloud-game-page-post-message'

interface PageProps {
  query: Record<string, string>
  env: string
}

type TBaseType = number | string

interface TScreenConfig {
  width: string
  height: string
}

export interface Config {
  showDelay: boolean
  autoSetScreen: boolean
  screenConfig: TScreenConfig
  fpsConfig: string
}

export const InitConfig: Config = {
  showDelay: true,
  autoSetScreen: true,
  screenConfig: { width: '1920', height: '1080' },
  fpsConfig: '60',
}

let timer: number | null = null
let animationId: number | null = null
let hangSuccess: Function | null = null
let fullFlag = false
let sdkInitTimer: number | null = null
let restartTimer: number | null = null

const PlayCloudGame: FC = () => {
  const [config, setConfig] = useState<Config>(InitConfig)
  const [controlCollapse, setControlCollapse] = useState<boolean>(true) // 游戏配置工具栏
  const [networkStatus, setNetWorkStatus] = useState<OnNetworkChangeStatus>() // 网络状态
  const [userCloudTime, setUserCloudTime] = useState<TCloudInfo>()// 用户剩余时长
  const [processInfo, setProcessInfo] = useState<{ step: number; stepText: string }>({
    step:1,
    stepText:'链接云服务器中',
  })
  const [processStep, setProcessStep] = useState<number>(0)
  const [startSuccess, setStartSuccess] = useState<boolean>(false)
  const [fullScreenStatus, setFullScreenState] = useState(false)
  const [timeBegin, setTimeBegin] = useState(false)
  const [restartLoading, setRestartLoading] = useState(false)
  const restartLoadingRef = useRef(restartLoading)
  restartLoadingRef.current = restartLoading
  const fullScreenStatusRef = useRef(fullScreenStatus)
  fullScreenStatusRef.current=fullScreenStatus
  const [sdkLoaded, setSdkLoaded]= useState(false)
  const escKeyDownRef=useRef(false)
  const escKeyDownTimerRef=useRef(0)
  const dataChannelRef=useRef<TcgDataChannel>()

  const query=useMemo(()=>{
    const q=new URLSearchParams(location.search)
    const temp:Record<string, string>={}
    for (let p of q.entries()){
      temp[p[0]]=p[1]
    }
    return temp
  },[])
  const isHost = query.userType === 'host'
  // const userId = query.userId
  const isHang = query.isHang === '1'
  const env=query.env
  //窗口通信socket端口
  const wsPort = query.wsport
  const isSupportTrainer = query.cloudModify === '1'
  const isSupportSpeed = query.cloudSpeed === '1'
  //是否自有账号登录
  const isOwnAccountLogin = query.accountLoginMode === 'own_account_login'

  const installCloudGameSDK = async () => {
    try {
      const result =await Promise.race([
        setupSDK(['/tencentCloudSdk.js']),
        sleep(60 * 1000)
      ])
      if (result === 'error'|| result === 'sleep') {
        handleExitGame(CLOUD_GAME_EXIT_TYPE.SDK_LOAD_FAIL,true)
        return
      }
      //sdk加载完毕
      setSdkLoaded(true)
    } catch (e) {
      handleExitGame(CLOUD_GAME_EXIT_TYPE.SDK_LOAD_FAIL,true)
    }
  }

  const startProcessAnimation = (start: number, end: number) => {
    if (animationId) {
      cancelAnimationFrame(animationId)
    }
    const animation = () => {
      start += 0.03
      const step = start >= end ? end : start
      setProcessStep(step)
      if (step < end) {
        animationId = requestAnimationFrame(animation)
      } else {
        animationId && cancelAnimationFrame(animationId)
      }
    }
    animationId = requestAnimationFrame(animation)
  }

  const getCloudInfo=()=>{
    fetchUserGameCloudInfo({realTime:true}).then(({result})=>{
      console.log('update-user-cloud-time', result)
      setUserCloudTime(result)
      CloudGamePagePostMessage.post({
        code: 'update-user-cloud-time',
        data: result
      })
    })
  }

  const startCloudInterval = () => {
    getCloudInfo()
    clearCloudInterval()
    timer = window.setInterval(() => {
      getCloudInfo()
    }, 1000 * 60)
  }

  const clearCloudInterval = () => {
    if (timer) {
      window.clearInterval(timer)
      timer = null
    }
  }

  const onMenuClick = (key: string, type: 'screen' | 'fps') => {
    if (type === 'screen') {
      const [width, height] = key.split('*')
      setConfig(c => ({ ...c, screenConfig: { width, height } }))
    } else if (type === 'fps') {
      setConfig(c => ({ ...c, fpsConfig: key }))
    }
  }

  const currentConfig = useMemo(() => {
    const { width, height } = config.screenConfig
    return {
      screenConfig: [width, height].join('*'),
      fpsConfig: config.fpsConfig,
    }
  }, [config])

  /** 保存配置 */
  const applyConfig = (config: Config) => {
    const {
      screenConfig: { width, height },
      fpsConfig,
    } = config
    try{
      TCGSDK.setStreamProfile({ fps: +fpsConfig, max_bitrate: 15, min_bitrate: 4 })
      TCGSDK.setRemoteDesktopResolution({ width: +width, height: +height }).then(({ code }) => {
        console.log('setRemoteDesktopResolution ==> ', code)
      })
    }catch (e){
    }
  }

  /** 初始化SDK */
  const init = async (params: Record<string, string>) => {
    console.log('init')
    TCGSDK.init({
      mount: 'mount-point',
      reconnect: true,
      fullVideoToScreen: true,
      showLoading: false,
      appid: +params.appId,
      showLogo: false,
      debugSetting: {
        showLog: true,
      },
      onConfigurationChange(change) {
        console.log(change)
      },
      onConnectSuccess,
      onConnectFail,
      onNetworkChange,
      onInitSuccess,
      // onMultiPlayerChange,
      onDisconnect,
      onCursorShowStatChange({ oldStatus, newStatus }) {
        console.log({ oldStatus, newStatus })
      },
    })
    //sdk初始化60s超时
    sdkInitTimer = window.setTimeout(() => {
      handleExitGame(CLOUD_GAME_EXIT_TYPE.SDK_INIT_TIMEOUT, true)
    }, 60 * 1000)
  }

  /** 断开/被踢触发此回调，调用 start 接口成功后才会触发 */
  const onDisconnect = (e: OnDisconnectResponse) => {
    console.log('onDisconnect', e)
    if (e.code === 0) {
      handleExitGame(CLOUD_GAME_EXIT_TYPE.SDK_DISCONNECT)
    }
  }

  /** 连接失败回调，调用 start 接口成功后才会触发 */
  const onConnectFail = () => {
    console.log('onConnectFail')
  }

  /** 连接成功回调，调用 start 接口成功后才会触发 */
  const onConnectSuccess = () => {
    applyCloudGameReport({
      gameCloudOrderNo: query?.gameCloudOrderNo || '',
      gameId: +(query?.platformGameId || 0),
      eventType: 'cloud_game_start_listener',
      msgContent: '',
    })
    applyConfig(config)
  }

  /** 网络状态变化 */
  const onNetworkChange = ({ status, stats }: OnNetworkChangeResponse) => {
    if (status === 'stats') {
      setNetWorkStatus(stats)
    }
  }

  /** 初始化完毕的回调，触发此回调之后才能调用后面的 API */
  const onInitSuccess = async () => {
    sdkInitTimer && clearTimeout(sdkInitTimer)
    applyConfig(config)
    if (!isHang) {
      creatSession()
    } else {
      new Promise(resolve => {
        hangSuccess = resolve
        setTimeout(() => {
          runHangSuccess()
        }, 20000)
      }).then(creatSession)
    }
    initDataChannel()
  }

  /** 挂盘完成 */
  const runHangSuccess = () => {
    hangSuccess?.()
    hangSuccess = null
  }

  /** 初始化数据通道  */
  const initDataChannel = () => {
    dataChannelRef.current = new TcgDataChannel()
    dataChannelRef.current?.subscribe('message', msg => {
      console.log('dataChannel onMessage', msg)
    })
  }

  const {run: startCloudPlugin} = useDebounceFn((type: 'modifier' | 'speed') => {
    const data = {
      env,
      path: `/mini/game-detail?disableWs=1&type=game&entry=${type}&branchId=${query.gameLibraryBranchId}&authorization=${query.Authorization}`,
    }
    const msg = btoa(JSON.stringify(data))
    dataChannelRef.current?.send('start_cloud_plugins=' + msg)
  }, {
    wait: 3000,
    leading: true,
    trailing: false
  })

  const getParams = () => {
    const {startGameNo, cloudArchiveOrder, cloudOssPath = ''} = query || {}
    return {
      startGameNo,
      gameCloudOrderNo: cloudArchiveOrder,
      cloudOssPath: unescape(encodeURIComponent(cloudOssPath)),
      env,
      ProdType: 'sgt',
      cloud_game: '1',
    }
  }

  /** mini端启动参数 */
  const getLaunchParams = () => {
    return btoa(JSON.stringify(getParams()))
  }

  /** 建立会话连接 */
  const creatSession = async () => {
    try {
      if (query) {
        const {result} = await applyCloudGameConnect({
          gameCloudOrderNo: query.gameCloudOrderNo,
          gameId: +query.platformGameId,
          clientSession: TCGSDK.getClientSession(),
          startGameNo: query.startGameNo,
          launchParam: getLaunchParams(),
          linkUid: query.linkUid,
        })
        result && TCGSDK.start(result.serverSession)
      }
    } catch (error) {
      handleExitGame(CLOUD_GAME_EXIT_TYPE.CONNECT_SESSION_CREATE_FAILED,true)
      console.error(error)
    }
  }

  /** 退出游戏 */
  const handleExitGame = (reason: { code: number; message: string; reason: string }, needRequest?:boolean) => {
    typeof TCGSDK!=='undefined' && TCGSDK.destroy({ code: reason.code, message: reason.reason })
    CloudGamePagePostMessage.post({ code: 'hide-cloud-game-view', data: {reason,needRequest} })
  }

  /** 点击退出云游戏按钮 */
  const onExitGameClick = () => {
    CloudGamePagePostMessage.post({ code: 'request-exit' })
  }

  /** 点击打开主界面 */
  const onShowMainWindow = () => {
    CloudGamePagePostMessage.post({ code: 'show-main-window' })
  }

  /** 重启 */
  const onRestart = (action: 'gameRestartAll'|'gameRestart' | 'switchGameArchiveRestart', params: any) => {
    console.log('onRestart', action, params)
    const msg = btoa(
      JSON.stringify({
        ...params,
        action, //默认正常启动start| 切换应用存档重启switchGameArchiveRestart | 游戏重启gameRestart | gameRestartAll 重新上号
      }),
    )
    dataChannelRef.current?.send('start_cloud_game=' + msg)
  }

  const doGameRestart = () => {
    setRestartLoading(true)
    const params = getParams()
    onRestart('gameRestartAll', params)
    //重启30s超时
    restartTimer && clearTimeout(restartTimer)
    restartTimer = window.setTimeout(() => {
      console.log('重启超时')
      setRestartLoading(false)
    }, 30 * 1000)
  }

  const onRestartGameSuccess = () => {
    if (!restartLoadingRef.current) {
      return
    }
    restartTimer && clearTimeout(restartTimer)
    setTimeout(() => {
      setRestartLoading(false)
      message.success('游戏重启成功' + (isOwnAccountLogin && '，请重新登录' || ''))
    }, 3000)
  }

  /** 重启游戏 */
  const onRestartGame = () => {
    if (restartLoading) {
      return
    }
    imperativeConfirmModal({
      title: '「重启」云游戏提示',
      content: isOwnAccountLogin ?
        '请再次确认您当前需要进行云游戏重启，确认后我们将重新拉起游戏登录&运行平台，您需要重新进行游戏平台的登录和游戏的启动' :
        '请再次确认您当前需要进行云游戏重启，确认后我们将结束掉正在运行的游戏&游戏平台，并重新帮您进行游戏启动，重启过程中请勿进行其他操作',
      okText: '确认重启',
      onOk: () => {
        doGameRestart()
      }
    })
  }

  /** 切换全屏 */
  const onChangeScreen = () => {
    CloudGamePagePostMessage.post({ code: 'set_full_screen' })
  }

  const onVideoBoxClick = () => {
    setControlCollapse(true)
  }

  const onFullscreenChange = (checked: boolean) => {
    if (checked) {
      document.documentElement.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  const showLoadingMsg = (type: string) => {
    message.warn(`${type}正在启动中，请等待启动完成后再进行使用`)
  }

  useEffect(()=>{
    if (query?.Authorization) {
      localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO, {
        token:query.Authorization
      })
    }
  },[query])

  useEffect(()=>{
    installCloudGameSDK()

    /** 长按esc */
    document.addEventListener('keydown',(e)=>{
      if(escKeyDownRef.current){
        return
      }
      if(e.key==='Escape'){
        escKeyDownRef.current = true
        escKeyDownTimerRef.current && clearTimeout(escKeyDownTimerRef.current)
        escKeyDownTimerRef.current = window.setTimeout(() => {
          console.log('长按esc')
          setTimeout(() => {
            setControlCollapse(false)
            TCGSDK?.setMouseCanLock(false)
            if (!TCGSDK?.getCursorShowStat()) {
              TCGSDK?.setCursorShowStat(true)
            }
          }, 300)
        }, 1000)
      }
    })
    document.addEventListener('keyup',(e)=>{
      if(e.key==='Escape'){
        escKeyDownTimerRef.current && clearTimeout(escKeyDownTimerRef.current)
        escKeyDownRef.current = false
      }
    })

    document.addEventListener('fullscreenchange',(e)=>{
      const isFull= !!document.fullscreenElement
      setFullScreenState(isFull)
      try{
        // @ts-ignore
        if(isFull && navigator.keyboard && navigator.keyboard.lock){
          // @ts-ignore
          navigator.keyboard.lock(['Escape'])
        }else{
          // @ts-ignore
          navigator.keyboard.unlock(['Escape'])
        }
      }catch (e){
        console.log('e',e)
      }
    })

    const reqFull=()=>{
      document.documentElement.requestFullscreen()
      document.removeEventListener('click',reqFull)
    }
    document.addEventListener('click',reqFull)

    //ctrl+alt+f切换全屏
    document.addEventListener('keydown',(ev)=>{
      if(ev.ctrlKey && ev.altKey && ev.key==='f'){
        console.log('切换全屏')
        onFullscreenChange(!fullScreenStatusRef.current)
      }
    })

  },[])

  useEffect(() => {
    if (wsPort) {
      CloudGamePagePostMessage.init(`ws://localhost:${wsPort}`).then(() => {
        //云游戏页面加载完成
        CloudGamePagePostMessage.post({ code: 'cloud_page_load_complete' })
      }).catch(()=>{
        handleExitGame(CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_PAGE_WS_ERROR, true)
      })
    } else {
      handleExitGame(CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_PAGE_WS_ERROR, true)
    }
  }, [wsPort])

  useEffect(() => {
    if (query?.Authorization) {
      localStorage.setItem('php-token', query.Authorization)
      startCloudInterval()
      return () => {
        clearCloudInterval()
      }
    }
  }, [query])

  useEffect(()=>{
    sdkLoaded && init(query)
  },[sdkLoaded])

  useEffect(() => {
    if (startSuccess) {
      startCloudInterval()
    }
  }, [startSuccess])

  useEffect(() => {
    const startFinishedOrder = localStorage.getItem('start-finished-orderNo')
    if (startFinishedOrder && startFinishedOrder === query?.gameCloudOrderNo) {
      setStartSuccess(true)
    }

    /** 启动进度 */
    CloudGamePagePostMessage.subscribe('start-progress', ({ process }) => {
      console.log('process',process)
      setProcessInfo(process)
    })

    /** 启动成功 */
    CloudGamePagePostMessage.subscribe('start-finished', () => {
      const cloudGameOrderNo = query?.gameCloudOrderNo
      cloudGameOrderNo && localStorage.setItem('start-finished-orderNo', cloudGameOrderNo)
      //模拟进度条跑满
      // setProcessInfo({
      //   step:3,
      //   stepText:'链接云电脑服务'
      // })
      setTimeout(()=>{
        setProcessInfo({
          step:4,
          stepText:'启动游戏客户端'
        })
      },300)
      setTimeout(()=>{
        setProcessInfo({
          step:5,
          stepText:'启动成功'
        })
      },800)
      setTimeout(()=>{
        setStartSuccess(true)
      },1000)
    })

    /** 开始计费 倒计时 */
    CloudGamePagePostMessage.subscribe('begin_cloud_time', () => {
      console.log('开始计费',Date.now())
      setTimeBegin(true)
    })

    /** 重启成功 */
    CloudGamePagePostMessage.subscribe('restart_success', () => {
      console.log('restart_success')
      onRestartGameSuccess()
    })

    /** 确认退出游戏弹窗显示 */
    CloudGamePagePostMessage.subscribe('exit-modal-status', ({ data }) => {
      TCGSDK?.setMouseCanLock(data.status !== 'show')
      TCGSDK?.setCursorShowStat(data.status === 'show')
    })

    /** ESC键盘按键事件 */
    CloudGamePagePostMessage.subscribe('request-unlock-pointer', () => {
      setTimeout(() => {
        TCGSDK?.setMouseCanLock(false)
        if (!TCGSDK?.getCursorShowStat()) {
          TCGSDK?.setCursorShowStat(true)
        }
        setControlCollapse(false)
      }, 300)
    })

    /** 切换全屏 */
    CloudGamePagePostMessage.subscribe('full-screen-change', ({ data }) => {
      setFullScreenState(data.status)
    })

    // /** 重新获取控制权 */
    // CloudGamePagePostMessage.subscribe('recover-control-res', ({ data }) => {
    //   forceControl().then(() => {
    //     CloudGamePagePostMessage.post({ code: 'force-recover' })
    //   })
    // })
    //
    // /** 设置控制权 */
    // CloudGamePagePostMessage.subscribe('set-control', ({ data: { control } }) => {
    //   TCGSDK.getSeats().then(response => {
    //     console.log(JSON.stringify(response))
    //   })
    //   console.log('set-control', control, control === 'player')
    //   if (control === 'player') {
    //     forceControl()
    //   } else {
    //     seatChange({ to_role: control, user_id: query.gameCloudOrderNo })
    //   }
    // })
  }, [])

  useEffect(() => {
    if (processInfo) {
      const { step } = processInfo
      if (step >= 3) {
        runHangSuccess()
      }
      const start = step * 20
      const end = step >= 5 ? 100 : Math.min((step + 1) * 20, 90)
      startProcessAnimation(start, end)
    }
  }, [processInfo])

  useEffect(()=>{
    applyConfig(config)
  },[config.fpsConfig,config.screenConfig])

  return (
    <>
      {/*<div>游戏窗口页面</div>*/}
      {!startSuccess && (
        <StyledGameStartMask>
          <HelpTips/>
          {processInfo && (
            <StyledProgressWrap>
              <div className="step">
                {`云游戏启动中(${processInfo?.stepText})...`}
              </div>
              <StyledProgress
                data-step-text={`云游戏启动中(${processInfo?.stepText})...`}
              >
                <span className="progress-track" style={{width:`${processStep}%`}}></span>
              </StyledProgress>
              <div className='processStep'>{(processStep | 0) + '%'}</div>
            </StyledProgressWrap>
          )}
        </StyledGameStartMask>
      )}
      <StyledPlayCloudGame id="mount-point" onClick={onVideoBoxClick}></StyledPlayCloudGame>

      {config.showDelay && <StyledRealTimeMessage>
        <div className="text"><SignalIcon/>延迟 : {networkStatus?.rtt || 0}</div>
        <div className="text"><SignalIcon/>帧率 : {networkStatus?.fps || 0}</div>
      </StyledRealTimeMessage>}

      {controlCollapse && <StyledConfigMini onClick={() => setControlCollapse(false)}>
        <ArrowIcon/>
        展<br/>开
      </StyledConfigMini>}

      <StyledSettingsDrawer
        title={null}
        placement="right"
        onClose={()=>setControlCollapse(true)}
        visible={!controlCollapse}
        closable={false}
        width={340}
        keyboard={false}
        forceRender={true}
      >
        <StyledSettings>
          <StyledLogo>
            <img src={logo} alt=""/>
          </StyledLogo>
          <StyledSettingsBox style={{marginTop:32}}>
            剩余云游戏时长：<TimeShow time={(userCloudTime?.overtime || 0) * 1000} timeBegin={timeBegin} />
          </StyledSettingsBox>
          <StyledTitle>设置</StyledTitle>
          <StyledSettingsBox>
            <StyledSettingsItem>
              <label>分辨率</label>
              <div className='content'>
                <StyledRadio
                  size="small"
                  onChange={(e)=> {
                    console.log('e',e)
                    onMenuClick(e.target.value,'screen')
                  }}
                  value={currentConfig.screenConfig}
                >
                  <Radio.Button value="1280*720">1280*720</Radio.Button>
                  <Radio.Button value="1920*1080">1920*1080</Radio.Button>
                </StyledRadio>
              </div>
            </StyledSettingsItem>

            <StyledSettingsItem>
              <label>帧率</label>
              <div className='content'>
                <StyledRadio
                  size="small"
                  onChange={(e)=>onMenuClick(e.target.value,'fps')}
                  value={config.fpsConfig}
                >
                  <Radio.Button value="30">30</Radio.Button>
                  <Radio.Button value="60">60</Radio.Button>
                </StyledRadio>
              </div>
            </StyledSettingsItem>

            <StyledSettingsItem>
              <label>全屏</label>
              <div className='content'>
                <StyledSwitch checked={fullScreenStatus} onChange={onFullscreenChange}/>
              </div>
            </StyledSettingsItem>

            <StyledSettingsItem>
              <label>呼出云游戏控制台</label>
              <div className='content'>
                <StyledKey>长按 ESC</StyledKey>
              </div>
            </StyledSettingsItem>

            <StyledSettingsItem>
              <label>显示延迟时间</label>
              <div className='content'>
                <StyledSwitch checked={config.showDelay} onChange={(v)=>setConfig(c=>({...c,showDelay:v}))}/>
              </div>
            </StyledSettingsItem>

          </StyledSettingsBox>
          {(isSupportTrainer || isSupportSpeed) && <>
            <StyledTitle>小工具</StyledTitle>
            <StyledSettingsBox>
              <StyledQuit>
                {isSupportTrainer && <div className="item" onClick={() => startSuccess ? startCloudPlugin('modifier'): showLoadingMsg('游戏修改器')}>
                  {startSuccess ? <ModifyIcon/> :  <Loading3QuartersOutlined  className='loading'/>}
                  修改器
                </div>}
                {isSupportSpeed && <div className="item" onClick={() => startSuccess ? startCloudPlugin('speed'):showLoadingMsg('加速器')}>
                  {startSuccess ? <SpeedIcon/> :  <Loading3QuartersOutlined className='loading' />}
                  加速器
                </div>}
              </StyledQuit>
            </StyledSettingsBox>
          </>}
          <StyledTitle>离开</StyledTitle>
          <StyledSettingsBox>
            <StyledQuit>
              <div className="item" onClick={onShowMainWindow}>
                <HomeIcon/>
                打开主界面
              </div>
              {startSuccess && <div className="item" onClick={onRestartGame}>
                {restartLoading ?
                  <>
                    <Loading3QuartersOutlined className="loading"/>
                    重启中
                  </> : <>
                    <RestartIcon/>
                    重启游戏
                  </>
                }
              </div>}
              <div className="item" onClick={onExitGameClick}>
                <LogoutIcon/>
                退出游戏
              </div>
            </StyledQuit>
          </StyledSettingsBox>
          <StyledBottom onClick={() => setControlCollapse(true)}><CloseIcon/></StyledBottom>
        </StyledSettings>
      </StyledSettingsDrawer>
      <ImperativeConfirmModalPoll />
    </>
  )
}

const TimeShow: FC<{ time: number; label?: string; timeBegin:boolean }> = ({ time, label, timeBegin }) => {
  const [lastTime, setLastTime] = useState<number>(0)
  const [timer, setTimer] = useState<number | null>(null)
  const lastRef = useRef(0)

  const formatTimes = useMemo(() => {
    return formatTime(lastTime)
  }, [lastTime])

  useEffect(() => {
    clearTimer()
    setLastTime(Math.max(time, 0))
    if(!timeBegin){
      return
    }
    let ti = time
    lastRef.current = Date.now()
    const t = window.setInterval(() => {
      const now = Date.now()
      ti -= (now - lastRef.current)
      lastRef.current = now
      if (ti < 0) {
        clearTimer()
      }
      setLastTime(Math.max(ti, 0))
    }, 1000)
    setTimer(t)
  }, [time, timeBegin])

  useEffect(() => {
    clearTimer()
    return () => {
      clearTimer()
    }
  }, [])

  const clearTimer = () => {
    if (timer) {
      lastRef.current=0
      clearInterval(timer)
      setTimer(null)
    }
  }

  return (
    <StyledTimeShowBox>
      <strong>{formatTimes[0]}</strong> 小时 <strong>{formatTimes[1]}</strong>分钟 <strong>{formatTimes[2]}</strong>秒
    </StyledTimeShowBox>
  )
}

const StyledBottom=styled.div`  
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid rgba(255,255,255,0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.12);
  cursor: pointer;
`

const StyledLogo=styled.div`
  padding-top: 32px;  
`

const StyledTimeShowBox=styled.span`
  >strong{
    font-size: 18px;
    color: #c19cfe;
    font-weight: bold;
  }
`

const StyledConfigMini=styled.div`
  width: 30px;
  height: 80px;
  position: fixed;
  right: 0;
  top: 100px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  padding-left: 4px;
  cursor: pointer;
  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.16);
    font-size: 14px;
    border: 1px solid rgba(255,255,255,0.3);
    //border-image: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;    
    border-radius: 10px 0 0 10px;
    left: 0;
    top: 0;
    color: #ffffff;
    transform: perspective(10px) rotateY(-5deg);
  }    
`

const StyledKey=styled.div`
  width: 119px;
  height: 24px;
  background: rgba(255,255,255,0.12);
  border-radius: 41px 41px 41px 41px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  line-height: 24px;
`

const StyledSwitch = styled(Switch)`
  background: #4a4a4a;
  &.ant-switch-checked{
    background: #c19cfe;    
    box-shadow: none;
  }  
`

const StyledRadio = styled(Radio.Group)`
  .ant-radio-button-wrapper{
    margin-left: 8px;
    background: rgba(255,255,255,0.12);
    height: 24px;
    border-radius: 24px;
    padding: 0 12px;
    color: #fff;
    font-size: 12px;
    border-color:rgba(255,255,255,0.12)!important;
    line-height: 24px;
    box-shadow: none !important;
    border-width: 1px;
    &:not(:first-child)::before{
      display: none;
    }
    &.ant-radio-button-wrapper-checked{
      border-color: #C19CFE !important;
      color: #C19CFE;
    }
  }
  
  
`

const StyledQuit=styled.div`
  display: flex;  
  justify-content: space-evenly;
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 20px;
    transition: color .1s;   
    .loading{
      animation: loading 1.5s linear infinite;
      font-size: 24px;
      margin-bottom: 4px;
      @keyframes loading {
        0%{
          transform: rotate(0deg);
        }
        100%{
          transform: rotate(360deg);
        }
      }
    }
    >svg{
      margin-bottom: 4px;
    }
    &:hover{
      color: #c19cfe;
    }
  }
`

const StyledSettingsItem= styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 24px;
  >label{
    
  }
  
  .content{
    display: flex;
    justify-content: flex-end;
  }
  
  &:last-child{
    margin-bottom: 0;
  }
  
`

const StyledTitle= styled.div`
  line-height: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  
`

const StyledSettingsBox= styled.div`
  padding: 24px 16px;
  background: rgba(255,255,255,0.06);
  border-radius: 8px;  
  margin-bottom: 24px;
`

const StyledSettings=styled.div`
  color: #fff;  
`

const StyledSettingsDrawer=styled(Drawer)`
  .ant-drawer-mask{
    background-color: rgba(0, 0, 0, 0.05);
  }
  .ant-drawer-content-wrapper{
    border-left: 1px solid rgba(117,117,117,0.3);
  }
  .ant-drawer-content{
    background: rgba(32,32,32,0.7);
  }
  .ant-drawer-body{
    padding: 0 16px;
  }
  
`

const StyledGameStartMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: #000 url("${bg}") no-repeat right center / cover;
`

const StyledProgressWrap = styled.div`
  position: absolute;
  left: 50%;
  bottom: 124px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .step{
    color: #96989d;
    position: absolute;
    left: 0;
    top: -22px;
    font-size: 14px;
  }
  .processStep{
    margin-left: 16px;
    color: #96989d;
    font-size: 14px;
  }
`

const StyledProgress = styled.div`
  width: 678px;
  height: 12px;
  border-radius: 6px;
  background: rgba(255,255,255,0.16);  
  .progress-track {
    display: block;
    height: 12px;
    border-radius: 5px;
    background: linear-gradient(92deg, #8A62FB 0%, #1AE6A3 52%, #E0CF53 100%);
  }
`

const StyledPlayCloudGame = styled.div`
  color: #fff;
`

const StyledRealTimeMessage = styled.div`
  position: fixed;
  right: 40px;
  top: 24px;
  text-align: right;
  z-index: 0;
  color: #96989D;
  font-size: 14px;
  display: flex;
  align-items: center;
  .text{
    margin-left: 16px;
    display: flex;
    align-items: center;
    >svg{
      margin-right: 4px;
    }
  }
`

const StyledConfigController = styled.div`
  font-family: Din;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: #fff;
    font-size: 0.12rem;
  }

  .clr-878787 {
    color: #878787;
  }
  .clr-fff {
    color: #fff;
  }

  .not-collapse {
    width: 0.9rem;
    opacity: 1;
    display: flex;
    overflow: hidden;
    align-items: center;
    transition: all 0.3s linear;
    .arrow-box {
      width: 0.24rem;
      height: 0.8rem;
      cursor: pointer;
      font-size: 0;
      position: relative;
      background: rgba(18, 18, 18, 0.7);
      clip-path: polygon(0.02rem 0.1rem, 100% 0, 100% 100%, 0.02rem 0.7rem);
      .arrow-icon {
        width: 0.08rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
    .config-box {
      flex: 1;
      min-width: 0.66rem;
      min-height: 1.8rem;
      border-radius: 0.04rem;
      background: rgba(18, 18, 18, 0.7);
      padding: 0 0.06rem;
      .config-item {
        text-align: center;
        line-height: 2em;
        cursor: pointer;
        padding-top: 0.1rem;
        &.disable {
          cursor: not-allowed;
          opacity: 0.5;
        }
        p {
          width: 0.74rem;
          margin-left: -0.1rem;
          text-align: center;
          height: 0.32rem;
          overflow: hidden;
        }
        .config-icon {
          max-height: 0.22rem;
        }
        p.hot-key:before {
          content: attr(data-text);
          display: block;
          font-size: 0.1rem;
        }
        &:hover {
          .config-icon {
            animation: jump 1s ease-in-out;
          }
          .hot-key {
            &:before {
              transform: scale(0.8);
              content: attr(data-key);
            }
          }
        }
      }
      .config-item:not(:last-child) {
        border-bottom: 0.02rem solid rgba(74, 241, 204, 0.2);
      }
      @keyframes jump {
        0% {
          transform: translate(0);
        }
        10% {
          transform: translateY(0.05rem) scaleX(1.2) scaleY(0.8);
        }
        30% {
          transform: translateY(-0.13rem) scaleX(1) scaleY(1) rotate(5deg);
        }
        50% {
          transform: translateY(0) scale(1) rotate(0);
        }
        55% {
          transform: translateY(0) scaleX(1.1) scaleY(0.9) rotate(0);
        }
        70% {
          transform: translateY(-0.04rem) scaleX(1) scaleY(1) rotate(-2deg);
        }
        80% {
          transform: translateY(0) scaleX(1) scaleY(1) rotate(0);
        }
        85% {
          transform: translateY(0) scaleX(1.05) scaleY(0.95) rotate(0);
        }
        100% {
          transform: translateY(0) scaleX(1) scaleY(1);
        }
      }
    }
  }
  .collapse-rect {
    width: 0;
    opacity: 0;
    height: 1.95rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: all 0.3s linear;
    .collapse-rect-bg {
      height: 1.95rem;
    }
  }
  &.collapse {
    .collapse-rect {
      opacity: 1;
      width: 0.39rem;
      .collapse-text {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 18px;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        line-height: 1.3em;
        padding: 0.1rem 0;
        span {
          display: block;
        }
        /* writing-mode: vertical-rl; */
      }
    }
    .not-collapse {
      width: 0;
      opacity: 0;
    }
  }
`

export default observer(PlayCloudGame)
