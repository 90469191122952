import useCloudGameStart, {
  clearTimeOutCheck,
  CloudGameStartProgress,
  gameIsRunningCheck,
  syncUserGameStatus
} from 'src/pages/game-detail/cloud-game/cloud-game-action'
import SocketStore, { CLOUD_EVENTS, CLOUD_MESSAGE, getConnectQuery } from 'src/pages/game-detail/cloud-game/socket'
import { CLOUD_GAME_EXIT_TYPE, CLOUD_GAME_PROGRESS_STATUS } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import { VipStore } from 'src/store'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import clientPostMessage from 'src/pages/game-detail/cloud-game/client-post-message'
import CEFTransfer from 'src/CEFTransfer'


const { stopCloudGame } = useCloudGameStart()

const initSocket = () => {

  /** linkUid消息 */
  SocketStore.subscribe(CLOUD_MESSAGE.NOTICE_LINK_UID, ({ code, prompt }) => {
    console.log('linkUid', prompt)
    CloudGamePlayStore.linkUid = prompt
  })

  /** 云游戏退出消息 */
  SocketStore.subscribe(CLOUD_MESSAGE.EXIT_CLOUD_GAME, ({ code, result }) => {
    const params: any = result
    console.log('cloud_game_desktop_quit', params, CloudGamePlayStore.gameCloudNo)
    if (params.gameCloudOrderNo !== CloudGamePlayStore.gameCloudNo) {
      return
    }
    stopCloudGame({
      needRequest: false,
      reason: `ws消息:${code}--${result.code}`,
      needArchive: false,
      // needFeedBack: true,
    })
    switch (result.code) {
      case 'cloud_game_wss_disconnect':
        CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_WSS_DISCONNECT
        break
      case 'cloud_game_quit_by_fwg':
        if (result.detailCode !== 'GAME_LOGOUT') {
          CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_QUIT_BY_FWG
        }
        break
      case 'cloud_game_start_time_out':
        CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.START_TIME_OUT
        break
      case 'cloud_game_stop_by_no_time':
        CloudGamePlayStore.cloudErrorModalType = 'force_quit'
        break
    }
    //拉到前端
    CEFTransfer.sendToClient('showWindowTop')
  })

  /** 云游戏保存存档隐藏窗口消息 */
  SocketStore.subscribe(CLOUD_MESSAGE.HIDE_CLOUD_GAME, ({ code, result }) => {
    const params: any = result
    if (params.gameCloudOrderNo !== CloudGamePlayStore.gameCloudNo) {
      return
    }
    stopCloudGame({
      needRequest: false,
      reason: `ws消息:${code}--${result.code}`,
      needArchive: false,
      // needFeedBack: false,
    })
    switch (result.code) {
      case 'cloud_game_wss_disconnect':
        CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_WSS_DISCONNECT
        break
      case 'cloud_game_quit_by_fwg':
        if (result.detailCode !== 'GAME_LOGOUT') {
          CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_QUIT_BY_FWG
        }
        break
      case 'cloud_game_start_time_out':
        CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.START_TIME_OUT
        break
      case 'cloud_game_stop_by_no_time':
        CloudGamePlayStore.cloudErrorModalType = 'force_quit'
        break
    }
    //拉到前端
    CEFTransfer.sendToClient('showWindowTop')
  })


  /** 开始计费 */
  SocketStore.subscribe(CLOUD_MESSAGE.BEGIN_CLOUD_TIME, () => {
    clientPostMessage.post({ code: 'begin_cloud_time' })
    CloudGamePlayStore.beginTime = Date.now()
    //开始云游戏时长倒计时
    VipStore.handleStartCloudGameCountdown()
  })

  /** 云游戏启动成功 */
  SocketStore.subscribe(CLOUD_MESSAGE.START_GAME_SUCCESS, () => {
    clearTimeOutCheck()
    CloudGamePlayStore.gameRunStatus = 'running'
    // /** 记录启动游戏时间 */
    // CloudGameClientStore.currentTheRunningGameInfo!.gameStartTime = Date.now()
    gameIsRunningCheck(CloudGamePlayStore.gameCloudNo)
    console.debug('云游戏启动进度-显示云游戏桌面', new Date().toLocaleString())
    // if (AppStore.windowStatus !== 2) {
    //   console.debug(
    //     `%c 云游戏启动成功, 当前客户端不是全屏状态， 切换到全屏`,
    //     'font-size:20px;font-weight:bold;color:#7fea2d;',
    //   )
    //   changeWindowStatus(2)
    // }
    // if (location.pathname !== '/my-game') {
    //   commonJump('/my-game')
    // }
    /** 切换到启动成功的游戏 */
    // if (CloudGameClientStore.currentTheStartingGameInfo) {
    //   const { gameLibraryId, gameLibraryImg, gameLibraryName, collection } = CloudGameClientStore.currentTheStartingGameInfo
    //   CloudGameClientStore.currentGameLibraryGame = { gameLibraryId, gameLibraryImg, gameLibraryName, collection }
    //   CloudGameClientStore.recommendPageFlag = false
    // }
    clientPostMessage.post({ code: 'start-finished' })
  })

  /** 云游戏启动超时 */
  SocketStore.subscribe(CLOUD_MESSAGE.START_TIME_OUT, ({ code, result }) => {
    stopCloudGame({ needRequest: false, reason: `ws消息:${code}`, needArchive: false })
    CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.START_TIME_OUT
    //拉到最前面
    CEFTransfer.sendToClient('showWindowTop')
  })

  /** 云游戏启动进度 */
  SocketStore.subscribe(CLOUD_MESSAGE.CLOUD_GAME_START_PROGRESS, ({ result }) => {
    let step = 0
    switch (result.code) {
      case 'PLATFORM_PULL':
        // 平台拉起成功
        console.debug(
          '云游戏启动进度-平台拉起成功',
          CLOUD_GAME_PROGRESS_STATUS['PLATFORM_PULL'],
          new Date().toLocaleString(),
        )
        step = CLOUD_GAME_PROGRESS_STATUS['PLATFORM_PULL']
        clientPostMessage.post({ code: 'restart_success' })
        break
      case 'PLATFORM_LOGIN':
        // 平台登录成功
        console.debug('云游戏启动进度-平台登录成功',new Date().toLocaleString())
        step = CLOUD_GAME_PROGRESS_STATUS['PLATFORM_LOGIN']
        CloudGamePlayStore.platformLogin = true
        break
      case 'GAME_PULL':
        console.debug(
          '云游戏启动进度-游戏拉起成功',
          CLOUD_GAME_PROGRESS_STATUS['GAME_PULL'],
          new Date().toLocaleString(),
        )
        step = CLOUD_GAME_PROGRESS_STATUS['GAME_PULL']
        CloudGamePlayStore.gamePull = true
        break
    }
    CloudGameStartProgress.setStep(step)
    if (step === CLOUD_GAME_PROGRESS_STATUS['GAME_PULL']) {
      CloudGameStartProgress.finish()
    }
  })

  /** 云游戏挂盘完成 */
  SocketStore.subscribe(CLOUD_MESSAGE.GAME_CLOUD_HANG_FINISHED_NOTICES, () => {
    console.debug('云游戏启动进度-挂盘完成', CLOUD_GAME_PROGRESS_STATUS.PLATFORM_PULL, new Date().toLocaleString())
    CloudGameStartProgress.setStep(CLOUD_GAME_PROGRESS_STATUS.PLATFORM_PULL)
  })

  /** 保存存档 */
  // SocketStore.subscribe(CLOUD_MESSAGE.CLOUD_GAME_CLOUD_HISTORY_SAVE, ({ result }) => {
  //   awaitSoure.done?.()
  //   window.FuluApp?.JSNotifyCppFunc('set_window_foreground')
  //   if (result.noChange) {
  //     return
  //   }
  //   AppStore.newSaveArchive.newSaveArchiveVisible = true
  //   AppStore.newSaveArchive.newGameArchiveSaveMode = 'cloud'
  //   AppStore.newSaveArchive.newSaveArchiveSaveSize = result.fileSize
  //   AppStore.newSaveArchive.newSaveArchiveSaveTime = result.updateSuccessTime
  //   AppStore.newSaveArchive.newSaveArchiveGameName = CloudGameClientStore.currentTheRunningGameInfo?.gameName || ''
  //   AppStore.newSaveArchive.newSaveArchiveGameOrderNo = result.orderNo
  //   AppStore.newSaveArchive.newSaveArchiveSaveId = result.cloudSaveId
  //   AppStore.newSaveArchive.newSaveArchiveSaveKey = result.key
  // })

  /** 用户登录 */
  SocketStore.subscribe(CLOUD_EVENTS.USER_UPDATE, ({ userId }) => {

    const WsOptions = SocketStore.option
    console.log('SocketStore  USER_UPDATE',userId,WsOptions.url)
    if (WsOptions.url) {
      const searchParams = new URL(WsOptions.url).searchParams
      const userType = searchParams.get('userType')
      const userGuid = searchParams.get('userGuid')
      if (userType === 'user_id' && userId + '' === userGuid) {
        return
      }
      SocketStore.connect(getConnectQuery('user_id', userId).url)
    }
  })

  /** 退出登录 */
  SocketStore.subscribe(CLOUD_EVENTS.USER_LOG_OUT, () => {
    const WsOptions = SocketStore.option
    console.log('SocketStore  USER_LOG_OUT',WsOptions.url)
    if (WsOptions.url) {
      const searchParams = new URL(WsOptions.url).searchParams
      const userType = searchParams.get('userType')
      if (userType === 'user_id') {
        SocketStore.connect(getConnectQuery().url)
      }
    }
  })

  /** 云游戏时长不足5分钟 */
  SocketStore.subscribe(CLOUD_MESSAGE.CLOUD_GAME_ADVICE_CHARGE, () => {
    console.log('不足5分钟')
    CloudGamePlayStore.cloudErrorModalType = 'balance'
    //拉到前端
    CEFTransfer.sendToClient('showWindowTop')
  })

  /** WS断开连接,并且重试失败 */
  SocketStore.subscribe(CLOUD_EVENTS.WS_DESTROYED, () => {
    const {
      gameRunStatus: status,
      currentTheStartingGameInfo: startInfo,
      currentTheRunningGameInfo: runningInfo,
    } = CloudGamePlayStore
    if (['starting', 'running'].includes(status)) {
      // const gameInfo = status === 'starting' ? startInfo : runningInfo
      // if (gameInfo?.gameMode === 'cloud' && gameInfo?.terminal === 'pc') {
      stopCloudGame({ needRequest: true, reason: `ws断开链接并且重试失败` })
      // }
      //拉到前端
      CEFTransfer.sendToClient('showWindowTop')
    }
  })

  /** 其它端状态同步 */
  // SocketStore.subscribe(CLOUD_MESSAGE.GAME_STATUS_CHANGE_NOTICES, ({ result }) => {
  //   const { currentTheRunningGameInfo: runningInfo } = CloudGameClientStore
  //   /** APP端结束 本地模式启动的游戏, 反外挂连接未建立的情况下需要退出游戏需要提示该信息 */
  //   if (
  //     runningInfo?.gameMode === 'local' &&
  //     runningInfo?.terminal === 'pc' &&
  //     +result.gameId === runningInfo?.gameId &&
  //     result.code === 'notice_status_free' &&
  //     result.event === 'END_GAME'
  //   ) {
  //     CloudGameClientStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.KICK_OFF_THE_LINE
  //   }
  //   syncUserGameStatus()
  // })

  /** 其它端退出游戏 */
  SocketStore.subscribe(CLOUD_MESSAGE.CLOUD_GAME_QUIT_BY_HAND, ({ result }) => {
    if (result.code === 'handOutByOtherTerminal') {
      const { currentTheRunningGameInfo: runningInfo } = CloudGamePlayStore
      stopCloudGame({
        needRequest: false,
        reason: `其它端退出游戏`,
        needArchive: false,
        // needFeedBack: runningInfo?.terminal === 'pc',
      })
      CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.KICK_OFF_THE_LINE
    }
  })

}

export default initSocket
