import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, message, Tooltip } from 'antd'
import { TextButton } from 'src/components/base-button'
import { speedTimeGoodsListHttp } from 'src/apis/game-api'
import { appStore, VipStore } from 'src/store'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import { StyledBuy, StyledVipGoodsItem } from 'src/pages/vip-recharge'
import RemainingTime from 'src/components/remaining-time'
import styled from 'styled-components'
import classNames from 'classnames'
import TipsIcon from 'src/assets/icons/common/tips.svg'
import PayModal from 'src/components/pay-modal'

const COLOR_MAPPING = ['#C38CFF', '#FFCE76', '#FF5FAC', '#66ACFE']/** 根据商品时长(单位：h), 计算单价 */

const DurationRecharge: FC = () => {
  const [vipGoodsList, setVipGoodsList] = useState<Http.TVipGoods[]>([])
  const [currentActiveVipGoods, setCurrentActiveVipGoods] = useState<Http.TVipGoods>()
  const [payModalVisible, setPayModalVisible] = useState<boolean>(false)

  const handleVipGoodsList = async () => {
    const { result } = await speedTimeGoodsListHttp()
    const finalVipGoodsList = result.filter((f, i) => i < 4)
    setVipGoodsList(finalVipGoodsList)
    let defaultIndex = null
    if (appStore.vipgoodsRecommendConfig) {
      const findIndex = finalVipGoodsList.findIndex(f => f.id === appStore.vipgoodsRecommendConfig)
      defaultIndex = findIndex > -1 ? findIndex : null
    }
    if (!defaultIndex) {
      let min = finalVipGoodsList[0]?.salePrice || 0
      defaultIndex = 0
      finalVipGoodsList.forEach((f, i) => {
        if (f.salePrice < min) {
          defaultIndex = i
          min = f.salePrice
        }
      })
    }
    finalVipGoodsList?.[defaultIndex!] && setCurrentActiveVipGoods(finalVipGoodsList[defaultIndex!])
  }

  const onSuccess = async () => {
    // message.success('购买成功')
    await VipStore.handleLoadUserRemainingSpeedBalance()
  }

  useEffect(() => {
    handleVipGoodsList()
  }, [])

  return (
    <StyledDurationRechargeContainer>
      <div className='vip-center-title' key='vip-center-1'>
        规格选择
      </div>
      <div className='vip-center-desc' key='vip-center-2'>
        若您从其他平台已获得了MyBuff的加速时长CDK您可以
        <TextButton onClick={() => showUserCenter(NAV_KEY_MAPPING.CDK_EXCHANGE)}>点击前往激活</TextButton>
      </div>
      <StyledBalanceRow key='vip-center-3'>
        {/*<FadeinImage type='notDefault' src={countDouwnImg} />*/}
        <div className='balance-title-text'>
          可用加速时长
          <Tooltip
            color={'rgb(45, 45, 45)'}
            overlayInnerStyle={{
              borderRadius: '4px',
              width: 300,
            }}
            overlay={
              <StyledVipFreeText>
                {/*加速器免费使用时间段: 每天*/}
                {/*<span className='main'>{VipStore.speedFreeConfig.freeSpeedTimeDesc}</span>; 免费加速时段不消耗可用时长*/}
                <div className='vip-free-text'>
                  <TextButton onClick={() => showUserCenter(NAV_KEY_MAPPING.VIP)}>MyBuff会员</TextButton>
                  可享受全天全时段免费加速服务
                </div>
              </StyledVipFreeText>
            }
            placement='bottom'
          >
            <TipsIcon />
          </Tooltip>
        </div>
        <div className='balance-time'>
          <RemainingTime timeType='speed' />
        </div>
      </StyledBalanceRow>
      <StyledVipCenterContent key='vip-center-4'>
        {vipGoodsList.map((d, i) => {
          // const { price, unit, number } = getUnitPrice(d.salePrice, d.vipDuration)
          return (
            <StyledVipGoodsItem
              className={classNames({ active: currentActiveVipGoods?.id === d.id })}
              onClick={() => setCurrentActiveVipGoods(d)}
              key={`vip-goods-item-${d.id}`}
            >
              <div className='animate-bor' />
              <div className='vip-goods-top'>
                <div className='vip-goods-name'>{d.name}</div>
                <div className='vip-goods-size' style={{ color: COLOR_MAPPING[i] }}>
                  <span className='main'>{d.vipDuration}</span>
                  小时
                </div>
              </div>
              <div className='vip-goods-bottom'>
                <div className='sale-price'>¥{d.salePrice}</div>
                <div className='source-price'>
                  原价：<span className='main'>¥{d.originalPrice}</span>
                </div>
              </div>
              {/*<FadeinImage*/}
              {/*  width={31}*/}
              {/*  height={28}*/}
              {/*  className='active-tag'*/}
              {/*  type='notDefault'*/}
              {/*  src={vipGoodsIconArrowsImg}*/}
              {/*/>*/}
              <div className='normal-tag' />
            </StyledVipGoodsItem>
          )
        })}
      </StyledVipCenterContent>
      <StyledBuy>
        <Button type='primary' className='btn' onClick={()=>setPayModalVisible(true)}>充值时长</Button>
      </StyledBuy>

      <PayModal
        visible={payModalVisible}
        goodsInfo={{
          goodsName: currentActiveVipGoods?.name,
          desc: currentActiveVipGoods?.vipDuration + '小时',
          goodsId: currentActiveVipGoods?.id,
          price: currentActiveVipGoods?.salePrice
        }}
        onSuccess={onSuccess}
        onClose={()=>setPayModalVisible(false)}
        autoSuccessModal={true}
      />

    </StyledDurationRechargeContainer>
  )
}

export const StyledVipFreeText = styled.div`
  .main {
    color: ${props => props.theme.color.mainColor};
  }
`

export const StyledVipCenterContent = styled.div`
  display: flex;
  margin-top: 24px;
  min-height: 168px;
  margin-bottom: 22px;
`

export const StyledBalanceRow = styled.div`
  margin-top: 24px;
  background: #26282B;
  padding: 16px 24px;
  border-radius: 4px;
  font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
  font-weight: bold;
  color: var(--text-color-FFFFFF);
  .balance-title-text{
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    > svg {
      margin-left: 8px;
    }
  }
 
  .balance-time {
    margin-top: 4px;
  }
`

export const StyledDurationRechargeContainer = styled.div`
  .vip-center-title {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
  }
  .vip-center-desc {
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.color.text.$BBBBBB};
    margin-top: 8px;
  }
`

export default observer(DurationRecharge)
