export async function setupSDK(
  scripts: string[],
): Promise<{ event?: Event; remove: () => void } | undefined | 'error'> {
  if (!scripts.length) {
    return
  }
  const scriptSrc = scripts.shift()
  return loaderScript(scriptSrc!).catch(({ remove }) => {
    remove()
    if (!scripts.length) {
      return 'error'
    } else {
      return setupSDK(scripts)
    }
  })
}

export function loaderScript(src: string): Promise<{ event?: Event; remove: () => void }> {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script')
    const remove = removeNode(script)
    const id = 'x' + btoa(src).padEnd(20, 'x').substr(10, 10)
    script.id = id
    script.src = src
    script.type = 'text/javascript'
    script.async = true
    script.onerror = event => reject({ event, remove })
    script.onload = event => resolve({ event, remove })
    try {
      const s = document.querySelector<HTMLScriptElement>(`script#${id}`)
      if (s) {
        removeNode(s)()
      }
      document.head.appendChild(script)
    } catch (error) {
      console.log(error)
      reject({ remove })
    }
  })
}

export function removeNode(Node: HTMLScriptElement) {
  return function () {
    const p = Node.parentNode
    p && p.removeChild(Node)
  }
}
