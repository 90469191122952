import React, { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { Form, message } from 'antd'
import { BaseInput } from 'src/components/base-input'
import { ConfirmButton, TextButton } from 'src/components/base-button'
import { PHONE_REG } from 'src/constants/reg'
import { signatureEncode, formatHideMobile } from 'src/tools/common'
import { verificationPhoneCodeHttp, accountIsRegistryHttp } from 'src/apis/user-api'
import type { FormInstance, Rule } from 'antd/es/form'
import useSendCode from 'src/hooks/use-send-code-modifier'
import styled from 'styled-components'
import classNames from 'classnames'
import ClearIcon from 'src/assets/icon/layout-header/clearIcon.svg'

type TPhoneVerification = {
  form: FormInstance<any>
  codeType?: 'BIND' | 'REG' | 'CHECK_IDENTITY' | 'UP_PASS'
  defaultPhone?: string | null
  extraRules?: Rule[]

  verificationExistOptions?: {
    hasExist: boolean
    field: string
    msg?: string
    callback?: Function
  }

  phonePlaceholder?: string
  phoneEmptyMsg?: string
  phoneErrorMsg?: string

  verificationCodePlaceholder?: string
  verificationCodeEmptyMsg?: string
  verificationCodeErrorMsg?: string
}

const PhoneVerification: FC<TPhoneVerification> = ({
  // phone input 相关配置
  phonePlaceholder = '请填写手机号',
  phoneEmptyMsg = '请填写手机号',
  phoneErrorMsg = '请输入正确的手机号',

  // verificationCode input 相关配置
  verificationCodePlaceholder = '请输入获取到的手机验证码',
  verificationCodeEmptyMsg = '请输入验证码',
  verificationCodeErrorMsg = '请输入正确的验证码',

  verificationExistOptions,
  defaultPhone,
  codeType = 'REG',
  extraRules,
  form,
}) => {
  const {
    text: verificationCodeText,
    isLoading: verificationCodeLoading,
    startCountdown,
  } = useSendCode('获取手机验证码', 60, '重新获取验证码')

  const { validateFields, setFields } = form

  const sendVerificationCode = async () => {
    if (verificationCodeLoading) {
      return
    }
    const phone = defaultPhone ? defaultPhone : (await validateFields(['phone'])).phone
    try {
      if (verificationExistOptions) {
        const { result: isRegistry } = await accountIsRegistryHttp({
          account: phone,
          accountType: 'mobile',
        })
        if (isRegistry === verificationExistOptions.hasExist) {
          // msg存在的话 直接设置校验不通过 并且显示msg
          if (verificationExistOptions.msg) {
            setFields([{ name: verificationExistOptions.field, errors: [verificationExistOptions.msg] }])
            return
          }
          verificationExistOptions.callback?.()
        }
      }
      const time = new Date().getTime()
      await verificationPhoneCodeHttp(
        { mobile: phone, action: codeType },
        {
          headers: {
            timestamp: String(time),
            signature: signatureEncode(phone + '.' + time),
          },
        },
      )
      startCountdown()
      message.success('发送成功')
    } catch (error) {}
  }

  return (
    <StyledPhoneVerificationContainer>
      {defaultPhone ? (
        <StyledReadOnlyRow>
          <div className='readonly-value'>{formatHideMobile(defaultPhone)}</div>
        </StyledReadOnlyRow>
      ) : (
        <Form.Item
          name='phone'
          validateFirst={true}
          rules={[
            { required: true, message: phoneEmptyMsg },
            {
              pattern: PHONE_REG,
              message: phoneErrorMsg,
            },
            ...(extraRules ? extraRules : []),
          ]}
        >
          <BaseInput maxLen={11} placeholder={phonePlaceholder} />
        </Form.Item>
      )}

      <Form.Item
        name='phoneVerificationCode'
        validateFirst={true}
        rules={[
          { required: true, message: verificationCodeEmptyMsg },
          { required: true, type: 'string', min: 4, message: verificationCodeErrorMsg },
        ]}
      >
        <BaseInput maxLen={4} className='verification-code-input' placeholder={verificationCodePlaceholder}>
          <TextButton className='send-code-text' onClick={() => sendVerificationCode()}>{verificationCodeText}</TextButton>
        </BaseInput>
      </Form.Item>
    </StyledPhoneVerificationContainer>
  )
}

const StyledReadOnlyRow = styled.div`
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  background-color: ${props => props.theme.color.bg.$FFFFFF12};
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 30px;
  .readonly-label {
    color: ${props => props.theme.color.text.$FFFFFF86};
  }
  .readonly-value {
    color: ${props => props.theme.color.text.$FFFFFF86};
  }
`

const StyledPhoneVerificationContainer = styled.div`
  .sg-base-input-warpper {
    width: 100%;
    height: 44px;
    border-radius: 8px;
  }
  .verification-code-input {
    .sg-base-button-dark {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 118px;
      height: 36px;
    }
    .send-code-text {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
`

export default observer(PhoneVerification)
