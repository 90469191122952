import React from 'react'
import { historyStack } from 'src/components/custom-router'
import { baseNotification } from 'src/components/base-notification'
import { GameStore } from 'src/store/modifier'
import { PLATFORM_MAPPING } from 'src/constants/common'
import { FadeinImage } from 'src/components/base-image'
import MessageSuccessIcon from 'src/assets/icons/notification/success.svg'
import styled from 'styled-components'

export const notifyNewlyAddInstallGamesNotify = async (games: Modifier.TInstalledGame[]) => {
  const handleJumpToDetail = (titleId: number, gameId: number | null) => {
    /** 暂时不需要这个跳转功能， 并且跳转路径也已经修改， 先注释 */
    // gameId && GameStore.changeActiveTitle(titleId, gameId)
    // historyStack.push(`/modifier/detail-${titleId}`)
    cancelInstance()
  }
  const { key, cancelInstance } = await baseNotification(
    '检测到新的本地安装游戏',
    <StyledNewlyAddInstallGamesContainer>
      {games.map(d => {
        const currentPlatform = PLATFORM_MAPPING[d.platformId]
        return (
          <div
            className='newly-add-install-game-item'
            key={`newly-add-install-game-${d.id}`}
            onClick={() => handleJumpToDetail(d.titleId, d.id)}
          >
            <div className='img-box'>
              <FadeinImage
                width={105}
                height={50}
                type='notDefault'
                src={`${d.thumbnail}?x-oss-process=image/resize,m_fill,h_50,w_105/format,jpg`}
              />
            </div>
            <div className='newly-add-install-game-info'>
              <div className='newly-add-install-game-title-box'>
                <span className='newly-add-install-game-text ellipsis'>{d.zhCnName || d.name} </span>
                <span className='newly-add-install-game-icon'>
                  <currentPlatform.Icon />
                </span>
              </div>
              <div className='newly-add-install-game-description ellipsis'>{d.name}</div>
            </div>
          </div>
        )
      })}
    </StyledNewlyAddInstallGamesContainer>,
    <MessageSuccessIcon />,
  )
  return
}

const StyledNewlyAddInstallGamesContainer = styled.div`
  margin-top: 20px;
  max-height: 207px;
  overflow: overlay;
  .newly-add-install-game-item {
    width: 100%;
    height: 69px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    .img-box {
      width: 105px;
      height: 50px;
      overflow: hidden;
      border-radius: 2px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .newly-add-install-game-info {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .newly-add-install-game-title-box {
        display: flex;
        align-items: center;
        .newly-add-install-game-text {
          font-size: 14px;
          line-height: 14px;
          font-weight: bold;
          color: #ffffff;
          max-width: 150px;
        }
        .newly-add-install-game-icon {
          margin-left: 10px;
          line-height: 0px;
          color: #999999;
        }
      }
      .newly-add-install-game-description {
        font-size: 12px;
        line-height: 12px;
        color: #999999;
        width: 180px;
        margin-top: 10px;
      }
    }
    &:hover {
      background: #4c4c50;
    }
  }
`
