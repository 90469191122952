import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { EDIT_ITEM_CLASS_MAPPING } from 'src/constants/common'
import { RunningStore } from 'src/store/modifier'
import { emitter } from 'src/tools/emitter'
import WindowController from 'src/CEFTransfer/window-controller'
import EditCheatItem from 'src/pages/game-detail/modifier/edit-cheat-item'
import styled from 'styled-components'
import classNames from 'classnames'

const TITLE_LETTER_SPACING_MAPPING: Record<number, string> = {
  '2': '0.5em',
  '3': '0.3em',
  '4': 'normal',
}



const EditBox: FC<{
  // 每个修改项的热键以及值
  cheatsValues: Record<string, Modifier.TCheatsValue> | null
  // 修改项列表
  cheatList: Modifier.TCheats[][]
  // 当前修改器id
  trainerId: number | null
  // 当前修改器游戏是否运行中
  currentIsRunning: boolean
}> = ({ cheatsValues, cheatList, trainerId, currentIsRunning }) => {
  const [currentEditHotkeys, setCurrentEditHotkeys] = useState<{ uuid: string; index: number }>()
  const handleSetCurrentEditHotkeys = async ({ uuid, index }: { uuid: string; index: number }) => {
    // 当前修改器游戏没有运行中就直接return
    if (!currentIsRunning) {
      return
    }
    // 先把之前的监听停止
    WindowController.handleStopEditHotkeys()
    // await window.wbAPI.js2client('stopEditHotkeys', null)
    setCurrentEditHotkeys({ uuid, index })
    try {
      // 监听按键触发
      // const result = await window.wbAPI.js2client('editHotkeys', null)
      const result = await WindowController.handleEditHotkeys()
      RunningStore.handleHotkeysChange(uuid, index, result)
      setCurrentEditHotkeys(undefined)
    } catch (error) {
      // 被其他事件触发停止监听时会走到这里来
      // console.log(error, 'bdfgdfg')
    }
  }
  const handleCheatsValueChange = (uuid: string, value: number) => {
    if (!RunningStore.hasRunning) {
      return
    }
    RunningStore.handleCheatsValuesChange('mouse', uuid, value)
  }

  useEffect(() => {
    return () => {
      WindowController.handleStopEditHotkeys()
      // window.wbAPI?.js2client('stopEditHotkeys', null)
    }
  }, [trainerId])

  return (
    <>
      {cheatList.map(d => {
        const category = d[0].category
        const { icon: Icon, title, key } = EDIT_ITEM_CLASS_MAPPING[category]
        return (
          <StyledEditBoxContainer key={category}>
            <div className='edit-box-title'>
              <div className='icon-box'>
                <Icon />
              </div>
              <span className='text' style={{ letterSpacing: TITLE_LETTER_SPACING_MAPPING[title.length] }}>
                {title}
              </span>
            </div>
            <div className='edit-cheat-content'>
              {d.map(d => {
                return (
                  <EditCheatItem
                    disabled={!currentIsRunning}
                    key={`cheat-${d.uuid}`}
                    cheat={d}
                    cheatsValue={cheatsValues![d.uuid]}
                    onChange={handleCheatsValueChange}
                    currentEditHotkeys={currentEditHotkeys}
                    startEditHotkeys={(uuid, index) => handleSetCurrentEditHotkeys({ uuid, index })}
                  />
                )
              })}
            </div>
          </StyledEditBoxContainer>
        )
      })}
    </>
  )
}

const StyledEditBoxContainer = styled.div`
  position: relative;
  margin-top: 12px;
  &::before {
    content: ' ';
    position: absolute;
    top: 9px;
    right: 0;
    width: calc(100% - 116px);
    height: 0px;
    border-top: 1px dashed ${props => props.theme.color.bor.$593D78};
  }
  .edit-cheat-content {
    padding-left: 23px;
  }
  .edit-box-title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .icon-box {
      width: 23px;
      margin-right: 9px;
      font-size: 0;
    }
    .text {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      color: var(--text-color-FFFFFF);
    }
  }
`

export default observer(EditBox)
