import { useEffect } from 'react'
import { IReactionDisposer, reaction } from 'mobx'
import { DownloadStore } from 'src/store/resource'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'

type TCallback = (data: Http.IFileDownloadInfo) => void

interface IFileDownloadManagerOption {
  link?: string
  onComplete?: TCallback
  onCancel?: TCallback
  onFail?: TCallback
}

interface UseFileDownloadOption extends IFileDownloadManagerOption {
  autoClear?: boolean //组件卸载，是否清理reaction，默认会清理
}

export const useFileDownload = (option: UseFileDownloadOption) => {
  const { link, onCancel, onFail, onComplete, autoClear = true } = option
  const fm = new FileDownloadManager({
    link,
    onCancel,
    onFail,
    onComplete,
  })

  const download = (resourceName: string, resourceTypeName: string, link?: string) => {
    fm.download(resourceName, resourceTypeName, link)
  }

  useEffect(() => {
    return () => {
      autoClear && fm.destroy()
    }
  }, [])

  return {
    download,
  }
}

class FileDownloadManager {
  static disposers: { [p: string]: IReactionDisposer } = {}
  private link: string
  private readonly onCancel: TCallback
  private readonly onComplete: TCallback
  private readonly onFail: TCallback

  constructor({
    link = '',
    onComplete = () => {},
    onCancel = () => {},
    onFail = () => {},
  }: IFileDownloadManagerOption) {
    this.link = link
    this.onComplete = onComplete
    this.onCancel = onCancel
    this.onFail = onFail
    this.listenStatus()
  }

  private clearNotValidStatus() {
    delete DownloadStore.fileDownloadProgress[this.link]
  }

  private listenStatus() {
    if (!this.link) {
      return
    }
    FileDownloadManager.dispose(this.link)
    FileDownloadManager.disposers[this.link] = reaction(
      () => DownloadStore.fileDownloadProgress[this.link]?.status,
      status => {
        if (status && status !== 2) {
          FileDownloadManager.dispose(this.link)
        }
        const data = DownloadStore.fileDownloadProgress[this.link]
        switch (status) {
          case -2:
            console.log('下载不可用')
            this.clearNotValidStatus()
            this.onFail(data)
            break
          case -1:
            console.log('取消下载')
            this.clearNotValidStatus()
            this.onCancel(data)
            break
          case 1:
            console.log('文件下载完成')
            this.onComplete(data)
            break
          default:
        }
      },
    )
  }

  download(resourceName: string, resourceTypeName: string, link?: string) {
    this.link = link || this.link
    cefOpenUrl(encodeURI(this.link) )
    //初始化下载状态
    // DownloadStore.fileDownloadProgress[this.link] = {
    //   downloadPath: '',
    //   filePath: '',
    //   progress: 0,
    //   status: 0,
    //   url: '',
    //   resourceName,
    //   resourceTypeName,
    // }
    // this.listenStatus()
    // const el = document.createElement('a')
    // el.download = ''
    // el.href = encodeURI(this.link) 
    // console.log(el.href, 'el.href')
    // el.click()
  }

  //注销下载进度、状态reaction
  static dispose(link?: string) {
    if (!link) {
      return
    }
    FileDownloadManager.disposers[link]?.()
    Reflect.deleteProperty(FileDownloadManager.disposers, link)
  }

  destroy() {
    FileDownloadManager.dispose(this.link)
  }
}
