import React, { FC, ReactNode, useState } from 'react'
import { BaseModal } from 'src/components/base-modal'
import { CancelButton, ConfirmButton } from 'src/components/base-button'
import { Button as BaseButton } from 'antd'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { generatorUUID } from 'src/tools/common'
import { useIntl } from 'react-intl'
import WarnIcon from 'src/assets/icons/common/warn.svg'
import styled from 'styled-components'
import classNames from 'classnames'

interface IConfirmModal {
  title: string | ReactNode
  content: string | ReactNode
  closable?: boolean
  width?: number
  zIndex?: number
  okText?: string | ReactNode
  cancelText?: string | ReactNode
  onOk?: () => void
  onCancel?: (type: 'btn' | 'close') => void
  btns?: Array<'ok' | 'cancel'>
  extra?: string | ReactNode
  showIcon?:boolean
  afterClose?:()=>void
  visible?: boolean
}

interface TConfirmModalPoll extends IConfirmModal {
  key: string
  _ok: () => void
  _cancel: (target: 'btn' | 'close') => void
}

const ImperativeConfirmModal: FC<{ options: TConfirmModalPoll | null }> = ({ options }) => {
  const { formatMessage } =useIntl()
  const visible = options?.visible===undefined ? !!options : options?.visible
  const okText = options?.okText || formatMessage({id:'common.confirm'})
  const cancelText = options?.cancelText || formatMessage({id:'common.cancel'})
  const btns = options?.btns || ['ok', 'cancel']
  const showIcon = options?.showIcon || options?.showIcon===undefined
  const [okLoading, setOkLoading] = useState(false)
  const onOk = async () => {
    setOkLoading(true)
    try{
      await options?.onOk?.()
      await options?._ok()
    }catch (e){
      throw e
    }finally {
      setOkLoading(false)
    }
  }
  const onCancel = async (type: 'btn' | 'close') => {
    await options?.onCancel?.(type)
    await options?._cancel(type)
  }
  return (
    <StyledConfirmModalContainer
      width={options?.width || 450}
      zIndex={options?.zIndex}
      visible={visible}
      onCancel={() => onCancel('close')}
      viewCloseIcon={options?.closable}
      showIcon={showIcon}
      afterClose={options?.afterClose}
    >
      <div className='sg-confirm-modal-title'>
        {showIcon && <div className="sg-confirm-icon-box">
          <WarnIcon className="sg-confirm-icon-item"/>
        </div>}
        <div>
          <span className='confirm-modal-title-text'>{options?.title}</span>
          <div className='sg-confirm-modal-content'>{options?.content}</div>
        </div>
      </div>
      {options?.extra && <div className='sg-confirm-modal-extra'>{options?.extra}</div>}
      <div className='sg-confirm-btns'>
        {btns.includes('cancel') && (
          <CancelButton shape='circle' onClick={() => onCancel('btn')}>
            {cancelText}
          </CancelButton>
        )}
        {btns.includes('ok') && (
          <ConfirmButton type='primary' shape='circle' onClick={onOk} loading={okLoading}>
            {okText}
          </ConfirmButton>
        )}
      </div>
    </StyledConfirmModalContainer>
  )
}

const StyledConfirmModalContainer = styled(BaseModal)<{viewCloseIcon?: boolean; showIcon:boolean}>`
  .ant-modal-content{
    backdrop-filter: blur(30px);
  }
  .ant-modal-body {
    padding: 32px 24px;
    background: rgba(32,32,32,0.9);
    //backdrop-filter: blur(60px);
    //border: 1px solid rgba(117,117,117,0.4);
  }
  .ant-modal-close-x {
    ${props => {
      if (props.viewCloseIcon) {
        return ''
      }
      return 'display: none;'
    }}
  }
  .sg-confirm-modal-title {
    display: flex;
    align-items: flex-start;
    .sg-confirm-icon-box {
      max-width: 50px;
    }
    .confirm-modal-title-text {
      ${props => props.showIcon ? 'margin-left: 8px;' :'' }
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      color: var(--text-color-FFFFFF);
    }
    .sg-confirm-modal-content {
      ${props => props.showIcon ? 'margin-left: 8px;' :'' }
      padding: 4px 0px 16px;
      font-size: 14px;
      color: ${props => props.theme.color.text.$BBBBBB};
      line-height: 24px;
    }
  }
  .sg-confirm-modal-extra {
    margin-bottom: 24px;
  }
  .sg-confirm-btns {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      width: 120px;
      height: 36px;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
`

// 初始化10个弹框池
const INIT_POLL_NUMBER = 10

type TConfirmModalStoreInstance = {
  confirmModals: Array<TConfirmModalPoll | null>
  idleNum: number
  useNum: number
}

const ConfirmModalStoreInstance = observable<TConfirmModalStoreInstance>({
  confirmModals: new Array(INIT_POLL_NUMBER).fill(null),

  // 当前空闲的弹框池数量
  get idleNum() {
    return this.confirmModals.filter((f: TConfirmModalPoll | null) => !f).length
  },

  // 当前已使用的弹框池数量
  get useNum() {
    return this.confirmModals.filter((f: TConfirmModalPoll | null) => !!f).length
  },
})

const ConfirmModalPoll: FC = observer(() => {
  return (
    <>
      {ConfirmModalStoreInstance.confirmModals.map((d, i) => {
        return <ImperativeConfirmModal options={d} key={`confirm-modal-${i}`} />
      })}
    </>
  )
})

/**
 * 调用一个确认弹框
 * @param IConfirmModal
 * @returns boolean
 * @example 
 * await confirmModal({
 *   title: '测试测试',
     content: '内容内容内容内容内容内容',
     onOk: () => {},
     onCancel: () => {},
     okText: '确定',
     cancelText: '取消',
     btns: ['ok', 'cancel']
 * })
 */
const confirmModal = (d: IConfirmModal, customKey?: string): Promise<{ value: boolean; target: 'btn' | 'close' }> => {
  // 生成当前弹框的唯一标识
  const key = customKey || generatorUUID('xxxx')
  
  return new Promise((res, rej) => {
    // 弹框消失时 维护弹框池的状态还原
    const resetConfirmModals = () => {
      const index = ConfirmModalStoreInstance.confirmModals.findIndex(d => d?.key === key)
      if (typeof index === 'number') {
        ConfirmModalStoreInstance.confirmModals[index] = null
      }
      ConfirmModalStoreInstance.confirmModals = ConfirmModalStoreInstance.confirmModals.sort((a, b) => (b ? 0 : -1))
      if (ConfirmModalStoreInstance.idleNum > INIT_POLL_NUMBER) {
        ConfirmModalStoreInstance.confirmModals.length = INIT_POLL_NUMBER
      }
    }
    // 弹框点击ok内部触发的回调
    const _ok = () => {
      resetConfirmModals()
      res({ value: true, target: 'btn' })
    }
    // 弹框点击cancel内部触发的回调
    const _cancel = (target: 'btn' | 'close') => {
      resetConfirmModals()
      res({ value: false, target })
    }

    const options = {
      ...d,
      _ok,
      _cancel,
      key,
    }

    // 打开弹框，并且传递res和rej，监听confirm里面的回调
    ConfirmModalStoreInstance.confirmModals[ConfirmModalStoreInstance.useNum] = options

    // 假如空闲弹框池小于2个则进行扩充
    if (ConfirmModalStoreInstance.idleNum - ConfirmModalStoreInstance.useNum < 2) {
      ConfirmModalStoreInstance.confirmModals.push(null)
    }
  })
}

const updateConfirmModalContent = (customKey: string, options: Partial<IConfirmModal>) => {
  const curIndex = ConfirmModalStoreInstance.confirmModals.findIndex(f => f?.key === customKey)
  const cur = ConfirmModalStoreInstance.confirmModals[curIndex]
  if (curIndex === -1 || !cur) {
    return false
  }
  ConfirmModalStoreInstance.confirmModals[curIndex] = {
    ...cur,
    ...options
  }
  return true
}

export { ConfirmModalPoll, confirmModal, updateConfirmModalContent }
export default observer(ImperativeConfirmModal)
