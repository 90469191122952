import React, { FC } from 'react'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import { ModalProps } from 'antd/lib/modal'
import styled from 'styled-components'

const TextModal: FC<ModalProps> = ({ children, ...props }) => {
  return (
    <StyledTextModal centered={true} {...props}>
      {children}
    </StyledTextModal>
  )
}

const StyledTextModal = styled(Modal)`
  .ant-modal-content {
    background: none;
  }
  .ant-modal-header {
    padding: 14px 20px;
    padding-left: 30px;
    background: #121517;
    border: none;
    .ant-modal-title {
      font-size: 16px;
      color: #f6f6f8;
    }
  }
  .ant-modal-close-x {
    height: 41px;
    width: 41px;
    line-height: 41px;
    color: #84888b;
    font-size: 12px;
  }
  .ant-modal-body {
    background: #22262a;
    padding: 20px 30px;
  }
  .ant-modal-footer {
    background: #22262a;
    height: 70px;
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    position: relative;
    &::before {
      content: ' ';
      width: calc(100% - 40px);
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: 0;
      left: 20px;
    }
  }
`

export default observer(TextModal)
