import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ImperativeConfirmModal } from 'src/components/base-modal'

const CrashesModal: FC<{ visible:boolean;  onCancel:()=>void; content: { message: string; code: number } | null }> = ({
  content,
  visible,
  ...props
}) => {
  const onOk = () => {
    props?.onCancel?.()
  }

  return (
    <ImperativeConfirmModal options={ visible ? {
      title: '云游戏异常崩溃',
      content: <div>
        {content?.message}({content?.code})
      </div>,
      key: 'confirm-modal-crash',
      okText: '我知道了',
      btns: ['ok'],
      _ok: onOk,
      _cancel() {

      }
    }:null } />
  )
}

export default observer(CrashesModal)
