import DEFAULTS from "./defaults";
import Emitter from "./emitter";
import HeartCheck from "./heartCheck";
import logger from "./logger";
import InterceptorManager from "./interceptorManager";

class Socket<EventMap, T, R> extends Emitter<EventMap, R> {
  socket?: WebSocket;
  option: NsWfSocket.ConfigOption<T, R>;
  reconnectNum: number = 0;
  messageQueue: NsWfSocket.SendType[] = [];
  heart?: HeartCheck;
  interceptor = {
    send: new InterceptorManager<
      T,
      NsWfSocket.SendType,
      Socket<EventMap, T, R>
    >(),
    message: new InterceptorManager<R, R, Socket<EventMap, T, R>>(),
  };
  constructor(initOption: Partial<NsWfSocket.ConfigOption<T, R>>) {
    super(initOption.debug);
    /** merge option */
    this.option = Object.assign(
      {},
      DEFAULTS,
      initOption
    ) as NsWfSocket.ConfigOption<T, R>;

    /** 初始化心跳 */
    if (this.option.heartOption !== false) {
      const ping = this.option.heartOption.ping;
      this.logger(`new HeartCheck`);
      this.heart = new HeartCheck(
        Object.assign({}, this.option.heartOption, {
          onCheck: () => {
            this.logger(`heart send ${ping}`);
            this.socket?.send(ping);
          },
          onTimeOut: () => {
            this.logger(`heart timeout`);
            this.socket?.close();
          },
        })
      );
    }

    /** 离线监听 */
    this.subscribe("offline", () => {
      this.logger("listener offline event");
      window.addEventListener("online", () => {
        this.dispatch("reconnect", "online: reconnect");
      });
    });

    /** 监听重连事件 */
    this.subscribe("reconnect", (reconnectType?: string) => {
      this.logger(
        `run reconnect [${reconnectType}] event: delay ${this.option.connectInterval}`
      );
      /** next： 增加取消异步的机制 */
      setTimeout(() => {
        this.connect();
      }, this.option.connectInterval);
    });

    /** 监听重连次数达到最大次数事件 */
    if (this.option.reconnectInterval > 0) {
      this.subscribe("connect-max", () => {
        this.logger(
          `run connect-max event delay ${
            this.option.reconnectInterval - this.option.connectInterval
          }`
        );
        setTimeout(() => {
          this.reconnectNum = 0;
          this.dispatch("reconnect", "connect-max");
        }, this.option.reconnectInterval - this.option.connectInterval);
      });
    }
  }
  logger(...args: any[]) {
    if (this.option.debug) {
      logger(...args);
    }
  }
  get readyState() {
    return typeof this.socket?.readyState === "undefined"
      ? WebSocket.CLOSED
      : this.socket?.readyState;
  }
  connect(url?: string, protocols?: string[]) {
    if (this.socket && this.socket.readyState < WebSocket.OPEN) {
      this.logger(
        `run connect; return  running state: ${this.socket.readyState}`
      );
      return this.socket?.readyState;
    }
    /** 强制刷新连接 */
    if (url) {
      this.reconnectNum = 0;
    }
    this.option.url = url || this.option.url;
    this.option.protocols = protocols || this.option.protocols;
    this.logger(
      `run connect; args: url = ${
        this.option.url
      }  protocols = ${this.option.protocols?.join(",")}`
    );
    if (this.option.url) {
      this.socket?.close();
      this.socket = new WebSocket(this.option.url, this.option.protocols);
      if (this.socket) {
        /** onopen */
        this.socket.onopen = (e: Event) => {
          this.logger(`socket onopen`);
          this.dispatch("open", e);
          this.messageQueue.forEach((msg) => {
            this.socket?.send(msg);
          });
          this.messageQueue = []
          this.reconnectNum = 0;
          this.heart?.run();
        };
        /** onmessage */
        this.socket.onmessage = (e: MessageEvent<R>) => {
          this.logger(`socket onmessage`);
          this.dispatch("message", e);
          this.heart?.run();
          this.onmessage(e.data);
        };
        /** onclose */
        this.socket.onclose = (e: CloseEvent) => {
          this.logger(`socket onclose`);
          this.heart?.stop();
          if (e.target !== this.socket) {
            this.dispatch("pre-close", e);
            return;
          }
          this.dispatch("close");
          if (!navigator.onLine) {
            this.logger(
              `socket onclose => navigator.onLine : ${navigator.onLine}`
            );
            this.dispatch("offline");
          } else if (
            this.option.maxReconnect > 0 &&
            this.reconnectNum < this.option.maxReconnect
          ) {
            this.reconnectNum++;
            this.logger(
              `socket onclose => reconnect : ${this.reconnectNum} time`
            );
            this.dispatch(
              "reconnect",
              `connect-close: ${this.reconnectNum}time`
            );
          } else if (this.reconnectNum >= this.option.maxReconnect) {
            this.dispatch("connect-max");
          }
        };
        /** onerror */
        this.socket.onerror = (e: Event) => {
          if (e.target !== this.socket) {
            this.logger(`pre socket error`);
            this.dispatch("pre-error", e);
            return;
          }
          this.logger(`socket onerror`);
          this.dispatch("error", e);
        };
      }
    } else {
      console.warn("parameter 'url' is required!");
    }
  }
  send(data: T) {
    let finalData = data;
    if (
      !ArrayBuffer.isView(data) &&
      !(data instanceof Blob) &&
      typeof data !== "string"
    ) {
      if (typeof this.option.transformData === "function") {
        finalData = this.option.transformData(data) as unknown as T;
      }
    }
    if (this.interceptor.send.handler) {
      const { handler, when } = this.interceptor.send.handler;
      if (typeof handler === "function") {
        if (typeof when === "function") {
          finalData = (when(this, {
            raw: data,
            data: finalData as unknown as NsWfSocket.SendType,
          })
            ? handler(finalData) || finalData
            : finalData) as unknown as T;
        } else {
          finalData = (handler(finalData) || finalData) as unknown as T;
        }
      }
    }
    if (this.socket?.readyState === WebSocket.CONNECTING) {
      this.messageQueue.push(finalData as unknown as NsWfSocket.SendType);
    } else if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket?.send(finalData as unknown as NsWfSocket.SendType);
    } else if (this.socket?.readyState === WebSocket.CLOSING) {
      console.warn("WebSocket is already in CLOSING state");
    } else if (this.socket?.readyState === WebSocket.CLOSED) {
      console.warn("WebSocket is already in CLOSED state");
    }
  }
  onmessage(data: R) {
    const transformResponse =
      typeof this.option.transformResponse === "function"
        ? this.option.transformResponse
        : null;
    const pong =
      this.option.heartOption !== false ? this.option.heartOption?.pong : null;

    let finalData = data;
    if (transformResponse) {
      try {
        finalData = transformResponse(data as unknown as NsWfSocket.SendType);
      } catch (error) {
        if (data !== pong) {
          console.warn(error);
        }
      }
    }
    const isPong = data === pong || finalData === pong;
    if (!isPong && this.interceptor.message.handler) {
      const { handler, when } = this.interceptor.message.handler;
      if (typeof handler === "function") {
        if (typeof when === "function") {
          finalData = when(this, { raw: data, data: finalData })
            ? handler(finalData) || finalData
            : finalData;
        } else {
          finalData = handler(finalData) || finalData;
        }
      }
    }
    if (!isPong && finalData) {
      this.dispatch("response", finalData);
    }
  }
  close() {
    this.reconnectNum = this.option.maxReconnect;
    this.heart?.stop();
    this.socket?.close();
  }
}

export default Socket;
