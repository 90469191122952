import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useVipRemainingTime } from 'src/hooks'
import { useNavigate } from 'react-router-dom'
import { ClientLocalStorageStore } from 'src/store/modifier'
import { useIntl } from 'react-intl'
import { checkOversea } from 'src/tools/common'
import SGCheckbox from 'src/components/base-checkbox'
import QueueAnim from 'rc-queue-anim'
import styled from 'styled-components'
import CEFTransfer from 'src/CEFTransfer'

const SystemSetting: FC = () => {
  const navigate = useNavigate()
  const { remainingTimeStringArr } = useVipRemainingTime()
  const { formatMessage } = useIntl()
  const isOversea = checkOversea()

  const handleSelectDownloadPath = async () => {
    const { path } = await CEFTransfer.sendToClient('selectDownloadPath')
    await CEFTransfer.sendToClient('setDownloadPath', { path })
    ClientLocalStorageStore.setSystemResourceFilePath(path)
  }

  const handleSetSystemAutoOpen = (checked: boolean) => {
    CEFTransfer.sendToClient('setAutoRun', { autoRun: checked })
    ClientLocalStorageStore.setSystemAutoOpen(checked)
  }

  return (
    <StyledSystemSettingContainer>
      <QueueAnim component={StyledSafetyInfoContainer}>
        <div className='safety-row' key='safety-row-account'>
          <div className='label'>{formatMessage({id:'sysSettings.common'})}</div>
          <div className='value'>
            <SGCheckbox
              checked={ClientLocalStorageStore.getSystemAutoOpen()}
              onChange={e => handleSetSystemAutoOpen(e.target.checked)}
            >
              {formatMessage({id:'sysSettings.boot'})}
            </SGCheckbox>
          </div>
        </div>
        {!isOversea && <div className='safety-row' key='safety-row-account-2'>
          <div className='label'>加速器设置</div>
          <div className='value'>
            <SGCheckbox
              checked={ClientLocalStorageStore.getSystemAutoStop()}
              onChange={e => ClientLocalStorageStore.setSystemAutoStop(e.target.checked)}
            >
              无加速流量超过 30 MIN 自动停止加速
            </SGCheckbox>
          </div>
        </div>}
        <div className='safety-row' key='safety-row-account-3'>
          <div className='label'>{formatMessage({id:'sysSettings.download'})}</div>
          <div className='value'>
            <StyledDir>
              <label>{formatMessage({id:'sysSettings.directory'})}</label>
              <div className="file" onClick={() => handleSelectDownloadPath()}>
                <div className="path">{ClientLocalStorageStore.getSystemResourceFilePath()}</div>
                <div className="more"><span/><span/><span/></div>
              </div>
            </StyledDir>
          </div>

        </div>
        <div className='safety-row' key='safety-row-account-4'>
          <div className='label'>{formatMessage({id:'sysSettings.closed'})}</div>
          <div className='value'>
            <SGCheckbox
              checked={ClientLocalStorageStore.getSystemConfig().windowClose === '1'}
              onChange={e =>
                e.target.checked
                  ? ClientLocalStorageStore.setSystemConfig({
                      windowCloseTips: ClientLocalStorageStore.getSystemConfig().windowCloseTips,
                      windowClose: '1',
                    })
                  : undefined
              }
            >
              {formatMessage({id:'sysSettings.hide'})}
            </SGCheckbox>
            <SGCheckbox
              checked={ClientLocalStorageStore.getSystemConfig().windowClose === '2'}
              onChange={e =>
                e.target.checked
                  ? ClientLocalStorageStore.setSystemConfig({
                      windowCloseTips: ClientLocalStorageStore.getSystemConfig().windowCloseTips,
                      windowClose: '2',
                    })
                  : undefined
              }
            >
              {formatMessage({id:'sysSettings.exit'})}
            </SGCheckbox>
          </div>
        </div>
      </QueueAnim>
    </StyledSystemSettingContainer>
  )
}

const StyledDir=styled.div`
  display: flex;
  align-items: center;
  >label{
    color: rgba(255,255,255,0.85);
    margin-right: 24px;
  }
  .file{
    flex: 1;
    height: 32px;
    background: #25282C;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    cursor: pointer;
  }
  .path{
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgba(255,255,255,0.6);
  }
  .more{
    display: flex;
    align-items: center;
    >span{
      width: 4px;
      height: 4px;
      background: #96989D;
      margin-left: 2px;
      border-radius: 50%;
    }
  }
`

const StyledSafetyInfoContainer = styled.div`
  .safety-row {
    margin-left: 2px;
    padding: 40px 0;
    border-bottom: 1px solid rgba(78,80,88,0.48);
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      border-bottom: 0;
    }
    .label{
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 24px;
    }
  }
`

const StyledSystemSettingContainer = styled.div`
  width: 100%;
`

export default observer(SystemSetting)
