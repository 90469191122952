import React, { forwardRef, FC, useEffect, useState, useMemo, useImperativeHandle, useRef, useCallback } from 'react'
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom'
import { observer } from 'mobx-react'
import { debounce } from 'lodash'
import { Table, Skeleton, Tooltip, message } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { SpeedStore } from 'src/store'
import { DOMID, SPEED_DETAIL_TABLE_Y, DETAIL_INDEX_HEIGHT } from 'src/constants/common'
import { FadeinImage } from 'src/components/base-image'
import { sleep } from 'src/tools/common'
import SGTable, { SG_TABLE_STYLE } from 'src/components/base-table'
import CheckNodeLoading from 'src/components/loading/check-node-loading'
import QueueAnim from 'rc-queue-anim'
import speedController from 'src/CEFTransfer/speedController'
import styled from 'styled-components'
import classNames from 'classnames'

import allAreaserverImg from 'src/assets/images/my-game/all-areaserver-img.png'
import SignalIcon from 'src/assets/icons/common/signal-icon.svg'
export type TAreaServer = CEF.TAreaInfo & CEF.TServeInfo
// let pollingAreasTimer: number | null = null
// let pollingNodesTestSpeedBatchID: number | null = null

const NODE_STATUS_MAP: Record<string, string> = {
  '1': '空闲',
  '2': '繁忙',
  '3': '满',
}

const SPEED_AREA_SERVER_MAEGIN_BOTTOM = 18

const computedAreaServerInfo = (checkedAreaInfo: CEF.TAreaInfo, checkedServerInfo?: CEF.TServeInfo) => {
  return {
    ...checkedAreaInfo,
    serverID: checkedServerInfo?.serverID || '',
    serverName: checkedServerInfo?.serverName || '',
  }
}

const CheckNode = forwardRef<
  { onConfirm: Function },
  {
    speedGameNo: string
    thirdGameNo: string
    isSpeeding: boolean
    onChangeDetailStatus: (status: undefined) => void
    speedGames: Game.BranchAssociatedDataSpeedGame[]
    setCurrentSpeedGame: (value: Game.BranchAssociatedDataSpeedGame) => void
    gameLibraryName: string
  }
>(({ speedGameNo, thirdGameNo, isSpeeding, onChangeDetailStatus, speedGames, setCurrentSpeedGame, gameLibraryName }, ref) => {
  const checkRef = useRef<{ serverID: string; areaID: string } | null>(null)
  const areaServerRef = useRef<{ gameId: string }>({ gameId: '' })
  const [areaServerList, setAreaServerList] = useState<CEF.TAreaInfo[]>([])
  const [nodeList, setNodeList] = useState<CEF.TNodeInfo[]>([])
  const [checkedAreaInfo, setCheckedAreaInfo] = useState<CEF.TAreaInfo>()
  const [checkedServerInfo, setCheckedServerInfo] = useState<CEF.TServeInfo>()
  const [selectRow, setSelectRow] = useState<CEF.TNodeInfo>()
  const [speedDetailTableY, setSpeedDetailTableY] = useState(124)
  const [loading, setLoading] = useState<boolean>(true)
  const [nodeFetching, setNodeFetching] = useState<boolean>(false)
  const isDefaultSelect = useRef<boolean>(true)
  const pollingNodesTestSpeedBatchID = useRef<number | null>(null)
  const speedInfo = SpeedStore.speedingInfo || SpeedStore.startingSpeedInfo
  const isCurrentSpeed = speedGameNo === SpeedStore.startingSpeedInfo?.speedGameNo
  const isStarting = isCurrentSpeed && SpeedStore.speedStatus === 'starting'
  const speedingInfo = SpeedStore.speedingInfo
  // const onCancel = () => {
  //   promiseModal.reject()
  //   speedStore.speedModalType = 'none'
  // }

  // const onConfirm = async () => {
  //   if (!selectRow?.nodeID) {
  //     return message.warn('请选择加速节点')
  //   }
  //   const { speedAreaId, speedNodeId } = speedInfo || {}
  //   if (`${speedAreaId}` === `${checkedAreaServer?.areaID}` && `${speedNodeId}` === `${selectRow.nodeID}`) {
  //     return message.warn('当前选择区服已在加速中，无需切换')
  //   }
  //   promiseModal.resolve({ areaInfo: checkedAreaServer, nodeInfo: selectRow })
  //   speedStore.speedModalType = 'none'
  // }

  const fetchAreaList = async (speedGameNo: string) => {
    try {
      setLoading(true)
      areaServerRef.current.gameId = `${speedGameNo}`
      const result = await speedController.getAllAreaList(`${speedGameNo}`)
      if (areaServerRef.current.gameId === `${speedGameNo}`) {
        handleAreaListChangeByThirdGameNo(speedGameNo, result.areaList)
        result?.areaList && setAreaServerList(result.areaList)
      }
    } catch (error:any) {
      message.warn(`getAllAreaList error(${error?.message || error})`)
      console.error(error)
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const fetchNodeList = useCallback(
    debounce(
      async (thirdGameNo: string, areaServer: TAreaServer) => {
        try {
          let result: {
            nodeList?: CEF.TNodeInfo[]
            testSpeedBatchID?: number
            moreTestSpeedBatch?: boolean
            errorMsg?: string
          } = {}
          if (areaServer.isAllAreaServerGame) {
            result = {
              nodeList: [
                {
                  nodeID: '0',
                  nodeName: '智能区服',
                  nodeDelay: '0',
                  nodeLoss: '0',
                  nodeBusy: '1',
                },
              ],
              testSpeedBatchID: 0,
              moreTestSpeedBatch: false,
            }
          } else {
            result = await speedController.getAllNodeList({
              gameID: `${thirdGameNo}`,
              areaID: `${areaServer.areaID}`,
              serverID: `${areaServer.serverID}`,
            })
          }
          if (result?.errorMsg) {
            message.warn(result.errorMsg)
            throw new Error(result.errorMsg)
          }
          if (
            !checkRef.current ||
            checkRef.current.areaID !== areaServer.areaID ||
            checkRef.current.serverID !== areaServer.serverID
          ) {
            throw new Error(`${areaServer.serverName} - ${areaServer.areaName}: 已切换区服`)
          }

          if (result?.nodeList?.length) {
            setNodeList(
              result.nodeList.sort((a, b) => {
                const weightMap = {
                  '1': 10000,
                  '2': 20000,
                  '3': 30000,
                }
                const finalA = Number(a.nodeDelay) + Number(weightMap[a.nodeBusy])
                const finalB = Number(b.nodeDelay) + Number(weightMap[b.nodeBusy])
                return finalA - finalB
              }),
            )
            setSelectRow(result.nodeList![0])
          }

          if (result.testSpeedBatchID) {
            if (result.moreTestSpeedBatch) {
              pollingNodesTestSpeedBatchID.current = result.testSpeedBatchID
              fetchMoreNodeList(result.testSpeedBatchID)
            } else {
              pollingNodesTestSpeedBatchID.current = null
              stopFetchNodeList(result.testSpeedBatchID)
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          setNodeFetching(false)
        }
      },
      300,
      { trailing: true },
    ),
    [],
  )

  const stopFetchNodeList = async (testSpeedBatchID: number) => {
    return await speedController.stopMoreNodeList({ testSpeedBatchID })
  }

  const fetchMoreNodeList = async (testSpeedBatchID: number) => {
    try {
      const result = await speedController.getMoreNodeList({ testSpeedBatchID })
      if (pollingNodesTestSpeedBatchID.current !== testSpeedBatchID) {
        stopFetchNodeList(testSpeedBatchID)
        return
      }
      setNodeList(result.nodeList.sort((a, b) => Number(a.nodeDelay) - Number(b.nodeDelay)))
      if (isDefaultSelect.current) {
        setSelectRow(result.nodeList![0])
      }
      if (result.moreTestSpeedBatch) {
        await sleep(3000)
        fetchMoreNodeList(testSpeedBatchID)
      } else {
        pollingNodesTestSpeedBatchID.current = null
        stopFetchNodeList(testSpeedBatchID)
      }
    } catch (error) {
      stopFetchNodeList(testSpeedBatchID)
    }
  }

  const onSelect = (record: CEF.TNodeInfo) => {
    return {
      onClick: () => {
        isDefaultSelect.current = false
        setSelectRow(record)
      },
    }
  }

  const handleStartNodesPolling = (
    thirdGameNo: string,
    checkedAreaInfo: CEF.TAreaInfo,
    checkedServerInfo?: CEF.TServeInfo,
  ) => {
    setNodeList([])
    setSelectRow(undefined)
    setNodeFetching(true)
    isDefaultSelect.current = true
    const fetchNodesPayload = computedAreaServerInfo(checkedAreaInfo, checkedServerInfo)
    checkRef.current = {
      serverID: checkedServerInfo?.serverID || '',
      areaID: checkedAreaInfo.areaID,
    }
    fetchNodeList(thirdGameNo, fetchNodesPayload)
  }

  const handleChangeAreaInfo = (areaInfo: CEF.TAreaInfo) => {
    if (areaInfo.areaID === checkedAreaInfo?.areaID) {
      return
    }
    setCheckedAreaInfo(areaInfo)
    const defaultServerInfo = areaInfo.serverList?.[0] || undefined
    setCheckedServerInfo(defaultServerInfo)
    handleStartNodesPolling(thirdGameNo, areaInfo, defaultServerInfo)
  }

  const hanleChangeServerInfo = (serverInfo: CEF.TServeInfo) => {
    if (!checkedAreaInfo) {
      return
    }
    setCheckedServerInfo(serverInfo)
    handleStartNodesPolling(thirdGameNo, checkedAreaInfo, serverInfo)
  }

  const handleRefreshNodeList = () => {
    handleStartNodesPolling(thirdGameNo, checkedAreaInfo!, checkedServerInfo)
  }

  const speedNodeInfo = useMemo(() => {
    let result = ''
    if (speedingInfo) {
      result = [speedingInfo.speedAreaName, speedingInfo.speedServerName].filter(Boolean).join(' | ')
      if (speedingInfo.speedNodeName) {
        result = `${result} - ${speedingInfo.speedNodeName}`
      }
    }
    return result
  }, [speedingInfo])

  const handleAreaListChangeByThirdGameNo = (thirdGameNo: string, areaServerList: CEF.TAreaInfo[]) => {
    const defaultAreaInfo = areaServerList[0]
    const defaultServerInfo = defaultAreaInfo.serverList?.[0] || undefined
    setCheckedAreaInfo(defaultAreaInfo)
    if (defaultServerInfo) {
      setCheckedServerInfo(defaultServerInfo)
    }
    handleStartNodesPolling(thirdGameNo, defaultAreaInfo, defaultServerInfo)
  }

  useImperativeHandle(ref, () => ({
    onConfirm: () => {
      if (!selectRow?.nodeID) {
        throw new Error('请选择加速节点')
      }
      if (selectRow && Number(selectRow.nodeDelay) > 999) {
        throw new Error('当前节点不可用，请刷新或更换加速节点后重试')
      }
      if (selectRow && Number(selectRow.nodeBusy) !== 1) {
        return message.warn('当前加速服务器节点繁忙，无法进行加速，请选择空闲节点进行加速')
      }
      const { speedAreaId, speedNodeId } = speedInfo || {}
      if (`${speedAreaId}` === `${checkedAreaInfo?.areaID}` && `${speedNodeId}` === `${selectRow.nodeID}`) {
        throw new Error('当前选择区服已在加速中，无需切换')
      }
      if (!checkedAreaInfo) {
        throw new Error('当前节点不可用，请刷新或更换加速节点后重试')
      }
      return { areaServerInfo: computedAreaServerInfo(checkedAreaInfo, checkedServerInfo), nodeInfo: selectRow }
    },
  }))

  // useEffect(() => {
  //   if (areaServerList?.length && !checkedAreaInfo) {
  //     const defaultAreaInfo = areaServerList[0]
  //     const defaultServerInfo = defaultAreaInfo.serverList?.[0] || undefined
  //     setCheckedAreaInfo(defaultAreaInfo)
  //     if (defaultServerInfo) {
  //       setCheckedServerInfo(defaultServerInfo)
  //     }
  //     console.log('1111111111')
  //     handleStartNodesPolling(defaultAreaInfo, defaultServerInfo)
  //   }
  // }, [areaServerList, checkedAreaInfo])

  useEffect(() => {
    if (thirdGameNo) {
      fetchAreaList(thirdGameNo)
    }
    return () => {
      console.log('clear ggggggggg')
      setAreaServerList([])
      setNodeList([])
      setCheckedAreaInfo(undefined)
      setSelectRow(undefined)
      pollingNodesTestSpeedBatchID.current = null
      isDefaultSelect.current = true
      checkRef.current = null
      areaServerRef.current.gameId = ''
    }
  }, [thirdGameNo])

  useEffect(() => {
    if (areaServerList && areaServerList.length !== 0) {
      let timer: number | null = null

      const handleComputeHeight = () => {
        const layoutContentDOM = document.querySelector(`#${DOMID.MINI_LAYOUT_CONTENT}`)
        const speedAreaSectionDOM = document.querySelector(`#${SPEED_DETAIL_TABLE_Y.SPEED_AREA_SECTION_ID}`)
        if (!layoutContentDOM || !speedAreaSectionDOM) {
          return
        }

        /** 游戏类目 */
        const speedGameSectionDOM = document.querySelector(`#${SPEED_DETAIL_TABLE_Y.SPEED_GAME_SECTION_ID}`)
        const speedGameSectionHeightTotal = speedGameSectionDOM
          ? speedGameSectionDOM.clientHeight + SPEED_AREA_SERVER_MAEGIN_BOTTOM
          : 0
        /** 区 */
        const speedAreaSectionHeightTotal =
          speedAreaSectionDOM.clientHeight + SPEED_AREA_SERVER_MAEGIN_BOTTOM

        /** 服 */
        const speedSectionDOM = document.querySelector(`#${SPEED_DETAIL_TABLE_Y.SPEED_SERVER_SECTION_ID}`)
        const speedServerSectionHeihtTotal = speedSectionDOM
          ? speedSectionDOM.clientHeight + SPEED_AREA_SERVER_MAEGIN_BOTTOM
          : 0

        const layoutContentHeight = layoutContentDOM.clientHeight

        // 包含上下padding
        const gameDetailContainerPadding = 0

        const gameNameHeightTotal = 52

        const speedSectionTitleHeightTotal =
          SPEED_DETAIL_TABLE_Y.SPEED_SECTION_TITLE_LINE_HEIGHT + SPEED_DETAIL_TABLE_Y.SPEED_SECTION_TITLE_MARGIN_BOTTOM
        const speedDetailTableHeaderHeightTotal =
          SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_HEIGHT + SG_TABLE_STYLE.SPEED_DETAIL_TABLE_HEADER_MARGIN_BOTTOM
        const speedingTotalHeight = isSpeeding
          ? SPEED_DETAIL_TABLE_Y.SPEED_SECTION_TITLE_LINE_HEIGHT + SPEED_AREA_SERVER_MAEGIN_BOTTOM
          : 0

        const finalValue =
          layoutContentHeight -
          gameDetailContainerPadding -
          gameNameHeightTotal -
          speedAreaSectionHeightTotal -
          speedGameSectionHeightTotal -
          speedServerSectionHeihtTotal -
          speedSectionTitleHeightTotal -
          speedDetailTableHeaderHeightTotal -
          speedingTotalHeight
        setSpeedDetailTableY(Math.max(finalValue, 124))
      }
      const handleSetTableY = () => {
        timer && window.clearTimeout(timer)
        const areaItemCount = document.querySelectorAll(`.${SPEED_DETAIL_TABLE_Y.SPEED_AREA_ITEM_CLASS}`).length || 0
        const serverItemCount =
          document.querySelectorAll(`.${SPEED_DETAIL_TABLE_Y.SPEED_SERVER_ITEM_CLASS}`).length || 0
        const serverTrueCount = checkedAreaInfo?.serverList?.length || 0
        if (!checkedAreaInfo || areaItemCount !== areaServerList.length || serverTrueCount !== serverItemCount) {
          timer = window.setTimeout(() => {
            handleSetTableY()
          }, 500)
          return
        }
        handleComputeHeight()
      }
      handleSetTableY()
      window.addEventListener('resize', handleSetTableY)
      return () => {
        timer && window.clearTimeout(timer)
        window.addEventListener('resize', handleSetTableY)
      }
    }
  }, [areaServerList, checkedAreaInfo, isSpeeding])
  return (
      <StyledCheckNodeContainer>
        <div className="game">{gameLibraryName}-云游戏加速器</div>
        {isSpeeding && (
            <StyledSectionBox key='StyledCheckNodeContainer-0'>
              <div className='title'>
            <span>
              当前加速<span className='small-text'>{speedNodeInfo}</span>
            </span>
                {!isStarting && (
                    <span className='extra-text' onClick={() => onChangeDetailStatus(undefined)}>
                取消切换
              </span>
                )}
              </div>
            </StyledSectionBox>
        )}
        {/* <StyledSectionBox key='StyledCheckNodeContainer-1' id={SPEED_DETAIL_TABLE_Y.SPEED_AREA_SERVER_ID}>
        <div className='title'>加速器区服</div>
        <div className='content'>
          <QueueAnim component={StyledViewAreaServer}>
            {renderAreaServerList?.map(info => {
              return (
                <StyledAreaServerItem
                  key={`${info.areaID}-${info.serverID}`}
                  className={classNames('pointer', SPEED_DETAIL_TABLE_Y.SPEED_AREA_SERVER_ITEM_CLASS, {
                    checked:
                      `${checkedAreaServer?.areaID}-${checkedAreaServer?.serverID}` ===
                      `${info.areaID}-${info.serverID}`,
                  })}
                  onClick={() => setCheckedAreaServer(info)}
                >
                  {[info.areaName, info.serverName].filter(Boolean).join('-')}
                </StyledAreaServerItem>
              )
            })}
          </QueueAnim>
        </div>
      </StyledSectionBox> */}
        {speedGames && speedGames.length > 1 && (
            <StyledSectionBox key='StyledCheckNodeContainer-0' id={SPEED_DETAIL_TABLE_Y.SPEED_GAME_SECTION_ID}>
              <div className='title'>加速器类目</div>
              <div className='content'>
                <StyledViewAreaServer>
                  {speedGames.map(info => {
                    return (
                        <StyledAreaServerItem
                            key={info.speedGameNo}
                            className={classNames('pointer', SPEED_DETAIL_TABLE_Y.SPEED_GAME_ITEM_CLASS, {
                              checked: speedGameNo === info.speedGameNo,
                            })}
                            onClick={() => {
                              if (info.speedGameNo === speedGameNo) {
                                return
                              }
                              setCurrentSpeedGame(info)
                            }}
                        >
                          {info.speedGameName}
                        </StyledAreaServerItem>
                    )
                  })}
                  {/* </QueueAnim> */}
                </StyledViewAreaServer>
              </div>
            </StyledSectionBox>
        )}
        {!loading && (
            <>
              <StyledSectionBox key='StyledCheckNodeContainer-1' id={SPEED_DETAIL_TABLE_Y.SPEED_AREA_SECTION_ID}>
                <div className='title'>加速器区服</div>
                <div className='content'>
                  <QueueAnim component={StyledViewAreaServer}>
                    {/* <StyledViewAreaServer> */}
                    {areaServerList?.map(info => {
                      return (
                          <StyledAreaServerItem
                              key={`${info.areaID}`}
                              className={classNames('pointer', SPEED_DETAIL_TABLE_Y.SPEED_AREA_ITEM_CLASS, {
                                checked: checkedAreaInfo?.areaID === info.areaID,
                              })}
                              onClick={() => handleChangeAreaInfo(info)}
                          >
                            {[info.areaName].filter(Boolean).join('-')}
                          </StyledAreaServerItem>
                      )
                    })}
                  </QueueAnim>
                  {/* </StyledViewAreaServer> */}
                </div>
              </StyledSectionBox>
              {checkedAreaInfo?.serverList && (
                  <StyledSectionBox id={SPEED_DETAIL_TABLE_Y.SPEED_SERVER_SECTION_ID}>
                    <div className='title'>加速器地区</div>
                    <div className='content'>
                      <StyledViewAreaServer>
                        {checkedAreaInfo?.serverList?.map(info => {
                          return (
                              <StyledAreaServerItem
                                  key={`${info.serverID}`}
                                  className={classNames('pointer', SPEED_DETAIL_TABLE_Y.SPEED_SERVER_ITEM_CLASS, {
                                    checked: checkedServerInfo?.serverID === info.serverID,
                                  })}
                                  onClick={() => hanleChangeServerInfo(info)}
                              >
                                {[info.serverName].filter(Boolean).join('-')}
                              </StyledAreaServerItem>
                          )
                        })}
                      </StyledViewAreaServer>
                    </div>
                  </StyledSectionBox>
              )}
              <StyledSectionBox key='StyledCheckNodeContainer-2'>
                <div className='title'>
                  加速节点
                  {checkedAreaInfo && (
                      <Tooltip overlay='刷新'>
                        <div
                            className={classNames('reload-icon', {
                              active: nodeFetching,
                            })}
                            onClick={() => !nodeFetching && handleRefreshNodeList()}
                        >
                          <SyncOutlined/>
                        </div>
                      </Tooltip>
                  )}
                </div>
                <div className='content'>
                  <StyledViewNodeList>
                    <SGTable
                        emptyHeiht={speedDetailTableY}
                        pagination={false}
                        scroll={{y: speedDetailTableY}}
                        dataSource={checkedAreaInfo?.isAllAreaServerGame || nodeFetching ? [] : nodeList}
                        rowKey='nodeID'
                        locale={{
                          emptyText: () => {
                            if (checkedAreaInfo?.isAllAreaServerGame) {
                              return (
                                  <StyledAllAreaServerContainer>
                                    <FadeinImage
                                        width={92}
                                        height={81}
                                        className='all-areaserver-img'
                                        type='notDefault'
                                        src={allAreaserverImg}
                                    />
                                    <div className='all-areaserver-text'>
                                      当前为全服加速，<span className='main'>无需选择区服节点</span>，游戏中
                                      <span className='main'>可随意切换区服</span>
                                    </div>
                                    <div className='all-areaserver-text'>如需手动选择节点，请切换到单服加速。</div>
                                  </StyledAllAreaServerContainer>
                              )
                            }
                            const skeletonList = new Array(4).fill(null)
                            return skeletonList.map(d => {
                              return (
                                  <StyledSkeletonContainer>
                                    <Skeleton.Input className='skeleton-1' active={true}/>
                                    <Skeleton.Input className='skeleton-2' active={true}/>
                                    <Skeleton.Input className='skeleton-3' active={true}/>
                                    <Skeleton.Input className='skeleton-4' active={true}/>
                                  </StyledSkeletonContainer>
                              )
                            })
                          },
                        }}
                        rowClassName={(record: any) => {
                          return record.nodeID === selectRow?.nodeID ? 'select-row' : ''
                        }}
                        onRow={record => onSelect(record as CEF.TNodeInfo)}
                    >
                      <Table.Column
                          title='可用节点'
                          key='0'
                          dataIndex='nodeName'
                          ellipsis={true}
                          render={(nodeName, record: CEF.TNodeInfo) => {
                            return [
                              <span>{nodeName}</span>,
                              `${speedInfo?.speedGameNo}` === `${speedGameNo}` &&
                              `${speedInfo?.speedNodeId}` === `${record.nodeID}` ? (
                                  <span className='speeding-tip'>加速中...</span>
                              ) : null,
                            ]
                          }}
                      />
                      <Table.Column
                          title='延迟'
                          key='1'
                          width='20%'
                          align='center'
                          dataIndex='nodeDelay'
                          render={value => (
                              <StyledNodeDelay
                                  className={classNames({
                                    good: value <= 90,
                                    general: value > 90 && value <= 120,
                                    bad: value > 120,
                                  })}
                              >
                                <SignalIcon className='icon'/>
                                {value > 999 ? '未知' : `${value || 0}ms`}
                              </StyledNodeDelay>
                          )}
                      />
                      <Table.Column
                          title='丢包率'
                          key='2'
                          width='20%'
                          align='center'
                          dataIndex='nodeLoss'
                          render={value => `${value || 0}%`}
                      />
                      <Table.Column
                          title='状态'
                          key='3'
                          width='20%'
                          align='center'
                          dataIndex='nodeBusy'
                          render={value => (
                              <StyledNodeDelay
                                  className={classNames({
                                    good: !value || Number(value) === 1,
                                    general: Number(value) === 2,
                                    bad: Number(value) === 3,
                                  })}
                              >
                                {NODE_STATUS_MAP[`${value}`] || '空闲'}
                              </StyledNodeDelay>
                          )}
                      />
                    </SGTable>
                  </StyledViewNodeList>
                </div>
              </StyledSectionBox>
            </>
        )}
      </StyledCheckNodeContainer>
  )
})

const StyledSkeletonContainer = styled.div`
  width: 100%;
  ${props => props.theme.flexAlignCenter}
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0;
  }
  .skeleton-1 {
    flex: 1;
    height: 24px;
    border-radius: 2px;
  }
  .skeleton-2 {
    width: 20%;
    min-width: 59px;
    height: 24px;
    border-radius: 2px;
  }
  .skeleton-3 {
    width: 20%;
    min-width: 59px;
    height: 24px;
    border-radius: 2px;
  }
  .skeleton-4 {
    width: 20%;
    min-width: 59px;
    height: 24px;
    border-radius: 2px;
  }
`

const StyledViewNodeList = styled.div`
  .ant-table-thead .ant-table-cell{
    background: rgba(255,255,255,.06);
  }
`

const StyledAllAreaServerContainer = styled.div`
  ${props => props.theme.flexCenter}
  flex-direction: column;
  width: 100%;
  min-height: 178px;
  height: 178px;
  padding-top: 56px;
  .all-areaserver-img {
    margin-bottom: 30px;
  }
  .all-areaserver-text {
    font-size: 12px;
    color: ${props => props.theme.color.text.$999999};
    line-height: 22px;
    .main {
      color: var(--text-color-FFFFFF);
    }
  }
`

const StyledNodeDelay = styled.span`
  font-size: 13px;
  .icon {
    margin-right: 0.4em;
  }
  &.good {
    color: #1edf43;
  }
  &.general {
    color: #ffd447;
  }
  &.bad {
    color: #ec3a00;
  }
`

const StyledAreaServerItem = styled.div`
  ${props => props.theme.flexCenter}
  padding: 0 10px;
  min-width: 152px;
  height: 40px;
  background: ${props => props.theme.color.bg.$FFFFFF06};
  border: 1px solid transparent;
  margin-right: 22px;
  margin-bottom: 22px;
  font-size: 14px;
  color: ${props => props.theme.color.text.$F0F0F0};
  border-radius: 4px;
  backdrop-filter: blur(30px);
  &:hover {
    background: ${props => props.theme.color.bg.$FFFFFF12};
  }
  &.checked {
    background: ${props => props.theme.color.bg.$864DFE24};
  }
`

const StyledViewAreaServer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 22px);
`

const StyledSectionBox = styled.div`
  margin-bottom: ${SPEED_AREA_SERVER_MAEGIN_BOTTOM}px;
  &:last-child {
    margin-bottom: 0;
  }
  .title {
    position: relative;
    font-size: 16px;
    line-height: ${SPEED_DETAIL_TABLE_Y.SPEED_SECTION_TITLE_LINE_HEIGHT}px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    margin-bottom: 12px;
    ${props => props.theme.flexAlignCenter}
    justify-content: space-between;
    .small-text {
      margin-left: 16px;
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$BBBBBB};
    }
    .extra-text {
      color: ${props => props.theme.color.mainColor};
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .reload-icon {
      position: absolute;
      left: 74px;
      bottom: 0;
      color: ${props => props.theme.color.text.$BBBBBB};
      font-size: 16px;
      margin-left: 12px;
      cursor: pointer;
      &:hover {
        color: var(--text-color-FFFFFF);
      }
      &.active {
        transform: rotate(0deg);
        animation: reload 1s linear 0s infinite;
      }
      @keyframes reload {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`

const StyledCheckNodeContainer = styled.div`
  .game{
    font-size: 28px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 1;
    margin-bottom: 24px;
  }
`

export default observer(CheckNode)
