import { observable, action } from 'mobx'

class ControllerStore {

  @observable
  detailModalResourceId: number | null = null

  @action
  openDetailModal = (resourceId: number) => {
    this.detailModalResourceId = resourceId
  }

  @action
  cancelDetailModal = () => {
    this.detailModalResourceId = null
  }

  @observable
  informationModalResourceId: number | null = null

  @action
  openInformationModal = (resourceId: number) => {
    this.informationModalResourceId = resourceId
  }

  @action
  cancelInformationModal = () => {
    this.informationModalResourceId = null
  }
}

export default new ControllerStore()
