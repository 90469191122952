import React, { useEffect, useState, FC, useMemo, useRef } from 'react'
import { Carousel, message } from 'antd'
import { observer } from 'mobx-react'
import { SectionsContainer, Section } from 'react-fullpage'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useThrottleFn } from 'ahooks'
import homeBannerBg from 'src/assets/images/website/bg.png'
import desImg from 'src/assets/images/website/des.png'
import downloadButton from 'src/assets/images/website/download-button.png'
import page2Title from 'src/assets/images/website/page2-title.png'
import page2Banner from 'src/assets/images/website/page2-banner.png'
import page3Title from 'src/assets/images/website/page3-title.png'
import page3Bg from 'src/assets/images/website/page3-bg.png'
import page4Bg from 'src/assets/images/website/page4-bg.png'
import page4Title from 'src/assets/images/website/page4-title.png'
import platform1 from 'src/assets/images/website/platform1.png'
import platform2 from 'src/assets/images/website/platform2.png'
import platform3 from 'src/assets/images/website/platform3.png'
import platform4 from 'src/assets/images/website/platform4.png'
import platform5 from 'src/assets/images/website/platform5.png'
import line from 'src/assets/images/website/line.png'
import logo from 'src/assets/images/website/logo.png'
import taoliImg from 'src/assets/images/website/逃离.png'
import taoliBgImg from 'src/assets/images/website/逃离背景.png'
import anheiImg from 'src/assets/images/website/暗黑.png'
import anheiBgImg from 'src/assets/images/website/暗黑背景.png'
import gameIcon from 'src/assets/images/website/game-icon.png'

import styled from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'

const sliderList = [
  {
    img: taoliImg,
    bgImg: taoliBgImg,
    desc: '支持多平台加速，低延迟秒连接，画面流畅',
    name: '逃离塔科夫专用加速器',
  },
  {
    img: anheiImg,
    bgImg: anheiBgImg,
    desc: '千款游戏，周周更新，告别延迟拒绝丢包',
    name: '暗黑破坏神2专用加速器',
  },
]

const product = [
  {
    img: 'https://files.cdn.namejin.com/demon/3-63ad8912cbce4a429b0c74f338185c44.png',
    des: '支持千款游戏',
    sort: '3',
  },
  {
    img: 'https://files.cdn.namejin.com/demon/2-ddf3555312d845989dfdb797f7d30f0e.png',
    des: '专线加速稳定',
    sort: '2',
  },
  {
    img: 'https://files.cdn.namejin.com/demon/1-319c3151166f414cacfe73f3245179d2.png',
    des: '全球千万用户覆盖',
    sort: '1',
  },
]

const desc =
  'MyBuff，一款专业的游戏加速软件，注册即支持海量游戏加速，拒绝延迟、告别卡顿，多线路节点让游戏更稳定畅快，不仅支持steam、epic、战网、橘子、uplay等平台游戏加速，还有专业技术团队保障加速体验为您畅快游戏保驾护航。'

type A = {
  removeMouseWheelEventHandlers: () => void
  addMouseWheelEventHandlers: () => void
  state: {
    activeSection: number
  }
}

let flag = false
let num = 0

const Home: FC = () => {
  const scrollDom = useRef<A>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [pageFlag, setPageFlag] = useState(false)
  const swiperRef = useRef<any>(null)
  const download = () => {
    window.open(`http://downloads.xutaowang.com/files/SGToolsSetup_1.0.0.17.exe`)
  }
  const options = {
    sectionClassName: 'section',
    anchors: ['sectionOne', 'sectionTwo', 'sectionThree', 'sectionFour'],
    scrollBar: false,
    navigation: true,
    verticalAlign: false,
    arrowNavigation: true,
    lockAnchors: true,
    delay: 500,
    scrollingSpeed: 1000,
    scrollCallback: function (states: any) {
    },
  }
  // const bindHandleScroll = (event: any) => {
  //   const e = event
  //   if (scrollDom.current?.state.activeSection === 3) {
  //     if (e?.wheelDelta) {
  //       //判断浏览器IE，谷歌滑轮事件
  //       changeSlider(e.wheelDelta)
  //     } else if (e?.detail) {
  //       //Firefox滑轮事件
  //       changeSlider(e.detail)
  //     }
  //   }
  // }
  const { run: changeSlider } = useThrottleFn(
    (wheelDelta: number) => {
      if (!flag) {
        flag = true
        return
      }
      if (wheelDelta < 0) {
        if (num === 0) {
          num++
        } else {
          changeFooter('block')
        }
      } else {
        if (num === 0) {
          nextPage(-100)
          scrollDom.current?.addMouseWheelEventHandlers()
          flag = false
        } else {
          num--
          changeFooter('none')
        }
      }
    },
    { wait: 300 },
  )
  const nextPage = (num: number) => {
    if (window && document) {
      const evt: any = document.createEvent('UIEvents')
      evt.initUIEvent('DOMMouseScroll', true, false, window, num)
      evt.initUIEvent('mousewheel', true, false, window, num)
      window.dispatchEvent(evt)
    }
  }
  const changeFooter = (display: string) => {
    const footer: any = document.getElementById('footer')
    const content: any = document.getElementById('page4-content')
    if (display === 'block') {
      content.classList.add('top')
      footer.style.opacity = 1
    } else {
      content.classList.remove('top')
      footer.style.opacity = 0
    }
  }
  useEffect(() => {
    /** 客户端环境执行 */
    if (window && document && pageFlag) {
      // if (document.addEventListener) {
      //   document.addEventListener('DOMMouseScroll', bindHandleScroll, false)
      // }
      // window.onmousewheel = bindHandleScroll as any

      // Init Context
      const c = document.createElement('canvas').getContext('2d')
      const postctx = (document.getElementById('home-banner-2') as HTMLCanvasElement).getContext('2d')

      const image = document.createElement('img')
      image.src = homeBannerBg

      const canvas = c!.canvas
      const vertices: number[][] = []

      // Effect Properties
      const vertexCount = 9000
      const vertexSize = 3
      const oceanWidth = 102
      const oceanHeight = -300
      const gridSize = 32
      const waveSize = 16
      const perspective = 300
      const depth = (vertexCount / oceanWidth) * gridSize
      let frame = 0
      const { sin, cos, tan, PI } = Math
      const loop = () => {
        const rad = (sin(frame / 100) * PI) / 20
        const rad2 = (sin(frame / 50) * PI) / 10
        frame++
        if (!postctx || !c) {
          return
        }
        if (
          postctx.canvas.width !== postctx.canvas.offsetWidth ||
          postctx.canvas.height !== postctx.canvas.offsetHeight
        ) {
          postctx.canvas.width = canvas.width = postctx.canvas.offsetWidth
          postctx.canvas.height = canvas.height = postctx.canvas.offsetHeight
        }
        // c.fillStyle = `hsl(200deg, 100%, 2%)`
        c.fillStyle = `rgb(0,0,0)`
        c.fillRect(0, 0, canvas.width, canvas.height)
        c.drawImage(image, 0, 0)
        c.save()
        c.translate(canvas.width / 2, canvas.height / 2)
        c.beginPath()
        vertices.forEach((vertex, i) => {
          let x = vertex[0] - (frame % (gridSize * 2))
          let z = vertex[2] - ((frame * 2) % gridSize) + (i % 2 === 0 ? gridSize / 2 : 0)
          const wave = cos(frame / 45 + x / 50) - sin(frame / 20 + z / 50) + sin(frame / 30 + (z * x) / 10000)
          let y = vertex[1] + wave * waveSize
          const a = Math.max(0, 1 - Math.sqrt(x ** 2 + z ** 2) / depth)
          let tx, ty, tz
          y -= oceanHeight
          // Transformation variables
          tx = x
          ty = y
          tz = z
          // Rotation Y
          tx = x * cos(rad) + z * sin(rad)
          tz = -x * sin(rad) + z * cos(rad)
          x = tx
          y = ty
          z = tz
          // Rotation Z
          tx = x * cos(rad) - y * sin(rad)
          ty = x * sin(rad) + y * cos(rad)
          x = tx
          y = ty
          z = tz
          // Rotation X
          ty = y * cos(rad2) - z * sin(rad2)
          tz = y * sin(rad2) + z * cos(rad2)

          x = tx
          y = ty
          z = tz

          x /= z / perspective
          y /= z / perspective

          if (a < 0.01) return
          if (z < 0) return

          c.globalAlpha = a
          c.fillStyle = `hsl(${180 + wave * 20}deg, 100%, 50%)`
          c.fillRect(x - (a * vertexSize) / 2, y - (a * vertexSize) / 2, a * vertexSize, a * vertexSize)
          c.globalAlpha = 1
        })
        c.restore()

        // Post-processing
        postctx.drawImage(canvas, 0, 0)

        postctx.globalCompositeOperation = 'screen'
        postctx.filter = 'blur(16px)'
        postctx.drawImage(canvas, 0, 0)
        postctx.filter = 'blur(0)'
        postctx.globalCompositeOperation = 'source-over'

        requestAnimationFrame(loop)
      }

      for (let i = 0; i < vertexCount; i++) {
        const x = i % oceanWidth
        const y = 0
        const z = (i / oceanWidth) >> 0
        const offset = oceanWidth / 2
        vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize])
      }

      loop()
    }
  }, [pageFlag])
  useEffect(() => {
    setPageFlag(true)
  }, [])
  return (
    <StyledHomeContainer>
      {!pageFlag && (
        <StyledBanner className='ffeefff'>
          <StyledLogo>
            <img style={{ marginRight: 10 }} src={logo} alt='' />
          </StyledLogo>
          <StyledDes src={desImg}></StyledDes>
          <StyledPage1Text>注册送免费时长</StyledPage1Text>
          <StyledDownload id='semDownloadBtn' onClick={download} />
          <StyledPage1Text2>
            <span className='left' />
            全网独家 • 所有平台加速 • 永久免费
            <span className='right' />
          </StyledPage1Text2>
          <StyledDownRow>
            <img className='platformImg' src={platform1} />
            <img className='platformImg' src={platform2} />
            <img className='platformImg' src={platform3} />
            <img className='platformImg' src={platform4} />
            <img className='platformImg' src={platform5} />
          </StyledDownRow>
        </StyledBanner>
      )}
      {pageFlag && (
        <SectionsContainer
          ref={scrollDom}
          {...options}
          className='full'
          id='full'
          style={{ top: `-${window && document ? window.innerHeight : 937}px` }}
          activeSection={activeIndex}
        >
          <Section className='page1'>
            <StyledBanner>
              <canvas id='home-banner-2'></canvas>
              <StyledLogo>
                <img style={{ marginRight: 10 }} src={logo} alt='' />
              </StyledLogo>
              <StyledDes src={desImg}></StyledDes>
              <StyledPage1Text>注册送免费时长</StyledPage1Text>
              <StyledDownload id='semDownloadBtn' data-agl-cvt={6} onClick={download} />
              <StyledPage1Text2>
                <span className='left' />
                全网独家 • 所有平台加速 • 永久免费
                <span className='right' />
              </StyledPage1Text2>
              <StyledDownRow>
                <img className='platformImg' src={platform1} />
                <img className='platformImg' src={platform2} />
                <img className='platformImg' src={platform3} />
                <img className='platformImg' src={platform4} />
                <img className='platformImg' src={platform5} />
              </StyledDownRow>
            </StyledBanner>
          </Section>
          <Section className='page2'>
            <StyledPage bg={'https://files.cdn.namejin.com/demon/page2-08c1659311fb4410821461a115c49597.png'}>
              <StyledSwiperTitle src={page2Title}></StyledSwiperTitle>
              <img style={{ marginTop: 80 }} src={page2Banner} />
            </StyledPage>
          </Section>
          <Section className='page3'>
            <StyledPage bg={page3Bg}>
              <StyledSwiperTitle src={page3Title}></StyledSwiperTitle>
              <StyledBox3>
                <div className={'swiper'}>
                  <Carousel effect='fade' className={'list'} ref={swiperRef} autoplay={true}>
                    {sliderList.map((value, index) => {
                      return (
                        <div key={index}>
                          <div className={'item'} style={{ backgroundImage: `url("${value.bgImg}")` }}>
                            <h3>{value.name}</h3>
                            <p>{value.desc}</p>
                            <img src={value.img} alt='' className={'person'} />
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                  <div className={'other'}>
                    <div className={'btn'} id='semDownloadBtn' onClick={download} data-agl-cvt={6}>
                      立即前往
                    </div>
                    <div className={'more'}>
                      <strong>更多热门游戏加速</strong>
                      <span onClick={download} id='semDownloadBtn' data-agl-cvt={6}>
                        更多 &gt;
                      </span>
                    </div>
                    <img
                      className={'icons'}
                      src={gameIcon}
                      alt=''
                      onClick={download}
                      data-agl-cvt={6}
                      id='semDownloadBtn'
                    />
                  </div>
                  <div className={'swiper-btn swiper-btn-prev'} onClick={() => swiperRef.current?.prev()}>
                    <LeftOutlined />
                  </div>
                  <div className={'swiper-btn swiper-btn-next'} onClick={() => swiperRef.current?.next()}>
                    <RightOutlined />
                  </div>
                </div>
              </StyledBox3>
            </StyledPage>
          </Section>
          <Section className='page4'>
            <StyledPage bg={page4Bg}>
              <div id='page4-content'>
                <StyledSwiperTitle src={page4Title}></StyledSwiperTitle>
                <StyledProductDes>{desc}</StyledProductDes>
                <StyledIntroduceBox>
                  {product.map((item, index) => {
                    return (
                      <StyledIntroduceItem key={`productIntroduce-${index}`}>
                        <img src={item.img} alt='' />
                        <span>{item.des}</span>
                      </StyledIntroduceItem>
                    )
                  })}
                </StyledIntroduceBox>
              </div>
            </StyledPage>
          </Section>
        </SectionsContainer>
      )}
    </StyledHomeContainer>
  )
}
const StyledHide = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 0;
  height: 50px;
  overflow: hidden;
`
const StyledDownRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 760px;
  .platformImg {
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
  }
  .downBox {
    width: 172px;
    height: 52px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #ffffff;
    margin: 0 12px;
    cursor: pointer;
    position: relative;
    transform: all 0.5s;
    img {
      margin-right: 20px;
    }
    &:hover {
      border-color: #429dff;
    }
  }
`
const StyledPage1Text2 = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  top: 700px;
  .left {
    width: 93px;
    height: 2px;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    margin-right: 14px;
  }
  .right {
    width: 93px;
    height: 2px;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    margin-left: 14px;
  }
`
const StyledPage1Text = styled.div`
  font-size: 28px;
  color: #d5d5d5;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 430px;
`
const StyledLine = styled.div`
  height: 1px;
  width: 1200px;
  background: url(${line});
  background-size: cover;
  margin-top: 70px;
`
const StyledInformationTitle = styled.div`
  display: flex;
  width: 1022px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  .left {
    font-size: 22px;
    font-weight: 800;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
    }
  }
  .right {
    font-size: 14px;
    cursor: pointer;
    color: #014cd5;
  }
`
const StyledBox3 = styled.div<{ bg?: string }>`
  height: 600px;
  background: url('${props => props.bg}') center top/1920px no-repeat;
  padding-top: 140px;
  overflow: hidden;
  .swiper {
    margin: 0 auto;
    width: 1200px;
    height: 416px;
    position: relative;
    .ant-carousel .slick-list {
      overflow: visible;
    }
    &:hover {
      .swiper-btn {
        opacity: 1;
      }
    }
  }
  .item {
    width: 1200px;
    height: 416px;
    background-size: cover;
    position: relative;
    padding-left: 50px;
    padding-top: 40px;
    color: #ffffff;
    > h3 {
      color: #ffffff;
      font-size: 36px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 20px;
      width: 461px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > p {
      font-size: 18px;
      line-height: 1;
      margin-bottom: 24px;
      width: 461px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .person {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .other {
    position: absolute;
    left: 47px;
    top: 137px;
    width: 461px;
  }
  .btn {
    width: 119px;
    height: 42px;
    border: 1px solid #ffffff;
    text-align: center;
    line-height: 40px;
    font-size: 17px;
    margin-bottom: 38px;
    cursor: pointer;
    transition: all 0.3s;
    color: #fff;
    &:hover {
      transform: translateX(3px);
    }
  }
  .more {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 17px;
    color: #fff;
    > strong {
      font-weight: normal;
    }
    > span {
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #40a9ff;
      }
    }
  }
  .icons {
    cursor: pointer;
  }
  .swiper-btn {
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 70px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
    &.swiper-btn-prev {
      left: 0;
    }
    &.swiper-btn-next {
      right: 0;
    }
  }
  .ant-carousel .slick-dots {
    width: 610px;
    left: auto;
    margin-right: 0;
    li,
    li.slick-active {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      button {
        height: 8px;
        border-radius: 50%;
      }
    }
  }
`
const StyledPage = styled.div<{ bg: string }>`
  height: 100%;
  width: 100%;
  background: url(${prop => prop.bg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 1s ease;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.43, 0.05, 0.17, 1);
  }
  #page4-content {
    position: static;
    top: 0;
    transition: all 0.3s cubic-bezier(0.43, 0.05, 0.17, 1);
    z-index: 10;
    &.top {
      position: relative;
      top: calc(-650px);
      transition: all 0.3s cubic-bezier(0.43, 0.05, 0.17, 1);
    }
  }
`
const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #fff;
  font-size: 32px;
  letter-spacing: 3px;
  img {
    max-height: 100px;
  }
`
const StyledInformationListItem = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid transparent;
  position: relative;
  padding-left: 42px;
  .time,
  .title {
    font-size: 14px;
    color: #999999;
  }
  .title {
    /* width: 364px; */
    svg {
      color: rgba(255, 255, 255, 0.1);
      margin-right: 11px;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    border-width: 0px 6px 6px;
    border-style: none solid solid;
    border-color: transparent transparent rgba(255, 255, 255, 0.1);
    left: 20px;
    top: 15px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    border-width: 0px 6px 6px;
    border-style: none solid solid;
    border-color: transparent transparent rgba(255, 255, 255, 0.1);
    left: 20px;
    bottom: 15px;
    transform: rotate(180deg);
  }
  &:hover {
    border: 1px solid;
    border-image: linear-gradient(270deg, rgba(38, 161, 254, 0), rgba(0, 76, 213, 1)) 1 1;
    .time,
    .title {
      /* color: #fff; */
      svg {
        color: #fff;
      }
    }
    &::after,
    &::before {
      border-color: transparent transparent rgba(255, 255, 255, 1);
    }
  }
`
const StyledListBox = styled.div`
  width: 500px;
  position: relative;
  height: 160px;
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
const StyledInformationGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1022px;
  margin: 0 auto;
  margin-top: 24px;
`
const StyledIntroduceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  span {
    font-size: 20px;
    color: #ffffff;
    margin-top: 33px;
  }
`
const StyledIntroduceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
  margin: 0 auto;
  margin-top: 69px;
`

const StyledProductDes = styled.div`
  font-size: 16px;
  color: #fff;
  text-align: center;
  width: 1167px;
  margin: 0 auto;
  margin-top: 30px;
`
const StyledSwiperTitle = styled.img`
  display: block;
  margin: 0px auto;
`
const StyledDownload = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #f5f5f6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 444px;
  height: 120px;
  background: url(${downloadButton}) no-repeat center center / contain;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0) scale(1);
  top: 500px;
  transition: top 0.3s linear;
  cursor: pointer;
  img {
    margin-right: 15px;
  }
  .free-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 24px;
    margin: 0;
  }
  &:hover {
    top: 493px;
  }
`
const StyledDes = styled.img`
  position: absolute;
  left: 50%;
  top: 315px;
  transform: translate(-50%, 0);
`
const StyledBanner = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url(${homeBannerBg}) center top / auto 100% no-repeat;
  background-size: 100% auto;
  position: relative;
  perspective: 500px;
  transform-style: preserve-3d;
  overflow: hidden;
  padding-top: 80px;
  #home-banner-2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`
const StyledHomeContainer = styled.div`
  .Navigation {
    display: none;
  }
`

export default observer(Home)
