import React from 'react'
import { ConfirmButton } from 'src/components/base-button'
import { KEYCODE, ASSIST_KEYCODE } from 'src/constants/keycode'
import { emitter } from 'src/tools/emitter'
import { Modal, notification } from 'antd'
import { DownloadStore } from 'src/store/resource'
import { baseNotification } from 'src/components/base-notification'
import { imperativeConfirmModal, imperativeUpdateConfirmModalContent } from 'src/components/base-modal'
import { injectStatusChangeHttp } from 'src/apis/user-api'
import { SpeedStore } from 'src/store'
import { StartingStore, RunningStore } from 'src/store/modifier'
import { sleep } from 'src/tools/common'
import { $t } from 'src/locales'
import MessageSuccessIcon from 'src/assets/icons/notification/success.svg'
import appStore from 'src/store/app-store'
import CEFTransfer from 'src/CEFTransfer'

// baseNotification(
//   <div style={{ display: 'flex', alignItems: 'center' }}>
//     检测到 MyBuff 有更新
//     <ConfirmButton style={{ width: 100, height: 30, marginLeft: 20 }}>
//       重启客户端
//     </ConfirmButton>
//   </div>,
//   undefined,
//   <MessageSuccessIcon style={{ marginTop: 2 }} />,
//   {
//     styled: {
//       padding: '9px 20px',
//     },
//   },
// )
class WindowController {
  static single: (() => void) | null = null
  constructor() {}

  static init() {
    CEFTransfer.subscribeClientMsg('windowState', props => {
      appStore.windowStatus = props.status
    })
    CEFTransfer.subscribeClientMsg('updateAppRequest', props => {
      this.updaterNotify(props.forceUpdate)
    })
    CEFTransfer.subscribeClientMsg('cpp2js_file_download_progress', props => {
      this.listenFileDownloadProgress(props)
    })
    this.listenHotkeys()
  }

  static async listenFileDownloadProgress(params: {
    originalUrl: string
    downloadPath: string
    fullPath: string
    status: Http.TFileDownloadStatus
    progress: number
  }) {
    const { originalUrl, downloadPath, fullPath, status, progress } = params
    //下载路径中文处理
    const link = decodeURI(originalUrl)
    if (DownloadStore.fileDownloadProgress[link]) {
      DownloadStore.fileDownloadProgress[link] = {
        ...DownloadStore.fileDownloadProgress[link],
        url: link,
        filePath: fullPath,
        downloadPath,
        status,
        progress,
      }
    }
  }

  static async beforeAppClose(customKey: string, needOpen?: boolean) {
    const awaitTimeout = new Promise(async (res, rej) => {
      for (let i = 10; i > 0; i--) {
        const isOpen = needOpen && i === 10
        if (isOpen) {
          this.single?.()
          imperativeConfirmModal(
            {
              title: $t({id:'update.closing'},{i}),
              content: $t({id:'update.lose'}),
              btns: [],
              zIndex: 9999
            },
            customKey,
          )
        } else {
          imperativeUpdateConfirmModalContent(customKey, {
            title: $t({id:'update.closing'},{i}),
            content: $t({id:'update.lose'}),
            btns: [],
            zIndex: 9999
          })
        }
        await sleep(1000)
      }
      res(null)
    })
    const awaitBuffPromise = new Promise(async (res, rej) => {
      const startNo = StartingStore.currentStartNo || StartingStore.currentStartNo
      if (startNo) {
        injectStatusChangeHttp({
          startNo,
          injectStatusEnum: 'stop_buff',
          code: '19998',
          message: '客户端退出'
        })
        await CEFTransfer.sendToClient('stopBuff')
      }
      res(null)
    })
    
    await Promise.race([
      awaitTimeout,
      Promise.all([
        SpeedStore.stopSpeed({ notify: true, report: true, reason: 'EXIT_CLIENT' }),
        awaitBuffPromise,
        DownloadStore.currentDownloadTask()
          ? CEFTransfer.sendToClient('cancelDownload', { originUrl: DownloadStore.currentDownloadTask()?.key })
          : null,
      ]),
    ])
  }

  static async updaterNotify(forceUpdate: boolean) {
    const customKey = `updaterNotify-${Math.random()}`
    const handleRelaunch = async () => {
      CEFTransfer.sendToClient('closeSaveFileDialog')
      await WindowController.beforeAppClose(customKey, !forceUpdate)
      CEFTransfer.sendToClient('restartApp')
    }
    if (forceUpdate) {
      imperativeConfirmModal(
        {
          title: $t({id:'update.force'}),
          content: $t({id:'update.forceTips'}),
          onOk: () => handleRelaunch(),
          okText: $t({id:'update.restart'}),
          btns: ['ok'],
        },
        customKey,
      )
      return
    }

    this.single?.()
    const { cancelInstance } = await baseNotification(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {$t({id:'update.hasUpdate'})}
        <ConfirmButton style={{ width: 120, height: 30, marginLeft: 20 }} onClick={() => handleRelaunch()}>
          {$t({id:'update.restart'})}
        </ConfirmButton>
      </div>,
      undefined,
      <MessageSuccessIcon style={{ marginTop: 2 }} />,
      {
        styled: {
          padding: '9px 20px',
          width: 410
        },
      },
    )
    this.single = cancelInstance
  }

  static listenHotkeys() {
    let temp: Array<keyof typeof KEYCODE> = []
    const handleKeydown = (e: any) => {
      const keyname = e.params
      const keycode = Object.keys(KEYCODE).find(k => KEYCODE[k].text.includes(keyname))
      if (!keycode) {
        return
      }
      temp.push(keycode)
      if (!ASSIST_KEYCODE.includes(keycode)) {
        emitter.emit('hotkeysHit', temp)
      }
    }
    const handleKeyup = (e: any) => {
      const keyname = e.params
      const keycode = Object.keys(KEYCODE).find(k => KEYCODE[k].text.includes(keyname))
      if (!keycode) {
        return
      }
      temp = temp.filter(d => d !== keycode)
    }
    CEFTransfer.subscribeClientMsg('keydown', handleKeydown)
    CEFTransfer.subscribeClientMsg('keyup', handleKeyup)
  }

  // 监听用户触发的按键
  static handleEditHotkeys(): Promise<number[]> {
    return new Promise((res, rej) => {
      const handleHotkeysHit = (e: number[]) => {
        clearListener()
        res(e)
      }
      const handleStopEditHotKeys = () => {
        clearListener()
        rej('停止')
      }
      const clearListener = () => {
        emitter.off('stopEditHotKeys', handleStopEditHotKeys)
        emitter.off('hotkeysHit', handleHotkeysHit)
      }
      emitter.on('stopEditHotKeys', handleStopEditHotKeys)
      emitter.on('hotkeysHit', handleHotkeysHit)
    })
  }

  static handleStopEditHotkeys() {
    emitter.emit('stopEditHotKeys')
    return 'success'
  }
}

export default WindowController
