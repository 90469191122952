const subscriptions = Symbol("subscriptions");

import logger from "./logger";

type MergeMap<T extends {}, R> = NsWfSocket.EmitterMap<R> & T;

class Emitter<EventMap, R> {
  [subscriptions] = new Map<keyof MergeMap<EventMap, R> | string, Function[]>();
  debug: boolean = false;
  constructor(debug?: boolean) {
    this.debug = Boolean(debug);
  }
  subscribe<K extends keyof MergeMap<EventMap, R>>(
    type: K,
    callback: (arg: MergeMap<EventMap, R>[K]) => void
  ) {
    this.debug && logger(`subscribe => ${type}`);
    if (callback) {
      if (!(typeof callback === "function")) {
        throw new Error("subscribe callback type must be 'Function'");
      }
      this[subscriptions].set(type, [
        ...(this[subscriptions].get(type) || []),
        callback,
      ]);
    }
  }
  dispatch(type: keyof MergeMap<EventMap, R> | string, args?: any) {
    this.debug && logger(`dispatch => ${type}`);
    this.debug && args && console.info(args);
    this[subscriptions].get(type)?.forEach((fun) => {
      fun(args);
    });
  }
  remove(type: keyof MergeMap<EventMap, R> | string, cb?: Function) {
    this.debug && logger(`remove => ${type}`);
    if (!this[subscriptions].has(type)) {
      return;
    }
    if (cb) {
      this[subscriptions].set(
        type,
        (this[subscriptions].get(type) || []).filter((c) => c !== cb)
      );
    } else {
      this[subscriptions].delete(type);
    }
  }
}

export default Emitter;
