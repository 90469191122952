import { stringify } from 'query-string'
import { generatorUUID, getFirstPageChannel, getFirstPageMAC, showFeedback, sleep } from 'src/tools/common'
import { CLOUD_GAME_EXIT_TYPE, CLOUD_GAME_PROGRESS_STATUS } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import { confirmStartGame, confirmStartGameTrial, startGpGame, startGpGameTrial } from 'src/apis/cloud-game-api'
import { cloudgameStopHttp } from 'src/apis/user-api'
import { getAuth } from 'src/tools/auth'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { VipStore } from 'src/store'
import SocketStore from 'src/pages/game-detail/cloud-game/socket'
import clientPostMessage from 'src/pages/game-detail/cloud-game/client-post-message'
import CEFTransfer from 'src/CEFTransfer'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'

let timer: number | null = null

// 游戏运行心跳
export const gameIsRunningCheck = (gameCloudOrderNo: string, option?: { clearOnly?: boolean; isReload?: boolean }) => {
  timer && clearInterval(timer)
  timer = null
  if (!option?.clearOnly) {
    const callback = () => {
      SocketStore.send({
        packetNo: generatorUUID('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx').replace(/-/g, 'x'),
        messageGroup: 'GAME_CLOUD',
        systemId: 'sgt',
        messageCode: 'cloud_game_running',
        data: {gameCloudOrderNo},
      })
    }
    if (option?.isReload) {
      callback()
    }
    timer = window.setInterval(callback, 1000 * 55)
  }
}

export const startGameHttp = async ({gameLibraryBranchId, accountLoginMode, queueMainId = '',gameMode,gameId,launchParamDto}: {
  gameLibraryBranchId: number
  accountLoginMode?:string
  queueMainId: string
  gameMode: 'cloud' | 'local'
  gameId?:number
  launchParamDto?: TLaunchParamDto
}) => {
  //云游戏、试玩区分调用接口
  const isLocal=gameMode==='local'
  const confirmStartGameReq = isLocal ? confirmStartGameTrial : confirmStartGame
  const startGpGameReq = isLocal ? startGpGameTrial : startGpGame
  const channel = getFirstPageChannel()
  const device = isLocal ? 'sgt' : undefined
  if (queueMainId) {
    return (await confirmStartGameReq({
      channel,
      gameLibraryBranchId,
      gameId,
      mode: 1,
      deviceMode: gameMode,
      mac: getFirstPageMAC(),
      queueMainId: queueMainId,
      launchParamDto,
      terminal:'pc',
      accountLoginMode,
      device,
    })).result
  } else {
    return (await startGpGameReq({
      channel,
      gameLibraryBranchId,
      gameId,
      mode: 1,
      deviceMode: gameMode,
      mac: getFirstPageMAC(),
      launchParamDto,
      terminal:'pc',
      accountLoginMode,
      device,
    })).data
  }
}

export const finalCloudGameStart = async (
  gameStartStartInfo: GameStartInfo,
  accountLoginMode: string,
  // launchParamDto: TLaunchParamDto,
  queueMainId: string = '',
) => {
  const {gameLibraryBranchId} = gameStartStartInfo
  const startGameResult = await startGameHttp({
    gameLibraryBranchId,
    queueMainId,
    gameMode: 'cloud',
    // launchParamDto,
    accountLoginMode
  })

  //判断是否需要排队，排队会正常返回isNeedQueueUp，这里兼容管家写法
  if(startGameResult.isNeedQueueUp){
    return Promise.reject({
      status_code: 999999,
      gameNo:startGameResult.gameNo,
    })
  }

  CloudGamePlayStore.currentTheRunningGameInfo = {
    ...gameStartStartInfo,
    startGameOrderNo: startGameResult.order_no,
    gameMode:'cloud'
  }

  const {startCloudGame} = useCloudGameStart()

  await startCloudGame({
    gameCloudOrderNo: startGameResult.gameCloudOrderNo || '',
    gameJson: startGameResult.gameJson,
    // cloudArchive: gameStartPayload.archivePayload || undefined,
    isHang: startGameResult.isHang,
    accountLoginMode
  })
}


/**
 * 云游戏停止 通知客户端和服务端 并修改前端状态
 * @param needRequest 是否需要通知服务端停止计费
 */
export const stopCloudGame = async ({
                                      needRequest = true,
                                      reason = '',
                                      needArchive = true,
                                      needCloseCloudGameWindow = true,
                                      needFeedBack,
                                    }: {
  needRequest: boolean
  reason: string
  needArchive?: boolean
  needCloseCloudGameWindow?: boolean
  needFeedBack?: boolean
}) => {
  console.log('stopCloudGame',CloudGamePlayStore.gameRunStatus)
  if (!['starting', 'running'].includes(CloudGamePlayStore.gameRunStatus)) {
    return
  }
  CloudGameStartProgress.reset()
  const GameCloudNo = CloudGamePlayStore.gameCloudNo
  const supportArchive = CloudGamePlayStore.currentTheRunningGameInfo?.isSupportArchive === 1
  const shouldWaitArchive =
    GameCloudNo && CloudGamePlayStore.gameRunStatus === 'running' && needArchive && supportArchive
  if (!shouldWaitArchive) {
    CloudGamePlayStore.gameCloudNo = ''
  }
  gameIsRunningCheck('', {clearOnly: true})
  clearTimeOutCheck()
  if (needRequest && GameCloudNo) {
    console.log('调用停止计费接口')
    await cloudgameStopHttp({gameCloudOrderNo: GameCloudNo, reason})
  }
  VipStore.handleStopCloudGameCountdown()
  VipStore.handleLoadUserRemainingCloudGameBalance()

  if(needFeedBack){
    //反馈弹窗
    showFeedback('cloudGame', {
      gameName: CloudGamePlayStore.currentTheRunningGameInfo?.gameName,
      platform: CloudGamePlayStore.currentTheRunningGameInfo?.gamePlatformName,
      accountLoginMode: CloudGamePlayStore.currentTheRunningGameInfo?.accountLoginMode,
      isLoginGamePlatform: CloudGamePlayStore.platformLogin ? '1' : '0',
      isStartGame: CloudGamePlayStore.gamePull ? '1' : '0',
      accountUseTime: CloudGamePlayStore.beginTime ? parseInt(String((Date.now() - CloudGamePlayStore.beginTime) / 1000)) : 0,
      bizOrderNo: GameCloudNo
    }, true)
  }
  if (shouldWaitArchive) {
    console.log('需要等待存档上传, 隐藏云游戏窗口')
    CloudGamePlayStore.cloudPageStatus = false
  } else {
    console.log('不需要等待存档上传, 关闭云游戏窗口')
    // CloudGameClientStore.cloudGameIframe.iframeSrc = 'about:blank'
    needCloseCloudGameWindow && CEFTransfer.sendToClient('closeCloudGame')
  }
  if (GameCloudNo) {
    CloudGamePlayStore.gameRunStatus = 'waitClose'
    /** 等待存档保存 */
    shouldWaitArchive && (await awaitSoure.finish(30000, false))
    shouldWaitArchive && console.log('存档等待结束, 关闭云游戏窗口')
    // CloudGameClientStore.cloudGameIframe.iframeSrc = 'about:blank'
    needCloseCloudGameWindow && CEFTransfer.sendToClient('closeCloudGame')

    /****************
     * @description 业务调整，前端在不需要存档时不做等待动作
     */
    /**  等待云游戏设备释放 */
    // await awaitSoure.finish(2000, true)
    /**************** */
  }
  // MobxStore.currentStep = 'gameover'
  // SocketStore.close()
  CloudGamePlayStore.gameRunStatus = 'stop'
}

/**
 * 启动云游戏
 * @param gameCloudOrderNo 云游戏启动单号
 * @param query 云游戏窗口url
 */
export const runCloudGame = async (gameCloudOrderNo: string, query: string) => {
  /** 清除之前的上号timeout计时器 */
  clearTimeOutCheck()
  console.debug(
    '云游戏启动进度-开始启动(参数拼接完成转为挂盘中状态)',
    CLOUD_GAME_PROGRESS_STATUS.GAME_INSTALLING,
    new Date().toLocaleString(),
  )
  SocketStore.send({
    packetNo: generatorUUID('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx').replace(/-/g, 'x'),
    messageGroup: 'GAME_CLOUD',
    systemId: 'sgt',
    messageCode: 'cloud_game_version',
    data: { gameCloudOrderNo, supportDelayArchival: true, details: null },
  })
  CloudGamePlayStore.gameCloudNo = gameCloudOrderNo
  CloudGameStartProgress.setStep(CLOUD_GAME_PROGRESS_STATUS.GAME_INSTALLING)

  let openResultStr = ''
  //监听云游戏页面是否打开成功定时器，60s超时
  console.log('启动云游戏页面打开超时定时器')
  CloudGamePlayStore.cloudGamePageLoadTimer && clearTimeout(CloudGamePlayStore.cloudGamePageLoadTimer)
  CloudGamePlayStore.cloudGamePageLoadTimer = window.setTimeout(() => {
    console.log('云游戏页面打开超时，结束游戏', openResultStr)
    stopCloudGame({
      needRequest: true,
      reason: CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_PAGE_LOAD_TIMEOUT.reason + `-` + openResultStr,
      needArchive: false,
    })
    CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.CLOUD_GAME_PAGE_LOAD_TIMEOUT
    CloudGamePlayStore.cloudGamePageLoadTimer = null
  }, 60 * 1000)

  const openResult = await Promise.race([
    CEFTransfer.sendToClient('openCloudGameByChrome',
      {cloudUrl: `${location.origin}/cloudgame-page?${query}&linkUid=${encodeURIComponent(CloudGamePlayStore.linkUid)}`}),
    sleep(3000),
  ])
  openResultStr = openResult?.status || openResult
  console.log('openCloudGameByChrome result',openResult)
  if (openResult?.status === 0) {
    //处理未安装chrome浏览器情况
    imperativeConfirmModal({
      title: '缺少合适浏览器支持',
      content: '为了保证您在云游戏过程中拥有一个较好的体验，请您先安装最新版本的 Chrome 浏览器后再进行游戏启动（注：若浏览器已安装，仍无法启动云游戏，请先手动打开浏览器后再进行游戏启动）',
      okText: '下载Chrome',
      cancelText:'我知道了',
      onOk(){
        cefOpenUrl('https://www.google.cn/chrome/')
      }
    })
    stopCloudGame({
      needRequest: true,
      reason: CLOUD_GAME_EXIT_TYPE.NO_CHROME.reason,
      needArchive: false,
    })
    CloudGamePlayStore.cloudGamePageLoadTimer && clearTimeout(CloudGamePlayStore.cloudGamePageLoadTimer)
    CloudGamePlayStore.cloudGamePageLoadTimer = null
    return
  }
  /** 启动超时的定时器 */
  checkCloudTimer = window.setTimeout(() => {
    stopCloudGame({
      needRequest: true,
      reason: CLOUD_GAME_EXIT_TYPE.CLIENT_START_TIME_OUT.reason,
      needArchive: false,
    })
    CloudGamePlayStore.cloudGameStartCrash = CLOUD_GAME_EXIT_TYPE.CLIENT_START_TIME_OUT
    checkCloudTimer = null
  }, 60 * 1000 * 5)
}

const useCloudGameStart = () => {
  const startCloudGame = async ({
    gameCloudOrderNo,
    gameJson,
    // cloudArchive,
    isHang,
    accountLoginMode
  }: {
    gameCloudOrderNo: string
    startGameNo?: string
    gameId?: number
    gameJson: string
    queue?: boolean
    // cloudArchive?: Http.TFinalArchive
    isHang: boolean
    accountLoginMode: string
  }) => {
    const Authorization = getAuth()
    if (!Authorization) {
      return
    }
    CloudGameStartProgress.reset()
    CloudGamePlayStore.cloudPageStatus = false
    console.debug(`当前游戏启动 ${!isHang ? '不' : ''} 需要挂盘`)
    console.debug(
      '云游戏启动进度-开始启动(拼接启动参数)',
      CLOUD_GAME_PROGRESS_STATUS.LOCK_DEVICE,
      new Date().toLocaleString(),
    )
    CloudGameStartProgress.setStep(CLOUD_GAME_PROGRESS_STATUS.LOCK_DEVICE)
    const json = gameJson ? JSON.parse(gameJson) : {}
    // const userId = MobxStore.userInfo!.id
    const userId = 0
    // if (cloudArchive) {
    //   json.cloudArchiveOrder = cloudArchive.orderNo
    //   json.cloudOssPath = cloudArchive.key.split('#')[0]
    // }

    json.gameLibraryBranchId = CloudGamePlayStore.currentTheRunningGameInfo?.gameLibraryBranchId
    json.cloudModify = CloudGamePlayStore.currentTheRunningGameInfo?.isSupportTrainer
    json.cloudSpeed = CloudGamePlayStore.currentTheRunningGameInfo?.isSupportSpeed
    json.disableWs = true
    json.prod_type = 'sgt'
    json.accountLoginMode = accountLoginMode
    json.userId = userId
    json.parent = encodeURIComponent(window.origin)
    json.isHang = +isHang
    json.systemId = 'sgt'
    /** 控制common cloud game的ui样式和接口地址 */
    json.productKey = 'hs'

    const envMapping = {
      development: 'test',
      test: 'test',
      preview: 'prd',
      production: 'prod',
    } as const

    json.env = envMapping[process.env.MODE]
    /** 缓存appId, 邀请玩家时需要使用 */
    if (json.appId) {
      localStorage.setItem('t_cloud_sdk_app_id', json.appId)
    }

    try {
      /** @TODO 暂时不需要 */
        // const userInfo = await getUserInfo(Authorization)
        // if (userInfo.hasInvitationRight) {
        //   json.userType = 'host'
        // }
      const url = `Authorization=${Authorization}&${stringify(json)}`
      console.log(url, 'url')
      await runCloudGame(gameCloudOrderNo, url)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    startCloudGame,
    stopCloudGame,
  }
}

export default useCloudGameStart

/** 等待云存档保存弹窗通知 */
export const awaitSoure: {
  done: Function | null
  finish: (timeout?: number, onlyTimeout?: boolean) => Promise<any>
} = {
  done: null,
  finish(timeout = 30000, onlyTimeout = false) {
    const start = Date.now()
    const promises = [
      new Promise(resolve => {
        setTimeout(resolve, timeout)
      }),
    ]
    if (!onlyTimeout) {
      promises.unshift(
        new Promise(resolve => {
          this.done = (v: unknown) => {
            resolve(v)
            this.done = null
          }
        }),
      )
    }
    return Promise.race(promises).then(v => {
      console.log('finished', Date.now() - start)
      return v
    })
  },
}

interface ProgressOption {
  interval?: number
  onchange: (step: number) => void
}

const loop = () => {
}

export class CLOUD_GAME_PROGRESS {
  state: 'on' | 'off' | 'done' = 'off'
  timer: number | null = null
  interval: number = 1000
  targetStep: number = 0
  step: number = 0
  onchange: (step: number) => void = loop

  constructor({interval = 1000, onchange = loop}: ProgressOption) {
    this.interval = interval
    this.onchange = onchange
  }

  setStep(n: number) {
    if (this.state === 'done') {
      return
    }
    if (n > this.targetStep) {
      this.targetStep = n
      if (this.state === 'off') {
        this.updateProgress()
      }
    }
  }

  updateProgress() {
    this.state = 'on'
    if (this.step >= this.targetStep) {
      this.state = 'off'
      return
    }
    this.timer = window.setTimeout(() => {
      this.step++
      this.onchange(this.step)
      this.updateProgress()
    }, this.interval)
  }

  finish() {
    this.state = 'done'
  }

  reset() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.step = 0
    this.targetStep = 0
    this.onchange(this.step)
  }
}

export const CloudGameStartProgress = new CLOUD_GAME_PROGRESS({
  onchange(step) {
    CloudGamePlayStore.cloudGameStartProcess = step
    clientPostMessage.post({
      code: 'start-progress',
      process: {
        step,
        stepText: CLOUD_GAME_PROGRESS_TEXT[step],
      },
    })
  },
})

export enum CLOUD_GAME_PROGRESS_TEXT {
  '链接云服务器中' = 1,
  '云端游戏安装中' = 2,
  '链接云电脑服务' = 3,
  '启动游戏客户端' = 4,
  '启动成功' = 5,
}

let checkCloudTimer: number | null = null

export const clearTimeOutCheck = () => {
  if (checkCloudTimer) {
    SocketStore.remove('open')
    window.clearTimeout(checkCloudTimer)
    checkCloudTimer = null
  }
}


/** 查询并同步其它端游戏状态 */
export const syncUserGameStatus = async () => {
  //todo:查询并同步其它端游戏状态
  console.log('syncUserGameStatus')

  // const { playing, queueing } = await DWgetUserGameStatus.Execute()
  // const { writeQueueInfo, quitQueue } = useQueue()
  // const { stopCloudGame } = useCloudGameStart()
  // /** 排队中 */
  // if (queueing && queueing.statusResultObject && QueueStore.queueStatus !== 'in') {
  //   writeQueueInfo(queueing)
  // } else if (!queueing) {
  //   quitQueue()
  // }
  // /** 游戏中 移动端只有云游戏模式 */
  // if (
  //   playing &&
  //   playing.terminal === 'h5' &&
  //   !['starting', 'running', 'tryRunning'].includes(GameLibraryStore.gameRunStatus)
  // ) {
  //   CloudGameClientStore.gameCloudNo = playing.gameCloudOrderNo
  //   GameLibraryStore.gameRunStatus = 'running'
  //   GameLibraryStore.currentTheRunningGameInfo = {
  //     gameId: playing.goodsId,
  //     gameLibraryId: playing.gameLibraryId,
  //     startGameOrderNo: playing.startGameNo || '',
  //     gameName: playing.gameName,
  //     gameMode: 'cloud',
  //     img: '',
  //     platform: '1',
  //     allowExit: 'allowExit',
  //     userGameId: NaN,
  //     appid: '',
  //     orderNo: '',
  //     isSupportArchive: null,
  //     terminal: playing.terminal,
  //   }
  // } else if (!playing && !CloudGameClientStore.visibleExitConfirmModal) {
  //   if (GameLibraryStore.currentTheRunningGameInfo?.gameMode === 'cloud') {
  //     stopCloudGame({ needRequest: false, needArchive: false, reason: '其它端游戏状态变为空闲' })
  //   } else {
  //     GameLibraryStore.gameRunStatus !== 'starting' && HideCheckModal()
  //   }
  // }
}