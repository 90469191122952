import React, { FC } from 'react'
import { Modal, ModalProps } from 'antd'
import { ConfirmButton, CancelButton } from 'src/components/base-button'
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

type TModalType = 'success' | 'warn' | 'info' | 'error'
type TButtonType = 'cancel' | 'confirm'

interface TModalProps extends ModalProps {
  icon?: React.ReactNode
  type?: TModalType
  buttons?: TButtonType[]
}

const ICON_MAP: Record<TModalType, React.ReactNode> = {
  success: <CheckCircleOutlined />,
  warn: <ExclamationCircleOutlined />,
  info: <InfoCircleOutlined />,
  error: <CloseCircleOutlined />,
}
const BaseModal: FC<TModalProps> = ({ children, icon, type, buttons = ['cancel', 'confirm'], ...props }) => {
  const TypeIcon = icon || (type ? ICON_MAP[type] : null)
  const TitleNode = props.title && (
    <div>
      {TypeIcon && <span className={`${type || ''} title-icon`}>{TypeIcon}</span>}
      {props.title}
    </div>
  )
  const DefaultFooter = (
    <StyledModalFooter>
      {buttons.includes('cancel') && (
        <CancelButton {...props.cancelButtonProps} onClick={props.onCancel}>
          {props.cancelText || '取消'}
        </CancelButton>
      )}
      {buttons.includes('confirm') && (
        <ConfirmButton {...props.okButtonProps} onClick={props.onOk}>
          {props.okText || '确定'}
        </ConfirmButton>
      )}
    </StyledModalFooter>
  )
  return (
    <StyledBaseModal centered maskClosable={false} footer={DefaultFooter} {...props} title={TitleNode}>
      {children}
    </StyledBaseModal>
  )
}

export default BaseModal

const StyledBaseModal = styled(Modal)<ModalProps>`
  text-indent: initial;
  .ant-modal-content {
    max-height: 500px;
    overflow-y: auto;
    border-radius: 4px;
    background: #2c2e34;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.8);
    .ant-modal-header {
      background: #2c2e34;
      border: none;
      height: 40px;
      padding: 9px 57px 9px 30px;
      border-radius: 4px 4px 0 0;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: #3d404c;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
      }
      .ant-modal-title {
        font-size: 14px;
        font-weight: bold;
        color: #b2b2b2;
        .title-icon {
          margin-right: 10px;
          &.success {
            color: #00f9a6;
          }
          &.warn {
            color: #ff8800;
          }
          &.info {
            color: #00f9a6;
          }
          &.error {
            color: #ff4d4f;
          }
        }
      }
    }
    .ant-modal-close {
      color: #727272;
      right: 17px;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
      &:hover {
        color: #c1c1c1;
      }
    }
    .ant-modal-footer {
      border-top: none;
      padding: 0;
      .ant-btn + .ant-btn {
        margin-left: 10px;
      }
    }
  }
  .ant-modal-body {
    padding: 40px 40px 20px;
    line-height: 24px;
    font-size: 13px;
  }
`

export const StyledModalFooter = styled.div`
  text-align: center;
  padding: 10px 20px 40px;
`
