import React, { useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { FadeinImage } from 'src/components/base-image'
import { useClickAway, useVirtualList } from 'ahooks'
import { GameStore } from 'src/store'
import { ToolsTypes, ToolsTypesName } from 'src/constants/common'
import { useNavigate } from 'react-router-dom'
import { cmsModDataByModId } from 'src/apis/cms-api'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import classNames from 'classnames'
import styled from 'styled-components'
import SearchIcon from 'src/assets/images/index-new/search.svg'
import CheckedIcon from 'src/assets/images/index-new/checked.svg'
import CloseIcon from 'src/assets/images/index-new/close.svg'
import homeBg from 'src/assets/images/home-new/homebg.png'

const Search = () => {
  const [keyword, setKeyword] = useState('')
  const [focus, setFocus] = useState(false)
  const [hotConfig, setHotConfig] = useState<any[]>([])
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef(null)
  const wrapperRef = useRef(null)
  const gameList = [...GameStore.installedGameList, ...GameStore.notInstalledGameList]
  const platformList = [...GameStore.installedPlatformList, ...GameStore.notInstalledPlatformList]

  const navigate = useNavigate()

  useClickAway(() => {
    setFocus(false)
  }, searchBoxRef);

  // const onHistoryClick=(text:string)=>{
  //   setKeyword(text)
  //   setTimeout(()=>setFocus(true),0)
  // }

  const gameSearch = (text: string) => {
    const key=text.toLowerCase()
    return gameList.filter((v)=>{
      return v.gameLibraryMasterCnName.toLowerCase().includes(key) ||
        v.gameLibraryMasterEnName.toLowerCase().includes(key) ||
        v.gameLibraryMasterAlias?.toLowerCase?.()?.includes?.(key)
    })
  }

  const platformSearch = (text: string) => {
    const key=text.toLowerCase()
    return platformList.filter((v)=>{
      return v.platformName.toLowerCase().includes(key)
    })
  }

  const sortListByKey = (list: (Game.GameLibraryMaster|Game.GamePlatform)[], key: string): (Game.GameLibraryMaster|Game.GamePlatform)[] => {
    const keywords = key?.trim().toLocaleLowerCase()
    return list.sort((a, b) => computedKeySortWeight(a, keywords) - computedKeySortWeight(b, keywords))
  }

  const isGameCheck=(data: Game.GameLibraryMaster|Game.GamePlatform): data is Game.GameLibraryMaster =>{
    return !!(data as Game.GameLibraryMaster).gameLibraryMasterCnName
  }

  const computedKeySortWeight = (d: Game.GameLibraryMaster|Game.GamePlatform, key: string) => {
    /**
     * 0 游戏母版中文名称精确匹配
     * 1 游戏平台中文名称精确匹配
     * 2 游戏母版英文名称精确匹配
     * 3 游戏平台英文名称精确匹配
     * 4 游戏母版别名精确匹配
     * 5 游戏母版中文模糊匹配
     * 6 游戏平台中文模糊匹配
     * 7 游戏母版英文名称模糊匹配
     * 8 游戏平台英文名称模糊匹配
     * 9 游戏母版别名模糊匹配
     */

    const isGame =isGameCheck(d)

    if (isGame && d.gameLibraryMasterCnName.toLocaleLowerCase()=== key) {
      return 0
    }
    if (!isGame && d.platformName.toLocaleLowerCase()=== key) {
      return 1
    }
    if (isGame && d.gameLibraryMasterEnName.toLocaleLowerCase() === key) {
      return 2
    }
    // if (!isGame && d.platformEnName.toLocaleLowerCase()=== key) {
    //   return 3
    // }
    if (isGame && d.gameLibraryMasterAlias) {
      for (let z of d.gameLibraryMasterAlias.split(',')) {
        if (z === key) {
          return 4
        }
      }
    }
    if (isGame && d.gameLibraryMasterCnName.toLocaleLowerCase().includes(key)) {
      return 5
    }
    if (!isGame && d.platformName.toLocaleLowerCase().includes(key)) {
      return 6
    }
    if (isGame && d.gameLibraryMasterEnName.toLocaleLowerCase().includes(key)) {
      return 7
    }
    // if (!isGame && d.platformEnName.toLocaleLowerCase().includes(key)) {
    //   return 8
    // }
    if (isGame && d.gameLibraryMasterAlias) {
      for (let z of d.gameLibraryMasterAlias.split(',')) {
        if (z.includes(key)) {
          return 9
        }
      }
    }
    return 10
  }

  const hostList=useMemo(()=>{
    return hotConfig.map(value => {
      const isPlatform =value.type==='1'
      let data:Game.GamePlatform | Game.GameLibraryMaster | undefined
      if(isPlatform){
        data= platformList.find(v => String(v.platformId) === value.id)
      }else {
        let gameLibraryBranch: Game.GameLibraryBranche | undefined
        const game = gameList.find(v => {
          return gameLibraryBranch = v.gameLibraryBranches.find(v => {
            return String(v.gameLibraryBranchId) === value.id
          })
        })
        if(game){
          data = {
            ...game,
            gameLibraryBranches: gameLibraryBranch ? [gameLibraryBranch] : []
          } as  Game.GameLibraryMaster
        }
      }
      return {
        ...value,
        data
      } as { type: string; data: Game.GamePlatform | Game.GameLibraryMaster }
    }).filter(v=>v.data)
  },[hotConfig, gameList, platformList])

  const searchResult = useMemo(()=>{
    const text=keyword.trim()

    const gameList = gameSearch(text)
    const platformList = platformSearch(text)
    return sortListByKey([...gameList,...platformList],text)
  },[keyword])

  const [list,scrollTo] =useVirtualList<Game.GameLibraryMaster|Game.GamePlatform>(searchResult,{
    containerTarget: containerRef,
    wrapperTarget: wrapperRef,
    itemHeight: 80,
    overscan: 5,
  })

  //游戏支持的功能
  const getSupportedTags=(value:Game.GameLibraryMaster)=>{
    const supportSet=new Set([
      ToolsTypes.TRIAL_GAME,
      ToolsTypes.SPEED_GAME,
      ToolsTypes.TRAINER_GAME,
      ToolsTypes.CLOUD_GAME,
      ToolsTypes.GAME_RESOURCE
    ])
    const supportedTags=[]
    let supportResources = false
    for (let i = 0; i < value.gameLibraryBranches.length; i++) {
      const data=value.gameLibraryBranches[i]
      const relates = data.relates
      if (!supportResources) {
        supportResources = !!data.resources?.length
      }
      if (supportSet.size === 0) {
        break
      }
      for (let k of supportSet) {
        for (let j = 0; j < relates.length; j++) {
          const v = relates[j]
          if (k === v.targetRelateType) {
            supportedTags.push(k)
            //找到一个支持的项目，就从supportSet删除此项目，supportSet为空时，不用再遍历
            supportSet.delete(k)
          }
        }
      }
    }
    supportResources && supportedTags.push(ToolsTypes.GAME_RESOURCE)
    return supportedTags
  }

  useEffect(() => {
    cmsModDataByModId({ modId: 'sgt_hot_search' }).then(({result}) => {
      setHotConfig(result?.datas?.map?.(value => value?.properties)?.slice?.(0,6)??[])
    })
  }, [])

  useEffect(() => {
    scrollTo(0)
  }, [searchResult])

  return <StyledSearch>
    <div className="title">
      <h2>ALL-in-one的游戏工具&周边服务平台</h2>
      <p>ALL-in-one game tools & peripheral service platform</p>
    </div>
    <StyledSearchBox ref={searchBoxRef}>
      <StyledSearchInput className={classNames({
        focus,
      })}>
        <input
          placeholder="请填写游戏或游戏平台名称"
          value={keyword}
          onChange={e=>setKeyword(e.target.value)}
          onFocus={()=>setFocus(true)}
          // onBlur={()=>{
          //   setTimeout(()=>setFocus(false),100)
          // }}
        />
        {
          keyword ? <div className="clear">
            <div className='icon' onClick={()=>setKeyword('')}>
              <CloseIcon/>
            </div>
          </div>:
            <div className="btn" onClick={()=>navigate('/main/game-search')}>
              <SearchIcon/>
            </div>
        }
      </StyledSearchInput>
      {focus && <StyledSearchResult>
        {
          keyword.trim() ?
            <>
              {!!searchResult?.length && <div className="result" key="result" ref={containerRef} style={{height: Math.min(5, searchResult.length) * 80}}>
                <div ref={wrapperRef}>
                  {
                    list?.map((v) => {
                      const value1 = v.data
                      const isPlatform = !isGameCheck(value1)
                      const nameText = isPlatform ? value1.platformName : value1.gameLibraryMasterCnName
                      const img = isPlatform ? value1.platformHorizontalImage : value1.gameLibraryMasterListImage
                      const supportedTags = isPlatform ?
                        value1.relates.map(v => v.targetRelateType) :
                        getSupportedTags(value1)
                      const key = isPlatform ? `platform-${value1.platformId}` : `game-${value1.gameLibraryMasterId}`
                      const onItemClick = () => {
                        navigate(isPlatform ?
                          `/game-detail?type=platform&platformId=${value1.platformId}` :
                          `/game-detail?type=game&gameLibraryMasterId=${value1.gameLibraryMasterId}`)
                      }
                      return <div
                        className="item"
                        key={key}
                        data-key={key}
                        onClick={onItemClick}
                      >
                        <StyledItem>
                          <FadeinImage
                            className='item-img'
                            width={'106px'}
                            height={'48px'}
                            src={img}/>
                          <div className="meta">
                            <h4>{nameText}</h4>
                            <div className="tags">
                              {
                                supportedTags.map((value1, index1) => {
                                  return <div className={`tag tag-${value1}`} key={value1}>
                                    <CheckedIcon/>{ToolsTypesName[value1]}
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        </StyledItem>
                      </div>
                    })
                  }
                </div>
              </div>}
              <div className={classNames('more',{
                empty: !searchResult?.length
              })}
              onClick={()=>cefOpenUrl('https://txc.qq.com/products/543586')}
              >没有找到游戏/反馈希望平台提供工具</div>
            </>:
            <>
              {/*<div className="history">*/}
              {/*  <div className="history-title">历史搜索</div>*/}
              {/*  <ul>*/}
              {/*    {*/}
              {/*      Array(5).fill('').map((v, i) => {*/}
              {/*        return <li key={i} onClick={()=>onHistoryClick('绝地求生')}>绝地求生</li>*/}
              {/*      })*/}
              {/*    }*/}
              {/*  </ul>*/}
              {/*</div>*/}
              {!!hostList?.length && <div className="hot" key='hot'>
                <div className="hot-title">热门搜索</div>
                <ul>
                  {
                    hostList.map((value, index) => {
                      const data = value.data
                      const isPlatform = !isGameCheck(data)
                      const nameText = isPlatform ? data.platformName : data.gameLibraryMasterCnName
                      const img = isPlatform ? data.platformHorizontalImage : data.gameLibraryMasterListImage
                      const supportedTags = isPlatform ?
                        data.relates.map(v => v.targetRelateType) :
                        getSupportedTags(data)
                      const iconImg=isPlatform ? data.platformIcon:data.gameLibraryBranches[0].platformIcon
                      const onItemClick=()=>{
                        navigate(isPlatform ?
                          `/game-detail?type=platform&platformId=${data.platformId}`:
                          `/game-detail?type=game&branchId=${data.gameLibraryBranches[0].gameLibraryBranchId}`)
                      }
                      return <li key={index} onClick={onItemClick}>
                        <div className="num">{index + 1}</div>
                        <div className="normal">{iconImg && <span><FadeinImage width={16} height={16} src={iconImg}/></span>}{nameText}</div>
                        <div className="hover">
                          <StyledItem>
                            <FadeinImage width={106} height={48} src={img} className='item-img'/>
                            <div className="meta">
                              <h4>{iconImg && <span><FadeinImage width={16} height={16} src={iconImg}/></span>} {nameText}</h4>
                              <div className="tags">
                                {
                                  supportedTags?.map((value1, index1) => {
                                    return <div className={`tag tag-${value1}`} key={index1}>
                                      <CheckedIcon/>{ToolsTypesName[value1]}
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </StyledItem>
                        </div>
                      </li>
                    })
                  }
                </ul>
              </div>}
            </>
        }
      </StyledSearchResult>}
    </StyledSearchBox>
  </StyledSearch>
}




const StyledItem=styled.div`
  display: flex;
  align-items: center;
  .item-img{
    .true-img{
      object-fit: cover;
      border-radius: 6px;
    }
  }  
  .meta{
    margin-left: 8px;
    >h4{
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 14px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      >span{
        margin-right: 4px;
        img{
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
    
    .tags{
      display: flex;
      color: #fff;
      font-size: 12px;
      .tag{
        display: flex;
        align-items: center;
        height: 21px;
        background: rgba(255,255,255,0.1);
        border-radius: 21px;
        padding: 0 8px;
        margin-right: 8px;
        svg{
          margin-right: 4px;
        }
        &.tag-CLOUD_GAME{
          svg{
            color: #FF6BEE;
          }
        }
        &.tag-TRIAL_GAME{
          svg{
            color: #A6FA24;
          }
        }
        &.tag-GAME_RESOURCE{
          svg{
            color: #FFC929;
          }
        }
        &.tag-SPEED_GAME{
          svg{
            color: #58BDFF;
          }
        }
        &.tag-TRAINER_GAME{
          svg{
            color: #917EFE;
          }
        }
        &.tag-PLATFORM_WELFARE{
          svg{
            color: #58BDFF;
          }
        }
      }     
    }
  } 
`

const StyledSearchResult=styled.div`
  width: 100%;
  //height: 405px;
  max-height: calc(100vh - 300px);
  overflow: auto;
  background: rgba(0,0,0,0.5);
  border-radius: 8px 8px 8px 8px;  
  position: absolute;
  left: 0;
  top: 63px;
  backdrop-filter: blur(60px);
  border: 1px solid rgba(117,117,117,0.4);


  .history{
    margin: 0 15px;
    border-bottom: 1px solid rgba(255,255,255,0.12);
    padding: 15px 0 12px;
    &-title{
      color: rgba(255,255,255,0.6);
      line-height: 12px;
      font-size: 12px;
      margin-bottom: 20px;
    }
    >ul{
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      >li{
        margin-right: 15px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  
  .hot{
    padding-top: 20px;
    &-title{
      font-weight: 500;
      color: #FFFFFF;
      line-height: 12px;
      padding-left: 15px;
    }

    > ul {
      margin-top: 12px;
      margin-bottom: 0;
      > li {
        display: flex;
        align-items: center;
        padding: 12px 0;
        cursor: pointer;
        transition: background .3s;
        .num{
          margin-left: 15px;
          margin-right: 12px;          
          width: 17px;
          height: 14px;
          line-height: 14px;
          font-size: 16px;
          font-weight: bold;
          color: #c5c5c5;
          position: relative;
          display: flex;
          align-items: flex-end;
          &:before{
            content: '';
            display: block;
            width: 12px;
            height: 16px;
            background: rgba(197,197,197,0.2);
            position: absolute;
            left: 1px;
            bottom: 0;
            transform:skew(-15deg);
            transform-origin: left bottom;
          }
        }
        .normal{
          font-size: 14px;
          font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
          font-weight: 400;
          color: rgba(255,255,255,0.8);
          line-height: 16px;
          display: flex;
          align-items: center;
          >span{
            margin-right: 4px;
            line-height: 0;
            img{
              width: 16px !important;
              height: 16px !important;
            }
          }
        }
        .hover{
          display: none;
        }
        &:hover{
          background: rgba(255,255,255,0.06);
          padding: 16px 0;
          .normal{
            display: none;
          }
          .hover{
            display: block;
          }
        }
        
        &:nth-child(1){
          .num{
            color: #FF0081;
            &:before{
              background: rgba(255,0,129,0.2);
            }
          }
        }
        &:nth-child(2){
          .num{
            color: #FF3D2F;
            &:before{
              background: rgba(255,61,47,0.2);
            }
          }
        }
        &:nth-child(3){
          .num{
            color: #FFD74D;
            &:before{
              background: rgba(255,215,77,0.2);
            }
          }
        }
      }
    }
  }

  .result {
    max-height: 400px;
    overflow: auto;
    .item {
      cursor: pointer;
      padding: 16px;
      transition: all .3s;

      &:hover {
        background: rgba(255, 255, 255, 0.06);
      }
    }
  }
  
  .more{
    font-size: 12px;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    border-top: 1px solid rgba(255,255,255,0.12);
    height: 43px;
    display: flex;
    align-items: center;
    margin: 0 15px;
    cursor: pointer;
    transition: color .3s;
    &.empty{
      height: 80px;
      border-top: none;
    }
    &:hover{
      color: var(--main-color);
    }
  }
`


const StyledSearchInput=styled.div`
  background: rgba(255, 255, 255);
  border-radius: 3px;
  border: none;
  display: flex;
  align-items: center;
  transition: all .3s;
  padding: 1px;

  > input {
    flex: 1;
    padding: 0 18px;
    font-size: 16px;
    color: #000;
    background: none;
    outline: none;
    border: none;

    &::placeholder {
      color: #313338;
    }
  }

  .btn {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
    padding: 0 10px;

    svg {
      color: rgba(78, 80, 88, 0.6);
    }
  }

  .clear {
    width: 84px;
    height: 42px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 11px;

    .icon {
      width: 24px;
      height: 24px;
      background: rgba(255, 255, 255, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

`


const StyledSearchBox=styled.div`
  margin: 18px auto 0;
  width: 650px;
  height: 43px;
  position: relative;
  z-index: 10;
`

const StyledSearch=styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url("${homeBg}") center bottom no-repeat;
  background-size: cover;
  aspect-ratio: 1544/354;
  border-radius: 8px;
  .title {   
    >h2{
      font-size: 22px;
      color: #fff;
      line-height: 33px;
    }
    > p {
      margin-top: 9px;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 22px;
      margin-bottom: 0;
    }
  }
  
`



export default observer(Search)