import React, { useEffect, useState, FC, ReactNode, CSSProperties, useRef } from 'react'
import { observer } from 'mobx-react'
import { Row } from 'antd'
import { TiChevronLeftOutline, TiChevronRightOutline } from 'react-icons/ti'
import { loadCarouselGameConfig, loadPromotion1GameConfig, cryptoByAES } from 'src/tools/common'
import { SpecialButton } from 'src/components/base-button'
import { Helmet } from 'react-helmet'
import { getResourceSuggestHttp } from 'src/apis/game-api'
import { emitter } from 'src/tools/emitter'
import styled from 'styled-components'
import classNames from 'classnames'
import PlatformAnimation from 'src/pages/website/components/platform-animation'
import EditCheat from 'src/pages/website/components/editCheat'

import bg from 'src/assets/images/website-promotion1/bg.png'
import HeaderLogoIcon from 'src/assets/images/website-promotion1/logo.svg'
import favicon from 'src/assets/images/website-promotion1/favicon.png'
import HeaderIcon from 'src/assets/images/website-promotion1/header-icon.svg'
import WindowIcon from 'src/assets/images/website-promotion1/window.svg'
import page1Img from 'src/assets/images/website-promotion1/page-1-img.png'
import ToolIcon1 from 'src/assets/images/website-promotion1/tool-1.svg'
import ToolIcon2 from 'src/assets/images/website-promotion1/tool-2.svg'
import ToolIcon3 from 'src/assets/images/website-promotion1/tool-3.svg'
import ToolIcon4 from 'src/assets/images/website-promotion1/tool-4.svg'
import toolImg1 from 'src/assets/images/website-promotion1/tool-1-img.png'
import gameImg1 from 'src/assets/images/website-promotion1/game1.png'
import gameImg2 from 'src/assets/images/website-promotion1/game2.png'
import gameImg3 from 'src/assets/images/website-promotion1/game3.png'
import gameImg4 from 'src/assets/images/website-promotion1/game4.png'
import mapGif from 'src/assets/images/website-promotion1/map.gif'
import circleBg from 'src/assets/images/website-promotion1/circle-bg.png'
import circle1 from 'src/assets/images/website-promotion1/circle1.png'
import circle2 from 'src/assets/images/website-promotion1/circle2.png'
import circle3 from 'src/assets/images/website-promotion1/circle3.png'
import circle4 from 'src/assets/images/website-promotion1/circle4.png'
import circle5 from 'src/assets/images/website-promotion1/circle5.png'
import circle6 from 'src/assets/images/website-promotion1/circle6.png'
import circle7 from 'src/assets/images/website-promotion1/circle7.png'
import circle8 from 'src/assets/images/website-promotion1/circle8.png'
import earthImg from 'src/assets/images/website-promotion1/earth.png'
import freeGif from 'src/assets/images/website-promotion1/free.gif'
import bg2 from 'src/assets/images/website-promotion1/bg2.png'
import page3Bg from 'src/assets/images/website-promotion1/page3-bg.png'
import StarIcon from 'src/assets/images/website-promotion1/star.svg'

const pageNav = [
  {
    icon: <ToolIcon1 />,
    title: '游戏安装检测',
  },
  {
    icon: <ToolIcon2 />,
    title: '网游加速器',
  },
  {
    icon: <ToolIcon3 />,
    title: '单机修改器',
  },
  {
    icon: <ToolIcon4 />,
    title: '游戏资源管理器',
  },
]

type TCarouselList = {
  title: string
  desc: string
  type: string
  listImg: string
}

const MAX_VISIBILITY = 3
let timeout: number | null = null
let bannerTimeout: number | null = null
let navTimeout: number | null = null

const Promotion1: FC = () => {
  const [page2NavIndex, setPage2NavIndex] = useState<number>(0)
  const [nav2OptionIndex, setNav2OptionIndex] = useState<number>(0)
  const [btnText, setBtnText] = useState<string>('玩游戏')
  const [carouselList, setCarouselList] = useState<TCarouselList[]>()
  const [active, setActive] = useState<number>(2)
  const [imgGameList, setImgGameList] = useState<string[]>()
  const [isFixed, setIsFixed] = useState<boolean>(false)
  const [resourceCount, setResourceCount] = useState<number | null>(null)
  const imgListContentRef1 = useRef<HTMLDivElement>(null)
  const imgListContentRef2 = useRef<HTMLDivElement>(null)

  const TDK = (
    <Helmet>
      <title>MYBUFF-游戏工具全家桶</title>
      <link rel='icon' href={favicon || ''} />
      <meta name='keywords' content={'[加速器]、[修改器]、[云游戏]、[游戏存档]、[游戏汉化包]、[游戏MOD]、[游戏工具]'} />
      <meta
        name='description'
        content={`提供众多主流游戏&平台的工具和服务，消除了使用的复杂性，简单的交互形式提供了方便的优化游戏体验的能力`}
      />
    </Helmet>
  )

  const loadConfig = async () => {
    const arr = await loadCarouselGameConfig()
    setCarouselList(arr)
    const imgList = await loadPromotion1GameConfig()
    setImgGameList(imgList)
  }

  const download = () => {
    window.open(`https://web.mybuff.com/anon/downcenter?channel=sgtdefault`)
    emitter.emit('report-log', {
      params: {
        encrypt: cryptoByAES(
          JSON.stringify({
            channel: 'sgtdefault',
            type: 'client_run',
            action: 'download',
          }),
        ),
      },
    })
  }

  const loadResourceCount = async () => {
    const { result } = await getResourceSuggestHttp({ pageNo: 1, pageSize: 1 })
    setResourceCount(result.totalRows)
  }

  // const changeBanner = () => {
  //   bannerTimeout = window.setInterval(() => {
  //     if (carouselList && active < (carouselList.length - 1)) {
  //       setActive((i) => i + 1)
  //       return
  //     }
  //     if (carouselList && active > 0) {
  //       setActive((i) => i - 1)
  //     }
  //   }, 3000)
  // }

  const moveImgList = () => {
    timeout = window.setInterval(() => {
      if (imgListContentRef1.current) {
        const LENGHT1 = -240 * (imgGameList?.length || 0)
        const currentLeft = parseInt(getComputedStyle(imgListContentRef1.current).left || '0', 10)
        if (currentLeft <= LENGHT1) {
          imgListContentRef1.current.style.transition = 'none'
          imgListContentRef1.current.style.left = `0px`
          return
        }
        imgListContentRef1.current.style.transition = 'left 0.5s ease-in-out'
        imgListContentRef1.current.style.left = `${currentLeft - 240}px`
      }
    }, 2000)
  }

  useEffect(() => {
    const onScroll = () => {
      const scrollHeight = window.scrollY
      if (scrollHeight > 0) {
        setIsFixed(true)
      }
      if (scrollHeight <= 10) {
        setIsFixed(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    // changeBanner()
    return () => {
      bannerTimeout && clearInterval(bannerTimeout)
    }
  }, [carouselList])

  useEffect(() => {
    moveImgList()
    return () => {
      timeout && clearInterval(timeout)
    }
  }, [imgGameList])

  const changeNavOptions = () => {
    navTimeout = window.setInterval(() => {
      setNav2OptionIndex(i => {
        if (i < 2) {
          return i + 1
        }
        return 0
      })
    }, 3000)
  }

  useEffect(() => {
    navTimeout && clearInterval(navTimeout)
    if (page2NavIndex === 1) {
      changeNavOptions()
    }
    return () => {
      navTimeout && clearInterval(navTimeout)
    }
  }, [page2NavIndex])

  useEffect(() => {
    loadConfig()
    loadResourceCount()
  }, [])

  return (
    <StyledHomeContainer>
      {TDK}
      <StyledHomeContent>
        <StyledHomeHeader className={classNames({ fixed: isFixed })}>
          <StyledHeaderContent>
            <HeaderLogoIcon />
            <StyledHeaderRight>
              <StyledHeaderNavList>
                <StyledHeaderNav className={classNames({ active: true })}>首页</StyledHeaderNav>
                {/* <StyledHeaderNav>游戏列表</StyledHeaderNav> */}
              </StyledHeaderNavList>
              <StyledHeaderDownload onClick={download}>
                <HeaderIcon />
                立即下载
              </StyledHeaderDownload>
            </StyledHeaderRight>
          </StyledHeaderContent>
        </StyledHomeHeader>
        <StyledPage1>
          <StyledPage1Left>
            <StyledPage1LogoTitle>MYBUFF</StyledPage1LogoTitle>
            <StyledPage1Title>游戏工具全家桶</StyledPage1Title>
            <StyledPage1Desc>
              集中游戏周边所有工具和资源：加速器、修改器、云游戏、插件、存档、MOD、汉化包等等
            </StyledPage1Desc>
            <StyledPage1DownloadBtn onClick={download}>
              <WindowIcon />
              <div>
                <StyledPage1DownloadTextTop>{`支持Win7 / 10 / 11 / 12`}</StyledPage1DownloadTextTop>
                <StyledPage1DownloadTextBottom>立即下载</StyledPage1DownloadTextBottom>
              </div>
              <StyledAnimationBtn>
                <div className='top-line' />
                <div className='bottom-line' />
              </StyledAnimationBtn>
            </StyledPage1DownloadBtn>
          </StyledPage1Left>
          <StyledPage1Right>
            <img src={page1Img} />
            <StyledAnimation>
              <div className='top-line' />
              <div className='left-line' />
            </StyledAnimation>
          </StyledPage1Right>
        </StyledPage1>
        <StyledPage2>
          <StyledModifierMask />
          <StyledPage2Title>我们提供的工具&服务</StyledPage2Title>
          <StyledPage2NavList>
            {pageNav.map((d, i) => (
              <StyledStyledPage2Nav
                className={classNames({ active: page2NavIndex === i })}
                onClick={() => setPage2NavIndex(i)}
              >
                {d.icon}
                {d.title}
              </StyledStyledPage2Nav>
            ))}
          </StyledPage2NavList>
          <StyledPage2Content>
            {page2NavIndex === 0 && (
              <StyledNav1Content>
                <StyledNavTitle>
                  MyBuff自动检测扫描您本地安装了的游戏或相关平台，帮您快速筛选出我们提供服务的游戏
                </StyledNavTitle>
                <StyledImgBox>
                  <div className='img-box1'>
                    <img className='img1' src={toolImg1} />
                  </div>
                  <div className='img-box2'>
                    <img className='img2' src={gameImg1} />
                  </div>
                  <div className='img-box3'>
                    <img className='img3' src={gameImg2} />
                  </div>
                  <div className='img-box4'>
                    <img className='img4' src={gameImg3} />
                  </div>
                  <div className='img-box5'>
                    <img className='img5' src={gameImg4} />
                  </div>
                </StyledImgBox>
              </StyledNav1Content>
            )}
            {page2NavIndex === 1 && (
              <StyledNav2Content>
                <StyledNav2Left>
                  {nav2OptionIndex === 1 && (
                    <StyledCircleContent>
                      {/* <div className='circle-box circle-box1'>
                        <img className='circle' src={circle1} />
                      </div>
                      <div className='circle-box circle-box2'>
                        <img className='circle' src={circle2} />
                      </div>
                      <div className='circle-box circle-box3'>
                        <img className='circle' src={circle3} />
                      </div>
                      <div className='circle-box circle-box4'>
                        <img className='circle' src={circle4} />
                      </div>
                      <div className='circle-box circle-box5'>
                        <img className='circle' src={circle5} />
                      </div>
                      <div className='circle-box circle-box6'>
                        <img className='circle' src={circle6} />
                      </div>
                      <div className='circle-box circle-box7'>
                        <img className='circle' src={circle7} />
                      </div> */}
                      <div className='circle-box'>
                        <img className='circle circle1' src={circle1} />
                        <img className='circle circle2' src={circle2} />
                        <img className='circle circle3' src={circle3} />
                        <img className='circle circle4' src={circle4} />
                        <img className='circle circle5' src={circle5} />
                        <img className='circle circle6' src={circle6} />
                        <img className='circle circle7' src={circle7} />
                        <img className='circle circle8' src={circle8} />
                      </div>
                    </StyledCircleContent>
                  )}
                  {nav2OptionIndex === 2 && (
                    <StyledFreeContent>
                      <img src={earthImg} className='earch' />
                      <img src={freeGif} className='gif' />
                    </StyledFreeContent>
                  )}
                </StyledNav2Left>
                <StyledNav2Right
                  onMouseEnter={() => navTimeout && clearInterval(navTimeout)}
                  onMouseLeave={() => changeNavOptions()}
                >
                  <StyledNav2RightOption
                    className={classNames({ active: nav2OptionIndex === 0 })}
                    onClick={() => setNav2OptionIndex(0)}
                  >
                    <div className='title'>金融专线，超低延迟</div>
                    <div className='desc'>全球多地去部署节点，提供各区服稳定加速线路</div>
                  </StyledNav2RightOption>
                  <StyledNav2RightOption
                    className={classNames({ active: nav2OptionIndex === 1 })}
                    onClick={() => setNav2OptionIndex(1)}
                  >
                    <div className='title'>海量游戏加速覆盖</div>
                    <div className='desc'>支持2000+游戏加速；DOTA2、CSGO、魔兽世界、使命召唤、GTA5等</div>
                  </StyledNav2RightOption>
                  <StyledNav2RightOption
                    className={classNames({ active: nav2OptionIndex === 2 })}
                    onClick={() => setNav2OptionIndex(2)}
                  >
                    <div className='title'>限时免费，还不快来</div>
                    <div className='desc'>
                      每天：<span>00:00～12:00限时免费使用</span>
                    </div>
                    <div className='desc'>MYBUFF会员更享全天免费加速</div>
                  </StyledNav2RightOption>
                </StyledNav2Right>
              </StyledNav2Content>
            )}
            {page2NavIndex === 2 && (
              <StyledModifier>
                <div className='description-container-left'>
                  <div className='description-item' style={{ width: 580, height: 320, marginBottom: 39 }}>
                    <div className='title'>自动检测游戏</div>
                    <div className='tips'>MyBuff自动检测扫描您本地安装了的游戏，帮您快速筛选出支持修改的游戏</div>
                    <PlatformAnimation />
                  </div>
                  <div className='description-item' style={{ width: 580, height: 390 }}>
                    <div className='title'>简单明了的操作界面</div>
                    <div className='tips'>我们采用交互式控件来帮助您快速简单的进行游戏内修改</div>
                    <div className='description-content'>
                      <EditCheat />
                    </div>
                  </div>
                </div>
                <div className='description-container-right'>
                  <div className='description-item-right' style={{ width: 580, height: 750 }}>
                    <div className='title'>方便到极致的应用方法</div>
                    <div className='steps'>
                      <div className='step'>
                        <span>步骤一</span>退出杀毒软件并关闭Windows Defender
                        <div className='desc'>
                          游戏修改会进行内存操作，会被杀毒软件误判，未避免修改应用失败请退出杀软后使用，但请放心我们的软件足够安全
                        </div>
                      </div>
                      <div className='step'>
                        <span>步骤二</span>启动您想要修改的游戏
                        <div className='desc'>
                          我们只支持<i>单机游戏</i>的修改 <br />
                          我们只支持<i>正版游戏</i>（破解，盗版我们不做兼容支持）
                        </div>
                      </div>
                      <div className='step'>
                        <span>步骤三</span>
                        {'游戏运行后点击 [一键加Buff] 按钮'}
                        <div className='desc'>请务必注意在游戏启动成功后再进行游戏修改的启动</div>
                      </div>
                      <div className='step'>
                        <span>步骤四</span>系统提示“buff加持成功”后，即可开始修改
                      </div>
                    </div>
                    <StyledBaseButtonDown
                      className='action-main-btn'
                      type='special'
                      data-agl-cvt={6}
                      onClick={() => download()}
                    >
                      <div
                        className='btnContent'
                        onMouseEnter={() => setBtnText('下载应用程序')}
                        onMouseLeave={() => setBtnText('玩游戏')}
                      >
                        {btnText}
                      </div>
                    </StyledBaseButtonDown>
                  </div>
                </div>
              </StyledModifier>
            )}
            {page2NavIndex === 3 && (
              <StyledResourceManager>
                <StyledResourceManagerLeft>
                  <div className='title'>畅享数千社区资源</div>
                  <div className='desc'>
                    海量用户源源不断上传各类游戏资源、存档、MOD、汉化包应有尽有，加入我们DIY你的游戏体验
                  </div>
                  <StyledCarouselDom>
                    <Carousel active={active} setActive={setActive}>
                      {carouselList?.map((d, i) => (
                        <div className='card' key={i}>
                          <img className='list-img' src={d.listImg} />
                        </div>
                      ))}
                    </Carousel>
                  </StyledCarouselDom>
                </StyledResourceManagerLeft>
                <StyledResourceManagerRight>
                  <Row>
                    <StyledResourceManagerBox>
                      <div className='num'>{resourceCount}</div>
                      <div className='desc'>提供资源游戏</div>
                    </StyledResourceManagerBox>
                    <StyledResourceManagerBox>
                      <div className='num2'>35.4K +</div>
                      <div className='desc'>累计订阅</div>
                    </StyledResourceManagerBox>
                  </Row>
                  <StyledDetail>
                    <div className='type'>{carouselList?.[active]?.type}</div>
                    <div className='title'>{carouselList?.[active]?.title}</div>
                    <div className='desc-title'>{carouselList?.[active]?.type}简介：</div>
                    <div className='desc'>{carouselList?.[active]?.desc}</div>
                  </StyledDetail>
                </StyledResourceManagerRight>
              </StyledResourceManager>
            )}
          </StyledPage2Content>
        </StyledPage2>
        <StyledPage3>
          <StyledPage3Content>
            <div className='title'>提供超过2000+款游戏和周边平台的工具与服务支持</div>
            <StyledGameList>
              <StyledGameListContent1 ref={imgListContentRef1}>
                {imgGameList?.map((d, i) => (
                  <StyledGameItem
                    key={`game-item1-${i}`}
                    onMouseEnter={() => timeout && clearInterval(timeout)}
                    onMouseLeave={() => moveImgList()}
                    onClick={download}
                  >
                    <img src={d} />
                  </StyledGameItem>
                ))}
                {imgGameList?.map((d, i) => (
                  <StyledGameItem
                    key={`game-item2-${i}`}
                    onMouseEnter={() => timeout && clearInterval(timeout)}
                    onMouseLeave={() => moveImgList()}
                    onClick={download}
                  >
                    <img src={d} />
                  </StyledGameItem>
                ))}
              </StyledGameListContent1>
            </StyledGameList>
          </StyledPage3Content>
        </StyledPage3>
        <StyledPage4>
          <div className='page4-title'>来自真实用户的评价</div>
          <StyledPage4Content>
            <StyledCommentItem>
              <div className='title'>最好的游戏工具箱！</div>
              <div className='desc'>
                我一直期待有一个游戏工具能够站在玩家的角度，一站式提供针对我爱玩的游戏的全部服务&工具，MYBUFF
                做到了，好看的界面、简单的操作、丰富齐全的功能！支持你们
              </div>
              <div className='userInfo'>
                MB_3f3334 <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </StyledCommentItem>
            <StyledCommentItem>
              <div className='title'>MyBuff YYDS</div>
              <div className='desc'>真的太棒了，在这里我可以同时使用 GTA V 的 加速、修改、MOD 游戏体验直接拉满</div>
              <div className='userInfo'>
                MB_516b42 <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </StyledCommentItem>
            <StyledCommentItem>
              <div className='title'>真的很方便</div>
              <div className='desc'>
                一个工具覆盖了我经常玩的游戏的全部周边功能，再也不用下一堆软件来优化我的游戏体验了，在这里无论想使用什么都是一键搞定
              </div>
              <div className='userInfo'>
                MB_45a119 <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </StyledCommentItem>
          </StyledPage4Content>
        </StyledPage4>
        <StyledFooter>
          <div className='line' />
          <div className='desc'>singhear LLC &nbsp;&nbsp; hello@mybuff.com</div>
        </StyledFooter>
      </StyledHomeContent>
      {page2NavIndex === 1 && nav2OptionIndex === 0 && (
        <StyledGifContent>
          <img src={mapGif} />
        </StyledGifContent>
      )}
    </StyledHomeContainer>
  )
}

const Carousel: React.FC<{
  children: ReactNode
  active: number
  setActive: React.Dispatch<React.SetStateAction<number>>
}> = ({ children, active, setActive }) => {
  const count: number = React.Children.count(children)

  return (
    <StyledCarousel className='carousel'>
      {active > 0 && (
        <button className='nav left' onClick={() => setActive(i => i - 1)}>
          <TiChevronLeftOutline />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className='card-container'
          style={
            {
              '--active': i === active ? 1 : 0,
              '--offset': (active - i) / 3,
              '--direction': Math.sign(active - i),
              '--abs-offset': Math.abs(active - i) / 3,
              'pointer-events': active === i ? 'auto' : 'none',
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
              display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
            } as CSSProperties
          }
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className='nav right' onClick={() => setActive(i => i + 1)}>
          <TiChevronRightOutline />
        </button>
      )}
    </StyledCarousel>
  )
}
const StyledFooter = styled.div`
  width: 100%;
  height: 130px;
  .line {
    width: 100%;
    height: 1px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(217, 217, 217, 0.3) 53%,
      rgba(255, 255, 255, 0) 100%
    );
    margin-bottom: 32px;
  }
  .desc {
    height: 14px;
    font-size: 14px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    line-height: 14px;
    margin-bottom: 20px;
  }
`
const StyledCommentItem = styled.div`
  width: 384px;
  height: 382px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px 8px 8px 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  .title {
    height: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
  .desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 30px;
    flex: auto;
    margin-top: 24px;
  }
  .userInfo {
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 30px;
    svg:nth-child(1) {
      margin-left: 12px;
    }
    svg {
      margin-right: 3px;
    }
  }
`
const StyledPage4Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
`
const StyledPage4 = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  margin-bottom: 130px;
  .page4-title {
    height: 58px;
    font-size: 36px;
    line-height: 58px;
    background: linear-gradient(180deg, #ffffff 0%, #cbc9cd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Alibaba PuHuiTi-Bold';
  }
`
const StyledGameItem = styled.div`
  width: 240px;
  height: 564px;
  position: relative;
  flex: 0 0 240px;
  cursor: pointer;
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateZ(0);
  transition: 0.3s;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s;
  }
  &::before {
    content: '';
    z-index: 10;
    width: 200%;
    height: 100%;
    top: -90%;
    left: -20px;
    opacity: 0.1;
    transform: rotate(45deg);
    background: linear-gradient(to top, transparent, #fff 15%, rgba(255, 255, 255, 0.5));
    transition: 0.3s;
    position: absolute;
  }
  img {
    width: 100%;
    height: 100%;
  }
  &:hover {
    &::after {
      background: rgba(0, 0, 0, 0.5);
    }
    &::before {
      transform: rotate(25deg);
      top: -40%;
      opacity: 0.15;
    }
  }
`
const StyledGameListContent2 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  left: 0;
  transition: left 0.5s ease-in-out;
  display: flex;
`
const StyledGameListContent1 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  left: 0;
  transition: left 0.5s ease-in-out;
  display: flex;
`

const StyledGameList = styled.div`
  width: 1200px;
  overflow: hidden;
  position: relative;
  margin-top: 40px;
  display: flex;
`
const StyledPage3Content = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  .title {
    width: 700px;
    font-size: 36px;
    line-height: 58px;
    background: linear-gradient(180deg, #ffffff 0%, #cbc9cd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Alibaba PuHuiTi-Bold';
  }
`
const StyledPage3 = styled.div`
  width: 100%;
  height: 930px;
  background: url(${page3Bg}) center center no-repeat;
  background-size: contain;
`
const StyledDetail = styled.div`
  padding-left: 15px;
  .type {
    display: inline-block;
    min-width: 50px;
    height: 32px;
    background: #a66fff;
    border-radius: 50%;
    padding: 0 10px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #020e20;
    margin-top: 60px;
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    line-height: 33px;
    margin-top: 24px;
  }
  .desc-title {
    font-size: 18px;
    color: #a66fff;
    margin-top: 16px;
    line-height: 36px;
    word-wrap: break-word;
    word-break: normal;
  }
  .desc {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 36px;
    word-wrap: break-word;
    word-break: normal;
  }
`
const StyledResourceManagerBox = styled.div`
  width: 235px;
  height: 88px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px 12px 12px 12px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  padding: 16px 24px;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  .num {
    height: 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 32px;
    background: linear-gradient(270deg, #4431ba 0%, #979dd8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .num2 {
    height: 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 32px;
    background: linear-gradient(270deg, #edb628 0%, #705612 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .desc {
    height: 16px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 16px;
    margin-top: 8px;
  }
`
const StyledResourceManagerRight = styled.div`
  max-width: 489px;
`
const StyledCarouselDom = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  .card {
    width: 100%;
    height: 100%;
    /* padding: 2rem; */
    /* background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%)); */
    border-radius: 1rem;
    color: #9ca3af;
    text-align: justify;
    transition: all 0.3s ease-out;
    .list-img {
      width: 100%;
      height: 100%;
    }

    p,
    h2 {
      transition: all 0.3s ease-out;
      opacity: var(--active);
    }
  }
`
const StyledCarousel = styled.div`
  position: relative;
  width: 340px;
  height: 187px;
  perspective: 500px;
  transform-style: preserve-3d;
  .card-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.4))
      translateZ(calc(var(--abs-offset) * -30rem)) translateX(calc(var(--direction) * -5rem));
    filter: blur(calc(var(--abs-offset) * 1rem));
    transition: all 0.3s ease-out;
  }
  .nav {
    color: white;
    font-size: 5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;

    &.left {
      transform: translateX(-100%) translatey(-50%);
    }

    &.right {
      right: 0;
      transform: translateX(100%) translatey(-50%);
    }
  }
  .card {
    width: 100%;
    height: 100%;
    /* padding: 2rem; */
    /* background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%)); */
    border-radius: 1rem;
    color: #9ca3af;
    text-align: justify;
    transition: all 0.3s ease-out;
    .list-img {
      width: 100%;
      height: 100%;
    }

    p,
    h2 {
      transition: all 0.3s ease-out;
      opacity: var(--active);
    }
  }
`
const StyledModifierMask = styled.div`
  position: absolute;
  width: 1800px;
  height: 1600px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle at center,
    rgb(117, 49, 186, 0.33) 0%,
    rgba(130, 61, 185, 0) 80%,
    rgba(130, 61, 185, 0) 100%
  );
  z-index: -1;
`
const StyledResourceManagerLeft = styled.div`
  .title {
    height: 28px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    line-height: 23px;
  }
  .desc {
    margin-top: 8px;
    height: 37px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 22px;
  }
`
const StyledResourceManager = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
`
const StyledGifContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 750px;
  left: 50%;
  transform: translateX(-65%);
  img {
    transform: scale(0.7);
  }
`
const StyledNav2Left = styled.div``
const StyledModifier = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 8px auto 57px;
  .description-item {
    padding: 30px 40px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .description3 {
      margin-top: 36px;
      padding: 30px 40px;
      width: 590px;
      height: 482px;
      background: linear-gradient(270deg, rgba(69, 58, 83, 0.5) 0%, rgba(90, 90, 96, 0.5) 100%);
      border-radius: 0px 0px 8px 8px;
    }
    .title {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 14px;
    }
    .tips {
      font-size: 14px;
      line-height: 24px;
      color: #b6b6b7;
    }
  }
  .description-content {
    width: 580px;
    height: 257px;
    background: linear-gradient(
      192deg,
      rgba(197, 152, 255, 0.4) 0%,
      rgba(197, 152, 255, 0) 45%,
      rgba(197, 152, 255, 0) 100%
    );
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .description-item-right {
    width: 580px;
    height: 770px;
    background: url(${bg2});
    background-size: cover;
    padding: 30px 40px;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 40px;
    }
  }
  .steps {
    width: 500px;
    margin: 0 auto;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: calc(100% - 20px);
      /* background: linear-gradient(180deg, #A97CAE 0%, #371DEA 100%); */
      left: 7px;
      top: 50%;
      transform: translateY(-50%);
      border-right: 1px dashed rgba(255, 255, 255, 0.3);
    }
    .step {
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      padding-left: 46px;
      margin-bottom: 107px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: bold;
        font-size: 18px;
        margin-right: 18px;
        color: #ffffff;
      }
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #1d0f2b;
        border: 1px solid #a485ff;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 4px;
        height: 4px;
        background: #a485ff;
        border-radius: 50%;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
      }
      .desc {
        position: absolute;
        left: 47px;
        top: 40px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 24px;
        font-size: 15px;
        font-weight: normal;
      }
    }
  }
`
const StyledBaseButtonDown = styled(SpecialButton)`
  width: 480px;
  height: 46px;
  margin-top: 104px;
  .btnContent {
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const StyledFreeContent = styled.div`
  width: 613px;
  height: 613px;
  position: relative;
  margin-left: 138px;
  .gif {
    position: absolute;
    left: -218px;
    bottom: -123px;
    transform: scale(0.6);
  }
`
const StyledCircleContent = styled.div`
  width: 606px;
  height: 611px;
  background: url(${circleBg});
  background-size: 100% 100%;
  margin: 30px 0 0 112px;
  position: relative;
  .circle-box {
    width: 543px;
    height: 543px;
    position: absolute;
    left: 30px;
    top: 38px;
    border-radius: 50%;
    transform-origin: center;
    animation: rotate 40s linear infinite;
    .circle {
      position: absolute;
      transform-origin: center;
      animation: againstRotate 40s linear infinite;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
        transform-origin: center;
      }
      100% {
        transform: rotate(360deg);
        transform-origin: center;
      }
    }
    @keyframes againstRotate {
      0% {
        transform: rotate(0deg);
        transform-origin: center;
      }
      100% {
        transform: rotate(-360deg);
        transform-origin: center;
      }
    }
    .circle1 {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .circle2 {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .circle3 {
      bottom: 50%;
      left: 0;
      /* transform: translateY(-50%); */
    }
    .circle4 {
      bottom: 50%;
      right: 0;
      /* transform: translateY(-50%); */
    }
    .circle5 {
      left: 83px;
      top: 58px;
    }
    .circle6 {
      left: 410px;
      top: 64px;
    }
    .circle7 {
      top: 400px;
      right: 56px;
    }
    .circle8 {
      bottom: 80px;
      left: 61px;
    }
  }
  /* .circle-box {
    position: absolute;
    transform-origin: center;
    animation: rotate 20s linear infinite;
    .circle {
      position: absolute;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
        transform-origin: center;
      }
      100% {
        transform: rotate(360deg);
        transform-origin: center;
      }
    }
  }
  .circle-box1 {
    width: 428px;
    height: 428px;
    left: 94px;
    top: 94px;
    .circle {
      right: 0;
      top: 0;
    }
  }
  .circle-box2 {
    width: 445px;
    height: 445px;
    left: 78px;
    top: 78px;
    animation: rotate 30s linear infinite;
    .circle {
      bottom: 0;
      right: 0;
    }
  }
  .circle-box3 {
    width: 380px;
    height: 380px;
    left: 118px;
    top: 118px;
    animation: rotate 25s linear infinite;
    .circle {
      bottom: 0;
      left: 0;
    }
  }
  .circle-box4 {
    width: 300px;
    height: 300px;
    left: 158px;
    top: 158px;
    animation: rotate 20s linear infinite;
    .circle {
      bottom: 0;
      left: 0;
    }
  }
  .circle-box5 {
    width: 476px;
    height: 476px;
    left: 65px;
    top: 65px;
    animation: rotate 45s linear infinite;
    .circle {
      top: 0;
      left: 0;
    }
  }
  .circle-box6 {
    width: 406px;
    height: 406px;
    left: 100px;
    top: 100px;
    animation: rotate 35s linear infinite;
    .circle {
      top: 0;
      right: 0;
    }
  }
  .circle-box7 {
    width: 496px;
    height: 496px;
    left: 55px;
    top: 55px;
    animation: rotate 40s linear infinite;
    .circle {
      top: 0;
      left: 0;
    }
  } */
`
const StyledNav2RightOption = styled.div`
  width: 294px;
  height: 108px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 8px 8px 8px 8px;
  padding: 16px 20px;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  .title {
    height: 28px;
    font-size: 18px;
    /* font-family */
    color: #ffffff;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .desc {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 24px;
    span {
      color: #977be1;
    }
  }
  &.active,
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`
const StyledNav2Right = styled.div`
  margin-top: 100px;
`
const StyledNav2Content = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
`
const StyledImgBox = styled.div`
  width: 1200px;
  height: 580px;
  margin-top: 32px;
  --side-height: 40px;
  position: relative;
  cursor: pointer;
  left: -10px;
  .img-box1,
  .img-box2,
  .img-box3,
  .img-box4,
  .img-box5 {
    position: absolute;
    transform: translate(0px, 0px);
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  }
  .img1,
  .img2,
  .img3,
  .img4,
  .img5 {
    position: relative;
    animation: 1s ease 0s 1 normal forwards running jBcSpD;
  }
  .img-box2 {
    left: 260px;
    top: 209px;
  }
  .img-box3 {
    left: 417px;
    top: 209px;
  }
  .img-box4 {
    left: 573px;
    top: 209px;
  }
  .img-box5 {
    left: 729px;
    top: 209px;
  }
  .img-box2,
  .img-box3,
  .img-box4,
  .img-box5 {
    transform-style: preserve-3d;
    transition: all 1s;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: transparent;
      transition: all 1s;
    }
    &::before {
      width: 100%;
      height: var(--side-height);
      left: 0;
      bottom: 0;
      transform-origin: center bottom;
      transform: scaleY(0) rotateX(90deg);
    }
    &::after {
      width: var(--side-height);
      height: 100%;
      right: 0;
      top: 0;
      transform-origin: right center;
      transform: scaleX(0) rotateY(-90deg);
    }
  }
  @keyframes jBcSpD {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &:hover {
    .img-box1,
    .img-box2,
    .img-box3,
    .img-box4,
    .img-box5 {
      transform: rotateY(-20deg) rotateX(20deg);
    }
    .img-box2,
    .img-box3,
    .img-box4,
    .img-box5 {
      transform: rotateY(-20deg) rotateX(20deg) translate3d(-10px, -10px, var(--side-height));
      transform: rotateY(-20deg) rotateX(20deg) translate3d(-10px, -10px, var(--side-height));
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
      &::before {
        transform: scaleY(1) rotateX(90deg);
        background: rgba(255, 255, 255, 0.02);
      }
      &::after {
        transform: scaleX(1) rotateY(-90deg);
        background: rgba(255, 255, 255, 0.06);
      }
    }
  }
`
const StyledNavTitle = styled.div`
  height: 24px;
  font-size: 16px;
  /* font-family: 'Alibaba PuHuiTi-Regular';
  font-weight: 400; */
  color: #ffffff;
  line-height: 24px;
  text-align: center;
`
const StyledNav1Content = styled.div``
const StyledPage2Content = styled.div`
  width: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  position: relative;
`
const StyledStyledPage2Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 282px;
  height: 60px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 8px 8px 8px 8px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 24px;
  transition: all 0.3s;
  &:last-child {
    margin-right: 0;
  }
  svg {
    margin-right: 16px;
  }
  &.active {
    color: #fe8888;
    box-shadow: 0px 3px 0px 0px #fe8888;
    font-weight: bold;
  }
  &:hover {
    color: #fe8888;
    font-weight: bold;
  }
`
const StyledPage2NavList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`
const StyledPage2Title = styled.div`
  width: 100%;
  height: 58px;
  font-size: 36px;
  line-height: 58px;
  background: linear-gradient(180deg, #ffffff 0%, #cbc9cd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: 'Alibaba PuHuiTi-Bold';
`
const StyledPage2 = styled.div`
  position: relative;
`
const StyledAnimationBtn = styled.div`
  overflow: hidden;
  width: 283px;
  height: 68px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  z-index: 1;
  .top-line {
    position: absolute;
    border-radius: 3px;
    background: linear-gradient(to left, #fff 0.43%, #fff 14.11%, rgba(120, 119, 198, 0) 70%);
    z-index: 1;
    opacity: 0;
    top: 0;
    left: 0px;
    width: 40px;
    height: 1px;
    animation: toRight 3000ms ease infinite normal forwards running 400ms;
  }
  @keyframes toRight {
    0% {
      opacity: 0;
      transform: translateX(0px);
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translateX(263px);
    }
  }
  .bottom-line {
    position: absolute;
    border-radius: 3px;
    background: linear-gradient(to right, #ffffff 0.43%, #ffffff 14.11%, rgba(120, 119, 198, 0) 70%);
    z-index: 1;
    opacity: 0;
    bottom: 1px;
    right: 0;
    width: 40px;
    height: 1px;
    animation: left 3000ms ease infinite normal forwards running 400ms;
  }
  @keyframes left {
    0% {
      opacity: 0;
      transform: translateX(0px);
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translateX(-263px);
    }
  }
`
const StyledAnimation = styled.div`
  overflow: hidden;
  width: 780px;
  height: 435px;
  position: absolute;
  left: 61px;
  top: 61px;
  border-radius: 24px;
  .top-line {
    position: absolute;
    border-radius: 3px;
    background: linear-gradient(to top, #9d9bf2 0.43%, #7877c6 14.11%, rgba(120, 119, 198, 0) 70%);
    z-index: 1;
    opacity: 0;
    top: 10px;
    right: 0px;
    width: 2px;
    height: 80px;
    animation: right 1600ms ease infinite normal forwards running 400ms;
  }
  @keyframes right {
    0% {
      opacity: 0;
      transform: translateY(0px);
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translateY(320px);
    }
  }
  .left-line {
    position: absolute;
    border-radius: 3px;
    background: linear-gradient(to left, #9d9bf2 0.43%, #7877c6 14.11%, rgba(120, 119, 198, 0) 70%);
    z-index: 1;
    opacity: 0;
    top: 0;
    left: 10px;
    width: 80px;
    height: 2px;
    animation: top 2000ms ease infinite normal forwards running 400ms;
  }
  @keyframes top {
    0% {
      opacity: 0;
      transform: translateX(0px);
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      transform: translateX(700px);
    }
  }
`
const StyledPage1Right = styled.div`
  position: absolute;
  right: -105px;
  top: 50px;
`
const StyledPage1DownloadTextBottom = styled.div`
  height: 22px;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  line-height: 22px;
  margin-top: 8px;
`
const StyledPage1DownloadTextTop = styled.div`
  height: 16px;
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
`
const StyledPage1DownloadBtn = styled.div`
  width: 283px;
  height: 68px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
  position: relative;
  background-color: #7600e1;
  background-image: #7600e1;
  background-image: -moz-linear-gradient(top, #9b00e1 0%, #7600e1 100%);
  background-image: -webkit-linear-gradient(top, #9b00e1 0%, #7600e1 100%);
  background-image: linear-gradient(to bottom, #9b00e1 0%, #7600e1 100%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  svg {
    margin-right: 18px;
  }
  &:hover {
    background-position: -200%;
    transition: background 300ms ease-in-out;
  }
`
const StyledPage1Desc = styled.div`
  height: 60px;
  width: 565px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 32px;
  margin-top: 24px;
`
const StyledPage1Title = styled.div`
  height: 70px;
  font-size: 60px;
  /* font-weight: bold; */
  color: #ffffff;
  line-height: 70px;
  letter-spacing: 1px;
  text-shadow: 15px 5px 5px rgba(0, 0, 0, 0.12);
  margin-top: 8px;
  font-family: 'Alibaba PuHuiTi-Bold';
`
const StyledPage1LogoTitle = styled.div`
  height: 50px;
  font-size: 46px;
  font-family: 'Alibaba PuHuiTi-Bold';
  line-height: 50px;
  background: linear-gradient(270deg, #fe8888 0%, #fe8888 60%, #8066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const StyledPage1Left = styled.div`
  position: relative;
  z-index: 10;
`
const StyledPage1 = styled.div`
  height: 655px;
  width: 1220px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
const StyledHeaderDownload = styled.div`
  width: 106px;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px 6px 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
  svg {
    margin-right: 8px;
  }
`
const StyledHeaderNav = styled.div`
  cursor: pointer;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 40px;
  &:hover {
    color: #ffffff;
  }
  &.active {
    color: #ffffff;
    border-bottom: 1px solid #fff;
  }
`
const StyledHeaderNavList = styled.div`
  display: flex;
  align-items: center;
`
const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 100;
`
const StyledHomeHeader = styled.div`
  width: 100%;
  transition: all 400ms ease 0s;
  height: auto;
  position: relative;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    width: 100%;
    height: 48px;
    bottom: 0;
    left: 0;
    background: rgba(15, 15, 35, 0.2);
    backdrop-filter: blur(14px);
    position: absolute;
    display: block;
    opacity: 0;
    transition: all 400ms ease 0s;
  }
  &.fixed {
    position: fixed;
    top: 0px;
    height: 48px;
    left: 0;
    &::after {
      opacity: 1;
    }
  }
`
const StyledHomeContent = styled.div`
  background: url(${bg}) top center;
  background-size: 100% auto;
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 60px 0 0;
  min-height: 100vh;
`
const StyledHomeContainer = styled.div`
  @font-face {
    font-family: Alibaba PuHuiTi-Bold;
    src: url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-115-Black/AlibabaPuHuiTi-3-115-Black.eot)
        format('embedded-opentype'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-115-Black/AlibabaPuHuiTi-3-115-Black.otf)
        format('opentype'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-115-Black/AlibabaPuHuiTi-3-115-Black.ttf)
        format('TrueType'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-115-Black/AlibabaPuHuiTi-3-115-Black.woff)
        format('woff'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-115-Black/AlibabaPuHuiTi-3-115-Black.woff2)
        format('woff2');
  }
  @font-face {
    font-family: Alibaba PuHuiTi-Regular;
    src: url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-55-Regular/AlibabaPuHuiTi-3-55-Regular.eot)
        format('embedded-opentype'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-55-Regular/AlibabaPuHuiTi-3-55-Regular.otf)
        format('opentype'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-55-Regular/AlibabaPuHuiTi-3-55-Regular.ttf)
        format('TrueType'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-55-Regular/AlibabaPuHuiTi-3-55-Regular.woff)
        format('woff'),
      url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlibabaPuHuiTi-3/AlibabaPuHuiTi-3-55-Regular/AlibabaPuHuiTi-3-55-Regular.woff2)
        format('woff2');
  }
  width: 100%;
  min-height: 100vh;
  background: #151110;
  font-family: Alibaba PuHuiTi-Regular;
`
export default observer(Promotion1)
