import React, { FC } from 'react'
import type { ModalProps } from 'antd/es/modal'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import classNames from 'classnames'

export interface IBaseModal extends ModalProps {}

const BaseModal: FC<IBaseModal> = ({ className, ...props }) => {
  return (
    <StyledBaseModalContainer
      className={classNames('app-nodrag', className)}
      keyboard={false}
      maskClosable={false}
      mask={true}
      centered={true}
      footer={false}
      {...props}
    />
  )
}

const StyledBaseModalContainer = styled(Modal)`
  .ant-modal-header {
    padding: 15px 24px;
    background: ${props => props.theme.color.bg.$272628};
    border-bottom-color: var(--border-color-4C4C50);
    border-radius: 8px 8px 0 0;
    .ant-modal-title {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      color: var(--text-color-FFFFFF);
    }
    & + .ant-modal-body {
      border-radius: 0 0 8px 8px;
    }
  }
  .ant-modal-close-x {
    height: 41px;
    width: 41px;
    line-height: 41px;
    color: ${props => props.theme.color.text.$D9D9D9};
    font-size: 12px;
    &:hover {
      color: var(--text-color-FFFFFF);
    }
  }
  .ant-modal-content {
    border-radius: 6px;
    background-color: transparent;
    backdrop-filter: blur(7px);
  }
  .ant-modal-body {
    background: ${props => props.theme.color.bg.$272628};
    padding: 30px;
    border-radius: 8px;
  }
`

export default observer(BaseModal)
