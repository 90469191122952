import React, { FC, useEffect, useRef, useState } from 'react'
import { getTextWidth } from 'src/tools/common'
import { observer } from 'mobx-react'
import { SpeedStore } from 'src/store'
import { ConfirmButton } from 'src/components/base-button'
import { useEaseOutQuadAnimation, TAttr } from 'src/hooks/useEaseOutQuadAnimation'
import { useNavigate } from 'react-router-dom'
import { FadeinImage } from 'src/components/base-image'
import styled from 'styled-components'
import classNames from 'classnames'

import defaultVerticalFigure from 'src/assets/images/common/default-vertical-figure.png'
import SpeedIcon from 'src/assets/icons/common/speed-icon.svg'

interface TGameItem {
  game: Game.GamePlatform
  isInstall: boolean
  style: {
    top: number
    left: number
  }
}

const DEFAULT_TRANFORM_STYLE = {
  rotateX: 0,
  rotateY: 0,
  translate3d: '0px, 0px, 0px',
}

const GameItem: FC<TGameItem> = ({ game, style, isInstall }) => {
  const { handleEaseOutQuadAnimation: handleLightEaseOutQuadAnimation } = useEaseOutQuadAnimation(0.1, 0.2, 4)
  const [transformStyle, setTransformStyle] = useState(DEFAULT_TRANFORM_STYLE)
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const lightBlockRef = useRef<HTMLDivElement>(null)
  const handleJumpToDetail = (gameid: number) => {
    navigate(`/game-detail?type=platform&platformId=${gameid}`)
  }
  const handleCardAnimation = (mouseX: number, mouseY: number) => {
    setTransformStyle({
      rotateX: mouseY / 15,
      rotateY: -(mouseX / 15),
      translate3d: `0px, 0px, ${(Math.abs(mouseX) + Math.abs(mouseY)) / 100}px`,
    })
  }
  const handleLightAnimation = (mouseX: number, mouseY: number) => {
    const attrList: TAttr[] = []
    const lightWidth = parseFloat(window.getComputedStyle(lightBlockRef.current!).getPropertyValue('width')) // 获取 transform 样式值
    const transform = window.getComputedStyle(lightBlockRef.current!).getPropertyValue('transform') // 获取 transform 样式值
    let [, , , , tx, ty]: any =
      transform !== 'none' ? transform.match(/matrix\(([^\)]+)\)/)?.[1].split(',') : ['0', '0', '0', '0', '0', '0']
    const translateX = parseFloat(tx.trim())
    const translateY = parseFloat(ty.trim())
    const targetX = mouseX - lightWidth / 2
    const targetY = mouseY - lightWidth / 2
    attrList.push({
      origin: translateX,
      target: targetX,
    })
    attrList.push({
      origin: translateY,
      target: targetY,
    })
    handleLightEaseOutQuadAnimation(attrList, ([tx, ty]) => {
      if (!lightBlockRef.current) {
        return
      }
      lightBlockRef.current.style.transform = `translateX(${tx}px) translateY(${ty}px)`
    })
  }
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!ref.current || !lightBlockRef.current) {
      return
    }
    const rect = ref.current!.getBoundingClientRect()
    const cardCenterX = rect.left + rect.width / 2
    const cardCenterY = rect.top + rect.height / 2
    handleCardAnimation(e.clientX - cardCenterX, e.clientY - cardCenterY)
    handleLightAnimation(e.clientX - rect.left, e.clientY - rect.top)
  }

  const handleMouseLeave = () => {
    setTransformStyle(DEFAULT_TRANFORM_STYLE)
  }
  return (
    <StyledGameItemContainer ref={ref} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} style={style}>
      <StyledGameItemContent
        key={game.platformId}
        style={{
          transform: `rotateX(${transformStyle.rotateX}deg) rotateY(${transformStyle.rotateY}deg) translate3d(${transformStyle.translate3d})`,
        }}
        onClick={() => handleJumpToDetail(game.platformId)}
      >
        <div ref={lightBlockRef} className='light-block' />
        <div className='img-box'>
          <FadeinImage
            width='100%'
            height='100%'
            draggable={false}
            src={game.platformListImage || defaultVerticalFigure}
          />
        </div>
        {/* {isInstall && <div className='install-tag'>已安装</div>} */}
        <div className='desc-box'>
          <div className='game-name'>{game.platformName}</div>
        </div>
        {/* {SpeedStore.speedingInfo?.speedGameNo === game.speedGameNo && SpeedStore.speedStatus === 'speeding' && (
          <div className='status-tag'>
            <SpeedIcon /> 加速中
          </div>
        )}
        {SpeedStore.speedingInfo?.speedGameNo === game.speedGameNo && SpeedStore.speedStatus === 'starting' && (
          <div className='status-tag' style={{ color: '#00FF8A' }}>
            <SpeedIcon /> 启动中
          </div>
        )} */}
      </StyledGameItemContent>
    </StyledGameItemContainer>
  )
}

const StyledGameItemContent = styled.div`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  will-change: transform;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .install-tag {
    ${props => props.theme.flexCenter}
    position: absolute;
    top: 12px;
    left: 12px;
    width: 51px;
    height: 20px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 183, 49, 0.3);
    border-radius: 20px;
    font-size: 13px;
    font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
    font-weight: 400;
    color: #ffb731;
    line-height: 12px;
  }
  .img-box {
    width: var(--block-width);
    height: var(--block-img-height);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .desc-box {
    width: 100%;
    height: 70px;
    background: ${props => props.theme.color.bg.$FFFFFF06};
    padding: 16px 12px;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-color-FFFFFF);
    .game-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .icon {
      color: #555;
      line-height: 1;
      margin-left: 5px;
      margin-top: 0.3em;
    }
  }
  .status-tag {
    background: rgba(0, 0, 0, 0.69);
    border-radius: 0px 0px 16px 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 10px;
    font-size: 12px;
    color: #02ca70;
    display: flex;
    align-items: center;
    svg {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .light-block {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: '';
    opacity: 0;
    width: 80px;
    height: 80px;
    filter: blur(50px);
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
  }
  &:hover {
    .light-block {
      opacity: 0.8;
    }
    .img-box {
      opacity: 0.8;
    }
  }
`

const StyledGameItemContainer = styled.div`
  perspective: 560px;
  width: var(--block-width);
  height: var(--block-height);
  position: absolute;
  margin-right: 26px;
  margin-bottom: 20px;
`

export default observer(GameItem)
