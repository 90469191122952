import { observable } from 'mobx'

const CloudGamePlayStore = observable<CloudGameClientStore>({
  gamePull:false,
  platformLogin:false,
  beginTime: 0,
  /** 云游戏页面打开超时定时器 */
  cloudGamePageLoadTimer: null,
  /** socket链接 id */
  linkUid: '',
  /** 云游戏单号 */
  gameCloudNo: '',
  /** 云游戏启动进度 */
  cloudGameStartProcess: 0,
  /** 云游戏错误弹窗信息 */
  cloudGameStartCrash: null,
  /** 云游戏业务提示框 */
  cloudErrorModalType: undefined,
  /** 云游戏Iframe状态 */
  cloudGameIframe: {
    status: 'normal',
    iframeSrc: '',
  },
  /** 云游戏页面是否加载完成 */
  cloudPageStatus: false,
  /** 退出云游戏弹窗显示 */
  visibleExitConfirmModal: false,
  /** 云游戏多人互动相关 */
  /** 是否多人模式 */
  multipleUserRole: 'none',
  multipleHasControl: 'none',
  multipleModalStatus: 'none',
  multiplayerInfo: null,

  /** 当前游戏运行状态 */
  gameRunStatus: 'stop',

  /** 当前正在运行的游戏 */
  currentTheRunningGameInfo: null,

  /** 当前正准备启动的游戏 */
  currentTheStartingGameInfo: null,
  /** 窗口状态 status: 0 - 窗口模式  1 - 最大化  2 - 全屏模式 */
  windowStatus: 0,

  /** 当前等待上一个游戏结束之后要启动的游戏 */
  willStartGameId:  null,

  gameStartingTimer: null,
  /** 启动游戏超时倒计时 */
  gameStartingCountdown:0,
  /** 试玩检测会员时长定时器 */
  trialVipTimeTimer:0,
  startGameInfoMap: new Map()
})

export default CloudGamePlayStore