import Socket from "./core";
import DEFAULTS from "./defaults";

function createWS<EventMap extends {}, T, R>(
  option: Partial<NsWfSocket.ConfigOption<T, R>>
) {
  const socket = new Socket<EventMap, T, R>(
    Object.assign({}, DEFAULTS, option)
  );
  function SocketInstance<A, B, C>(url: string, protocols?: string[]) {
    socket.connect(url, protocols);
    return socket as unknown as Socket<A, B, C>;
  }
  SocketInstance.defaults = Object.assign({}, DEFAULTS, option);
  SocketInstance.Socket = Socket;
  SocketInstance.create = function create<E extends {}, F, G>(
    instanceOption?: Partial<NsWfSocket.ConfigOption<F, G>>
  ) {
    return createWS<E, F, G>(
      Object.assign({}, DEFAULTS, SocketInstance.defaults, instanceOption)
    );
  };
  return SocketInstance;
}

let socketInstance = createWS<{}, any, any>(DEFAULTS);

export default socketInstance;
