import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { BaseModal, imperativeConfirmModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { ConfirmButton, CancelButton } from 'src/components/base-button'
import { message, Tooltip, Badge } from 'antd'
import { useFileDownload } from 'src/hooks'
import { getResourceDetailByResourceId, getResourceDownloadUrlHttp } from 'src/apis/game-api'
import { subscribeResourceHttp, unsubscribeResourceHttp } from 'src/apis/user-api'
import { DownloadStore, ControllerStore } from 'src/store/resource'
import { VipStore, appStore } from 'src/store'
import { computedDownloadCount } from 'src/tools/common'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import openVipImg from 'src/assets/images/detail-resource/open-vip.png'
import CEFTransfer from 'src/CEFTransfer'
import PlusIcon from 'src/assets/icons/common/plus.svg'
import styled from 'styled-components'
import classNames from 'classnames'
import dayjs from 'dayjs'

const exchangeSize = (size: number) => {
  if (size < 1024) {
    return `${size}KB`
  } else {
    return `${(size / 1024).toFixed(2)}MB`
  }
}

const DetailModal: FC = () => {
  const navigate = useNavigate()
  const [resourceDetail, setResourceDetail] = useState<Resource.TResourceDetail | null>(null)
  const [loading, setLoading] = useState(false)
  const [tabsList, setTabsList] = useState<{ name: string; content: string }[] | null>(null)
  const [activeTabsIndex, setActiveTabsIndex] = useState<number>(0)
  const link = ControllerStore.detailModalResourceId
    ? DownloadStore.fileDownloadLinkCache[ControllerStore.detailModalResourceId]
    : undefined
  const info = link ? DownloadStore.fileDownloadProgress[link] : null
  // const isDownloading = info?.status === 2

  const { download } = useFileDownload({
    link,
    autoClear: false,
    onCancel() {
      setLoading(false)
    },
    onComplete(data) {
      message.success(`下载成功，请对照使用说明应用`)
      // 上报下载次数
      // reportMODDownload.Execute({
      //   goodsId: d.goodsId,
      // })
      // const modal = Modal.success({
      //   className: 'custom postsConfirm',
      //   title: '下载成功',
      //   // icon: null,
      //   width: 432,
      //   closable: true,
      //   centered: true,
      //   autoFocusButton: 'ok',
      //   content: (
      //     <StyledSuccess>
      //       <div className='text'>
      //         <strong>{d.productName}</strong>&nbsp;<span>下载完成！</span>
      //       </div>
      //       <p>文件目录：{data.filePath}</p>
      //     </StyledSuccess>
      //   ),
      //   okText: `打开下载目录`,
      //   cancelText: `我知道了`,
      //   onOk() {
      //     window?.FuluApp?.JSNotifyCppFunc('explorer_local_file', data.filePath)
      //   },
      // })
      setLoading(false)
    },
    onFail() {
      setLoading(false)
      message.warn('下载失败，请重试')
    },
  })

  //获取下载链接
  const getLink = async (resourceId: string | number) => {
    const { result } = await getResourceDownloadUrlHttp({
      resourceId,
    })
    return result.fileResource
  }

  const handleOpenFilePath = () => {
    if (!info) {
      return
    }
    CEFTransfer.sendToClient('explorer_local_file', { filePath: info?.filePath })
  }

  const handleSubscribeResource = async (type?: 'resubscribe') => {
    if (!ControllerStore.detailModalResourceId || !resourceDetail) {
      return
    }
    const detailModalResourceId = ControllerStore.detailModalResourceId
    const isResubscribe = type === 'resubscribe'

    setLoading(true)
    try {
      await VipStore.handleLoadVipInfo()
      /** 当前用户非会员，并且没得次数了 */
      if (!VipStore.memberinfo?.isMember && !VipStore.hasFreeSubscribeNum()) {
        if (isResubscribe) {
          return imperativeConfirmModal({
            title: '游戏资源订阅提示',
            width: 620,
            closable: true,
            zIndex: 1001,
            content: (
              <StyledSubscribeTipContainer>
                订阅资源已于:{' '}
                <span className='main'>{dayjs(resourceDetail.tempHaveExpiryDate).format('YYYY-MM-DD')}</span> 过期
                <div className='row'>
                  如继续使用请重新进行订阅激活，免费用户 <span className='main'>每周</span> 仅可订阅{' '}
                  <span className='main'>{VipStore.freeSubscribeConfig?.cycleFreeNum}</span>{' '}
                  次游戏资源，每个资源订阅有效期为{' '}
                  <span className='main'>{VipStore.freeSubscribeConfig?.freeValidDay}</span>{' '}
                  天，您本周资源订阅次数已达超过上限，请下周再试/开通MYBUFF会员
                </div>
                <StyledOpenVipTipsBox>
                  <FadeinImage type='notDefault' src={openVipImg} width={26} height={18} />
                  开通<span className='primary'>MYBUUFF会员</span>，会员期间全部游戏资源免费使用
                </StyledOpenVipTipsBox>
              </StyledSubscribeTipContainer>
            ),
            onOk: () => {
              ControllerStore.cancelDetailModal()
              // navigate('/vip-recharge')
              showUserCenter(NAV_KEY_MAPPING.VIP)
            },
            okText: '开通会员',
            cancelText: '我知道了',
          })
        } else {
          return imperativeConfirmModal({
            title: '游戏资源订阅提示',
            width: 620,
            zIndex: 1001,
            closable: true,
            content: (
              <StyledSubscribeTipContainer>
                免费用户 <span className='main'>每周</span> 仅可订阅{' '}
                <span className='main'>{VipStore.freeSubscribeConfig?.cycleFreeNum}</span>{' '}
                次游戏资源，每个资源订阅有效期为{' '}
                <span className='main'>{VipStore.freeSubscribeConfig?.freeValidDay}</span>{' '}
                天，您本周资源订阅次数已达超过上限，请下周再试/开通MYBUFF会员
                <StyledOpenVipTipsBox>
                  <FadeinImage type='notDefault' src={openVipImg} width={26} height={18} />
                  开通<span className='primary'>MYBUUFF会员</span>，会员期间全部游戏资源免费使用
                </StyledOpenVipTipsBox>
              </StyledSubscribeTipContainer>
            ),
            onOk: () => {
              ControllerStore.cancelDetailModal()
              // navigate('/vip-recharge')
              showUserCenter(NAV_KEY_MAPPING.VIP)
            },
            okText: '开通会员',
            cancelText: '我知道了',
          })
        }
      }
      /** 当前用户非会员，但是有次数 */
      if (!VipStore.memberinfo?.isMember) {
        if (isResubscribe) {
          const isConfirm = await imperativeConfirmModal({
            title: '游戏资源订阅提示',
            width: 620,
            zIndex: 1001,
            closable: true,
            content: (
              <StyledSubscribeTipContainer>
                订阅资源已于:{' '}
                <span className='main'>{dayjs(resourceDetail.tempHaveExpiryDate).format('YYYY-MM-DD')}</span> 过期
                <div className='row'>
                  如继续使用请重新进行订阅激活，免费用户 <span className='main'>每周</span> 仅可订阅{' '}
                  <span className='main'>{VipStore.freeSubscribeConfig?.cycleFreeNum}</span>{' '}
                  次游戏资源，每个资源订阅有效期为{' '}
                  <span className='main'>{VipStore.freeSubscribeConfig?.freeValidDay}</span>{' '}
                  天，有效期内可以无限次下载/使用资源（本周已订阅资源{' '}
                  <span className='main'>{VipStore.userUseSubscribeNum}</span> 次）
                </div>
                <div className='row'>
                  请再次确认您要进行
                  <span className='main'>
                    《{resourceDetail?.resourceName}({resourceDetail?.resourceTypeName})》
                  </span>
                  资源的订阅？
                </div>
                <StyledOpenVipTipsBox>
                  <FadeinImage type='notDefault' src={openVipImg} width={26} height={18} />
                  开通<span className='primary'>MYBUUFF会员</span>，会员期间全部游戏资源免费使用
                </StyledOpenVipTipsBox>
              </StyledSubscribeTipContainer>
            ),
            okText: '我确定',
            cancelText: '开通会员',
          })
          if (!isConfirm.value && isConfirm.target === 'close') {
            /** 用户取消订阅 */
            return
          }
          if (!isConfirm.value && isConfirm.target === 'btn') {
            ControllerStore.cancelDetailModal()
            // navigate('/vip-recharge')
            showUserCenter(NAV_KEY_MAPPING.VIP)
            return
          }
        } else {
          const isConfirm = await imperativeConfirmModal({
            title: '游戏资源订阅提示',
            width: 620,
            zIndex: 1001,
            closable: true,
            content: (
              <StyledSubscribeTipContainer>
                免费用户 <span className='main'>每周</span> 仅可订阅{' '}
                <span className='main'>{VipStore.freeSubscribeConfig?.cycleFreeNum}</span>{' '}
                次游戏资源，每个资源订阅有效期为{' '}
                <span className='main'>{VipStore.freeSubscribeConfig?.freeValidDay}</span>{' '}
                天，有效期内可以无限次下载/使用资源（本周已订阅资源{' '}
                <span className='main'>{VipStore.userUseSubscribeNum}</span> 个）
                <div className='row'>
                  请再次确认您要进行
                  <span className='main'>
                    《{resourceDetail.resourceName}({resourceDetail.resourceTypeName})》
                  </span>
                  资源的订阅？
                </div>
                <StyledOpenVipTipsBox>
                  <FadeinImage type='notDefault' src={openVipImg} width={26} height={18} />
                  开通<span className='primary'>MYBUUFF会员</span>，会员期间全部游戏资源免费使用
                </StyledOpenVipTipsBox>
              </StyledSubscribeTipContainer>
            ),
            okText: '我确定',
            cancelText: '开通会员',
          })
          if (!isConfirm.value && isConfirm.target === 'close') {
            /** 用户取消订阅 */
            return
          }
          if (!isConfirm.value && isConfirm.target === 'btn') {
            ControllerStore.cancelDetailModal()
            // navigate('/vip-recharge')
            showUserCenter(NAV_KEY_MAPPING.VIP)
            return
          }
        }
      }
      await subscribeResourceHttp({ resourceId: detailModalResourceId })
      await Promise.all([handleLoadResourceDetail(detailModalResourceId), VipStore.handleLoadUserFreeSubscribeNum()])
      if (isResubscribe) {
        message.success('游戏资源订阅激活成功')
      } else {
        message.success('订阅成功，请下载资源并对照使用说明应用')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
      CEFTransfer.sendToClient('openUrl', { url: e.target.href })
    }
  }

  const handleDragStartEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const handleUnubscribeResource = async () => {
    if (!resourceDetail) {
      return
    }
    if (!VipStore.memberinfo?.isMember && resourceDetail.have) {
      const isConfirm = await imperativeConfirmModal({
        title: '游戏资源移除订阅提示',
        width: 620,
        closable: true,
        content: (
          <StyledSubscribeTipContainer>
            <div>
              订阅资源将于:{' '}
              <span className='main'>{dayjs(resourceDetail.tempHaveExpiryDate).format('YYYY-MM-DD')}</span>{' '}
              过期，过期前您可以随时下载及使用，请再次确认您要从已订阅资源列表中移除他
            </div>
            <div>注意：移除订阅不会返还您每周游戏资源免费订阅次数</div>
          </StyledSubscribeTipContainer>
        ),
        okText: '我确定',
        cancelText: '开通会员',
      })
      if (!isConfirm.value && isConfirm.target === 'close') {
        /** 用户取消订阅 */
        return
      }
      if (!isConfirm.value && isConfirm.target === 'btn') {
        ControllerStore.cancelDetailModal()
        // navigate('/vip-recharge')
        showUserCenter(NAV_KEY_MAPPING.VIP)
        return
      }
    }
    setLoading(true)
    try {
      await unsubscribeResourceHttp({ resourceId: resourceDetail?.resourceId })
      message.success('移除订阅成功')
      appStore.mySubscribeListReload += 1
      ControllerStore.cancelDetailModal()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleDownloadResource = async () => {
    if (!resourceDetail) {
      return
    }
    if (!ControllerStore.detailModalResourceId) {
      return
    }
    if (DownloadStore.hasOtherDownloadTasks()) {
      return message.warn(`目前有正在执行的下载任务，请等任务结束后重试`)
    }
    // setLoading(true)
    let link = ''
    try {
      const result = await getLink(ControllerStore.detailModalResourceId)
      link = result.filePath
      if (result.storageType === 'CLOUD_STORAGE') {
        cefOpenUrl(link)
        setLoading(false)
        return
      }
    } catch (e) {
      message.warn((e as Error).message)
      setLoading(false)
      return
    }
    if (!link.startsWith('http')) {
      console.log('下载链接不正确')
      setLoading(false)
      message.warn('下载失败，请重试')
      return
    }
    DownloadStore.fileDownloadLinkCache[ControllerStore.detailModalResourceId] = link
    download(resourceDetail.resourceName, resourceDetail.resourceTypeName, link)
    setLoading(false)
    //浏览器中直接重置loading状态
    !window.FuluApp && setLoading(false)
  }

  const handleLoadResourceDetail = async (resourceId: number) => {
    const { result } = await getResourceDetailByResourceId({ resourceId })
    setResourceDetail(result)
    setTabsList(
      [
        {
          name: `${result.resourceTypeName}介绍`,
          content: result.fileResource.description,
        },
        {
          name: '使用说明',
          content: result.fileResource.readme,
        },
      ].filter(f => Boolean(f.content)),
    )
  }

  useEffect(() => {
    if (ControllerStore.detailModalResourceId) {
      handleLoadResourceDetail(ControllerStore.detailModalResourceId)
    }
    return () => {
      setResourceDetail(null)
      setTabsList(null)
      setActiveTabsIndex(0)
    }
  }, [ControllerStore.detailModalResourceId])

  return (
    <StyledDetailModal
      width={770}
      visible={ControllerStore.detailModalResourceId !== null}
      onCancel={ControllerStore.cancelDetailModal}
    >
      <StyledResourceContainer>
        <StyledResourceLeft>
          <FadeinImage width={200} height={92} src={resourceDetail?.listImage} />
          <div className='resource-name ellipsis'>
            <Tooltip overlay={resourceDetail?.resourceName}>{resourceDetail?.resourceName}</Tooltip>
          </div>
          <div className='resource-info-title'>{resourceDetail?.resourceTypeName}信息</div>
          {resourceDetail?.fileResource.supportGameVersion && (
            <div className='resource-info-text'>
              <span className='label'>适用版本</span>
              <span className='value'>{resourceDetail.fileResource.supportGameVersion || '--'}</span>
            </div>
          )}
          {resourceDetail?.platformName && (
            <div className='resource-info-text'>
              <span className='label'>适用平台</span>
              <span className='value'>{resourceDetail.platformName || '--'}</span>
            </div>
          )}
          {!!resourceDetail?.fileResource.fileSize && (
            <div className='resource-info-text'>
              <span className='label'>{resourceDetail?.resourceTypeName}大小</span>
              <span className='value'>{exchangeSize(resourceDetail.fileResource.fileSize)}</span>
            </div>
          )}
          {(resourceDetail?.fileResource.downloadCount || resourceDetail?.fileResource.downloadCount === 0) && (
            <div className='resource-info-text'>
              <span className='label'>累计下载</span>
              <span className='value'>{computedDownloadCount(resourceDetail.fileResource.downloadCount)}次</span>
            </div>
          )}
          {resourceDetail?.fileResource.extractPassword && (
            <Tooltip overlay={resourceDetail.fileResource.extractPassword}>
              <div className='resource-info-text ellipsis'>
                <span className='label'>下载密码</span>
                <span className='value'>{resourceDetail.fileResource.extractPassword || '--'}</span>
              </div>
            </Tooltip>
          )}
          {resourceDetail?.subscribe && (
            <StyledSubscriptionContainer>
              {resourceDetail?.have ? (
                <div className='subscription-valid'>
                  <Badge color='#10fab8' />
                  订阅有效期至：
                  <span className='main'>{dayjs(resourceDetail.tempHaveExpiryDate).format('YYYY-MM-DD')}</span>
                </div>
              ) : (
                <div className='subscription-not-valid'>
                  <div className='subscription-status'>
                    <Badge color='#ff2e00' />
                    订阅已过期
                  </div>
                  <div className='subscription-text'>
                    过期时间 {dayjs(resourceDetail.tempHaveExpiryDate).format('YYYY-MM-DD')}
                  </div>
                </div>
              )}
            </StyledSubscriptionContainer>
          )}
        </StyledResourceLeft>
        <StyledResourceRight>
          {tabsList && tabsList.length > 0 && (
            <div className='resource-tabs'>
              {tabsList?.map((d, i) => {
                return (
                  <div
                    key={d.name}
                    className={classNames('resource-tab-item', { active: i === activeTabsIndex })}
                    onClick={() => setActiveTabsIndex(i)}
                  >
                    {d.name}
                  </div>
                )
              })}
            </div>
          )}
          {tabsList?.[activeTabsIndex] && (
            <div
              className='resource-info-content'
              onClick={handleEventDelegation}
              onDragStart={handleDragStartEventDelegation}
            >
              <div dangerouslySetInnerHTML={{ __html: tabsList[activeTabsIndex].content }} />
            </div>
          )}
        </StyledResourceRight>
        <div className='actions-box'>
          {resourceDetail && (
            <>
              {resourceDetail.subscribe && (
                <CancelButton
                  loading={loading}
                  className='resource-button'
                  style={{ marginRight: 16 }}
                  onClick={() => handleUnubscribeResource()}
                >
                  移除订阅
                </CancelButton>
              )}
              {!resourceDetail.subscribe && (
                <ConfirmButton className='resource-button' onClick={() => handleSubscribeResource()} loading={loading}>
                  订阅资源
                </ConfirmButton>
              )}
              {resourceDetail.subscribe && !resourceDetail.have && (
                <ConfirmButton
                  className='resource-button'
                  onClick={() => handleSubscribeResource('resubscribe')}
                  loading={loading}
                >
                  重新激活
                </ConfirmButton>
              )}
              {resourceDetail.subscribe && resourceDetail.have && !info && (
                <ConfirmButton className='resource-button' onClick={() => handleDownloadResource()}>
                  下载资源
                </ConfirmButton>
              )}
              {resourceDetail.subscribe && resourceDetail.have && info && info.status === 2 && (
                <ConfirmButton className='resource-button' loading={true}>
                  下载中...{info?.progress ?? 0}%
                </ConfirmButton>
              )}
              {resourceDetail.subscribe && resourceDetail.have && info && info.status === 1 && (
                <ConfirmButton className='resource-button' onClick={() => handleOpenFilePath()}>
                  打开目录
                </ConfirmButton>
              )}
            </>
          )}
        </div>
      </StyledResourceContainer>
    </StyledDetailModal>
  )
}

const StyledOpenVipTipsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 383px;
  height: 40px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-top: 24px;
  font-size: 14px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 16px;
  .primary {
    color: ${props => props.theme.color.mainColor};
  }
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 156px;
    height: 40px;
    opacity: 0.3;
    background: linear-gradient(90deg, #c38cff 0%, rgba(195, 140, 255, 0) 100%);
  }
`

const StyledSubscribeTipContainer = styled.div`
  .row {
    margin-top: 20px;
  }
  .main {
    color: var(--text-color-FFFFFF);
    font-weight: 800;
  }
`

const StyledResourceRight = styled.div`
  padding: 32px 32px 0;
  width: 523px;
  .resource-info-content {
    margin-top: 24px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
    font-weight: 400;
    color: ${props => props.theme.color.text.$BBBBBB};
    line-height: 22px;
    height: 310px;
    overflow-y: auto;
    img {
      max-width: 100%;
    }
  }
  .resource-tabs {
    display: inline-flex;
    align-items: center;
    height: 32px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 32px;
    .resource-tab-item {
      ${props => props.theme.flexCenter}
      color: ${props => props.theme.color.text.$FFFFFF86};
      padding: 0 18px;
      height: 32px;
      border-radius: 32px;
      margin-right: 6px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: var(--text-color-FFFFFF);
      }
      &.active {
        background: rgba(192, 138, 254, 0.24);
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: var(--text-color-FFFFFF);
        line-height: 20px;
      }
    }
  }
`

const StyledSubscriptionContainer = styled.div`
  width: 248px;
  background: rgba(255, 255, 255, 0.06);
  position: absolute;
  bottom: 0;
  left: 0;
  .subscription-valid {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.87);
    line-height: 24px;
    .main {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .subscription-not-valid {
    height: 58px;
    width: 100%;
    padding-top: 6px;
    .subscription-status {
      padding-left: 24px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.87);
      line-height: 24px;
      .ant-badge {
        width: 16px;
      }
    }
    .subscription-text {
      margin-top: 2px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 20px;
      padding-left: 40px;
    }
  }
`

const StyledResourceLeft = styled.div`
  width: 248px;
  position: relative;
  padding: 32px 24px 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%);
  .true-img {
    border-radius: 4px;
  }
  .resource-name {
    font-size: 16px;
    line-height: 22px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: #ffffff;
    margin-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${props => props.theme.color.bor.$FFFFFF12};
  }
  .resource-info-title {
    font-size: 16px;
    line-height: 24px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: ${props => props.theme.color.text.$FFFFFF86};
    margin-top: 16px;
  }
  .resource-info-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;
    .label {
      color: rgba(255, 255, 255, 0.6);
    }
    .value {
      color: rgba(255, 255, 255, 0.86);
    }
  }
`

const StyledResourceContainer = styled.div`
  display: flex;
  width: 770px;
  height: 487px;
  box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.14);
  .actions-box {
    position: absolute;
    bottom: 32px;
    right: 50px;
    display: flex;
    align-items: center;
    .resource-button {
      ${props => props.theme.flexCenter}
      width: 100px;
      height: 36px;
    }
  }
`

const StyledDetailModal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
  }
`

export default observer(DetailModal)
