import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { Form, message } from 'antd'
import { CancelButton, ConfirmButton } from 'src/components/base-button'
import { EmailVerification, PhoneVerification } from 'src/components/base-verification'
import { userBindIdentity } from 'src/apis/user-api'
import styled from 'styled-components'
import classNames from 'classnames'

type TBindModal = {
  visible: Modifier.TBindModalVisible | null
  onCancel: () => void
  onSuccess: () => void
}

const BindModal: FC<TBindModal> = ({ visible, onCancel, onSuccess }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const handleCancelModal = () => {
    onCancel()
    form.resetFields()
  }

  const handleBind = async () => {
    const values = await form.validateFields()
    setLoading(true)
    let params: Modifier.TUserBindIdentityPayload
    
    if (visible === '手机') {
      params = {
        type: 'mobile',
        account: values.phone,
        code: values.phoneVerificationCode,
      }
    }
    if (visible === '邮箱') {
      params = {
        type: 'email',
        account: values.email,
        code: values.emailVerificationCode,
      }
    }
    try {
      await userBindIdentity(params!)
      handleCancelModal()
      onSuccess()
      message.success(`${visible}绑定成功`)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledBindModalContainer
      visible={!!visible}
      title={`绑定${visible}`}
      width={414}
      onCancel={() => handleCancelModal()}
    >
      <StyledBindModalForm form={form}>
        {visible === '邮箱' && (
          <EmailVerification
            emailPlaceholder='请填写想要绑定的邮箱地址'
            emailEmptyMsg='邮箱地址不能为空'
            emailErrorMsg='请填写正确格式的邮箱地址'
            verificationCodePlaceholder='请输入您收到的邮箱验证码'
            verificationCodeEmptyMsg='验证码不能为空'
            verificationCodeErrorMsg='请填写正确格式的验证码'
            // verificationExistOptions={{
            //   hasExist: true,
            //   field: 'email',
            //   callback: () => {
            //     message.warn('当前邮箱已被其他账号绑定，确认绑定后系统将迁移绑定关系至当前账号')
            //   }
            // }}
            form={form}
            codeType='BIND'
          />
        )}
        {visible === '手机' && (
          <PhoneVerification
            phonePlaceholder='请填写想要绑定的手机号'
            phoneEmptyMsg='手机号不能为空'
            phoneErrorMsg='请填写正确格式的手机号'
            verificationCodePlaceholder='请输入您收到的手机验证码'
            verificationCodeEmptyMsg='验证码不能为空'
            verificationCodeErrorMsg='请填写正确格式的验证码'
            // verificationExistOptions={{
            //   hasExist: true,
            //   field: 'phone',
            //   callback: () => {
            //     message.warn('当前手机号已被其他账号绑定，确认绑定后系统将迁移绑定关系至当前账号')
            //   }
            // }}
            form={form}
            codeType='BIND'
          />
        )}
        <div className='bind-btns-row'>
          <CancelButton shape='circle' onClick={() => handleCancelModal()}>
            取消
          </CancelButton>
          <ConfirmButton
            type='primary'
            shape='circle'
            style={{ marginLeft: 10 }}
            onClick={() => handleBind()}
            loading={loading}
          >
            确认绑定
          </ConfirmButton>
        </div>
      </StyledBindModalForm>
    </StyledBindModalContainer>
  )
}

const StyledBindModalForm = styled(Form)`
  padding: 10px;
  .ant-form-item {
    height: 68px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bind-btns-row {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
`

const StyledBindModalContainer = styled(BaseModal)``

export default observer(BindModal)
