import React, { FC, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { MainTestButton, TextButton } from 'src/components/base-button'
import { getAuth } from 'src/tools/auth'
import {
  getCheatsConfigByTrainerIdHttp,
  saveCheatsConfigHttp,
  injectStartHttp,
  injectStatusChangeHttp,
} from 'src/apis/user-api'
import { getAdminGlobalConfig, reportLogHttp } from 'src/apis/cms-api'
import {
  GameStore,
  LoginStore,
  RunningStore,
  StartingStore,
  ProtocolStore,
  HistoryStore,
  ClientLocalStorageStore,
} from 'src/store/modifier'
import { ConfigStore, appStore, VipStore } from 'src/store'
import {
  PLATFORM_MAPPING,
  BUFF_ERROR_CODE,
  EDIT_ITEM_CLASS_MAPPING,
  DOMID,
  MODIFIER_DETAIL_TABLE_Y,
  DETAIL_INDEX_HEIGHT,
} from 'src/constants/common'
import { message } from 'antd'
import { cryptoByAES, getFirstPageChannel } from 'src/tools/common'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { emitter } from 'src/tools/emitter'
import {
  StyledBottom,
  StyledContent,
  StyledLeftContent,
  StyledMainButton,
  StyledMainButtonContent,
  StyledRightContent,
} from 'src/pages/game-detail/trial'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import CEFTransfer from 'src/CEFTransfer'
import PrereadModal from 'src/pages/game-detail/modifier/preread-modal'
import ForceBindModal from 'src/pages/game-detail/modifier/force-bind-modal'
import EditBox from 'src/pages/game-detail/modifier/edit-box'
import PreRead from 'src/pages/game-detail/modifier/preread'
import SeleteProcessModal from 'src/pages/game-detail/modifier/find-process-modal'
import Checkbox from 'src/components/base-checkbox'
import styled from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'
import LoadingIcon from 'src/assets/icons/my-game/spinner.svg'
import BuffIcon from 'src/assets/icons/my-game/buff.svg'

export const normalizeCheats = (
  cheats: Modifier.TCheats[],
  lastTimeCheatsConfig?: Modifier.TSaveCheatsConfigHttp['cheatsList'],
) => {
  let target: Modifier.TCheats[][] = []
  // 获取分类集合
  const categorys = Object.keys(EDIT_ITEM_CLASS_MAPPING) as Modifier.TCheatsCategory[]
  // 每个修改项的热键以及值
  const cheatsValues: Record<string, Modifier.TCheatsValue> = {}
  for (let i = 0; i < cheats.length; i++) {
    /*********************** 生成每个修改项的 热键 和 值 配置 start */

    // 查找之前有没有保存过， 保存过就直接用上次的， 没有的话按正常逻辑生成
    const currentLastTimeCheatsConfig = lastTimeCheatsConfig?.find(f => f.uuid === cheats[i].uuid)
    if (currentLastTimeCheatsConfig) {
      cheatsValues[cheats[i].uuid] = {
        ...cheats[i],
        hotkeys: currentLastTimeCheatsConfig.hotkeys,
        value: currentLastTimeCheatsConfig.val,
      }
    } else {
      let value: number | undefined = 0
      switch (cheats[i].type) {
        case 'number':
          value = cheats[i].args?.min || 0
          break
        case 'slider':
          value = cheats[i].args?.min || 0
          break
        case 'selection':
          value = undefined
          break
      }
      cheatsValues[cheats[i].uuid] = {
        ...cheats[i],
        // 这个赋值主要是用于深克隆， 必须！
        hotkeys: cheats[i].hotkeys.map(d => [...d]),
        value,
      }
    }
    /*********************** 生成每个修改项的 热键 和 值 配置 end */

    /*********************** 格式化修改项的数据结构，方便渲染 start */
    const resultIndex = categorys.findIndex(category => category === cheats[i].category)
    if (target[resultIndex]) {
      target[resultIndex].push(cheats[i])
    } else {
      target[resultIndex] = [cheats[i]]
    }
    /*********************** 格式化修改项的数据结构，方便渲染 end */
  }
  return {
    // 每个修改项的 热键 和 值 的配置
    cheatsValues,
    // 优化过的数据结构， 方便渲染
    cheatList: target.filter(Boolean),
  }
}

const StyledSecuritySoftwareContainer = styled.div`
  width: 509px;
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 7px 7px 7px 7px;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  .security-software-title {
    font-size: 14px;
    line-height: 14px;
    font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    margin-bottom: 12px;
  }
  .security-software-content {
    display: flex;
    flex-wrap: wrap;
    .security-software-app-box {
      ${props => props.theme.flexCenter}
      padding: 12px 8px;
      height: 32px;
      background: ${props => props.theme.color.bg.$FFFFFF06};
      border-radius: 32px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 14px;
      line-height: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: var(--text-color-FFFFFF);
      margin-right: 8px;
      margin-bottom: 8px;
      .security-software-app-icon {
        margin-right: 8px;
      }
    }
  }
`

export const errDescMap = {
  '1005': '未关闭杀毒软件或未按照运行帮助替换反修改文件可能会造成此错误',
  '1016': '使用破解版或者非官方汉化版时可能会出现此错误',
}

const Detail: FC<{ trainerTitleId?: string | number; gameLibraryName: string; gamePlatformName: string }> = ({
  trainerTitleId,
  gameLibraryName,
  gamePlatformName,
}) => {
  const navigate = useNavigate()
  // 从什么地方跳进来的
  const [referrer, setReferrer] = useState<string | null>(null)
  const [findProcessObject, setFindProcessObject] =
    useState<{ _res: Function; _rej: Function; list: Modifier.TProcessItem[] }>()
  const [agreeProtocol, setAgreeProtocol] = useState<boolean>(ClientLocalStorageStore.getHoldHarmlessProtocolCache())
  const [cheatsNeedSave, setCheatsNeedSave] = useState<boolean>(false)
  const [forceBindVisible, setForceBindVisible] = useState<{ callback: Function } | null>(null)
  const [prereadVisible, setPrereadVisible] = useState<((value: any) => void) | null>(null)
  const [errorCode, setErrorCode] = useState<string>('')
  const [modifierDetailTableY, setModifierDetailTableY] = useState(502)
  // 本地的每个修改项的热键以及值
  const [cheatsValues, setCheatsValues] = useState<Record<string, Modifier.TCheatsValue> | null>(null)
  // 修改项列表
  const [cheatList, setCheatList] = useState<Modifier.TCheats[][]>([])
  // 当前页面显示的修改器对象
  const currentActiveTrainerInfo = GameStore.activeTrainerInfo
  // 当前页面显示的title对象
  const currentActiveTitleInfo = GameStore.activeTitleInfo
  // 当前页面显示的game对象
  const currentActiveGameSimpleInfo = GameStore.activeGameSimpleInfo
  // 当前游戏正在更新中
  // 当前游戏正在运行中
  // const currentIsUpdate = currentActiveGameSimpleInfo?.flags ? !((currentActiveGameSimpleInfo.flags & 4) > 0) : false
  const currentIsRunning =
    RunningStore.trainerInfo && currentActiveTrainerInfo && currentActiveTrainerInfo.id === RunningStore.trainerInfo.id
  // 当前游戏正在启动中
  const currentIsStarting =
    StartingStore.trainerInfo &&
    currentActiveTrainerInfo &&
    currentActiveTrainerInfo.id === StartingStore.trainerInfo.id

  const finalNotes = currentActiveTrainerInfo?.blueprint.zhCnNotes || currentActiveTrainerInfo?.blueprint.notes || ''

  const titleId = useMemo(() => {
    const id = trainerTitleId ? parseInt(`${trainerTitleId}`) : NaN
    if (!isNaN(id)) {
      return id
    }
    return null
  }, [trainerTitleId])

  const currentIsUpdate = useMemo(() => {
    if (!currentActiveGameSimpleInfo?.flags) {
      return false
    }
    if ((currentActiveGameSimpleInfo.flags & 3) !== 0) {
      return true
    }
    return (currentActiveGameSimpleInfo.flags & 4) <= 0
  }, [currentActiveGameSimpleInfo])

  //需要测试(游戏有更新，修改器可能可以用，用不了请等待修改)
  const currentIsUnknown = useMemo(() => {
    if (!currentActiveGameSimpleInfo?.flags) {
      return false
    }
    return (currentActiveGameSimpleInfo.flags & 32) !== 0
  }, [currentActiveGameSimpleInfo])

  // 是否收藏
  // const isCollect = useMemo(() => {
  //   return titleId ? CollectGameStore.isCollect(titleId) : false
  // }, [titleId, CollectGameStore.collectGameList])

  // const handleChangeCollect = async () => {
  //   if (!LoginStore.userinfo) {
  //     return LoginStore.openLoginModal()
  //   }
  //   if (titleId) {
  //     CollectGameStore.changeCollect(titleId)
  //   }
  // }

  const handleCancelInjectBuff = async () => {
    RunningStore.normalStopInjectBuff(true)
    // if (!RunningStore.cheatsValues || !RunningStore.trainerInfo) {
    //   return message.warn('环境错误')
    // }
    // try {
    //   const isConfirm = await imperativeConfirmModal({
    //     title: '提示',
    //     content: '尊敬的MyBuff用户，请再次确认您要结束当前游戏的Buff加持',
    //     okText: '取消',
    //     cancelText: '确认停止',
    //   })
    //   if (isConfirm.value || isConfirm.target === 'close') {
    //     throw '用户取消结束'
    //   }
    //   if (RunningStore.cheatsNeedSave) {
    //     const currentCheatsValues = Object.values(RunningStore.cheatsValues!)
    //       .filter(f => f.type !== 'selection')
    //       .map(d => {
    //         return {
    //           uuid: d.uuid,
    //           hotkeys: d.hotkeys,
    //           val: d.value,
    //         }
    //       })
    //     await saveCheatsConfigHttp({ trainerId: RunningStore.trainerInfo!.id, cheatsList: currentCheatsValues })
    //   }
    //   CEFTransfer.sendToClient('stopBuff')
    //   RunningStore.clearRunningInfo()
    //   message.success('Buff加持结束，感谢您的使用')
    // } catch (error) {}
  }

  const handleInjectBuff = async () => {
    setErrorCode('')
    let tempErrorCode = ''
    /** 判断是否登录 */
    if (!LoginStore.userinfo) {
      return LoginStore.openLoginModal()
    }

    if (!currentActiveTrainerInfo || !currentActiveTitleInfo || !currentActiveGameSimpleInfo || !cheatsValues) {
      return message.warn('网络错误')
    }
    if (!agreeProtocol) {
      return message.warn('请阅读并同意《MyBuff免责使用说明》')
    }

    ClientLocalStorageStore.setLatelyLaunchGameIds(currentActiveGameSimpleInfo.id)

    emitter.emit('report-log', {
      params: {
        encrypt: cryptoByAES(
          JSON.stringify({
            userId: LoginStore.userinfo.userId,
            gameId: currentActiveTrainerInfo.gameId,
            channel: getFirstPageChannel(),
            type: 'inject',
            code: 1000,
            action: 'click-start-btn',
          }),
        ),
      },
    })

    /***  核心启动逻辑 start */
    StartingStore.setStartingInfo({
      userId: LoginStore.userinfo.userId,
      titleInfo: currentActiveTitleInfo,
      trainerInfo: currentActiveTrainerInfo,
      gameSimpleInfo: currentActiveGameSimpleInfo,
      gameLibraryName,
      gamePlatformName,
      cheatsNeedSave,
      cheatsValues,
    })

    try {
      // 判断是否会员
      try {
        await VipStore.handleLoadVipInfo()
      } catch (error) {
        throw '获取会员信息失败'
      }
      if (!VipStore.memberinfo?.isMember && !VipStore.isInTrainerFreeTimeRange()) {
        const isConfirm = await imperativeConfirmModal({
          title: '加持 BUFF 失败提示',
          content: (
            <div>
              <div>游戏修改为会员特权功能，开通 MYBUFF 会员，全部游戏全天不限次数随意使用</div>
            </div>
          ),
          okText: '开通会员',
          cancelText: '我知道了',
        })
        if (isConfirm.value) {
          // navigate('/vip-recharge')
          showUserCenter(NAV_KEY_MAPPING.VIP)
        }
        throw '用户不是会员'
      }
      const hasOpenSecuritySoftware = await CEFTransfer.sendToClient('checkProcessList', {
        processList: ConfigStore.securitySoftwareConfig.map(d => d.process),
      })
      if (hasOpenSecuritySoftware.length > 0) {
        imperativeConfirmModal({
          title: '加持Buff提示',
          width: 555,
          content: (
            <div>
              系统检测到您电脑上有处于运行状态的杀毒软件，
              <span style={{ color: `rgba(225, 50, 50, 1)` }}>
                游戏修改会进行内存操作将被认定为危险操作，导致修改操作被拦截
              </span>
              ，请先退出对应软件后再进行游戏修改
            </div>
          ),
          btns: ['ok'],
          okText: '我知道了',
          extra: (
            <StyledSecuritySoftwareContainer>
              <div className='security-software-title'>正在运行的杀毒软件</div>
              <div className='security-software-content'>
                {hasOpenSecuritySoftware.map((d: string) => {
                  const temp = ConfigStore.securitySoftwareConfig.find(f => f.process === d)
                  if (!temp) {
                    return null
                  }
                  return (
                    <div className='security-software-app-box'>
                      <div className='security-software-app-icon'>
                        <FadeinImage width={16} height={16} src={temp.icon} />
                      </div>
                      <div className='security-software-app-text'>{temp.name}</div>
                    </div>
                  )
                })}
              </div>
            </StyledSecuritySoftwareContainer>
          ),
        })
        throw '用户开启了杀毒软件'
      }

      console.log(hasOpenSecuritySoftware, 'hasOpenSecuritySoftware')

      // const isConfirm = await imperativeConfirmModal({
      //   title: '提示',
      //   content: '是否要结束正在加持的Buff，重新进行当前游戏的Buff加载',
      //   okText: '确认切换',
      //   cancelText: '取消',
      // })

      const { result: isForceBind } = await getAdminGlobalConfig({
        channel: 'all',
        moduleName: 'user',
        keyName: 'force_bind_user_info',
      })
      if (
        isForceBind.enable &&
        isForceBind.value === '1' &&
        LoginStore.userBaseInfo &&
        LoginStore.userBaseInfo.bindEmail !== 1 &&
        LoginStore.userBaseInfo.bindMobile !== 1
      ) {
        const promise = new Promise((res, rej) => {
          setForceBindVisible({
            callback: res,
          })
        })
        const hasBind = await promise
        if (hasBind === 'cancel') {
          throw '用户取消绑定手机号或者邮箱'
        }
        LoginStore.getUserBaseInfo()
      }

      if (
        currentActiveTrainerInfo.needReadHelp === 1 &&
        !ClientLocalStorageStore.getNotTipsPrereadGameIds().includes(currentActiveTrainerInfo.gameId) &&
        finalNotes
      ) {
        const isRead = await handleOpenPrereadModal()
        if (!isRead) {
          throw '用户取消阅读修改须知'
        }
      }

      console.log(RunningStore.hasRunning, 'RunningStore.hasRunning')
      // 判断是否有其他游戏正在加持中
      if (RunningStore.hasRunning) {
        const isConfirm = await imperativeConfirmModal({
          title: '提示',
          content: '是否要结束正在加持的Buff，重新进行当前游戏的Buff加载',
          okText: '确认切换',
          cancelText: '取消',
        })
        if (!isConfirm.value) {
          throw '用户切换加持取消'
        }
        if (RunningStore.hasRunning) {
          if (RunningStore.cheatsNeedSave) {
            const currentCheatsValues = Object.values(RunningStore.cheatsValues!).map(d => {
              return {
                uuid: d.uuid,
                hotkeys: d.hotkeys,
                val: d.value,
              }
            })
            await saveCheatsConfigHttp({ trainerId: RunningStore.trainerInfo!.id, cheatsList: currentCheatsValues })
          }
          injectStatusChangeHttp({
            startNo: RunningStore.currentStartNo,
            injectStatusEnum: 'stop_buff',
            code: '19997',
            message: '用户切换加BUFF',
          })
          CEFTransfer.sendToClient('stopBuff')
          RunningStore.clearRunningInfo()
        }
      }

      let injectProcess: Modifier.TInjectProcess
      // 之前成功过的进程名
      const preTrainerExe = ClientLocalStorageStore.getTrainerExe(currentActiveTrainerInfo.id)
      console.log('getProcess')
      const processInfo: { processName: string; pid: number } | null = await CEFTransfer.sendToClient(
        'getGameProcessInfo',
        {
          // 之前启动成功过的进程名  string | null
          preTrainerExe: preTrainerExe || null,
          // 后台配置的版本路径  string | null
          versionPath: currentActiveGameSimpleInfo.versionPath || null,
          // 后台配置的文件路径  string | null
          image: currentActiveTrainerInfo.blueprint.config?.target?.image || null,
          // 后台配置的进程名    string | null
          gameExe: currentActiveTrainerInfo.blueprint.config?.launch?.proxy?.gameExe || null,
          // 后台配置的对应平台的appid       格式为  {platformId}:{appid},{platformId}:{appid}
          appid: currentActiveGameSimpleInfo.correlationIds?.join(',') || null,
        },
      )

      if (processInfo) {
        injectProcess = {
          type: 'name',
          exeName: processInfo.processName,
          pid: processInfo.pid,
        }
      } else {
        const params = await CEFTransfer.sendToClient('getProcessList')
        const processList = params as Modifier.TProcessItem[]
        const findProcessPromise = new Promise((res, rej) => {
          setFindProcessObject({
            _res: res,
            _rej: rej,
            list: processList,
          })
        })
        try {
          const findProcessResult = (await findProcessPromise) as Modifier.TInjectProcess
          console.log(findProcessResult, 'findProcessResult')
          // 这里， type为path的话 代表用户选择的是文件， 所以等会启动buff的时候 需要把文件路径传递给客户端
          // type为name的话， 代表用户选择的是正常运行的进程名，  等会启动buff的时候直接把用户选中的pid传递给客户端
          if (findProcessResult.type === 'name') {
            injectProcess = {
              type: 'name',
              exeName: findProcessResult.exeName,
              pid: findProcessResult.pid,
            }
          } else {
            injectProcess = {
              type: 'path',
              exeName: findProcessResult.exeName,
              path: findProcessResult.path,
            }
          }
          setFindProcessObject(undefined)
        } catch (error) {
          // 弹框被取消了会走这里
          setFindProcessObject(undefined)
          throw '用户取消选择进程'
        }
      }

      console.log(currentActiveTrainerInfo?.fwgType, 'currentActiveTrainerInfo?.fwgType')

      // 判断当前游戏是需要反外挂， 弹出提示
      if (currentActiveTrainerInfo?.fwgType) {
        const checkAnticheatResult = await CEFTransfer.sendToClient('checkAnticheat', {
          fwgType: currentActiveTrainerInfo?.fwgType,
        })
        if (checkAnticheatResult.fwgType === 1) {
          const isConfirm = await imperativeConfirmModal({
            title: '提示',
            content: (
              <div>
                由于游戏特性，该游戏使用修改器后将
                <span className='primary-text'>无法使用在线模式!</span>
                请再次确认是否开启修改器，若您正在游戏中，平台将帮您自动结束游戏并重启，请注意！
              </div>
            ),
            okText: '确认开启',
            cancelText: '下次再说',
          })
          if (!isConfirm.value) {
            throw '用户加持反外挂取消'
          }
        }
      }

      // console.log(
      //   CollectGameStore.isCollect(currentActiveTitleInfo.id),
      //   'CollectGameStore.isCollect(currentActiveTitleInfo.id)',
      // )
      // 判断是否被收藏， 没有被收藏需要调用收藏接口
      // if (!CollectGameStore.isCollect(currentActiveTitleInfo.id)) {
      //   CollectGameStore.changeCollect(currentActiveTitleInfo.id)
      // }

      // 启动 buff 需要的进程相关的参数
      const injectProcessValues: { pid: string | null; filepath: string | null } =
        injectProcess.type === 'name'
          ? {
              pid: `${injectProcess.pid}`,
              filepath: null,
            }
          : {
              pid: null,
              filepath: injectProcess.path!,
            }

      if (!LoginStore.userinfo) {
        throw '用户退出登录'
      }

      emitter.emit('report-log', {
        params: {
          encrypt: cryptoByAES(
            JSON.stringify({
              userId: LoginStore.userinfo.userId,
              gameId: currentActiveTrainerInfo.gameId,
              channel: getFirstPageChannel(),
              type: 'inject',
              code: 1000,
              action: 'start-inject',
            }),
          ),
        },
      })

      const { result } = await injectStartHttp({
        gameId: currentActiveTrainerInfo.gameId,
        type: 'client',
      })

      StartingStore.currentStartNo = result.startNo
      StartingStore.startHeartBeat(result.startNo)

      const startResult = await CEFTransfer.sendToClient('startBuff', {
        ...injectProcessValues,
        orderid: result.startNo,
        gameid: `${currentActiveTrainerInfo.gameId}`,
        dllpath: `${currentActiveTrainerInfo.loaderArgs.dllUrl}`,
        dllmd5: `${currentActiveTrainerInfo.loaderArgs.dllMd5}`,
        token: getAuth(),
        calls: currentActiveTrainerInfo.blueprint.cheats.map(d => d.target).join(','),
        callsize: currentActiveTrainerInfo.blueprint.cheats.length,
        appid: currentActiveGameSimpleInfo.correlationIds?.join(',') || null,
        anticheat: currentActiveTrainerInfo.fwgType || null,
        cliargs: currentActiveTrainerInfo.blueprint.config?.launch?.cliArgs || null,
      })

      emitter.emit('report-log', {
        params: {
          encrypt: cryptoByAES(
            JSON.stringify({
              userId: LoginStore.userinfo.userId,
              gameId: currentActiveTrainerInfo.gameId,
              channel: getFirstPageChannel(),
              type: 'inject',
              code: startResult.code,
              action: 'end-inject',
            }),
          ),
        },
      })

      if (!LoginStore.userinfo) {
        CEFTransfer.sendToClient('stopBuff')
        throw '用户退出登录'
      }

      if (startResult.code === 1000) {
        ClientLocalStorageStore.setTrainerExe(currentActiveTrainerInfo.id, injectProcess.exeName)
        StartingStore.startSuccess()
        message.success('Buff加持成功，畅享游戏之旅')
        ClientLocalStorageStore.setHoldHarmlessProtocolCache('1')
        return
      }

      if (startResult.code === 1002) {
        // 客户端返回了鉴权失败之后， 前端判断下是什么原因 然后做出相应处理
        try {
          await VipStore.handleLoadVipInfo()
        } catch (error) {
          throw '获取会员信息失败'
        }
        if (!VipStore.memberinfo?.isMember && !VipStore.isInTrainerFreeTimeRange()) {
          const isConfirm = await imperativeConfirmModal({
            title: '加持 BUFF 失败提示',
            content: (
              <div>
                <div>游戏修改为会员特权功能，开通 MYBUFF 会员，全部游戏全天不限次数随意使用</div>
              </div>
            ),
            okText: '开通会员',
            cancelText: '我知道了',
          })
          if (isConfirm.value) {
            // navigate('/vip-recharge')
            showUserCenter(NAV_KEY_MAPPING.VIP)
          }
        }
        throw '用户不是会员'
      }
      setErrorCode(startResult.code)
      tempErrorCode = startResult.code
      throw BUFF_ERROR_CODE[startResult.code as keyof typeof BUFF_ERROR_CODE] || '未知错误'
    } catch (error) {
      const msg = typeof error === 'string' ? error : (error as Error).message
      console.log(error)
      if (StartingStore.currentStartNo) {
        injectStatusChangeHttp({
          startNo: StartingStore.currentStartNo,
          injectStatusEnum: 'stop_buff',
          code: tempErrorCode ? `1${tempErrorCode}` : '19999',
          message: msg || '未知异常',
        })
      }
      StartingStore.clearStartingInfo()
    }

    /***  核心启动逻辑 end */
  }

  const handleInitCheatsValues = async () => {
    if (!currentActiveTrainerInfo) {
      setCheatsValues(null)
      setCheatList([])
      return
    }
    if (!getAuth()) {
      const { cheatsValues, cheatList } = normalizeCheats(currentActiveTrainerInfo.blueprint.cheats)
      setCheatsValues(cheatsValues)
      setCheatList(cheatList)
      return
    }
    // 这是当前正在运行的游戏 所以直接把正在运行的游戏的修改项热照赋值过来
    if (currentIsRunning) {
      const { cheatList } = normalizeCheats(currentActiveTrainerInfo.blueprint.cheats)
      setCheatsValues(RunningStore.cheatsValues)
      setCheatList(cheatList)
      return
    }
    // // 这是当前正在启动中的游戏 所以直接把正在启动中的游戏的修改项的热照赋值过来
    // if (currentIsStarting) {
    //   const { cheatList } = normalizeCheats(
    //     currentActiveTrainerInfo.blueprint.cheats
    //   )
    //   setCheatsValues(StartingStore.cheatsValues)
    //   setCheatList(cheatList)
    //   return
    // }
    const { result } = await getCheatsConfigByTrainerIdHttp({
      trainerId: currentActiveTrainerInfo.id,
    })

    /**
     * 这里这个判断
     * 主要是 currentActiveTrainerInfo 变量为闭包， 为当前上下文里面确定的 当前正在显示的修改器对象
     * 而 GameStore.activeTrainerInfo 变量GameStore下面的属性， 是不存在闭包的，
     * 所以这个判断用于 假如接口请求还没有回来 但是当前显示的修改器对象已经变化了， 这时候就不需要再为这个修改器赋值了
     */
    if (currentActiveTrainerInfo.id === currentActiveTrainerInfo.id) {
      const { cheatsValues, cheatList } = normalizeCheats(currentActiveTrainerInfo.blueprint.cheats, result)
      setCheatsValues(cheatsValues)
      setCheatList(cheatList)
    }
  }

  const handleForceBindCancel = async () => {
    if (forceBindVisible) {
      forceBindVisible.callback('cancel')
      setForceBindVisible(null)
    }
  }

  const handleForceBindSuccess = async () => {
    if (forceBindVisible) {
      forceBindVisible.callback('success')
      setForceBindVisible(null)
    }
  }

  const handleOpenPrereadModal = async () => {
    const promise = new Promise((res, rej) => {
      setPrereadVisible(() => res)
    })
    const result = await promise
    return result === 'success'
  }

  const handlePrereadCancel = async () => {
    prereadVisible && prereadVisible('cancel')
    setPrereadVisible(null)
  }

  const handlePrereadSuccess = async (notTips: boolean) => {
    if (notTips) {
      ClientLocalStorageStore.setNotTipsPrereadGameIds(currentActiveTrainerInfo!.gameId)
    }
    prereadVisible && prereadVisible('success')
    setPrereadVisible(null)
  }

  const errorDesc = useMemo(() => {
    return errDescMap[errorCode as keyof typeof errDescMap] || ''
  }, [errorCode])

  useEffect(() => {
    if (titleId && GameStore.activeTitleId !== titleId) {
      GameStore.changeActiveTitle(titleId)
    }
    return () => {
      GameStore.changeActiveTitle(null)
    }
  }, [titleId, GameStore.titleList])

  useEffect(() => {
    setAgreeProtocol(
      ClientLocalStorageStore.getHoldHarmlessProtocolCache() || !!currentIsRunning || !!currentIsStarting,
    )
    setCheatsNeedSave(
      (!!currentIsRunning && RunningStore.cheatsNeedSave) || (!!currentIsStarting && StartingStore.cheatsNeedSave),
    )
  }, [currentActiveTrainerInfo, currentIsRunning, currentIsStarting])

  useEffect(() => {
    handleInitCheatsValues()
    return () => {
      setCheatsValues(null)
      setCheatList([])
    }
  }, [getAuth(), currentActiveTrainerInfo, currentIsRunning])

  useEffect(() => {
    setErrorCode('')
  }, [currentActiveTrainerInfo])

  useEffect(() => {
    setReferrer(HistoryStore.form)
  }, [])

  // useEffect(() => {
  //   if (GameStore.activeTitleInfo?.detailLongImg) {
  //     appStore.layoutBg = GameStore.activeTitleInfo.detailLongImg
  //     appStore.layoutBgType = 'modifier'
  //     return () => {
  //       appStore.layoutBg = null
  //       appStore.layoutBgType = null
  //     }
  //   }
  // }, [GameStore.activeTitleInfo])

  return (
    <>
      <StyledDetailEditContainer>
        <StyledContent>
          <StyledLeftContent>
            {/* <div className='detail-edit-header' id={MODIFIER_DETAIL_TABLE_Y.EDIT_HEADER_ID}>
          <div className='detail-platform-tabs'>
            {GameStore.activeGameSimpleList.map(d => {
              const platformInfo = PLATFORM_MAPPING[d.platformId]
              return (
                <div
                  key={`detail-platform-${d.id}`}
                  className={classNames(MODIFIER_DETAIL_TABLE_Y.EDIT_HEADER_ITEM_CLASS, {
                    active: GameStore.activeGameId === d.id,
                  })}
                  onClick={() => GameStore.changeActiveGame(d.id)}
                >
                  <platformInfo.Icon />
                  {platformInfo.alias}
                  {d.edition ? ` ${d.edition}` : ''}
                </div>
              )
            })}
          </div>
          <div className='detail-edit-tips'>
            共支持{GameStore.activeGameSimpleInfo?.trainer?.cheatCount || '--'}项修改
          </div>
        </div> */}
            <div className='detail-edit-content'>
              {currentActiveTrainerInfo && cheatsValues && (
                <>
                  <div className='detail-edit-tips'>
                    共支持<span className='main'>{GameStore.activeGameSimpleInfo?.trainer?.cheatCount || '--'}</span>
                    项修改
                  </div>
                  <PreRead notes={finalNotes} />
                  <EditBox
                    currentIsRunning={!!currentIsRunning}
                    trainerId={currentActiveTrainerInfo?.id || null}
                    cheatList={cheatList}
                    cheatsValues={currentIsRunning ? RunningStore.cheatsValues : cheatsValues}
                  />
                </>
              )}
            </div>
          </StyledLeftContent>
          <StyledUsePanlsContainer>
            <div className='title'>使用说明</div>
            <div className='description-item'>
              <div className='content'>
                <div className='use-description'>
                  <div className='description-step'>
                    <div className='text'>
                      <div className='text-item'>退出杀毒软件并关闭Windows Defender</div>
                      <div className='text-item primary'>
                        <TextButton onClick={() => ProtocolStore.openProtocolModal('closeWindowsDefender')}>
                          {ProtocolStore.protocolConfig['closeWindowsDefender']?.title}
                        </TextButton>
                      </div>
                    </div>
                  </div>
                  <div className='description-step'>
                    <div className='text'>
                      <div className='text-item'>启动游戏</div>
                      <div className='text-item primary'>注：非正版游戏修改可能无法生效</div>
                    </div>
                  </div>
                  <div className='description-step'>
                    <div className='text'>
                      <div className='text-item'>游戏运行后点击“一键加BUFF”按钮</div>
                    </div>
                  </div>
                  <div className='description-step'>
                    <div className='text'>
                      <div className='text-item'>等待提示“BUFF加持成功”后，可开始修改</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='title'>功能设置</div>
            <div className='description-item'>
              <div className='content'>
                <Checkbox
                  disabled={!!(currentIsRunning || currentIsStarting)}
                  checked={cheatsNeedSave}
                  onChange={e => setCheatsNeedSave(e.target.checked)}
                >
                  修改器生效后下次启动默认开启上次修改的配置
                </Checkbox>
              </div>
            </div>
            <div className='title'>免责协议</div>
            <div className='description-item'>
              <div className='content'>
                <Checkbox
                  disabled={!!(currentIsRunning || currentIsStarting)}
                  checked={agreeProtocol}
                  onChange={e => setAgreeProtocol(e.target.checked)}
                >
                  我已阅读并充分理解
                </Checkbox>
                <TextButton
                  onClick={e => {
                    e.preventDefault()
                    ProtocolStore.openProtocolModal('mybuffExemption')
                  }}
                >
                  《{ProtocolStore.protocolConfig['mybuffExemption']?.title}》
                </TextButton>
              </div>
            </div>
          </StyledUsePanlsContainer>
        </StyledContent>

        <StyledBottom2>
          <div className='error-text'>
            {errorCode && (
              <>
                Buff注入失败，错误码{errorCode}
                {errorDesc && (
                  <>
                    <br />
                    {errorDesc}
                  </>
                )}
              </>
            )}
          </div>

          <StyledMainButton
            onClick={() => !currentIsStarting && !currentIsUpdate && (currentIsRunning ? handleCancelInjectBuff() : handleInjectBuff())}
            className={classNames('action-main-btn', {
              'is-unknown': currentIsUnknown,
              'start-btn-speeding': currentIsStarting,
              'start-btn-speeded': currentIsRunning,
              disabled: currentIsUpdate || currentIsStarting,
            })}
          >
            <StyledMainButtonContent2
              className={classNames({
                column:
                  currentIsUpdate || (currentIsUnknown && !currentIsRunning && !currentIsStarting && !currentIsUpdate),
              })}
            >
              {currentIsUpdate && (
                <>
                  <div className='action-main-btn-updatetitle'>修改器更新中</div>
                  <div className='action-main-btn-updatedesc'>暂时无法加持Buff</div>
                </>
              )}
              {currentIsUnknown && !currentIsRunning && !currentIsStarting && !currentIsUpdate && (
                <>
                  <div className='action-main-btn-updatetitle'>修改器更新中</div>
                  <div className='action-main-btn-updatedesc'>修改成功可能无法生效请谨慎使用</div>
                </>
              )}
              {
                currentIsStarting && (
                  <>
                    <LoadingIcon className='icon-loading' />
                    <span>B U F F 加 持 中</span>
                  </>
                )
              }
              {currentIsRunning && '结 束 B U F F 加 持'}
              {!currentIsRunning && !currentIsStarting && !currentIsUpdate && !currentIsUnknown && <><BuffIcon className='icon'/><span>一 键 加 B U F F</span></>}
            </StyledMainButtonContent2>
          </StyledMainButton>
        </StyledBottom2>
      </StyledDetailEditContainer>
      <SeleteProcessModal processObject={findProcessObject} />
      <ForceBindModal visible={forceBindVisible} onCancel={handleForceBindCancel} onSuccess={handleForceBindSuccess} />
      <PrereadModal
        visible={!!prereadVisible}
        onCancel={handlePrereadCancel}
        onSuccess={handlePrereadSuccess}
        content={currentActiveTrainerInfo?.blueprint.zhCnNotes || currentActiveTrainerInfo?.blueprint.notes || ''}
      />
    </>
  )
}

const StyledMainButtonContent2 = styled(StyledMainButtonContent)`
  &.column {
    flex-direction: column;
    .action-main-btn-updatetitle {
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      //color: var(--text-color-FFFFFF);
    }
    .action-main-btn-updatedesc {
      font-size: 14px;
      line-height: 14px;
      //color: #d7d7d7;
      margin-top: 6px;
      font-weight: normal;
    }
  }
`

const StyledBottom2 = styled(StyledBottom)`
  .error-text {
    margin-left: 32px;
    font-size: 12px;
    line-height: 16px;
    color: #f46242;
  }
`

// const StyledLoadingBox = styled.div`
//   position: relative;
//   width: 50%;
//   height: 38px;
//   .loading-item {
//     position: absolute;
//     left: 0%;
//     transform-origin: 60%;
//     animation: circle 1.2s alternate infinite ease;
//   }
//   @keyframes circle {
//     0% {
//       top: 4px;
//     }
//     25% {
//       top: -3px;
//     }
//     50% {
//       top: 0;
//     }
//   }
//   .loading-item:nth-child(1) {
//     left: 0%;
//     animation-delay: 0.22s;
//   }
//   .loading-item:nth-child(2) {
//     left: 10%;
//     animation-delay: 0.32s;
//   }
//   .loading-item:nth-child(3) {
//     left: 20%;
//     animation-delay: 0.42s;
//   }
//   .loading-item:nth-child(4) {
//     left: 30%;
//     animation-delay: 0.52s;
//   }
//   .loading-item:nth-child(5) {
//     left: 40%;
//     animation-delay: 0.62s;
//   }
//   .loading-item:nth-child(6) {
//     left: 55%;
//     animation-delay: 0.72s;
//   }
//   .loading-item:nth-child(7) {
//     left: 70%;
//     animation-delay: 0.82s;
//   }
//   .loading-item:nth-child(8) {
//     left: 85%;
//     animation-delay: 0.92s;
//   }
//   .loading-item:nth-child(9) {
//     left: 95%;
//     animation-delay: 1.2s;
//   }
//   .loading-item:nth-child(10) {
//     left: 105%;
//     animation-delay: 1.3s;
//   }
// `
const StyledUsePanlsContainer = styled(StyledRightContent)`
  padding: 32px;
  .title {
    font-size: 16px;
    font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    line-height: 1;
    margin-bottom: 16px;
  }
  .error-text {
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    color: #f46242;
  }  
  .description-item {
    margin-bottom: 40px;
    .title {
      margin-bottom: 20px;
      line-height: 20px;
      font-size: 20px;
      font-weight: bold;
      color: var(--text-color-FFFFFF);
    }
    .content {
      .primary {
        color: ${props => props.theme.color.text.$C598FF};
      }
      .use-description {
        .description-step {
          padding-left: 42px;
          display: flex;
          align-items: flex-start;
          height: 72px;
          position: relative;
          &:last-child {
            height: 28px;
          }
          &::before {
            ${props => props.theme.flexCenter}
            color: rgba(255, 255, 255, 0.65);
            position: absolute;
            top: 0px;
            left: -2px;
            font-size: 12px;
            line-height: 12px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.32);
          }
          &:nth-child(1) {
            &::before {
              content: '1';
            }
          }
          &:nth-child(2) {
            &::before {
              content: '2';
            }
            &::after {
              height: 39px !important;
            }
          }
          &:nth-child(3) {
            &::before {
              content: '3';
              top: -5px;
            }
            &::after {
              top: 23px !important;
              //height: 44px !important;
            }
          }
          &:nth-child(4) {
            &::before {
              content: '4';
              top: -5px;
            }
          }
          &:not(:last-child) {
            &::after {
              content: ' ';
              width: 1px;
              height: 44px;
              background-color: rgba(118, 121, 125, 0.4);
              position: absolute;
              top: 28px;
              left: 11px;
            }
          }
          .index {
            font-size: 14px;
            line-height: 14px;
            font-weight: bold;
            color: var(--text-color-FFFFFF);
            margin-right: 16px;
            flex-shrink: 0;
          }
          .text {
            .text-item {
              font-size: 14px;
              line-height: 14px;
              color: ${props => props.theme.color.text.$C6C6C6};
              &.primary {
                color: ${props => props.theme.color.mainColor};
              }
              &:not(:first-child) {
                margin-top: 10px;
              }
            }
          }
        }
      }
      .sg-text-button-normal {
        color: #fff;
      }
    }
  }
`

const StyledDetailEditContainer = styled.div`
  height: 100%;
  .detail-edit-content {
    padding: 32px 32px 0;
    .detail-edit-tips {
      font-size: 14px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.65);
      text-align: right;
      margin-bottom: 24px;
      .main {
        color: var(--text-color-FFFFFF);
        margin: 0 2px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledDetailContainer = styled.div`
  .returns {
    padding: 0 50px;
    display: flex;
    .returns-text {
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.color.text.$FFFFFF86};
      cursor: pointer;
      .icon {
        transform: rotate(90deg);
        margin-right: 1px;
      }
      &:hover {
        color: var(--text-color-FFFFFF);
      }
    }
  }
`

export default observer(Detail)
