import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { ConfirmButton } from 'src/components/base-button'
import CEFTransfer from 'src/CEFTransfer'
import Checkbox from 'src/components/base-checkbox'
import styled from 'styled-components'
import classNames from 'classnames'

type TPrereadModal = {
  visible: boolean
  content: string
  onCancel: () => void
  onSuccess: (notTips: boolean) => void
}

let timer: number | null = null

const PrereadModal: FC<TPrereadModal> = ({ visible, onSuccess, onCancel, content }) => {
  const [notTipsValue, setNotTipsValue] = useState<boolean>(false)
  const [countDownValue, setCountDownValue] = useState<number>(5)
  useEffect(() => {
    if (visible) {
      handleCountDown(5)
    }
    return () => {
      timer && clearTimeout(timer)
      setNotTipsValue(false)
      setCountDownValue(5)
      const contentDOM = document.getElementById('preread-content')
      if (contentDOM) {
        contentDOM.scrollTop = 0
      }
    }
  }, [visible])

  const handleCountDown = (count: number) => {
    timer && clearTimeout(timer)
    setCountDownValue(count)
    if (count === 0) {
      return
    }
    timer = window.setTimeout(() => {
      handleCountDown(count - 1)
    }, 1000)
  }

  const handleEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
      CEFTransfer.sendToClient('openUrl', { url: e.target.href })
    }
  }
  const handleDragStartEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  return (
    <StyledPrereadModalContainer zIndex={1011} visible={visible} width={600} onCancel={() => onCancel()}>
      <div className='preread-title'>修改游戏须知</div>
      <div
        id='preread-content'
        className='preread-content'
        onClick={handleEventDelegation}
        onDragStart={handleDragStartEventDelegation}
      >
        <div className='main-description'>
          当前游戏修改器的使用存在特殊性，请仔细阅读游戏修改须知，并照做才能获得修改效果
        </div>
        <div dangerouslySetInnerHTML={{ __html: content || '' }} />
      </div>
      <div style={{ marginTop: 20 }}>
        <Checkbox checked={notTipsValue} onChange={e => setNotTipsValue(e.target.checked)}>
          我已了解这个游戏的特殊性了，不要重复提示了
        </Checkbox>
      </div>
      <ConfirmButton type='primary' disabled={!!countDownValue} onClick={() => onSuccess(notTipsValue)}>
        {countDownValue ? `请仔细阅读(${countDownValue}S)` : '继续启动'}
      </ConfirmButton>
    </StyledPrereadModalContainer>
  )
}

const StyledPrereadModalContainer = styled(BaseModal)`
  .ant-modal-body {
    padding: 40px;
  }
  .preread-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
  }
  .preread-content {
    max-height: 364px;
    margin-top: 30px;
    overflow: overlay;
    color: var(--text-color-FFFFFF);
    font-size: 14px;
    line-height: 24px;
    padding-right: 6px;
    .main-description {
      font-weight: 700;
      margin-bottom: 20px;
    }
    img {
      max-width: 100% !important;
      height: auto !important;
      width: auto !important;
    }
  }
  a {
    color: ${props => props.theme.color.text.$C598FF} !important;
    &:hover {
      filter: brightness(1.1) !important;
    }
  }
  .sg-confirm-btn[disabled],
  .sg-confirm-btn[disabled]:hover,
  .sg-confirm-btn {
    display: block;
    width: 395px;
    height: 44px;
    margin: 40px auto 0;
    font-size: 16px;
  }
`

export default observer(PrereadModal)
