import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { Slider } from 'antd'
import sliderHanlderImg from 'src/assets/images/slider/handler.png'
import styled from 'styled-components'
import classNames from 'classnames'

type TSlider = {
  value: number
  max: number
  min: number
  step: number
  onChange: (value: number) => void 
  disabled?: boolean
}

const SGSlider: FC<TSlider> = ({max, min, step, value, onChange, disabled}) => {
  return (
    <StyledSliderContainer>
      <StyledSlider disabled={disabled} tooltipVisible={false} onChange={(e) => onChange(e)} value={value} max={max} min={min} step={step} />
      <div className='step-text'>{value}</div>
    </StyledSliderContainer>
  )
}

const StyledSlider = styled(Slider)`
  width: 83px;
  margin: 10px 14px 10px;
  .ant-slider-track {
    background: ${props => props.theme.color.bg.$EE921A} !important;
  }
  .ant-slider-rail {
    background: ${props => props.theme.color.bg.$FFFFFF30} !important;
  }
  .ant-slider-handle {
    background: url(${sliderHanlderImg}) no-repeat;
    border: none;
    width: 24px;
    height: 16px;
    border-radius: 0;
    box-shadow: none;
  }
`

const StyledSliderContainer = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${props => props.theme.color.text.$FFFFFF86};
`

export default observer(SGSlider)
