import { observer } from 'mobx-react'
import { BaseModalV3 } from 'src/components/base-modal'
import { Button, message } from 'antd'
import { appStore } from 'src/store'
import styled from 'styled-components'
import React from 'react'
import copy from 'copy-to-clipboard'


const Contact=()=>{

  const onCopy=(text:string)=>{
    copy(text)
    message.success('Copy successfully')
  }
  return <StyledContactModal
    visible={appStore.contactVisible}
    width={500}
    title={null}
    footer={[<Button type={'primary'} onClick={()=>appStore.contactVisible=false}>I know</Button> ]}
    onCancel={()=>appStore.contactVisible=false}
  >
    <div className="content">
      Glad to serve you.<br/>
      For inquiries, please send an email to support@mybuff.com <span style={{cursor:'pointer', color:'#0DFAB8'}} onClick={()=>onCopy('support@mybuff.com')}>[Copy]</span>
    </div>
  </StyledContactModal>

}

const StyledContactModal =styled(BaseModalV3)`
  .content{
    padding-top:50px;
  }
  .ant-modal-footer{
    justify-content: center;     
  }
`


export default observer(Contact)