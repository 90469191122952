import { observable } from 'mobx'

const TrialGamePlayStore = observable<ITrialGameClientStore>({
  gamePull:false,
  platformLogin:false,
  beginTime: 0,
  /** 当前游戏运行状态 */
  gameRunStatus: 'stop',

  /** 当前正在运行的游戏 */
  currentTheRunningGameInfo: null,

  /** 当前正准备启动的游戏 */
  currentTheStartingGameInfo: null,
  /** 窗口状态 status: 0 - 窗口模式  1 - 最大化  2 - 全屏模式 */
  windowStatus: 0,

  /** 当前等待上一个游戏结束之后要启动的游戏 */
  willStartGameId:  null,

  gameStartingTimer: null,
  /** 启动游戏超时倒计时 */
  gameStartingCountdown:0,
  /** 试玩检测会员时长定时器 */
  trialVipTimeTimer:0,
  startGameInfoMap: new Map()
})

export default TrialGamePlayStore