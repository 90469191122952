import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { GameStore } from 'src/store'
import { FadeinImage } from 'src/components/base-image'
import { analysisSupportTypeByGameLibraryMaster, analysisSupportTypeByGamePlatform } from 'src/tools/common'
import styled from 'styled-components'
import classNames from 'classnames'
import LeftArrowsIcon from 'src/assets/icons/home/left-arrows.svg'
import RightArrowsIcon from 'src/assets/icons/home/right-arrows.svg'
import useHomeListCalcSize from './use-home-list-calc-size'
import supportImg from 'src/assets/images/home-new/support.png'
import notSupportImg from 'src/assets/images/home-new/not-support.png'
import installedEmptyImg from 'src/assets/images/common/installed-empty.png'

const CONTAINER_PADDING = 0
const ITEM_MARGIN_RIGHT = 26

const InstalledGame: FC = () => {
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [scrollOffset, setScrollOffset] = useState(0)
  const [scrollRightArrowIsDisabled, setScrollRightArrowIsDisabled] = useState(false)
  const [isViewScrollArrow, setIsViewScrollArrow] = useState(false)

  const jumpToDetail = (type: 'gameMaster' | 'platform', id: string | number, branchId?: string | number) => {
    if (type === 'gameMaster') {
      navigate(`/game-detail?type=game&gameLibraryBranchId=${id}&branchId=${branchId}`)
    } else {
      navigate(`/game-detail?type=platform&platformId=${id}`)
    }
  }

  const computedLayout = () => {
    if (containerRef.current && scrollRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const scrollWidth = scrollRef.current.offsetWidth

      /** 滚动区域大于展示区域则展示滚动按钮 */
      const isScrollGTView = scrollWidth > containerWidth
      setIsViewScrollArrow(isScrollGTView)

      /** 展示区域加上偏移 大于 滚动区域， 则修改偏移为当前能偏移的最大值 */
      const scrollOffsetWithContainerWidthGTScrollWidth = scrollOffset + containerWidth > scrollWidth
      const scrollOffsetMaxValue = scrollWidth - containerWidth
      if (scrollOffsetWithContainerWidthGTScrollWidth) {
        setScrollOffset(value => Math.min(Math.max(0, value), scrollOffsetMaxValue))
      }
    }
  }

  const handleMouseWheelScroll = (event: WheelEvent) => {
    if (containerRef.current && scrollRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const scrollWidth = scrollRef.current.offsetWidth
      /** 根据滚轮 得出偏移 不小于0  不大于最高值 */
      const scrollOffsetMaxValue = scrollWidth - containerWidth

      setScrollOffset(value => {
        const currentValue = value + event.deltaY
        setScrollRightArrowIsDisabled(currentValue >= scrollOffsetMaxValue)
        return Math.min(Math.max(0, value + event.deltaY), scrollOffsetMaxValue)
      })
    }

    // 阻止默认的垂直滚动
    event.preventDefault()
  }

  const handleScrollArrowClick = (scrollValue: number) => {
    if (containerRef.current && scrollRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const currentValue = scrollOffset + scrollValue
      const scrollWidth = scrollRef.current.offsetWidth
      const scrollOffsetMaxValue = scrollWidth - containerWidth
      setScrollOffset(Math.min(Math.max(0, currentValue), scrollOffsetMaxValue))
      setScrollRightArrowIsDisabled(currentValue + scrollValue >= scrollOffsetMaxValue)
    }
  }

  useEffect(() => {
    computedLayout()
  }, [GameStore.installedGameList.length, GameStore.installedPlatformList.length])

  useEffect(() => {
    computedLayout()
    window.addEventListener('resize', computedLayout)
    if (scrollRef.current) {
      scrollRef.current.addEventListener('wheel', handleMouseWheelScroll)
    }
    return () => {
      window.removeEventListener('resize', computedLayout)
      scrollRef.current?.removeEventListener('wheel', handleMouseWheelScroll)
    }
  }, [])

  const isEmpty = GameStore.installedGameList.length + GameStore.installedPlatformList.length === 0

  return (
    <StyledInstallGameContainer ref={containerRef}>
      <div className='title'>
        本地已安装
        {isViewScrollArrow && (
          <div className='extra'>
            <div className='icon'>
              <LeftArrowsIcon
                className={classNames({ disabled: scrollOffset === 0 })}
                onClick={() => handleScrollArrowClick(-100)}
              />
            </div>
            <div className='icon'>
              <RightArrowsIcon
                className={classNames({ disabled: scrollRightArrowIsDisabled })}
                onClick={() => handleScrollArrowClick(100)}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classNames('content', { isEmpty })}>
        {GameStore.installedGameList.length + GameStore.installedPlatformList.length === 0 ? (
          <StyledEmptyContainer>
            <FadeinImage
              width={80}
              height={64}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              src={installedEmptyImg}
            />
            <div className='empty-text'>当前未识别检测到您本地安装的游戏/游戏平台，建议您手动进行搜索</div>
          </StyledEmptyContainer>
        ) : (
          <StyledScrollBox
            ref={scrollRef}
            style={{
              transform: `translate(-${scrollOffset}px, -50%)`,
            }}
          >
            {GameStore.installedGameList.map(d => {
              const { supportSpeed, supportResource, supportTrial, supportCloudGame, supportModifier } =
                analysisSupportTypeByGameLibraryMaster(d)
              const supportList = [
                { label: '支持加速', support: supportSpeed },
                { label: '支持云游戏', support: supportCloudGame },
                { label: '支持修改', support: supportModifier },
                { label: '提供游戏资源', support: supportResource },
                { label: '提供共享账号', support: supportTrial },
              ]
              return (
                <div
                  className='scroll-item-container'
                  key={`${d.gameLibraryMasterCnName}-${d.gameLibraryMasterId}`}
                  onClick={() => jumpToDetail('gameMaster', d.gameLibraryMasterId, d.gameLibraryBrancheId)}
                >
                  <FadeinImage
                    width={240}
                    height={112}
                    className='install-game-img'
                    type='notDefault'
                    src={d.gameLibraryMasterListImage}
                  />
                  <div className='scroll-item-text ellipsis'>
                    <FadeinImage
                      width={16}
                      height={16}
                      type='notDefault'
                      src={
                        d.gameLibraryBranches.find(f => f.gameLibraryBranchId === d.gameLibraryBrancheId)?.platformIcon
                      }
                    />
                    <span className='text'>{d.gameLibraryMasterCnName}</span>
                  </div>
                  <div className='scroll-item-desc'>{d.gameLibraryMasterEnName}</div>
                  <div className='scroll-item-mask'>
                    {supportList.map(d => {
                      return (
                        <div
                          className={classNames('scroll-item-support-item', { isNotSupport: !d.support })}
                          key={d.label}
                        >
                          <FadeinImage
                            width={10}
                            height={7}
                            style={{ fontSize: 0 }}
                            type='notDefault'
                            src={!d.support ? notSupportImg : supportImg}
                          />
                          <div className='scroll-item-support-text'>{d.label}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            {GameStore.installedPlatformList.map(d => {
              const { supportSpeed, supportWelfare } = analysisSupportTypeByGamePlatform(d)
              const supportList = [
                { label: '支持加速', support: supportSpeed },
                { label: '支持喜加一', support: supportWelfare },
              ]

              return (
                <div
                  className='scroll-item-container'
                  key={`${d.platformName}-${d.platformId}`}
                  onClick={() => jumpToDetail('platform', d.platformId)}
                >
                  <FadeinImage
                    width={240}
                    height={112}
                    className='install-game-img'
                    type='notDefault'
                    src={d.platformHorizontalImage}
                  />
                  <div className='scroll-item-text ellipsis'>
                    <FadeinImage width={16} height={16} type='notDefault' src={d.platformIcon} />
                    <span className='text'>{d.platformName}</span>
                  </div>
                  <div className='scroll-item-mask'>
                    {supportList.map(d => {
                      return (
                        <div
                          className={classNames('scroll-item-support-item', { isNotSupport: !d.support })}
                          key={d.label}
                        >
                          <FadeinImage
                            width={10}
                            height={7}
                            style={{ fontSize: 0 }}
                            type='notDefault'
                            src={!d.support ? notSupportImg : supportImg}
                          />
                          <div className='scroll-item-support-text'>{d.label}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </StyledScrollBox>
        )}
      </div>
    </StyledInstallGameContainer>
  )
}

const StyledEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .empty-text {
    margin-top: 12px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: 400;
    color: #f2f3f5;
    line-height: 20px;
  }
`

const StyledScrollBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  position: absolute;
  top: 50%;
  left: ${CONTAINER_PADDING}px;
  transition: transform 150ms ease 0s;
  padding: 0 16px;
  .scroll-item-container {
    width: 256px;
    height: 190px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 8px;
    padding: 8px;
    position: relative;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    .install-game-img {
      width: 240px;
      height: 112px;
      .true-img {
        border-radius: 4px;
      }
    }
    .scroll-item-text {
      ${props => props.theme.flexAlignCenter}
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: var(--text-color-FFFFFF);
      line-height: 14px;
      margin-top: 15px;
      .text {
        margin-left: 8px;
      }
    }
    .scroll-item-desc {
      margin-top: 8px;
      width: 240px;
      font-size: 12px;
      font-family: Noto Sans, Noto Sans;
      font-weight: normal;
      color: #b5bac1;
      line-height: 18px;
    }
    .scroll-item-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(23, 24, 25, 0.86);
      border-radius: 4px;
      opacity: 0;
      transition: opacity 150ms ease 0s;
      display: flex;
      flex-wrap: wrap;
      padding: 42px 21px;
      .scroll-item-support-item {
        display: flex;
        align-items: center;
        width: 50%;
        color: #ffffff;
        .scroll-item-support-text {
          font-size: 14px;
          font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
          font-weight: 400;
          line-height: 16px;
          margin-left: 11px;
        }
        &.isNotSupport {
          color: #96989d;
          text-decoration: line-through;
        }
      }
    }
    &:hover {
      .scroll-item-mask {
        opacity: 1;
      }
    }
  }
`

const StyledInstallGameContainer = styled.div`
  margin-top: 32px;
  .title {
    font-size: 20px;
    font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    line-height: 30px;
    margin-bottom: 13px;
    position: relative;
    .extra {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      .icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.12);
        &:last-child {
          margin-left: 8px;
        }
      }
      svg {
        margin-right: 16px;
        color: rgba(255, 255, 255, 0.6);
        &:not(.disabled) {
          cursor: pointer;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
        &.disabled {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
  .content {
    height: 222px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background: #232428;
    &.isEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`

export default observer(InstalledGame)
