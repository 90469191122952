import { ToolsTypes } from 'src/constants/common'
import { baseFetch } from 'src/fetch'
import { CMSResultFilter, CMSResultFilter1, javaResultFilter } from 'src/fetch/resInterceptors'

/** 获取CMS版本号 */
export const cmsVersion = baseFetch<Http.JavaApiResult<string>>({
  url: '/fulu-page-cloud/anon/cms/getVersion',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_ORIGIN_API_HOST,
  },
})

/** 通过modid获取CMS配置数据 */
export const cmsModDataByModId = baseFetch<CMS.CmsModDataByModIdResult<any>, CMS.CmsModDataByModIdPayload>({
  url: '/fulu-page-cloud/anon/cms/getModDataByModId',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_ORIGIN_API_HOST,
  },
})

/** 通过dataid获取CMS配置数据 */
export const cmsModDataByDataId = baseFetch<
  Http.JavaApiResult<CMS.ModDataItem<any>>,
  { dataId: string; version: string }
>({
  url: '/fulu-page-cloud/anon/cms/getModDataByDataId',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_ORIGIN_API_HOST,
    resInterceptors: (data?) => CMSResultFilter1(data),
  },
})

/** 获取多个cms配置 */
export const cmsMultipleModDataByModId = baseFetch<
  Http.JavaApiResult<CMS.ModData<any>[]>,
  { modIds: string; version?: string }
>({
  url: '/fulu-page-cloud/anon/cms/getModDatasByModIds',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_ORIGIN_API_HOST,
  },
})

/** 埋点 */
export const reportLogHttp = baseFetch<
  Http.JavaApiResult<{ enableWechatPay: number; overPriceRate: number }>,
  { encrypt: string }
>({
  url: '/sgt/log',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取后台的全局配置 */
export const getAdminGlobalConfig = baseFetch<
  Http.JavaApiResult<any>,
  { channel: string; moduleName: string; keyName: string }
>({
  url: '/anon/query/gloabal/config',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取后台的渠道配置 */
export const getAdminChannelConfig = baseFetch<
  Http.JavaApiResult<{defaultTool: ToolsTypes}>,
  { channel: string; product: string }
>({
  url: '/anon/channel/config/get',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

