import { observable, action, computed } from 'mobx'
import { RunningStore } from 'src/store/modifier'
import { injectHeartBeatHttp } from 'src/apis/user-api'
import { notifyOpenVipByModifier } from 'src/tools/common'
import CEFTransfer from 'src/CEFTransfer'

// 正在启动中的状态
class StartingStore {
  @observable
  userId: number | null = null
  @observable
  titleInfo: Modifier.TTitle | null = null
  @observable
  trainerInfo: Modifier.TGame | null = null
  @observable
  gameSimpleInfo: Modifier.TGameSimple | null = null
  @observable
  cheatsNeedSave: boolean = false
  @observable
  cheatsValues: Record<string, Modifier.TCheatsValue> | null = null
  @observable
  gameLibraryName: string = ''
  @observable
  gamePlatformName: string = ''

  @observable
  currentStartNo: string = ''
  heartBeatTimer: null | number = null

  @computed
  get hasStarting() {
    return !!this.titleInfo
  }

  @action
  startSuccess() {
    RunningStore.userId = this.userId
    RunningStore.titleInfo = this.titleInfo
    RunningStore.trainerInfo = this.trainerInfo
    RunningStore.gameSimpleInfo = this.gameSimpleInfo
    RunningStore.cheatsNeedSave = this.cheatsNeedSave
    RunningStore.cheatsValues = this.cheatsValues
    RunningStore.gameLibraryName = this.gameLibraryName
    RunningStore.gamePlatformName = this.gamePlatformName
    RunningStore.currentStartNo = this.currentStartNo
    RunningStore.startHeartBeat(this.currentStartNo)
    this.clearStartingInfo()
    RunningStore.listenHotkeysHit()
  }

  @action
  setStartingInfo({
    userId,
    titleInfo,
    trainerInfo,
    gameSimpleInfo,
    cheatsNeedSave,
    cheatsValues,
    gameLibraryName,
    gamePlatformName,
  }: {
    gameLibraryName: string
    gamePlatformName: string
    userId: number
    titleInfo: Modifier.TTitle
    trainerInfo: Modifier.TGame
    gameSimpleInfo: Modifier.TGameSimple
    cheatsNeedSave: boolean
    cheatsValues: Record<string, Modifier.TCheatsValue>
  }) {
    this.userId = userId
    this.titleInfo = titleInfo
    this.trainerInfo = trainerInfo
    this.gameSimpleInfo = gameSimpleInfo
    this.cheatsNeedSave = cheatsNeedSave
    this.cheatsValues = cheatsValues
    this.gameLibraryName = gameLibraryName
    this.gamePlatformName = gamePlatformName
  }

  @action
  async startHeartBeat(startNo: string) {
    if (!this.hasStarting) {
      return false
    }
    const { result } = await injectHeartBeatHttp({ startNo })
    if (this.currentStartNo !== startNo) {
      return false
    }
    if (result.status === 'buffing') {
      this.clearHeartBeat()
      this.heartBeatTimer = window.setTimeout(() => this.startHeartBeat(startNo), 10000)
    } else {
      if (result.injectResultCode === '20004') {
        notifyOpenVipByModifier()
      }
      CEFTransfer.sendToClient('stopBuff')
      this.clearStartingInfo()
    }
    return result.status === 'buffing'
  }

  @action
  async clearHeartBeat() {
    this.heartBeatTimer && window.clearTimeout(this.heartBeatTimer)
  }

  @action
  clearStartingInfo() {
    this.clearHeartBeat()
    this.userId = null
    this.titleInfo = null
    this.trainerInfo = null
    this.gameSimpleInfo = null
    this.cheatsNeedSave = false
    this.cheatsValues = null
    this.currentStartNo = ''
  }
}

export default new StartingStore()
