import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { FullContentModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { ConfirmButton } from 'src/components/base-button'
import { message, Dropdown } from 'antd'
import { useFileDownload } from 'src/hooks'
import { DownloadStore } from 'src/store/resource'
import CEFTransfer from 'src/CEFTransfer'
import FullContentModalCloseIcon from 'src/assets/icons/common/full-content-modal-close.svg'
import selectorIconImage from 'src/assets/images/common/selector-icon.png'
import PlusIcon from 'src/assets/icons/common/plus.svg'
import styled from 'styled-components'
import classNames from 'classnames'
import AngleRightIcon from 'src/assets/images/common/angleright.svg'

type TFilterSelector = {
  list: { icon: string; name: string; key: string | number }[]
  value: string | number
  changeValue: (key: string | number) => void
  width?: number
  small?: boolean
  disabled?: boolean
}

const FilterSelector: FC<TFilterSelector> = ({
  list,
  value,
  changeValue,
  width = 204,
  small = false,
  disabled = false,
}) => {
  const [visible, setVisible] = useState<boolean>()
  const current = list.find(f => f.key === value) || list[0]

  const iconWidth = small ? 16 : 24
  const boxPadding = small ? 8 : 16
  const imgMarginRight = small ? 8 : 16
  const handleChangeValue = (key: string | number) => {
    setVisible(false)
    changeValue(key)
  }

  const PlatformDropdownMenu = (
    <StyledDropdownMenu className='no-drag' width={width}>
      {list.map(d => {
        return (
          <div className='menu-item pointer' key={d.key} onClick={() => handleChangeValue(d.key)}>
            {d.icon && (
              <div
                className='filter-img-box'
                style={{ marginRight: imgMarginRight, width: iconWidth, maxWidth: iconWidth, minWidth: iconWidth }}
              >
                <FadeinImage type='notDefault' width={iconWidth} height={iconWidth} src={d.icon} />
              </div>
            )}
            <div className='filter-value ellipsis'>{d.name}</div>
          </div>
        )
      })}
    </StyledDropdownMenu>
  )
  if (disabled) {
    return (
      <StyledFilterSelector
        className='filter-seletor-container'
        style={{
          background: visible ? 'rgba(195,140,255,0.16)' : 'rgba(255, 255, 255, .12)',
          padding: `0 ${boxPadding}px`,
        }}
      >
        <div className='filter-left'>
          {current?.icon && (
            <div
              className='filter-img-box'
              style={{ marginRight: imgMarginRight, width: iconWidth, maxWidth: iconWidth, minWidth: iconWidth }}
            >
              <FadeinImage width={iconWidth} height={iconWidth} type='notDefault' src={current.icon} />
            </div>
          )}
          <div className='filter-value ellipsis'>{current?.name}</div>
        </div>
      </StyledFilterSelector>
    )
  }
  return (
    <Dropdown overlay={PlatformDropdownMenu} trigger={['click']} placement='bottomCenter' onVisibleChange={setVisible}>
      <StyledFilterSelector
        className='filter-seletor-container'
        style={{
          background: visible ? 'rgba(195,140,255,0.16)' : 'rgba(255, 255, 255, .12)',
          padding: `0 ${boxPadding}px`,
        }}
      >
        <div className='filter-left'>
          {current?.icon && (
            <div
              className='filter-img-box'
              style={{ marginRight: imgMarginRight, width: iconWidth, maxWidth: iconWidth, minWidth: iconWidth }}
            >
              <FadeinImage width={iconWidth} height={iconWidth} type='notDefault' src={current.icon} />
            </div>
          )}
          <div className='filter-value ellipsis'>{current?.name}</div>
        </div>
        <div className='filter-right'>
          <AngleRightIcon/>
          {/*<FadeinImage src={selectorIconImage} type='notDefault' width={10} height={5} />*/}
        </div>
      </StyledFilterSelector>
    </Dropdown>
  )
}

const StyledDropdownMenu = styled.div<{ width: number }>`
  width: ${props => props.width}px;
  background: #121314;
  border-radius: 4px;
  //box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.6);
  padding: 6px;
  .menu-item {
    display: flex;
    align-items: center;
    color: #96989D;
    font-size: 14px;
    padding-left: 12px;
    height: 40px;
    border-radius: 4px;
    &:hover {
      background: #7044CD;
      color: var(--text-color-FFFFFF);
    }
    .filter-img-box {
      font-size: 0;
    }
    .filter-value {
      width: 120px;
    }
    svg {
      margin-right: 10px;
    }
  }
`

const StyledFilterSelector = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  width: 204px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  .filter-left {
    flex: 1;
    ${props => props.theme.flexAlignCenter}
    .filter-img-box {
    }
    .filter-value {
      flex: 1;
    }
  }
  .filter-right {
    line-height: 0;
    //width: 10px;
    //min-width: 10px;
  }
`
export default observer(FilterSelector)
