import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

/**
 * @description 发送验证码功能
 * @param initText 初始化按钮文案
 * @param initTime 初始化间隔时间
 * @param resendText 倒计时结束之后的文案
 * @return {sendCode} 发送验证码的事件
 * @return {text} 发送验证码的按钮文案
 * @return {isLoading} 当前是否在冷却中
 */
const useSendCode = (initText?: string, initTime?: number, resendText?: string) => {
  const [text, setText] = useState<string>(initText || '发送验证码')

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [timer, setTimer] = useState<number | null>(null)

  const { formatMessage } = useIntl()
  const countdownSendCode = (count: number) => {
    if (count === 0) {
      timer && clearTimeout(timer)
      setTimer(null)
      setIsLoading(false)
      setText(resendText || initText || '获取验证码')
    } else {
      setText(formatMessage({id:'common.sendTime'},{count}))
      setTimer(
        window.setTimeout(() => {
          countdownSendCode(count - 1)
        }, 1000),
      )
    }
  }

  const startCountdown = () => {
    console.log(11)
    setIsLoading(true)
    countdownSendCode(initTime || 60)
  }

  useEffect(() => {
    return () => {
      timer && clearTimeout(timer)
      setTimer(null)
    }
  }, [])

  return { text, isLoading, startCountdown }
}

export default useSendCode
