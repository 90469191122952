import { ToolsTypes } from 'src/constants/common'
import { baseFetch } from 'src/fetch'
import { CMSResultFilter, CMSResultFilter1, javaResultFilter, phpResultFilter } from 'src/fetch/resInterceptors'


/**
 * @description 云游戏-事件消息上报
 */
export const applyCloudGameReport = baseFetch<Http.JavaApiResult,TCloudGameReportQuery>({
  url: '/api/user/game/cloud/event/msg/advice',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/**
 * @description 云游戏-建立会话连接
 */
export const applyCloudGameConnect = baseFetch<Http.JavaApiResult<TCloudGameConnectResult>,TCloudGameConnectQuery>({
  url: '/api/user/game/cloud/connect',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 开始游戏
 */
export const startGpGame = baseFetch<Http.BoxApiResult<TStartGameHttp>,StartGameQuery>({
  url: '/api/startGpGame',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: (data)=>phpResultFilter(data,{warnToast:false}),
  },
})

/**
 * @description 开始游戏-试玩
 */
export const startGpGameTrial = baseFetch<Http.BoxApiResult<TStartGameHttp>,StartGameQuery>({
  url: '/game/trial/api/startGpGame',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: (data)=>phpResultFilter(data,{warnToast:false}),
  },
})


/**
 * @description 排队成功后启动游戏
 */
export const confirmStartGame = baseFetch<Http.JavaApiResult<TStartGameHttp>,StartGameQuery & {queueMainId: string}>({
  url: '/api/startGame/confirm',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 排队成功后启动游戏-本地游戏
 */
export const confirmStartGameTrial = baseFetch<Http.JavaApiResult<TStartGameHttp>,StartGameQuery & {queueMainId: string}>({
  url: '/game/trial/api/startGame/confirm',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/**
 * @description 查询用户排队资源信息
 */
export const queryResourceQueue = baseFetch<Http.JavaApiResult<TResourceQueueInfo>,{ gameId?:number; gameLibraryBranchId: number; queueMainId?: string }>({
  url: '/api/user/game/resource/queue/query',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询用户排队资源信息-试玩
 */
export const queryResourceQueueTrial = baseFetch<Http.JavaApiResult<TResourceQueueInfo>,{ gameLibraryBranchId: number; queueMainId?: string }>({
  url: '/game/trial/api/user/game/resource/queue/query',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/**
 * @description 加入资源排队
 */
export const enterResourceQueue = baseFetch<Http.JavaApiResult<TJoinResourceQueueResponse>,TJoinResourceQueue>({
  url: '/api/user/game/resource/queue/enter',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 加入资源排队-试玩
 */
export const enterResourceQueueTrial = baseFetch<Http.JavaApiResult<TJoinResourceQueueResponse>,TJoinResourceQueue>({
  url: '/game/trial/api/user/game/resource/queue/enter',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 取消资源排队
 */
export const cancelResourceQueue = baseFetch<Http.JavaApiResult<null>,TCancelResourceQueue>({
  url: '/api/user/game/resource/queue/cancel',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/**
 * @description 取消资源排队-试玩
 */
export const cancelResourceQueueTrial = baseFetch<Http.JavaApiResult<null>,TCancelResourceQueue>({
  url: '/game/trial/api/user/game/resource/queue/cancel',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})
