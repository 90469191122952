import React, { FC, useEffect } from 'react'
import CEFTransfer from 'src/CEFTransfer'
import { render } from 'react-dom'
import { ConfigProvider } from 'antd'
import { MtJsWebSDK, listenHistoryChange } from '@mt0926/js-web-sdk'
import { ThemeProvider, createGlobalStyle, css } from 'styled-components'
import { ImperativeConfirmModalPoll } from 'src/components/base-modal'
import { checkOversea, getFirstPageChannel } from 'src/tools/common'
import { IntlProvider } from 'react-intl'
import { messageMap } from 'src/locales'
import { appStore } from 'src/store'
import bootstrap, { bootstrapCloudPage, bootstrapMini } from 'src/bootstrap'
import Promotion1 from 'src/pages/website/promotion1'
import Home from 'src/pages/website'
import CustomRouter from 'src/components/custom-router'
import NewLayout from 'src/layout'
import LayoutMini from 'src/layout/mini'
import LoginModal from 'src/components/login-modal'
import SpeedModal from 'src/components/speed-modal'
import Activity230817Modal from 'src/pages/activity/activity-23-08-17'
import FeedbackModal from 'src/components/feedback'
import zhCN from 'antd/lib/locale/zh_CN'
import enUS from 'antd/lib/locale/en_US'

// import 'src/tools/animate.js'
import 'src/assets/styles/normalize.less'
import CloudGamePage from 'src/pages/cloudgame-page'
import Contact from 'src/components/contact'

const antdLocaleMap = {
  'zh-CN': zhCN,
  'en-US': enUS
}

// CEFTransfer.sendToClient('setWindowSize', { width: 1920, height: 1080 })
declare module 'styled-components' {
  export interface DefaultTheme {
    flexCenter: FlattenSimpleInterpolation
    flexAlignCenter: FlattenSimpleInterpolation
    flexJustifyCenter: FlattenSimpleInterpolation
    color: {
      mainColor: string
      text: {
        $BBBBBB: string
        $1E1E1E: string
        $7B7B7B: string
        $D9D9D9: string
        $F0F0F0: string
        $10FAB8: string
        $999999: string
        $DF9FFC: string
        $FEB731: string
        $C598FF: string
        $D8B3FF: string
        $A7A7A9: string
        $1E1E20: string
        $C6C6C6: string
        $FFFFFF86: string
      }
      bg: {
        $FFFFFF30: string
        $FFFFFF12: string
        $FFFFFF06: string
        $19191935: string
        $19191950: string
        $29283950: string
        $121212: string
        $19191980: string
        $111312: string
        $13131350: string
        $E8B409: string
        $C38CFF10: string
        $00000020: string
        $864DFE24: string
        $1E1C27: string
        $999999: string
        $252525: string
        $272628: string
        $4C4C50: string
        $444648: string
        $EE921A: string
        $FFFFFF: string
        $171717: string
        $1D1C2780: string
        $13131340: string
        $54545860: string
      }
      bor: {
        $FFFFFF12: string
        $FFFFFF20: string
        $593D78: string
        $864DFE: string
        $9A72CA: string
      }
    }
  }
}

const flexAlignCenter = css`
  display: flex;
  align-items: center;
`

const flexJustifyCenter = css`
  display: flex;
  justify-content: center;
`

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

// MtJsWebSDK.init({
//   serverUrl: 'http://10.13.7.19:3030/log.gif?project=game_market',
//   appInfo: {
//     $app_id: 'sgtools',
//     $app_name: 'sgtools',
//     $app_version: '1.0.0',
//   },
//   plugins: [
//     listenHistoryChange
//   ]
// })

// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'AAAAA', age: 16})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'AAAAA', age: 20})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'BBBBB', age: 25, job: 'coding'})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'BBBBB', age: 20})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'CCCCC', age: 300})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'DDDDD', age: 300, job: 'coding'})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'EEEEE', job: 'coding'})
// }, 100)
// setInterval(() => {
//   MtJsWebSDK.track('enter_page', {name: 'FFFFF'})
// }, 100)

MtJsWebSDK.init({
  serverUrl: 'https://mt-ns.w.kunluncb.com/log.gif?project=test_product',
  appInfo: {
    $app_id: 'sgtools-client',
    $app_name: 'sgtools-client',
    $app_version: '1.0.2',
  },
  plugins: [listenHistoryChange],
})

MtJsWebSDK.assignCommonParams({
  channel: getFirstPageChannel(),
})

const isCloudGamePage = window.location.pathname==='/cloudgame-page'

const isMiniClient = window.location.pathname.startsWith('/mini/')

const HGApp: FC = () => {

  const isOversea = checkOversea()

  useEffect(() => {
    CEFTransfer.sendToClient('uiLoadComplete')
  }, [])
  return (
    <React.StrictMode>
      <IntlProvider messages={messageMap[appStore.languageCode]} locale={appStore.languageCode} defaultLocale='zh-CN'>
      <ThemeProvider
        theme={{
          flexCenter,
          flexAlignCenter,
          flexJustifyCenter,
          color: {
            mainColor: '#C38CFF',
            text: {
              $BBBBBB: '#BBBBBB',
              $1E1E1E: '#1E1E1E',
              $7B7B7B: '#7B7B7B',
              $D9D9D9: '#D9D9D9',
              $F0F0F0: '#F0F0F0',
              $10FAB8: '#10FAB8',
              $999999: '#999999',
              $DF9FFC: '#DF9FFC',
              $FEB731: '#FEB731',
              $C598FF: '#C598FF',
              $D8B3FF: '#D8B3FF',
              $A7A7A9: '#A7A7A9',
              $1E1E20: '#1E1E20',
              $C6C6C6: '#C6C6C6',
              $FFFFFF86: 'rgba(255,255,255,0.86)',
            },
            bg: {
              $FFFFFF30: 'rgba(255,255,255,0.3)',
              $FFFFFF06: 'rgba(255,255,255,0.06)',
              $FFFFFF12: 'rgba(255,255,255,0.12)',
              $19191935: 'rgba(19, 19, 19, .35)',
              $19191950: 'rgba(19,19,19,0.5)',
              $19191980: 'rgba(19,19,19,0.8)',
              $111312: '#111312',
              $13131350: '#13131350',
              $E8B409: '#E8B409',
              $C38CFF10: 'rgba(195,140,255,0.1)',
              $00000020: 'rgba(0, 0, 0, .2)',
              $1E1C27: '#1E1C27',
              $999999: '#999999',
              $252525: '#252525',
              $121212: '#121212',
              $272628: '#272628',
              $4C4C50: '#4C4C50',
              $444648: '#444648',
              $EE921A: '#EE921A',
              $FFFFFF: '#FFFFFF',
              $171717: '#171717',
              $29283950: 'rgba(29, 28, 39, 0.5)',
              $1D1C2780: 'rgba(29, 28, 39, 0.8)',
              $54545860: 'rgba(84, 84, 88, 0.6)',
              $13131340: 'rgba(13, 13, 13, .4)',
              $864DFE24: 'rgba(192, 138, 254, 0.24)',
            },
            bor: {
              $FFFFFF12: 'rgba(255, 255, 255, .12)',
              $FFFFFF20: 'rgba(255, 255, 255, .20)',
              $593D78: '#593D78',
              $864DFE: '#864DFE',
              $9A72CA: '#9A72CA',
            },
          },
        }}
      >
        <ConfigProvider locale={antdLocaleMap[appStore.languageCode]}>
          {
            isCloudGamePage ?
              <CloudGamePage />:
              <CustomRouter>
                {window.location.hostname === 'www.xutaowang.com' ? (
                  <Home />
                ) : ['mybuff.haot8.cn', 'www.mybuff.com'].includes(window.location.hostname) ? (
                  <Promotion1 />
                ) : isMiniClient ? <>
                  <LayoutMini/>
                  <ImperativeConfirmModalPoll />
                </> : <>
                  <NewLayout />
                  <LoginModal />
                  <ImperativeConfirmModalPoll />
                  {
                    isOversea ?
                      <>
                        <Contact/>
                      </> :
                      <>
                        <SpeedModal />
                        <Activity230817Modal />
                        <FeedbackModal />
                      </>
                  }
                </>}
              </CustomRouter>
          }
        </ConfigProvider>
      </ThemeProvider>
      </IntlProvider>
    </React.StrictMode>
  )
}

if (isCloudGamePage) {
  bootstrapCloudPage()
} else if (isMiniClient) {
  bootstrapMini()
} else {
  bootstrap()
}

render(<HGApp />, document.getElementById('app'))
