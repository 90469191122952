import { observer } from 'mobx-react'
import { getTextWidth } from 'src/tools/common'
import { useEffect, useRef, useState } from 'react'
import helpTipsIcon from 'src/assets/images/cloud-game/tips.png'
import styled from 'styled-components'
import classNames from 'classnames'
import React from 'react'

const TIPS_TEXT_CONTENT_WIDTH = 680

type TCloudgameHelpTips = {
  sort: string, 
  text: string
}


const HelpTips = () => {
  const EnterFlag = useRef<boolean>(false)
  const [helpTipsValue, setHelpTipsValue] = useState<Array<TCloudgameHelpTips & {textWidth: number}>>()
  const [index, setIndex] = useState<number>(0)

  const handleCloudGameHelpTips = async () => {
    const result = [
      {text:'由于游戏本身 / 游戏平台启动更新可能会造成云游戏拉起后一段时间内只看到桌面壁纸的情况，请耐心等待游戏启动',sort:'1'},
      {text:'由于云游戏特性，为保证您有个较为良好的体验，建议您不要使用无线网络下进行游戏',sort:'2'},
      {text:'浏览器全屏模式下长按「ESC」可解除全屏状态；「Alt」+「Ctrl」+ 「F」的组合键可快速切换全屏/非全屏',sort:'3'},
      {text:'由于系统异常出现游戏无法捕获鼠标时，可以尝试在游戏页面内多次点击鼠标右键解决',sort:'4'},
      {text:'进入游戏后出现画面模糊，语言非中文，大多数情况是启动的游戏本身设置问题，请尝试在游戏内找到【Optioning】/【Setting】进行设置',sort:'5'},
    ]
    /** 随机顺序 */
    const helpTipsValue = result?.sort((a, b) => Math.random() > 0.5 ? 1 : -1).map(d => ({
      ...d,
      textWidth: getTextWidth(d.text, { fontSize: '14px', fontWeight: '500' })
    }))
    console.log(helpTipsValue, 'helpTipsValue')
    setHelpTipsValue(helpTipsValue)
  }

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    EnterFlag.current = true
  }

  const handleMouseOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    EnterFlag.current = false
  }
  
  useEffect(() => {
    if (helpTipsValue) {
      let timer = setInterval(() => {
        if (EnterFlag.current) {
          return
        }
        setIndex(v => ++ v)
      }, 3000)
      return () => {
        timer && clearInterval(timer)
      }
    }
  }, [helpTipsValue])

  useEffect(() => {
      handleCloudGameHelpTips()
  }, [])

  if (!helpTipsValue) {
    return null
  }

  return (
    <StyledHelpTips onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
      <img className='help-tips-icon' src={helpTipsIcon} draggable={false} />
      {helpTipsValue.map((d, i) => {
        return (
          <StyledHelpTipsTextScroll key={d.text} textWidth={d.textWidth} className={classNames({active: i === (index % helpTipsValue.length)})}>
            <div className='help-tips-text-content'>{d.text}</div>
          </StyledHelpTipsTextScroll>
        )
      })}
    </StyledHelpTips>
  )
}

const StyledHelpTipsTextScroll = styled.div<{textWidth: number}>`
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  width: ${TIPS_TEXT_CONTENT_WIDTH}px;
  max-width: ${TIPS_TEXT_CONTENT_WIDTH}px;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: all 300ms linear 0s;
  .help-tips-text-content {
    position: relative;
    white-space: nowrap;
    text-align: center;
  }
  &.active {
    opacity: 1;
    z-index: 1;
  }
  ${props => {
    if (props.textWidth <= TIPS_TEXT_CONTENT_WIDTH) {
      return ''
    }
    const diffWidth = props.textWidth - TIPS_TEXT_CONTENT_WIDTH
    return `
      cursor: pointer;
      &:hover {
        .help-tips-text-content {
          transition: all ${diffWidth / 50}s linear 0s;
          transform: translateX(-${diffWidth}px);
        }
      }
    `
  }}
`

const StyledHelpTips = styled.div`
  position: absolute;
  bottom: 182px;
  left: 50%;
  transform: translateX(-50%);
  width: 722px;
  height: 36px;
  background: rgba(255,255,255,0.16);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .help-tips-icon {
    position: absolute;
    top: -11px;
    left: 19px;
  }
`

export default observer(HelpTips)
