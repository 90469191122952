import React, { FC, useEffect } from 'react'
import { ImperativeConfirmModal } from 'src/components/base-modal'
import { observer } from 'mobx-react'
import { LoginStore } from 'src/store/modifier'
import { VipStore } from 'src/store'


const BalanceInsufficientModal: FC<{visible:boolean; onCancel:()=>void; onOk:()=>void}> = ({ visible,onOk,onCancel }) => {

  useEffect(()=>{
    if(visible && LoginStore.userinfo){
      VipStore.handleLoadUserRemainingCloudGameBalance()
    }
  },[visible,LoginStore.userinfo])

  return (
    <ImperativeConfirmModal options={ visible ? {
      title: '云游戏时长余额不足',
      content: <div>您的云游戏时长即将消耗完毕，请尽快进行充值，云游戏时长消耗完后，我们将直接断开您与云端游戏的链接，可能会造成您游戏进度的丢失！</div>,
      key: 'confirm-modal-force-balance-insufficient',
      okText: '前往充值',
      cancelText:'我知道了',
      btns: ['cancel', 'ok',],
      _ok: onOk,
      _cancel:onCancel,
    }:null } />
  )
}

export default observer(BalanceInsufficientModal)
