import React, { FC, useState, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react'
import { SpeedStore } from 'src/store'
import { fetchGameInfo } from 'src/apis/game-api'
import { formatTime } from 'src/tools/helper'
import { ConfirmButton } from 'src/components/base-button'
import { FadeinImage } from 'src/components/base-image'
import { Column, Area } from '@ant-design/plots'
import dayjs from 'dayjs'
import QueueAnim from 'rc-queue-anim'
import styled from 'styled-components'

import step1Img from 'src/assets/images/my-game/step1.png'
import step2Img from 'src/assets/images/my-game/step2.png'
import step3Img from 'src/assets/images/my-game/step3.png'
import successImg from 'src/assets/images/my-game/success.png'
import SwitchIcon from 'src/assets/icons/my-game/switch.svg'
import classNames from 'classnames'

const SpeedSuccess: FC<{ onChangeDetailStatus: (status: 'change-area-server') => void }> = ({
  onChangeDetailStatus,
}) => {
  const ChartsRef = useRef<any>(null)
  const ChartsRenderDataRef = useRef<
    {
      timestamp: number
      delay: number
      time: string
    }[]
  >([])
  const [gameInfo, setGameInfo] = useState<Http.GameInfo>()
  // const [chartsType, setChartsType] = useState<'ten_seconds' | 'one_minute'>('ten_seconds')
  const speedingInfo = SpeedStore.speedingInfo
  const nodeDelay = speedingInfo?.nodeDelay ? Number(speedingInfo.nodeDelay) : 0

  const getGameInfo = async (speedGameNo: string) => {
    try {
      const { result } = await fetchGameInfo({ speedGameNo })
      result && setGameInfo(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSwitchAreaServer = () => {
    onChangeDetailStatus('change-area-server')
  }

  const speedRunningTime = useMemo(() => {
    const speedTime = speedingInfo?.speedTime || 0
    return formatTime(speedTime).join(':')
  }, [speedingInfo?.speedTime])

  const speedNodeInfo = useMemo(() => {
    let result = ''
    if (speedingInfo) {
      result = [speedingInfo.speedAreaName, speedingInfo.speedServerName].filter(Boolean).join(' | ')
      if (speedingInfo.speedNodeName) {
        result = `${result} - ${speedingInfo.speedNodeName}`
      }
    }
    return result
  }, [speedingInfo])

  const ChartsDOM = useMemo(() => {
    const config = {
      data: [],
      xField: 'time',
      yField: 'delay',
      // xAxis: {
      //   label: null,
      //   grid: null,
      // },
      smooth: true,
      yAxis: {
        grid: {
          line: {
            style: {
              stroke: '#ffffff',
              lineWidth: 1,
              lineDash: [0, 0],
              strokeOpacity: 0.1,
            },
          },
        },
      },
      tooltip: {
        customContent: (...props: any) => {
          const [, [temp]] = props
          return temp
            ? `<div style="padding: 4px 0">${temp.title}:  <span style="margin-left: 4px">延迟 ${temp.value} ms</span></div>`
            : null
        },
      },
      color: '#C38CFF',
      areaStyle: () => {
        return {
          fill: 'l(270) 0:rgba(195,140,255,0) 1:#C38CFF',
        }
      },
    }

    return <Area {...config} ref={ChartsRef} />
    // const config = {
    //   data: [],
    //   xField: 'time',
    //   yField: 'delay',
    //   tooltip: {
    //     customContent: (...props: any) => {
    //       const [, [temp]] = props
    //       return temp
    //         ? `<div style="padding: 4px 0">${temp.name}:  <span style="margin-left: 4px">延迟 ${temp.value} ms</span></div>`
    //         : null
    //     },
    //   },
    //   xAxis: {
    //     label: null,
    //     grid: null,
    //   },
    //   yAxis: {
    //     grid: {
    //       line: {
    //         style: {
    //           stroke: '#ffffff',
    //           lineWidth: 1,
    //           lineDash: [0, 0],
    //           strokeOpacity: 0.1,
    //         },
    //       },
    //     },
    //   },
    //   minColumnWidth: 8,
    //   maxColumnWidth: 24,
    //   color: ({ time }: any) => {
    //     const temp = ChartsRenderDataRef.current?.find(f => f.time === time)?.delay
    //     if (!temp || temp <= 90) {
    //       return '#10FAB8'
    //     }
    //     if (temp > 90 && temp <= 120) {
    //       return '#ffd447'
    //     }
    //     return '#ec3a00'
    //   },
    //   meta: {
    //     time: {
    //       alias: '时间',
    //     },
    //     delay: {
    //       alias: '延迟',
    //     },
    //   },
    // }
    // return <Column {...config} ref={ChartsRef} />
  }, [])

  useEffect(() => {
    const rerender = () => {
      let renderData = Object.values(SpeedStore.speedingDelayChartsData)
      renderData = renderData.sort((a, b) => a.timestamp - b.timestamp).slice(-50)
      ChartsRenderDataRef.current = renderData
      ChartsRef.current.getChart().changeData(renderData)
    }
    if (ChartsRef.current) {
      rerender()
    } else {
      setTimeout(() => {
        if (ChartsRef.current) {
          rerender()
        }
      }, 500)
    }
  }, [SpeedStore.speedingDelayChartsData])

  useEffect(() => {
    if (speedingInfo?.relateIdens) {
      getGameInfo(speedingInfo.relateIdens)
    }
  }, [speedingInfo?.relateIdens])

  return (
    <StyledSectionBox>
      <div className='title'>当前加速</div>
      <QueueAnim className='content'>
        <StyledCurrentAreaServerContent key={`speed-success-1`}>
          <div className='areaserver-row'>
            <div className='areaserver-text'>{speedNodeInfo}</div>
            <div className='areaserver-switch'>
              <span className='switch-text' onClick={handleSwitchAreaServer}>
                切换区服
              </span>
              <SwitchIcon />
            </div>
          </div>
          {gameInfo?.categoryId === 2 && (
            <StyledSpeedTip>
              <div className='speed-tip'>
                <FadeinImage width={16} height={16} type='notDefault' src={successImg} />
                <span>已同时加速游戏平台</span>
              </div>
              <div className='speed-tip'>
                <FadeinImage width={16} height={16} type='notDefault' src={successImg} />
                <span>已同时加速游戏下载</span>
              </div>
            </StyledSpeedTip>
          )}
        </StyledCurrentAreaServerContent>
        <StyledCurrentSpeedInfoContent key={`speed-success-2`}>
          <div className='speedinfo-item'>
            <div className='main'>
              <span>{speedRunningTime}</span>
            </div>
            <div className='text'>已加速时长</div>
          </div>
          <div className='speedinfo-item'>
            <div
              className={classNames('main', {
                good: nodeDelay <= 90,
                general: nodeDelay > 90 && nodeDelay <= 120,
                bad: nodeDelay > 120,
              })}
            >
              <span>{nodeDelay}</span>
              <span className='unit'>ms</span>
            </div>
            <div className='text'>当前延迟</div>
          </div>
          <div className='speedinfo-item'>
            <div className='main'>
              <span>{parseInt(speedingInfo?.nodeLoss || '0', 10)}</span>
              <span className='unit'>%</span>
            </div>
            <div className='text'>丢包率</div>
          </div>
        </StyledCurrentSpeedInfoContent>

        <StyledDeviceInfoContent key={`speed-success-3`}>
          <div className='deviceinfo-title'>设备连接</div>
          <div className='deviceinfo-graph-content'>
            <div className='deviceinfo-graph-step-box'>
              <div className='deviceinfo-graph-step-img'>
                <FadeinImage width={46} height={38} src={step1Img} type='notDefault' />
              </div>
              <div className='deviceinfo-graph-step-text'>电脑</div>
            </div>
            <div className='deviceinfo-graph-transfer-box'>
              <div className='deviceinfo-delay-text'>延迟：{speedingInfo?.a_delay || '0'}ms</div>
              <div className='deviceinfo-loss-text'>丢包：{speedingInfo?.a_loss_avg || '0'}%</div>
            </div>
            <div className='deviceinfo-graph-step-box'>
              <div className='deviceinfo-graph-step-img'>
                <FadeinImage width={46} height={38} src={step2Img} type='notDefault' />
              </div>
              <div className='deviceinfo-graph-step-text'>路由器</div>
            </div>
            <div className='deviceinfo-graph-transfer-box'>
              <div className='deviceinfo-delay-text'>延迟：{speedingInfo?.b_delay || '0'}ms</div>
              <div className='deviceinfo-loss-text'>丢包：{speedingInfo?.b_loss_avg || '0'}%</div>
            </div>
            <div className='deviceinfo-graph-step-box'>
              <div className='deviceinfo-graph-step-img'>
                <FadeinImage width={46} height={40} src={step3Img} type='notDefault' />
              </div>
              <div className='deviceinfo-graph-step-text'>服务器</div>
            </div>
          </div>
          <div className='deviceinfo-title'>
            加速走势
            {/* <div className='deviceinfo-extra-box'>
              <div
                className={classNames('deviceinfo-extra-item', { active: chartsType === 'ten_seconds' })}
                onClick={() => setChartsType('ten_seconds')}
              >
                10sec
              </div>
              <div
                className={classNames('deviceinfo-extra-item', { active: chartsType === 'one_minute' })}
                onClick={() => setChartsType('one_minute')}
              >
                1min
              </div>
            </div> */}
          </div>
          <div className='deviceinfo-charts-box'>{ChartsDOM}</div>
        </StyledDeviceInfoContent>
      </QueueAnim>
    </StyledSectionBox>

    // {/* <StyledViewAreaServer>
    //               {renderAreaServerList?.map(info => {
    //                 return (
    //                   <StyledAreaServerItem
    //                     key={`${info.areaID}-${info.serverID}`}
    //                     className={classNames('pointer', {
    //                       checked:
    //                         `${checkedAreaServer?.areaID}-${checkedAreaServer?.serverID}` ===
    //                         `${info.areaID}-${info.serverID}`,
    //                     })}
    //                     onClick={() => setCheckedAreaServer(info)}
    //                   >
    //                     {[info.areaName, info.serverName].filter(Boolean).join('-')}
    //                   </StyledAreaServerItem>
    //                 )
    //               })}
    //             </StyledViewAreaServer> */}
    // {/* <StyledSectionBox>
    //     <div className='title'>加速器区服</div>
    //     <div className='content'>
    //       <StyledViewAreaServer>
    //         {renderAreaServerList?.map(info => {
    //           return (
    //             <StyledAreaServerItem
    //               key={`${info.areaID}-${info.serverID}`}
    //               className={classNames('pointer', {
    //                 checked:
    //                   `${checkedAreaServer?.areaID}-${checkedAreaServer?.serverID}` ===
    //                   `${info.areaID}-${info.serverID}`,
    //               })}
    //               onClick={() => setCheckedAreaServer(info)}
    //             >
    //               {[info.areaName, info.serverName].filter(Boolean).join('-')}
    //             </StyledAreaServerItem>
    //           )
    //         })}
    //       </StyledViewAreaServer>
    //     </div>
    //   </StyledSectionBox> */}
    // <StyledAccelerateSuccessView>
    //   <StyledAccelerateSuccessContent gameImg={gameInfo?.image || defaultBigImg}>
    //     <div className='game-name'>{gameInfo?.name}</div>
    //     <StyledSpeedInfoRow>
    //       <div className='area-server'>
    //         <div className='node-name'>{speedNodeInfo}</div>
    //         <div className='change-node pointer' onClick={onChangeSpeedNode}>
    //           <SwitchIcon />
    //         </div>
    //       </div>
    //     </StyledSpeedInfoRow>
    //     <StyledSpeedDetail>
    //       <StyledSpeedNumberInfo>
    //         <div className='info-item right-padding with-line'>
    //           <div className='number'>{speedRunningTime}</div>
    //           <div className='label'>
    //             <TimeIcon />
    //             已加速时长
    //           </div>
    //         </div>
    //         <div className='info-item with-line'>
    //           <div
    //             className={classNames('number', {
    //               good: nodeDelay <= 90,
    //               general: nodeDelay > 90 && nodeDelay <= 120,
    //               bad: nodeDelay > 120,
    //             })}
    //           >
    //             <span>{speedingInfo?.nodeDelay || 0}</span>
    //             <span className='mini-size'>ms</span>
    //           </div>
    //           <div className='label'>
    //             <SportIcon />
    //             当前延迟
    //           </div>
    //         </div>
    //         <div className='info-item'>
    //           <div className='number'>
    //             <span>{parseInt(speedingInfo?.nodeLoss || '0', 10)}</span>
    //             <span className='mini-size'>%</span>
    //           </div>
    //           <div className='label'>
    //             <DataIcon />
    //             丢包率
    //           </div>
    //         </div>
    //       </StyledSpeedNumberInfo>
    //     </StyledSpeedDetail>
    //     <StyledStopSpeedButton onClick={onStopSpeed}>停止加速</StyledStopSpeedButton>
    //     {gameInfo?.categoryId === 2 && (
    //       <StyledSpeedTip>
    //         <div className='speed-tip'>
    //           <SpeedIcon />
    //           <span>已同时加速游戏平台</span>
    //         </div>
    //         <div className='speed-tip'>
    //           <SpeedIcon />
    //           <span>已同时加速游戏下载</span>
    //         </div>
    //       </StyledSpeedTip>
    //     )}
    //   </StyledAccelerateSuccessContent>
    // </StyledAccelerateSuccessView>
  )
}

const StyledDeviceInfoContent = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 380px;
  border-radius: 4px;
  background: ${props => props.theme.color.bg.$FFFFFF06};
  backdrop-filter: blur(30px);
  padding: 14px 16px;
  .deviceinfo-title {
    font-size: 14px;
    font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 16px;
    position: relative;
    .deviceinfo-extra-box {
      ${props => props.theme.flexAlignCenter}
      position: absolute;
      right: 0;
      top: 0;
      .deviceinfo-extra-item {
        cursor: pointer;
        margin-right: 16px;
        font-size: 12px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: ${props => props.theme.color.text.$999999};
        line-height: 14px;
        &:hover {
          color: var(--text-color-FFFFFF);
        }
        &.active {
          color: ${props => props.theme.color.mainColor};
        }
      }
    }
  }
  .deviceinfo-charts-box {
    height: 110px;
  }
  .deviceinfo-graph-content {
    ${props => props.theme.flexAlignCenter}
    justify-content: space-between;
    margin-bottom: 40px;
    .deviceinfo-graph-step-box {
      width: 46px;
      min-width: 46px;
      .deviceinfo-graph-step-text {
        margin-top: 10px;
        font-size: 12px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 16px;
      }
    }
    .deviceinfo-graph-transfer-box {
      text-align: center;
      position: relative;
      flex: 1;
      height: 1px;
      background: rgba(195, 140, 255, 0.4);
      margin: -32px 16px 0;
      font-size: 12px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: ${props => props.theme.color.text.$999999};
      line-height: 16px;
      .deviceinfo-delay-text {
        top: -8px;
        left: 50%;
        transform: translate(-50%, -100%);
        position: absolute;
      }
      .deviceinfo-loss-text {
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
      }
    }
  }
`

const StyledCurrentSpeedInfoContent = styled.div`
  ${props => props.theme.flexCenter}
  margin-top: 24px;
  width: 100%;
  height: 98px;
  border-radius: 4px;
  background: ${props => props.theme.color.bg.$FFFFFF06};
  backdrop-filter: blur(30px);
  .speedinfo-item {
    ${props => props.theme.flexCenter}
    flex: 1;
    flex-direction: column;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 40px;
      border: 1px solid ${props => props.theme.color.bor.$FFFFFF12};
    }
    &:nth-child(1) {
      &::before {
        display: none;
      }
      .main {
        color: ${props => props.theme.color.text.$DF9FFC};
      }
    }
    &:nth-child(2) {
      .main {
        &.good {
          color: ${props => props.theme.color.text.$10FAB8};
        }
        &.general {
          color: #ffd447;
        }
        &.bad {
          color: #ec3a00;
        }
      }
    }
    &:nth-child(3) {
      .main {
        color: ${props => props.theme.color.text.$FEB731};
      }
    }
    .main {
      display: flex;
      align-items: baseline;
      font-size: 24px;
      line-height: 24px;
      font-family: Impact-Regular, Impact;
      .unit {
        font-size: 12px;
      }
    }
    .text {
      margin-top: 10px;
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$999999};
    }
  }
`

const StyledCurrentAreaServerContent = styled.div`
  width: 100%;
  padding: 16px 24px;
  background: ${props => props.theme.color.bg.$FFFFFF06};
  backdrop-filter: blur(30px);
  border-radius: 4px;
  .areaserver-row {
    ${props => props.theme.flexAlignCenter}
    justify-content: space-between;
    .areaserver-text {
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$F0F0F0};
    }
    .areaserver-switch {
      ${props => props.theme.flexAlignCenter}
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      color: ${props => props.theme.color.mainColor};
      .switch-text {
        margin-right: 8px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const StyledSectionBox = styled.div`
  padding: 32px 32px 0;
  .title {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    margin-bottom: 12px;
  }
`

export default observer(SpeedSuccess)

const StyledAccelerateSuccessView = styled.div`
  padding: 30px 40px 0;
`

const StyledAccelerateSuccessContent = styled.div<{ gameImg: string }>`
  position: relative;
  z-index: 1;
  height: 268px;
  padding: 20px 0 20px 30px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  ${props => `background-image:url(${props.gameImg});`}
  background-size: 100% 100%;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(23, 29, 62, 0.2);
    z-index: -1;
  }
  .game-name {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    line-height: 30px;
    margin-bottom: 10px;
  }
`

const StyledSpeedInfoRow = styled.div`
  display: flex;
  align-items: center;
  .area-server {
    display: flex;
    align-items: center;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    font-weight: 400;
    color: #b2b2b2;
    background: #202126;
    .node-name {
      flex: 1;
      color: #c2c3d1;
      padding: 0 12px;
      border-radius: 2px 0 0 2px;
    }
    .change-node {
      width: 40px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      text-align: center;
      background: #34363d;
      border-radius: 0px 2px 2px 0px;
      &:hover {
        color: #fff;
      }
      svg {
        vertical-align: middle;
      }
    }
  }
`

const StyledSpeedDetail = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`

const StyledSpeedNumberInfo = styled.div`
  display: flex;
  align-items: center;
  .info-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:not(.right-padding) {
      padding: 0 20px;
    }
    &.right-padding {
      padding-right: 40px;
    }
    &.with-line {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 50px;
        background: rgba(255, 255, 255, 0.24);
      }
    }
    .number {
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 7px;
      .mini-size {
        margin-left: 2px;
        font-size: 13px;
      }
      &.good {
        color: #1edf43;
      }
      &.general {
        color: #ffd447;
      }
      &.bad {
        color: #ec3a00;
      }
    }
    .label {
      font-size: 13px;
      color: #b2b2b2;
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
      }
    }
  }
`

const StyledStopSpeedButton = styled(ConfirmButton)`
  user-select: none;
  width: 120px;
  margin-bottom: 25px;
`
const StyledSpeedTip = styled.div`
  ${props => props.theme.flexAlignCenter}
  margin-top: 24px;
  .speed-tip {
    font-size: 0;
    ${props => props.theme.flexAlignCenter}
    color: ${props => props.theme.color.text.$BBBBBB};
    margin-right: 40px;
    span {
      font-size: 14px;
      margin-left: 4px;
    }
    svg {
      margin-right: 8px;
    }
  }
`
