import { observable, action } from 'mobx'
import { SpeedStore } from 'src/store'
import { message } from 'antd'
import { sleep } from 'src/tools/common'
import { imperativeConfirmModal } from 'src/components/base-modal'
import CEFTransfer from 'src/CEFTransfer'

export enum RepairType {
  REPAIR_NETWORK = 'repairNetwork',
  REPAIR_DNS = 'repairDNS',
}

export enum RepairStatus {
  NORMAL = 'normal',
  PROCESS = 'process',
}

class Repair {
  @observable
  private repairStatus: Record<RepairType, RepairStatus> = {
    [RepairType.REPAIR_NETWORK]: RepairStatus.NORMAL,
    [RepairType.REPAIR_DNS]: RepairStatus.NORMAL,
  }

  @action
  handleRepair = async (key: RepairType, onSuccess?: () => void, onError?: (error: any) => void) => {
    const speedInfo = SpeedStore.hasSpeeding()
    if ((!!speedInfo && SpeedStore.speedStatus === 'starting') || SpeedStore.isSwitchSpeed) {
      message.warn('正在启动加速服务,若加速失败后再进行修复')
      return
    }
    if (!!speedInfo) {
      const isConfirm = await imperativeConfirmModal({
        title: '是否停止加速',
        content: '当前正在加速中,修复需要停止当前加速',
        okText: '停止加速',
        cancelText: '取消',
      })
      if (!isConfirm.value) {
        return
      }
      await SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_STOP' })
      SpeedStore.isSwitchSpeed = false
    }
    if (this.repairIsProceeAll()) {
      message.warn('当前正在进行网络修复,请等待完成后再进行操作')
      return
    }
    this.repairStatus[key] = RepairStatus.PROCESS
    let now = Date.now()
    try {
      // await CEFTransfer.sendToClient(key, {}, { timeout: 10 * 1000 })
      await CEFTransfer.sendToClient(key)
      const diff = Date.now() - now
      diff < 3000 && (await sleep(3000 - diff))
      onSuccess?.()
    } catch (error) {
      onError?.(error)
    } finally {
      this.repairStatus[key] = RepairStatus.NORMAL
    }
  }

  getRepairStatus = (key: RepairType) => {
    return this.repairStatus[key]
  }

  repairIsProcee = (key: RepairType) => {
    return this.repairStatus[key] === RepairStatus.PROCESS
  }

  repairIsProceeAll = () => {
    const keys = Object.keys(this.repairStatus) as RepairType[]
    return keys.find((f: RepairType) => this.repairIsProcee(f))
  }
}

export const RepairStore = new Repair()
