export const CLOUD_GAME_EXIT_TYPE = {
  /** 没有空闲机器 */
  NOT_ENOUGH_MACHINES: {
    code: 10001,
    message: '暂无空闲机器',
    reason: '暂无空闲机器',
  },
  /** WS未连接 */
  WS_NOT_CONNECTED: {
    code: 10002,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: 'WS未连接',
  },
  /** WS消息通知启动超时 */
  START_TIME_OUT: {
    code: 10003,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: 'WS消息通知启动超时',
  },
  /** 本地定时器检验启动超时 */
  CLIENT_START_TIME_OUT: {
    code: 10004,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '本地定时器检验启动超时',
  },
  /** 云游戏窗口崩溃,客户端通知关闭窗口退出云游戏 */
  CLOUD_GAME_WINDOW_CRASH: {
    code: 10005,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '云游戏窗口崩溃,客户端通知关闭窗口退出云游戏',
  },
  /** WS断开连接消息通知(正常收不到此消息) */
  CLOUD_GAME_WSS_DISCONNECT: {
    code: 10006,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: 'WS断开连接消息通知',
  },
  /** 反外挂通知退出游戏 */
  CLOUD_GAME_QUIT_BY_FWG: {
    code: 10007,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '反外挂通知退出游戏',
  },
  /** 用户剩余云游戏时长不足 */
  USER_NOT_ENOUGH_CLOUD_TIME: {
    code: 10008,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '用户剩余云游戏时长不足',
  },
  /** 该游戏不支持云游戏模式 */
  NOT_SUPPORT_CLOUD_GAME: {
    code: 10009,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '该游戏不支持云游戏模式',
  },
  /** 创建会话连接失败 */
  CONNECT_SESSION_CREATE_FAILED: {
    code: 10010,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '创建会话连接失败',
  },
  /** SDK断开连接 */
  SDK_DISCONNECT: {
    code: 10011,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: 'SDK断开连接',
  },
  /** 用户主动点击退出游戏 */
  USER_OPTED_OUT: {
    code: 10012,
    message: '用户主动退出',
    reason: '用户主动退出',
  },
  /** 踢下线 */
  KICK_OFF_THE_LINE: {
    code: 10013,
    message: '系统检测到您在其他终端设备启动了其他游戏，已帮您自动结束当前游戏',
    reason: '踢下线',
  },
  /** 云游戏时长消耗完毕 */
  CLOUDGAME_TIMEOUT: {
    code: 10014,
    message:
      '尊贵的用户，您的云游戏时长已消耗完毕，再次感谢您使用，如果您还想请继续使用云游戏进行游戏，请尽快进行充值！',
    reason: '云游戏时长消耗完毕',
  },
  NO_CHROME: {
    code: 10015,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '未安装chrome',
  },
  SDK_LOAD_FAIL: {
    code: 10016,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: 'sdk加载失败',
  },
  SDK_INIT_TIMEOUT: {
    code: 10017,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: 'sdk初始化超时',
  },
  CLOUD_GAME_PAGE_LOAD_TIMEOUT: {
    code: 10018,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '云游戏页面加载超时',
  },
  CLOUD_GAME_PAGE_WS_ERROR: {
    code: 10019,
    message:
      '尊贵的用户，由于未知原因造成了云游戏的崩溃，对您造成的不便我们致以最大的歉意，我们已帮您结束云游戏时长计费，谢谢！ ',
    reason: '云游戏页面ws通信错误',
  }
}

export enum CLOUD_GAME_PROGRESS_STATUS {
  /** 申请云游戏设备 */
  LOCK_DEVICE = 1,
  /** 云游戏挂盘中 */
  GAME_INSTALLING = 2,
  /** 平台拉起中 */
  PLATFORM_PULL = 3,
  /** 平台登录成功 */
  PLATFORM_LOGIN = 4,
  /** 游戏拉起 */
  GAME_PULL = 5,
}
