import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import classNames from 'classnames'

type TSwitch = {
  onChange?: (e: boolean) => void
  check?: boolean
}
const Switch: FC<TSwitch> = ({ onChange, check }) => {
  const hasCheck = typeof check !== 'undefined'
  const [active, setActive] = useState<boolean>(false)
  const finalActive = hasCheck ? !!check : active
  const handleChange = () => {
    onChange?.(!finalActive)
    if (!hasCheck) {
      setActive(!finalActive)
    }
  }
  return (
    <StyledSwitchContainer className={classNames('sg-switch', { active: finalActive })} onClick={() => handleChange()}>
      <div className='handler'>{finalActive ? '开启' : '关闭'}</div>
    </StyledSwitchContainer>
  )
}

const StyledSwitchContainer = styled.div`
  width: 54px;
  height: 20px;
  background: ${props => props.theme.color.bg.$444648};
  border-radius: 13px;
  cursor: pointer;
  position: relative;
  .handler {
    width: 40px;
    height: 20px;
    background: ${props => props.theme.color.bg.$FFFFFF30};
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 12px;
    color: ${props => props.theme.color.text.$1E1E20};
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(0px);
    transition: all 300ms ease 0s;
  }
  &.active {
    .handler {
      transform: translateX(14px);
      background: ${props => props.theme.color.text.$C598FF};
    }
  }
`

export default observer(Switch)
