import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useRef } from 'react'
import { LoginStore } from 'src/store/modifier'
import { FadeinImage } from 'src/components/base-image'
import { checkOversea, handleShowLogin, openServiceView } from 'src/tools/common'
import { appStore, GameStore } from 'src/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { emitter } from 'src/tools/emitter'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import classNames from 'classnames'
import styled from 'styled-components'
import PlusIcon from 'src/assets/icons/common/aside-home.svg'
import VipIcon from 'src/assets/icons/common/aside-vip.svg'
import CDKIcon from 'src/assets/icons/common/aside-cdk.svg'
import CustomerIcon from 'src/assets/icons/common/aside-customer.svg'
import SettingsIcon from 'src/assets/icons/common/aside-settings.svg'
import Logo from 'src/assets/icons/common/logo.svg'

const Aside = () => {
  const gamesScrollBoxEl = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const userInfo = LoginStore.userinfo
  const baseUserInfo = LoginStore.userBaseInfo
  const navigate = useNavigate()
  const isOversea = checkOversea()

  const query = new URLSearchParams(location.search)

  const isDetailPage = location.pathname === '/game-detail'

  const jumpToDetail = (type: 'gameMaster' | 'platform', id: string | number, branchId?: string | number) => {
    if (type === 'gameMaster') {
      navigate(`/game-detail?type=game&gameLibraryMasterId=${id}&branchId=${branchId}`, { replace: true })
    } else {
      navigate(`/game-detail?type=platform&platformId=${id}`, { replace: true })
    }
    emitter.emit('inDetailToDetail')
  }

  const checkAndHandleGamesScrollBoxShadowDisplay = () => {
    const el = gamesScrollBoxEl.current
    if (!el) {
      return
    }
    const isTop = el.scrollTop === 0
    const isBottom = el.scrollHeight - el.clientHeight === el.scrollTop
    document.documentElement.style.setProperty('--aside-games-scroll-top-display', isTop ? 'none' : 'block')
    document.documentElement.style.setProperty('--aside-games-scroll-bottom-display', isBottom ? 'none' : 'block')
  }

  //当前查看的游戏是否已安装
  const isCurrentGameLibraryMasterInstalled = useMemo(() => {
    if (appStore.currentDetail?.type !== 'game') {
      return false
    }
    return GameStore.installedGameList.some(
      v =>
        v.gameLibraryMasterId === (appStore.currentDetail?.detail as Game.GameLibraryMasterDetail)?.gameLibraryMasterId,
    )
  }, [GameStore.installedGameList, appStore.currentDetail])

  //当前查看的平台是否已安装
  const isCurrentPlatformInstalled = useMemo(() => {
    if (appStore.currentDetail?.type !== 'platform') {
      return false
    }
    return GameStore.installedPlatformList.some(
      v => v.platformId === (appStore.currentDetail?.detail as Game.PlatformDetail)?.platformId,
    )
  }, [GameStore.installedPlatformList, appStore.currentDetail])

  const currentItem = useMemo(() => {
    if (!appStore.currentDetail) {
      return null
    }
    if (appStore.currentDetail.type === 'platform') {
      return {
        type: 'platform',
        img: (appStore.currentDetail.detail as Game.PlatformDetail).platformIcon,
        id: (appStore.currentDetail.detail as Game.PlatformDetail).platformId,
      }
    }
    return {
      type: 'game',
      img: (appStore.currentDetail.detail as Game.GameLibraryMasterDetail).squareImage,
      id: (appStore.currentDetail.detail as Game.GameLibraryMasterDetail).gameLibraryMasterId,
      subId: (appStore.currentDetail.detail as Game.GameLibraryMasterDetail).gameLibraryBranchId,
    }
  }, [appStore.currentDetail])

  useEffect(() => {
    window.addEventListener('resize', checkAndHandleGamesScrollBoxShadowDisplay)
    return () => {
      window.removeEventListener('resize', checkAndHandleGamesScrollBoxShadowDisplay)
    }
  }, [])

  return (
    <StyledAside>
      {/* <StyledAvatar>
        {userInfo && baseUserInfo?.avatar ? (
          <div className='avatar' onClick={() => showUserCenter(NAV_KEY_MAPPING.SELF_INFO)}>
            <FadeinImage style={{ fontSize: 0 }} width={48} height={48} type='notDefault' src={baseUserInfo.avatar} />
          </div>
        ) : (
          <div className='avatar default' onClick={handleShowLogin}>
            <Logo />
          </div>
        )}
      </StyledAvatar> */}
      <StyledMore
        onClick={() => navigate('/', { replace: true })}
        className={classNames('item', {
          active: location.pathname.includes('/main/') || location.pathname === '/',
        })}
      >
        <div className='icon'>
          <PlusIcon />
        </div>
      </StyledMore>
      <StyledGames>
        <div className='games-scroll-box' onScroll={checkAndHandleGamesScrollBoxShadowDisplay} ref={gamesScrollBoxEl}>
          {isDetailPage &&
            currentItem &&
            !(currentItem.type === 'platform' ? isCurrentPlatformInstalled : isCurrentGameLibraryMasterInstalled) && (
              <div
                className={classNames('item game other active')}
                onClick={() => {
                  if (currentItem.type === 'platform') {
                    jumpToDetail('platform', currentItem.id)
                  } else {
                    jumpToDetail('gameMaster', currentItem.id, currentItem.subId)
                  }
                }}
              >
                <div className='icon'>
                  <FadeinImage
                    style={{ fontSize: 0 }}
                    width={36}
                    height={36}
                    type='notDefault'
                    src={currentItem?.img}
                  />
                </div>
              </div>
            )}
          {GameStore.installedGameList?.map((d, index) => {
            if (
              isOversea &&
              !d.gameLibraryBranches.some(value => value.relates.some(v => v.targetRelateType === 'TRIAL_GAME'))
            ) {
              return null
            }
            return (
              <div
                className={classNames('item game', {
                  active:
                    isDetailPage &&
                    d.gameLibraryMasterId ===
                      (Number(query.get('gameLibraryMasterId') ?? '') ||
                        (appStore.currentDetail?.detail as Game.GameLibraryMasterDetail)?.gameLibraryMasterId),
                })}
                key={`${d.gameLibraryMasterCnName}-${d.gameLibraryMasterId}`}
                onClick={() => jumpToDetail('gameMaster', d.gameLibraryMasterId, d.gameLibraryBrancheId)}
              >
                <div className='icon'>
                  <FadeinImage
                    style={{ fontSize: 0 }}
                    width={36}
                    height={36}
                    type='notDefault'
                    src={d.gameLibraryMasterSquareImage || d.gameLibraryMasterLongImage}
                  />
                </div>
              </div>
            )
          })}
          {!isOversea &&
            GameStore.installedPlatformList?.map((d, index) => {
              return (
                <div
                  className={classNames('item game', {
                    active:
                      isDetailPage &&
                      d.platformId ===
                        (Number(query.get('platformId') ?? '') ||
                          (appStore.currentDetail?.detail as Game.PlatformDetail)?.platformId),
                  })}
                  key={`${d.platformId}`}
                  onClick={() => jumpToDetail('platform', d.platformId)}
                >
                  <div className='icon'>
                    <FadeinImage
                      style={{ fontSize: 0 }}
                      width={36}
                      height={36}
                      type='notDefault'
                      src={d.platformIcon || d.platformLongImage}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </StyledGames>
      <StyledAsideFooterContainer>
        <StylefFooterAction
          onClick={() => {
            const isLogin = userInfo && baseUserInfo?.avatar
            if (!isLogin) {
              handleShowLogin()
            } else {
              showUserCenter(NAV_KEY_MAPPING.VIP)
            }
          }}
        >
          <VipIcon />
        </StylefFooterAction>
        <StylefFooterAction
          onClick={() => {
            const isLogin = userInfo && baseUserInfo?.avatar
            if (!isLogin) {
              handleShowLogin()
            } else {
              showUserCenter(NAV_KEY_MAPPING.CDK_EXCHANGE)
            }
          }}
        >
          <CDKIcon />
        </StylefFooterAction>
        <StylefFooterAction
          onClick={() => {
            if (isOversea) {
              appStore.contactVisible = true
            } else {
              openServiceView()
            }
          }}
        >
          <CustomerIcon />
        </StylefFooterAction>
        <StylefFooterAction
          onClick={() => {
            const isLogin = userInfo && baseUserInfo?.avatar
            if (!isLogin) {
              handleShowLogin()
            } else {
              showUserCenter(NAV_KEY_MAPPING.SYSTEM_SETTING)
            }
          }}
        >
          <SettingsIcon />
        </StylefFooterAction>
      </StyledAsideFooterContainer>
    </StyledAside>
  )
}

const StylefFooterAction = styled.div`
  height: 56px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b5bac1;
  cursor: pointer;
  &:hover {
    color: #7044cd;
  }
`

const StyledAsideFooterContainer = styled.div`
  height: 264px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
`

const StyledMore = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #b5bac1;
  .icon {
    /* background: #7044cd !important; */
    filter: none !important;
    background: transparent !important;
  }
  &.active,
  &:hover {
    background: #26282b;
    color: #fff;
  }
`
const StyledAvatar = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 2px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 32px;
    height: 2px;
    background: #373a3f;
    border-radius: 2px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .default {
    background: #36393f;
  }
`

const StyledGames = styled.div`
  position: relative;
  .game {
    width: 72px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .games-scroll-box {
    height: calc(100vh - 80px - 264px);
    overflow: overlay;
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    width: 72px;
    background: linear-gradient(0deg, rgba(28, 29, 31, 0) 0%, #1c1d1f 100%);
    z-index: 1;
    display: var(--aside-games-scroll-top-display);
  }
  &::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    width: 72px;
    background: linear-gradient(180deg, rgba(28, 29, 31, 0) 0%, #1c1d1f 100%);
    z-index: 1;
    display: var(--aside-games-scroll-bottom-display);
  }
`

const StyledAside = styled.aside`
  width: 72px;
  background: #1c1d1f;
  height: 100%;
  display: flex;
  flex-direction: column;
  .item {
    position: relative;
    .icon {
      /* filter: grayscale(100%); */
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      transition: all 0.3s;
      cursor: pointer;
      overflow: hidden;
      background: #36393f;
      &:hover {
        border-radius: 12px;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 56px;
      background: #7044cd;
      opacity: 0;
      /* border-radius: 0px 4px 4px 0px; */
      transition: all 0.3s;
    }
    &.other {
      &:after {
        content: '';
        display: block;
        width: 32px;
        height: 2px;
        background: #373a3f;
        border-radius: 2px;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.active,
    &:hover {
      background: #26282b;
      .icon {
        filter: none;
        border-radius: 12px;
      }
    }
    &.active {
      &:before {
        opacity: 1;
      }
    }
  }
`

export default observer(Aside)
