import React, { useEffect, useState } from 'react'
import { throttle } from 'lodash'

const useHomeListCalcSize=(containerRef: React.RefObject<HTMLElement>,size:{
  CONTAINER_PADDING:number,
  ITEM_MARGIN_RIGHT:number,
  ITEM_MIN_WIDTH:number,
  ITEM_MAX_WIDTH:number,
  ITEM_IMAGE_ASPECT_RATIO:number
},listLength:number)=>{
  const [viewNum, setViewNum] = useState(4)
  const [offsetIndex, setOffsetIndex] = useState(0)
  const {
    CONTAINER_PADDING,
    ITEM_MARGIN_RIGHT,
    ITEM_MAX_WIDTH,
    ITEM_MIN_WIDTH,
    ITEM_IMAGE_ASPECT_RATIO
  } = size

  useEffect(() => {
    const handleResize = throttle(
      (boxWidth?:number) => {
        if (!containerRef.current) {
          return
        }
        const content = containerRef.current
        const contentTrueWidth = (boxWidth || content.offsetWidth) - CONTAINER_PADDING * 2 + ITEM_MARGIN_RIGHT
        const maxElementNum = Math.floor(contentTrueWidth / ITEM_MAX_WIDTH)
        const minElementNum = Math.floor(contentTrueWidth / ITEM_MIN_WIDTH)
        const trueElementNum = Math.max(minElementNum, maxElementNum, 1)
        const trueElementWidth = contentTrueWidth / trueElementNum - ITEM_MARGIN_RIGHT
        const trueElementImgHeight = trueElementWidth / ITEM_IMAGE_ASPECT_RATIO
        const trueElementItemHeight = trueElementImgHeight + 51
        const itemSizeObj = {
          containerHeight: trueElementItemHeight,
          blockWidth: trueElementWidth,
          blockHeight: trueElementItemHeight,
          blockImgHeight: trueElementImgHeight,
          blockNumByRow: trueElementNum,
        }
        //       --install-game-block-width: 173px;
        // --install-game-img-height: 0px;
        content.style.setProperty('--install-game-block-width', `${itemSizeObj.blockWidth}px`)
        content.style.setProperty('--install-game-block-height', `${itemSizeObj.blockHeight}px`)
        content.style.setProperty('--install-game-container-height', `${itemSizeObj.containerHeight}px`)
        content.style.setProperty('--install-game-img-height', `${itemSizeObj.blockImgHeight}px`)
        setViewNum(trueElementNum)
        // content.style.setProperty('--block-img-height', `${itemSizeObj.blockImgHeight}px`)
      },
      100,
      { trailing: true },
    )
    // window.addEventListener('resize', handleResize)
    handleResize()

    const ob= new ResizeObserver((entries)=>{
      handleResize(entries[0].contentRect.width)
    })

    containerRef.current && ob.observe(containerRef.current)


    return () => {
      // window.addEventListener('resize', handleResize)
      ob.disconnect()
    }
  }, [listLength])

  useEffect(() => {
    if (viewNum >= listLength) {
      setOffsetIndex(0)
    }
  }, [viewNum, listLength])


  return {
    viewNum,
    setViewNum,
    offsetIndex,
    setOffsetIndex,
    listLength,
  }
}



export default useHomeListCalcSize