import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'

export const getAuth = () => {
  const userInfo = localStorageTool.get(LOCALSTORAGE_KEYS.AUTH_INFO)
  if (userInfo) {
    return userInfo.token
  }
  return ''
}

export const getGJAuth = () => {
  const userInfo = localStorageTool.get(LOCALSTORAGE_KEYS.AUTH_INFO_GJ)
  if (userInfo) {
    return userInfo.token
  }
  return ''
}
