import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { throttle } from 'lodash'
import { appStore, GameStore } from 'src/store'
import { SearchInput } from 'src/components/base-input'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import SGCheckbox from 'src/components/base-checkbox'
import GameItem from 'src/pages/home-en/game-item'
import styled from 'styled-components'
import classNames from 'classnames'
import useUrlState from '@ahooksjs/use-url-state'
import emptyImg from 'src/assets/images/common/empty.png'
import SearchIcon from 'src/assets/icons/layout/search-icon-1.svg'
import TopIcon from 'src/assets/icons/common/top.svg'
import useLayoutLeft from 'src/hooks/use-layout-left'
import homeBg from 'src/assets/images/home-new/homebg.png'
import UpIcon from 'src/assets/images/home-new/up.svg'

type TSortKey = 'normal' | 'updatetime'
type TSortRule = 'desc' | 'asc' | ''

export type TElementSizeObj = {
  blockWidth: number
  blockHeight: number
  blockImgHeight: number
  blockNumByRow: number
}

// export type TVirtualListObj = {
//   startIndex: number
//   endIndex: number
// }

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const filterKeywords = (k: string, list: Game.GameLibraryMaster[]) => {
  if (!k) {
    return list
  }
  return list.filter(f => {
    const originName = f.gameLibraryMasterEnName?.toLowerCase() || ''
    const originZhCnName = f.gameLibraryMasterCnName?.toLowerCase() || ''
    return originName.includes(k) || originZhCnName.includes(k)
  })
  // return list.filter(f => {
  //   const aliasList = f.alias.split(',').map(d => d.toLowerCase())
  //   const originName = f.name?.toLowerCase() || ''
  //   const originZhCnName = f.zhCnName?.toLowerCase() || ''
  //   return aliasList.find(alias => alias.includes(k)) || originName.includes(k) || originZhCnName.includes(k)
  // })
}

const filterIsInstalled = (type: '1' | '0') => {
  if (['1'].includes(type)) {
    // return GameStore.gameMasterList
    return [...GameStore.installedGameList]
  }
  return [...GameStore.installedGameList, ...GameStore.notInstalledGameList]
}

const computedKeySortWeight = (d: Game.GameLibraryMaster, key: string) => {
  const cName = d.gameLibraryMasterCnName.toLocaleLowerCase()
  const eName = d.gameLibraryMasterEnName.toLocaleLowerCase()
  /**
   * 0 中文名精确匹配
   * 1 英文名精确匹配
   * 2 别名精确匹配
   * 3 中文名模糊匹配
   * 4 英文模糊匹配
   * 5 别名模糊匹配
   */
  if (cName === key) {
    return 0
  }
  if (eName === key) {
    return 1
  }
  let res = 5
  if (eName.includes(key)) {
    res = 4
  }
  if (cName.includes(key)) {
    res = 3
  }
  if (d.gameLibraryMasterAlias) {
    for (let z of d.gameLibraryMasterAlias.split(',')) {
      if (z === key) {
        res = 2
        return res
      }
      if (z.includes(key)) {
        res = Math.min(res, 5)
      }
    }
  }
  return res
}

const sortList = (list: Game.GameLibraryMaster[], key: string, time: string): Game.GameLibraryMaster[] => {
  const keywords = key?.trim().toLocaleLowerCase()
  return list.sort((a, b) => {
    const aWeight = keywords ? computedKeySortWeight(a, keywords) : 0
    const bWeight = keywords ? computedKeySortWeight(b, keywords) : 0
    if (aWeight === bWeight) {
      if(time){
        const aTime = Number(a.updateTime)
        const bTime = Number(b.updateTime)
        return time === '1' ? aTime - bTime : bTime - aTime
      } else {
        return a.gameLibraryMasterSort - b.gameLibraryMasterSort
      }
    }
    return aWeight - bWeight
  })
}

const CONTENT_PADDING_LEFT = 32
const ITEM_MARGIN_RIGHT = 24
const ITEM_MIN_WIDTH = 158 + ITEM_MARGIN_RIGHT
const ITEM_MAX_WIDTH = 172 + ITEM_MARGIN_RIGHT
const ITEM_MARGIN_BOTTOM = 24
const ITEM_IMAGE_ASPECT_RATIO = 1 / 1.5

const entryMap = {
  supportSpeed: 'speed',
  supportResource: 'resource',
  supportModifier: 'modifier',
  supportCloudGame: 'branch',
  supportTrial: 'trial',
}

const FIXED_SCROLL_TOP = 225

const GameSearch: FC = () => {
  const navigate = useNavigate()
  const [state, setState] = useUrlState(
    {
      isInstalled: '0',
      supportSpeed: '0',
      supportResource: '0',
      supportModifier: '0',
      supportCloudGame: '0',
      supportTrial: '1',
      keywords: '',
      sortKey: 'normal',
      sortRule: '',
      tags:'',
      time:'',
    },
    {
      navigateMode: 'replace',
    },
  )
  type TStateKey = keyof typeof state
  const ListContentRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [itemSizeObj, setItemSizeObj] = useState<TElementSizeObj>()
  const [tags, setTags] = useState<{ key: TStateKey; name: string }[]>(() => {
    let temp = []
    state.supportSpeed === '1' &&
      temp.push({
        key: 'supportSpeed' as TStateKey,
        name: '支持加速',
      })
    state.supportModifier === '1' &&
      temp.push({
        key: 'supportModifier' as TStateKey,
        name: '支持修改',
      })
    state.supportCloudGame === '1' &&
    temp.push({
      key: 'supportCloudGame' as TStateKey,
      name: '支持云游戏',
    })
    state.supportTrial === '1' &&
    temp.push({
      key: 'supportTrial' as TStateKey,
      name: '提供共享账号',
    })
    state.supportResource === '1' &&
      temp.push({
        key: 'supportResource' as TStateKey,
        name: '提供资源',
      })
    state.isInstalled === '1' &&
      temp.push({
        key: 'isInstalled' as TStateKey,
        name: '本地已安装',
      })
    return temp
  })
  // const [virtualListObj, setVirtualListObj] = useState<TVirtualListObj>()
  const [virtualListStartIndex, setVirtualListStartIndex] = useState<number | null>(null)
  const [virtualListEndIndex, setVirtualListEndIndex] = useState<number | null>(null)
  const [viewTopButon, setViewTopButton] = useState<boolean>(false)
  const [fixed, setFixed ]= useState<boolean>(false)

  const fixFlagRef = useRef(fixed)

  const filterOtherCond = (s: typeof state, list: Game.GameLibraryMaster[]) => {
    const key = (state.keywords?.trim() || '').toLowerCase()
    const tags = state.tags ? state.tags.split(',') : []
    const needFilter = !!key || !!tags

    return !needFilter ? list : list.filter(f => {
      if (key) {
        const originName = f.gameLibraryMasterEnName?.toLowerCase() || ''
        const originZhCnName = f.gameLibraryMasterCnName?.toLowerCase() || ''
        const valid =
          originName.includes(key) ||
          originZhCnName.includes(key) ||
          f.gameLibraryMasterAlias?.split(',').find(v => v.trim().toLowerCase().includes(key))
        if (!valid) {
          return false
        }
      }

      //标签筛选
      if (tags.length > 0) {
        const valid = tags.every((item: any) => f.tags?.us?.includes(item))
        if (!valid) {
          return false
        }
      }
      return true
    })
  }

  const allTrialGame=useMemo(()=>{
    return filterIsInstalled('0').filter(item => {
      return item.gameLibraryBranches.some(value => value.relates.some(v=>v.targetRelateType==='TRIAL_GAME'))
    })
  },[GameStore.gameMasterList, GameStore.installedGameList,GameStore.notInstalledGameList])

  const {
    data: finalTitleList,
    total
  } = useMemo(() => {
    if (GameStore.gameMasterList) {
      let list = allTrialGame
      const finalList = filterOtherCond(state, allTrialGame)
      list = sortList(finalList, state.keywords, state.time)
      const start = 0
      const end = list.length
      return {
        data: list.slice(start, end),
        total: list.length,
      }
    }
    return {
      data: [],
      total: 0,
    }
  }, [allTrialGame, state.tags, state.time, state.keywords])

  const allTags = useMemo(()=>{
    const tags = allTrialGame
      .filter(item => {
        return item.tags?.us
       })
      .map(item => item.tags.us)
      .flat()
      const frequencyMap = new Map<string,number>()
    // 统计元素出现的次数
      for (const item of tags) {
        if (frequencyMap.has(item)) {
          frequencyMap.set(item, (frequencyMap.get(item)||0) + 1)
        } else {
          frequencyMap.set(item, 1)
        }
      }
      // 将元素出现次数存储在一个新的数组中
      const frequencyArr = Array.from(frequencyMap.entries())
      // 根据元素出现次数对数组进行排序
      frequencyArr.sort((a, b) => b[1] - a[1])
      // 返回前20个元素
      return frequencyArr.slice(0, 20).map(value => value[0])
  },[allTrialGame])

  const scrollToTop = () => {
    if (ListContentRef.current) {
      ListContentRef.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }
  }

  const changeSortKey = (sort: TSortKey, rule: TSortRule) => {
    setState(value => ({ ...value, sortKey: sort, sortRule: rule }))
    scrollToTop()
  }

  const handleChangeIsInstalled = (key: TStateKey, name?: string) => {
    const isCheck = state[key] === '1'
    setState(value => ({ ...value, [key]: isCheck ? '0' : '1' }))
    if (isCheck) {
      setTags(tags.filter(f => f.key !== key))
    } else {
      setTags([
        ...tags,
        {
          key,
          name: name || '',
        },
      ])
    }
    scrollToTop()
  }

  const changeKeyword = (keywords: string) => {
    setState(value => ({ ...value, keywords }))
    scrollToTop()
  }

  // console.log(virtualListObj, 'virtualListObj')
  useEffect(() => {
    if (!itemSizeObj || !ListContentRef.current) {
      return
    }
    const handleListContentScroll = throttle(
      () => {
        if (!ListContentRef.current) {
          return
        }
        let virtualListStartIndex = 0
        let virtualListEndIndex = total
        const content = ListContentRef.current
        const scrollTop = ListContentRef.current.scrollTop
        // if (scrollTop === 0) {
        //   console.log('scrollTop')
        //   fixFlagRef.current=false
        //   setFixed(false)
        //   contentRef.current!.scrollTop = FIXED_SCROLL_TOP
        //   contentRef.current?.focus()
        // }
        const trueBlockPlaceholderHeight = itemSizeObj.blockHeight + ITEM_MARGIN_BOTTOM
        /** 当前可视区域高度 */
        const viewContentHeight = ListContentRef.current.offsetHeight
        /**
         * 上面隐藏行数
         */
        const topHideRowNum = Math.max(Math.floor(scrollTop / trueBlockPlaceholderHeight) - 1, 0)
        setViewTopButton(topHideRowNum > 0)
        /**
         * 显示行数
         * 先计算可视区域高度可以显示多少行，然后再上下各多渲染1行
         */
        const viewRowNum = Math.ceil(viewContentHeight / trueBlockPlaceholderHeight) + (topHideRowNum === 0 ? 0 : 1) + 3
        /** 应该渲染元素的数量，假如total少于需要渲染的元素数量可以就不用进行下面额度计算了 */
        const renderElementNum = Math.floor(viewRowNum * itemSizeObj.blockNumByRow)
        if (total <= renderElementNum) {
          content.style.setProperty('--virtualList-box-height', `0px`)
          setVirtualListStartIndex(virtualListStartIndex)
          setVirtualListEndIndex(virtualListEndIndex)
          return
        }
        const sumScrollHeight = Math.ceil(total / itemSizeObj.blockNumByRow) * trueBlockPlaceholderHeight
        content.style.setProperty('--virtualList-box-height', `${sumScrollHeight}px`)
        virtualListStartIndex = topHideRowNum * itemSizeObj.blockNumByRow
        virtualListEndIndex = virtualListStartIndex + viewRowNum * itemSizeObj.blockNumByRow
        setVirtualListStartIndex(virtualListStartIndex)
        setVirtualListEndIndex(virtualListEndIndex)
        return
      },
      100,
      { trailing: true },
    )
    ListContentRef.current.addEventListener('scroll', handleListContentScroll)
    handleListContentScroll()
    return () => {
      ListContentRef.current?.removeEventListener('scroll', handleListContentScroll)
    }
  }, [itemSizeObj, total])

  useEffect(() => {

    const handlerScroll= ()=>{
      if(fixFlagRef.current){
        return
      }
      const st = contentRef.current?.scrollTop??0
      fixFlagRef.current = st > FIXED_SCROLL_TOP
      setFixed(fixFlagRef.current)
      if(fixFlagRef){
        setTimeout(()=>{
          ListContentRef.current?.dispatchEvent(new MouseEvent('click'))
        },100)
      }
    }

    const handlerMousewheel = (e:any) => {
      if(e.deltaY>=0){
        return
      }
      const scrollTop = ListContentRef.current!.scrollTop
      if (scrollTop <= 0 && fixFlagRef.current) {
        fixFlagRef.current=false
        setFixed(false)
        contentRef.current!.scrollTop = FIXED_SCROLL_TOP
        contentRef.current?.focus()
      }
    }

    contentRef.current?.addEventListener('scroll', handlerScroll)
    contentRef.current?.addEventListener('mousewheel', handlerMousewheel);

    return () => {
      contentRef.current?.removeEventListener('scroll', handlerScroll)
      contentRef.current?.removeEventListener('mousewheel', handlerMousewheel)
    }
  }, [])

  useEffect(() => {
    if(!appStore.leftElement){
      return
    }

    const handleResize = throttle(
      () => {
        if (!ListContentRef.current) {
          return
        }
        const content = ListContentRef.current
        const contentTrueWidth = content.offsetWidth - CONTENT_PADDING_LEFT * 2 + ITEM_MARGIN_RIGHT
        const maxElementNum = Math.floor(contentTrueWidth / ITEM_MAX_WIDTH)
        const minElementNum = Math.floor(contentTrueWidth / ITEM_MIN_WIDTH)
        const trueElementNum = Math.max(maxElementNum, minElementNum, 1)
        const trueElementWidth = Math.floor(contentTrueWidth / trueElementNum - ITEM_MARGIN_RIGHT)
        const trueElementImgHeight = trueElementWidth / ITEM_IMAGE_ASPECT_RATIO
        const trueElementItemHeight = trueElementImgHeight + 55
        const itemSizeObj = {
          blockWidth: trueElementWidth,
          blockHeight: trueElementItemHeight,
          blockImgHeight: trueElementImgHeight,
          blockNumByRow: trueElementNum,
        }
        content.style.setProperty('--block-width', `${itemSizeObj.blockWidth}px`)
        content.style.setProperty('--block-height', `${itemSizeObj.blockHeight}px`)
        content.style.setProperty('--block-img-height', `${itemSizeObj.blockImgHeight}px`)
        setItemSizeObj(itemSizeObj)
      },
      100,
      { trailing: true },
    )
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [appStore.leftElement])



  useEffect(() => {
    GameStore.getAllData()
  }, [])

  const onTagsChange=(tag:string, v:boolean)=>{
    const tagsSet = new Set(state.tags ? state.tags.split(',') :[])
    if(v){
      tagsSet.add(tag)
    }else{
      tagsSet.delete(tag)
    }
    setState({
      ...state,
      tags:[...tagsSet].join(','),
    })
  }

  return (
    <StyledGameLibraryView>
      {useLayoutLeft(<StyledLeft>
        <div className="title">Narrow by tag</div>
        <ul className="tags">
          { allTags.length ? allTags.map((value, index) => {
            return <li key={value}>
              <SGCheckbox
                className="checkbox"
                checked={state.tags?.split(',').includes(value)}
                onChange={e =>onTagsChange(value, e.target.checked)}
              >
                {value}
              </SGCheckbox>
            </li>
          }): Array(12).fill('').map((value, index) => {
            return <li className='load' key={index}>
              <label className='loading'></label>
              <span className='loading'></span>
            </li>
          })}
        </ul>
      </StyledLeft>)}
      <StyledRight ref={contentRef}>
      <StyledGameLibraryHeader className={classNames({
        'is-fix': fixed
      })}>
        <div className='title'>Browse Mybuff support games</div>
        <div className='desc'><span>{allTrialGame?.length??'--'}</span> games with new titles added every week</div>
        <SearchInput
          className='search-input'
          placeholder='Eneter search term'
          value={state.keywords}
          onChange={changeKeyword}
          addonBefore={
            <StyledSearchButton className='pointer'>
              <SearchIcon className='search-icon' />
            </StyledSearchButton>
          }
        />
      </StyledGameLibraryHeader>
      <StyledHeaderRow>
        <div
          className={classNames('sort',{
            active:!state.time,
          })}
          onClick={()=>setState({
          ...state,
          time:''
        })}>Relevance</div>
        <div
          className={classNames('sort',{
            active: state.time,
          })}
          onClick={()=>setState({
          ...state,
          time: state.time === '0' ? '1' : '0'
        })}>
          <span>Release date</span>
          <div className='icon-box'>
            <UpIcon className={classNames('icon up',{
              active:state.time ==='1'
            })}/>
            <UpIcon className={classNames('icon down',{
              active: state.time==='0'
            })}/>
          </div>
        </div>
      </StyledHeaderRow>
      <StyledGameListContent className={classNames({
        'is-fix':fixed
      })} ref={ListContentRef} bodySizeHeight={appStore.bodySize?.height || 0}>
        <div
          className='box'
          style={{
            height: 'var(--virtualList-box-height)',
          }}
        >
          {finalTitleList.length === 0 && (
            <StyledEmptyContainer>
              <FadeinImage width={91} height={91.5} type='notDefault' src={emptyImg} />
              <div>MYBUFF does not currently provide a shared account for this game</div>
              <div>
                If you want to use this game sharing account, welcome to feedback to us through the service email
              </div>
              <div>Service email address：<span className='color' onClick={()=>cefOpenUrl('mailto:support@mybuff.com')}>support@mybuff.com</span></div>
            </StyledEmptyContainer>
          )}
          {finalTitleList.map((d, i) => {
            if (!itemSizeObj || virtualListStartIndex === null || virtualListEndIndex === null) {
              return null
            }
            if (i < virtualListStartIndex) {
              return null
            }
            if (i >= virtualListEndIndex) {
              return null
            }
            const rowNum = Math.floor(i / itemSizeObj.blockNumByRow)
            const colNum = i % itemSizeObj.blockNumByRow
            return (
              <GameItem
                isInstall={!!GameStore.installedGameList.find(f => f.gameLibraryMasterId === d.gameLibraryMasterId)}
                style={{ top: rowNum * (itemSizeObj.blockHeight + 24), left: colNum * (itemSizeObj.blockWidth + 24) }}
                key={`game-search-item-${d.gameLibraryMasterId}`}
                game={d}
                entry={tags?.[0] && entryMap[(tags[0].key) as keyof typeof entryMap]}
              />
            )
          })}
        </div>
      </StyledGameListContent>
      {viewTopButon && (
        <StyledViewTopButton className='pointer' onClick={scrollToTop}>
          <TopIcon />
        </StyledViewTopButton>
      )}
      </StyledRight>
    </StyledGameLibraryView>
  )
}

const StyledLeft=styled.div`
  width: 240px;
  background: #26282B;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title{
    height: 56px;
    line-height: 56px;    
    color: #96989d;
    font-size: 13px;
    padding-left: 20px;
  }
  .tags{
    flex: 1;
    overflow: overlay;
    margin-bottom: 0;
    >li{
      height: 56px;
      margin-bottom: 8px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      .ant-checkbox{
        transform: scale(.8);
      }
      .checkbox{
        //color: #96989D;
        font-size: 16px;      
      }
      &.load{    
        display: flex;
        align-items: center;
        .loading{
          background: #36393F;
          animation: blink 1.2s ease-in-out infinite;
        }
        label{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 12px;
        }
        span{
          flex: 1;
          height: 16px;
          background: #36393F;
          border-radius: 24px;
          margin-right: 20px;
        }
        
        @keyframes blink {
          50% {
            opacity: 0.64;
          }
        }
      }
    }
  }
`

const StyledRight=styled.div`
  flex: 1;  
  height: 100%;
  overflow: auto;
`

const StyledGameItemSkeletonContainer = styled.div`
  width: var(--block-width);
  height: var(--block-height);
  margin-right: 26px;
  margin-bottom: 20px;
  position: relative;
  .ant-skeleton-input {
    background: linear-gradient(
      90deg,
      rgba(190, 190, 190, 0.06) 25%,
      rgba(129, 129, 129, 0.1) 37%,
      rgba(190, 190, 190, 0.06) 63%
    );
    background-size: 400% 100%;
  }
  .box {
    height: var(--block-height);
    width: var(--block-width);    
  }
  .top {
    width: var(--block-width);
    height: var(--block-img-height);
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.06);
  }
  .bottom-1 {
    position: absolute;
    left: 12px;
    bottom: 38px;
    height: 17px;
    width: 83px;
    background: rgba(255, 255, 255, 0.06);
  }
  .bottom-2 {
    position: absolute;
    left: 12px;
    bottom: 18px;
    height: 12px;
    width: 115px;
    background: rgba(255, 255, 255, 0.06);
  }

  /* .bottom-box {
    height: 70px;
    width: var(--block-width);
  } */
`

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$C6C6C6};
  div {
    margin-top: 20px;
  }
  .color{
    color: #fff;
    cursor: pointer;
  }
`

const StyledFreeTips = styled.div`
  ${props => props.theme.flexAlignCenter}
  min-width: 229px;
  height: 30px;
  border-radius: 30px;
  background: ${props => props.theme.color.bg.$FFFFFF12};
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
  padding: 0 10px 0 4px;
  font-size: 13px;
  color: ${props => props.theme.color.text.$FFFFFF86};
  .main {
    ${props => props.theme.flexCenter}
    width: 38px;
    height: 22px;
    background: linear-gradient(136deg, #3faeff 0%, #9b29ff 100%);
    border-radius: 24px;
    margin-right: 8px;
    font-size: 13px;
    color: var(--text-color-FFFFFF);
    font-weight: 700;
  }
  .primary {
    color: ${props => props.theme.color.mainColor};
  }
`

const StyledSearchButton = styled.div`
  ${props => props.theme.flexCenter}
  height: 100%;
  padding: 0 8px 0 10px;
`

const StyledGameListContent = styled.div<{ bodySizeHeight: number }>`
  --block-width: 0px;
  --block-height: 0px;
  --block-img-height: 0px;
  display: flex;
  flex-wrap: wrap;
  /* height: 420px; */  
  padding-left: 32px;
  height: calc(100% - 32px - 43px - 32px - 44px);  
  position: relative;
  &.is-fix{
    overflow: auto;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .spin-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    color: #fff;
    font-size: 12px;
  }
  .empty-box {
    width: 100%;
    height: 476px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.color.text.$C6C6C6};
    div {
      margin-top: 20px;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledHeaderRow = styled.div`
  padding-left: 32px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  display: flex;  
  .sort{
    display: flex;
    align-items: center;
    color: #96989d;
    cursor: pointer;
    margin-right: 32px;
    &.active{
      color: #fff;
    }
    .icon-box{
      margin-left: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #96989d;
      .icon{
        font-size: 8px;
      }
      .down{
        transform: rotate(180deg);
      }
      .active{
        color: #fff;
      }
    }
  }
`

const StyledViewTopButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 0.5em;
  bottom: 0.5em;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  color: #fff;
  font-size: 28px;
  border-radius: 50%;
  svg {
    width: 15px;
    height: 18px;
  }
  &:hover {
    background: #2777ff;
  }
`

const StyledGameLibraryHeader = styled.div`
  background: url("${homeBg}") center center no-repeat;
  background-size: cover;
  height: 268px;
  margin: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 22px;
    color: #fff;
    line-height: 30px;
    text-align: center;
  }

  .desc {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.7);
  }

  .search-input {
    margin-top: 18px;
    width: 560px;
    height: 43px;

    .ant-input-affix-wrapper {
      background: #fff;
      transition: none;
    }

    .ant-input-group-addon {
      background: #fff;
      color: rgba(78, 80, 88, 0.6);
      font-size: 25px;
      transition: none;
    }

    .ant-input {
      color: #313338;
      font-size: 16px;
      flex: 1;
      margin-right: 10px;
      width: 100%;
      transition: none;

      &::placeholder {
        color: #313338;
      }
    }

    .ant-input-suffix {
      margin-right: 10px;
    }
  }

  &.is-fix {
    flex-direction: row;
    justify-content: flex-start;
    background: none;
    height: 43px;
    line-height: 43px;

    .search-input {
      width: 400px;
      margin-left: auto;
      margin-top: 0;

      .ant-input {
        height: 43px;
        color: #fff;

        &::placeholder {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .ant-input-group-addon {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 3px 0 0 3px;

        .search-icon {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .ant-input-affix-wrapper {
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0px 3px 3px 0;
      }
    }

    .desc {
      margin-left: 12px;
      margin-top: 0;
    }
  }

`

const StyledGameLibraryView = styled.div`
  display: flex;
  height: 100%;
`

export default observer(GameSearch)
