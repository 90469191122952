import React, { FC, useMemo, useState } from 'react'
import { message } from 'antd'
import { Router, Navigator } from 'react-router-dom'
import { createBrowserHistory, BrowserHistory } from 'history'

interface StackNode {
  pathname: string
  search: string
  state: any
}

class HistoryStack {
  history: BrowserHistory
  private currentIndex = -1
  private nodes: StackNode[] = []

  constructor(options?: {}) {
    this.history = createBrowserHistory(options)
    const { pathname, search, state } = this.history.location
    const node = { pathname, search, state }
    this.currentIndex++
    this.nodes.push(node)
    this.history.listen(({ location, action }) => {
      if (!navigator?.onLine) {
        message.warn('当前无网络连接')
      }
      const { pathname, search, state } = location
      const node = { pathname, search, state }
      const cusAction = (location.state as any)?.action
      if (cusAction === 'ADVANCE') {
        return
      }
      if (cusAction === 'BACK') {
        return
      }
      if (action === 'PUSH') {
        this.currentIndex++
        this.nodes.length = this.currentIndex
        this.nodes.push(node)
      } else if (action === 'POP') {
        this.nodes.pop()
        this.currentIndex--
        if (this.currentIndex < 0) this.currentIndex = 0
      } else if (action === 'REPLACE') {
        this.nodes.length = this.currentIndex
        this.nodes[this.currentIndex] = node
        console.log(this.currentIndex, 'this.currentIndex')
        console.log(this.nodes, 'this.nodes')
      }
    })
  }

  push = (pathname: string, state?: any) => {
    this.history.push(pathname, state)
    // const node = { pathname, search: '', state }
    // this.nodes.push(node)
    // this.currentIndex++
  }

  back = () => {
    if (this.canBack) {
      this.currentIndex--
      const node = this.nodes[this.currentIndex]
      this.history.replace(node, { action: 'BACK' })
    }
  }

  advance = () => {
    if (this.canAdvance) {
      this.currentIndex++
      const nextNode = this.nodes[this.currentIndex]
      this.history.push(nextNode, { action: 'ADVANCE' })
    }
  }

  forward = () => {
    if (this.currentIndex < this.nodes.length - 1) {
      this.currentIndex++
      const node = this.nodes[this.currentIndex]
      this.history.replace(node.pathname, node.state)
    }
  }

  clearStack = () => {
    this.currentIndex = -1
    this.nodes = []
    const { pathname, search, state } = this.history.location
    const node = { pathname, search, state }
    this.currentIndex++
    this.nodes.push(node)
  }

  get canBack() {
    return historyStack.currentIndex > 0
  }

  get canAdvance() {
    return historyStack.length - historyStack.currentIndex > 1
  }

  get length() {
    return this.nodes.length
  }

  get stack() {
    return this.nodes
  }
}


export const historyStack = new HistoryStack()
const CustomRouter: FC = ({ children }) => {
  const [state, setState] = useState({
    action: historyStack.history.action,
    location: historyStack.history.location,
  })
  React.useLayoutEffect(() => historyStack.history.listen(setState), [historyStack.history])
  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={historyStack.history}
    />
  )
}

export default CustomRouter
