import React, { useState, FC, ImgHTMLAttributes, useEffect, useRef } from 'react'
import defaultLogoImg from 'src/assets/images/common/default-logo.png'
import styled from 'styled-components'

const DEFAULT_IMAGE = defaultLogoImg

type IImage = {
  width?: string | number
  height?: string | number
  className?: string
  type?: 'normal' | 'notDefault'
  defaultImg?: string
} & ImgHTMLAttributes<HTMLImageElement>
/**
 * @class Image
 * @description 提供图片loading状态的高阶组件
 * @param {prop} src 图片路径
 * @param {prop} className 接受外部定义class
 * @param {prop} style 接受外部定义样式
 * @param {state} loading 控制loading状态是否存在
 */
const redirectHostList = [
  'https://cdn.akamai.steamstatic.com/',
  'https://media.st.dl.eccdnx.com/',
  'https://cdn.cloudflare.steamstatic.com/',
]
const getSrcList = (src?: string) => {
  if (!src) {
    return []
  }
  const redirectHost = redirectHostList.find(f => src.startsWith(f))
  if (redirectHost) {
    return redirectHostList.map(d => src.replace(redirectHost, d))
  }
  return [src]
}

const Image: FC<IImage> = ({
  src,
  className,
  defaultImg = DEFAULT_IMAGE,
  type = 'normal',
  width = 'auto',
  height = 'auto',
  style,
}) => {
  const key = useRef<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [isCache, setIsCache] = useState<boolean>(false)
  const [finalSrc, setFinalSrc] = useState<string>('')
  const srcList = getSrcList(src)
  // console.log(src, 'src')
  // console.log(srcList, 'srcList')
  // console.log(finalSrc, 'finalSrc')

  const loadFromNextHost = (img: HTMLImageElement, host: string) => {
    if (!host || host === 'undefined') {
      setLoading(false)
      setFinalSrc(defaultImg)
      return
    }
    img.onload = null
    img.src = host
    if (img.complete) {
      setIsCache(true)
      setFinalSrc(host)
    } else {
      img.onload = () => {
        setLoading(false)
        setFinalSrc(host)
      }
    }
  }

  useEffect(() => {
    key.current++
    // if (type === 'notDefault') {
    //   return
    // }
    const curSrcList = [...srcList]
    const curKey = key.current
    let img: HTMLImageElement | null = document.createElement('img')
    let index = 0
    img.onerror = function () {
      if (curKey !== key.current) {
        return
      }
      if (!img) {
        return
      }
      index++
      if (index === curSrcList.length) {
        setFinalSrc(defaultImg)
        return
      }
      loadFromNextHost(img, curSrcList[index])
    }

    loadFromNextHost(img, srcList[index])
    // if (img.complete) {
    //   setIsCache(true)
    // } else {
    //   img.onload = () => {
    //     setLoading(false)
    //   }
    // }
    return () => {
      if (img) {
        img.onload = null
        img = null
      }
    }
  }, [type, JSON.stringify(srcList)])

  const showDefault = loading && type !== 'notDefault' && !isCache
  return (
    <StyledFadeinImageContainer style={{ width, height, ...style }} className={className}>
      <StyledImageMarkContainer style={{ opacity: showDefault ? 1 : 0, zIndex: showDefault ? 1 : -1 }}>
        <img className='default-img' src={defaultImg} />
      </StyledImageMarkContainer>
      {finalSrc && (
        <StyledImagesContainer
          className='true-img'
          onError={(e: any) => (e.target.src = defaultImg)}
          isCache={isCache}
          src={finalSrc}
          draggable={false}
          style={{ opacity: !showDefault ? 1 : 0 }}
        />
      )}
    </StyledFadeinImageContainer>
  )
}

const StyledImageMarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.12);
  transition: opacity 100ms ease 0s;
  .default-img {
    width: 47px !important;
    height: 48.5px !important;
  }
`

const StyledImagesContainer = styled.img<{ isCache: boolean }>`
  width: 100%;
  height: 100%;
  ${props => {
    if (props.isCache) {
      return ''
    }
    return `transition: opacity 100ms ease 0s;`
  }}
`

const StyledFadeinImageContainer = styled.div`
  position: relative;
`

export default Image
