import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { getAuth, getGJAuth } from 'src/tools/auth'
import { getFirstPageChannel, handleShowLogin } from 'src/tools/common'
import { localStorageTool } from 'src/tools/storage'
import { LoginStore } from 'src/store/modifier'

const Query = new URLSearchParams(window.location.search)

const fetch = axios.create({
  timeout: 30000,
  withCredentials: true,
  headers: {
    'X-Speed-Company': Query.get('speedclient') === 'v2' ? 'nji' : 'by',
    // 'X-Speed-Company': 'nji',
    terminal: 'pc',
  },
})

/**
 * 请求拦截器
 */
fetch.interceptors.request.use((config: AxiosRequestConfig) => {
  const customHeaders = config.headers || {}
  const region = localStorageTool.get(LOCALSTORAGE_KEYS.COUNTRY_CODE) || ''
  config.headers = {
    ...customHeaders,
    Authorization: getAuth(),
    channel: getFirstPageChannel(),
    adan: localStorageTool.get(LOCALSTORAGE_KEYS.ADAN) || '',
    region,
    currency: region === 'US' ? 'USD' : 'CNY'
  }
  //转发到管家的接口，添加管家token请求头
  if (config.url?.includes('/game/trial/')) {
    config.headers.trialToken = getGJAuth()
  }
  return config
})

/**
 * 响应拦截器
 */

fetch.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response?.data?.code === '401') {
      // 登录过期
      LoginStore.logout()
      handleShowLogin()
    }
    if (response?.data?.code === '4010') {
      // 触发单点登录
      LoginStore.logout()
      handleShowLogin()
    }
    return response.data
  },
  (error: any) => Promise.reject(error),
)

export default fetch
