import { useRef, useEffect, useState, useMemo } from 'react'
import { VipStore } from 'src/store'
import { formatTime } from 'src/tools/helper'
import dayjs from 'dayjs'

type TTimeType = 'speed' | 'cloudgame'
export const useVipRemainingTime = (timeType: TTimeType = 'speed') => {
  const value = timeType === 'cloudgame' ? VipStore.cloudGameRemainingTimeInRealTime : VipStore.remainingTimeInRealTime
  const remainingTimeStringArr = useMemo(() => {
    if (!value || value<=0) {
      return ['00', '00', '00']
    }
    // const normalTime = dayjs('2023-04-18 00:00:00').add(VipStore.remainingTimeInRealTime, 'second')
    // [normalTime.format('HH'), normalTime.format('mm'), normalTime.format('ss')]
    return formatTime(value * 1000)
  }, [value])

  return {
    remainingTimeStringArr,
  }
}
