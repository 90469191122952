import React, { useEffect, useMemo, useState } from 'react'
import { LoginStore } from 'src/store/modifier'
import { VipStore } from 'src/store'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { useNavigate } from 'react-router-dom'
import { checkOversea, exchangeSize, getFirstPageMAC, loginGJ, sleep } from 'src/tools/common'
import { getGameArchive } from 'src/apis/game-api'
import { useHandleGameStart } from 'src/hooks/use-handle-game-start'
import { useIntl } from 'react-intl'
import { $t } from 'src/locales'
import { getPlayGameConfig } from 'src/apis/user-api'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import cloudSave from 'src/CEFTransfer/CloudSave'
import moment from 'moment/moment'

const formatCloudArchives = async (cloudArchivedList: TFinalArchive[], downKeys: string[]) => {
  return cloudArchivedList.filter(d => !downKeys.includes(d.key))?.sort((a, b) => b.time - a.time)
}

const formatLocalArchives = async (
  localArchiveList: LocalArchiveType[],
  downArchiveList: LocalArchiveType[],
  cloudArchiveList: TFinalArchive[],
) => {
  const temp1: TFinalArchive[] =
    localArchiveList?.map(d => {
      return {
        name: d.name,
        time: d.save_time * 1000 || 0,
        key: d.name === 'autosave' ? '' : d.name,
        type: 'local',
        fileSize: exchangeSize(d.file_size || 0),
        new_name:
          d.new_name === 'autosave'
            ? $t({id:'save.auto'})
            : d.new_name && d.new_name.includes('-_-')
              ? d.new_name.split('-_-')[0]
              : d.new_name,
        orderNo: d.orderNo || '',
      }
    }) || []

  const temp2: TFinalArchive[] =
    downArchiveList?.map(d => {
      const cloudArchive = cloudArchiveList.find(f => f.key === d.key)
      return {
        name: d.name,
        time: cloudArchive?.time || d.save_time || 0,
        key: d.name === 'autosave' ? '' : d.key,
        type: 'localCloud',
        fileSize: exchangeSize(d.file_size || 0),
        diy: d.diy === 1 ? 1 : 0,
        new_name:
          d.new_name === 'autosave'
            ? $t({id:'save.auto'})
            : d.new_name && d.new_name.includes('-_-')
              ? d.new_name.split('-_-')[0]
              : d.new_name,
        orderNo: d.orderNo || cloudArchive?.orderNo || '',
        presented: cloudArchive?.presented,
        repelOther: cloudArchive?.repelOther || false,
      }
    }) || []

  const finalTemp = [...temp1, ...temp2].sort((a, b) => {
    if (b.new_name === $t({id:'save.auto'})) {
      return 1
    }
    if (a.new_name === $t({id:'save.auto'})) {
      return -1
    }
    return b.time - a.time
  })

  return finalTemp
}


const useStartTrial = () => {
  const [archiveConfigVisible, setArchiveConfigVisible] = useState<{resolve:(v:any)=>void;reject:(v:any)=>void}>()
  const [finalLocalArchiveList, setFinalLocalArchiveList] = useState<TFinalArchive[]>()
  const [finalCloudArchiveList, setFinalCloudArchiveList] = useState<TFinalArchive[]>()
  const [cloudArchivedList, setCloudArchiveList] = useState<TFinalArchive[]>()
  /** 选中的存档 */
  const [activeArchive, setActiveArchive] = useState<TFinalArchive | null>()

  const [platformTipsVisible, setPlatformTipsVisible] = useState(false)
  const [playGameConfig, setPlayGameConfig] = useState<TPlayGameConfig>()

  const navigate = useNavigate()
  const {handleStartGameByGameStartInfo} = useHandleGameStart()
  const { formatMessage } = useIntl()
  const isOversea = checkOversea()

  const loadPlayConfig=(gameId:number)=>{
    getPlayGameConfig({ gameId }).then(res=>{
      setPlayGameConfig(res.result)
    })
  }

  const showArchiveConfig = (resolve: (v: any) => void, reject: (v: any) => void) => {
    setArchiveConfigVisible({
      resolve(v:any){
        resolve(v)
        setArchiveConfigVisible(undefined)
      },
      reject(v:any){
        reject(v)
        setArchiveConfigVisible(undefined)
      }
    })
  }

  /** 获取下载了的存档列表 */
  const loadDownArchiveList = async (gameId: number) => {
    if (!window.FuluApp) {
      return []
    }
    const result = await Promise.race([
      //获取已下载的存档
      cloudSave.noticeClient('GetDownloadedArchivesList', {
        user_id: `${LoginStore.userinfoGJ?.cloudArchiveIdentify}`,
        game_id: `${gameId}`,
      }),
      sleep(2.5 * 1000),
    ])
    if (result === 'sleep') {
      return []
    }
    if (result && Array.isArray(result) && result.length > 0) {
      return result
    } else {
      return []
    }
  }

  /** 获取本地存档列表 */
  const loadLocalArchiveList = async (gameId: number) => {
    if (!window.FuluApp) {
      return []
    }
    try {
      const result = await Promise.race([
        //获取本地存档 GetLocalArchivesList
        cloudSave.noticeClient('GetLocalArchivesList', {
          user_id: `${LoginStore.userinfoGJ?.cloudArchiveIdentify}`,
          game_id: `${gameId}`,
        }),
        sleep(2.5 * 1000),
      ])
      if (result === 'sleep') {
        return []
      }
      if (result && Array.isArray(result) && result.length > 0) {
        return result
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  /** 获取云存档列表 */
  const loadCloudArchiveList = async (gameId: number) => {
    //获取云存档列表
    const {result} = await getGameArchive({
      gameId: gameId,
      //用户信息中的cloudArchiveIdentify
      userId: `${LoginStore.userinfoGJ?.cloudArchiveIdentify}`,
      page: 1,
      mac: getFirstPageMAC()|| '',
    })
    const temp: TFinalArchive[] = result.map(d => {
      return {
        name: d.name.includes('-_-') ? d.name.split('-_-')[0] : d.name,
        time: moment(d.time).valueOf(),
        key: d.key,
        type: 'cloud',
        diy: d.diy,
        md5: d.md5,
        fileSize: exchangeSize(d.fileSize || 0),
        id: d.id,
        orderNo: d.orderNo || '',
        goodsId: d.goodsId || 0,
        presented: d.presented,
        repelOther: d.repelOther,
      }
    })

    return temp
  }

  const loadAllArchivedList: TLoadAllArchivedList = async (gameId: number) => {
    const [downloadArchivedList, localArchivedList, cloudArchivedList] = await Promise.all([
      loadDownArchiveList(gameId),
      loadLocalArchiveList(gameId),
      loadCloudArchiveList(gameId),
    ])
    const [finalLocalArchivedList, finalCloudArchivedList, sortCloudArchivedList] = await Promise.all([
      formatLocalArchives(localArchivedList, downloadArchivedList, cloudArchivedList),
      formatCloudArchives(
        cloudArchivedList,
        downloadArchivedList.map(d => d.key),
      ),
      formatCloudArchives(cloudArchivedList, []),
    ])
    setFinalLocalArchiveList(finalLocalArchivedList)
    setFinalCloudArchiveList(finalCloudArchivedList)
    setCloudArchiveList(sortCloudArchivedList)
    return {
      finalLocalArchivedList,
      finalCloudArchivedList,
      cloudArchivedList: sortCloudArchivedList,
    }
  }


  const getArchivePayload = async (gameStartInfo: GameStartInfo) => {
    const {finalLocalArchivedList, finalCloudArchivedList, cloudArchivedList} = await loadAllArchivedList(
      gameStartInfo.gameId ?? 0,
    )
    // 本地模式 有云存档或者本地存档才可选择存档
    const showModal = [...(finalLocalArchivedList || []), ...(finalCloudArchivedList || [])].length > 0
    if (showModal) {
      try {
        return {
          result: 1,
          data: await new Promise<TFinalArchive>((resolve, reject) => {
            showArchiveConfig(resolve, reject)
          })
        }
      } catch (e) {
        //取消
        return {
          result: 0,
          data: null
        }
      }
    }
    return {
      result: 1,
      data: null
    }
  }


  const handleStartGameByGameStartInfoPre = async (gameStartInfo: GameStartInfo) => {

    // if (!archiveConfigVisible) {
    // const { finalLocalArchivedList, finalCloudArchivedList, cloudArchivedList } = await loadAllArchivedList(
    //   gameStartInfo.gameId??0,
    // )
    // let archivePayload: TFinalArchive |null = null
    // // 本地模式 有云存档或者本地存档才可选择存档
    // const showModal = [...(finalLocalArchivedList || []), ...(finalCloudArchivedList || [])].length > 0
    // if (showModal) {
    //   try{
    //     archivePayload = await new Promise((resolve,reject) => {
    //       showArchiveConfig(resolve,reject)
    //     })
    //   }catch (e){
    //     //取消
    //     return
    //   }
    // }
    handleStartGameByGameStartInfo({
      gameStartInfo,
      gameMode: 'local',
      queueMainId: '',
      archive: activeArchive,
    })
  }
  const startTrial = async (gameStartInfo: GameStartInfo, platformPath?:string, onPlatformNoInstall?:()=>void) => {
    //是否登录
    if (!LoginStore.userinfo) {
      return LoginStore.openLoginModal()
    }

    //海外版，使用共享账号需要判断是否会员，中文版不需要
    if (isOversea && !VipStore.memberinfo?.isMember) {
      return imperativeConfirmModal({
        title: formatMessage({id:'common.notify'}),
        content: <>
          {formatMessage({id:'trial.notifyVip1'})}<br/>
          {formatMessage({id:'trial.notifyVip2'})}
        </>,
        okText: formatMessage({id:'trial.openVip'}),
        onOk() {
          showUserCenter(NAV_KEY_MAPPING.VIP)
        },
        cancelText:formatMessage({id:'common.cancel'}),
      })
    }

    //判断平台是否安装
    if (!platformPath) {
      setPlatformTipsVisible(true)
      onPlatformNoInstall?.()
      return
    }

    //授权换取管家token，试玩接游戏是后端转发调用的管家接口，须先换取管家token
    await loginGJ()

    handleStartGameByGameStartInfoPre(gameStartInfo)

  }

  const trialArchive = useMemo(()=>{
    return [...(finalLocalArchiveList || []), ...(finalCloudArchiveList || [])]
  },[finalLocalArchiveList,finalCloudArchiveList])

  const hasTrialArchive =useMemo(()=>{
    return trialArchive.length > 0
  },[trialArchive])

  useEffect(() => {
    if(!playGameConfig ||!trialArchive.length ){
      return
    }
    const archive = trialArchive.find(d => d.key === playGameConfig?.archiveKey)
    setActiveArchive(archive || trialArchive[0] || null)
  }, [playGameConfig, trialArchive])

  return {
    hasTrialArchive,
    startTrial,
    loadAllArchivedList,
    showArchiveConfig,
    archiveConfigVisible,
    activeArchive,
    setActiveArchive,
    finalLocalArchiveList,
    finalCloudArchiveList,
    cloudArchivedList,
    platformTipsVisible,
    setPlatformTipsVisible,
    loadPlayConfig
  }

}


export default useStartTrial