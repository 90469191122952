import { archiveStore } from 'src/store'
import { imperativeConfirmModal } from 'src/components/base-modal'
import React from 'react'
import appStore from 'src/store/app-store'
import TrialGamePlayStore from 'src/store/trial-game-play-store'
import CEFTransfer from 'src/CEFTransfer'

class CloudSave {
  instance: Window['FuluApp'] | null
  UID = 0
  js2CppCallback = new Map()
  cpp2JsCallback = new Map()
  constructor() {
    this.instance = window.FuluApp || null
    this.reportClinet()

    this.cpp2JsCallback.set('DownArchivesProgress', ({ current, total, key }: DownArchivesProgress['params']) => {
      /** 启动游戏前的下载云存档进度 */
      if (appStore.archivesProgressObj && appStore.archivesProgressObj.key === key) {
        appStore.archivesProgressObj.current = current
        appStore.archivesProgressObj.total = total
        if (current === total) {
          console.log('配合云存档启动游戏')
          appStore.archivesProgressObj = null
        }
      } else {
        /** 我的存档下载云存档进度 */
        appStore.archivesProgressObjMap.set(key, { current, total, key: key })
      }
    })
    /** 下载成功的通知 */
    this.cpp2JsCallback.set('DownArchivesResult', ({ success, key }: DownArchivesResult['params']) => {
      if (success) {
        appStore.archivesProgressObjMap.delete(key)
        appStore.downComplete = appStore.downComplete++
      }
    })

    this.cpp2JsCallback.set(
      'PopupSaveArchivesFrame',
      ({ order_no: orderNo, save_time: saveTime, file_size: fileSize, noChange }: PopupSaveArchivesFrame['params']) => {
        if (TrialGamePlayStore.gameRunStatus === 'waitClose') {
          /** 当前要是在等待弹出存档弹框 则把状态修改掉 */
          TrialGamePlayStore.gameRunStatus = 'stop'
        }
        if (noChange) {
          return
        }
        /**
         * 配合本地游戏一次启动允许多次保存存档的需求，
         * 这里假如上一次存档正在保存中时客户端重新发送了新的存档保存消息，这时候不搭理他
         */
        if (archiveStore.cloudArchiveSave) {
          return
        }
        CEFTransfer.sendToClient('showWindowTop')
        // this.instance?.JSNotifyCppFunc('set_window_foreground')
        appStore.newSaveArchive.newGameArchiveSaveMode = 'local'
        appStore.newSaveArchive.newSaveArchiveVisible = true
        appStore.newSaveArchive.newSaveArchiveSaveSize = fileSize
        appStore.newSaveArchive.newSaveArchiveSaveTime = saveTime * 1000
        appStore.newSaveArchive.newSaveArchiveGameOrderNo = orderNo
        appStore.newSaveArchive.newSaveArchiveGameName = TrialGamePlayStore.startGameInfoMap.get(orderNo ?? '')?.gameName
        //获取游戏名称及图片
        // getUserOrderInfo({ order_no: orderNo }).then(res => {
        //   const data = res
        //   appStore.newSaveArchive.newSaveArchiveGameName = data?.data?.game_name
        // })
      },
    )
    this.cpp2JsCallback.set(
      'PopupHandleFailFrame',
      ({ error_code: errorCode, error_msg: errorMsg }: PopupSaveArchivesFailFrame['params']) => {
        TrialGamePlayStore.gameRunStatus = 'stop'
        CEFTransfer.sendToClient('showWindowTop')
        // this.instance?.JSNotifyCppFunc('set_window_foreground')
        if (errorCode === '112') {
          imperativeConfirmModal({
            title: '存档保存失败',
            content:
              '本地磁盘空间不足,存档文件保存失败,请清理磁盘空间后,点击下方[重试] 进行存档保存。取消将视为放弃本次存档保存!',
            okText: '重试',
            cancelText: '取消',
            onOk: () => {
              console.log('ArchivesHandleTry')
              this.noticeClient('ArchivesHandleTry', {})
            },
          })
        } else {
          imperativeConfirmModal({
            title: '存档保存失败',
            content: `由于系统环境原因,游戏存档保存失败,请联系右上角客服解决(ErrorCode=${errorCode})`,
            okText: '我知道了',
            btns:['ok']
          })
        }
      },
    )
  }

  /**
   *
   * @param ApplyCloudArchives  应用云存档
   * @param DownloadCloudArchives 下载云存档
   * @param UploadCloudArchives 上传云存档
   * @param GetLocalArchivesList 获取本地存档列表
   * @param ApplyLocalArchives 应用本地存档
   * @param SaveLocalArchives 保存本地存档
   * @param DeleteLocalArchives 删除本地存档
   * @param GetDownloadedArchivesList 获取已经下载到本地的云存档
   * @param DeleteLocaCloudlArchives 删除下载的存档
   * @param AchivesRename 重命名本地存档的名称
   * @param check_apply_mod 检测md5
   */
  noticeClient(key: 'ArchivesHandleTry', params: {}): Promise<NoticeClientResult>
  noticeClient(key: 'BeforeStartGame', params: BeforeStartGamePayload): Promise<NoticeClientResult>
  noticeClient(key: 'ApplyCloudArchives', params: ApplyCloudArchivesPayload): Promise<NoticeClientResult>
  noticeClient(key: 'DownloadCloudArchives', params: DownloadCloudArchives): Promise<NoticeClientResult>
  noticeClient(key: 'GetLocalArchivesList', params: GetlocalArchivesListPayload): Promise<LocalArchiveType[]>
  noticeClient(key: 'ApplyLocalArchives', params: ApplyLocalArchivesPayload): Promise<NoticeClientResult>
  noticeClient(key: 'SaveLocalArchives', params: SaveLocalArchivesPayload): Promise<NoticeClientResult>
  noticeClient(key: 'DeleteLocalArchives', params: DeleteLocalArchives): Promise<NoticeClientResult>
  noticeClient(key: 'UploadCloudArchives', params: UploadCloudArchives): Promise<NoticeClientResult>
  noticeClient(key: 'GetDownloadedArchivesList', params: GetlocalArchivesListPayload): Promise<LocalArchiveType[]>
  noticeClient(key: 'DeleteLocaCloudlArchives', params: DeleteLocalArchives): Promise<NoticeClientResult>
  noticeClient(key: 'AchivesRename', params: renameArchives): Promise<NoticeClientResult>
  // noticeClient(key: 'CheckApplyMod', params: CheckApplyMod): Promise<CheckApplyModResult>
  // noticeClient(key: 'ApplyMod', params: ApplyMod): Promise<ApplyModResult>
  // noticeClient(key: 'AppTimeOut', params: TApplyTimeOut): Promise<NoticeClientResult>
  noticeClient(key: 'ArchivesClashSelect', params: TArchivesClashSelect): Promise<any>

  noticeClient(
    key: NoticeClientKey,
    params: Object,
  ): Promise<NoticeClientResult | LocalArchiveType[] | CheckApplyModResult | ApplyModResult> {
    return new Promise(resolve => {
      const parseParams = {
        id: ++this.UID,
        method: key,
        params,
        jsonrpc: '2.0',
      }
      if (this.instance?.JSNotifyCppFunc) {
        this.instance.JSNotifyCppFunc?.('js2cpp_cloudsave_msg', JSON.stringify(parseParams))
        console.log('js2cpp_cloudsave_msg =>',parseParams)
        // console.log(JSON.stringify(parseParams))
        // console.log(this.UID)
        // console.log(resolve)
        this.js2CppCallback.set(this.UID, resolve)
        // console.log(this.js2CppCallback)
      } else {
        console.log('not found cloudsave_msg')
      }
    })
  }

  reportClinet() {
    if (this.instance) {
      this.instance!.RegisterCppNotifyCppFunc('cpp2js_cloudsave_msg', (result: string) => {
        console.log(' => cpp2js_cloudsave_msg ',result)
        const finalResult: CloudSaveMsgReplyPayload = JSON.parse(result)
        // console.log(finalResult)
        if (finalResult.method) {
          // console.log(980)
          /** 客户端主动推送 */
          this.cpp2JsCallback.has(finalResult.method) && this.cpp2JsCallback.get(finalResult.method)(finalResult.params)
        } else {
          // console.log(654)
          // console.log(this.js2CppCallback.has(finalResult.id))
          // console.log(this.js2CppCallback.get(finalResult.id))
          /** 请求客户端，客户端返回的结果 */
          this.js2CppCallback.has(finalResult.id) && this.js2CppCallback.get(finalResult.id)(finalResult.result)
        }
      })
      // this.instance.cpp2js_cloudsave_msg_reply = result => {
      //   console.log(result)
      //   if (result.method) {
      //     /** 客户端主动推送 */
      //     this.cpp2JsCallback.has(result.method) && this.cpp2JsCallback.get(result.method)(result.params)
      //   } else {
      //     /** 请求客户端，客户端返回的结果 */
      //     this.js2CppCallback.has(result.id) && this.js2CppCallback.get(result.id)(result.params)
      //   }
      // }
    }
  }
}

export default new CloudSave()
