import { observable, action } from 'mobx'

class ProtocolStore {
  /** 协议弹框是否显示 */
  @observable
  modalVisible: boolean = false

  @observable
  protocolKey: string | null = null

  @observable
  protocolConfig: { [props: string]: { title: string; content: string } } = {}

  @action
  openProtocolModal = (protocolKey: string) => {
    this.protocolKey = protocolKey
    this.modalVisible = true
  }

  @action
  cancelProtocolModal = () => {
    this.modalVisible = false
    this.protocolKey = null
  }

  @action
  changeProtocolConfig = (result: CMS.ModDataItem<any>[]) => {
    console.log(result, 'result')
    result.forEach(
      (d: { properties: { title: string; content: string; key: Store.AppStore['currentProtocolType'] } }) => {
        const temp = d.properties
        this.protocolConfig[temp.key] = { title: temp.title, content: temp.content }
      },
    )
  }
}

export default new ProtocolStore()
