import CEFTransfer from 'src/CEFTransfer'
import appStore from 'src/store/app-store'

/**
 * @description 最小化客户端
 */
export const handleMiniClient = () => {
  CEFTransfer.sendToClient('minPage')
}

/**
 * @description 最大化客户端
 */
export const handleMaxiClient = async () => {
  CEFTransfer.sendToClient('maxPage')
}

/**
 * @description 关闭客户端
 */
export const handleCloseClient = () => {
  CEFTransfer.sendToClient('exitApp')
}

/**
 * @description 打开外链
 */
export const cefOpenUrl = (url: string) => {
  return CEFTransfer.sendToClient('openUrl', { url })
}

/**
 * @description 打开客服弹窗
 */
export const openCustomService = (url: string, js?: string) => {
  //用vjs传参，因客户端会把map里面属性，排序取第一个，确保第一个为url，所以加个v
  return url ? CEFTransfer.sendToClient('openCustomService', { url, vjs: js }) : url
}

/**
 * @description 获取剪贴板内容分
 */

export const getClipboard = async () => {
  try {
    const { data = '' } = await CEFTransfer.sendToClient<{ data: string }>('getClipboard')
    return data
  } catch (error) {
    return ''
  }
}
