import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import {
  getGameLibraryBranchListSpeedGameDetailHttp,
  getGameLibraryBranchListTrainerGameHttp,
  getGameLibraryBranchQueryCloudGame,
  getGameLibraryBranchQueryTrialGame,
  getGameLibraryMasterDetailHttp,
  getPlatformDetailHttp,
  getPlatformListSpeedGameDetailHttp,
  getPlatformWelfareByPlatformId,
  getResourceListByGameMasterId,
} from 'src/apis/game-api'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { GameStore } from 'src/store/modifier'
import { appStore, ConfigStore, SpeedStore } from 'src/store'
import { DETAIL_INDEX_HEIGHT, ToolsTypes } from 'src/constants/common'
import { emitter } from 'src/tools/emitter'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import CloudGameIcon from 'src/assets/icons/game-detail/cloud-game.svg'
import ModifierIcon from 'src/assets/icons/game-detail/modifier.svg'
import ResourceIcon from 'src/assets/icons/game-detail/resource.svg'
import TrialIcon from 'src/assets/icons/game-detail/trial.svg'
import PlatformWelfareIcon from 'src/assets/icons/game-detail/platform-welfare.svg'
import SpeedIcon from 'src/assets/icons/game-detail/speed.svg'
import useUrlState from '@ahooksjs/use-url-state'
import Modifier from 'src/pages/mini/game-detail/modifier'
import Speed from 'src/pages/mini/game-detail/speed'
import NotFound from 'src/pages/game-detail/not-found'
import styled from 'styled-components'
import dayjs from 'dayjs'

enum PageType {
  PLATFORM = 'platform',
  GAME = 'game',
}

enum EntryType {
  MODIFIER = 'modifier',
  SPEED = 'speed',
  RESOURCE = 'resource',
  BRANCH = 'branch',
  PLATFORM_WELFARE = 'platform-welfare',
  TRIAL = 'trial',
}

const MiniGameDetail: FC = () => {
  const params = useParams<{ id: string }>()
  const [state, setState] = useUrlState<{
    type: PageType
    entry?: EntryType
    branchId?: string
    gameLibraryMasterId?: string
    trainerGameId?: string
    platformId?: string
    speedGameNo?: string
    refer?: string
  }>(undefined, {
    navigateMode: 'replace',
  })
  const [cloudGame, setCloudGame] = useState<Http.TCloudGameByGameBranch>()
  const [trialGame, setTrialGame] = useState<Http.TTrialGameByGameBranch>()
  const [gameLibraryMasterDetail, setGameLibraryMasterDetail] = useState<Game.GameLibraryMasterDetail>()
  const [platformDetail, setPlatformDetail] = useState<Game.PlatformDetail>()
  const [currentGameLibraryBranche, setCurrentGameLibraryBranche] = useState<Game.GameLibraryDetailBranche>()
  const [currentToolsType, setCurrentToolsType] = useState<Game.GameLibraryDetailToolsType<EntryType>>()
  const [trainerGame, setTrainerGame] = useState<Game.BranchAssociatedDataTrainerGame>()
  const [toolsTypes, setToolsTypes] = useState<Game.GameLibraryDetailToolsType<EntryType>[]>()
  const [speedGames, setSpeedGames] = useState<Game.BranchAssociatedDataSpeedGame[]>()
  const [platformWelfares, setPlatformWelfares] = useState<Game.TPlatformWelfare[]>()
  const [reload, setReload] = useState(0)
  const toolsListRef=useRef<HTMLDivElement>(null)
  const navigator=useNavigate()

  const isGame = state.type === PageType.GAME

  console.log('toolsTypes',toolsTypes)

  const handleReceivePlatformWelfare = async (target: string, gameName: string) => {
    const speedInfo = SpeedStore.hasSpeeding()
    if (
      !speedInfo ||
      !speedGames ||
      speedGames.length === 0 ||
      !speedGames.find(f => f.speedGameNo === speedInfo.speedGameNo)
    ) {
      const isConfirm = await imperativeConfirmModal({
        title: '领取免费游戏提示',
        content: `您即将前往访问 ${
          platformDetail?.platformName || gameLibraryMasterDetail?.cnName
        } 商店，领取《${gameName}》游戏，建议您开启加速器后再进行访问，避免领取页面无法打开`,
        okText: '前往加速',
        cancelText: '我知道了',
      })
      if (isConfirm.value) {
        const targetToolsType = toolsTypes?.find(f => f.toolsType === ToolsTypes.SPEED_GAME)
        targetToolsType && handleSetCurrentToolsType(targetToolsType)
        return
      }
    }
    cefOpenUrl(target)
  }

  console.log(currentGameLibraryBranche, 'currentGameLibraryBranche')

  const { zhName, enName } = useMemo(() => {
    if (state.type === PageType.PLATFORM) {
      return {
        zhName: platformDetail?.platformName,
        enName: '',
      }
    }
    return {
      zhName: gameLibraryMasterDetail?.cnName,
      enName: gameLibraryMasterDetail?.enName,
    }
  }, [state.type, gameLibraryMasterDetail, platformDetail])
  const CurrentComponent = useMemo(() => {
    if (currentToolsType && !toolsTypes?.find(v => v.toolsType === currentToolsType?.toolsType)) {
      const params = {
        tool: currentToolsType?.toolsTypeName,
        gameName: gameLibraryMasterDetail?.cnName || gameLibraryMasterDetail?.enName,
        platformName: currentGameLibraryBranche?.platformName || '',
        platformIcon: currentGameLibraryBranche?.platformIcon || '',
      }
      return <NotFound
        img={platformDetail?.platformHorizontalImage || gameLibraryMasterDetail?.listImage || ''}
        type={platformDetail ? 'platform' : 'game'}
        {...params}
      />
    }
    if (currentToolsType?.toolsType === ToolsTypes.TRAINER_GAME && trainerGame) {
      return (
        <Modifier
          trainerTitleId={trainerGame?.trainerTitleId}
          gameLibraryName={zhName || ''}
          gamePlatformName={currentGameLibraryBranche?.platformName || ''}
        />
      )
    }
    if (currentToolsType?.toolsType === ToolsTypes.SPEED_GAME && speedGames) {
      return (
        <Speed
          speedGames={speedGames}
          changeSearch={setState}
          gameLibraryName={zhName || ''}
          gamePlatformName={currentGameLibraryBranche?.platformName || ''}
        />
      )
    }
  }, [
    currentToolsType?.toolsType,
    currentGameLibraryBranche,
    currentGameLibraryBranche?.platformName,
    trainerGame,
    speedGames,
    gameLibraryMasterDetail?.gameLibraryMasterId,
    state.branchId,
    toolsTypes,
    setState,
    zhName,
  ])

  const handleSetCurrentToolsType = (d: Game.GameLibraryDetailToolsType<EntryType>) => {
    if (state.type === PageType.GAME) {
      let params: typeof state = {}
      if (d.entryType === EntryType.MODIFIER) {
        params.trainerGameId = trainerGame?.trainerGameId
      }
      if (d.entryType === EntryType.SPEED) {
        params.speedGameNo = speedGames?.[0].speedGameNo
      }
      setState({
        ...params,
        entry: d.entryType,
      })
    }
    setCurrentToolsType(d)
  }

  const loadAssociatedDataByPlatform = async (platformId: number | string) => {
    getPlatformWelfareByPlatformId
    const [{ result: speedGames }, { result: platformWelfares }] = await Promise.all([
      getPlatformListSpeedGameDetailHttp({ platformId }),
      getPlatformWelfareByPlatformId({ platform: Number(platformId) }),
    ])
    // const { result: speedGames } = await getPlatformListSpeedGameDetailHttp({ platformId })
    const tempToolsTypes: Game.GameLibraryDetailToolsType<EntryType>[] = []
    if (speedGames.length > 0) {
      setSpeedGames(speedGames)
      tempToolsTypes.push({
        toolsType: ToolsTypes.SPEED_GAME,
        toolsTypeName: '加速器',
        toolsTypeSort: 100,
        toolsIcon: <SpeedIcon className='tools-icon' />,
        entryType: EntryType.SPEED,
      })
    }
    if (platformWelfares.length > 0) {
      setPlatformWelfares(platformWelfares.sort((a, b) => dayjs(a.endDate).valueOf() - dayjs(b.endDate).valueOf()))
      tempToolsTypes.push({
        toolsType: ToolsTypes.PLATFORM_WELFARE,
        toolsTypeName: '喜加一',
        toolsTypeSort: 0,
        toolsIcon: <PlatformWelfareIcon className='tools-icon' />,
        entryType: EntryType.PLATFORM_WELFARE,
      })
    }
    setToolsTypes(tempToolsTypes)
    setCurrentToolsType(tempToolsTypes[0])
  }

  const loadAssociatedDataByGame = async (gameLibraryMasterId: number | string, branchId: number | string) => {
    const [
      { result: trainerGame },
      { result: speedGames },
      // { result: cloudGame },
      // { result: trialGame },
      // { result: resourceList }
    ] = await Promise.all([
      getGameLibraryBranchListTrainerGameHttp({ gameLibraryBranchId: branchId }),
      getGameLibraryBranchListSpeedGameDetailHttp({ gameLibraryBranchId: branchId }),
      // getGameLibraryBranchQueryCloudGame({ gameLibraryBranchId: branchId }),
      // getGameLibraryBranchQueryTrialGame({ gameLibraryBranchId: branchId }),
      // getResourceListByGameMasterId({
      //   pageNo: 1,
      //   pageSize: 1,
      //   gameLibraryMasterId,
      //   gameLibraryBranchId: branchId,
      // }),
    ])
    console.log(cloudGame, 'cloudGame')
    const tempToolsTypes = []
    let currentToolsType = null
    //试玩
    // if(trialGame){
    //   const params = {
    //     toolsType: ToolsTypes.TRIAL_GAME,
    //     toolsTypeName: '共享账号',
    //     toolsTypeSort: 0,
    //     toolsIcon: <TrialIcon className='tools-icon' />,
    //     entryType: EntryType.TRIAL,
    //   }
    //   tempToolsTypes.push(params)
    //   setTrialGame(trialGame)
    //   if (state.entry === EntryType.TRIAL) {
    //     currentToolsType = params
    //   }
    // }
    if (speedGames.length > 0) {
      const params = {
        toolsType: ToolsTypes.SPEED_GAME,
        toolsTypeName: '加速器',
        toolsTypeSort: 100,
        toolsIcon: <SpeedIcon className='tools-icon' />,
        entryType: EntryType.SPEED,
      }
      setSpeedGames(speedGames)
      tempToolsTypes.push(params)
      if (state.entry === EntryType.SPEED) {
        currentToolsType = params
      }
    }
    if (trainerGame.length > 0 && appStore.isInWhiteList) {
      // if (trainerGame.length > 0) {
      setTrainerGame(trainerGame[0])
      const params = {
        toolsType: ToolsTypes.TRAINER_GAME,
        toolsTypeName: '修改器',
        toolsTypeSort: 200,
        toolsIcon: <ModifierIcon className='tools-icon' />,
        entryType: EntryType.MODIFIER,
      }
      tempToolsTypes.push(params)
      if (state.entry === EntryType.MODIFIER) {
        currentToolsType = params
      }
    }
    // if (resourceList.totalRows > 0) {
    //   const params = {
    //     toolsType: ToolsTypes.GAME_RESOURCE,
    //     toolsTypeName: '游戏资源',
    //     toolsTypeSort: 400,
    //     toolsIcon: <ResourceIcon className='tools-icon' />,
    //     entryType: EntryType.RESOURCE,
    //   }
    //   tempToolsTypes.push(params)
    //   if (state.entry === EntryType.RESOURCE) {
    //     currentToolsType = params
    //   }
    // }
    // if (cloudGame.cloudGameId && cloudGame.accountLoginMode?.length > 0) {
    //   const params = {
    //     toolsType: ToolsTypes.CLOUD_GAME,
    //     toolsTypeName: '云游戏',
    //     toolsTypeSort: 300,
    //     toolsIcon: <CloudGameIcon className='tools-icon' />,
    //     entryType: EntryType.BRANCH,
    //   }
    //   setCloudGame(cloudGame)
    //   tempToolsTypes.push(params)
    //   if (state.entry === EntryType.BRANCH) {
    //     currentToolsType = params
    //   }
    // }
    const sortToolsTypes=tempToolsTypes.sort((a, b) => (a.toolsTypeSort-b.toolsTypeSort))
    setToolsTypes(sortToolsTypes)

    console.log('currentToolsType 0 ',currentToolsType,state.entry,speedGames, trainerGame,appStore.isInWhiteList)
    setCurrentToolsType(
      currentToolsType ||
      sortToolsTypes.find(
          f => !!ConfigStore.channelConfig && f.toolsType === ConfigStore.channelConfig.defaultTool,
        ) ||
      sortToolsTypes[0],
    )
  }

  console.log('currentToolsType',currentToolsType)

  const loadGameLibraryMasterDetail = async () => {
    let params = {}
    if (!state.entry || state.entry === EntryType.RESOURCE) {
      params = { gameLibraryMasterId: state.gameLibraryMasterId, gameLibraryBranchId: state.branchId }
    }
    if (state.entry === EntryType.MODIFIER) {
      params = { gameLibraryMasterId: state.gameLibraryMasterId, trainerGameId: state.trainerGameId, gameLibraryBranchId: state.branchId }
    }
    if (state.entry === EntryType.SPEED) {
      params = { gameLibraryMasterId: state.gameLibraryMasterId, gameLibraryBranchId: state.branchId }
    }
    if (state.entry === EntryType.BRANCH) {
      params = { gameLibraryMasterId: state.gameLibraryMasterId, gameLibraryBranchId: state.branchId }
    }
    if (state.entry === EntryType.TRIAL) {
      params = { gameLibraryMasterId: state.gameLibraryMasterId, gameLibraryBranchId: state.branchId }
    }
    try {
      const {result} = await getGameLibraryMasterDetailHttp(params)
      const defaultBranch = result.gameLibraryBranches.find(f => f.gameLibraryBranchId === result.gameLibraryBranchId)
      setGameLibraryMasterDetail(result)
      setCurrentGameLibraryBranche(defaultBranch)
    } catch (e:any) {
      // navigator('/', {replace: true})
      state.refer !== 'firstJump' && message.warn(e.message)
    }
  }

  const loadPlatformDetail = async () => {
    const { result } = await getPlatformDetailHttp({ platformId: state.platformId })
    setPlatformDetail(result)
  }

  const checkToolAvailable=(type:ToolsTypes)=>{
    return !!toolsTypes?.find(v=>v.toolsType===type)
  }

  // const tools=useMemo(()=>{
  //   const list = state.type === PageType.GAME ?
  //     [
  //       {
  //         toolsType: ToolsTypes.TRIAL_GAME,
  //         toolsTypeName: '共享账号',
  //         toolsTypeSort: 0,
  //         toolsIcon: <TrialIcon className="tools-icon"/>,
  //         entryType: EntryType.TRIAL,
  //         available: checkToolAvailable(ToolsTypes.TRIAL_GAME)
  //       },
  //       {
  //         toolsType: ToolsTypes.SPEED_GAME,
  //         toolsTypeName: '加速器',
  //         toolsTypeSort: 100,
  //         toolsIcon: <SpeedIcon className="tools-icon"/>,
  //         entryType: EntryType.SPEED,
  //         available: checkToolAvailable(ToolsTypes.SPEED_GAME)
  //       },
  //       {
  //         toolsType: ToolsTypes.TRAINER_GAME,
  //         toolsTypeName: '修改器',
  //         toolsTypeSort: 200,
  //         toolsIcon: <ModifierIcon className="tools-icon"/>,
  //         entryType: EntryType.MODIFIER,
  //         available: checkToolAvailable(ToolsTypes.TRAINER_GAME)
  //       },
  //       {
  //         toolsType: ToolsTypes.CLOUD_GAME,
  //         toolsTypeName: '云游戏',
  //         toolsTypeSort: 300,
  //         toolsIcon: <CloudGameIcon className="tools-icon"/>,
  //         entryType: EntryType.BRANCH,
  //         available: checkToolAvailable(ToolsTypes.CLOUD_GAME)
  //       },
  //       {
  //         toolsType: ToolsTypes.GAME_RESOURCE,
  //         toolsTypeName: '游戏资源',
  //         toolsTypeSort: 400,
  //         toolsIcon: <ResourceIcon className="tools-icon"/>,
  //         entryType: EntryType.RESOURCE,
  //         available: checkToolAvailable(ToolsTypes.GAME_RESOURCE)
  //       }
  //     ] :
  //     [
  //       {
  //         toolsType: ToolsTypes.SPEED_GAME,
  //         toolsTypeName: '加速器',
  //         toolsTypeSort: 100,
  //         toolsIcon: <SpeedIcon className="tools-icon"/>,
  //         entryType: EntryType.SPEED,
  //         available: checkToolAvailable(ToolsTypes.SPEED_GAME)
  //       },
  //       {
  //         toolsType: ToolsTypes.PLATFORM_WELFARE,
  //         toolsTypeName: '喜加一',
  //         toolsTypeSort: 0,
  //         toolsIcon: <PlatformWelfareIcon className="tools-icon"/>,
  //         entryType: EntryType.PLATFORM_WELFARE,
  //         available: checkToolAvailable(ToolsTypes.PLATFORM_WELFARE)
  //       }
  //     ]
  //   return list.sort((a, b) => {
  //     return Number(b.available) - Number(a.available)
  //   })
  // },[state.type, toolsTypes])

  useEffect(() => {
    if (state.type) {
      if (state.type === PageType.GAME) {
        loadGameLibraryMasterDetail()
      }
      if (state.type === PageType.PLATFORM) {
        loadPlatformDetail()
      }
      return () => {
        setTrainerGame(undefined)
        setSpeedGames(undefined)
        setCloudGame(undefined)
        setTrialGame(undefined)
        setCurrentGameLibraryBranche(undefined)
        setGameLibraryMasterDetail(undefined)
        setPlatformDetail(undefined)
        setToolsTypes(undefined)
        setCurrentToolsType(undefined)
      }
    }
  }, [params.id, state.type, reload])

  useEffect(() => {
    if (gameLibraryMasterDetail) {
      appStore.layoutBg = gameLibraryMasterDetail.detailLongImage
    }
    if (platformDetail) {
      appStore.layoutBg = platformDetail.platformLongImage
    }
    appStore.layoutBgType = 'modifier'
    return () => {
      appStore.layoutBg = null
      appStore.layoutBgType = null
    }
  }, [platformDetail, gameLibraryMasterDetail])

  useEffect(() => {
    if (currentGameLibraryBranche?.gameLibraryBranchId && gameLibraryMasterDetail?.gameLibraryMasterId) {
      loadAssociatedDataByGame(
        gameLibraryMasterDetail?.gameLibraryMasterId,
        currentGameLibraryBranche.gameLibraryBranchId,
      )
      return () => {
        setTrainerGame(undefined)
        setSpeedGames(undefined)
        setCloudGame(undefined)
        setTrialGame(undefined)
        setToolsTypes(undefined)
        setCurrentToolsType(undefined)
      }
    }
  }, [currentGameLibraryBranche, gameLibraryMasterDetail])

  useEffect(() => {
    if (platformDetail?.platformId) {
      loadAssociatedDataByPlatform(platformDetail.platformId)
    }
  }, [platformDetail])

  useEffect(() => {
    if (currentToolsType?.toolsType && currentToolsType.toolsType === ToolsTypes.TRAINER_GAME && trainerGame) {
      GameStore.changeActiveTitle(trainerGame.trainerTitleId, trainerGame.trainerGameId)
    }
  }, [currentToolsType?.toolsType, trainerGame])

  useEffect(() => {
    const handleInDetailToDetail = () => {
      setReload(value => value + 1)
    }
    emitter.on('inDetailToDetail', handleInDetailToDetail)
    return () => {
      emitter.off('inDetailToDetail', handleInDetailToDetail)
    }
  }, [])

  useEffect(()=>{
    const item= toolsTypes?.find(v=>v.entryType===state.entry)
    if(!item){
      return
    }
    handleSetCurrentToolsType(item)
  },[state.entry, toolsTypes])

  return (
    <StyledDetailContainer>
      {CurrentComponent}
    </StyledDetailContainer>
  )
}

const StyledDetailContainer = styled.div`
  margin: 0 auto;
`

export default observer(MiniGameDetail)
