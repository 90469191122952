import { SpeedStore } from 'src/store'
import { message } from 'antd'
import { requestExitApp, speedExitPromise } from 'src/tools/speed'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { ClientLocalStorageStore } from 'src/store/modifier'
import CEFTransfer from 'src/CEFTransfer'

const Platform: Record<TPlatform, number> = {
  steam: 1,
  uplay: 2,
  epic: 3,
  origin: 4,
  blizzard: 5,
  riot: 6,
  tencent: 7,
  '99box': 8,
}

class SpeedController {
  constructor() {
    CEFTransfer.subscribeClientMsg('exitAppRequest', () => {
      // CEFTransfer.sendToClient('showWindowTop')
      requestExitApp()
    })

    CEFTransfer.subscribeClientMsg('speedExit', result => {
      if (speedExitPromise.resolve && ['退出加速成功', '当前未加速'].includes(result.msg)) {
        speedExitPromise.resolve()
      }
      if (SpeedStore.speedingInfo) {
        SpeedStore.stopSpeed({ notify: false, report: true, reason: 'CLIENT_NOTICE_EXIT' })
      }
    })

    CEFTransfer.subscribeClientMsg<CEF.TSpeedStatus>('speedStatus', result => {
      if (SpeedStore.speedStatus === 'speeding' && SpeedStore.speedingInfo) {
        if (SpeedStore.recordSpeedBytes?.bytes !== result.speedBytes) {
          SpeedStore.recordSpeedBytes = {
            time: Date.now(),
            bytes: result.speedBytes,
          }
        } else {
          const { time, bytes } = SpeedStore.recordSpeedBytes
          if (ClientLocalStorageStore.getSystemAutoStop() && Date.now() - time > (window.autoStop || 30 * 60 * 1000)) {
            //自动断线，不需要置顶窗口 2022-6-11
            // CEFTransfer.sendToClient('showWindowTop')
            imperativeConfirmModal({
              title: '停止加速提醒',
              content:
                '系统监测到您长时间未使用加速服务，为防止无效带宽占用影响您的网速，我们帮您暂时停止了游戏加速服务',
              okText: '我知道了',
              btns: ['ok'],
            })
            return SpeedStore.stopSpeed({ report: true, reason: 'SPEED_IDLE', notify: true })
          }
        }
        const { gameID, nodeDelay, nodeLoss, a_delay, a_loss_avg, b_delay, b_loss_avg, c_delay, c_loss_avg } = result
        if (`${gameID}` === `${SpeedStore.speedingInfo.thirdGameNo}`) {
          SpeedStore.speedingInfo = {
            ...SpeedStore.speedingInfo,
            nodeDelay,
            nodeLoss,
            a_delay,
            a_loss_avg,
            b_delay: b_delay + c_delay,
            b_loss_avg: b_loss_avg + c_loss_avg,
            speedBytes: result.speedBytes,
          }
        }
      }
    })
  }

  /** 获取加速节点区服列表 */
  async getAllAreaList(gameID: string) {
    if (!gameID) {
      return Promise.reject('missing gameID')
    }
    try {
      return await CEFTransfer.sendToClient<{ areaList: CEF.TAreaInfo[] }>(
        'getAllAreaList',
        { gameID },
        { timeout: 10000 },
      )
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
  /** 获取区服下的节点 */
  // async getAllNodeList(params: { gameID: string; areaID: string; serverID: string }) {
  //   try {
  //     return await CEFTransfer.sendToClient<{ nodeList: CEF.TNodeInfo[] }>('getAllNodeList', params, { timeout: 15000 })
  //   } catch (error) {
  //     console.error(error)
  //     return Promise.reject(error)
  //   }
  // }
  /** 获取区服下的节点 */
  async getAllNodeList(params: { gameID: string; areaID: string; serverID: string }) {
    try {
      return await CEFTransfer.sendToClient<{
        nodeList: CEF.TNodeInfo[]
        testSpeedBatchID: number
        moreTestSpeedBatch: boolean
        errorMsg: string
      }>('getAllNodeListBatch', params, { timeout: 15000 })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
  /** 获取区服下的更多节点 */
  async getMoreNodeList(params: { testSpeedBatchID: number }) {
    try {
      return await CEFTransfer.sendToClient<{
        nodeList: CEF.TNodeInfo[]
        testSpeedBatchID: number
        moreTestSpeedBatch: string
      }>('getMoreTestSpeedBatch', params, { timeout: 15000 })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
  /** 停止获取区服下的更多节点 */
  async stopMoreNodeList(params: { testSpeedBatchID: number }) {
    try {
      return await CEFTransfer.sendToClient('stopTestSpeedBatch', params, { timeout: 15000 })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
  /** 启动加速 */
  async cefStartSpeed(params: {
    userID: string
    token: string
    gameID: string
    areaID: string
    serverID: string
    nodeID: string
    platform: keyof typeof Platform | ''
  }) {
    try {
      const sendParams = {
        ...params,
        platform: params.platform ? Platform[params.platform] || 0 : 0,
      }
      return await CEFTransfer.sendToClient<{ ybcode: number; code: string; msg: string }>('startSpeed', sendParams, {
        timeout: 60000,
      })
    } catch (error) {
      const isStop = error === 'timeout' && (SpeedStore.speedingInfo ? SpeedStore.speedingInfo.speedNo === params.userID : true)
      if (isStop) {
        SpeedStore.stopSpeed({ notify: false, report: true, reason: 'START_TIMEOUT' })
        message.warn('启动超时')
      }
      return Promise.reject(error)
    }
  }
  /** 停止加速 */
  async cefStopSpeed() {
    try {
      return await CEFTransfer.sendToClient('stopSpeed', {}, { timeout: 10000 })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
  /** 启动平台加速 */
  async cefStartSpeedEx(params: Record<string, string[]>) {
    try {
      return await CEFTransfer.sendToClient('startSpeedEx', params, { timeout: 5000 })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  /** 停止平台加速 */
  async cefStopSpeedEx() {
    try {
      return await CEFTransfer.sendToClient('stopSpeedEx', {}, { timeout: 1500 })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }
}
export default new SpeedController()
