import React, { Children, FC, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Select } from 'antd'
import styled, { css } from 'styled-components'
import classNames from 'classnames'

const { Option } = Select
const Selector: FC<{
  value: number | undefined
  defaultValue?: number | undefined
  options?: string[]
  onChange?: (value: number) => void
  disabled?: boolean
  placeholder?: string
  style?: React.CSSProperties
}> = ({ options, value, onChange, disabled, placeholder, defaultValue, children, style }) => {
  const [dom, setDom] = useState<HTMLDivElement | null>()
  return (
    <StyledSelectorContainer ref={e => setDom(e)} style={style}>
      {dom && (
        <StyledSelector
          disabled={disabled}
          placeholder={placeholder}
          getPopupContainer={() => dom}
          defaultValue={defaultValue}
          onChange={e => onChange?.((e as any) as number)}
          value={value}
        >
          {options?.map((d, i) => {
            return (
              <Option value={i} key={`options-${d}`}>
                {d}
              </Option>
            )
          })}
          {children}
        </StyledSelector>
      )}
    </StyledSelectorContainer>
  )
}

const StyledSelector = styled(Select)`
  width: 140px;
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 26px;
    background: ${props => props.theme.color.bg.$54545860} !important;
    border-color: ${props => props.theme.color.bg.$54545860} !important;
    box-shadow: none !important;
    .ant-select-selection-search-input {
      height: 24px;
    }
    .ant-select-selection-placeholder {
      color: ${props => props.theme.color.text.$999999} !important;
      line-height: 24px;
    }
    .ant-select-selection-item {
      color: var(--text-color-FFFFFF) !important;
      line-height: 24px;
    }
  }
  .ant-select-arrow {
    color: ${props => props.theme.color.text.$999999} !important;
  }
`

const StyledSelectorContainer = styled.div`
  .ant-select-dropdown {
    background: #323234 !important;
    border-radius: 4px;
    .ant-select-item {
      background: transparent;
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$999999} !important;
      &:hover {
        color: ${props => props.theme.color.text.$C598FF} !important;
      }
    }
  }
  .ant-select-item-option-content {
    line-height: 22px;
  }
`

export default observer(Selector)
