import React, { FC, useRef, useState } from 'react'
import { Button, ButtonProps } from 'antd'
import { useThrottleFn, useDebounceFn } from 'ahooks'
import specialBtnBgImg from 'src/assets/images/common/special-btn-bg.jpg'
import styled from 'styled-components'

function randomEase() {
  let target = 1 // 随机生成目标进度值
  let current = 0 // 当前进度值
  let easing = 0.2 + Math.random() * 0.2 // 缓动系数，可以随机生成
  return function (progress: number) {
    if (progress >= 1) {
      return 1
    }
    let increment = Math.random() * 0.1 - 0.05 // 随机增量，可以为负数
    current += (increment + (target - current) * easing) * progress // 逐渐逼近目标进度值
    current = Math.max(Math.min(current, target), 0) // 确保进度值不超出 0 到目标进度值之间的范围
    return current
  }
}

function animate(callback: (progress: number, type: 'running' | 'over') => void) {
  let start = Date.now()
  let duration = 2000
  let ease = randomEase()

  function update() {
    let timeElapsed = Date.now() - start
    let timeProgress = timeElapsed / duration
    let easedProgress = ease(timeProgress)

    callback(easedProgress, 'running')
    if (timeElapsed < duration) {
      requestAnimationFrame(update)
    } else {
      callback(easedProgress, 'over')
    }
  }
  requestAnimationFrame(update)
}

const gradientList = ['gradient1', 'gradient2', 'gradient3', 'gradient4', 'gradient5']

const generateUUID = () => {
  const uuid = []
  for (let i = 0; i < 4; i++) {
    uuid.push(
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1),
    )
  }
  return uuid.join('')
}

const handleGenRandomFilter = () => {
  const id = 'filter' + generateUUID()
  /** 1 - 10 */
  const start = Math.random() * 5 + 1
  /** 1 - 50, 一定大于start */
  const end = Math.random() * 5 + start
  const scribbles = document.querySelector('#scribbles')
  const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter')
  filter.setAttribute('color-interpolation-filters', `sRGB`)
  filter.setAttribute('id', id)
  filter.setAttribute('x', `-50`)
  filter.setAttribute('y', `-50`)
  filter.setAttribute('width', `200`)
  filter.setAttribute('height', `200`)
  filter.setAttribute('filterUnits', `userSpaceOnUse`)
  const feTurbulence = document.createElementNS('http://www.w3.org/2000/svg', 'feTurbulence')
  feTurbulence.setAttribute('type', `fractalNoise`)
  feTurbulence.setAttribute('baseFrequency', `0.2 0.2`)
  feTurbulence.setAttribute('numOctaves', `1`)
  feTurbulence.setAttribute('result', `warp`)
  const feDisplacementMap = document.createElementNS('http://www.w3.org/2000/svg', 'feDisplacementMap')
  feDisplacementMap.setAttribute('xChannelSelector', `R`)
  feDisplacementMap.setAttribute('yChannelSelector', `G`)
  feDisplacementMap.setAttribute('scale', `${start}`)
  feDisplacementMap.setAttribute('in', `SourceGraphic`)
  feDisplacementMap.setAttribute('in2', `warp`)
  filter.appendChild(feTurbulence)
  filter.appendChild(feDisplacementMap)
  scribbles?.appendChild(filter)
  return {
    id,
    start,
    end,
    dom: filter,
    fdDom: feDisplacementMap,
  }
}

const handleGenRect = (filterId: string, gradientId: string, type: 'down' | 'up') => {
  const id = 'rect' + generateUUID()
  const lightning = document.querySelector('#lightning')
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
  // const strokeWidth = Math.random() * 1 + 0.5
  const strokeWidth = Math.random() * .5 + 1
  const upPoints = [
    { x: 0, y: 20 },
    { x: 0, y: 0 },
    { x: 99, y: 0 },
    { x: 99, y: 25 },
    // { x: 85, y: 35 },
    // { x: 80, y: 25 },
  ]
  const downPoints = [
    { x: 0, y: 20 },
    { x: 0, y: 50 },
    { x: 99, y: 50 },
    { x: 99, y: 25 },
    // { x: 80, y: 45 },
    // { x: 80, y: 25 },
  ]
  const points = (type === 'down' ? downPoints : upPoints).map((d, i) => {
    if (i === 2) {
      return d
    }
    const random = Math.random() * 2
    const x = random > 1 ? d.x + random : d.x - random
    const y = random > 1 ? d.y + random : d.y - random
    return {
      x,
      y,
    }
  })
  rect.setAttribute(
    'points',
    `${points[0].x},${points[0].y} ${points[1].x},${points[1].y} ${points[2].x},${points[2].y} ${points[3].x},${points[3].y}`,
  )
  rect.setAttribute('filter', `url(#${filterId})`)
  rect.setAttribute('id', id)
  rect.setAttribute('class', 'strike')
  rect.setAttribute('stroke', `url(#${gradientId})`)
  rect.setAttribute('x', '0')
  rect.setAttribute('y', '0')
  rect.setAttribute('width', '102')
  rect.setAttribute('height', '52')
  rect.setAttribute('rx', '0')
  rect.setAttribute('fill', 'none')
  rect.setAttribute('stroke-miterlimit', '10')
  rect.setAttribute('stroke-width', `${strokeWidth}`)
  rect.style.position = 'absolute'
  rect.style.left = '0'
  rect.style.top = '0'
  rect.classList.add(type)
  lightning?.appendChild(rect)
  return {
    id,
    dom: rect,
  }
}

const handleGenLightningItem = (type: 'down' | 'up') => {
  const filterConfig = handleGenRandomFilter()
  const gradientNum = Math.floor(Math.random() * (gradientList.length - 1) + 1)
  const linearGradientId = `gradient${gradientNum}`
  const rectConfig = handleGenRect(filterConfig.id, linearGradientId, type)
  animate((process, type) => {
    if (type === 'over') {
      filterConfig.dom && filterConfig.dom.remove()
      rectConfig.dom && rectConfig.dom.remove()
      return
    }
    const total = filterConfig.end - filterConfig.start
    const finalValue = Math.floor(filterConfig.end - process * total)
    filterConfig.fdDom.setAttribute('scale', `${finalValue}`)
  })
}

interface TConfirmButton extends ButtonProps {
  delayType?: 'debounce' | 'throttle'
  delay?: number
}
const MainButton: FC<TConfirmButton> = ({ delayType, delay, children, ...props }) => {
  const [btnProcess, setBtnProcess] = useState(0)
  // const process = useRef(0)
  const { run: _throttleClick } = useThrottleFn(
    e => {
      !props.disabled && !props.loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  const { run: _debounceClick } = useDebounceFn(
    e => {
      !props.disabled && !props.loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  const handleStartAnimation = () => {
    const scribbles = document.querySelector('#scribbles')
    scribbles?.classList.add('active')
    const borderGradient = document.querySelector('.border-gradient')
    borderGradient?.classList.add('active')
    const lightning = document.querySelector('#lightning')
    lightning?.classList.add('active')
    let count = 0
    const timer = setInterval(() => {
      count = Math.min(1, count + 0.03)
      const temp = Math.floor(count * 100)
      // const random = Math.random() * 100
      const type = temp % 2 === 0 ? 'down' : 'up'
      handleGenLightningItem(type)
      // for (let i = 0; i < 10 * newValue; i++) {
      //   const random = Math.random() * 100
      //   const type = i % 2 === 0 ? 'down' : 'up'
      //   setTimeout(() => {
          
      //   }, random)
      // }
      console.log(count, 'count')
      if (count === 1) {
        timer && clearInterval(timer)
      }
      setBtnProcess(count)
    }, 300)

    // const filter1 = handleFilterAnimation('filter1')
    // const filter2 = handleFilterAnimation('filter2')
    // const filter3 = handleFilterAnimation('filter3')
    // const filter4 = handleFilterAnimation('filter4')
    // for (let i = 0; i < 10; i ++) {
    //   const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    //   const filterNum = Math.floor(Math.random() * (filterList.length - 1) + 1)
    //   const gradientNum = Math.floor(Math.random() * (gradientList.length - 1) + 1)
    //   const strokeWidth = Math.random() * 2
    //   rect.setAttribute('filter', `url(#filter${filterNum})`)
    //   rect.setAttribute('class', 'strike')
    //   rect.setAttribute('stroke', `url(#gradient${gradientNum})`)
    //   rect.setAttribute('x', '0')
    //   rect.setAttribute('y', '0')
    //   rect.setAttribute('width', '100')
    //   rect.setAttribute('height', '50')
    //   rect.setAttribute('rx', '20')
    //   rect.setAttribute('fill', 'none')
    //   rect.setAttribute('stroke-miterlimit', '10')
    //   rect.setAttribute('stroke-width', `${strokeWidth}`)

    //   lightning?.appendChild(rect)
    // }
    // setInterval(() => {
    //   const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    //   const filterNum = Math.floor(Math.random() * (filterList.length - 1) + 1)
    //   const gradientNum = Math.floor(Math.random() * (gradientList.length - 1) + 1)
    //   const strokeWidth = Math.random() * 2
    //   rect.setAttribute('filter', `url(#filter${filterNum})`)
    //   rect.setAttribute('class', 'strike')
    //   rect.setAttribute('stroke', `url(#gradient${gradientNum})`)
    //   rect.setAttribute('x', '0')
    //   rect.setAttribute('y', '0')
    //   rect.setAttribute('width', '100')
    //   rect.setAttribute('height', '50')
    //   rect.setAttribute('rx', '20')
    //   rect.setAttribute('fill', 'none')
    //   rect.setAttribute('stroke-miterlimit', '10')
    //   rect.setAttribute('stroke-width', `${strokeWidth}`)

    //   lightning?.appendChild(rect)
    // }, 100)

    // filterList

    // const
    // <rect
    //   filter='url(#filter)'
    //   className='strike'
    //   stroke='url(#gradient)'
    //   x='0'
    //   y='0'
    //   width='100'
    //   height='50'
    //   rx='20'
    //   fill='none'
    //   stroke-miterlimit='10'
    //   stroke-width='1.5'
    // />
  }

  const handleStopAnimation = () => {
    const scribbles = document.querySelector('#scribbles')
    scribbles?.classList.remove('active')
    const borderGradient = document.querySelector('.border-gradient')
    borderGradient?.classList.remove('active')
    const lightning = document.querySelector('#lightning')
    lightning?.classList.remove('active')
  }

  return (
    <StyledMainButton
      process={btnProcess}
      onClick={() => handleStartAnimation()}
      onDoubleClick={() => handleStopAnimation()}
      // onClick={delayType === 'debounce' ? _debounceClick : delayType === 'throttle' ? _throttleClick : props.onClick}
      {...props}
    >
      <svg id='scribbles' aria-hidden='true' preserveAspectRatio='none' viewBox='0 0 100 50'>
        <filter
          color-interpolation-filters='sRGB'
          id='glow'
          x='-50'
          y='-50'
          width='200'
          height='200'
          filterUnits='userSpaceOnUse'
        >
          <feGaussianBlur stdDeviation='10' />
          <feComponentTransfer>
            <feFuncA type='linear' slope='2' />
          </feComponentTransfer>
          <feBlend in2='SourceGraphic' />
        </filter>
        <filter
          color-interpolation-filters='sRGB'
          id='filter'
          x='-50'
          y='-50'
          width='200'
          height='200'
          filterUnits='userSpaceOnUse'
        >
          <feTurbulence type='fractalNoise' baseFrequency='0.15 0' numOctaves='1' result='warp'></feTurbulence>
          <feDisplacementMap xChannelSelector='R' yChannelSelector='G' scale='5' in='SourceGraphic' in2='warp' />
        </filter>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient1'>
        <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#69eeff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient2' gradientTransform='rotate(65)'>
        <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#69eeff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient3'>
          <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#69eeff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient4'>
          <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#69eeff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient5'>
          <stop offset='0%' stop-color='#fff' />
          <stop offset='100%' stop-color='#6ff5ff' />
        </linearGradient>
        {/* <linearGradient gradientUnits='userSpaceOnUse' id='gradient1'>
          <stop offset='0%' stop-color='#ffe17e' />
          <stop offset='10%' stop-color='#f65426' />
          <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#6ff5ff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient2' gradientTransform='rotate(65)'>
          <stop offset='0%' stop-color='#ffe17e' />
          <stop offset='10%' stop-color='#f65426' />
          <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#6ff5ff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient3'>
          <stop offset='0%' stop-color='#69eeff' />
          <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#69eeff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient4'>
          <stop offset='0%' stop-color='#fff' />
          <stop offset='50%' stop-color='#fff' />
          <stop offset='100%' stop-color='#69eeff' />
        </linearGradient>
        <linearGradient gradientUnits='userSpaceOnUse' id='gradient5'>
          <stop offset='0%' stop-color='#fff' />
          <stop offset='50%' stop-color='#f65426' />
          <stop offset='100%' stop-color='#6ff5ff' />
        </linearGradient> */}
        <g id='lightning'>
          {/* <polygon
            points="5,15 2,5 10,3 80,5 95,30"
            filter="url(#filter)"
            className="strike"
            stroke="url(#gradient1)"
            x="0"
            y="0"
            width="100"
            height="50"
            rx="38.59"
            fill="none"
            stroke-miterlimit="10"
            stroke-width="1.5"
          /> */}
          {/* <rect
            filter='url(#filter2)'
            className='strike'
            stroke='url(#gradient2)'
            x='0'
            y='0'
            width='100'
            height='50'
            rx='20'
            fill='none'
            stroke-miterlimit='10'
            stroke-width='2'
          />
          <rect
            filter='url(#filter3)'
            className='strike'
            stroke='url(#gradient3)'
            x='0'
            y='0'
            width='100'
            height='50'
            rx='20'
            fill='none'
            stroke-miterlimit='10'
            stroke-width='1.5'
          />
          <rect
            filter='url(#filter2)'
            className='strike'
            stroke='url(#gradient3)'
            x='0'
            y='0'
            width='100'
            height='50'
            rx='20'
            fill='none'
            stroke-miterlimit='10'
            stroke-width='1'
          />
          <rect
            filter='url(#filter4)'
            className='strike'
            stroke='url(#gradient3)'
            x='0'
            y='0'
            width='100'
            height='50'
            rx='20'
            fill='none'
            stroke-miterlimit='10'
            stroke-width='1.5'
          /> */}
        </g>
      </svg>
      {children}
    </StyledMainButton>
  )
}

export default MainButton

const StyledMainButton = styled(Button)<{ process: number }>`
  opacity: 0.8;
  position: absolute;
  top: 300px;
  left: 50px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${props => props.process * 100}%;
    border-radius: 8px;
    height: 100%;
    z-index: -1;
    /* background: rgb(0, 0, 0, 0.2); */
    transition: width 300ms linear 0s;
    opacity: 1;
    background: url(${specialBtnBgImg}) no-repeat;
  }
  &,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    width: 366px;
    height: 58px;
    box-shadow: inset 0px 4px 8px 0px #73368a;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #8b41a5;
    background: rgba(255, 255, 255, .1);
    /* background: url(${specialBtnBgImg}) no-repeat; */
    
    color: #fff;
  }
  &:before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:hover,
  &:focus {
    color: #fff;
    background: rgba(255, 255, 255, .1);
    /* background: url(${specialBtnBgImg}) no-repeat; */
    border: 1px solid #8b41a5;
    filter: brightness(1.2);
  }

  #scribbles {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-out 0s;
    position: absolute;
    height: 58px;
    width: 366px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
    .strike {
      opacity: 1;
      stroke-dashoffset: 0px;
      stroke-dasharray: 15px, 295px;
    }
    /* .strike:nth-child(1n) {
      stroke-dashoffset: 20px;
      stroke-dasharray: 20px, 270.2px;
    }
    .strike:nth-child(2n) {
      stroke-dashoffset: 0;
      stroke-dasharray: 60px, 240px;
    }
    .strike:nth-child(3n) {
      stroke-dashoffset: 0;
      stroke-dasharray: 30px, 270px;
    }
    .strike:nth-child(4n) {
      stroke-dashoffset: -360px;
      stroke-dasharray: 60px, 240px;
    }
    .strike:nth-child(5n) {
      stroke-dashoffset: -60px;
      stroke-dasharray: 60px, 240px;
    } */
    &.active {
      opacity: 1;
      .strike {
        animation: strikes 1s ease-out infinite;
      }
      /* .strike:nth-child(1) {
        animation: strikes1 .5s ease-out infinite;
      }
      .strike:nth-child(2) {
        animation: strikes2 2s ease-out infinite;
      }
      .strike:nth-child(3) {
        animation: strikes3 2s ease-out infinite;
      }
      .strike:nth-child(4) {
        animation: strikes4 2s ease-out infinite;
      }
      .strike:nth-child(5) {
        animation: strikes5 2s ease-out infinite;
      } */
    }
  }

  .border-gradient {
    opacity: 0;
    transition: opacity 2s ease-out 0s;
    &.active {
      opacity: 1;
    }
  }

  #lightning {
    opacity: 0;
    transition: opacity 0.1s ease-out 0s;
    &.active {
      opacity: 1;
    }
  }

  @keyframes strikes {
    0% {
      stroke-dashoffset: 0px;
      stroke-dasharray: 15px, 295px;
      opacity: 1;
    }
    95% {
      stroke-dashoffset: -130px;
      stroke-dasharray: 15px, 295px;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -130px;
      stroke-dasharray: 15px, 295px;
      opacity: 0;
    }
  }
  /* 
  @keyframes strikes1 {
    0% {
      stroke-dashoffset: 20px;
      stroke-dasharray: 20px, 270.2px;
      opacity: 1;
    }
    95% {
      stroke-dashoffset: -72px;
      stroke-dasharray: 20px, 270px;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -72px;
      stroke-dasharray: 20px, 270px;
      opacity: 0;
    }
  }

  @keyframes strikes2 {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 60px, 240px;
      opacity: 1;
    }
    95% {
      stroke-dashoffset: -300px;
      stroke-dasharray: 60px, 240px;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -300px;
      stroke-dasharray: 60px, 240px;
      opacity: 0;
    }
  }

  @keyframes strikes3 {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 30px, 270px;
      opacity: 1;
    }
    95% {
      stroke-dashoffset: -405px;
      stroke-dasharray: 30px, 270px;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -405px;
      stroke-dasharray: 30px, 270px;
      opacity: 0;
    }
  }

  @keyframes strikes4 {
    0% {
      stroke-dashoffset: -360px;
      stroke-dasharray: 60px, 240px;
      opacity: 1;
    }
    95% {
      stroke-dashoffset: -105px;
      stroke-dasharray: 60px, 240px;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -105px;
      stroke-dasharray: 60px, 240px;
      opacity: 0;
    }
  }

  @keyframes strikes5 {
    0% {
      stroke-dashoffset: -60px;
      stroke-dasharray: 60px, 240px;
      opacity: 1;
    }
    95% {
      stroke-dashoffset: -405px;
      stroke-dasharray: 60px, 240px;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: -405px;
      stroke-dasharray: 60px, 240px;
      opacity: 0;
    }
  } */
`

// tl.to("#lightning", { opacity: 1, duration: 0.1 })
//   .to(".border-gradient", { opacity: 1 })
//   .to("#filter feDisplacementMap", { attr: { scale: "10" }, ease: "rough" }, 0)
//   .to("#filter2 feDisplacementMap", { attr: { scale: "30" }, ease: "rough" }, 0)
//   .to("#filter4 feDisplacementMap", { attr: { scale: "40" }, ease: "rough" }, 0)
//   .fromTo(strikes[0], { drawSVG: "100% 90%" }, { drawSVG: "0% 10%" }, 0)
//   .fromTo(strikes[1], { drawSVG: "0% 20%" }, { drawSVG: "100% 100%" }, 0)
//   .fromTo(strikes[2], { drawSVG: "0% 10%" }, { drawSVG: "135% 140%" }, 0)
//   .fromTo(strikes[3], { drawSVG: "120% 140%" }, { drawSVG: "35% 40%" }, 0)
//   .fromTo(strikes[4], { drawSVG: "20% 40%" }, { drawSVG: "135% 140%" }, 0)
//   .to("#lightning", { opacity: 0, duration: 0.3 }, "-=0.4");
