import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import classNames from 'classnames'
// import ClearIcon from 'src/assets/icon/layout-header/clearIcon.svg'

type TBaseInput = {
  style?: React.CSSProperties
  className?: string
  onFocus?: Function
  onBlur?: Function
  onKeyDown?: (e: any) => void
  readOnly?: boolean
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  maxLen?: number
  type?: React.HTMLInputTypeAttribute | undefined
  allowClear?: boolean
  onClear?: Function
  defaultValue?: string | number
  styled?: 'normal' | 'edit'
  disabled?: boolean
  onPressEnter?: () => void
}

const BaseInput: FC<TBaseInput> = ({
  children,
  onFocus,
  onBlur,
  onClear,
  className,
  readOnly,
  value,
  onChange,
  placeholder,
  maxLen,
  type,
  allowClear,
  defaultValue,
  disabled,
  onKeyDown,
  styled = 'normal',
  onPressEnter,
  ...props
}) => {
  const [bgColor, setBgColor] = useState('rgba(255,255,255,0.12)')
  const handleFocus = () => {
    setBgColor('rgba(195,140,255,0.16)')
    onFocus?.()
  }
  const handleBlur = () => {
    setBgColor('rgba(255,255,255,0.12)')
    onBlur?.()
  }
  return (
    <StyledBaseInputContainer
      style={{ background: bgColor }}
      className={classNames('sg-base-input-warpper', className || '', {
        'sg-base-input-warpper-edit': styled === 'edit',
      })}
      {...props}
    >
      {children}
      <input
        disabled={disabled}
        onKeyDown={onKeyDown}
        onKeyPress={e => e.key === 'Enter' && onPressEnter?.()}
        defaultValue={defaultValue}
        autoComplete='off'
        placeholder={placeholder}
        readOnly={readOnly}
        className={classNames('sg-base-input', {
          'sg-base-input-edit': styled === 'edit',
        })}
        type={type || 'text'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        {...(typeof value !== undefined ? { value } : {})}
        {...(maxLen ? { maxLength: maxLen } : {})}
      />
      {/* {allowClear && value !== undefined && value !== '' && (
        <ClearIcon onClick={() => onClear?.()} className='clear-btn app-nodrag' />
      )} */}
    </StyledBaseInputContainer>
  )
}
const StyledBaseInputContainer = styled.div`
  width: 240px;
  height: 38px;
  background: ${props => props.theme.color.bg.$FFFFFF12};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 200ms ease 0s;
  .sg-base-input {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    outline: none;
    border: none;
    color: var(--text-color-FFFFFF);
    background-color: transparent;
    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
    &::placeholder {
      color: ${props => props.theme.color.text.$BBBBBB};
      font-size: 14px;
    }
  }
  .clear-btn {
    position: absolute;
    right: 10px;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: auto;
    cursor: pointer;
    color: ${props => props.theme.color.text.$A7A7A9};
    z-index: 20;
    &:hover {
      color: var(--text-color-FFFFFF);
    }
  }

  &.sg-base-input-warpper-edit {
    background: ${props => props.theme.color.bg.$54545860};
    border-radius: 2px;
  }
`

export default observer(BaseInput)
