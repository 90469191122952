import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { getHomeViewDatasHttp } from 'src/apis/game-api'
import { appStore } from 'src/store'
import styled from 'styled-components'
import classNames from 'classnames'
import useHomeListCalcSize from './use-home-list-calc-size'
import ArrowIcon from 'src/assets/images/index-new/arrow.svg'
import SpeedIcon from 'src/assets/images/index-new/speed.svg'
import ModifierIcon from 'src/assets/images/index-new/modifier.svg'
import CloudIcon from 'src/assets/images/index-new/cloud.svg'
import TrialIcon from 'src/assets/images/index-new/trial.svg'
import ResourceIcon from 'src/assets/images/index-new/resource.svg'
import LeftArrowsIcon from 'src/assets/icons/home/left-arrows.svg'
import RightArrowsIcon from 'src/assets/icons/home/right-arrows.svg'
import mask from 'src/assets/images/index-new/mask.png'

const CONTAINER_PADDING = 0
const ITEM_MARGIN_RIGHT = 26
const ITEM_MIN_WIDTH = 260 + ITEM_MARGIN_RIGHT
const ITEM_MAX_WIDTH = 298 + ITEM_MARGIN_RIGHT
const ITEM_IMAGE_ASPECT_RATIO = 269 / 124

const ToolsServiceStatus: FC = () => {
  const navigate = useNavigate()
  const [resourceCount, setResourceCount] = useState<Game.HomeViewData | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const MAP = useMemo(() => {

    return [
      {
        title: '网游加速器',
        titleIcon: <SpeedIcon/>,
        color: 'rgba(31,244,75,0.1)',
        color2: 'radial-gradient(#208A36 0%, rgba(12,75,25,.8) 100%)',
        target: '/main/game-search?supportSpeed=1',
        // @ts-ignore
        isView: () => true,
        text1: () => {
          return resourceCount?.speedGame || '--'
        },
        text2: () => {
          return resourceCount?.speeding || '--'
        },
      },
      {
        title: '单机修改器',
        titleIcon: <ModifierIcon/>,
        color: 'rgba(192,130,255,0.1)',
        color2: 'radial-gradient(#A257EE 0%, rgba(121,56,187,.8) 100%)',
        target: '/main/game-search?supportModifier=1',
        isView: () => {
          return appStore.isInWhiteList
        },
        text1: () => {
          return resourceCount?.buffGame || '--'
        }
      },
      {
        title: '共享账号',
        title2: '支持游戏',
        titleIcon: <TrialIcon/>,
        color: 'rgba(255,201,41,0.1)',
        color2: ' radial-gradient(#C8A332 0%, rgba(160,131,41,.8) 100%)',
        target: '/main/game-search?supportTrial=1',
        isView: () => true,
        text1: () => {
          return resourceCount?.trialGame || '--'
        }
      },
      {
        title: '云游戏',
        titleIcon: <CloudIcon/>,
        color: 'rgba(25,197,254,0.1)',
        color2: 'radial-gradient(#2B98BD 0%, rgba(28,101,125,.8) 100%)',
        target: '/main/game-search?supportCloudGame=1',
        isView: () => true,
        text1: () => {
          return resourceCount?.cloudGame || '--'
        }
      },
      {
        title: '游戏资源',
        titleIcon: <ResourceIcon/>,
        color: 'rgba(203,57,76,0.1)',
        color2: 'radial-gradient(#982735 0%, rgba(160,41,41,.8) 100%)',
        target: '/main/game-search?supportResource=1',
        isView: () => true,
        text1: () => {
          return resourceCount?.sourceGame || '--'
        }
      },
    ]
  }, [resourceCount])

  const {
    viewNum,
    offsetIndex,
    setOffsetIndex,
    listLength,
  } = useHomeListCalcSize(
    containerRef,
    {
      CONTAINER_PADDING,
      ITEM_MARGIN_RIGHT,
      ITEM_MIN_WIDTH,
      ITEM_MAX_WIDTH,
      ITEM_IMAGE_ASPECT_RATIO,
    }, MAP.map(d => d.isView()).length)

  const handleLoadResourceCount = async () => {
    const { result } = await getHomeViewDatasHttp()
    setResourceCount(result)
  }

  const jumpToStore = (target: string) => {
    navigate(target)
  }

  useEffect(() => {
    handleLoadResourceCount()
  }, [])

  return (
    <StyledToolsServiceStatusContainer ref={containerRef}>
      <div className='title'>工具&服务状态
        {listLength > viewNum && (
          <div className='extra'>
            <div className="icon">
              <LeftArrowsIcon
                className={classNames({ disabled: offsetIndex === 0 })}
                onClick={() => setOffsetIndex(value => Math.max(0, value - 1))}
              />
            </div>
            <div className="icon">
              <RightArrowsIcon
                className={classNames({ disabled: offsetIndex > listLength - viewNum - 1 })}
                onClick={() => setOffsetIndex(value => Math.min(listLength - viewNum, value + 1))}
              />
            </div>
          </div>
        )}
      </div>
      <div className="scroll">
        <div
          className='content'
          style={{
            transform: `translate(calc(-${offsetIndex} * (var(--install-game-block-width) + ${
              ITEM_MARGIN_RIGHT
            }px)), 0)`,
          }}
        >
          {MAP.map(d => {
            if (!d.isView()) {
              return null
            }
            const text1 = typeof d.text1 === 'function' ? d.text1() : d.text1
            return (
              <StyledItemContainer
                key={d.title}
                color={d.color}
                onClick={() => d.target && jumpToStore(d.target)}
                style={{
                  // @ts-ignore
                  '--color':d.color,
                  '--color2':d.color2
                }}
              >
                <div className="normal">
                  <div className="icon">
                    {d.titleIcon}
                  </div>
                  <div className="info">
                    <div className='name'>
                      <strong>{d.title}</strong> {d.title2 ||'支持项目'}
                    </div>
                    <div className="num">{text1}</div>
                  </div>
                </div>
                <div className="hover">
                  <div className="icon">
                    {d.titleIcon}
                  </div>
                  <strong>查看全部</strong>
                </div>
                <ArrowIcon className='arrow-icon'/>
              </StyledItemContainer>
            )
          })}
        </div>
      </div>
    </StyledToolsServiceStatusContainer>
  )
}

const StyledItemContainer = styled.div<{ color: string }>`
  width: var(--install-game-block-width);
  height: 97px;  
  margin-right: 26px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;  
  backdrop-filter: blur(60px);
  position: relative;  
  &:last-child{
    margin-right: 0;
  }
  .icon{
    margin-left: 24px;
    margin-right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color);
  }
  .normal,.hover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    >*{
      position: relative;
      z-index: 10;
    }
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: url("${mask}");
      opacity: 0.03;
      z-index: 5;
      backdrop-filter: blur(60px);
    }
  }
  .normal{    
    display: flex;
    align-items: center;
    background: rgba(32,32,32,0.5);    
    opacity: 1;
    .name{
      font-size: 14px;
      color: #6e7377;
      line-height: 18px;
      >strong{
        color: #fff;
        font-weight: normal;
        margin-right: 8px;
      }
    }
    .num{
      font-size: 18px;
      line-height: 18px;
      margin-top: 12px;
      font-family: Orbitron;
      font-weight: 600;
      color: #FFFFFF;      
    }
  }
  
  .hover{
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      background: var(--color2);
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
      opacity: .4;
      backdrop-filter: blur(60px);
    }
    .icon{
      margin-left: 0;
      margin-right: 8px;
    }
    >strong{
      color: #fff;
      font-size: 16px;
      font-weight: normal;
    }
  }
  
  .arrow-icon{
    position: absolute;
    right: 16px;
    top: 16px;
    transition: all .3s;
  }
  
  &:hover{
    .normal{
      opacity: 0;
    }
    .hover{
      opacity: 1;
    }
    .arrow-icon{
      transform: rotate(45deg);
    }
  }
  
 
`

const StyledToolsServiceStatusContainer = styled.div`
  margin-top: 30px;
  .title{
    font-size: 20px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
    margin-bottom: 16px;
    position: relative;
    .extra {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      .icon{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255,255,255,0.12);
        &:last-child{
          margin-left: 8px;
        }
      }
      svg {
        margin-right: 16px;
        color: rgba(255, 255, 255, 0.6);
        &:not(.disabled) {
          cursor: pointer;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
        &.disabled {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
  .content{
    position: absolute;
    display: flex;
    transition: transform 150ms ease 0s;
    left: 0;
    top: 0;
  }
  .scroll{
    height: 97px;
    width: 100%;
    position: relative;
    overflow: hidden;    
  }
  @font-face {
    font-family: 'Orbitron';
    src: url('/Orbitron-Bold.ttf') format('truetype');
  }
`

export default observer(ToolsServiceStatus)
