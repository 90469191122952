import { observable, action } from 'mobx'
import { VipStore } from 'src/store'
import { fetchCheckSpeedStatus, reportSpeedStatus } from 'src/apis/user-api'
import { message } from 'antd'
import { notifyOpenVipBySpeed } from 'src/tools/common'
import CEFTransfer from 'src/CEFTransfer'

let heartCheckTimer: number | null = null

/**
 * @description 停止加速场景
 */
export const EXIT_SPEED_MAPING = {
  /** 客户端通知加速停止 */
  CLIENT_NOTICE_EXIT: {
    type: 'client_speed_exit',
    code: 10001,
    reason: '客户端通知加速停止',
    message: '',
  },
  /** 启动加速超时 */
  START_TIMEOUT: {
    type: 'startTimeout',
    code: 10002,
    reason: '启动加速超时',
    message: '',
  },
  /** 网络连接断开 */
  NETWORK_DISCONNECT: {
    type: 'network_disconnect',
    code: 10003,
    reason: '网络连接断开',
    message: '',
  },
  /** 退出登录 */
  USER_LOGOUT: {
    type: 'user_logout',
    code: 10004,
    reason: '退出登录',
    message: '',
  },
  /** 用户停止加速 */
  USER_STOP: {
    type: 'user',
    code: 10005,
    reason: '用户停止加速',
    message: '',
  },
  /** 切换加速游戏,结束本次加速 */
  CHANGE_SPEED_GAME: {
    type: 'user',
    code: 10006,
    reason: '切换加速游戏,结束本次加速',
    message: '',
  },
  /** 切换加速节点,结束本次加速 */
  CHANGE_SPEED_NODE: {
    type: 'user',
    code: 10007,
    reason: '切换加速节点,结束本次加速',
    message: '',
  },
  /** 心跳检测到加速单非加速中状态 */
  HEART_CHECK_STOPED: {
    type: 'heart_check_stoped',
    code: 10008,
    reason: '心跳检测到加速单非加速中状态',
    message: '',
  },
  /** 用户退出客户端 */
  EXIT_CLIENT: {
    type: 'user',
    code: 10009,
    reason: '用户退出客户端',
    message: '',
  },
  /** 已申请加速单,查询我的加速游戏列表报错 */
  FETCH_LIST_ERROR: {
    type: 'speedFail',
    code: 10010,
    reason: '已申请加速单,查询我的加速游戏列表接口报错',
    message: '',
  },
  /** 长时间加速流量未变化 */
  SPEED_IDLE: {
    type: 'speedIdle',
    code: 10011,
    reason: '加速流量长时间未变化, 自动结束加速',
    message: '',
  },
  /** 未知错误 */
  OTHER: {
    type: 'other',
    code: 99999,
    reason: '未知错误',
    message: '',
  },
}

type StopParams = {
  notify: boolean
  report: boolean
  reason: keyof typeof EXIT_SPEED_MAPING | typeof EXIT_SPEED_MAPING['OTHER']
}

class Speed {
  /** 弹框透传的一些额外参数 */
  @observable
  speedModalParmas: any = null

  @observable
  speedModalType:
    | 'none'
    | 'close-notice'
    | 'check-node'
    | 'multi-start'
    | 'not-vip'
    | 'network-lost'
    | 'settings'
    | 'authLogin' = 'none'

  @observable
  speedStatus: 'none' | 'starting' | 'speeding' = 'none'

  @observable
  speedingInfo: SpeedInfo | null = null

  @observable
  startingSpeedInfo: SpeedInfo | null = null

  @observable
  checkedSpeedInfo: SpeedInfo | null = null

  @observable
  recordSpeedBytes: {
    time: number
    bytes: string
  } | null = {
    time: 0,
    bytes: '',
  }

  @observable
  isSwitchSpeed: boolean = false

  @observable
  checkedCategoryIds: number[] = []

  @observable
  platformSpeedStatus: 'none' | 'starting' | 'speeding' | 'stoping' = 'none'

  @observable
  platformSpeedSuccessTime: number = Date.now()

  @observable
  speedingDelayChartsData: Record<string, { timestamp: number; delay: number; time: string; timer: number }> = {}

  @action
  stopSpeed = async ({ notify, report, reason }: StopParams) => {
    this.stopHeartCheck()
    const speedInfo = this.hasSpeeding()
    if (speedInfo) {
      this.clearSpeedInfo()
      if (report && speedInfo?.speedNo) {
        try {
          const { result } = await fetchCheckSpeedStatus({ speedNo: speedInfo.speedNo })
          if (['starting', 'speeding'].includes(result.status)) {
            const isCustomReason = typeof reason !== 'string'
            let stopReasonType = ''
            let remark: typeof reason | null = null
            if (!isCustomReason) {
              const stopInfo = EXIT_SPEED_MAPING[reason] || EXIT_SPEED_MAPING['OTHER']
              stopReasonType = stopInfo.type || 'other'
              remark = stopInfo
            } else {
              stopReasonType = reason.type || ''
              remark = reason
            }
            await reportSpeedStatus({
              speedNo: speedInfo.speedNo,
              event: 'stop',
              stopReasonType,
              remark: JSON.stringify(remark),
            })
            console.log('reportSpeedStatus done')
          }
        } catch (error) {
          console.warn(error)
        }
      }
      notify && (await CEFTransfer.sendToClient('stopSpeed', {}, { timeout: 10000 }))
      VipStore.handleLoadUserRemainingSpeedBalance()
      VipStore.handleLoadVipFreeConfig()
      VipStore.handleLoadVipInfo()
      this.speedingDelayChartsData = {}
    }
  }

  @action
  clearSpeedInfo = () => {
    this.speedingInfo = null
    this.startingSpeedInfo = null
    this.speedStatus = 'none'
    this.recordSpeedBytes = null
  }

  @action
  startHeartCheck = () => {
    this.stopHeartCheck()
    heartCheckTimer = window.setTimeout(async () => {
      if (this.speedStatus === 'speeding' && this.speedingInfo) {
        try {
          const { result } = await fetchCheckSpeedStatus({ speedNo: this.speedingInfo.speedNo })
          if (!result.isSpeeding) {
            this.stopSpeed({ notify: true, report: false, reason: 'HEART_CHECK_STOPED' })
            if (result.stopType === 'expired') {
              notifyOpenVipBySpeed('stop')
            }
          } else {
            this.speedingInfo.speedTime = +new Date(result.nowTime) - +new Date(result.startTime)
            this.startHeartCheck()
            VipStore.handleLoadUserRemainingSpeedBalance()
            VipStore.handleLoadVipInfo()
          }
        } catch (error) {
          console.error(error)
          this.startHeartCheck()
          VipStore.handleLoadUserRemainingSpeedBalance()
        }
      } else {
        this.stopHeartCheck()
      }

      /** 因王恒要求从30s轮询改为3min */
    }, 1000 * 60 * 3)
  }

  @action
  speedSuccess = (speedInfo: SpeedInfo) => {
    setTimeout(() => {
      this.speedStatus = 'speeding'
      VipStore.handleStartSpeedCountdown(speedInfo.speedNo)
    }, 800)
    if (this.startingSpeedInfo) {
      this.startingSpeedInfo.speedProgress = 100
    }
    this.speedingInfo = speedInfo
    this.recordSpeedBytes = {
      time: Date.now(),
      bytes: '',
    }
    this.startHeartCheck()
    message.success('加速成功')
  }

  /**
   * @description 停止心跳检测
   */
  stopHeartCheck = () => {
    if (heartCheckTimer) {
      window.clearTimeout(heartCheckTimer)
      heartCheckTimer = null
    }
  }

  hasSpeeding() {
    return (
      (this.speedStatus === 'starting' && this.startingSpeedInfo) ||
      (this.speedStatus === 'speeding' && this.speedingInfo)
    )
  }
}

export const SpeedStore = new Speed()
