import { baseFetch, persistentFetch } from 'src/fetch'
import { javaResultFilter, phpResultFilter } from 'src/fetch/resInterceptors'

/**
 * @description 查询游戏详情
 * @method get
 * @params speedGameNo
 */
export const fetchGameInfo = baseFetch<Http.JavaApiResult<Http.GameInfo>, { speedGameNo: string }>({
  url: '/game/sgt',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 第三方游戏信息查询
 * @method get
 */
export const fetchThirdGameInfo = baseFetch<Http.JavaApiResult<Http.ThirdGameInfo>, { speedGameNo: string }>({
  url: '/game/query',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 获取加速游戏列表
 * @method get
 */
export const fetchSpeedGameList = baseFetch<Http.JavaApiResult<Http.TGetGameListResult>, Http.TGetGameListPayload>({
  url: '/games/sgt',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询加速游戏类目
 * @method get
 */
export const fetchGetGameCategories = baseFetch<Http.JavaApiResult<Http.TGetGameCategoriesResult[]>>({
  url: '/game/categories',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 通过ids查询列表
 * @method get
 */
export const fetchGameListWithIds = baseFetch<Http.JavaApiResult<Http.GameInfo[]>, { speedGameNos: string }>({
  url: '/game/list?',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 获取会员商品
 * @method get
 */
export const fetchVipGoodsList = baseFetch<Http.JavaApiResult<Http.TVipGoods[]>, { activityNo?: string }>({
  url: '/goods/list',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 获取会员商品详情
 * @method get
 */
export const fetchVipGoodsDetail = persistentFetch<
  Http.JavaApiResult<Http.TVipGoods>,
  { goodsId: number; activityNo?: string }
>({
  url: '/goods',
  method: 'get',
  key: 'fetchVipGoodsDetail',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取加速器时长商品列表 */
export const speedTimeGoodsListHttp = baseFetch<Http.JavaApiResult<Http.TVipGoods[]>>({
  url: '/anon/speed/goods/list',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取云游戏时长商品列表 */
export const cloudGameTimeGoodsListHttp = baseFetch<Http.JavaApiResult<{goodsList:Http.TCloudGoodsList[], recommendGoodsId:number}>>({
  url: 'anon/get/cloud/game/goods/list',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取会员商品列表 */
export const vipGoodsListHttp = baseFetch<Http.JavaApiResult<Http.TVipGoods[]>>({
  url: '/anon/vip/goods/list',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取加速器免费时段文案 */
export const speedFreePeriodHttp = baseFetch<Http.JavaApiResult<Http.TSpeedFreePeriod>>({
  url: '/anon/freePeriod/speed',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取修改器免费时段文案 */
export const trainerFreePeriodHttp = baseFetch<Http.JavaApiResult<Http.TSpeedFreePeriod>>({
  url: '/anon/freePeriod/trainer',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取云游戏免费时段 */
export const cloudGameFreePeriodHttp = baseFetch<Http.JavaApiResult<Http.TCloudGameFreePeriod>>({
  url: '/anon/freePeriod/cloud/game',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取每周赠送云游戏时长 */
export const cloudGameGiveTimeHttp = baseFetch<Http.JavaApiResult<Http.ICloudGameGiveTime>>({
  url: '/anon/api/vip/give/game/cloud/config',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取资源搜索的提示(自动提示功能) */
export const getResourceSuggestHttp = baseFetch<
  Http.JavaApiResult<Http.BaseMultPageResult<Resource.TResourceSuggestItem>>
>({
  url: '/anon/gameLibrary/master/suggest',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 根据游戏母版id获取平台下拉列表 */
export const getDropdownPlatformListHttp = baseFetch<
  Http.JavaApiResult<Resource.TPlatformItem[]>,
  { gameLibraryMasterId: number | string }
>({
  url: '/anon/gameResource/dropDown/platform',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 根据游戏母版id获取资源类型下拉列表 */
export const getDropdownResourceTypeListHttp = baseFetch<
  Http.JavaApiResult<Resource.TResourceTypeItem[]>,
  { gameLibraryMasterId: number | string }
>({
  url: '/anon/gameResource/dropDown/resourceType',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取母版下的资源列表 */
export const getResourceListByGameMasterId = baseFetch<
  Http.JavaApiResult<Resource.TGetResourceListByGameMasterIdResult>,
  Resource.TGetResourceListByGameMasterIdPayload
>({
  url: '/anon/gameResource/search',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 通过资源id获取资源详情 */
export const getResourceDetailByResourceId = baseFetch<
  Http.JavaApiResult<Resource.TResourceDetail>,
  { resourceId: number | string }
>({
  url: '/anon/gameResource/detail',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 通过资源id获取资源详情 */
export const getResourceDownloadUrlHttp = baseFetch<
  Http.JavaApiResult<{ fileResource: { filePath: string; storageType: 'CLOUD_STORAGE' | 'PLATFORM_STORAGE' } }>,
  { resourceId: number | string }
>({
  url: '/gameResource/download',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取gamelist的oss文件地址 */
export const getGameLibraryOssAllDownloadHttp = baseFetch<Http.JavaApiResult<{ downloadUrl: string }>>({
  url: '/anon/gameLibrary/oss/download',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取game详情 */
export const getGameLibraryMasterDetailHttp = baseFetch<
  Http.JavaApiResult<Game.GameLibraryMasterDetail>,
  { gameLibraryMasterId?: string; trainerGameId?: string; speedGameNo?: string; gameLibraryBranchId?: string }
>({
  url: '/anon/gameLibrary/master/detail',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: (data)=>javaResultFilter(data,{warnToast:false}),
  },
})

/** 获取游戏关联的修改器 */
export const getGameLibraryBranchListTrainerGameHttp = baseFetch<
  Http.JavaApiResult<Game.BranchAssociatedDataTrainerGame[]>,
  { gameLibraryBranchId: string | number }
>({
  url: '/anon/gameLibrary/branch/listTrainerGame',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取游戏关联的加速器 */
export const getGameLibraryBranchListSpeedGameDetailHttp = baseFetch<
  Http.JavaApiResult<Game.BranchAssociatedDataSpeedGame[]>,
  { gameLibraryBranchId: string | number }
>({
  url: '/anon/gameLibrary/branch/listSpeedGame',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取游戏关联的云游戏 */
export const getGameLibraryBranchQueryCloudGame = baseFetch<
  Http.JavaApiResult<Http.TCloudGameByGameBranch>,
  { gameLibraryBranchId: string | number }
>({
  url: '/anon/gameLibrary/branch/queryCloudGame',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取游戏关联的试玩 */
export const getGameLibraryBranchQueryTrialGame = baseFetch<
  Http.JavaApiResult<Http.TTrialGameByGameBranch>,
  { gameLibraryBranchId: string | number }
>({
  url: '/anon/gameLibrary/branch/queryTrialGame',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/** 获取平台详情 */
export const getPlatformDetailHttp = baseFetch<Http.JavaApiResult<Game.PlatformDetail>, { platformId: string }>({
  url: '/anon/gamePlatform/detail',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取平台关联的加速器 */
export const getPlatformListSpeedGameDetailHttp = baseFetch<
  Http.JavaApiResult<Game.BranchAssociatedDataSpeedGame[]>,
  { platformId: string | number }
>({
  url: '/anon/gamePlatform/listSpeedGame',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取首页展示数据 */
export const getHomeViewDatasHttp = baseFetch<Http.JavaApiResult<Game.HomeViewData>>({
  url: '/anon/gamecount',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取首页展示数据 */
export const getPlatformWelfareByPlatformId = baseFetch<
  Http.JavaApiResult<Game.TPlatformWelfare[]>,
  { platform: number }
>({
  url: '/anon/free/game/list',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取进程名 */
export const getProcessNameByOrder = baseFetch<
  Http.JavaApiResult<{processName: string}>,
  { orderNo: string }
>({
  url: '/game/trial/fulu-common-cloud/anon/cloudSave/config/getByOrder',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/** 获取存档列表 */
export const getGameArchive = baseFetch<
  Http.JavaApiResult<GameArchive[]>,
  GameArchiveQuery
>({
  url: '/game/trial/fulu-common-cloud/anon/cloud/cd/list',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 删除存档 */
export const deleteGameArchive = baseFetch<
  Http.BoxApiResult<null>,
  { key:string }
>({
  url: '/game/trial/api/deleteArchive',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: phpResultFilter,
  },
})

/** 保存云存档 */
export const saveCloudArchive = baseFetch<
  Http.JavaApiResult<null>,
  { id: string; name: string }
>({
  url: '/game/trial/anon/cloud/saveLog/updateCloudGameCloudSaveLogInfo',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 修改云存档名称 */
export const updateCloudArchiveName = baseFetch<
  Http.JavaApiResult<null>,
  { name: string; userId: string; id: number }
>({
  url: '/game/trial/fulu-common-cloud/anon/cloud/saveLog/updateName',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 上报云存档下载次数 */
export const reportCloudArchivesDownloadNum = baseFetch<
  Http.JavaApiResult<{ id: number; type: 1 | 7; ctime: string }[]>,
  { orderNo: string }
>({
  url: '/game/trial/api/addArchiveDownloadNum',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})