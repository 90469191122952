import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import Banner from './banner'
import ToolsServiceStatus from './tools-service-status'
import InstalledGame from './installed-game'
import FreePlayGame from './free-play-game'
import Search from './search'
import bg from 'src/assets/images/index-new/bg.png'

const Home=()=>{

  return <StyledIndexContainer>
    <Search/>
    <ToolsServiceStatus/>
    <InstalledGame />
    <FreePlayGame />
    {/*<Banner />*/}
  </StyledIndexContainer>
}




const StyledIndexContainer=styled.div`
  //min-height: 100vh;
  //background: url("${bg}") top center/100% auto no-repeat;
  padding: 32px;
`


export default observer(Home)