import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, message } from 'antd'
import { vipGoodsListHttp } from 'src/apis/game-api'
import { useNavigate } from 'react-router-dom'
import { FadeinImage } from 'src/components/base-image'
import { VipStore } from 'src/store'
import { LoginStore } from 'src/store/modifier'
import { appStore } from 'src/store'
import { formatTimeLocal } from 'src/tools/helper'
import dayjs from 'dayjs'
import styled from 'styled-components'
import classNames from 'classnames'
import alipayImg from 'src/assets/images/common/alipay.png'
import wechatImg from 'src/assets/images/common/wechat.png'
import rights1 from 'src/assets/images/vip-center/rights1.png'
import rights2 from 'src/assets/images/vip-center/rights2.png'
import rights3 from 'src/assets/images/vip-center/rights3.png'
import rights4 from 'src/assets/images/vip-center/rights4.png'
import rights5 from 'src/assets/images/vip-center/rights5.png'
import PayModal from 'src/components/pay-modal'

enum PaymentMethod {
  wechat_scan = 'wechat_scan',
  alipay_scan = 'alipay_scan',
}

const PAYMENT_METHOD_MAPPING = [
  {
    key: PaymentMethod.alipay_scan,
    name: '支付宝支付',
    icon: alipayImg,
  },
  {
    key: PaymentMethod.wechat_scan,
    name: '微信支付',
    icon: wechatImg,
  },
]

const COLOR_MAPPING = ['#C38CFF', '#FFCE76', '#FF5FAC', '#66ACFE']

/** 根据商品时长(单位：h), 计算单价 */
const getUnitPrice = (price: number, duration: number) => {
  let p = (price / duration).toFixed(2)
  let unit = '小时'
  let n = duration
  if (duration > 24) {
    // 天
    p = ((price / duration) * 24).toFixed(2)
    unit = '天'
    n = Math.floor(duration / 24)
  }
  return { number: n, price: p, unit }
}


const VipRecharge: FC = () => {
  const navigate = useNavigate()
  const [vipGoodsList, setVipGoodsList] = useState<Http.TVipGoods[]>([])
  const [currentActiveVipGoods, setCurrentActiveVipGoods] = useState<Http.TVipGoods>()
  const [payModalVisible, setPayModalVisible] = useState<boolean>(false)

  const vipRights=[
    {
      img:rights1,
      title:<>3000+款<strong>游戏共享</strong>账号免费使用</>,
      desc:'最新、最热门游戏，第一时间免费体验，周周上新游，天天玩大作'
    },
    {
      img:rights2,
      title:<>2000+款<strong>游戏修改器</strong>免费使用</>,
      desc:'多平台游戏支持、可视化操作面板，简单易用，安全稳定'
    },
    {
      img:rights3,
      title:<>1500+<strong>游戏加速器</strong>免费使用</>,
      desc:'海量游戏、全球节点覆盖；金融专线，超低延迟'
    },
    {
      img:rights4,
      title:<>自研<strong>云游戏</strong>黑科技</>,
      desc:`任意设备，轻松玩转精品大作，无需下载一键畅玩，每周赠送${formatTimeLocal(VipStore.cloudGameGiveTime?.vipUserGiveTime??0)}免费时长`
    },
    {
      img:rights5,
      title:<>平台<strong>海量游戏资源</strong>免费使用</>,
      desc:'存档、MOD、语言包应有尽有，告别单调，DIY你的游戏体验'
    },
  ]

  const handleVipGoodsList = async () => {
    const { result } = await vipGoodsListHttp()
    const finalVipGoodsList = result.filter((f, i) => i < 4)
    setVipGoodsList(finalVipGoodsList)
    // let defaultIndex = null
    // if (appStore.vipgoodsRecommendConfig) {
    //   const findIndex = finalVipGoodsList.findIndex(f => f.id === appStore.vipgoodsRecommendConfig)
    //   defaultIndex = findIndex > -1 ? findIndex : null
    // }
    // if (!defaultIndex) {
    //   let min = finalVipGoodsList[0]?.salePrice || 0
    //   defaultIndex = 0
    //   finalVipGoodsList.forEach((f, i) => {
    //     if (f.salePrice < min) {
    //       defaultIndex = i
    //       min = f.salePrice
    //     }
    //   })
    // }
    /** 博文要求 默认选中权重最高的 也就是第一个 */
    finalVipGoodsList?.[0] && setCurrentActiveVipGoods(finalVipGoodsList[0])
  }

  const onSuccess = () => {
    //从其他页面跳转的会员页，开通成功后关闭会员页，回到原页面。
    if (appStore.userCenterReferer) {
      appStore.userCenterVisible = false
    }
    // message.success('购买成功')
    VipStore.handleLoadVipInfo()
  }

  const currentDesc=useMemo(()=>{
    if(!currentActiveVipGoods){
      return ''
    }
    const { unit, number } = getUnitPrice(currentActiveVipGoods.salePrice, currentActiveVipGoods.vipDuration)
    return  `${number}${unit}`
  },[currentActiveVipGoods])

  useEffect(() => {
    handleVipGoodsList()
  }, [])

  useEffect(() => {
    VipStore.handleLoadVipInfo()
  }, [])

  return (
    <StyledVipRechargeContainer>
      <StyledTitle>
        会员订阅
      </StyledTitle>
      {LoginStore.userinfo && (
        <StyledVipRechargeHeader>
          <div className='vip-status'>
            当前会员状态：
            <span
              className='main'
              style={{ color: VipStore.memberinfo?.isMember ? 'rgba(55, 198, 43, 1)' : 'rgba(195, 140, 255, 1)' }}
            >
              {VipStore.memberinfo?.isMember ? '启用中' : '未开通'}
            </span>
          </div>
          {VipStore.memberinfo?.isMember && (
            <div className='expire-time'>
              到期时间：{dayjs(VipStore.memberinfo.expireDate).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          )}
        </StyledVipRechargeHeader>
      )}
      <StyledVipRechargeContent>
        <div className='vip-recharge-section-title' key='vip-recharge-3'>
          规格选择
        </div>
        <StyledVipCenterContent key='vip-recharge-4'>
          {vipGoodsList.map((d, i) => {
            const { price, unit, number } = getUnitPrice(d.salePrice, d.vipDuration)
            return (
              <StyledVipGoodsItem
                className={classNames({ active: currentActiveVipGoods?.id === d.id })}
                onClick={() => setCurrentActiveVipGoods(d)}
                key={`vip-goods-item-${d.id}`}
              >
                <div className='animate-bor' />
                <div className='vip-goods-top'>
                  <div className='vip-goods-name'>{d.name}</div>
                  <div className='vip-goods-size' style={{ color: COLOR_MAPPING[i] }}>
                    <span className='main'>{number}</span>
                    {unit}
                  </div>
                </div>
                <div className='vip-goods-bottom'>
                  <div className='sale-price'>¥{d.salePrice}</div>
                  <div className='source-price'>
                    原价：<span className='main'>¥{d.originalPrice || d.costPrice}</span>
                  </div>
                </div>
                {/*<FadeinImage*/}
                {/*  width={31}*/}
                {/*  height={28}*/}
                {/*  className='active-tag'*/}
                {/*  type='notDefault'*/}
                {/*  src={vipGoodsIconArrowsImg}*/}
                {/*/>*/}
                <div className='normal-tag' />
              </StyledVipGoodsItem>
            )
          })}
        </StyledVipCenterContent>

        <StyledBuy>
          <Button type='primary' className='btn' onClick={()=>setPayModalVisible(true)}>立即开通</Button>
        </StyledBuy>

        <div className='vip-recharge-section-title' key='vip-recharge-7'>
          会员权益
        </div>
        <StyledVipRights>
            {
              vipRights.map((value, index) => {
                return <li>
                  <div className="img">
                    <FadeinImage src={value.img} width={224} height={159}/>
                  </div>
                  <h3>{value.title}</h3>
                  <p>{value.desc}</p>
                </li>
              })
            }
        </StyledVipRights>
      </StyledVipRechargeContent>
      <PayModal
        visible={payModalVisible}
        goodsInfo={{
          goodsName: currentActiveVipGoods?.name,
          desc: currentDesc,
          goodsId: currentActiveVipGoods?.id,
          price: currentActiveVipGoods?.salePrice
        }}
        onSuccess={onSuccess}
        onClose={()=>setPayModalVisible(false)}
        autoSuccessModal={true}
      />
    </StyledVipRechargeContainer>
  )
}

export const StyledBuy=styled.div`
  display: flex;

  .btn {
    width: 236px;
    height: 42px;
    background: #7044CD;
    border-radius: 4px 4px 4px 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: auto;

    &:hover {
      background: #7949de;
    }
  }
`

const StyledVipRights=styled.ul`
  margin-top: 16px;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(2,1fr);
  >li{
    height: 278px;
    background: #202024;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      height: 159px;
    }
    >h3{
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 0;
      >strong{
        color: #C38CFF;
      }
    }
    >p{
      line-height: 22px;
      margin-top: 8px;
      margin-bottom: 0;
      width: 270px;
      color: #bbbbbb;
      font-size: 14px;
      text-align: center;
    }
  }
`

export const StyledPaymentContainer = styled.div`
  ${props => props.theme.flexAlignCenter}
  padding-left: 30px;
  margin-top: 16px;
  width: 100%;
  height: 188px;
  background: #26282B;
  border-radius: 4px;

  .vip-goods-protocol {
    font-size: 12px;
    line-height: 12px;
    color: #a1a1a1;
    margin-top: 30px;
    .primary {
      color: #f5f5f7;
      cursor: pointer;
      &:hover {
        color: var(--text-color-FFFFFF);
      }
    }
  }
  .vip-goods-overprice-rate-description {
    //width: 500px;
    height: 30px;
    background: linear-gradient(90deg, rgba(233, 56, 47, 0.3) 0%, transparent 100%);
    font-size: 14px;
    line-height: 14px;
    color: var(--text-color-FFFFFF);
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    margin-bottom: 16px;
    &::before {
      content: ' ';
      width: 2px;
      position: absolute;
      height: 30px;
      top: 0;
      left: 0;
      background: #e9382f;
    }
    .vip-goods-overprice-icon {
      color: #e9382f;
      margin-right: 10px;
    }
    .primary {
      color: #e9382f;
      margin: 0 4px;
    }
  }
  .vip-goods-payment-price {
    min-width: 280px;
    margin-top: 6px;
    font-size: 18px;
    line-height: 18px;
    color: #f5f5f7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .main {
      color: #ffb731;
      font-family: Impact-Regular, Impact;
      font-size: 24px;
      line-height: 24px;
    }
  }
  .qrcode-box {
    padding: 6px;
    width: 140px;
    height: 140px;
    margin: 0 40px 0 45px;
    background: ${props => props.theme.color.bg.$FFFFFF};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    .qrcode-mark {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(4px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-color-FFFFFF);
      cursor: pointer;
    }
  }
  .vip-goods-payment-method-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .vip-goods-payment-method-item {
      display: flex;
      align-items: center;
      padding-left: 22px;
      cursor: pointer;
      width: 140px;
      height: 56px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 14px;
      color: var(--text-color-FFFFFF);
      position: relative;
      overflow: hidden;
      background: #1E1F22;
      border: 1px solid transparent;
      .vip-goods-payment-method-text {
        margin-left: 8px;
      }
      .vip-goods-payment-recommend {
        width: 33px;
        height: 18px;
        background: #e9382f;
        border-radius: 8px 100px 100px 0px;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        color: var(--text-color-FFFFFF);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        //background: ${props => props.theme.color.bg.$FFFFFF30};
        border-color: #7044CD;
      }
      &.active {
        //background: rgba(192, 138, 254, 0.24);
        border-color: #7044CD;
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
`

const StyledVipCenterContent = styled.div`
  display: flex;
  margin-top: 16px;
  min-height: 168px;
  margin-bottom: 22px;
`

export const StyledVipGoodsItem = styled.div`
  position: relative;
  flex: 0.25;
  height: 168px;
  background: #1C1C1F;
  border-radius: 4px;
  margin-right: 15px;
  cursor: pointer;
  backdrop-filter: blur(30px);
  overflow: hidden;
  &:last-child {
    margin-right: 0;
  }
  .animate-bor {
    content: '';
    opacity: 0;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    padding: 1px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    z-index: -1;
    mask-composite: exclude;
    -webkit-mask-composite: xor;
    border-radius: 4px;
    transition: opacity 300ms ease 0s;
    &::before {
      content: '';
      display: block;
      background: conic-gradient(
        from 180deg at 50% 50%,
        #e92a67 0deg,
        #a853ba 112.5deg,
        #2a8af6 228.75deg,
        rgba(42, 138, 246, 0) 360deg
      );
      width: calc(100% * 1.41421356237);
      padding-bottom: calc(100% * 1.41421356237);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -2;
      animation: landing_spin 5s linear infinite;
    }
    @keyframes landing_spin {
      0% {
        transform: translate(-50%, -50%) rotate(1turn);
      }
      to {
        transform: translate(-50%, -50%) rotate(0);
      }
    }
  }
  .vip-goods-top {
    ${props => props.theme.flexCenter};
    flex-direction: column;
    height: 118px;
    .vip-goods-name {
      font-size: 16px;
      line-height: 22px;
      color: #bbb;
      padding: 0 10px;
    }
    .vip-goods-size {
      margin-top: 12px;
      font-size: 16px;
      line-height: 24px;
      font-family: Impact-Regular, Impact;      
      .main{
        font-size: 20px;
      }
    }
  }  
  .vip-goods-bottom {
    ${props => props.theme.flexCenter}
    height: 50px;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 0 0 4px 4px;
    .sale-price {
      font-size: 20px;
      line-height: 22px;
      font-family: Impact-Regular, Impact;
      color: var(--text-color-FFFFFF);
    }
    .source-price {
      margin-left: 8px;
      font-size: 12px;
      line-height: 24px;
      color: #bbb;
      .main {
        text-decoration: line-through;
      }
    }
  }
  .normal-tag {
    position: absolute;
    top: 0;
    right: 0;
    width: 104px;
    height: 30px;
    background: ${props => props.theme.color.mainColor};
    opacity: 0.3;
    filter: blur(36px);
    transition: opacity 300ms ease 0s;
  }
  .active-tag {
    position: absolute;
    bottom: 1px;
    right: 1px;
    opacity: 0;
    transition: opacity 300ms ease 0s;
  }
  &:hover {
    .animate-bor {
      opacity: 1;
    }
  }
  &.active {
    background: linear-gradient(270deg, #402375 0%, #5B359B 100%);
    box-shadow: 0px 4px 30px 0px rgba(17,5,36,0.4);
    .vip-goods-size {
      color: var(--text-color-FFFFFF) !important;
    }
    .sale-price {
      color: #ffb731;
    }
    .active-tag {
      opacity: 1;
    }
    .normal-tag {
      opacity: 0;
    }
    .vip-goods-bottom {
      background-color: rgba(255, 255, 255, 0.12);
    }
    .animate-bor{
      opacity: 0 !important;
    }
  }
`

const StyledBalanceRow = styled.div`
  ${props => props.theme.flexAlignCenter}
  margin-top: 16px;
  width: 100%;
  height: 60px;
  background: ${props => props.theme.color.bg.$FFFFFF06};
  padding: 0 30px;
  border-radius: 4px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
  font-weight: bold;
  color: var(--text-color-FFFFFF);
  img {
    width: 28px;
    height: 28px;
    margin-right: 16px;
  }
  svg {
    margin-left: 16px;
  }
  .balance-time {
    margin-left: 36px;
  }
`

const StyledPowerDescContainer = styled.div`
  display: flex;
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  margin-bottom: 18px;
  .power-col-box {
    flex: 1;
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: linear-gradient(180deg, #4f4f4f 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 4px 4px 0 0;
      .power-col-title {
        color: #ffb731;
      }
      .power-col-text {
        font-weight: 800;
        font-size: 16px;
        color: #ffffff;
      }
    }
    &.power-title {
      .power-col-title {
        font-size: 14px;
      }
    }
    .power-col-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
      font-weight: bold;
      color: #bbbbbb;
      line-height: 26px;
      height: 54px;
    }
    .power-col-text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #bbbbbb;
      line-height: 24px;
      background: rgba(255, 255, 255, 0.06);
      margin-bottom: 2px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`

const StyledVipRechargeContent = styled.div`
  .vip-recharge-section-title {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
    margin-top: 32px;
  }
`

const StyledVipRechargeHeader = styled.div`
  //position: absolute;
  //top: 0;
  //left: 0;
  display: flex;
  align-items: center;
  //width: 100%;
  height: 48px;
  background: #26282B;
  padding: 0 24px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #bbbbbb;
  line-height: 24px;
  //backdrop-filter: blur(60px);
  .expire-time {
    margin-left: 40px;
  }  
`

const StyledTitle=styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  line-height: 32px;
  color: #fff;
`

const StyledVipRechargeContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  overflow: overlay;
  &::-webkit-scrollbar {
    display: none;
  }
  &:before{
    content: '';
    display: block;
    width: 77%;
    height: 800px;
    background: radial-gradient(at 0 0, #462469 0%, rgba(15,13,12,0) 70%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: .4;
    pointer-events: none;
  }
`

export default observer(VipRecharge)
