import React, { FC } from 'react'
import OutlineIcon from 'src/assets/icons/loading/outline.svg'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const antIcon = <OutlineIcon style={{ fontSize: 24 }} />

type TOutlineLoading = {
  loading: boolean
}
const OutlineLoading: FC<TOutlineLoading> = ({ loading }) => {
  return <StyledOutlineLoadingContainer>{antIcon}</StyledOutlineLoadingContainer>
}

const StyledOutlineLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: OutlineLoading 1000ms linear 0s infinite;
`

export default observer(OutlineLoading)
