import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { SearchInput } from 'src/components/base-input'
import { useThrottleFn } from 'ahooks'
import { Spin, Tooltip, message, Dropdown } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { TextButton, ConfirmButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { ResourceType } from 'src/constants/common'
import {
  speedTimeGoodsListHttp,
  getDropdownPlatformListHttp,
  getDropdownResourceTypeListHttp,
  getResourceListByGameMasterId,
} from 'src/apis/game-api'
import { DETAIL_INDEX_HEIGHT } from 'src/constants/common'
import { useNavigate, useParams } from 'react-router-dom'
import { FadeinImage } from 'src/components/base-image'
import { VipStore, appStore } from 'src/store'
import { LoginStore, ProtocolStore, ClientLocalStorageStore } from 'src/store/modifier'
import { ControllerStore } from 'src/store/resource'
import { throttle } from 'lodash'
import FilterSelector from 'src/pages/game-detail/resource/filter-selector'
import ResourceItem from 'src/pages/game-detail/resource/resource-item'
import useUrlState from '@ahooksjs/use-url-state'
import QueueAnim from 'rc-queue-anim'
import styled from 'styled-components'
import classNames from 'classnames'

import emptyImg from 'src/assets/images/common/empty.png'
import selectorIconImage from 'src/assets/images/common/selector-icon.png'
import SearchIcon from 'src/assets/icons/layout/search-icon.svg'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const ITEM_MARGIN_RIGHT = 26
const ITEM_MIN_WIDTH = 230 + ITEM_MARGIN_RIGHT
const ITEM_MAX_WIDTH = 330 + ITEM_MARGIN_RIGHT
const ITEM_IMAGE_ASPECT_RATIO = 298 / 138

const GameInfo: FC<{ id: string; platformId: string; branchId?: string }> = ({ id, platformId, branchId }) => {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const [viewTopButon, setViewTopButton] = useState<boolean>(false)
  const [resourceList, setResourceList] = useState<Resource.TResourceItem[]>([])
  const [over, setOver] = useState<boolean>(false)
  const [pageNo, setPageNo] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchWord, setSearchWord] = useState<string>('')
  const [resourceType, setResourceType] = useState<string>('')
  const [resourceTypeList, setResourceTypeList] = useState<Resource.TResourceTypeItem[]>([])

  const { run: throttleGetGameList } = useThrottleFn(
    e => {
      const { offsetHeight, scrollTop } = e.target
      setViewTopButton(scrollTop > offsetHeight)
      gameListScroll(e)
    },
    { wait: 500 },
  )

  const { run: onSearch } = useThrottleFn(
    (key: string) => {
      setSearchWord(key)
    },
    { wait: 500, leading: false, trailing: true },
  )

  const handleGetResourceList = async (gameLibraryMasterId: string) => {
    setLoading(true)
    const result = await getResourceListByGameMasterId({
      pageNo,
      pageSize: 30,
      keyword: searchWord,
      platformIds: Number(platformId) === 0 ? '' : platformId,
      resourceTypes: resourceType,
      gameLibraryMasterId,
      gameLibraryBranchId: branchId,
    })
    setOver(pageNo * 30 >= result.result.totalRows)
    if (pageNo === 1) {
      setResourceList(result.result.data)
    } else {
      setResourceList([...resourceList, ...result.result.data])
    }
    setLoading(false)
  }

  const gameListScroll = (e?: any) => {
    const { scrollHeight, scrollTop } = e.target
    if (scrollHeight - scrollTop - e.target.offsetHeight <= 450 && !over) {
      setPageNo(pageNo + 1)
      setLoading(true)
    }
  }

  const handleSearchWord = (key: string) => {
    setSearchWord(key)
    onSearch(key)
  }

  const init = async (gameLibraryMasterId: string) => {
    const [{ result: resourceTypeList }] = await Promise.all([getDropdownResourceTypeListHttp({ gameLibraryMasterId })])
    setResourceTypeList([{ resourceType: '', resourceTypeName: '全部资源' }, ...resourceTypeList])
  }

  const handleResourceItemClick = (d: Resource.TResourceItem) => {
    if (d.resourceType === ResourceType.INFORMATION && d.presentType === 'LINK') {
      return () => cefOpenUrl(d.resourceLink || '')
    }
    if (d.resourceType === ResourceType.INFORMATION && d.presentType === 'POPUP') {
      return ControllerStore.openInformationModal
    }
    return ControllerStore.openDetailModal
  }

  useEffect(() => {
    const handleResize = throttle(
      () => {
        if (!scrollContainer.current) {
          return
        }
        const content = scrollContainer.current
        const contentTrueWidth = content.offsetWidth - 1
        const maxElementNum = Math.floor(contentTrueWidth / ITEM_MAX_WIDTH)
        const minElementNum = Math.floor(contentTrueWidth / ITEM_MIN_WIDTH)
        const trueElementNum = Math.max(maxElementNum, minElementNum, 1)
        const trueElementWidth = contentTrueWidth / trueElementNum - ITEM_MARGIN_RIGHT
        const trueElementImgHeight = trueElementWidth / ITEM_IMAGE_ASPECT_RATIO
        const trueElementItemHeight = trueElementImgHeight + 82
        const itemSizeObj = {
          blockWidth: trueElementWidth,
          blockHeight: trueElementItemHeight,
          blockImgHeight: trueElementImgHeight,
          blockNumByRow: trueElementNum,
        }
        content.style.setProperty('--block-width', `${itemSizeObj.blockWidth}px`)
        content.style.setProperty('--block-height', `${itemSizeObj.blockHeight}px`)
        content.style.setProperty('--block-img-height', `${itemSizeObj.blockImgHeight}px`)
      },
      100,
      { trailing: true },
    )
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }
    init(id)
  }, [id])

  useEffect(() => {
    if (!id) {
      return
    }
    if (pageNo === 1) {
      handleGetResourceList(id)
    } else {
      setPageNo(1)
    }
  }, [searchWord, platformId, resourceType, id])

  useEffect(() => {
    if (!id) {
      return
    }
    handleGetResourceList(id)
  }, [id, pageNo])

  return (
    <StyledContentBox>
      <div className='filter-row'>
        <FilterSelector
          list={resourceTypeList.map(d => ({ icon: '', name: d.resourceTypeName, key: d.resourceType }))}
          value={resourceType}
          changeValue={key => setResourceType(`${key}`)}
        />
        <div className='resource-search-box'>
          <SearchInput
            className='search-input'
            placeholder='搜索资源关键词'
            value={searchWord}
            onChange={handleSearchWord}
          />
          <ConfirmButton className='search-btn'>搜 索</ConfirmButton>
        </div>
      </div>
      <StyledResourceListContent onScroll={throttleGetGameList} ref={scrollContainer} bodySizeHeight={appStore.bodySize?.height || 0}>
        {!resourceList.length && (
          <StyledEmptyContainer>
            <FadeinImage width={91} height={91.5} type='notDefault' src={emptyImg} />
            <div>
              平台暂未收录和此游戏相关的资源，点击
              <TextButton onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>前往反馈</TextButton>
            </div>
          </StyledEmptyContainer>
        )}
        {/* {gameList?.map(d => {
          return (
            <StyledGameItemSkeletonContainer>
              <Skeleton.Input active={true} className='box' />
              <div className='top' />
              <div className='bottom-1' />
              <div className='bottom-2' />
            </StyledGameItemSkeletonContainer>
          )
        })} */}
        {resourceList?.map(d => {
          return <ResourceItem click={handleResourceItemClick(d)} resource={d} key={d.resourceId} />
        })}
        {loading && !over && (
          <div className='spin-box'>
            <Spin indicator={antIcon} />
          </div>
        )}
      </StyledResourceListContent>
    </StyledContentBox>
  )
}

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$C6C6C6};
  div {
    margin-top: 20px;
  }
`

const StyledResourceListContent = styled.div<{bodySizeHeight: number}>`
  --block-width: 0px;
  --block-height: 0px;
  --block-img-height: 0px;
  width: calc(100% + ${ITEM_MARGIN_RIGHT+'px'});
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  /* height: 420px; */
  margin-top: 20px;
  flex: 1; 
  overflow: auto;
  position: relative;
  .spin-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    color: #fff;
    font-size: 12px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledDropdownMenu = styled.div`
  width: 204px;
  border-radius: 8px;
  background: ${props => props.theme.color.bg.$252525};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.6);
  padding: 4px;
  .menu-item {
    display: flex;
    align-items: center;
    color: var(--text-color-FFFFFF);
    font-size: 14px;
    padding-left: 16px;
    height: 44px;
    border-radius: 8px;
    &:hover {
      background: ${props => props.theme.color.bg.$FFFFFF06};
    }
    .filter-img-box {
      font-size: 0;
      margin-right: 16px;
    }
    .filter-value {
    }
    svg {
      margin-right: 10px;
    }
  }
`

const StyledFilterSelector = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  width: 204px;
  height: 36px;
  background: ${props => props.theme.color.bg.$FFFFFF12};
  border-radius: 4px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  .filter-left {
    ${props => props.theme.flexAlignCenter}
    .filter-img-box {
      margin-right: 16px;
    }
    .filter-value {
    }
  }
  .filter-right {
    line-height: 0;
  }
`

const StyledContentBox = styled.div`
  padding: 32px 32px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter-row {
    position: relative;
    ${props => props.theme.flexAlignCenter}
    .resource-search-box {
      width: 314px;
      height: 36px;
      position: absolute;
      right: 0;
      top: 0;
      .search-input {
        width: 314px;
        .ant-input {
          width: 196px;
        }
      }
      .search-btn {
        width: 80px;
        height: 36px;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 4px 4px 0;
      }
    }
  }
`

export default observer(GameInfo)
