import React, { PropsWithChildren } from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

type TModalType = 'success' | 'warn' | 'info' | 'error'

export interface CustomModalProps extends ModalProps {
  singleButton?: boolean
  titleIcon?: React.ReactNode
  type?: TModalType
}

const IconMap: Record<TModalType, React.ReactNode> = {
  success: <CheckCircleOutlined />,
  warn: <ExclamationCircleOutlined />,
  info: <InfoCircleOutlined />,
  error: <CloseCircleOutlined />,
}

const UnityModal = ({ children, ...props }: PropsWithChildren<CustomModalProps>) => {
  const TypeIcon = props.titleIcon || (props.type ? IconMap[props.type] : null)
  const Title = (
    <div>
      {TypeIcon && <span className={`${props.type || ''} title-icon`}>{TypeIcon}</span>}
      {props.title}
    </div>
  )
  return (
    <StyledUnityModal centered maskClosable={false} {...props} title={Title}>
      <StyledModalContent>{children}</StyledModalContent>
    </StyledUnityModal>
  )
}

export default UnityModal

const StyledUnityModal = styled(Modal)<CustomModalProps>`
  .ant-modal-footer button.ant-btn:first-child {
    display: ${props => (props.singleButton ? 'none' : 'inline-block')};
    &:hover {
      border-color: #fff;
    }
  }
  .ant-modal-header {
    background: #252527;
    border: none;
    height: 50px;
    padding: 14px 24px;
    border-radius: 4px 4px 0 0;
    .ant-modal-title {
      font-size: 16px;
      color: #f5f5f7;
      .title-icon {
        margin-right: 8px;
        &.success {
          color: #00f9a6;
        }
        &.warn {
          color: #ff8800;
        }
        &.info {
          color: #00f9a6;
        }
        &.error {
          color: #ff4d4f;
        }
      }
    }
  }
  .ant-modal-close-x {
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: #727272;
    font-size: 16px;
    &:hover {
      color: #f5f5f7;
    }
  }
  .ant-modal-body {
    padding: 24px;
    border-radius: 0;
    max-height: 520px;
    overflow: auto;
    background: #303033;
  }
  .ant-modal-content {
    border-radius: 4px;
    background: #242424;
  }
  .ant-modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding: 20px 24px 24px;
    text-align: center;
    background: #303033;
    button.ant-btn {
      color: #ffbf28;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      min-width: 100px;
      background: transparent;
      &.ant-btn-primary {
        background: linear-gradient(270deg, #feb93e 0%, #ffdb4d 100%);
        color: #000;
        border: none;
      }
      & + button {
        margin-left: 20px;
      }
    }
  }
`
export const StyledModalContent = styled.div`
  font-size: 14px;
  color: #f5f5f7;
  p {
    margin: 0;
  }
  .bold {
    font-weight: bold;
  }
  .pointer {
    cursor: pointer;
  }
  .not-allowed {
    cursor: not-allowed;
  }
  .through {
    text-decoration: line-through;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
