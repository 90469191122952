import React, { FC, useEffect, useRef, useState } from 'react'
import { getTextWidth, applyAuthValid, computedDownloadCount } from 'src/tools/common'
import { observer } from 'mobx-react'
import { SpeedStore } from 'src/store'
import { message } from 'antd'
import { DownloadStore } from 'src/store/resource'
import { ConfirmButton } from 'src/components/base-button'
import { Dropdown } from 'antd'
import { getResourceDetailByResourceId, getResourceDownloadUrlHttp } from 'src/apis/game-api'
import { useEaseOutQuadAnimation, TAttr } from 'src/hooks/useEaseOutQuadAnimation'
import { ResourceType, RESOURCE_EXTRA_INFO_MAP } from 'src/constants/common'
import { FadeinImage } from 'src/components/base-image'
import { useFileDownload } from 'src/hooks'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import CEFTransfer from 'src/CEFTransfer'
import styled from 'styled-components'
import classNames from 'classnames'

import MoreIcon from 'src/assets/icons/layout/more.svg'
import PCDownloadIcon from 'src/assets/icons/common/pc-download.svg'
import defaultVerticalFigure from 'src/assets/images/common/default-vertical-figure.png'
import SpeedIcon from 'src/assets/icons/common/speed-icon.svg'

interface TGameItem {
  resource: Resource.TResourceItem
  click: (resourceId: number) => void
  inSubPage?: boolean
  menu?: React.ReactElement
}

const DEFAULT_TRANFORM_STYLE = {
  rotateX: 0,
  rotateY: 0,
  translate3d: '0px, 0px, 0px',
}

const ResourceItem: FC<TGameItem> = ({ resource, click, menu, inSubPage }) => {
  const link = DownloadStore.fileDownloadLinkCache[resource.resourceId]
  const info = link ? DownloadStore.fileDownloadProgress[link] : null
  const { handleEaseOutQuadAnimation: handleLightEaseOutQuadAnimation } = useEaseOutQuadAnimation(0.1, 0.2, 4)
  const [transformStyle, setTransformStyle] = useState(DEFAULT_TRANFORM_STYLE)
  const { download } = useFileDownload({
    link,
    autoClear: false,
    onCancel() {},
    onComplete(data) {
      message.success(`下载成功，请对照使用说明应用`)
      // 上报下载次数
      // reportMODDownload.Execute({
      //   goodsId: d.goodsId,
      // })
      // const modal = Modal.success({
      //   className: 'custom postsConfirm',
      //   title: '下载成功',
      //   // icon: null,
      //   width: 432,
      //   closable: true,
      //   centered: true,
      //   autoFocusButton: 'ok',
      //   content: (
      //     <StyledSuccess>
      //       <div className='text'>
      //         <strong>{d.productName}</strong>&nbsp;<span>下载完成！</span>
      //       </div>
      //       <p>文件目录：{data.filePath}</p>
      //     </StyledSuccess>
      //   ),
      //   okText: `打开下载目录`,
      //   cancelText: `我知道了`,
      //   onOk() {
      //     window?.FuluApp?.JSNotifyCppFunc('explorer_local_file', data.filePath)
      //   },
      // })
    },
    onFail() {
      message.warn('下载失败，请重试')
    },
  })
  const ref = useRef<HTMLDivElement>(null)
  const lightBlockRef = useRef<HTMLDivElement>(null)
  const handleCardAnimation = (mouseX: number, mouseY: number) => {
    setTransformStyle({
      rotateX: mouseY / 15,
      rotateY: -(mouseX / 15),
      translate3d: `0px, 0px, ${(Math.abs(mouseX) + Math.abs(mouseY)) / 100}px`,
    })
  }
  //获取下载链接
  const getLink = async (resourceId: string | number) => {
    const { result } = await getResourceDownloadUrlHttp({
      resourceId,
    })
    return result.fileResource
  }
  const handleDownloadResource = async (e: any) => {
    e.stopPropagation()
    if (DownloadStore.hasOtherDownloadTasks()) {
      return message.warn(`目前有正在执行的下载任务，请等任务结束后重试`)
    }
    let link = ''
    try {
      const result = await getLink(resource.resourceId)
      link = result.filePath
      if (result.storageType === 'CLOUD_STORAGE') {
        cefOpenUrl(link)
        return
      }
    } catch (e) {
      message.warn((e as Error).message)
      return
    }
    if (!link.startsWith('http')) {
      console.log('下载链接不正确')
      message.warn('下载失败，请重试')
      return
    }
    DownloadStore.fileDownloadLinkCache[resource.resourceId] = link
    download(resource.resourceName, link)
  }
  const handleLightAnimation = (mouseX: number, mouseY: number) => {
    const attrList: TAttr[] = []
    const lightWidth = parseFloat(window.getComputedStyle(lightBlockRef.current!).getPropertyValue('width')) // 获取 transform 样式值
    const transform = window.getComputedStyle(lightBlockRef.current!).getPropertyValue('transform') // 获取 transform 样式值
    let [, , , , tx, ty]: any =
      transform !== 'none' ? transform.match(/matrix\(([^\)]+)\)/)?.[1].split(',') : ['0', '0', '0', '0', '0', '0']
    const translateX = parseFloat(tx.trim())
    const translateY = parseFloat(ty.trim())
    const targetX = mouseX - lightWidth / 2
    const targetY = mouseY - lightWidth / 2
    attrList.push({
      origin: translateX,
      target: targetX,
    })
    attrList.push({
      origin: translateY,
      target: targetY,
    })
    handleLightEaseOutQuadAnimation(attrList, ([tx, ty]) => {
      if (!lightBlockRef.current) {
        return
      }
      lightBlockRef.current.style.transform = `translateX(${tx}px) translateY(${ty}px)`
    })
  }
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!ref.current || !lightBlockRef.current) {
      return
    }
    const rect = ref.current!.getBoundingClientRect()
    const cardCenterX = rect.left + rect.width / 2
    const cardCenterY = rect.top + rect.height / 2
    handleCardAnimation(e.clientX - cardCenterX, e.clientY - cardCenterY)
    handleLightAnimation(e.clientX - rect.left, e.clientY - rect.top)
  }

  const handleMouseLeave = () => {
    setTransformStyle(DEFAULT_TRANFORM_STYLE)
  }

  const handleOpenFilePath = (e: any) => {
    e.stopPropagation()
    if (!info) {
      return
    }
    CEFTransfer.sendToClient('explorer_local_file', { filePath: info.filePath })
  }
  return (
    <StyledResourceItemContainer ref={ref} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <StyledGameItemContent
        key={resource.resourceId}
        style={{
          transform: `rotateX(${transformStyle.rotateX}deg) rotateY(${transformStyle.rotateY}deg) translate3d(${transformStyle.translate3d})`,
        }}
        onClick={() => applyAuthValid(click)(resource.resourceId)}
      >
        <div ref={lightBlockRef} className='light-block' />
        <div className='img-box'>
          <FadeinImage width='100%' height='100%' draggable={false} src={resource.listImage || defaultVerticalFigure} />
        </div>
        {menu && (
          <Dropdown overlay={menu} trigger={['hover']} placement='bottomRight'>
            <div className='menu-icon' onClick={e => e.stopPropagation()}>
              <MoreIcon className='icon' />
            </div>
          </Dropdown>
        )}
        <div className='desc-box'>
          <div className='game-name'>
            <div
              className='name-tag'
              style={{
                backgroundColor: RESOURCE_EXTRA_INFO_MAP[resource.resourceType as ResourceType].bgColor,
                color: RESOURCE_EXTRA_INFO_MAP[resource.resourceType as ResourceType].textColor,
              }}
            >
              {resource.resourceType === ResourceType.INFORMATION ? resource.tag || resource.resourceTypeName : resource.resourceTypeName}
            </div>
            <div className='name-text ellipsis'>{resource.resourceName}</div>
          </div>
          {resource.fileResource && (
            <div className='game-desc'>
              <div className='desc-version ellipsis'>适用版本：{resource.fileResource.supportGameVersion}</div>
              <div className='desc-download'>
                {/* {inSubPage ? (
                <>
                  {!info && (
                    <ConfirmButton className='resource-button' onClick={e => handleDownloadResource(e)}>
                      立即下载
                    </ConfirmButton>
                  )}
                  {info && info.status === 2 && (
                    <ConfirmButton className='resource-button' loading={true}>
                      下载中...{info?.progress ?? 0}%
                    </ConfirmButton>
                  )}
                  {info && info.status === 1 && (
                    <ConfirmButton className='resource-button' onClick={e => handleOpenFilePath(e)}>
                      打开目录
                    </ConfirmButton>
                  )}
                </>
              ) : (
                <> */}
                <PCDownloadIcon />
                {computedDownloadCount(resource.fileResource.downloadCount)}
                {/* </>
              )} */}
              </div>
            </div>
          )}
        </div>
      </StyledGameItemContent>
    </StyledResourceItemContainer>
  )
}

const StyledGameItemContent = styled.div`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  will-change: transform;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
  .menu-icon {
    color: #ffffff;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .img-box {
    width: calc(var(--block-width) - 16px);
    height: var(--block-img-height);
    margin: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .desc-box {
    width: 100%;
    height: 66px;
    padding: 16px 12px 0;
    font-size: 14px;
    line-height: 19px;
    color: var(--text-color-FFFFFF);
    .game-name {
      ${props => props.theme.flexAlignCenter}
      .name-tag {
        ${props => props.theme.flexCenter}
        min-width: 48px;
        padding: 0 5px;
        height: 18px;
        background: rgba(250, 198, 13, 0.16);
        margin-right: 8px;
        line-height: 12px;
        font-size: 12px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #c38cff;
        border-radius: 18px;
      }
      .name-text {
        flex: 1;
        max-width: 165px;
        font-size: 14px;
        line-height: 14px;
        font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .game-desc {
      ${props => props.theme.flexAlignCenter}
      justify-content: space-between;
      font-size: 12px;
      line-height: 12px;
      font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
      font-weight: 500;
      color: #aaaaaa;
      margin-top: 9px;
      .desc-version {
        width: 160px;
      }
      .desc-download {
        .resource-button {
          height: 20px;
          width: 94px;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
    .icon {
      color: #555;
      line-height: 1;
      margin-left: 5px;
      margin-top: 0.3em;
    }
  }
  .status-tag {
    background: rgba(0, 0, 0, 0.69);
    border-radius: 0px 0px 16px 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 10px;
    font-size: 12px;
    color: #02ca70;
    display: flex;
    align-items: center;
    svg {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .light-block {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: '';
    opacity: 0;
    width: 80px;
    height: 80px;
    filter: blur(50px);
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    pointer-events: none;
  }
  &:hover {
    .light-block {
      opacity: 0.8;
    }
    .img-box {
      opacity: 0.8;
    }
  }
`

const StyledResourceItemContainer = styled.div`
  border-radius: 4px;
  perspective: 560px;

  width: var(--block-width);
  height: var(--block-height);
  position: relative;
  margin-right: 26px;
  margin-bottom: 26px;
`

export default observer(ResourceItem)
