import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, message, Tooltip } from 'antd'
import { TextButton } from 'src/components/base-button'
import { cloudGameTimeGoodsListHttp } from 'src/apis/game-api'
import { VipStore } from 'src/store'
import { formatTimeLocal } from 'src/tools/helper'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import {
  StyledBalanceRow,
  StyledDurationRechargeContainer,
  StyledVipCenterContent,
  StyledVipFreeText,
} from 'src/pages/duration-recharge'
import { StyledBuy, StyledVipGoodsItem } from 'src/pages/vip-recharge'
import RemainingTime from 'src/components/remaining-time'
import classNames from 'classnames'
import TipsIcon from 'src/assets/icons/common/tips.svg'
import PayModal from 'src/components/pay-modal'

const COLOR_MAPPING = ['#C38CFF', '#FFCE76', '#FF5FAC', '#66ACFE']

const CloudGameRecharge: FC = () => {
  const [vipGoodsList, setVipGoodsList] = useState<Http.TCloudGoodsList[]>([])
  const [currentActiveVipGoods, setCurrentActiveVipGoods] = useState<Http.TCloudGoodsList>()
  const [payModalVisible, setPayModalVisible] = useState<boolean>(false)

  const handleVipGoodsList = async () => {
    const { result:{goodsList, recommendGoodsId} } = await cloudGameTimeGoodsListHttp()
    console.log('result',goodsList)
    const list= goodsList?.sort?.((a, b) => a.sort - b.sort)?.splice?.(0, 4) || []
    setVipGoodsList(list)
    // const defaultActive = list.find(value => value.id === recommendGoodsId)
    setCurrentActiveVipGoods(list[0])
  }

  const onSuccess=async ()=>{
    // message.success('购买成功')
    await VipStore.handleLoadUserRemainingCloudGameBalance()
  }

  useEffect(() => {
    handleVipGoodsList()
  }, [])

  return (
    <StyledDurationRechargeContainer>
      <div className='vip-center-title' key='vip-center-1'>
        规格选择
      </div>
      <div className='vip-center-desc' key='vip-center-2'>
        若您从其他平台已获得了MyBuff的云游戏时长CDK您可以
        <TextButton onClick={() => showUserCenter(NAV_KEY_MAPPING.CDK_EXCHANGE)}>点击前往激活</TextButton>
      </div>
      <StyledBalanceRow key='vip-center-3'>
        <div className='balance-title-text'>
          可用云游戏时长
          <Tooltip
            color={'rgb(45, 45, 45)'}
            overlayInnerStyle={{
              borderRadius: '4px',
              width: 300,
            }}
            overlay={
              <StyledVipFreeText>
                <div className='vip-free-text'>
                  <TextButton onClick={() => showUserCenter(NAV_KEY_MAPPING.VIP)}>MyBuff会员</TextButton>
                  每周可获赠{formatTimeLocal(VipStore.cloudGameGiveTime?.vipUserGiveTime??0)}云游戏时长
                </div>
              </StyledVipFreeText>
            }
            placement='bottom'
          >
            <TipsIcon />
          </Tooltip>
        </div>
        <div className='balance-time'>
          <RemainingTime timeType='cloudgame' />
        </div>
      </StyledBalanceRow>
      <StyledVipCenterContent key='vip-center-4'>
        {vipGoodsList.map((d, i) => {
          // const { price, unit, number } = getUnitPrice(d.salePrice, d.vipDuration)
          return (
            <StyledVipGoodsItem
              className={classNames({ active: currentActiveVipGoods?.id === d.id })}
              onClick={() => setCurrentActiveVipGoods(d)}
              key={`vip-goods-item-${d.id}`}
            >
              <div className='animate-bor' />
              <div className='vip-goods-top'>
                <div className='vip-goods-name'>{d.productName}</div>
                <div className='vip-goods-size' style={{ color: COLOR_MAPPING[i] }}>
                  <span className='main'>{d.buyCloudGameTime}</span>
                  小时
                </div>
              </div>
              <div className='vip-goods-bottom'>
                <div className='sale-price'>¥{d.price}</div>
                <div className='source-price'>
                  原价：<span className='main'>¥{d.costPrice}</span>
                </div>
              </div>
              {/*<FadeinImage*/}
              {/*  width={31}*/}
              {/*  height={28}*/}
              {/*  className='active-tag'*/}
              {/*  type='notDefault'*/}
              {/*  src={vipGoodsIconArrowsImg}*/}
              {/*/>*/}
              <div className='normal-tag' />
            </StyledVipGoodsItem>
          )
        })}
      </StyledVipCenterContent>
      <StyledBuy>
        <Button type='primary' className='btn' onClick={()=>setPayModalVisible(true)}>充值时长</Button>
      </StyledBuy>

      <PayModal
        visible={payModalVisible}
        goodsInfo={{
          goodsName: currentActiveVipGoods?.productName,
          desc: currentActiveVipGoods?.buyCloudGameTime + '小时',
          goodsId: currentActiveVipGoods?.id,
          price: currentActiveVipGoods?.price
        }}
        onSuccess={onSuccess}
        onClose={()=>setPayModalVisible(false)}
        autoSuccessModal={true}
      />

    </StyledDurationRechargeContainer>
  )
}

export default observer(CloudGameRecharge)
