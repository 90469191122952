import React, { FC, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react'
import { FadeinImage } from 'src/components/base-image'
import { useLocation, useNavigate } from 'react-router-dom'
import { Badge, message, Popover } from 'antd'
import {
  checkLocal,
  checkOversea,
  getGamePlayStoreByGameMode,
  getQueueStoreByGameMode,
  openServiceView,
  handleShowLogin,
} from 'src/tools/common'
import { requestExitApp } from 'src/tools/speed'
import { handleMaxiClient, handleMiniClient, openCustomService } from 'src/CEFTransfer/cefController'
import { appStore, CloudQueueStore, ConfigStore, SpeedStore, TrialQueueStore, VipStore } from 'src/store'
import { ClientLocalStorageStore, LoginStore, RunningStore, StartingStore } from 'src/store/modifier'
import { historyStack } from 'src/components/custom-router'
import { DownloadStore } from 'src/store/resource'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { emitter } from 'src/tools/emitter'
import { handleStopGame } from 'src/hooks/use-handle-game-start'
import { useIntl } from 'react-intl'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import styled, { createGlobalStyle } from 'styled-components'
import classNames from 'classnames'
import BackArrowsLeftIcon from 'src/assets/icons/layout/back-arrows-left.svg'
import BackArrowsRightIcon from 'src/assets/icons/layout/back-arrows-right.svg'
import LogoutIcon from 'src/assets/icons/layout/logout.svg'
import LogoutHoverIcon from 'src/assets/icons/layout/logout-hover.svg'
import FullContentModalCloseIcon from 'src/assets/icons/common/full-content-modal-close.svg'
import MinimizeIcon from 'src/assets/icons/layout/minimize.svg'
import MaximizeIcon from 'src/assets/icons/layout/maximize.svg'
import RestoreIcon from 'src/assets/icons/layout/restore.svg'
import CloseIcon from 'src/assets/icons/layout/closeMenu.svg'
import CEFTransfer from 'src/CEFTransfer'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import useQueue from 'src/hooks/use-queue'
import TrialGamePlayStore from 'src/store/trial-game-play-store'
import ContactIcon from 'src/assets/icons/common/contact.svg'
import HeaderUserDefault from 'src/assets/icons/common/header-user-default.svg'
import VipIcon from 'src/assets/icons/layout/vipicon.svg'
import StarIcon1 from 'src/assets/icons/layout/star1.svg'
import StarIcon2 from 'src/assets/icons/layout/star2.svg'

const LayoutHeader: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userInfo = LoginStore.userinfo
  const baseUserInfo = LoginStore.userBaseInfo
  const currentDownloadTask = DownloadStore.currentDownloadTask()
  const { cancenQueue } = useQueue()
  const { formatMessage } = useIntl()
  const isOversea = checkOversea()

  const runningSpeedInfo = useMemo(() => {
    return SpeedStore.hasSpeeding()
  }, [SpeedStore.hasSpeeding()])

  const runningBuffInfo = useMemo(() => {
    if (StartingStore.titleInfo || RunningStore.titleInfo) {
      return {
        name: StartingStore.titleInfo?.zhCnName || RunningStore.titleInfo?.zhCnName,
        gameId: StartingStore.gameSimpleInfo?.id || RunningStore.gameSimpleInfo?.id,
        titleId: StartingStore.titleInfo?.id || RunningStore.titleInfo?.id,
      }
    }
    return null
  }, [StartingStore.titleInfo, StartingStore.gameSimpleInfo, RunningStore.titleInfo, RunningStore.gameSimpleInfo])

  const handleToSpeedDetail = () => {
    if (!runningSpeedInfo) {
      return
    }
    const type = runningSpeedInfo.gamePlatformId ? 'platform' : 'game'
    const mainId = runningSpeedInfo.gamePlatformId || runningSpeedInfo.gameLibraryBranchId
    if (type === 'platform') {
      navigate(`/game-detail?type=platform&speedGameNo=${runningSpeedInfo.speedGameNo}&platformId=${mainId}`, {
        replace: true,
      })
    } else {
      navigate(`/game-detail?type=game&entry=speed&speedGameNo=${runningSpeedInfo.speedGameNo}&branchId=${mainId}`, {
        replace: true,
      })
    }
    emitter.emit('inDetailToDetail')
  }

  const handleToBuffDetail = () => {
    if (!runningBuffInfo || !runningBuffInfo.titleId || !runningBuffInfo.gameId) {
      return
    }
    navigate(`/game-detail?type=game&entry=modifier&trainerGameId=${runningBuffInfo.gameId}`, {
      replace: true,
    })
    emitter.emit('inDetailToDetail')
  }

  const handleToGameDetail = (gameMode: TGameMode) => {
    const GamePlayStore = getGamePlayStoreByGameMode(gameMode)
    const gameLibraryMasterId =
      GamePlayStore.currentTheRunningGameInfo?.gameLibraryMasterId ||
      GamePlayStore.currentTheStartingGameInfo?.gameLibraryMasterId
    console.log('handleToCloudGameDetail', gameLibraryMasterId, gameMode)
    if (!gameLibraryMasterId) {
      return
    }
    navigate(
      `/game-detail?type=game&entry=${
        checkLocal(gameMode) ? 'trial' : 'branch'
      }&gameLibraryMasterId=${gameLibraryMasterId}`,
      {
        replace: true,
      },
    )
    emitter.emit('inDetailToDetail')
  }

  const handleStopSpeed = () => {
    imperativeConfirmModal({
      title: '停止加速提醒',
      content: '停止加速前请确认您已结束正在加速的游戏，直接停止可能造成您正在加速的游戏掉线！',
      cancelText: '取消',
      okText: '确认停止',
      onOk() {
        const speedInfo = SpeedStore.hasSpeeding()
        SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_STOP' })
        message.success('停止加速成功')
        console.log(speedInfo, 'speedInfo')
        if (
          speedInfo &&
          appStore.feedbackController.counter.speed === 0 &&
          appStore.feedbackController.visible === false
        ) {
          appStore.feedbackController.counter.speed = 1
          appStore.feedbackController.type = 'speed'
          appStore.feedbackController.visible = true
          appStore.feedbackController.context.platform = speedInfo.gamePlatformName || ''
          appStore.feedbackController.context.gameName = speedInfo.gameLibraryName || ''
          appStore.feedbackController.context.speedArea = speedInfo.speedAreaName || ''
          appStore.feedbackController.context.speedNode = speedInfo.speedNodeName || ''
          appStore.feedbackController.context.speedOperate = speedInfo.speedGameName || ''
        }
      },
    })
  }

  const handleStopDownload = async (link: string, name: string) => {
    await CEFTransfer.sendToClient('cancelDownload', { originUrl: link })
    message.warn(`${name}已取消下载`)
  }

  const handleStopBuff = () => {
    RunningStore.normalStopInjectBuff(true)
  }

  const openCDKExchangeModal = () => {
    navigate('/gift-key')
  }

  const logout = async () => {
    const isConfirm = await imperativeConfirmModal({
      title: '切换账号提示',
      content: (
        <>
          <div>尊敬的MyBuff用户，请再次确认您要登出当前账号</div>
          <div>登出后某些依赖客户端的功能将无法生效</div>
        </>
      ),
      okText: '确认登出',
    })
    if (isConfirm.value) {
      // SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_LOGOUT' }).finally(() => {
      LoginStore.logout()
      // })
    }
  }

  // const openServiceView = async () => {
  //   try {
  //     const url = 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=neSQI6Ffwn'
  //     const jsFile = ''
  //     openCustomService(url, jsFile)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  const openCustomerQQGroup = () => {
    CEFTransfer.sendToClient('openUrl', { url: ConfigStore.customerQQGroupUrl })
  }

  const onSettings = () => {
    ClientLocalStorageStore.systemModalType = 'normal'
    ClientLocalStorageStore.systemModalVisible = true
    // showSpeedModal('settings')
  }

  const onClose = async () => {
    // if (ClientLocalStorageStore.getSystemConfig().windowCloseTips) {
    //   ClientLocalStorageStore.systemModalType = 'close'
    //   ClientLocalStorageStore.systemModalVisible = true
    //   return
    // }
    if (ClientLocalStorageStore.getSystemConfig().windowClose === '1') {
      handleMiniClient()
    } else {
      requestExitApp()
    }
  }

  const DropdownMenu = (
    <StyledDropdownMenu className='no-drag'>
      {/*<div className='menu-item pointer' onClick={openCDKExchangeModal}>*/}
      {/*  <CDKIcon className='normal' />*/}
      {/*  <CDKHoverIcon className='hover' />*/}
      {/*  CDK兑换*/}
      {/*</div>*/}
      {/*<div className='menu-item pointer' onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>*/}
      {/*  <HelpIcon className='normal' />*/}
      {/*  <HelpHoverIcon className='hover' />*/}
      {/*  帮助中心*/}
      {/*</div>*/}
      {/*<div className='menu-item pointer' onClick={() => openServiceView()}>*/}
      {/*  <CustomerIcon className='normal' />*/}
      {/*  <CustomerHoverIcon className='hover' />*/}
      {/*  联系客服*/}
      {/*</div>*/}
      {/*<div className='menu-item pointer' onClick={() => openCustomerQQGroup()}>*/}
      {/*  <CustomerQQGroupIcon className='normal' />*/}
      {/*  <CustomerQQGroupHoverIcon className='hover' />*/}
      {/*  官方Q群*/}
      {/*</div>*/}
      {/* <div className='menu-item pointer' onClick={onSettings}>
        <SettingIcon className='normal' />
        <SettingHoverIcon className='hover' />
        系统设置
      </div> */}
      {userInfo && (
        <div className='menu-item pointer' onClick={logout}>
          <LogoutIcon className='normal' />
          <LogoutHoverIcon className='hover' />
          退出登录
        </div>
      )}
    </StyledDropdownMenu>
  )

  const handleQueue = (gameMode: TGameMode) => {
    const QueueStore = getQueueStoreByGameMode(gameMode)
    //点击排队标签
    if (QueueStore.queueStatus === 'in') {
      QueueStore.queueVisible = true
    } else if (QueueStore.queueStatus === 'success') {
      QueueStore.queueSuccessVisible = true
    }
  }

  const trialGameRunningContainer = useMemo(() => {
    //排队中
    if (TrialQueueStore.queueStatus === 'in') {
      return (
        <NotificationItem
          title={TrialQueueStore.currentQueueInfo?.gameStartInfo.gameName}
          colorTitle={formatMessage(
            { id: 'trial.queueing' },
            { frontUser: (TrialQueueStore.currentQueueInfo?.frontUser || 0) + 1 },
          )}
          onClick={() => handleQueue('local')}
          onClose={e => {
            e.stopPropagation()
            cancenQueue('local')
          }}
        />
      )
    }

    //排队成功
    if (TrialQueueStore.queueStatus === 'success') {
      return (
        <NotificationItem
          title={TrialQueueStore.currentQueueInfo?.gameStartInfo.gameName}
          colorTitle={formatMessage({ id: 'trial.queueSuccess' })}
          onClick={() => handleQueue('local')}
          onClose={e => {
            e.stopPropagation()
            cancenQueue('local')
          }}
        />
      )
    }

    //游戏启动中
    if (TrialGamePlayStore.gameRunStatus === 'starting') {
      return (
        <NotificationItem
          title={TrialGamePlayStore.currentTheStartingGameInfo?.gameName}
          colorTitle={formatMessage({ id: 'trial.starting' })}
          onClick={() => handleToGameDetail('local')}
          onClose={e => {
            e.stopPropagation()
            handleStopGame()
          }}
        />
      )
    }

    //游戏运行中
    if (TrialGamePlayStore.gameRunStatus === 'running') {
      return (
        <NotificationItem
          title={TrialGamePlayStore.currentTheRunningGameInfo?.gameName}
          colorTitle={formatMessage({ id: 'trial.using' })}
          onClick={() => handleToGameDetail('local')}
          onClose={e => {
            e.stopPropagation()
            handleStopGame()
          }}
        />
      )
    }

    if (TrialGamePlayStore.gameRunStatus === 'waitClose') {
      return (
        <NotificationItem
          title={TrialGamePlayStore.currentTheRunningGameInfo?.gameName}
          colorTitle={formatMessage({ id: 'trial.saving' })}
          onClick={() => handleToGameDetail('local')}
        />
      )
    }
    return null
  }, [
    TrialQueueStore.queueStatus,
    TrialQueueStore.currentQueueInfo,
    TrialGamePlayStore.gameRunStatus,
    TrialGamePlayStore.currentTheRunningGameInfo,
    TrialGamePlayStore.currentTheStartingGameInfo,
    TrialQueueStore.currentQueueInfo?.frontUser,
  ])

  const cloudGameRunningContainer = useMemo(() => {
    //云游戏排队中
    if (CloudQueueStore.queueStatus === 'in') {
      return (
        <NotificationItem
          title={CloudQueueStore.currentQueueInfo?.gameStartInfo.gameName}
          colorTitle={<>云游戏排队中 第{(CloudQueueStore.currentQueueInfo?.frontUser || 0) + 1}位</>}
          onClick={() => handleQueue('cloud')}
          onClose={e => {
            e.stopPropagation()
            cancenQueue('cloud')
          }}
        />
      )
    }

    //排队成功
    if (CloudQueueStore.queueStatus === 'success') {
      return (
        <NotificationItem
          title={CloudQueueStore.currentQueueInfo?.gameStartInfo.gameName}
          colorTitle='云游戏排队成功'
          onClick={() => handleQueue('cloud')}
          onClose={e => {
            e.stopPropagation()
            cancenQueue('cloud')
          }}
        />
      )
    }

    //游戏启动中
    if (CloudGamePlayStore.gameRunStatus === 'starting') {
      return (
        <NotificationItem
          title={CloudGamePlayStore.currentTheStartingGameInfo?.gameName}
          colorTitle='云游戏启动中'
          onClick={() => handleToGameDetail('cloud')}
          onClose={e => {
            e.stopPropagation()
            CloudGamePlayStore.visibleExitConfirmModal = true
          }}
        />
      )
    }

    //云游戏运行中
    if (CloudGamePlayStore.gameRunStatus === 'running') {
      return (
        <NotificationItem
          title={CloudGamePlayStore.currentTheRunningGameInfo?.gameName}
          colorTitle='云游戏运行中'
          onClick={() => handleToGameDetail('cloud')}
          onClose={e => {
            e.stopPropagation()
            CloudGamePlayStore.visibleExitConfirmModal = true
          }}
        />
      )
    }

    if (CloudGamePlayStore.gameRunStatus === 'waitClose') {
      return (
        <NotificationItem
          title={CloudGamePlayStore.currentTheRunningGameInfo?.gameName}
          colorTitle='存档保存中'
          onClick={() => handleToGameDetail('cloud')}
        />
      )
    }
    return null
  }, [
    CloudQueueStore.queueStatus,
    CloudQueueStore.currentQueueInfo,
    CloudGamePlayStore.gameRunStatus,
    CloudGamePlayStore.currentTheRunningGameInfo,
    CloudGamePlayStore.currentTheStartingGameInfo,
    CloudQueueStore.currentQueueInfo?.frontUser,
  ])

  return (
    <StyledLayoutHeaderView className={classNames('drag')}>
      <StyledHeaderContent>
        <BackArrowsLeftIcon
          onClick={() => historyStack.back()}
          className={classNames('no-drag left', {
            disabled: !historyStack.canBack,
          })}
        />
        <BackArrowsRightIcon
          onClick={() => historyStack.advance()}
          className={classNames('no-drag right', {
            disabled: !historyStack.canAdvance,
          })}
        />
        <StyledNotification>
          {currentDownloadTask && (
            <NotificationItem
              title={
                <>
                  {currentDownloadTask.value.resourceName}-{currentDownloadTask.value.resourceTypeName}下载中
                </>
              }
              colorTitle={<>{currentDownloadTask.value.progress ?? 0}%</>}
              onClick={() => {}}
              onClose={e => {
                e.stopPropagation()
                handleStopDownload(currentDownloadTask.key, currentDownloadTask.value.resourceName)
              }}
            />
          )}
          {runningSpeedInfo && (
            <NotificationItem
              title={runningSpeedInfo.speedGameName}
              colorTitle='加速中'
              onClick={() => handleToSpeedDetail()}
              onClose={e => {
                e.stopPropagation()
                handleStopSpeed()
              }}
            />
          )}
          {runningBuffInfo && (
            <NotificationItem
              title={runningBuffInfo.name}
              colorTitle='Buff加持中'
              onClick={() => handleToBuffDetail()}
              // 启动中不显示关闭按钮
              onClose={
                StartingStore.trainerInfo && !RunningStore.trainerInfo
                  ? undefined
                  : e => {
                      e.stopPropagation()
                      handleStopBuff()
                    }
              }
            />
          )}
          {trialGameRunningContainer}
          {cloudGameRunningContainer}
          <StyledPopover />
        </StyledNotification>
      </StyledHeaderContent>
      {/* 客户端控制 (最小化,关闭,) */}
      <StyledClientController className='no-drag'>
        {/*<LayoutUserInfo />*/}
        {/* <div className='notDrag action pointer' onClick={openServiceView}>
          <ServiceIcon />
        </div> */}
        {/*<Dropdown overlay={DropdownMenu} key='Dropdown' placement='bottomCenter'>*/}
        {/*  <div className='notDrag action pointer more'>*/}
        {/*    <MoreIcon className='icon' />*/}
        {/*  </div>*/}
        {/*</Dropdown>*/}

        {!isOversea && LoginStore.userinfo && VipStore.memberinfo && !VipStore.memberinfo.isMember && (
          <div className='vip' onClick={() => showUserCenter(NAV_KEY_MAPPING.VIP)}>
            <VipIcon className='icon' /> 订阅BUFF会员
            <StarIcon1 className='icon-1' />
            <StarIcon2 className='icon-2' />
          </div>
        )}

        <div className='other'>
          {/* <div
            className='item'
            onClick={() => {
              if (isOversea) {
                appStore.contactVisible = true
              } else {
                openServiceView()
              }
            }}
          >
            <ContactIcon />
          </div> */}
          {/* {userInfo && baseUserInfo?.avatar ? (
            <div className='avatar' onClick={() => showUserCenter(NAV_KEY_MAPPING.SELF_INFO)}></div>
          ) : (
            <div className='avatar default' onClick={handleShowLogin}>
              <Logo />
            </div>
          )} */}

          <div
            className='item'
            onClick={() => {
              const isLogin = userInfo && baseUserInfo?.avatar
              if (!isLogin) {
                handleShowLogin()
              } else {
                showUserCenter(NAV_KEY_MAPPING.SELF_INFO)
              }
            }}
          >
            {userInfo && baseUserInfo?.avatar ? (
              <FadeinImage
                style={{ fontSize: 0, borderRadius: '50%', overflow: 'hidden' }}
                width={20}
                height={20}
                type='notDefault'
                src={baseUserInfo.avatar}
              />
            ) : (
              <HeaderUserDefault />
            )}
          </div>
        </div>

        <div className='notDrag action pointer minimize' onClick={handleMiniClient}>
          <MinimizeIcon className='icon' />
        </div>
        <div className='notDrag action pointer minimize' onClick={handleMaxiClient}>
          {appStore.windowStatus === 0 ? <MaximizeIcon className='icon' /> : <RestoreIcon className='icon' />}
        </div>
        <div className='notDrag action pointer close' onClick={onClose}>
          <CloseIcon className='icon' />
        </div>
      </StyledClientController>
    </StyledLayoutHeaderView>
  )
}

const NotificationItem = ({
  title,
  colorTitle,
  onClick,
  onClose,
}: {
  title: React.ReactNode
  colorTitle: React.ReactNode
  onClick: (e: React.MouseEvent) => void
  onClose?: (e: React.MouseEvent) => void
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const wrapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!wrapRef.current || !contentRef.current) {
      return
    }
    const ob = new ResizeObserver(entries => {
      const boxWidth = Math.ceil(entries[0].contentRect.width)
      const wrapWidth = wrapRef.current?.offsetWidth ?? 0
      const target = entries[0].target
      if (wrapWidth > boxWidth) {
        target.classList.add('overlay')
      } else {
        target.classList.remove('overlay')
      }
    })
    ob.observe(contentRef.current)
    return () => {
      ob.disconnect()
    }
  }, [])

  return (
    <Popover
      overlayClassName='custom-popover'
      placement='bottom'
      content={
        <StyledPopContent>
          <div className='name'>{title}</div>
          <div className='main'>
            <Badge status='processing' />
            {colorTitle}
          </div>
        </StyledPopContent>
      }
      color='rgba(39,39,46,1)'
      mouseEnterDelay={0.5}
    >
      <StyledRunningContainer onClick={onClick}>
        <div className='content' ref={contentRef}>
          <div className='wrap' ref={wrapRef}>
            <Badge status='processing' />
            {title} - <span className='main'>{colorTitle}</span>
          </div>
        </div>
        {onClose && (
          <div className='icon'>
            <FullContentModalCloseIcon className='running-icon' onClick={onClose} />
          </div>
        )}
      </StyledRunningContainer>
    </Popover>
  )
}

const StyledPopover = createGlobalStyle`
    .custom-popover{
      padding-top: 3px;
      .ant-popover-inner{
        border: 1px solid rgba(117,117,117,0.4);
        border-radius: 8px;
        backdrop-filter: blur(60px);
      }
      .ant-popover-inner-content{
        padding: 23px;
        
      }
      .ant-popover-arrow{
        display: none;
      }
    }
`

const StyledPopContent = styled.div`
  color: #fff;
  min-width: 200px;
  .name {
    font-size: 14px;
    line-height: 20px;
  }
  .ant-badge-status-processing {
    background: rgba(13, 250, 184, 1);
  }
  .ant-badge-status-processing::after {
    border-color: rgba(13, 250, 184, 1);
  }
  .main {
    color: rgba(13, 250, 184, 1);
    margin-top: 16px;
    line-height: 16px;
  }
`

const StyledNotification = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-left: 24px;
`

const StyledDropdownMenu = styled.div`
  width: 130px;
  border-radius: 8px;
  padding: 0;
  background: ${props => props.theme.color.bg.$252525};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.6);
  .menu-item {
    display: flex;
    align-items: center;
    color: var(--text-color-FFFFFF);
    font-size: 14px;
    line-height: 39px;
    padding-left: 16px;
    svg {
      margin-right: 10px;
    }
    .hover {
      display: none;
    }
    &:hover {
      color: ${props => props.theme.color.mainColor};
      .normal {
        display: none;
      }
      .hover {
        display: block;
      }
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`

const StyledLayoutHeaderView = styled.header`
  background: #2b2d31;
  height: 50px;
  ${props => props.theme.flexAlignCenter}
  padding-left: 32px;
  padding-right: 12px;
  border-bottom: 1px solid #232428;
  p {
    width: 50px;
  }
`

const StyledRunningContainer = styled.div`
  ${props => props.theme.flexCenter}
  height: 35px;
  //background: ${props => props.theme.color.bg.$FFFFFF06};
  background: rgb(55, 57, 61);
  //padding: 0 12px 0 0;
  font-size: 14px;
  color: var(--text-color-FFFFFF);
  line-height: 16px;
  margin-right: 12px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  -webkit-app-region: no-drag;
  &:last-child {
    margin-right: 0;
  }
  .content {
    overflow: hidden;
    flex: 1;
    margin-right: 12px;
    padding-left: 12px;
    position: relative;
    .wrap {
      white-space: nowrap;
      width: fit-content;
    }
    &.overlay {
      &:after {
        content: '';
        width: 14px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(to left, rgba(55, 57, 61, 1), rgba(55, 57, 61, 0));
      }
    }
  }
  .icon {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 12px;
  }
  .running-icon {
    cursor: pointer;
    font-size: 13px;
    color: ${props => props.theme.color.text.$A7A7A9};
    margin-left: 4px;
    flex-shrink: 0;
  }
  .ant-badge-status-processing {
    background: rgba(13, 250, 184, 1);
  }
  .ant-badge-status-processing::after {
    border-color: rgba(13, 250, 184, 1);
  }
  .main {
    color: rgba(13, 250, 184, 1);
    margin-left: 2px;
  }
  &:hover {
    background: rgb(68, 70, 73);
    .content {
      &:after {
        background: linear-gradient(to left, rgba(68, 70, 73, 1), rgba(68, 70, 73, 0));
      }
    }
  }
  &.download {
    .ant-badge-status-processing {
      background: ${props => props.theme.color.mainColor};
    }
    .ant-badge-status-processing::after {
      border-color: ${props => props.theme.color.mainColor};
    }
    .main {
      color: var(--text-color-FFFFFF);
      margin-left: 12px;
      font-weight: 700;
    }
  }
`

const StyledHeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 25px;
  overflow: hidden;
  svg {
    color: rgba(255, 255, 255, 0.6);
    &.left {
      margin-right: 18px;
    }
    &.disabled {
      color: rgba(255, 255, 255, 0.3);
    }
    &:not(.disabled):hover {
      cursor: pointer;
      color: var(--text-color-FFFFFF);
    }
  }
`

const StyledClientController = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -6px;
  flex-shrink: 0;

  .vip {
    color: #fff;
    cursor: pointer;
    padding: 0 6px;
    height: 25px;
    background: linear-gradient(90deg, #8547c6 0%, #b845c1 52%, #ab5d8a 100%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-right: 23px;
    font-size: 12px;
    transition: all 0.3s;
    position: relative;
    .icon {
      margin-right: 4px;
    }
    .icon-1 {
      position: absolute;
      top: -7px;
      right: -7px;
    }
    .icon-2 {
      position: absolute;
      bottom: -6px;
      left: -3px;
    }
    &:hover {
      filter: brightness(1.1);
    }
  }

  .other {
    display: flex;
    align-items: center;
    border-right: 1px solid #42464d;
    height: 24px;
    margin-right: 16px;
    .item {
      color: #b6bac0;
      margin-right: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: #fff;
      }
    }
  }

  .action {
    font-size: 0px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;

    &:hover {
      color: var(--text-color-FFFFFF);
    }

    .icon {
      font-size: 16px;
    }
  }
`

export default observer(LayoutHeader)
