import React from 'react'
import { notification } from 'antd'
import type { ArgsProps } from 'antd/es/notification'
import { generatorUUID } from 'src/tools/common'
import { FadeinImage } from 'src/components/base-image'
import warnImg from 'src/assets/images/message/warn-icon.png'
import successImg from 'src/assets/images/message/success-icon.png'
import ModalCancelIcon from 'src/assets/icons/notification/cancel.svg'
import styled, { createGlobalStyle } from 'styled-components'

type TMessage = (msg: string) => void

let uniqueKey: string | undefined = undefined

const COMMON_PARAMS: Partial<ArgsProps> = {
  top: 60,
  placement: 'topRight',
  className: 'sg-notify-message',
  style: {
    background: 'rgba(29,28,39,0.8)',
    padding: '0px',
  },
  closeIcon: null,
}

export const message: Record<'success' | 'warn', TMessage> = {
  success: msg => {
    uniqueKey && notification.close(uniqueKey)
    uniqueKey = generatorUUID('xxxx')
    notification.success({
      message: (
        <>
          <ResetSGNotificationStyled />
          {msg}
        </>
      ),
      icon: (
        <FadeinImage
          width={24}
          height={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          src={successImg}
        />
      ),
      key: uniqueKey,
      ...COMMON_PARAMS,
    })
  },
  warn: msg => {
    uniqueKey && notification.close(uniqueKey)
    uniqueKey = generatorUUID('xxxx')
    notification.warn({
      message: (
        <>
          <ResetSGNotificationStyled />
          {msg}
        </>
      ),
      icon: (
        <FadeinImage
          width={24}
          height={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          src={warnImg}
        />
      ),
      key: uniqueKey,
      ...COMMON_PARAMS,
    })
  },
}

const ResetSGNotificationStyled = createGlobalStyle`
  .sg-notify-message {
    &.ant-notification-fade-leave-active {
      position: absolute !important;
      z-index: -10 !important;
    }
    .ant-notification-notice-message {
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      color: #FFFFFF;
      line-height: 44px;
      margin-left: 54px;
      margin-bottom: 0;
    }
    .ant-notification-notice-icon {
      margin-top: 10px;
      margin-left: 22px;
    }
    .true-img {
      width: 100%;
      height: auto;
    }
  }
`
