export const ASSIST_KEYCODE = ['16', '17', '18']

export const KEYCODE: Record<string, { text: string[] }> = {
  '9': {
    text: ['TAB', 'Tab'],
  },
  '13': {
    text: ['ENTER', 'Enter'],
  },
  '16': {
    text: ['SHIFT', 'LShift', 'RShift'],
  },
  '17': {
    text: ['CTRL', 'LControl', 'RControl'],
  },
  '18': {
    text: ['ALT', 'LAlt', 'RAlt'],
  },
  '20': {
    text: ['ESC', 'Escape'],
  },
  '32': {
    text: ['SPACE', 'Space'],
  },
  '33': {
    text: ['PGUP', 'PageUp'],
  },
  '34': {
    text: ['PGDN', 'PageDown'],
  },
  '35': {
    text: ['END', 'End'],
  },
  '36': {
    text: ['HOME', 'Home'],
  },
  '45': {
    text: ['INS', 'Insert'],
  },
  '46': {
    text: ['DEL', 'Delete'],
  },
  '48': {
    text: ['0', 'Key0'],
  },
  '49': {
    text: ['1', 'Key1'],
  },
  '50': {
    text: ['2', 'Key2'],
  },
  '51': {
    text: ['3', 'Key3'],
  },
  '52': {
    text: ['4', 'Key4'],
  },
  '53': {
    text: ['5', 'Key5'],
  },
  '54': {
    text: ['6', 'Key6'],
  },
  '55': {
    text: ['7', 'Key7'],
  },
  '56': {
    text: ['8', 'Key8'],
  },
  '57': {
    text: ['9', 'Key9'],
  },
  '65': {
    text: ['A'],
  },
  '66': {
    text: ['B'],
  },
  '67': {
    text: ['C'],
  },
  '68': {
    text: ['D'],
  },
  '69': {
    text: ['E'],
  },
  '70': {
    text: ['F'],
  },
  '71': {
    text: ['G'],
  },
  '72': {
    text: ['H'],
  },
  '73': {
    text: ['I'],
  },
  '74': {
    text: ['J'],
  },
  '75': {
    text: ['K'],
  },
  '76': {
    text: ['L'],
  },
  '77': {
    text: ['M'],
  },
  '78': {
    text: ['N'],
  },
  '79': {
    text: ['O'],
  },
  '80': {
    text: ['P'],
  },
  '81': {
    text: ['Q'],
  },
  '82': {
    text: ['R'],
  },
  '83': {
    text: ['S'],
  },
  '84': {
    text: ['T'],
  },
  '85': {
    text: ['U'],
  },
  '86': {
    text: ['V'],
  },
  '87': {
    text: ['W'],
  },
  '88': {
    text: ['X'],
  },
  '89': {
    text: ['Y'],
  },
  '90': {
    text: ['Z'],
  },
  '96': {
    text: ['数字键 0', 'Numpad0'],
  },
  '97': {
    text: ['数字键 1', 'Numpad1'],
  },
  '98': {
    text: ['数字键 2', 'Numpad2'],
  },
  '99': {
    text: ['数字键 3', 'Numpad3'],
  },
  '100': {
    text: ['数字键 4', 'Numpad4'],
  },
  '101': {
    text: ['数字键 5', 'Numpad5'],
  },
  '102': {
    text: ['数字键 6', 'Numpad6'],
  },
  '103': {
    text: ['数字键 7', 'Numpad7'],
  },
  '104': {
    text: ['数字键 8', 'Numpad8'],
  },
  '105': {
    text: ['数字键 9', 'Numpad9'],
  },
  '106': {
    text: ['MULTIPLY', 'NumpadMultiply'],
  },
  '107': {
    text: ['ADD', 'NumpadAdd'],
  },
  // 有疑问
  // '108': {
  //   text: ['ENTER']
  // },
  '109': {
    text: ['SUBTRACT', 'NumpadSubtract'],
  },
  // 监听不到
  // '110': {
  //   text: ['DECIMAL', '']
  // },
  '111': {
    text: ['DIVIDE', 'NumpadDivide'],
  },
  '112': {
    text: ['F1'],
  },
  '113': {
    text: ['F2'],
  },
  '114': {
    text: ['F3'],
  },
  '115': {
    text: ['F4'],
  },
  '116': {
    text: ['F5'],
  },
  '117': {
    text: ['F6'],
  },
  '118': {
    text: ['F7'],
  },
  '119': {
    text: ['F8'],
  },
  '120': {
    text: ['F9'],
  },
  '121': {
    text: ['F10'],
  },
  '122': {
    text: ['F11'],
  },
  '123': {
    text: ['F12'],
  },
  '186': {
    text: [';', 'Semicolon']
  },
  '187': {
    text: ['+', 'Equal']
  },
  '188': {
    text: [',', 'Comma']
  },
  '189': {
    text: ['-', 'Minus']
  },
  '190': {
    text: ['.', 'Dot']
  },
  '191': {
    text: ['/', 'Slash']
  },
  '192': {
    text: ['`', 'Grave'],
  },
  '219': {
    text: ['[', 'LeftBracket']
  },
  '220': {
    text: ['\\', 'BackSlash']
  },
  '221': {
    text: [']', 'RightBracket']
  },
  '222': {
    text: ["'", 'Apostrophe']
  }
}