type listener = (data: any) => void

interface Subscriptions {
  [key: string]: listener[]
}

interface PostMessage {
  code: string
  group?: string
  [key: string]: any
}

type WindowController = WindowProxy | null

interface PostMessageControllerOption {
  url: string
}

class PostMessageController {
  socket: WebSocket |null = null
  postOrigin: string = ''
  postWin: WindowProxy | null = null
  subscriptions: Subscriptions = {}
  group = 'cloud'

  constructor() {}

  async init(url:string){
    return new Promise((resolve,reject) => {
      this.socket = new WebSocket(url)

      this.socket.addEventListener('open',  (event)=> {
        console.log('socket open',event)
        // this.socket?.send('hello')
        resolve(true)
      })

      this.socket.addEventListener('message',  (event)=> {
        try {
          const data=JSON.parse(event.data)
          console.log("recvMessageFromClient ", data)
          const code = data.code
          // this.socket.dispatch(response.code, response)
          this.subscriptions[code]?.forEach?.(f => f(data))
        }catch (e){
          console.log('socket onMessage err',e)
        }
      })

      this.socket.addEventListener('close',(event)=>{
        console.log('socket close',event)
      })

      this.socket.addEventListener('error',(event)=>{
        console.log('socket error',event)
        reject()
      })
    })
  }

  post(message: PostMessage) {
    try {
      const finalMessage = Object.assign({ group: this.group }, message)
      console.log('PostMessageToClient',finalMessage)
      this.socket?.send(JSON.stringify(finalMessage))
    } catch (error) {
      console.warn(error)
    }
  }

  subscribe(code: string, listener: listener) {
    this.subscriptions[code] = (this.subscriptions[code] || []).filter(c => c !== listener).concat(listener)
  }
}

const CloudGamePagePostMessage= new PostMessageController()

export default CloudGamePagePostMessage
