
export enum LOCALSTORAGE_KEYS {
  //不支持云存档弹窗
  CLOUD_ARCHIVE_REMIND = 'cloud_archive_remind',
  //国家
  COUNTRY_CODE = 'countryCode',
  //语言
  LANGUAGE_CODE = 'languageCode',
  /** Adan */
  ADAN = 'adan',

  // 用户信息
  AUTH_INFO = "auth_info",
  //管家用户信息
  AUTH_INFO_GJ = "auth_info_gj",
  // 用户基本信息
  USER_BASE_INFO = 'user_base_info',
  // userId
  USER_ID = 'user_id',
  // 会员信息
  MEMBER_INFO = 'member_info',
  // 用户账号
  REMEMBER_ACCOUNT = 'remember_account',
  // 用户密码
  REMEMBER_PASSWORD = 'remember_password',
  // 账号密码信息
  // REMEMBER_INFO = 'remember_info',
  // 客户端本地缓存的修改器进程名
  TRAINER_EXE = 'trainer_exe',
  // 最热门游戏id
  HOTGAMES = 'hotgames',

  //系统设置 加速器设置
  STSTEM_AUTO_OPEN = 'system_auto_open',
  //系统设置 加速器设置
  STSTEM_AUTO_STOP = 'system_auto_stop',
  //系统设置 默认资源下载目录
  STSTEM_RESOURCE_FILE_PATH = 'system_resouce_file_path',
  // 系统设置弹框
  SYSTEM_CONFIG = 'system_config',
  // 免责协议缓存
  HOLD_HARMLESS_PROTOCOL_CACHE = 'hold_harmless_protocol_cache',
  // 是否第一次打开
  NOT_FIRST_OPEN = 'not_first_open',
  // semid
  SEM_ID = 'sem_id',
  // 最近启动的游戏id缓存
  LATELY_LAUNCH_GAME_IDS = 'lately_launch_game_ids',
  // 不再提示提前阅读notes的游戏id
  NOT_TIPS_PREREAD_GAME_IDS = 'not_tips_preread_game_ids',
  
  // 之前记录的已安装修改器游戏id数组
  RECORD_INSTALL_MODIFIER_GAME_IDS = 'record_install_modifier_game_ids',

  // 之前记录的已安装游戏id数组
  RECORD_INSTALL_GAME_IDS = 'record_install_game_ids',

  // 之前记录的已安装平台id数组
  RECORD_INSTALL_PLATFORM_IDS = 'record_install_platform_ids',

  // 记录已经展示过的公告id
  RECORD_TEMP_NOTIFY_IDS = 'RECORD_TEMP_NOTIFY_IDS'
  
}

export enum SESSIONSTORAGE_KEYS {
  /** 记录首次进入客户端的URL */
  INDEX_URL = 'index_url'
}