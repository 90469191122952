import React, { FC } from 'react'
import { useThrottleFn, useDebounceFn } from 'ahooks'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import styled from 'styled-components'
import classNames from 'classnames'

interface TTextButton extends React.HTMLAttributes<HTMLSpanElement> {
  delayType?: 'debounce' | 'throttle'
  delay?: number
  disabled?: boolean
  loading?: boolean
}

const antIcon = <LoadingOutlined spin />

const TextButton: FC<TTextButton> = ({ delayType, delay, children, loading, disabled, ...props }) => {
  const { run: _throttleClick } = useThrottleFn(
    e => {
      !disabled && !loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  const { run: _debounceClick } = useDebounceFn(
    e => {
      !disabled && !loading && props.onClick?.(e)
    },
    { leading: true, trailing: false, wait: delay },
  )

  return (
    <StyledTextButton
      onClick={delayType === 'debounce' ? _debounceClick : delayType === 'throttle' ? _throttleClick : props.onClick}
      {...props}
      className={classNames(props.className, {
        'sg-text-button-normal': true,
        'sg-text-button-loading': loading,
      })}
    >
      {children}
      {loading && <Spin className='loading-icon' indicator={antIcon} />}
    </StyledTextButton>
  )
}

const StyledTextButton = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-family: Poppins-Regular, Poppins;
  color: ${props => props.theme.color.mainColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.color.text.$D8B3FF};
  }
  &.sg-text-button-loading {
    cursor: not-allowed;
    color: ${props => props.theme.color.text.$D8B3FF};
    .loading-icon {
      margin-left: 2px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$D8B3FF};
    }
    .ant-spin-dot {
      font-size: 14px;
    }
  }
`

export default TextButton
