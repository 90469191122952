import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { CancelButton, ConfirmButton } from 'src/components/base-button'
import { CloudQueueStore } from 'src/store'
import { useHandleGameStart } from 'src/hooks/use-handle-game-start'
import { message } from 'antd'
import styled from 'styled-components'
import useQueue from 'src/hooks/use-queue'
import CEFTransfer from 'src/CEFTransfer'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'

let queueTimer: number | null = null

const clearQueueTimer = () => {
  if (queueTimer) {
    clearInterval(queueTimer)
    queueTimer = null
  }
}

const QueueSuccessModal: FC = () => {
  const [queueSuccessTime, setQueueSuccessTime] = useState<number>(10)
  const { handleStartGameByGameStartInfo } = useHandleGameStart()
  const { exitQueue, quitQueue } = useQueue()

  useEffect(() => {
    if (CloudQueueStore.queueStatus === 'success' && CloudQueueStore.currentQueueInfo) {
      // const deadline = QueueStore.currentQueueInfo.deadline || 0
      // const value = formatTimeFixedOffset(moment(deadline).unix() * 1000)
      const value = Date.now() + 10 * 1000
      if (value > Date.now()) {
        clearQueueTimer()
        let offset = value - Date.now()
        const handler=()=>{
          if (value < Date.now()) {
            clearQueueTimer()
            // message.warn('由于您长时间未进入游戏，我们已将您从游戏排队队列中移除，若要继续游戏请重新进行排队!')
            return confirmStartGame()
          }
          offset -= 1000
          setQueueSuccessTime(Math.max((offset / 1000) | 0, 0))
        }
        handler()
        queueTimer = window.setInterval(() => {
          handler()
        }, 1000)
      }
    } else {
      clearQueueTimer()
    }
  }, [CloudQueueStore.queueStatus, CloudQueueStore.currentQueueInfo])

  const confirmStartGame = () => {
    // if (location.pathname !== '/my-game') {
    //   commonJump('/my-game')
    // }
    // GameLibraryStore.currentGameLibraryGame = {
    //   gameLibraryId: QueueStore.currentQueueInfo?.gameLibraryId || 0,
    //   gameLibraryImg: QueueStore.currentQueueInfo?.gameLibraryImg || '',
    //   gameLibraryName: QueueStore.currentQueueInfo?.gameLibraryName || '',
    //   collection: QueueStore.currentQueueInfo?.collection,
    // }
    CloudQueueStore.queueSuccessVisible = false
    Promise.resolve().then(() => {
      const QueueInfo = CloudQueueStore.currentQueueInfo!
      quitQueue('cloud')
      clearQueueTimer()
      handleStartGameByGameStartInfo({
        // accountLoginMode:QueueInfo.gameStartInfo?.accountLoginMode,
        gameStartInfo:QueueInfo.gameStartInfo,
        queueMainId:QueueInfo.queueMainId,
        archive:CloudGamePlayStore.currentTheStartingGameInfo?.archivePayload
      })
    })
  }

  const close= ()=>{
    CloudQueueStore.queueSuccessVisible=false
  }

  const cancelStartGame = () => {
    exitQueue('cloud')
    close()
  }

  const cancelEnterGame=()=>{
    message.warn(`用户拒绝进入云游戏`)
    cancelStartGame()
  }

  useEffect(()=>{
    if(CloudQueueStore.queueSuccessVisible){
      //弹出windows通知
      CEFTransfer.sendToClient('queueCompletioned',{
        appName: `MYBUFF - 云游戏排队通知`,
        text: `您已成功排到云端游戏设备`,
        text2:'快去玩游戏吧！',
        image:CloudQueueStore.currentQueueInfo?.gameStartInfo?.squareImage??'',
        cloudgameID:CloudQueueStore.currentQueueInfo?.queueMainId??'',
        btn1:'取消',
        btn2: '进入云游戏',
        isLocalGame:false
      })
    }
  },[CloudQueueStore.queueSuccessVisible])

  useEffect(()=>{
    //排队成功 windows通知 进入游戏
    CEFTransfer.subscribeClientMsg('startCloudGame',data=>{
      console.log('startCloudGame',data)
      if(data?.cloudgameID!==CloudQueueStore.currentQueueInfo?.queueMainId || CloudQueueStore.queueStatus !== 'success'){
        return
      }
      confirmStartGame()
    })

    //排队成功 windows通知 取消
    CEFTransfer.subscribeClientMsg('cancelCloudGame',data=>{
      console.log('cancelCloudGame',data)
      if (data?.cloudgameID !== CloudQueueStore.currentQueueInfo?.queueMainId || CloudQueueStore.queueStatus !== 'success') {
        return
      }
      cancelEnterGame()
    })
  },[])

  return (
    <StyledNotifyModal width={330} visible={CloudQueueStore.queueSuccessVisible} onCancel={cancelEnterGame}>
      <StyledNotifyContainer>
        <div className='cloudgame-notify-title'>MYBUFF - 云游戏排队通知</div>
        <FadeinImage
          width={268}
          height={124}
          src={CloudQueueStore.currentQueueInfo?.gameStartInfo?.listImage}
        />
        <div className='cloudgame-notify-footer-box'>
          <div className='cloudgame-notify-footer-text'>
            您已成功排到云端游戏设备
          </div>
          <div className='cloudgame-notify-footer-desc'>
            快去玩游戏吧！
          </div>
          <div className='cloudgame-notify-footer-actions'>
            <CancelButton style={{ width: 126, height: 36 }} onClick={cancelEnterGame}>取消</CancelButton>
            <ConfirmButton style={{ width: 126, height: 36 }} onClick={confirmStartGame}>进入游戏({queueSuccessTime}s)</ConfirmButton>
          </div>
        </div>
      </StyledNotifyContainer>
    </StyledNotifyModal>
  )
}

const StyledNotifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  height: 387px;
  box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.14);
  padding-top: 30px;
  .cloudgame-notify-footer-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cloudgame-notify-footer-text {
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #bbbbbb;
      line-height: 24px;
    }
    .cloudgame-notify-footer-desc {
      line-height: 32px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #f4f4f5;
      margin-top: 10px;
      display: flex;
      .cloudgame-notify-footer-number-box {
        display: flex;
        align-items: center;
        margin: 0 8px;
        .cloudgame-notify-footer-number-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 32px;
          background: #0a0a0a;
          border-radius: 4px;
          font-size: 24px;
          font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
          font-weight: bold;
          color: #c38cff;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .cloudgame-notify-footer-actions {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-btn {
        font-size: 14px;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .true-img {
    border-radius: 8px;
  }
  .cloudgame-notify-title {
    //width: 197px;
    height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
    margin-bottom: 24px;
  }
  .actions-box {
    position: absolute;
    bottom: 32px;
    right: 50px;
    display: flex;
    align-items: center;
    .resource-button {
      ${props => props.theme.flexCenter}
      width: 100px;
      height: 36px;
    }
  }
`

const StyledNotifyModal = styled(BaseModal)`
  .ant-modal-body {
    padding: 0;
  }
`

export default observer(QueueSuccessModal)
