import { message } from 'antd'

/**
 * @description java接口的res过滤器
 */
export const javaResultFilter = <T>(
  data?: Http.JavaApiResult<T>,
  { warnToast = true }: { warnToast?: boolean } = {},
): Http.JavaApiResult<T> => {
  if (data?.success && data.code === '1') {
    return data
  } else {
    warnToast && data?.message && message.warn(data.message)
    throw data
  }
}

/**
 * @description 原php接口的res过滤器
 */
export const phpResultFilter = <T>(
  data?: Http.BoxApiResult<T>,
  { warnToast = true }: { warnToast?: boolean } = {},
): Http.BoxApiResult<T> => {
  if (data?.success && data.status_code === 1000) {
    return data
  } else {
    warnToast && data?.message && message.warn(data.message)
    throw data
  }
}

/**
 * @description cms接口的res过滤器
 */
export const CMSResultFilter = <T>(data?: CMS.CmsModDataByModIdResult<T>): CMS.ModDataItem<T>[] => {
  if (data?.success) {
    return data.result.datas
  } else {
    throw new Error(`${data?.message}`)
  }
}

/**
 * @description cms接口的res过滤器
 */
export const CMSResultFilter1 = <T>(
  data?: Http.JavaApiResult<CMS.ModDataItem<T>>,
): Http.JavaApiResult<CMS.ModDataItem<T>> => {
  if (data?.success) {
    return data
  } else {
    throw new Error(`${data?.message}`)
  }
}
