import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, message } from 'antd'
import { BaseInput } from 'src/components/base-input'
import { ConfirmButton, TextButton } from 'src/components/base-button'
import { registerHttp, verificationEmailCodeHttp, loginHttp, accountIsRegistryHttp } from 'src/apis/user-api'
import { EMAIL_REG, PW_REG_MEDIUM_LEVEL } from 'src/constants/reg'
import { ProtocolStore, LoginStore, ClientLocalStorageStore } from 'src/store/modifier'
import { checkOversea, signatureEncode } from 'src/tools/common'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { useIntl } from 'react-intl'
import PasswordHdIcon from 'src/assets/icons/login/password-hd.svg'
import PasswordIcon from 'src/assets/icons/login/password.svg'
import useSendCode from 'src/hooks/use-send-code-modifier'
import SGCheckbox from 'src/components/base-checkbox'
import styled from 'styled-components'
import classNames from 'classnames'

const Registry: FC = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [isShowPW, setIsShowPW] = useState<boolean>(false)
  const [checkProtocol, setCheckProtocol] = useState<boolean>(false)
  const { validateFields, resetFields, setFields } = form
  const { formatMessage} = useIntl()

  const agreement = checkOversea() ? 'zhuceEn' : 'zhuce'

  const {
    text: verificationCodeText,
    isLoading: verificationCodeLoading,
    startCountdown,
  } = useSendCode(formatMessage({id:'email.send'}), 60, formatMessage({id:'email.resend'}))
  const submit = async () => {
    setLoading(true)
    try {
      const { email, pw, emailVerificationCode } = await validateFields()
      await registerHttp({
        code: emailVerificationCode,
        account: email,
        password: pw,
        accountType: 'email',
        semId: ClientLocalStorageStore.getSemId(),
      })
      const { result } = await loginHttp({ account: email, password: pw, accountType: 'email' })
      await LoginStore.loginSuccess(result)
      LoginStore.cancelLoginModal()
      message.success(formatMessage({id:'login.reg.success'}))
      localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_ACCOUNT, email)
      localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD, pw)
    } catch (error) {
      const err = error as Error
      err.message && message.warn({ content: err.message, className: 'login-message' })
    } finally {
      setLoading(false)
    }
  }
  const sendVerificationCode = async () => {
    if (verificationCodeLoading) {
      return
    }
    const { email } = await validateFields(['email'])
    try {
      const { result: isRegistry } = await accountIsRegistryHttp({
        account: email,
        accountType: 'email',
      })
      if (isRegistry) {
        setFields([{ name: 'email', errors: [formatMessage({id:'login.reg.used'})] }])
        return
      }
      const time = new Date().getTime()
      await verificationEmailCodeHttp(
        { email, action: 'REG' },
        {
          headers: {
            timestamp: String(time),
            signature: signatureEncode(email + '.' + time),
          },
        },
        { warnToast: false },
      )
      startCountdown()
      message.success({ content: formatMessage({id:'login.sentSuccess'}), className: 'login-message' })
    } catch (error) {
      const err = error as Error
      err.message && message.warn({ content: err.message, className: 'login-message' })
    }
  }
  return (
    <StyledRegistryContainer form={form}>
      <Form.Item
        name='email'
        validateFirst={true}
        rules={[
          {
            required: true,
            message: formatMessage({id:'login.email.emptyMsg'}),
          },
          {
            pattern: EMAIL_REG,
            message: formatMessage({id:'login.email.errMsg'}),
          },
        ]}
        validateTrigger='onBlur'
      >
        <BaseInput
          onPressEnter={() => {
            const nextDom: any = document.querySelectorAll('.verification-code-input input')?.[0]
            if (nextDom) {
              nextDom.focus()
            }
          }}
          placeholder={formatMessage({id:'login.email.placeholder'})}
        />
      </Form.Item>
      <Form.Item
        name='emailVerificationCode'
        validateFirst={true}
        rules={[
          { required: true, message: formatMessage({id:'change.email.emptyMsg2'}) },
          { required: true, type: 'string', min: 6, message: formatMessage({id:'change.email.errorMsg2'}) },
        ]}
      >
        <BaseInput
          onPressEnter={() => {
            const nextDom: any = document.querySelectorAll('.password-input input')?.[0]
            if (nextDom) {
              nextDom.focus()
            }
          }}
          maxLen={6}
          className='verification-code-input'
          placeholder={formatMessage({id:'change.email.placeholder2'})}
        >
          <TextButton className='send-code-text' onClick={() => sendVerificationCode()}>
            {verificationCodeText}
          </TextButton>
        </BaseInput>
      </Form.Item>
      <Form.Item
        name='pw'
        validateFirst={true}
        rules={[
          {
            required: true,
            message: formatMessage({id:'login.pwd.emptyMsg'}),
          },
          { pattern: PW_REG_MEDIUM_LEVEL, message: formatMessage({id:'login.pwd.errMsg'}) },
        ]}
      >
        <BaseInput
          maxLen={16}
          type={isShowPW ? 'text' : 'password'}
          className='password-input'
          onPressEnter={() => submit()}
          placeholder={formatMessage({id:'login.pwd.placeholder'})}
        >
          {isShowPW ? (
            <PasswordHdIcon className='password-icon' onClick={() => setIsShowPW(false)} />
          ) : (
            <PasswordIcon className='password-icon' onClick={() => setIsShowPW(true)} />
          )}
        </BaseInput>
      </Form.Item>
      <div className='registry-help-row'>
        <Form.Item
          className='registry-help-row'
          name='check'
          valuePropName='checked'
          validateFirst={true}
          rules={[
            {
              validator(rules, value, callback) {
                if (!value) {
                  return callback(formatMessage({id:'login.reg.agreementRead'},{
                    name:ProtocolStore.protocolConfig[agreement]?.title
                  }))
                }
                callback()
              },
            },
          ]}
        >
          <SGCheckbox checked={checkProtocol} onChange={e => setCheckProtocol(e.target.checked)}>
            {formatMessage({id:'login.reg.agreement'},{
              name:ProtocolStore.protocolConfig[agreement]?.title,
              span:str=><TextButton
                onClick={e => {
                  e.preventDefault()
                  ProtocolStore.openProtocolModal(agreement)
                }}
              >
                {str}
              </TextButton>
            })}
          </SGCheckbox>
        </Form.Item>
      </div>
      <ConfirmButton type='primary' onClick={() => submit()} loading={loading}>
        {formatMessage({id:'login.reg.create'})}
      </ConfirmButton>
    </StyledRegistryContainer>
  )
}

const StyledRegistryContainer = styled(Form)`
  .password-input {
    .password-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      font-size: 20px;
      color: ${props => props.theme.color.text.$FFFFFF86};
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  .verification-code-input {
    .send-code-text {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
    .sg-base-input{
      width: 50%;
    }
  }

  .ant-btn {
    width: 340px;
    height: 52px;
    font-size: 16px;
    font-weight: bold;
  }
  .sg-base-input-warpper {
    width: 340px;
    height: 52px;
    border-radius: 4px;
  }
  .registry-help-row {
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.color.text.$FFFFFF86};
    .primary {
      color: ${props => props.theme.color.mainColor};
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  .ant-form-item {
    min-height: 68px;
  }
`

export default observer(Registry)
