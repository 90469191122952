import React, { FC, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import styled from 'styled-components'
import emptyImg from '../../../assets/images/common/empty.png'

const NotFound: FC<{ img: string;
  type: 'platform' | 'game';
  tool?:string;
  gameName?:string
  platformName?:string
  platformIcon?:string
}> = ({ img, type,tool,gameName, platformName, platformIcon }) => {
  return (
    <StyledEmptyContainer>
      <FadeinImage width={91} height={91.5} type='notDefault' src={emptyImg} />
      <div>MYBUFF does not currently provide a shared account for this game</div>
      <div>
        If you want to use this game sharing account, welcome to feedback to us through the service email
      </div>
      <div>Service email address：<span className='color' onClick={()=>cefOpenUrl('mailto:support@mybuff.com')}>support@mybuff.com</span></div>
    </StyledEmptyContainer>
  )
}

const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.color.text.$C6C6C6};
  div {
    margin-top: 20px;
  }
  .color{
    color: #fff;
    cursor: pointer;
  }
`

const StyledDetailContainer = styled.div`
  ${props => props.theme.flexAlignCenter}
  .info {
    margin-left: 32px;
    .title {
      font-size: 20px;
      font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.76);
      line-height: 23px;
      .main {
        color: var(--text-color-FFFFFF);
      }
    }
    .text {
      font-size: 16px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 19px;
      margin-top: 20px;
    }
  }
`

export default observer(NotFound)
