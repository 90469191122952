import React from 'react'
import { createBrowserHistory } from 'history'
import { LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS } from 'src/constants/storage'
import { localStorageTool, sessionStorageTool } from 'src/tools/storage'
import {
  appStore,
  VipStore,
  RepairStore,
  ConfigStore,
  Activity230817Store,
  TrialQueueStore,
  CloudQueueStore,
} from 'src/store'
import { ProtocolStore } from 'src/store/modifier'
import { LoginStore } from 'src/store/modifier'
import { initBaiduOcpcConfig } from 'src/tools/report'
import { message } from 'antd'
import { getAuth, getGJAuth } from 'src/tools/auth'
import { cmsModDataByModId, cmsMultipleModDataByModId, getAdminChannelConfig } from 'src/apis/cms-api'
// import
import { getCurrentServerTime } from 'src/apis/common-api'
import { ToolsTypes } from 'src/constants/common'
import { fetchUserRemainingSpeedBalance } from 'src/apis/user-api'
import { cefOpenUrl, openCustomService } from 'src/CEFTransfer/cefController'
import { historyStack } from 'src/components/custom-router'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { sha256 } from 'js-sha256'
import { reportCloudArchivesDownloadNum } from 'src/apis/game-api'
import intlLocale, { $t } from 'src/locales'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
import moment from 'moment'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import TrialGamePlayStore from 'src/store/trial-game-play-store'
import CEFTransfer from 'src/CEFTransfer'
import cloudSave from 'src/CEFTransfer/CloudSave'
import SGCheckbox from 'src/components/base-checkbox'
import styled from 'styled-components'

const Query = new URLSearchParams(window.location.search)

/**
 * 保存广告分析
 */
export const httpAdAnalyse = () => {
  const Query = new URLSearchParams(window.location.search)
  Query.delete('token')
  if (Query.toString()) {
    localStorageTool.set(LOCALSTORAGE_KEYS.ADAN, Query.toString())
  }
}

export const documentFullStatus = () => {
  return document.fullscreenElement ? 'fulled' : 'normal'
}

export const changeWindowStatus = async (cb?: (status: number) => void) => {
  const fullscreenEnabled = document.fullscreenEnabled
  if (fullscreenEnabled) {
    let status = documentFullStatus()
    if (status === 'normal') {
      await document.documentElement.requestFullscreen()
      status = 'fulled'
    } else {
      await document.exitFullscreen()
      status = 'normal'
    }
    cb?.(status === 'normal' ? 0 : 1)
  } else {
    console.warn('当前浏览器不支持全屏切换')
  }
}

// 传入想要的uuid格式， 比如 xxxxyyyyx-xxxx-4xxx-yyxx-xxxxxxxxxxxx
export const generatorUUID = (formatString: string) => {
  return formatString.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 获取当前用户的渠道
 */
export const getFirstPageChannel = () => {
  let indexViewUrl = sessionStorageTool.get(SESSIONSTORAGE_KEYS.INDEX_URL)
  if (!indexViewUrl) {
    sessionStorageTool.set(SESSIONSTORAGE_KEYS.INDEX_URL, location.href)
    indexViewUrl = location.href
  }
  /**无效的域名渠道*/
  const ignoreHostChannel = ['www', 'localhost']
  const url = new URL(indexViewUrl)
  /*通过域名获取渠道*/
  const HostChannel = url.hostname.split('.')[0] || ''
  const finalHostChannel = HostChannel.substring(HostChannel.indexOf('-')).replace('-', '')
  /*通过 URL 查询字段获取渠道*/
  const finalChannel =
    url.searchParams.get('channel') ||
    (ignoreHostChannel.includes(HostChannel) ? undefined : finalHostChannel) ||
    'hgspeed'
  return finalChannel
}

export const getFirstPageMAC = () => {
  let indexViewUrl = sessionStorageTool.get(SESSIONSTORAGE_KEYS.INDEX_URL)
  if (!indexViewUrl) {
    sessionStorageTool.set(SESSIONSTORAGE_KEYS.INDEX_URL, location.href)
    indexViewUrl = location.href
  }
  return new URLSearchParams(new URL(indexViewUrl).search).get('mac') ?? ''
}

/**
 * @description 打开链接
 */
export const openUrl = async ({
  url,
  target = 'push',
  ClickEvent,
}: {
  url: string
  target?: 'push' | 'out'
  ClickEvent?: () => void
}) => {
  if (!url) {
    return
  }
  if (target === 'push') {
    historyStack.push(url)
    ClickEvent?.()
  } else {
    try {
      await cefOpenUrl(url)
      ClickEvent?.()
    } catch (error) {
      window?.open(url)
      ClickEvent?.()
    }
  }
}

export const analysisSupportTypeByGameLibraryMaster = (gameLibraryMaster: Game.GameLibraryMaster) => {
  let supportModifier = false
  let supportCloudGame = false
  let supportTrial = false
  let supportResource = false
  let supportSpeed = false
  for (let gameLibraryBranche of gameLibraryMaster.gameLibraryBranches) {
    if (!supportResource && gameLibraryBranche.resources.length !== 0) {
      supportResource = true
    }
    for (let relate of gameLibraryBranche.relates) {
      if (supportModifier && supportSpeed && supportCloudGame && supportTrial) {
        break
      }
      if (!supportModifier && relate.targetRelateType === ToolsTypes.TRAINER_GAME) {
        supportModifier = true
      }
      if (!supportCloudGame && relate.targetRelateType === ToolsTypes.CLOUD_GAME) {
        supportCloudGame = true
      }
      if (!supportTrial && relate.targetRelateType === ToolsTypes.TRIAL_GAME) {
        supportTrial = true
      }
      if (!supportSpeed && relate.targetRelateType === ToolsTypes.SPEED_GAME) {
        supportSpeed = true
      }
    }
    if (supportResource && supportModifier && supportSpeed && supportCloudGame && supportTrial) {
      break
    }
  }
  return {
    supportResource,
    supportModifier,
    supportSpeed,
    supportCloudGame,
    supportTrial,
  }
}

export const analysisSupportTypeByGamePlatform = (gamePlatform: Game.GamePlatform) => {
  let supportWelfare = false
  let supportSpeed = false
  for (let relate of gamePlatform.relates) {
    if (relate.targetRelateType === ToolsTypes.PLATFORM_WELFARE) {
      supportWelfare = true
    }
    if (!supportSpeed && relate.targetRelateType === ToolsTypes.SPEED_GAME) {
      supportSpeed = true
    }
  }
  return {
    supportWelfare,
    supportSpeed,
  }
}

export const promiseCommon: {
  resolve: Function
  reject: Function
} = {
  resolve: () => {},
  reject: () => {},
}

/**
 * @description 显示登录弹窗
 */
export const handleShowLogin = () => {
  LoginStore.openLoginModal()
}

/**
 * @description 关闭登录弹窗
 */
export const handleHideLogin = () => {
  LoginStore.cancelLoginModal()
}

/**
 *
 * @param 提示用户开通会员
 */
export const notifyOpenVipByModifier = () => {
  return imperativeConfirmModal({
    title: '停止BUFF加持提示',
    closable: true,
    content: (
      <div>
        <div>游戏修改为会员特权功能，开通 MYBUFF 会员，全部游戏全天不限次数随意使用</div>
      </div>
    ),
    onOk: () => {
      // historyStack.push('/vip-recharge')
      showUserCenter(NAV_KEY_MAPPING.VIP)
    },
    okText: '开通会员',
    cancelText: '我知道了',
  })
}

/**
 *
 * @param 提示用户开通会员
 */
export const notifyOpenVipBySpeed = (type: 'start' | 'stop') => {
  return imperativeConfirmModal({
    title: type === 'start' ? '加速时长不足提醒' : '停止加速提醒',
    closable: true,
    content: (
      <div>
        <div>你剩余的加速时长不足，请前往充值后使用。</div>
        {/*<div>免费加速时段: 每天 {VipStore.speedFreeConfig.freeSpeedTimeDesc}</div>*/}
        <div>开通 MYBUFF 会员，可享受全天全时段免费加速服务</div>
      </div>
    ),
    onOk: () => {
      showUserCenter(NAV_KEY_MAPPING.DURATION_RECHARGE)
      // historyStack.push('/duration-recharge')
    },
    onCancel: type => {
      if (type === 'btn') {
        showUserCenter(NAV_KEY_MAPPING.VIP)
        // historyStack.push('/vip-recharge')
      }
    },
    okText: '前往充值',
    cancelText: '开通会员',
  })
}

// /**
//  * @description 登录成功
//  */
// export const loginSuccess = async (result: Http.LoginResult) => {
//   saveUserInfo(result)
//   VipStore.handleLoadVipInfo()
//   promiseCommon.resolve?.(result)
// }

// /**
//  * @description 保存用户信息
//  */
// export const saveUserInfo = (result: Http.LoginResult) => {
//   // result.avatar = result.avatar || DefaultAvatar
//   localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO, result, { expires: result.expires })
//   LoginStore.userinfo = result
// }

// /**
//  * @description 清除用户信息
//  */
// export const removeUserInfo = () => {
//   localStorageTool.remove(LOCALSTORAGE_KEYS.AUTH_INFO)
//   LoginStore.userinfo = null
//   LoginStore.userBaseInfo = null
// }

/**
 * @description 同步缓存里面用户信息到VipStore
 */
export const syncUserInfoFromCache = () => {
  const cacheUserInfo = localStorageTool.get(LOCALSTORAGE_KEYS.AUTH_INFO)
  if (cacheUserInfo) {
    LoginStore.getUserBaseInfo()
    LoginStore.userinfo = cacheUserInfo
    VipStore.handleLoadUserRemainingSpeedBalance()
    VipStore.handleLoadUserRemainingCloudGameBalance()
    VipStore.handleLoadVipInfo()
    VipStore.handleLoadUserFreeSubscribeNum()
    Activity230817Store.loadUserHasReceiveDuration()
    Activity230817Store.handleUserLogin(false)
  }
}

/** 前置需要登录 */
export const applyAuthValid = <T extends (...args: any) => any>(handle: T) => {
  return ((...args: any) => {
    if (!getAuth()) {
      return handleShowLogin()
    }
    return handle(...args)
  }) as T
}

/** 前置需要会员 */
export const applyMemberValid = <T extends (...args: any) => any>(handle: T) => {
  return ((...args: any) => {
    if (!getAuth()) {
      return handleShowLogin()
    }
    if (!VipStore.memberinfo?.isMember && !VipStore.remainingTimeInRealTime && !VipStore.isInSpeedFreeTimeRange()) {
      return notifyOpenVipBySpeed('start')
    }
    return handle(...args)
  }) as T
}

export const computedDownloadCount = (count: string | number) => {
  let countNum = Number(count)
  countNum = Number.isNaN(countNum) ? 0 : countNum
  if (countNum > 1000000) {
    return `${Math.floor(countNum / 1000000)}M`
  }
  if (countNum > 1000) {
    return `${Math.floor(countNum / 1000)}K`
  }
  return countNum
}

/** 前置判断是否正在使用工具 */
export const applyRepairValid = <T extends (...args: any) => any>(handle: T) => {
  return ((...args: any) => {
    if (RepairStore.repairIsProceeAll()) {
      return message.warn('当前正在进行网络修复,请等待完成后再进行操作')
    }
    return handle(...args)
  }) as T
}

/** 打开协议弹框 */
export const openProtocolModal = (key: Store.AppStore['currentProtocolType']) => {
  appStore.currentProtocolType = key
}

/** 加载协议配置 */
export const loadProtocolByCMS = async () => {
  const { result } = await cmsModDataByModId({ modId: 'sgt_protocol_config' })
  ProtocolStore.changeProtocolConfig(result.datas)
}

export const loadCarouselGameConfig = async () => {
  const { result } = await cmsModDataByModId({ modId: 'sgt_web_promotion1' })
  const arr = result.datas.map(d => d.properties) as { title: string; desc: string; type: string; listImg: string }[]
  return arr
}
export const loadPromotion1GameConfig = async () => {
  const { result } = await cmsModDataByModId({ modId: 'sgt_web_promotion1_game' })
  const arr = result.datas.map(d => d.properties.img) as string[]
  return arr
}

/** 设置全局room */
export const handleSetGlobalZoom = () => {
  const Query = new URLSearchParams(window.location.search)
  const zoom = Query.get('zoom') || '1.0'
  const Document = document as any
  Document.body.style.zoom = zoom
}

/** 从客户端query里面初始化baseURL */
export const handleInitApiBaseURL = async () => {
  const Query = new URLSearchParams(window.location.search)
  const baseURL = Query.get('webapihost')
  const cmsBaseURL = Query.get('cmsapihost')
  if (baseURL) {
    window.baseURL = baseURL
  }
  if (cmsBaseURL) {
    window.cmsBaseURL = cmsBaseURL
  }
}

/** 判断是否过白中 */
export const loadIsWaitingForWhite = async () => {
  const Query = new URLSearchParams(window.location.search)
  const publishedState = Query.get('publishedState')
  appStore.isInWhiteList = publishedState === '1' && Query.get('ver') !== '1.0.0.243'
  // appStore.isInWhiteList = true
  // appStore.isInWhiteList = true
}

/** 保存CMS配置 */
const setupGlobalCmsConfig: Record<string, Function> = {
  // /** 公共cms配置 */
  vipgoods_recommend_config(d: CMS.ModDataItem<{ id: string }>[]) {
    if (d?.[0]?.properties.id) {
      appStore.vipgoodsRecommendConfig = Number(d[0].properties.id)
    }
  },
  security_software_config(d: CMS.ModDataItem<{ name: string; process: string; icon: string }>[]) {
    ConfigStore.securitySoftwareConfig = d?.map(f => f.properties) || []
  },
  home_banner_config(
    d: CMS.ModDataItem<{
      title: string
      desc: string
      btnText: string
      img: string
      jumpType: string
      jumpValue: string
      waitingToWhitelisted?: '1'
    }>[],
  ) {
    ConfigStore.homeBannerList = (d?.map(f => f.properties) || []).filter(f => {
      if (appStore.isInWhiteList) {
        return f.waitingToWhitelisted !== '1'
      }
      return f.waitingToWhitelisted === '1'
    })
  },
  customer_qqgroup_config(d: CMS.ModDataItem<{ url: string }>[]) {
    ConfigStore.customerQQGroupUrl = d.find(d => !!d.properties.url)?.properties.url || null
  },
  sgt_free_play_config(d: CMS.ModDataItem<{ id: string; sort: string }>[]) {
    ConfigStore.freePlayConfig = d?.map(f => ({ sort: Number(f.properties.sort), id: Number(f.properties.id) })) || null
  },
  sgt_trial_faq(d: CMS.ModDataItem<{ title: string; url: string; goodsId: string }>[]) {
    ConfigStore.trialFaq = d?.map(f => f.properties) || []
  },
  sgt_speed_faq(d: CMS.ModDataItem<{ title: string; url: string; goodsId: string }>[]) {
    ConfigStore.speedFaq = d?.map(f => f.properties) || []
  },
  sgt_cloud_game_faq(d: CMS.ModDataItem<{ title: string; url: string; goodsId: string }>[]) {
    ConfigStore.cloudGameFaq = d?.map(f => f.properties) || []
  },
  sgt_cloud_account_mode(d: CMS.ModDataItem<{ defaultMode: string }>[]) {
    ConfigStore.cloudGameAccountMode = d?.[0]?.properties
  },
  sgt_baidu_ocpc_config(
    d: CMS.ModDataItem<{ channel?: string; token?: string; isDefault?: string; type?: 'baidu' | 'haoduofen' }>[],
  ) {
    const channel = getFirstPageChannel()
    // 1. 先找对应渠道是否有配置
    let curConfig = d.find(f => f.properties.channel === channel)
    // 2. 对应渠道没有配置则查找默认配置
    curConfig = curConfig || d.find(f => f.properties.isDefault === '1')
    // 3. 找到了配置（也可能没有找到）之后检查配置字段是否完整，不完整则不当作没有找到
    curConfig =
      !!curConfig?.properties.token && !!curConfig?.properties.type && ['baidu', 'haoduofen'].includes(curConfig.properties.type)
        ? curConfig
        : undefined
    // 4. 获取当前安装包的downloadId
    let indexViewUrl = sessionStorageTool.get(SESSIONSTORAGE_KEYS.INDEX_URL)
    if (!indexViewUrl) {
      sessionStorageTool.set(SESSIONSTORAGE_KEYS.INDEX_URL, location.href)
      indexViewUrl = location.href
    }
    const packageName = new URLSearchParams(new URL(indexViewUrl).search).get('SetupName') || ''
    const downloadId = packageName.split('@')[1]

    // 5.downloadId存在，进行下一个步骤
    if (!!downloadId && !!curConfig) {
      initBaiduOcpcConfig(downloadId, {token: curConfig.properties.token!, type: curConfig.properties.type!, channel})
    }
   
    
  },
  // home_resource_config(
  //   d: CMS.ModDataItem<
  //     Game.Resource & {
  //       gameLibraryMasterCnName: string
  //       gameLibraryMasterId: string | number
  //       supportGameVersion: string
  //       platformIcon: string
  //     }
  //   >[],
  // ) {
  //   ConfigStore.homeResourceList = d?.map(f => {
  //     return {
  //       ...f.properties,
  //       gameLibraryMasterId: Number(f.properties.gameLibraryMasterId),
  //       gameLibraryBranchId: Number(f.properties.gameLibraryBranchId),
  //     }
  //   })
  // },
  // ...(Query.get('speedclient') === 'v2'
  //   ? {
  //       home_nji_tools_config(
  //         d: CMS.ModDataItem<{
  //           iden: string
  //           name: string
  //           type: ToolsTypes
  //           platformName: string
  //           platformIcon: string
  //           mainType: 'game' | 'platform'
  //           mainId: string
  //         }>[],
  //       ) {
  //         ConfigStore.homeToolsList = d?.map(f => f.properties)
  //       },
  //     }
  //   : {
  //       home_tools_config(
  //         d: CMS.ModDataItem<{
  //           iden: string
  //           name: string
  //           type: ToolsTypes
  //           platformName: string
  //           platformIcon: string
  //           mainType: 'game' | 'platform'
  //           mainId: string
  //         }>[],
  //       ) {
  //         ConfigStore.homeToolsList = d?.map(f => f.properties)
  //       },
  //     }),
}

/** 加载全局cms配置 */
export const loadGlobalCmsConfig = async () => {
  try {
    const modIds = Object.keys(setupGlobalCmsConfig).join(',')
    const { result } = await cmsMultipleModDataByModId({ modIds })
    result?.forEach(config => {
      const K = config.modId as keyof typeof setupGlobalCmsConfig
      const D = config.datas
      K && D && setupGlobalCmsConfig[K]?.(D)
    })
  } catch (error) {
    console.error(error)
  }
}

/** 加载渠道配置 */
export const loadChannelConfig = async () => {
  const { result } = await getAdminChannelConfig({
    channel: getFirstPageChannel(),
    // channel: 'sgtoolsv2',
    product: 'SGTools',
  })
  ConfigStore.channelConfig = result
}

export const loadServerTimeOffset = async () => {
  const { result } = await getCurrentServerTime()
  appStore.serverTimeOffset = result - Date.now()
}

/** 计算文本长度 */
export const getTextWidth = (text: string, style?: Partial<{ fontSize: string; fontWeight: string }>) => {
  const DOM = document.createElement('span')
  if (style) {
    DOM.style.fontSize = style.fontSize || '12px'
    DOM.style.fontWeight = style.fontWeight || '400'
    DOM.style.whiteSpace = 'nowrap'
  }
  DOM.innerText = text
  document.body.appendChild(DOM)
  const width = DOM.offsetWidth
  document.body.removeChild(DOM)
  return width
}

export const filterDiscount = (num1: number, num2: number) => {
  const temp = Math.ceil((1 - num1 / num2) * 1000) / 10
  if (num2 < num1) {
    return `-0%`
  }
  if (temp === 100) {
    return `-99.9%`
  }
  return `-${temp}%`
}

/**
 * 折扣语义化
 * @param {Number} Discount 折扣
 */
export const discountSemantics = (Discount: number, noUnit?: boolean): string => {
  /*if (Discount * 100 < 10) {
    return '特惠'
  } else*/
  if (isNaN(Discount)) {
    return noUnit ? '10' : '10折'
  }

  if (Discount >= 1) {
    return noUnit ? '10' : '10折'
  }
  const Str = (Math.floor(Discount * 100) / 10).toFixed(1)
  return noUnit ? `${Str}` : `${Str}折`
}
/**
 * @description 存放工具类函数
 * 必须为纯函数，非纯函数别放这里
 */
export const fitImgUrl = (url: string, width?: number, height?: number) => {
  if (!url || !width || !height) {
    return url
  }
  return `${url}?x-oss-process=image/resize,m_fill,h_${height},w_${width}`
}

/** aes加密 */
export const cryptoByAES = (msg: string) => {
  const key = CryptoJS.enc.Utf8.parse('fWu45g3KQUq6whta7JnPyCspXAYDrobS')
  const iv = CryptoJS.enc.Utf8.parse('U6yWOVCdNF8Dq5HE')

  const encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return decodeURIComponent(encrypted.toString())
}

/** 避免精度问题的加法 */
export const sum = (a: number, b: number) => {
  let r1
  let r2
  try {
    r1 = a.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = b.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  return (a * m + b * m) / m
}

/** 避免精度问题的减法 */
export const subtract = (a: number, b: number) => {
  let r1
  let r2
  try {
    r1 = a.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = b.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  const n = r1 >= r2 ? r1 : r2
  return Number(((a * m - b * m) / m).toFixed(n))
}

// 部分接口需要签名
export const signatureEncode = (str: string) => {
  //算法: 先反序、再sha256、再反序、再md5
  const timeR = String(str).split('').reverse().join('')
  const timeSha256R = sha256(timeR).split('').reverse().join('')
  return md5(timeSha256R)
}

export const formatHideMobile = (mobile: string) => {
  if (mobile.startsWith('11')) {
    return mobile
  }
  return `${mobile.substr(0, 3)}****${mobile.substr(7, 11)}`
}

/**
 *
 * @param payMethod 符合java或者php风格接口的支付方式
 * @returns 符合java风格接口的支付方式
 */
export function formatJavaPayMethod(payMethod: any): any {
  switch (payMethod) {
    case 'alipay':
      return 'alipay_scan'
    case 'wechat':
      return 'wechat_scan'
    case 'qq_wallet':
      return 'qq_scan'
    case 'balance':
      return 'yue'
    default:
      return payMethod
  }
}

export const sleep = (timeout: number): Promise<'sleep'> => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res('sleep')
    }, timeout)
  })
}

export const formatTimeFixedOffset = (timestamp: number) => {
  return timestamp + (480 - moment().utcOffset()) * 60 * 1000
}

export const downloadArchive = async (d: TFinalArchive, e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
  //下载存档
  e?.stopPropagation()

  const orderNo = d.key.split('#')[1]
  const path = d.key.split('#')[0]
  if (d.diy === 0) {
    //上报存档下载
    reportCloudArchivesDownloadNum({ orderNo: d.orderNo || '' })
  }
  // cloudSaveInstance DownloadCloudArchives
  await cloudSave.noticeClient('DownloadCloudArchives', {
    order_no: orderNo,
    oss_path: path,
    name: d.name,
    archives_create_time: d.time / 1000,
    is_save_down_info: 1,
    save_archives_count: 999,
    oss_md5: d.md5,
  })
}

/** 小于1M, 转换成K, 大于1M，转成M  */
export const exchangeSize = (size: number) => {
  if (size === 0) {
    return `0B`
  } else if (size < 1024 * 1024) {
    // console.log(`${(size / 1024).toFixed(2)}KB`)
    return `${(size / 1024).toFixed(2)}KB`
  } else {
    // console.log(`${(size / 1024 / 1024).toFixed(2)}MB`)
    return `${(size / 1024 / 1024).toFixed(2)}MB`
  }
}

export const HideCheckModal = () => {
  TrialGamePlayStore.gameStartingTimer && window.clearTimeout(TrialGamePlayStore.gameStartingTimer)
  TrialGamePlayStore.trialVipTimeTimer && window.clearTimeout(TrialGamePlayStore.trialVipTimeTimer)
  TrialGamePlayStore.gameRunStatus = 'stop'
  //退出游戏
  CEFTransfer.sendToClient('exitGame', {
    waitCloudSave: 0,
  })
  appStore.CheckModalVisible = false
  appStore.minimizeGameCheckVisible = false
  appStore.startProblemVisible = false
}

export const gameStartingSuccess = (notHideCheckModal?: boolean) => {
  console.log('启动成功')

  TrialGamePlayStore.gameStartingTimer && window.clearTimeout(TrialGamePlayStore.gameStartingTimer)
  if (!notHideCheckModal) {
    appStore.CheckModalVisible = false
  }
  appStore.minimizeGameCheckVisible = false
  const startSuccessNum = (Number(localStorage.getItem(`start-game-success-num`)) || 0) + 1
  localStorage.setItem('start-game-success-num', `${startSuccessNum}`)
}

export const PlatformText: { [key: string]: string } = {
  1: 'Steam',
  2: 'EA',
  4: 'Uplay',
  5: 'Epic',
  6: 'Blizzard',
  9: 'Riot',
}

/** 是否本地上号模式 */
export const checkLocal = (gameMode?: TGameMode) => {
  return gameMode === 'local'
}

/** 根据模式返回队列store  */
export const getQueueStoreByGameMode = (gameMode?: TGameMode) => {
  return checkLocal(gameMode) ? TrialQueueStore : CloudQueueStore
}

/** 根据模式返回游戏状态store  */
export const getGamePlayStoreByGameMode = (gameMode?: TGameMode) => {
  return checkLocal(gameMode) ? TrialGamePlayStore : CloudGamePlayStore
}

/**
 * 获取安装包
 * @param url
 */
export const getSetupName = (url?: string) => {
  const link = url || sessionStorageTool.get(SESSIONSTORAGE_KEYS.INDEX_URL)
  if (!link) {
    return ''
  }
  try {
    const query = new URLSearchParams(new URL(link).search)
    return query.get('SetupName') ?? ''
  } catch (e) {
    return ''
  }
}

/**
 * 保存云游戏sgt mini端 token
 */
export const saveAuthInfo = () => {
  //保存token
  const query = new URLSearchParams(location.search)
  const authorization = query.get('authorization')
  if (authorization) {
    const userinfo = {
      token: authorization,
    } as Modifier.TLoginResult
    LoginStore.userinfo = userinfo
    localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO, userinfo)
  }
}

/**
 * 显示反馈弹窗
 * @param type 反馈类型
 * @param context 反馈上报时的数据
 * @param once 是否只弹一次
 */
export const showFeedback = (type: TFeedbackType, context: IFeedbackContext, once?: boolean) => {
  if ((once && appStore.feedbackController.counter[type] !== 0) || appStore.feedbackController.visible) {
    return
  }
  appStore.feedbackController.counter[type] = 1
  appStore.feedbackController.type = type
  appStore.feedbackController.context = context
  appStore.feedbackController.visible = true
}

/**
 *  初始化i18n
 */
export const initI18n = () => {
  enum defaultLanguage {
    CN = 'zh-CN',
    US = 'en-US',
  }

  const query = new URLSearchParams(location.search)
  const countryCode = query.get('country')
  appStore.countryCode = countryCode || localStorageTool.get(LOCALSTORAGE_KEYS.COUNTRY_CODE) || 'CN'
  // appStore.languageCode = localStorageTool.get(LOCALSTORAGE_KEYS.LANGUAGE_CODE) || defaultLanguage[appStore.countryCode as keyof typeof defaultLanguage] || 'zh-CN'
  appStore.languageCode = defaultLanguage[appStore.countryCode as keyof typeof defaultLanguage] || 'zh-CN'
  localStorageTool.set(LOCALSTORAGE_KEYS.COUNTRY_CODE, appStore.countryCode)
  localStorageTool.set(LOCALSTORAGE_KEYS.LANGUAGE_CODE, appStore.languageCode)
  intlLocale.configIntl(appStore.languageCode)
}

/**
 * 授权换取管家token，试玩接游戏是后端转发调用的管家接口，须先换取管家token
 */
export const loginGJ = async () => {
  ;(!getGJAuth() || !LoginStore.userinfoGJ?.cloudArchiveIdentify) && (await LoginStore.loginGJ())
}

/**
 * 是否海外版
 */
export const checkOversea = () => {
  return appStore.countryCode === 'US'
}

/**
 * 不支持云存档弹窗
 */
export const showCloudArchiveTips = async () => {
  let notRemind = false
  const StyledTips = styled.div`
    .name {
      margin-bottom: 10px;
    }
  `
  const result = await imperativeConfirmModal({
    title: $t({ id: 'cloudArchiveTips.title' }),
    okText: $t({ id: 'cloudArchiveTips.next' }),
    content: (
      <StyledTips>
        <div className='name'>{$t({ id: 'cloudArchiveTips.desc' })}</div>
        <SGCheckbox
          defaultChecked={false}
          onChange={e => {
            notRemind = e.target.checked
          }}
        >
          <span className='checkText'>{$t({ id: 'cloudArchiveTips.notRemind' })}</span>
        </SGCheckbox>
      </StyledTips>
    ),
  })
  return { result, notRemind }
}

/**
 * 不支持云存档提示弹窗检测
 * @param gameStartInfo
 */
export const checkCloudArchiveTips = async (gameStartInfo: GameStartInfo) => {
  //判断是否需要弹出存档提示框
  const remind = localStorageTool.get(LOCALSTORAGE_KEYS.CLOUD_ARCHIVE_REMIND)
  const noRemindGames = remind ? remind.split(',') : []
  if (
    !gameStartInfo.isSupportArchive &&
    gameStartInfo.enableShareTractor !== 1 &&
    !noRemindGames.some((d: string) => Number(d) === gameStartInfo.gameLibraryBranchId)
  ) {
    const tipsRes = await showCloudArchiveTips()
    if (!tipsRes.result.value) {
      return false
    }
    if (tipsRes.notRemind) {
      noRemindGames.push(gameStartInfo.gameLibraryBranchId + '')
      localStorageTool.set(LOCALSTORAGE_KEYS.CLOUD_ARCHIVE_REMIND, noRemindGames.join(','))
    }
  }
  return true
}

/**
 * 打开客服
 */
export const openServiceView = async () => {
  try {
    const url = 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=neSQI6Ffwn'
    const jsFile = ''
    openCustomService(url, jsFile)
  } catch (error) {
    console.error(error)
  }
}
