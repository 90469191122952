
let DEFAULTS: Omit<
  NsWfSocket.ConfigOption<any, any>,
  "url" | "responseInterceptor"
> = {
  maxReconnect: 5, // 单轮最大重连次数
  connectInterval: 1000, // 单轮重连间隔
  reconnectInterval: 1000 * 60 * 2, // 重连间隔
  heartOption: {
    interval: 20000,
    delayInterval: 20000,
    checkInterval: 20000,
    maxTimeOutTime: 3,
    ping: "ping",
    pong: "pong",
  },
  transformData(d: any) {
    if (typeof d !== "string") {
      return JSON.stringify(d);
    }
    return d;
  },
  transformResponse(response: NsWfSocket.SendType) {
    try {
      if(typeof response === 'string'){
        return JSON.parse(response);
      }
      return response;
    } catch (error) {
      throw error
    }
  },
};

export default DEFAULTS;
