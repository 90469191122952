import { observable, action, computed } from 'mobx'
import { PLATFORM_MAPPING, ToolsTypes } from 'src/constants/common'
import { message } from 'antd'
import { ConfigStore } from 'src/store/config-store'
import { ClientLocalStorageStore } from 'src/store/modifier/'
import { getGameLibraryOssAllDownloadHttp } from 'src/apis/game-api'
import CEFTransfer from 'src/CEFTransfer'

class Game {
  // gameList
  @observable
  gameMasterList: Game.GameLibraryMaster[] | null = null

  @observable
  platformList: Game.GamePlatform[] | null = null

  @computed
  get allGameList() {
    return this.gameMasterList?.reduce((a, b) => {
      a.push(...b.gameLibraryBranches)
      return a
    }, [] as Game.GameLibraryBranche[])
  }

  @action
  async getAllData() {
    try {
      const { result } = await getGameLibraryOssAllDownloadHttp()
      const temp = await fetch(result.downloadUrl)
      const data: { gameLibraryMasters: Game.GameLibraryMaster[]; gamePlatforms: Game.GamePlatform[] } =
        await temp.json()
      this.gameMasterList = data.gameLibraryMasters
      this.platformList = data.gamePlatforms
    } catch (error) {}
  }

  // 已安装的 game 列表
  @observable
  installedGameList: (Game.GameLibraryMaster & { gameLibraryBrancheId?: number })[] = []

  // 未安装的 game 列表
  @observable
  notInstalledGameList: (Game.GameLibraryMaster & { gameLibraryBrancheId?: number })[] = []

  // 已安装的 platform 列表
  @observable
  installedPlatformList: (Game.GamePlatform & { launcherPath: string })[] = []

  // 未安装的 platform 列表
  @observable
  notInstalledPlatformList: Game.GamePlatform[] = []

  // 免费体验游戏列表
  @observable
  freePlayGameList: (Game.GameLibraryMaster & { gameLibraryBrancheId?: number })[] = []

  async listenFreePlayGameList() {
    try {
      if (this.gameMasterList && ConfigStore.freePlayConfig && this.installedGameList) {
        const configFreePlayGameList = []
        const randomFreePlayGameList = []
        const finalPlayGameListConfig = ConfigStore.freePlayConfig.filter(
          f => !this.installedGameList.find(v => v.gameLibraryBrancheId === f.id),
        )
        for (let i = 0; i < this.gameMasterList.length; i++) {
          const current = finalPlayGameListConfig.find(f =>
            this.gameMasterList![i].gameLibraryBranches.find(
              v =>
                `${v.gameLibraryBranchId}` === `${f.id}` &&
                !!v.relates.find(f => f.targetRelateType === ToolsTypes.TRIAL_GAME),
            ),
          )
          if (!!current) {
            configFreePlayGameList.push({
              ...this.gameMasterList![i],
              gameLibraryBrancheId: Number(current.id),
              sort: current.sort,
            })
          } else {
            if (randomFreePlayGameList.length < 8 && Math.random() > 0.8) {
              const currentGameLibraryBrancheId = this.gameMasterList![i].gameLibraryBranches.find(
                v => !!v.relates.find(f => f.targetRelateType === ToolsTypes.TRIAL_GAME),
              )?.gameLibraryBranchId
              !!currentGameLibraryBrancheId &&
                !this.installedGameList.find(v => v.gameLibraryBrancheId === currentGameLibraryBrancheId) &&
                randomFreePlayGameList.push({
                  ...this.gameMasterList![i],
                  gameLibraryBrancheId: currentGameLibraryBrancheId,
                  sort: 9999,
                })
            }
          }
        }
        const result =
          configFreePlayGameList.length > 8
            ? configFreePlayGameList
            : [...configFreePlayGameList, ...randomFreePlayGameList].filter((f, i) => i < 8)
        this.freePlayGameList = result.sort((a, b) => a.sort - b.sort)
      }
    } catch (error) {}
  }

  async listenInstalled() {
    try {
      if (this.allGameList) {
        const result: string[] = CEFTransfer.instance
          ? await CEFTransfer.sendToClient('getGameInstallStatus', {
              installParams: this.allGameList.map(d => {
                return {
                  id: d.gameLibraryBranchId,
                  install: d.gameLibraryBranchInstallDiscovery,
                }
              }),
            })
          : []
        console.log('getGameInstallStatus', result)
        if (this.gameMasterList) {
          const installedGameList = []
          const notInstalledGameList = []
          for (let i = 0; i < this.gameMasterList.length; i++) {
            if (result.length === installedGameList.length) {
              notInstalledGameList.push(this.gameMasterList[i])
              continue
            }
            const current = result.find(f =>
              this.gameMasterList![i].gameLibraryBranches.find(v => `${v.gameLibraryBranchId}` === f),
            )
            if (!!current) {
              installedGameList.push({
                ...this.gameMasterList![i],
                gameLibraryBrancheId: Number(current),
              })
            } else {
              notInstalledGameList.push(this.gameMasterList[i])
            }
          }
          this.installedGameList = installedGameList
          this.notInstalledGameList = notInstalledGameList
        }
      }

      if (this.platformList) {
        const currentPlatform = this.platformList
        const result: { installParams: { id: number; path: string }[] } = CEFTransfer.instance
          ? await CEFTransfer.sendToClient('getPlatformInstallStatus', {
              installParams: currentPlatform.map(d => {
                return {
                  id: d.platformId,
                  install: d.platformInstallDiscovery,
                }
              }),
            })
          : { installParams: [] }
        console.log('result', result)
        const installedPlatformList = []
        const notInstalledPlatformList = []
        for (let i = 0; i < currentPlatform.length; i++) {
          if (result.installParams.length === installedPlatformList.length) {
            notInstalledPlatformList.push(currentPlatform[i])
            continue
          }
          const current = result.installParams.find(f => f.id === currentPlatform[i].platformId)
          if (!!current) {
            installedPlatformList.push({
              ...currentPlatform[i],
              launcherPath: current.path,
            })
          } else [notInstalledPlatformList.push(currentPlatform[i])]
        }
        this.installedPlatformList = installedPlatformList
        this.notInstalledPlatformList = notInstalledPlatformList
      }
      this.listenFreePlayGameList()
    } catch (error) {}
    setTimeout(async () => {
      this.listenInstalled()
      /** 按博文和耀忠的协商  从5s -> 1min */
    }, 1000 * 60)
  }
}

export const GameStore = new Game()
