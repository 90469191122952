import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, message, Spin } from 'antd'
import { BaseModal, BaseModalV3 } from 'src/components/base-modal'
import { ClientLocalStorageStore, LoginStore, ProtocolStore } from 'src/store/modifier'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import {
  bindCsAndPayTargetHttp,
  getPayMethodConfigHttp,
  getPreOrderAmtHttp,
  orderCheckHttp,
  orderCreateHttp,
  paymentLaunchHttp,
} from 'src/apis/user-api'
import { checkOversea, formatJavaPayMethod } from 'src/tools/common'
import { reportBaiduOcpcByBuySuccess } from 'src/tools/report'
import { getAuth } from 'src/tools/auth'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import CEFTransfer from 'src/CEFTransfer'
import QRcode from 'qrcode.react'
import classNames from 'classnames'
import WechatIcon from 'src/assets/icons/pay/wechat.svg'
import AlipayIcon from 'src/assets/icons/pay/alipay.svg'
import PayPalIcon from 'src/assets/icons/pay/paypal.svg'
import PayermaxIcon from 'src/assets/icons/pay/payermax.svg'
import copy from 'copy-to-clipboard'
import SuccessIcon from 'src/assets/images/user-center/success.svg'

let pollingTimer: number | null = null
let orderNo = ''

interface IPayModalProps {
  visible: boolean
  goodsInfo?: {
    goodsId?: number
    goodsName?: string
    desc?: string
    price?: number
  }
  onClose: () => void
  onSuccess: () => void
  currencySymbol?: string
  autoSuccessModal?: boolean
}

const PayModal: FC<IPayModalProps> = ({
  visible,
  goodsInfo,
  onSuccess,
  onClose,
  currencySymbol = '￥',
  autoSuccessModal,
}) => {
  const isOversea = checkOversea()
  //是否隐藏PayPal支付。中国&未知IP地区&排除港澳台
  const isGAT =
    LoginStore.userBaseInfo &&
    ['中国-香港', '中国-台湾省', '中国-澳门', '中国-澳门省'].includes(LoginStore.userBaseInfo.country)
  const hidePayPal = !LoginStore.userBaseInfo?.country || ['中国', '未知'].includes(LoginStore.userBaseInfo.country)
  const payTypeList: {
    payType: Modifier.TPaymentMethod
    name: string
    icon: React.ReactNode
    desc?: (data: Record<string, any>) => React.ReactNode
    recommend?: boolean
  }[] = isOversea
    ? [
        ...(hidePayPal && !isGAT
          ? []
          : [
              {
                payType: 'paypal' as Modifier.TPaymentMethod,
                name: 'PayPal',
                icon: <PayPalIcon />,
              },
            ]),
        {
          payType: 'payermax',
          name: 'Support VISA and other payments',
          icon: <PayermaxIcon />,
        },
      ]
    : [
        {
          payType: 'alipay_scan',
          name: '支付宝支付',
          icon: <AlipayIcon />,
          recommend: true,
        },
        {
          payType: 'wechat_scan',
          name: '微信支付',
          icon: <WechatIcon />,
          desc: (data: Record<string, any>) => {
            return (
              <>
                (需额外收取<span>{data?.overPriceRate}%</span>手续费)
              </>
            )
          },
        },
        ...(hidePayPal
          ? []
          : [
              {
                payType: 'paypal' as Modifier.TPaymentMethod,
                name: 'PayPal',
                icon: <PayPalIcon />,
              },
            ]),
      ]
  const defaultPayType = payTypeList[0].payType
  const [paymentMethod, setPaymentMethod] = useState<Modifier.TPaymentMethod>(defaultPayType)
  const [createOrderLoading, setCreateOrderLoading] = useState<boolean>(false)
  const [qrcodestring, setQrcodeString] = useState<string>()
  const [preOrderAmt, setPreOrderAmt] = useState<number | null>(null)
  const [overPriceRate, setOverPriceRate] = useState<number | null>(null)
  const [showWechatPayment, setShowWechatPayment] = useState<boolean>(false)
  const [payConfirmVisible, setPayConfirmVisible] = useState(false)
  const [timeout, setTimeout] = useState(true)
  const [successVisible, setSuccessVisible] = useState(false)
  const paymentMethodRef = useRef<null | Modifier.TPaymentMethod>(paymentMethod)
  paymentMethodRef.current = !visible || !LoginStore.userinfo ? null : paymentMethod

  const isJumpOutPay = ['paypal', 'payermax'].includes(paymentMethod)

  const { formatMessage } = useIntl()

  const cancelPolling = () => {
    pollingTimer && clearTimeout(pollingTimer)
    setQrcodeString('')
  }

  const handleBindCSAndTarget = async (cs: string, target: string) => {
    await bindCsAndPayTargetHttp({ cs, url: target })
  }

  const onPaySuccess = () => {
    cancelPolling()
    setPayConfirmVisible(false)
    reportBaiduOcpcByBuySuccess(LoginStore.userBaseInfo?.userId || 0)
    autoSuccessModal && setSuccessVisible(true)
    onClose?.()
    onSuccess?.()
  }

  const startPolling = (orderNo: string, pastdueTime: number) => {
    pollingTimer = window.setTimeout(async () => {
      if (pastdueTime === 0) {
        cancelPolling()
        setTimeout(true)
        setPayConfirmVisible(false)
        return message.warn(isJumpOutPay ? formatMessage({ id: 'payModal.timeout' }) : '二维码过期，请刷新二维码')
      }
      try {
        const { result: checkResult } = await orderCheckHttp({
          orderNo,
        })
        if (!checkResult.payStatus) {
          pollingTimer && clearTimeout(pollingTimer)
          startPolling(orderNo, pastdueTime - 1)
          return
        }
        onPaySuccess()
      } catch (error) {
        pollingTimer && clearTimeout(pollingTimer)
        startPolling(orderNo, pastdueTime - 1)
      }
    }, 1000)
  }

  const createOrder = async () => {
    if (!goodsInfo?.goodsId) {
      return
    }
    setTimeout(false)
    setCreateOrderLoading(true)
    try {
      const { result: orderInfo } = await orderCreateHttp({
        semId: ClientLocalStorageStore.getSemId(),
        goodsId: goodsInfo?.goodsId,
        num: 1,
        payMode: paymentMethod,
        platform: 'pc',
      })

      const { result: payLaunchInfo } = await paymentLaunchHttp({
        balanceNo: orderInfo.balanceNo,
        payMode: formatJavaPayMethod(paymentMethod),
        returnUrl: isOversea ? 'https://mb.u8play.com' : 'https://pay-success.mybuff.app/',
        accessMode: 'embedded',
      })

      if (!paymentMethodRef.current) {
        console.log('已退出支付')
        return
      }

      if (paymentMethodRef.current !== paymentMethod) {
        console.log('支付方式已变更0')
        return
      }

      let sPayment = false

      if (payLaunchInfo.includes('clientSecret') && payLaunchInfo.includes('publicKey')) {
        let json: { clientSecret: string; publicKey: string } | undefined

        try {
          const temp = JSON.parse(payLaunchInfo)
          if (temp.clientSecret && temp.publicKey) {
            json = temp
          }
        } catch (error) {
          json = undefined
        }
        if (typeof json !== 'undefined') {
          const tempUrl = `https://pay-success.mybuff.app/?cs=${json.clientSecret}&token=${getAuth()}&env=${
            process.env.MODE === 'production' ? 'mybuff' : 'mybuffDev'
          }`
          setQrcodeString(tempUrl)
          setCreateOrderLoading(false)
          const sPaymentURL = await CEFTransfer.sendToClient('generatorSPaymentURL', {
            pk: json.publicKey,
            cs: json.clientSecret,
          })

          if (sPaymentURL.url === 'fail') {
            return
          }
          await handleBindCSAndTarget(json.clientSecret, sPaymentURL.url)
          sPayment = true
        }
      }

      if (!paymentMethodRef.current) {
        console.log('已退出支付')
        return
      }

      if (paymentMethodRef.current !== paymentMethod) {
        console.log('支付方式已变更1')
        return
      }

      //跳转外部链接支付
      if (isJumpOutPay) {
        cefOpenUrl(payLaunchInfo)
        setPayConfirmVisible(true)
        sPayment = true
      }

      !sPayment && setQrcodeString(payLaunchInfo)
      // 单位秒
      const PASTDUE_TIME = 60 * 3
      orderNo = orderInfo.orderNo
      startPolling(orderInfo.orderNo, PASTDUE_TIME)
    } catch (error) {}

    setCreateOrderLoading(false)
  }

  const handleGetPreOrderAmt = async () => {
    if (!goodsInfo?.goodsId) {
      return
    }
    setPreOrderAmt(null)
    const { result } = await getPreOrderAmtHttp({ goodsId: goodsInfo.goodsId, payMode: paymentMethod })
    setPreOrderAmt(result.payAmt)
  }

  const handleGetPayMethodConfig = async () => {
    const { result } = await getPayMethodConfigHttp()
    setShowWechatPayment(result.enableWechatPay === 1)
    setOverPriceRate(result.overPriceRate)
  }

  const confirmPay = async () => {
    const { result: checkResult } = await orderCheckHttp({
      orderNo,
    })
    if (checkResult.payStatus) {
      onPaySuccess()
    } else {
      message.warn(formatMessage({ id: 'payModal.noPay' }))
    }
  }

  const onCopy = (text: string) => {
    copy(text)
    message.success('Copy successfully')
  }

  useEffect(() => {
    handleGetPayMethodConfig()
  }, [])

  useEffect(() => {
    if (!visible) {
      return
    }
    handleGetPreOrderAmt()
  }, [goodsInfo?.goodsId, paymentMethod, visible])

  useEffect(() => {
    if (!goodsInfo?.goodsId || !visible) {
      return
    }
    cancelPolling()
    if (isJumpOutPay) {
      return
    }
    createOrder()
    return () => {
      setCreateOrderLoading(false)
    }
  }, [paymentMethod, goodsInfo?.goodsId, visible])

  useEffect(() => {
    if (!visible || !LoginStore.userinfo) {
      cancelPolling()
    }
  }, [visible, LoginStore.userinfo])

  useEffect(() => {
    if (!visible) {
      setPreOrderAmt(null)
      setPaymentMethod(defaultPayType)
      setTimeout(false)
      setCreateOrderLoading(false)
    }
  }, [visible])

  return (
    <>
      <StyledPayModal visible={visible} title={null} width={733} onCancel={onClose}>
        <StyledPayBox>
          <div className='title'>{formatMessage({ id: 'payModal.product' })}</div>
          <div className='order'>
            {goodsInfo?.goodsName}&nbsp;&nbsp;&nbsp;&nbsp;{goodsInfo?.desc}{' '}
            <span className='price'>
              {currencySymbol}
              {goodsInfo?.price}
            </span>
          </div>
          <div className='pay'>
            <div className='left'>
              <h3>{formatMessage({ id: 'payModal.payType' })}</h3>
              {payTypeList.map(value => {
                if (!showWechatPayment && value.payType === 'wechat_scan') {
                  return null
                }
                return (
                  <div
                    className={classNames('pay-item', {
                      active: paymentMethod === value.payType,
                    })}
                    key={value.payType}
                    onClick={() => setPaymentMethod(value.payType)}
                  >
                    <div className='checkbox'></div>
                    <div className='icon'>{value.icon}</div>
                    <div className='name'>{value.name}</div>
                    <div className='desc'>
                      {value.desc?.({ overPriceRate: overPriceRate && (overPriceRate * 100) | 0 })}
                    </div>
                    {value.recommend && <div className='recommend'>推荐</div>}
                  </div>
                )
              })}
            </div>
            <div className='right'>
              <div className='amt'>
                {formatMessage({ id: 'payModal.total' })}
                <strong>
                  {currencySymbol} {preOrderAmt?.toFixed(2) || '--'}
                </strong>
              </div>
              {isJumpOutPay ? (
                <div className='paypal-box'>
                  <StyledButton type='primary' loading={createOrderLoading} onClick={createOrder}>
                    {formatMessage({ id: 'payModal.payNow' })}
                  </StyledButton>
                </div>
              ) : (
                <div className='qr'>
                  <QRcode size={190} value={qrcodestring || ''} />
                  {(createOrderLoading || timeout) && (
                    <div className='mask'>
                      {timeout ? (
                        <div className='reload' onClick={createOrder}>
                          <ReloadOutlined />
                          &nbsp;{formatMessage({ id: 'payModal.refresh' })}
                        </div>
                      ) : (
                        <span>
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className='agreement' style={{ textAlign: isOversea ? 'left' : 'center' }}>
                {formatMessage(
                  { id: 'payModal.agreement' },
                  {
                    title: ProtocolStore.protocolConfig[isOversea ? 'zhuceEn' : 'sale']?.title,
                    span: str => {
                      return (
                        <span
                          className='primary'
                          onClick={() => ProtocolStore.openProtocolModal(isOversea ? 'zhuceEn' : 'sale')}
                        >
                          {str}
                        </span>
                      )
                    },
                  },
                )}
              </div>
            </div>
          </div>
        </StyledPayBox>
      </StyledPayModal>

      <BaseModalV3
        visible={payConfirmVisible}
        width={460}
        title={formatMessage({ id: 'payModal.tips' })}
        okText={formatMessage({ id: 'payModal.complete' })}
        onOk={confirmPay}
        cancelText={formatMessage({ id: 'payModal.cancel' })}
        onCancel={() => {
          setPayConfirmVisible(false)
          cancelPolling()
        }}
      >
        {formatMessage(
          { id: 'payModal.tipContent' },
          {
            span: str => (
              <span style={{ cursor: 'pointer', color: '#0DFAB8' }} onClick={() => onCopy('support@mybuff.com')}>
                {str}
              </span>
            ),
          },
        )}
      </BaseModalV3>

      <StyledSuccessModal
        visible={successVisible}
        width={350}
        title={null}
        footer={[
          <Button
            type={'primary'}
            onClick={() => {
              setSuccessVisible(false)
            }}
          >
            {formatMessage({ id: 'common.known' })}
          </Button>,
        ]}
        onCancel={() => setSuccessVisible(false)}
      >
        <StyledSuccess>
          <SuccessIcon />
          <h3>{formatMessage({ id: 'payModal.success' })}</h3>
        </StyledSuccess>
      </StyledSuccessModal>
    </>
  )
}

const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  > h3 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 20px;
    margin-top: 24px;
  }
  > p {
    color: #c6c6c6;
    margin-bottom: 0;
  }
`

const StyledSuccessModal = styled(BaseModalV3)`
  .ant-modal-footer {
    justify-content: center;
    .ant-btn {
      width: 233px;
    }
  }
`

const StyledButton = styled(Button)`
  height: 42px;
  background: #7044cd !important;
  border-radius: 4px 4px 4px 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  border: none !important;

  &:hover {
    background: #794dd8 !important;
  }
`

const StyledPayBox = styled.div`
  .title {
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
  }
  .order {
    margin-top: 12px;
    color: #bbbbbb;
    font-size: 14px;
    line-height: 20px;
    .price {
      margin-left: 20px;
      color: #fff;
    }
  }
  .pay {
    margin-top: 24px;
    display: flex;
    height: 315px;
    .left,
    .right {
      background: rgba(255, 255, 255, 0.06);
      border-radius: 8px;
      height: 100%;
      padding: 16px;
    }
    .left {
      flex: 1;
      > h3 {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin-bottom: 16px;
      }
      .pay-item {
        height: 58px;
        display: flex;
        align-items: center;
        color: #fff;
        background: rgba(255, 255, 255, 0.06);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        margin-bottom: 16px;
        cursor: pointer;
        transition: all 0.3s;
        padding-left: 16px;
        position: relative;
        overflow: hidden;
        .recommend {
          position: absolute;
          right: 0;
          top: 0;
          background: #13844b;
          padding: 0 7px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          border-radius: 0 0 0 11px;
        }
        &.active,
        &:hover {
          background: rgba(112, 68, 205, 0.4);
        }
        .checkbox {
          width: 20px;
          height: 20px;
          border-radius: 10px 10px 10px 10px;
          border: 2px solid #c6c6c6;
        }
        .icon {
          width: 55px;
          height: 40px;
          background: #ffffff;
          border-radius: 4px;
          margin-left: 12px;
          margin-right: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .desc {
          margin-left: 8px;
          color: #bbbbbb;
          > span {
            color: #ed262b;
          }
        }
        &.active {
          .checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              background: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .right {
      width: 268px;
      margin-left: 16px;
      color: #fff;
      .amt {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        > strong {
          font-size: 24px;
          font-family: Impact;
          font-weight: 400;
          color: #ffb731;
          line-height: 29px;
        }
      }
      .qr {
        margin: 12px auto;
        width: 220px;
        height: 220px;
        //border-radius: 4px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .mask {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          //backdrop-filter: blur(4px);
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .reload {
          cursor: pointer;
        }
      }
      .paypal-box {
        padding-top: 64px;
        margin-bottom: 16px;
      }
      .agreement {
        text-align: center;
        color: #bbbbbb;
        font-size: 12px;
        line-height: 1.2;
        > span {
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
`

const StyledPayModal = styled(BaseModal)``

export default observer(PayModal)
