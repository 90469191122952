import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, message } from 'antd'
import { BaseInput } from 'src/components/base-input'
import { ConfirmButton } from 'src/components/base-button'
import { loginHttp } from 'src/apis/user-api'
import { EMAIL_REG, PW_REG_MEDIUM_LEVEL } from 'src/constants/reg'
import { LoginStore } from 'src/store/modifier'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { useIntl } from 'react-intl'
import PasswordHdIcon from 'src/assets/icons/login/password-hd.svg'
import PasswordIcon from 'src/assets/icons/login/password.svg'
import SGCheckbox from 'src/components/base-checkbox'
import styled from 'styled-components'
import classNames from 'classnames'

const Login: FC = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [isShowPW, setIsShowPW] = useState<boolean>(false)
  const [rememberCheck, setRememberCheck] = useState<boolean>(true)
  const { validateFields, resetFields, setFieldsValue } = form
  const { formatMessage } = useIntl()
  const submit = async () => {
    setLoading(true)
    try {
      const { email, pw } = await validateFields()
      const { result } = await loginHttp({ account: email, password: pw, accountType: 'email' })
      await LoginStore.loginSuccess(result)
      LoginStore.cancelLoginModal()
      message.success(formatMessage({ id: 'login.success' }))
      console.log(rememberCheck, 'rememberCheck')
      localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_ACCOUNT, email)
      if (rememberCheck) {
        // const rememberInfo = {
        //   un: email,
        //   pw: pw,
        // }
        localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD, pw)
      } else {
        localStorageTool.set(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD, null)
      }
    } catch (error) {
      const err = error as Error
      err.message && message.warn({ content: err.message, className: 'login-message' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      resetFields()
      setIsShowPW(false)
      setRememberCheck(false)
    }
  }, [LoginStore.modalVisible])

  // 之前有记住密码 则初始化， 并且默认把记住密码勾上
  useEffect(() => {
    if (LoginStore.modalVisible) {
      const account = localStorageTool.get(LOCALSTORAGE_KEYS.REMEMBER_ACCOUNT)
      const password = localStorageTool.get(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD)
      if (account || password) {
        const email = account
        const pw = password
        setFieldsValue({
          email,
          pw,
        })
        setRememberCheck(true)
      }
    }
  }, [LoginStore.modalVisible])

  return (
    <StyledLoginContainer form={form}>
      <Form.Item
        name='email'
        validateFirst={true}
        rules={[
          { required: true, message: formatMessage({id:'login.email.emptyMsg'}) },
          {
            pattern: EMAIL_REG,
            message: formatMessage({id:'login.email.errMsg'}),
          },
        ]}
      >
        <BaseInput
          onPressEnter={() => {
            const nextDom: any = document.querySelectorAll('.password-input input')?.[0]
            if (nextDom) {
              nextDom.focus()
            }
          }}
          placeholder={formatMessage({id:'login.email.placeholder'})}
        />
      </Form.Item>
      <Form.Item
        name='pw'
        validateFirst={true}
        rules={[
          { required: true, message: formatMessage({id:'login.pwd.emptyMsg'}) },
          { pattern: PW_REG_MEDIUM_LEVEL, message: formatMessage({id:'login.pwd.errMsg'}) },
        ]}
      >
        <BaseInput
          className='password-input'
          maxLen={16}
          onPressEnter={() => submit()}
          type={isShowPW ? 'text' : 'password'}
          placeholder={formatMessage({id:'login.pwd.placeholder'})}
        >
          {isShowPW ? (
            <PasswordHdIcon className='password-icon' onClick={() => setIsShowPW(false)} />
          ) : (
            <PasswordIcon className='password-icon' onClick={() => setIsShowPW(true)} />
          )}
        </BaseInput>
      </Form.Item>
      <div className='login-help-row'>
        <div>
          <SGCheckbox checked={rememberCheck} onChange={e => setRememberCheck(e.target.checked)}>
            {formatMessage({id:'login.pwd.remember'})}
          </SGCheckbox>
        </div>
        {/* 邮箱注册修改为账号密码注册， 所以暂时移除忘记密码 */}
        <div className='forget-pw' onClick={() => LoginStore.openForgetPWModal()}>
          {formatMessage({id:'login.pwd.forget'})}
        </div>
      </div>
      <ConfirmButton onClick={() => submit()} loading={loading}>
        {formatMessage({id:'login.toLogin'})}
      </ConfirmButton>
    </StyledLoginContainer>
  )
}

const StyledLoginContainer = styled(Form)`
  .password-input {
    .password-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      font-size: 20px;
      color: ${props => props.theme.color.text.$BBBBBB};
      cursor: pointer;
      &:hover {
        color: var(--text-color-FFFFFF);
      }
    }
  }
  .sg-base-input-warpper {
    width: 340px;
    height: 52px;
    border-radius: 4px;
  }
  .login-help-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.color.text.$FFFFFF86};
    .forget-pw {
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  .ant-form-item {
    min-height: 68px;
  }
  .ant-btn {
    width: 340px;
    height: 52px;
    margin-top: 60px;
    font-size: 16px;
    font-weight: bold;
  }
`

export default observer(Login)
