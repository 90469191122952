import React, { FC, useEffect } from 'react'
import { useVipRemainingTime } from 'src/hooks'
import { VipStore } from 'src/store'
import { LoginStore } from 'src/store/modifier'
import { observer } from 'mobx-react'
import styled from 'styled-components'

type TRemainingTime = {
  timeType: 'speed' | 'cloudgame'
}
const RemainingTime: FC<TRemainingTime> = ({ timeType }) => {
  const { remainingTimeStringArr } = useVipRemainingTime(timeType)
  const isLogin = !!LoginStore.userinfo

  useEffect(() => {
    if (isLogin) {
      if (timeType === 'cloudgame') {
        VipStore.handleLoadUserRemainingCloudGameBalance()
      }
      if (timeType === 'speed') {
        VipStore.handleLoadUserRemainingSpeedBalance()
      }
    }
  }, [isLogin, timeType])

  return <StyledRemainingTimeContainer>{remainingTimeStringArr.join(':')}</StyledRemainingTimeContainer>
}

const StyledRemainingTimeContainer = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-family: Impact-Regular, Impact;
  color: #C19CFE;
  font-weight: 400;
`

export default observer(RemainingTime)
