import { observable, action } from 'mobx'
import { getAuth } from 'src/tools/auth'
import {
  fetchUserRemainingSpeedBalance,
  fetchUserGameCloudInfo,
  fetchMemberInfo,
  fetchFreeSubscribeConfig,
  fetchUserFreeSubscribeNum,
} from 'src/apis/user-api'
import { SpeedStore, appStore } from 'src/store'
import {
  cloudGameFreePeriodHttp,
  cloudGameGiveTimeHttp,
  speedFreePeriodHttp,
  trainerFreePeriodHttp
} from 'src/apis/game-api'
import { notifyOpenVipBySpeed } from 'src/tools/common'
import { message } from 'antd'
import dayjs from 'dayjs'
import CEFTransfer from 'src/CEFTransfer'

class Vip {
  private pollingTimer: number | null = null

  private remainingTimeTimer: number | null = null

  private systemTimeOffset: number = 0

  private countDownStartTime: number | null = null

  private cloudGameRemainingTimeTimer: number | null = null

  private cloudGameRemainingTimeLast: number = 0

  @observable
  memberinfo: Http.TMemberResult | null = null

  @observable
  vipinfo: Http.MemberInfo | null = null

  @observable
  remainingTimeInRealTime: number | null = null

  @observable
  cloudGameRemainingTimeInRealTime: number | null = null

  @observable
  userUseSubscribeNum: number | null = null

  @observable
  cloudGameGiveTime: Http.ICloudGameGiveTime | null = null

  @observable
  speedFreeConfig: Http.TSpeedFreePeriod = {
    configs: [],
    freeSpeedTimeDesc: '',
  }

  @observable
  freeSubscribeConfig: Http.TFreeSubscribeConfig | null = null

  @observable
  trainerFreeConfig: Http.TSpeedFreePeriod = {
    configs: [],
    freeSpeedTimeDesc: '',
  }

  @observable
  cloudGameFreeConfig: Http.TCloudGameFreePeriod = {
    configs: [],
    freeSpeedTimeDesc: '',
  }

  @action
  logout = async () => {
    this.vipinfo = null
    this.remainingTimeInRealTime = null
    this.cloudGameRemainingTimeInRealTime = null
    this.memberinfo = null
    this.userUseSubscribeNum = null
    this.systemTimeOffset = 0
    this.handleLoadVipFreeConfig()
  }

  @action
  handleLoadVipFreeConfig = async (speedNo?: string) => {
    const { result } = await speedFreePeriodHttp({ speedNo })
    this.speedFreeConfig = result
  }

  @action
  handleLoadFreeSubscribeConfig = async () => {
    const { result } = await fetchFreeSubscribeConfig()
    this.freeSubscribeConfig = result
  }

  @action
  handleLoadTrainerFreeConfig = async () => {
    const { result } = await trainerFreePeriodHttp()
    this.trainerFreeConfig = result
  }

  @action
  handleLoadCloudGameFreeConfig = async () => {
    const { result } = await cloudGameFreePeriodHttp()
    this.cloudGameFreeConfig = result
  }

  @action
  handleCloudGameGiveFreeTime = async () => {
    try {
      const { result } = await cloudGameGiveTimeHttp()
      this.cloudGameGiveTime = result
    } catch (error) {
      console.error(error)
    }
  }

  @action
  handleLoadUserRemainingCloudGameBalance = async () => {
    try {
      if (getAuth()) {
        const { result } = await fetchUserGameCloudInfo({ realTime: true })
        console.log(result, 'update-user-cloud-time 0')
        this.cloudGameRemainingTimeInRealTime = result.overtime
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  handleLoadUserRemainingSpeedBalance = async () => {
    try {
      if (getAuth()) {
        const { result } = await fetchUserRemainingSpeedBalance()
        console.log(result, 'aaaaaaaaaaaaaaa')
        this.vipinfo = result
        this.remainingTimeInRealTime = this.vipinfo.speedTimeLength - this.vipinfo.lockSpeedTimeLength
        this.systemTimeOffset = new Date(this.vipinfo.systemTime).valueOf() - new Date().valueOf()
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  handleLoadUserFreeSubscribeNum = async () => {
    try {
      if (getAuth()) {
        const { result } = await fetchUserFreeSubscribeNum()
        this.userUseSubscribeNum = result.cycleCostNum
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  handleLoadVipInfo = async () => {
    try {
      if (getAuth()) {
        const { result } = await fetchMemberInfo()
        this.memberinfo = result
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  handleStartSpeedCountdown = async (speedNo: string) => {
    this.pollingTimer && clearTimeout(this.pollingTimer)
    this.countDownStartTime = new Date().valueOf()
    await this.handleLoadVipFreeConfig(speedNo)
    this.handleComputedRemainingTimeInRealTime()
  }

  @action
  handleStartCloudGameCountdown = async () => {
    this.handleStopCloudGameCountdown()
    this.cloudGameRemainingTimeLast = Date.now()
    console.log('this.cloudGameRemainingTimeLast',this.cloudGameRemainingTimeLast)
    this.cloudGameRemainingTimeTimer = window.setInterval(() => {
      if (this.cloudGameRemainingTimeInRealTime && this.cloudGameRemainingTimeInRealTime > 0) {
        const now=Date.now()
        this.cloudGameRemainingTimeInRealTime -= ((now - this.cloudGameRemainingTimeLast) / 1000)
        this.cloudGameRemainingTimeLast = now
      } else {
        this.handleStopCloudGameCountdown()
      }
    }, 1000)
  }

  @action
  handleStopCloudGameCountdown = () => {
    this.cloudGameRemainingTimeTimer && clearTimeout(this.cloudGameRemainingTimeTimer)
    this.cloudGameRemainingTimeLast = 0
  }

  @action
  hasFreeSubscribeNum = () => {
    console.log(this.freeSubscribeConfig, 'this.freeSubscribeConfig')
    console.log(this.userUseSubscribeNum, 'this.userUseSubscribeNum')
    return (
      !!this.freeSubscribeConfig &&
      this.userUseSubscribeNum !== null &&
      this.freeSubscribeConfig.cycleFreeNum > this.userUseSubscribeNum
    )
  }

  @action
  isInSpeedFreeTimeRange = () => {
    let _isInSpeedFreeTimeRange = false
    if (this.speedFreeConfig.configs.length > 0) {
      for (let vipFreeConfig of this.speedFreeConfig.configs.values()) {
        const serverNow = dayjs(Date.now() + this.systemTimeOffset)
        const serverNowTime = serverNow.valueOf()
        const serverNowstring = serverNow.format('YYYY-MM-DD')
        const freeStartTime = dayjs(`${serverNowstring} ${vipFreeConfig.beginTime}`).valueOf()
        const freeEndTime = dayjs(`${serverNowstring} ${vipFreeConfig.endTime}`).valueOf()
        if (serverNowTime > freeStartTime && serverNowTime < freeEndTime) {
          _isInSpeedFreeTimeRange = true
          break
        }
      }
    }
    return _isInSpeedFreeTimeRange
  }

  @action
  isInTrainerFreeTimeRange = () => {
    let _isInTrainerFreeTimeRange = false
    if (this.trainerFreeConfig.configs.length > 0 && appStore.serverTimeOffset !== null) {
      for (let trainerFreeConfig of this.trainerFreeConfig.configs.values()) {
        const serverNow = dayjs(Date.now() + appStore.serverTimeOffset)
        const serverNowTime = serverNow.valueOf()
        const serverNowstring = serverNow.format('YYYY-MM-DD')
        const freeStartTime = dayjs(`${serverNowstring} ${trainerFreeConfig.beginTime}`).valueOf()
        const freeEndTime = dayjs(`${serverNowstring} ${trainerFreeConfig.endTime}`).valueOf()
        if (serverNowTime > freeStartTime && serverNowTime < freeEndTime) {
          _isInTrainerFreeTimeRange = true
          break
        }
      }
    }
    return _isInTrainerFreeTimeRange
  }

  @action
  private handleComputedRemainingTimeInRealTime = async () => {
    this.remainingTimeTimer && clearTimeout(this.remainingTimeTimer)
    const isInSpeedFreeTimeRange = this.isInSpeedFreeTimeRange()
    const hasRemainingTimeInRealTime =
      typeof this.remainingTimeInRealTime === 'number' && this.remainingTimeInRealTime > 0
    const isSpeeding = SpeedStore.speedStatus === 'speeding' && SpeedStore.speedingInfo
    const isCloudGameSpeed = SpeedStore.speedingInfo?.clientType === 'cloud'
    if (!isSpeeding) {
      return
    }
    if (!this.memberinfo?.isMember && !isInSpeedFreeTimeRange && !hasRemainingTimeInRealTime && !isCloudGameSpeed) {
      await Promise.all([this.handleLoadUserRemainingSpeedBalance(), this.handleLoadVipInfo()])
      const confirmHasRemainingTimeInRealTime =
        typeof this.remainingTimeInRealTime === 'number' && this.remainingTimeInRealTime > 0
      if (!confirmHasRemainingTimeInRealTime && !this.memberinfo?.isMember) {
        SpeedStore.stopHeartCheck()
        SpeedStore.stopSpeed({ notify: true, report: false, reason: 'HEART_CHECK_STOPED' })
        notifyOpenVipBySpeed('stop')
        CEFTransfer.sendToClient('showWindowTop')
        // message.warn('可用加速时长已耗尽，请充值后重新进行加速')
        return
      }
    }
    // if (isInVipFreeTime || hasRemainingTimeInRealTime) {
    // let shouldDeductBalance = true
    // if (this.vipFreeConfig.configs.length > 0) {
    //   for (let vipFreeConfig of this.vipFreeConfig.configs.values()) {
    //     const serverNow = dayjs(Date.now() + this.systemTimeOffset)
    //     const serverNowTime = serverNow.valueOf()
    //     const serverNowstring = serverNow.format('YYYY-MM-DD')
    //     const freeStartTime = dayjs(`${serverNowstring} ${vipFreeConfig.beginTime}`).valueOf()
    //     const freeEndTime = dayjs(`${serverNowstring} ${vipFreeConfig.endTime}`).valueOf()
    //     if (serverNowTime > freeStartTime && serverNowTime < freeEndTime) {
    //       shouldDeductBalance = false
    //       break
    //     }
    //   }
    // }
    if (!this.memberinfo?.isMember && !isInSpeedFreeTimeRange && !isCloudGameSpeed) {
      this.remainingTimeInRealTime = this.remainingTimeInRealTime! - 1
    }
    SpeedStore.speedingInfo!.speedTime = SpeedStore.speedingInfo!.speedTime + 1000
    if (typeof SpeedStore.speedingInfo!.nodeDelay !== 'string') {
      const date = dayjs().format('HH:mm:ss')
      if (!SpeedStore.speedingDelayChartsData[date]) {
        SpeedStore.speedingDelayChartsData = {
          ...SpeedStore.speedingDelayChartsData,
          [date]: {
            timestamp: SpeedStore.speedingInfo!.speedTime,
            delay: Number(SpeedStore.speedingInfo!.nodeDelay),
            time: date,
            /** 数据3分钟过期 */
            timer: window.setTimeout(() => {
              if (SpeedStore.speedingDelayChartsData && SpeedStore.speedingDelayChartsData[date]) {
                delete SpeedStore.speedingDelayChartsData[date]
              }
            }, 3 * 60 * 1000),
          },
        }
      }
    }
    this.remainingTimeTimer = window.setTimeout(() => this.handleComputedRemainingTimeInRealTime(), 1000)
    // }
  }
}

export const VipStore = new Vip()
