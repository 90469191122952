import { appStore, VipStore } from 'src/store'
import { gameStartStep as gameStartStepNew } from 'src/pages/game-detail/trial/game-start-step'
import { gameStartingSuccess, HideCheckModal } from 'src/tools/common'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { $t } from 'src/locales'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import CEFTransfer from 'src/CEFTransfer'
import TrialGamePlayStore from 'src/store/trial-game-play-store'
import dayjs from 'dayjs'

const HideGameArchives = () => {
  appStore.GameArchiveVisible = false
}

const toVip=()=>{
  showUserCenter(NAV_KEY_MAPPING.VIP)
}

class TrialController {
  constructor() {}

  static init(){

    /** 游戏异常消息 */
    CEFTransfer.subscribeClientMsg('gameStatusMsg', (data:{msg: GameStatus}) => {
      console.log('=> gameStatusMsg', data)
      const {msg}=data
      //屏蔽部分消息不处理
      if (['platformclosed'].includes(msg)) {
        return
      }
      HideCheckModal()
      CEFTransfer.sendToClient('showWindowTop')
      if(msg.includes('会员到期')){
        imperativeConfirmModal({
          title:$t({id:'trial.vip.expiration'}),
          content:$t({id:'trial.vip.expiration2'}),
          okText:$t({id:'common.subscribe'}),
          onOk(){
            toVip()
          },
          cancelText:$t({id:'common.known'}),
        })
      }else{
        this.showCrashModal(`GameStatus:${JSON.stringify(data)}`)
      }
    })

    /** 游戏启动进度 */
    CEFTransfer.subscribeClientMsg('notifyGameRunSteps', (data: {
      step: number,
      status: 0 | 1,
      msg: string
    }) => {
      console.log('=> notifyGameRunSteps', data)
      const {step, status, msg} = data
      if (appStore.GameCheckStep >= step && step !== 0 || TrialGamePlayStore.gameRunStatus==='stop') {
        return
      }
      appStore.GameCheckStep = step
      gameStartStepNew.setStep(step + 1)
      if (status === 0) {
        //检测失败
        this.showCrashModal(`GameRunSteps:${JSON.stringify(data)}`)
        CEFTransfer.sendToClient('showWindowTop')
        HideCheckModal()
        return
      }
      if (step === 2) {
        /*成功到第二步 清除临时选择配置*/
        sessionStorage.removeItem('WIFINotShow')
        sessionStorage.removeItem('MemoryNotShow')
        sessionStorage.removeItem('UseCurrentNetwork')
      }
      if (step === 4) {
        TrialGamePlayStore.gameRunStatus = 'running'
        //记录平台拉起成功
        TrialGamePlayStore.platformLogin = true
        /*检测成功*/
        gameStartingSuccess()
        HideGameArchives()

        //开始检测会员时长不足30min提示
        console.log('启动会员时长不足30MIN提示定时器')
        ~(function check(){
          //中文版，非会员也可以免费试玩，不用检测会员时长
          if (!VipStore.memberinfo?.isMember) {
            return
          }
          if (dayjs(VipStore.memberinfo?.expireDate).diff() > 30 * 60 * 1000) {
            TrialGamePlayStore.trialVipTimeTimer = window.setTimeout(() => {
              check()
            }, 60 * 1000)
            return
          }
          TrialGamePlayStore.trialVipTimeTimer && clearTimeout(TrialGamePlayStore.trialVipTimeTimer)
          CEFTransfer.sendToClient('showWindowTop')
          imperativeConfirmModal({
            title: $t({id:'trial.vip.expiration3'}),
            content: $t({id:'trial.vip.expiration4'}),
            okText: $t({id:'common.subscribe'}),
            onOk() {
              toVip()
            },
            cancelText: $t({id:'common.known'}),
          })
        })()
      }
    })

    /** 游戏退出 */
    CEFTransfer.subscribeClientMsg('notifyGameExit', () => {
      console.log('用户主动退出游戏')
      /** 通过这里 接收游戏退出的消息，主要是用于在一些用户不通过我们客户端 直接关闭游戏或其他操作时 做出处理， */
      if (TrialGamePlayStore.gameRunStatus === 'running') {
        /** 假如用户退出游戏时 我们的游戏状态为running  这里直接改成stop，  其他的状态不改 是因为这个消息可能会跟其他消息冲突， 这里只处理运行中的状态 */
        TrialGamePlayStore.gameRunStatus = 'stop'
      }
    })

    CEFTransfer.subscribeClientMsg('noneedWaitforCloudPopup', () => {
      if (TrialGamePlayStore.gameRunStatus === 'waitClose') {
        TrialGamePlayStore.gameRunStatus = 'stop'
      }
    })


    /**
     * 开始游戏前的环境检测：内存、cpu、wifi、操作系统
     * @param step 0--检测系统版本（版本对应数字），1--检测是否是wifi连接 （0.不是wifi  1.是wifi） 2--检测cpu占用率（返回百分比） 3--检测内存剩余空间(剩余空间 单位GB)
     * @param status 状态，默认1
     * @param msg
     */
    CEFTransfer.subscribeClientMsg('notifyGameEnvCheckSteps', (data: {
      step: number,
      status: 0 | 1,
      msg: string
    }) => {
      console.log('notifyGameEnvCheckSteps data', data)
      const {step, status, msg} = data
      if (typeof appStore.gameEnvStatus[step] === 'undefined') {
        appStore.gameEnvStatus[step] = msg
      }
    })

  }

  static showCrashModal(msg:string){
    imperativeConfirmModal({
      title:$t({id:'trial.crash'}),
      content:$t({id:'trial.crash2'},{msg}),
      btns:['ok'],
      okText:$t({id:'common.known'})
    })
  }

}


export default TrialController