import { CloudQueueStore, TrialQueueStore } from 'src/store'
import {
  cancelResourceQueue, cancelResourceQueueTrial,
  enterResourceQueue, enterResourceQueueTrial,
  queryResourceQueue,
  queryResourceQueueTrial
} from 'src/apis/cloud-game-api'
import { checkLocal, getFirstPageChannel, getFirstPageMAC, getQueueStoreByGameMode } from 'src/tools/common'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { $t } from 'src/locales'
import React from 'react'
import moment from 'moment'


/** 间隔10s轮询一次排队名次 */
let queueTimer: number | null = null
/** 间隔1s进行一次排队时间的计算 */
let queueDownCountTimer: number | null = null
let frontQueueTime = 0

/**试玩相关定时器 */
let queueTimerTrial: number | null = null
let queueDownCountTimerTrial: number | null = null
let frontQueueTimeTrial = 0

const useQueue = () => {

  const formatMessage = $t

  const formatQueueTime = (startTime: string, currentTime: string, diff?: number, gameMode?:TGameMode) => {
    const isLocal = checkLocal(gameMode)
    const QueueStore = getQueueStoreByGameMode(gameMode)
    if (isLocal) {
      queueDownCountTimerTrial && clearTimeout(queueDownCountTimerTrial)
    } else {
      queueDownCountTimer && clearTimeout(queueDownCountTimer)
    }
    if (QueueStore.currentQueueInfo) {
      let diffTime = diff || moment(currentTime).diff(startTime)
      if(isLocal){
        frontQueueTimeTrial = diffTime
      }else{
        frontQueueTime = diffTime
      }

      const hour = Math.floor(diffTime / 1000 / 60 / 60)
      diffTime -= hour * 1000 * 60 * 60
      const minute = Math.floor(diffTime / 1000 / 60) % 60
      diffTime -= minute * 1000 * 60
      const seconds = Math.floor(diffTime / 1000)
      const newQueueTime = moment({
        hour,
        minute,
        seconds,
      }).format('HH:mm:ss')
      QueueStore.currentQueueInfo.queueLength = newQueueTime
      queueDownCountTimer = window.setTimeout(() => {
        formatQueueTime('', '', (isLocal ? frontQueueTimeTrial : frontQueueTime) + 1000)
      }, 1000)
    }
  }

  const queueSuccess = (endTime: string, currentTime: string,gameMode:TGameMode) => {
    const isLocal = checkLocal(gameMode)
    const QueueStore = getQueueStoreByGameMode(gameMode)
    if(isLocal){
      queueTimerTrial && clearTimeout(queueTimerTrial)
    }else {
      queueTimer && clearTimeout(queueTimer)
    }
    if (QueueStore.currentQueueInfo && QueueStore.queueStatus === 'in') {
      QueueStore.currentQueueInfo.deadline = Date.now() + 60000
      QueueStore.queueStatus = 'success'
      QueueStore.queueVisible = false
      QueueStore.queueSuccessVisible = true
    }
  }

  const checkQueueStatus = async ({ gameId, queueMainId, gameMode }: { gameId: number; queueMainId: string; gameMode:TGameMode }) => {
    const isLocal = checkLocal(gameMode)
    const QueueStore = getQueueStoreByGameMode(gameMode)
    const polling = () => {
      if (isLocal) {
        queueTimerTrial && clearTimeout(queueTimerTrial)
        queueTimerTrial = window.setTimeout(() => {
          checkQueueStatus({gameId, queueMainId, gameMode})
        }, 6000)
      } else {
        queueTimer && clearTimeout(queueTimer)
        queueTimer = window.setTimeout(() => {
          checkQueueStatus({gameId, queueMainId, gameMode})
        }, 6000)
      }
    }
    try {
      if (QueueStore.currentQueueInfo) {
        const {result} = await (checkLocal(QueueStore.currentQueueInfo?.gameStartInfo?.gameMode) ?
          queryResourceQueueTrial : queryResourceQueue)({
          gameLibraryBranchId:gameId,
          queueMainId,
          gameId: CloudQueueStore.currentQueueInfo?.gameStartInfo?.gameId,
        })
        if (result.status === 2) {
          QueueStore.currentQueueInfo.frontUser = 0
          // if (result.terminal === 'pc') {
            queueSuccess(result.stayEndTime, result.currentTime,gameMode)
            QueueStore.subTaskStatus = 'none'
          // }
        } else if (result.status === 3) {
          quitQueue(gameMode)
        } else {
          formatQueueTime(result.startTime, result.currentTime,undefined,gameMode)
          QueueStore.currentQueueInfo.frontUser = result.frontUser
          if (result.subTaskDoneCode === '101' && result.terminal === 'pc') {
            QueueStore.subTaskStatus = 'success'
          }
          polling()
        }
      }
    } catch (error) {
      polling()
    }
  }

  const startCheckQueueStatus = async ({ gameId, queueMainId, gameMode }: { gameId: number; queueMainId: string; gameMode:TGameMode }) => {
    checkQueueStatus({ gameId, queueMainId, gameMode })
  }

  const quitQueue = (gameMode: TGameMode) => {
    if(gameMode==='local'){
      TrialQueueStore.subTaskStatus = 'none'
      queueTimerTrial && clearTimeout(queueTimerTrial)
      queueDownCountTimerTrial && clearTimeout(queueDownCountTimerTrial)
      TrialQueueStore.currentQueueInfo = null
      TrialQueueStore.queueStatus = 'stop'
    }else{
      CloudQueueStore.subTaskStatus = 'none'
      queueTimer && clearTimeout(queueTimer)
      queueDownCountTimer && clearTimeout(queueDownCountTimer)
      CloudQueueStore.currentQueueInfo = null
      CloudQueueStore.queueStatus = 'stop'
    }
  }

  const cancenQueue =async (gameMode:TGameMode) => {
    const QueueStore = getQueueStoreByGameMode(gameMode)
    if (QueueStore.currentQueueInfo) {
      await imperativeConfirmModal({
        title: formatMessage({id:'queue.confirmCancel'}),
        content: (
          <p>{formatMessage({id:'queue.confirmCancel2'})}</p>
        ),
        okText: formatMessage({id:'common.confirm'}),
        cancelText: formatMessage({id:'common.cancel'}),
        onOk(){
          exitQueue(gameMode)
        }
      })
    }
  }

  const exitQueue = async (gameMode: TGameMode) => {
    const isLocal = checkLocal(gameMode)
    const currentQueueInfo = getQueueStoreByGameMode(gameMode).currentQueueInfo
    if (currentQueueInfo) {
      await (isLocal ? cancelResourceQueueTrial : cancelResourceQueue)({
        queueMainId: currentQueueInfo.queueMainId,
      })
      quitQueue(gameMode)
    }
  }

  const enterQueue = async ({
                              // gameStartPayload,
                              gameStartInfo,
                              gameNo
                              // currentGameLibraryGame,
                              // launchParamDto,
                            }: {
    // gameStartPayload: GameStartPayload
    gameStartInfo: GameStartInfo
    gameNo: string
    // currentGameLibraryGame: Omit<GameLibraryGame, 'membersToAdd'>
    // launchParamDto: TLaunchParamDto
  }) => {
    const QueueStore = getQueueStoreByGameMode(gameStartInfo.gameMode)
    QueueStore.currentQueueInfo = {
      // ...gameStartPayload,
      // ...currentGameLibraryGame,
      gameStartInfo,
      queueMainId: '',
      gameJson: '',
      terminal: 'pc',
      gameNo,
      enterTime: Date.now()
      // launchParamDto,
    }
    QueueStore.subTaskStatus = 'none'
    console.log('1')
    QueueStore.queueStatus = 'in'
    try {
      const {result} = await (checkLocal(QueueStore.currentQueueInfo?.gameStartInfo?.gameMode) ?
        enterResourceQueueTrial : enterResourceQueue)({
        gameLibraryBranchId: gameStartInfo.gameLibraryBranchId, //游戏ID
        gameId: gameStartInfo.gameId,
        mode: 1, //模式(该参数与游戏启动接口中一致)
        orderNo: gameNo, //订单号
        deviceMode: gameStartInfo.gameMode, //是否有依赖子作业调度(true/false)，本地模式为false，非本地模式（包括云游戏及其他需要多个子任务调度模式）为true
        devType: 2, //判断是否网吧环境1：是，2：否(该参数与游戏启动接口中一致)
        mac: getFirstPageMAC() || '', //mac地址(该参数与游戏启动接口中一致)
        terminal:'pc',
        // launchParamDto,
        startParams: JSON.stringify({  gameStartInfo,
          // launchParamDto
        }), // json string
        channel: getFirstPageChannel()
      })
      console.log('2')
      if (QueueStore.currentQueueInfo) {
        console.log('3')
        QueueStore.currentQueueInfo = {
          ...QueueStore.currentQueueInfo,
          frontUser: result.frontUser,
          queueMainId: result.queueMainId,
          enterTime: Date.now()
        }
        // QueueStore.currentQueueInfo.frontUser = result.frontUser
        // QueueStore.currentQueueInfo.queueMainId = result.queueMainId
        console.log(QueueStore.currentQueueInfo)
      }
      startCheckQueueStatus({
        gameId: gameStartInfo.gameLibraryBranchId,
        queueMainId: result.queueMainId,
        gameMode:gameStartInfo.gameMode
      })
      QueueStore.queueVisible=true
    } catch (error) {
      console.error(error)
    }
  }

  const writeQueueInfo = (result: TUserGameStatusInfo) => {
    if (result.statusResultObject) {
      const { gameStartPayload, gameStartInfo, currentGameLibraryGame, launchParamDto } = JSON.parse(
        result.statusResultObject.startParams,
      )
      const gameMode= gameStartInfo.gameMode
      const QueueStore = getQueueStoreByGameMode(gameMode)
      QueueStore.currentQueueInfo = {
        ...gameStartPayload,
        ...currentGameLibraryGame,
        gameStartInfo,
        queueMainId: result.statusResultObject.queueMainId,
        frontUser: result.statusResultObject.frontUser,
        terminal: result.terminal,
        launchParamDto,
      }
      QueueStore.queueStatus = 'in'
      startCheckQueueStatus({
        gameId: result.statusResultObject.gameId,
        queueMainId: result.statusResultObject.queueMainId,
        gameMode
      })
    }
  }
  return {
    enterQueue,
    cancenQueue,
    exitQueue,
    quitQueue,
    writeQueueInfo,
    startCheckQueueStatus,
  }
}

export default useQueue
