import React from 'react'

export default {
  'common.cancel':'取消',
  'common.notify':'提示',
  'common.confirm':'确认',
  'common.known':'我知道了',
  'common.subscribe':'开通会员',
  'common.hours':'小时',
  'common.minutes':'分',
  'common.seconds':'秒',
  'common.sendTime':'{count}秒后重发',

  //试玩详情页
  'detail.trial.description':'共享账号说明：',
  'detail.trial.description2':'游戏试玩本质上是 MYBUFF 平台通过自动化工具分配给您一个拥有当前游戏的<span>{platformName}</span>平台账号，并帮您自动化登录的过程，游戏的安装、下载、使用请在登录完成的<span>{platformName}</span>平台进行',
  'detail.trial.step1':'第一步: ',
  'detail.trial.step1Content':'用户申请游戏账号',
  'detail.trial.step2':'第二步: ',
  'detail.trial.step2Content':'平台分配共享账号',
  'detail.trial.step3':'第三步: ',
  'detail.trial.step3Content':'系统自动登录共享账号',
  'detail.trial.step4':'第四步: ',
  'detail.trial.step4Content':'用户自行下载、安装、游戏',
  'detail.trial.tipsTitle':'注意事项：',
  'detail.trial.tipsContent1':'试玩游戏使用账号不固定，请勿在游戏内或试玩使用游戏账号里充值',
  'detail.trial.tipsContent2':'订阅试玩前请仔细阅读<span>【最低配置要求】</span>，避免无法正常进行游戏',
  'detail.trial.tags':'标签：',
  'detail.trial.supportArchive':'支持云存档',
  'detail.trial.notSupportArchive':'不支持云存档',
  'detail.trial.minimum':'最低配置要求',
  'detail.trial.os':'系统：',
  'detail.trial.cpu':'CPU：',
  'detail.trial.ram':'内存：',
  'detail.trial.gpu':'显卡：',
  'detail.trial.directory':'游戏平台安装目录',
  'detail.trial.directoryTips':'系统未检测到您本地的{platformName}平台，请先<span>前往下载</span>或手动指定游戏平台安装目录。',
  'detail.trial.start': '立即使用',
  'detail.trial.queueing': '排队中...',
  'detail.trial.stop': '结束启动',
  'detail.trial.stop2': '结束使用',
  'detail.trial.starting': '启动中...',
  'detail.trial.save': '存档保存中...',
  'detail.trial.skip': '继续启动',
  'detail.trial.select': '当前选择存档',
  'detail.tools':'提供工具&服务',
  'detail.platform':'运行平台',

  'start.step1':'第一步：正在检测本地环境和网络连接状态',
  'start.step2':'第二步：正在启动游戏平台',
  'start.step3':'第三步：正在启动游戏安全组件',
  'start.step4':'第四步：正在输入账号密码，进行游戏启动登录',
  'start.step2Desc':'注: 游戏平台启动过程中，若触发平台更新请耐心等待，平台更新完成后自动进行后续启动流程',
  'start.step4Desc':'注: 系统将自动进行账号密码输入及游戏启动，若多次启动失败/提示登录账号错误请联系客服解决',

  'env.os':'操作系统版本',
  'env.network':'网络环境检测',
  'env.cpu':'CPU占用率',
  'env.memory':'剩余可用内存',
  'env.osLow':'操作系统版本过低',
  'env.networkLow':'网络连接不稳定',
  'env.cpuLow':'当前CPU占用率过高',
  'env.memoryLow':'剩余可用内存不足',
  'env.osLowDesc':'当前系统版本 <span>{text}</span>，操作系统版本过低，「继续启动」可能会出现失败的情况，望知悉！',
  'env.networkLowDesc':'当前使用 <span>WI-FI</span> 联网中，「继续启动」可能会出现失败的情况，建议您更换成网线连接！',
  'env.cpuLowDesc':'当前本机CPU占用率已 <span>{text}</span>，CPU占用率过高，「继续启动」可能造成游戏启动时闪退/崩溃，建议您关闭部分非必要程序后再启动！',
  'env.memoryLowDesc':'当前剩余可用内存 <span>{text}</span>，剩余内存不足，「继续启动」可能造成游戏启动时闪退/崩溃，建议您关闭部分非必要程序后再启动！',

  'detail.save.name': '存档名称',
  'detail.save.type': '存档类型',
  'detail.save.time': '存档创建时间',
  'detail.save.size': '存档大小',
  'detail.save.operate': '存档操作',
  'detail.save.noUse': '不使用存档进行游戏',
  'detail.save.save': '保存',

  'detail.tab.trial': '共享账号',

  'vip.day':'天',
  'vip.days':'天',
  'vip.hour':'小时',
  'vip.hours':'小时',

  'queue.notification':'MYBUFF - 共享账号排队通知',
  'queue.tips':'游戏太火爆啦！暂无可用共享账号',
  'queue.fontUser':'当前处于队列第<div>{fontUserDom}</div>位',
  'queue.cancel':'取消排队',
  'queue.success.account':'您已成功分配到共享游戏账号',
  'queue.success.play':'快去玩游戏吧！',
  'queue.success.enter':'进入游戏',
  'queue.success.refuse':'用户拒绝进入游戏试玩',
  'queue.confirmCancel':'确认取消排队?',
  'queue.confirmCancel2':'确认后，您将从队列中移除',
  'queue.change':`当前正在 {gameName} 队列中，第 {frontUser} 位，您已经等待 {time}，是否取消并启动 {gameName2}？`,

  'trial.notifyVip1':'使用共享账号为 MYBUFF 会员专属特权',
  'trial.notifyVip2':'开通 MYBUFF 会员可无限使用 MYBUFF 的共享账号',
  'trial.openVip':'开通会员',

  'trial.starting':'共享账号登录中',
  'trial.queueing':'共享账号排队中 第{frontUser}位',
  'trial.queueSuccess':'共享账号排队成功',
  'trial.using':'共享账号使用中',
  'trial.saving':'存档保存中',
  'trial.stop':'结束共享账号使用提示',
  'trial.stop2':'请确认您要结束当前共享账号的使用，确认后我们将直接关闭您正在运行的游戏进程，并回收共享账号。{save}',
  'trial.stop3':'结束后请注意进行存档保存',
  'trial.change':'切换共享账号提醒',
  'trial.change2':'您当前正在使用 <strong>{gameName}</strong> 的共享账号，确认要更换使用 <strong>{nextGameName}</strong> 共享账号么？ {save}',
  'trial.change3':' <br></br><br></br><span>直接结束游戏将不会进行云存档保存，请注意！</span>',
  'trial.crash':'游戏试玩异常崩溃',
  'trial.crash2':'由于未知原因造成了试玩游戏的崩溃，对您造成的不便我们致以最大的歉意。({msg})',

  'trial.vip.expiration':'会员到期提示',
  'trial.vip.expiration2':'您的会员已到期，我们已自动结束了您当前正在试玩的游戏，并收回了您共享账号的使用权限',
  'trial.vip.expiration3':'会员即将到期提示',
  'trial.vip.expiration4':'您的会员即将到期，到期后我们将直接回收您当前使用的共享账号使用权，并关闭您正在试玩的游戏，此操作可能会造成您游戏进度的丢失！',

  'save.title':'当前保存存档信息',
  'save.yes':'确认保存',
  'save.no':'不保存',
  'save.gameName':'保存存档游戏：',
  'save.time':'存档创建时间：',
  'save.size':'保存存档大小：',
  'save.name':'云存档命名',
  'save.placeholder':'请填写自定义云存档的名称',
  'save.notBlank':'存档名不能为空',
  'save.nameRule':'存档名不能包含 空格><:"\'/\\|?*',
  'save.success':'保存成功',
  'save.fail':'保存失败',
  'save.local':'本地存档',
  'save.cloud':'云存档',
  'save.auto':'自动保存',
  'save.renameSuccess':'存档名称变更成功',
  'save.renameFail':'修改失败',
  'save.deleteSuccess':'删除成功',
  'save.deleteFail':'删除成功',
  'save.deleteLocal':'请再次确认您要删除此存档，删除后您将再无法找回此存档进度！',
  'save.deleteLocalCloud':'请再次确认您要删除此存档，删除后您需要再次下载至本地才可使用！',
  'save.delete2':'请再次确认您要删除此存档，删除后您将再无法找回此存档进度！',

  'userCenter.user':'个人信息',
  'userCenter.sysSetting':'系统设置',
  'userCenter.cdk':'CDK兑换',
  'userCenter.vip':'会员订阅',

  'userCenter.edit':'修改昵称',
  'userCenter.regTime':'注册日期',
  'userCenter.SafetyInformation':'安全信息',
  'userCenter.account':'账号',
  'userCenter.pwd':'密码',
  'userCenter.bound':'已绑定',
  'userCenter.changePwd':'修改密码',
  'userCenter.email':'邮箱',
  'userCenter.changeEmail':'换绑邮箱',
  'userCenter.bindEmail':'绑定邮箱',
  'userCenter.vipStatus':'会员状态',
  'userCenter.subscribing':'启用中',
  'userCenter.notSubscribed':'未开通',
  'userCenter.expireDate':'到期时间',
  'userCenter.subscribe':'订阅',
  'userCenter.logout':'退出登录',
  'userCenter.logout1':'切换账号提示',
  'userCenter.logout2':'尊敬的MyBuff用户，请再次确认您要登出当前账号',
  'userCenter.logout3':'登出后某些依赖客户端的功能将无法生效',
  'userCenter.logout4':'确认登出',
  'userCenter.nick.editSuccess':'修改成功',
  'userCenter.nick.blank':'新昵称不能为空',
  'userCenter.nick.same':'新昵称与旧昵称一致,无需修改',

  'bind.mobile':'手机',
  'bind.email':'邮箱',
  'bind.password':'密码',
  'bind.change':'换绑{type}',
  'bind.changePwd':'更换密码',
  'bind.auth':'身份验证',
  'bind.setNew':'绑定新{type}',
  'bind.end':'完成',
  'bind.authSelect':'验证方式选择',
  'bind.authType':'{type}验证',
  'bind.changeSuccess':'换绑成功',
  'bind.next':'下一步',

  'bind.email.placeholder':'请输入您收到的邮箱验证码',
  'bind.email.emptyMsg':'验证码不能为空',
  'bind.email.errorMsg':'请填写正确格式的验证码',

  'bind.pwd.placeholder':'请填写当前账号的登录密码',
  'bind.pwd.emptyMsg':'登录密码不能为空',
  'bind.pwd.errorMsg':'密码长度为8-16位;密码由字母+数字组成',

  'email.send':'获取邮箱验证码',
  'email.resend':'重新获取验证码',

  'change.pwd.placeholder':'请填写想要更换的新的密码',
  'change.pwd.emptyMsg':'请填写想要更换的新的密码',
  'change.pwd.errMsg':'密码长度为8-16位;密码由字母+数字组成',
  'change.pwd.placeholder2':'请重复输入新的密码',
  'change.pwd.emptyMsg2':'请重复输入新的密码',
  'change.pwd.errMsg2':'请重复输入正确的新的密码',
  'change.pwd.notSame':'前后2次密码不一致，请重新输入',

  'reset.pwd.confirm':'账号确认',
  'reset.pwd.reset':'更换密码',
  'reset.pwd.email':'请输入您要找回账号绑定的邮箱',
  'reset.pwd.noAccount':'当前邮箱未绑定任何MyBuff账号，请重新填写',
  'reset.pwd.authType':'绑定{type}验证',
  'reset.pwd.account':'发起找回账号',
  'reset.pwd.confirmReset':'确 认 更 换',
  'reset.pwd.loginAccount':'登录账号',
  'reset.pwd.newPwd':'新的密码',
  'reset.pwd.login':'直 接 登 录',

  'change.email.placeholder':'请填写想要绑定的新的邮箱地址',
  'change.email.emptyMsg':'邮箱地址不能为空',
  'change.email.errorMsg':'请填写正确格式的邮箱地址',
  'change.email.placeholder2':'请输入您收到的邮箱验证码',
  'change.email.emptyMsg2':'验证码不能为空',
  'change.email.errorMsg2':'请填写正确格式的验证码',
  'change.email.same':'换绑邮箱不可与当前绑定邮箱一致',

  'login.login':'登录',
  'login.reg':'注册',
  'login.forgetPwd':'忘记密码',
  'login.success':'登录成功',
  'login.email.placeholder':'请填写您作为登录账号的邮箱地址',
  'login.email.emptyMsg':'请填写您作为登录账号的邮箱地址',
  'login.email.errMsg':'请输入正确的邮箱地址',
  'login.pwd.placeholder':'请输入登录密码',
  'login.pwd.emptyMsg':'请输入密码',
  'login.pwd.errMsg':'密码长度为8-16位;密码由字母+数字组成',
  'login.pwd.remember':'记住密码',
  'login.pwd.forget':'忘记密码?',
  'login.toLogin':'点 击 登 录',

  'login.reg.success':'注册成功',
  'login.reg.used':'当前邮箱已被使用,请直接登录',
  'login.sentSuccess':'发送成功',
  'login.reg.agreement':'注册即代表同意并遵守<span>《{name}》</span>',
  'login.reg.create':'创 建 账 号',
  'login.reg.agreementRead':'请阅读并同意《{name}》',

  'sysSettings.common':'常用设置',
  'sysSettings.boot':'开机自动启动',
  'sysSettings.download':'资源下载设置',
  'sysSettings.directory':'默认资源下载目录',
  'sysSettings.closed':'当窗口关闭时',
  'sysSettings.hide':'隐藏到任务栏托盘',
  'sysSettings.exit':'退出主程序',

  'cdk.redeem':'CDK兑换',
  'cdk.placeholder':'请填写您获得的平台CDK',
  'cdk.btn':'立即兑换',
  'cdk.record':'兑换记录',
  'cdk.noRecord':'暂无兑换记录',
  'cdk.time':'兑换时间',
  'cdk.key':'使用CDK',
  'cdk.goods':'兑换物品',
  'cdk.success':'兑换成功',
  'cdk.empty':'CDK不能为空',

  'platform.err':'错误的游戏平台路径，请重新识别',
  'platform.success':'游戏平台识别成功',

  'update.hasUpdate':'检测到 MyBuff 有更新',
  'update.restart':'重启客户端',
  'update.closing':'MyBuff 客户端正在关闭中 {i}S......',
  'update.lose':'请耐心等待客户端关闭，强行结束将可能造成数据丢失',
  'update.force':'检测到 MYBUFF 必要的更新',
  'update.forceTips':'系统检测到有十分必要的安全性更新，请在重启更新完成后继续使用MYBUFF的各项功能，对您造成不便我们深表歉意',

  'cloudArchiveTips.title':'游戏启动提示',
  'cloudArchiveTips.desc':'当前游戏暂时不支持云存档，二次游戏时可能由于使用账号更换而造成游戏进度丢失，望知悉！',
  'cloudArchiveTips.notRemind':'下次启动不再提示',
  'cloudArchiveTips.next':'确认继续',

  'service.title':'服务保障：',
  'service.name1':'官方采购正版账号',
  'service.desc1':'全部游戏账号均通过官方渠道注册；所有游戏均通过官方商店购买激活',
  'service.name2':'使用期间不顶号',
  'service.desc2':'共享账号使用期间一人独占，结束共享账号使用前绝不顶号',
  'service.name3':'账号错误/异常自动换号',
  'service.desc3':'上号程序自动检测登录共享账号是否正常可用；出现异常自动更换，安全、快捷、方便',
  'service.name4':'支持联机/在线模式',
  'service.desc4':'游戏为联机游戏/提供在线模式时，共享账号也可正常使用，完爆破解及离线游戏',

  'payModal.product':'订单信息',
  'payModal.timeout':'支付超时',
  'payModal.noPay':'您尚未完成支付',
  'payModal.payType':'支付方式',
  'payModal.total':'支付金额：',
  'payModal.payNow':'立即支付',
  'payModal.refresh':'刷新',
  'payModal.agreement':'完成购买视为同意<span>《{title}》</span>',
  'payModal.tips':'温馨提示',
  'payModal.complete':'已完成支付',
  'payModal.cancel':'返回重新支付',
  'payModal.tipContent':'请您在浏览器新打开的页面完成支付，支付完成前建议不要关闭该窗口',
  'payModal.success':'购买成功',
}