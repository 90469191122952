import React from 'react'
import { RouteObject } from 'react-router-dom'

import NotFound from 'src/pages/not-found'
// import Home from 'src/pages/home'
// import Index from 'src/pages/home-new'
// import GameLibrary from 'src/pages/speed/game-library'
// import DurationRecharge from 'src/pages/duration-recharge'
// import CloudGameRecharge from 'src/pages/cloudgame-recharge'
// import VipRecharge from 'src/pages/vip-recharge'
// import UserCenter from 'src/pages/user-center'
// import GiftKey from 'src/pages/gift-key'
// import ModifierStore from 'src/pages/modifier/store'
// import Resource from 'src/pages/resource'
// import GameSearch from 'src/pages/game-search'
// import PlatformSearch from 'src/pages/platform-search'
// import MySubscription from 'src/pages/my-subscription'
// import GameDetail from 'src/pages/game-detail'
import GameDetail from 'src/pages/game-detail'
// import MyTrialRecord from 'src/pages/my-trial-record'
import MiniGameDetail from 'src/pages/mini/game-detail'
import Main from 'src/pages/main'

const routes: RouteObject[] = [
  // { path: '/speed/game-library', element: <GameLibrary /> },
  // { path: '/user-center', element: <UserCenter /> },
  // { path: '/modifier/store', element: <ModifierStore /> },
  // { path: '/duration-recharge', element: <DurationRecharge /> },
  // { path: '/cloudgame-recharge', element: <CloudGameRecharge /> },
  // { path: '/gift-key', element: <GiftKey /> },
  // { path: '/resource/store', element: <Resource /> },
  // { path: '/game-search', element: <GameSearch /> },
  // { path: '/platform-search', element: <PlatformSearch /> },
  // { path: '/my-subscription', element: <MySubscription /> },
  // { path: '/my-trial-record', element: <MyTrialRecord /> },
  { path: '/game-detail', element: <GameDetail /> },
  { path: '/mini/game-detail', element: <MiniGameDetail /> },
  // { path: '/vip-recharge', element: <VipRecharge /> },
  { path: '/main/*', element: <Main />},
  { path: '/', element: <Main /> },
  // { path: '/index', element: <Home /> },
  { path: '*', element: <NotFound /> },
]

export default routes
