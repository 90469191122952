import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { ConfigStore, RepairStore, RepairType, SpeedStore, VipStore } from 'src/store'
import { LoginStore } from 'src/store/modifier'
import { message, Tooltip } from 'antd'
import { fetchGameInfo, fetchThirdGameInfo } from 'src/apis/game-api'
import { TextButton } from 'src/components/base-button'
import { startSpeed } from 'src/tools/speed'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import { useNavigate } from 'react-router-dom'
import { showFeedback } from 'src/tools/common'
import {
  StyledContent,
  StyledLeftContent,
  StyledMainButton,
  StyledMainButtonContent,
  StyledRightContent
} from 'src/pages/game-detail/trial'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'
import CheckNode, { TAreaServer } from 'src/pages/game-detail/speed/check-node'
import dayjs from 'dayjs'
import RemainingTime from 'src/components/remaining-time'
import QueueAnim from 'rc-queue-anim'
import SpeedSuccess from 'src/pages/game-detail/speed/speed-success'
import styled from 'styled-components'

import TipsIcon from 'src/assets/icons/common/tips.svg'
import ArrowsIcon from 'src/assets/icons/vip/arrows.svg'
import ArrowsHoverIcon from 'src/assets/icons/vip/arrows-hover.svg'
import WifiIcon from 'src/assets/icons/speed-detail/wifi.svg'
import EcgIcon from 'src/assets/icons/speed-detail/ecg.svg'
import StartBtnIcon from 'src/assets/icons/my-game/start-btn.svg'
import PauseBtnIcon from 'src/assets/icons/my-game/pause-btn.svg'
import SwitchBtnIcon from 'src/assets/icons/my-game/switch-btn.svg'
import classNames from 'classnames'
import CEFTransfer from 'src/CEFTransfer'
import LoadingIcon from 'src/assets/icons/my-game/spinner.svg'

type TBtnState = 'hover' | 'leave' | 'start' | 'speeding' | 'speeded' | 'normal'
type TDetailStatus = 'change-area-server'
const mocklist = ['网络基础知识科普', '加速后无法打开网页的方案', '1000M光纤为什么加速后还是这么卡', '怎么重置网络']

const GameDetail: FC<{
  speedGames: Game.BranchAssociatedDataSpeedGame[]
  changeSearch: (state: Record<string, string>) => void
  gameLibraryName: string
  gamePlatformName: string
  branchId: number
}> = ({ speedGames, changeSearch, gameLibraryName, gamePlatformName,branchId }) => {
  const Query = new URLSearchParams(location.search)

  const CheckNodeRef = useRef<{ onConfirm: () => { areaServerInfo: TAreaServer; nodeInfo: CEF.TNodeInfo } }>(null)
  const navigate = useNavigate()
  const [currentSpeedGame, setCurrentSpeedGame] = useState<Game.BranchAssociatedDataSpeedGame | undefined>(() => {
    const defaultSpeedGameNo = Query.get('speedGameNo')
    if (defaultSpeedGameNo && speedGames.length > 0 && speedGames.find(f => f.speedGameNo === defaultSpeedGameNo)) {
      return speedGames.find(f => f.speedGameNo === defaultSpeedGameNo)
    }
    // if (!defaultSpeedGameNo && !!speedGames[0]) {
    return speedGames[0]
    // }
    // return undefined
  })
  const [btnState, setBtnState] = useState<TBtnState>('normal')
  const [thirdInfo, setThirdInfo] = useState<Http.ThirdGameInfo>()
  const [gameInfo, setGameInfo] = useState<Http.GameInfo>()
  const [detailStatus, setDetailStatus] = useState<TDetailStatus>()
  const currentSpeedGameNo = currentSpeedGame ? currentSpeedGame.speedGameNo : ''
  const currentRelateIden = currentSpeedGame ? currentSpeedGame.relateIdens : ''
  const isCurrentSpeed = currentRelateIden === SpeedStore.startingSpeedInfo?.relateIdens
  const isLogin = !!LoginStore.userinfo
  const isSpeeding = isCurrentSpeed && SpeedStore.speedStatus === 'speeding' && SpeedStore.speedingInfo
  const isStarting = isCurrentSpeed && SpeedStore.speedStatus === 'starting'
  const loadInitData = async (relateIden: string, speedGameNo: string) => {
    const [thirdInfo, gameInfo] = await Promise.all([
      fetchThirdGameInfo({ speedGameNo }),
      fetchGameInfo({ speedGameNo: relateIden }),
    ])
    setThirdInfo(thirdInfo.result)
    setGameInfo(gameInfo.result)
  }

  const handleSetCurrentSpeedGame = (value: Game.BranchAssociatedDataSpeedGame) => {
    setCurrentSpeedGame(value)
    changeSearch({
      speedGameNo: value.speedGameNo,
    })
  }

  const handleStartSpeed = async (startType?: 'start' | 'change') => {
    if (!CheckNodeRef.current || !gameInfo || !thirdInfo) {
      return
    }
    if (startType === 'change') {
      SpeedStore.isSwitchSpeed = true
    }
    try {
      const { areaServerInfo, nodeInfo } = CheckNodeRef.current.onConfirm()
      await startSpeed({
        ...gameInfo,
        thirdInfo,
        gameLibraryName,
        gamePlatformName,
        areaInfo: areaServerInfo,
        nodeInfo,
        speedGameName: gameInfo?.name,
        startType,
        relateIdens: currentRelateIden,
        modelCallback: startType === 'change' ? () => setDetailStatus(undefined) : undefined,
      })
    } catch (error) {
      message.warn((error as Error).message)
    }
  }

  const changeBtnState = (state: TBtnState) => {
    setTimeout(() => {
      setBtnState(state)
    }, 10)
  }

  const handleStopSpeed = () => {
    imperativeConfirmModal({
      title: '停止加速提醒',
      content: '停止加速前请确认您已结束正在加速的游戏，直接停止可能造成您正在加速的游戏掉线！',
      cancelText: '取消',
      okText: '确认停止',
      onOk() {
        const speedInfo = SpeedStore.hasSpeeding()
        SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_STOP' })
        message.success('停止加速成功')
        if (speedInfo) {
          showFeedback('speed', {
            platform: speedInfo.gamePlatformName || '',
            gameName: speedInfo.gameLibraryName || '',
            speedArea: speedInfo.speedAreaName || '',
            speedNode: speedInfo.speedNodeName || '',
            speedOperate: speedInfo.speedGameName || '',
          }, true)
        }
      },
    })
  }

  const LeftComponent = useMemo(() => {
    if (!thirdInfo || !currentSpeedGameNo) {
      return null
    }
    if (detailStatus === 'change-area-server') {
      return (
        <CheckNode
          onChangeDetailStatus={setDetailStatus}
          isSpeeding={true}
          ref={CheckNodeRef}
          speedGames={speedGames}
          setCurrentSpeedGame={handleSetCurrentSpeedGame}
          speedGameNo={currentSpeedGameNo}
          thirdGameNo={thirdInfo.thirdGameNo}
        />
      )
    }
    return isSpeeding ? (
      <SpeedSuccess onChangeDetailStatus={setDetailStatus} />
    ) : (
      <CheckNode
        onChangeDetailStatus={setDetailStatus}
        isSpeeding={false}
        ref={CheckNodeRef}
        speedGames={speedGames}
        setCurrentSpeedGame={handleSetCurrentSpeedGame}
        speedGameNo={currentSpeedGameNo}
        thirdGameNo={thirdInfo.thirdGameNo}
      />
    )
  }, [detailStatus, isSpeeding, CheckNodeRef, currentSpeedGameNo, thirdInfo?.thirdGameNo, speedGames])

  const mainButtonProps = useMemo(() => {
    if (isStarting) {
      return {
        children: (
          <StyledMainButtonContent>
            {/*<StartBtnIcon className='icon' />*/}
            <LoadingIcon className='icon-loading' />
            <span>加 速 中</span>
          </StyledMainButtonContent>
        ),
        click: undefined,
        className: `start-btn-speeding`,
        disabled: true,
      }
    }
    if (isSpeeding && detailStatus === 'change-area-server') {
      return {
        children: (
          <StyledMainButtonContent>
            <SwitchBtnIcon className='icon' />
            <span>确 认 切 换</span>
          </StyledMainButtonContent>
        ),
        click: () => handleStartSpeed('change'),
        className: `start-btn-${btnState}`,
      }
    }
    if (isSpeeding && !detailStatus) {
      return {
        children: (
          <StyledMainButtonContent>
            <PauseBtnIcon className='icon' />
            <span>停 止 加 速</span>
          </StyledMainButtonContent>
        ),
        click: () => handleStopSpeed(),
        className: `start-btn-speeded`,
      }
    }

    if (SpeedStore.isSwitchSpeed && SpeedStore.checkedSpeedInfo?.speedGameNo === currentSpeedGameNo) {
      return {
        children: (
          <StyledMainButtonContent>
            <SwitchBtnIcon className='icon' />
            <span>切 换 中</span>
          </StyledMainButtonContent>
        ),
        click: undefined,
        className: `start-btn-speeding`,
      }
    }

    return {
      children: (
        <StyledMainButtonContent>
          <StartBtnIcon className='icon' />
          <span>一 键 加 速</span>
        </StyledMainButtonContent>
      ),
      click: () => handleStartSpeed(),
      className: `start-btn-${btnState}`,
    }
  }, [
    isStarting,
    isSpeeding,
    handleStartSpeed,
    btnState,
    detailStatus,
    SpeedStore.isSwitchSpeed,
    SpeedStore.checkedSpeedInfo,
    currentSpeedGameNo,
  ])

  const faqList= useMemo(()=>{
    return ConfigStore.speedFaq.filter((v)=>{
      return !v.goodsId || v.goodsId.split(',').includes(String(branchId))
    }).slice(0,4)
  },[ConfigStore.speedFaq, branchId])

  useEffect(() => {
    if (currentRelateIden && currentSpeedGameNo) {
      loadInitData(currentRelateIden, currentSpeedGameNo)
    }
  }, [currentRelateIden, currentSpeedGameNo])

  // useEffect(() => {
  //   if (gameInfo?.image) {
  //     appStore.layoutBg = gameInfo.image
  //     return () => {
  //       appStore.layoutBg = null
  //     }
  //   }
  // }, [gameInfo])

  useEffect(() => {
    if (isCurrentSpeed && SpeedStore.startingSpeedInfo && SpeedStore.startingSpeedInfo.speedProgress > 0) {
      changeBtnState(SpeedStore.startingSpeedInfo?.speedProgress >= 100 ? 'speeded' : 'speeding')
    } else {
      changeBtnState('normal')
    }
  }, [SpeedStore.startingSpeedInfo?.speedProgress])

  useEffect(() => {
    if (isLogin) {
      /** 单纯的刷新一下用户的会员状态 */
      VipStore.handleLoadVipInfo()
    }
  }, [isLogin])

  if (!thirdInfo || !gameInfo || !currentSpeedGameNo) {
    return null
  }

  return (
    <StyledGameDetailContainer>
      <StyledContent>
        <StyledLeftContent>{LeftComponent}</StyledLeftContent>
        <QueueAnim component={StyledRightContent}>
          <StyledSectionBox key='StyledSectionBox-1'>
            {VipStore.memberinfo?.isMember ? (
              <>
                <div className='title'>
                  会员到期时间
                  {VipStore.speedFreeConfig.freeSpeedTimeDesc && (
                    <Tooltip
                      color={'rgb(45, 45, 45)'}
                      overlayInnerStyle={{
                        borderRadius: '4px',
                        width: 300,
                      }}
                      overlay={<StyledVipFreeText>会员期间，全天全时段免费加速！</StyledVipFreeText>}
                      placement='bottom'
                    >
                      <TipsIcon />
                    </Tooltip>
                  )}
                </div>
                <div className='content'>
                  <StyledSpeedBalanceBox>
                    <div className='balance'>
                      {dayjs(VipStore.memberinfo.expireDate).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </StyledSpeedBalanceBox>
                </div>
              </>
            ) : (
              <>
                <div className='title'>
                  可用加速时长
                  {/*{VipStore.speedFreeConfig.freeSpeedTimeDesc && (*/}
                    <Tooltip
                      color={'rgb(45, 45, 45)'}
                      overlayInnerStyle={{
                        borderRadius: '4px',
                        width: 300,
                      }}
                      overlay={
                        <StyledVipFreeText>
                          {/*加速器免费使用时间段: 每天*/}
                          {/*<span className='main'>{VipStore.speedFreeConfig.freeSpeedTimeDesc}</span>;*/}
                          {/*免费加速时段不消耗可用时长*/}
                          <div className='vip-free-text'>
                            <TextButton onClick={() => showUserCenter(NAV_KEY_MAPPING.VIP)}>MyBuff会员</TextButton>
                            可享受全天全时段免费加速服务
                          </div>
                        </StyledVipFreeText>
                      }
                      placement='bottom'
                    >
                      <TipsIcon />
                    </Tooltip>
                  {/*)}*/}
                </div>
                <div className='content'>
                  <StyledSpeedBalanceBox>
                    <div className='balance'>
                      <RemainingTime timeType='speed' />
                    </div>
                  </StyledSpeedBalanceBox>
                </div>
              </>
            )}

            <div
              className='buy-btn'
              onClick={() =>{
                showUserCenter(VipStore.memberinfo?.isMember ? NAV_KEY_MAPPING.VIP : NAV_KEY_MAPPING.DURATION_RECHARGE)
              }}
            >
              {VipStore.memberinfo?.isMember ? '续费会员' : '充值时长'}
              <ArrowsIcon className='arrows-icon' />
              <ArrowsHoverIcon className='arrows-icon arrows-hover-icon' />
            </div>
          </StyledSectionBox>
          <StyledSectionBox
            key='StyledSectionBox-2'
          >
            <div className='title'>
              常见问题
              <div className='title-extra'>
                <TextButton className='btn' onClick={() => cefOpenUrl('https://support.qq.com/product/543586')}>查看更多</TextButton>
              </div>
            </div>
            <div className='content'>
              <StyledFQContent>
                {faqList.map((d, i) => {
                  return (
                    <StyledFQItem key={i} onClick={()=>cefOpenUrl(d.url)}>
                      <span>{d.title}</span>
                    </StyledFQItem>
                  )
                })}
              </StyledFQContent>
            </div>
          </StyledSectionBox>
          <StyledSectionBox key='StyledSectionBox-3'>
            <div className='title'>
              常用工具
            </div>
            <div className='content'>
              <StyledUtilsContainer>
                <div className='util-item' key='StyledSectionBox-5'>
                  <div className='util-left'>
                    <WifiIcon
                      className={classNames('wifi-icon', {
                        active: RepairStore.repairIsProcee(RepairType.REPAIR_NETWORK),
                      })}
                    />
                    <span className='text'>网络连接修复工具</span>
                  </div>
                  <div className='util-right'>
                    <TextButton
                      loading={RepairStore.repairIsProcee(RepairType.REPAIR_NETWORK)}
                      onClick={() =>
                        RepairStore.handleRepair(
                          RepairType.REPAIR_NETWORK,
                          () => {
                            imperativeConfirmModal({
                              title: '网络连接修复成功',
                              content: '网络重置成功，重启计算机后生效',
                              okText: '立即重启',
                              onOk: () => {
                                CEFTransfer.sendToClient('restartComputer')
                              },
                            })
                          },
                          () => message.warn('网络连接修复失败，请稍后再试或联系客服解决'),
                        )
                      }
                    >
                      {RepairStore.repairIsProcee(RepairType.REPAIR_NETWORK) ? '修复中' : '立即修复'}
                    </TextButton>
                  </div>
                </div>
                <div className='util-item' key='StyledSectionBox-6'>
                  <div className='util-left'>
                    <EcgIcon
                      className={classNames('ecg-icon', { active: RepairStore.repairIsProcee(RepairType.REPAIR_DNS) })}
                    />
                    <span className='text'>DNS修复工具</span>
                  </div>
                  <div className='util-right'>
                    <TextButton
                      loading={RepairStore.repairIsProcee(RepairType.REPAIR_DNS)}
                      onClick={() =>
                        RepairStore.handleRepair(
                          RepairType.REPAIR_DNS,
                          () => message.success('DNS修复成功'),
                          () => message.warn('DNS修复失败，请稍后再试或联系客服解决'),
                        )
                      }
                    >
                      {RepairStore.repairIsProcee(RepairType.REPAIR_DNS) ? '修复中' : '立即修复'}
                    </TextButton>
                  </div>
                </div>
              </StyledUtilsContainer>
            </div>
          </StyledSectionBox>
        </QueueAnim>
      </StyledContent>
      <StyledBottom>
        <StyledMainButton
          onClick={mainButtonProps.click}
          className={classNames(mainButtonProps.className,{
            disabled:mainButtonProps.disabled
          })}
        >
          {mainButtonProps.children}
        </StyledMainButton>
      </StyledBottom>
    </StyledGameDetailContainer>
  )
}

const StyledBottom=styled.footer`
  width: 100%;  
  height: 77px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(255,255,255,0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// const StyledMainButton = styled(MainTestButton)`
//   ${props => props.theme.flexCenter}
//   .icon {
//     margin-right: 16px;
//   }
//   .normal-row {
//     ${props => props.theme.flexCenter}
//   }
// `

const StyledUtilsContainer = styled.div`
  margin-bottom: 14px;
  .util-item {
    ${props => props.theme.flexAlignCenter}
    justify-content: space-between;
    height: 22px;
    margin-bottom: 24px;
    .util-left {
      ${props => props.theme.flexAlignCenter}
      img {
        width: 24px;
      }
      .text {
        margin-left: 15px;
        font-size: 14px;
        line-height: 14px;
        color: #C6C6C6;
      }
      .ecg-icon {
        path:nth-child(1) {
          transform: translateX(0px);
        }
        path:nth-child(2) {
          display: none;
          transform: translateX(-13px);
        }
        path:nth-child(3) {
          display: none;
          transform: translateX(13px);
        }
        &.active {
          path:nth-child(1) {
            animation: ecg1 1s linear 0s infinite;
          }
          path:nth-child(2) {
            display: block;
            animation: ecg2 1s linear 0s infinite;
          }
          path:nth-child(3) {
            display: block;
            animation: ecg3 1s linear 0s infinite;
          }
          @keyframes ecg1 {
            0% {
              transform: translateX(0px);
            }
            100% {
              transform: translateX(13px);
            }
          }
          @keyframes ecg2 {
            0% {
              transform: translateX(-13px);
            }
            100% {
              transform: translateX(0px);
            }
          }
          @keyframes ecg3 {
            0% {
              transform: translateX(13px);
            }
            100% {
              transform: translateX(26px);
            }
          }
        }
      }
      .wifi-icon {
        path:nth-child(2) {
          opacity: 0;
        }
        path:nth-child(3) {
          opacity: 0;
        }
        path:nth-child(4) {
          opacity: 0;
        }
        &.active {
          path:nth-child(1) {
            display: none;
          }
          path:nth-child(2) {
            animation: wifi3 1s linear 0s infinite;
          }
          path:nth-child(3) {
            animation: wifi2 1s linear 0s infinite;
          }
          path:nth-child(4) {
            animation: wifi1 1s linear 0s infinite;
          }
          @keyframes wifi1 {
            0% {
              opacity: 0;
            }
            19% {
              opacity: 0;
            }
            20% {
              opacity: 1;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes wifi2 {
            0% {
              opacity: 0;
            }
            39% {
              opacity: 0;
            }
            40% {
              opacity: 1;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes wifi3 {
            0% {
              opacity: 0;
            }
            59% {
              opacity: 0;
            }
            60% {
              opacity: 1;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
    .util-right {
      font-size: 14px;
      line-height: 14px;
      .sg-text-button-normal {
        ${props => props.theme.flexCenter}
      }
    }
  }
`

export const StyledFQItem = styled.div`
  margin-bottom: 8px;
  span {
    cursor: pointer;
    line-height: 28px;
    font-size: 14px;
    color: #C6C6C6;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${props => props.theme.color.bg.$999999};
    }
  }
`

// const StyledMainButtonContent = styled.div`
//   ${props => props.theme.flexAlignCenter}
// `

export const StyledFQContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  border-radius: 4px;
  padding: 0 20px 0;
`

export const StyledSpeedBalanceBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  font-size: 24px;
  line-height: 24px;
  font-family: Impact-Regular, Impact;
  color: #C19CFE;
  font-weight: 400;
`

const StyledSectionFlexBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  margin-bottom: 16px;
  
`

const StyledVipFreeText = styled.div`
  .main {
    color: ${props => props.theme.color.mainColor};
  }
`

export const StyledSectionBox = styled.div`
  padding: 32px 32px 0;
  position: relative;

  .buy-btn {
    ${props => props.theme.flexCenter}
    position: relative;
    width: 100px;
    height: 35px;
    background: rgba(255,255,255,0.12);
    border-radius: 4px;
    font-size: 14px;
    color: #C6C6C6;
    padding-right: 10px;
    cursor: pointer;
    position: absolute;
    right: 32px;
    top: 50%;
    margin-top: 16px;
    transform: translateY(-50%);
    .arrows-icon {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      margin-left: 14px;
      &.arrows-hover-icon {
        opacity: 0;
        transition: right 300ms ease 0s;
      }
    }
    &:hover {
      color: ${props => props.theme.color.mainColor};
      .arrows-icon {
        margin-left: 14px;
        opacity: 0;
        &.arrows-hover-icon {
          opacity: 1;
          right: 10px;
        }
      }
    }
  }
  
  .title {
    ${props => props.theme.flexAlignCenter}
    font-size: 16px;
    line-height: 27px;
    font-weight: bold;
    margin-bottom: 16px;
    color: var(--text-color-FFFFFF);
    position: relative;
    svg {
      margin-left: 10px;
    }
  }
  .title-extra {
    position: absolute;
    right: 0;
    bottom: 0;
    .sg-text-button-normal {
      font-size: 14px;
      color: rgba(255,255,255,0.86);
      font-weight: normal;
      &:hover{
        color: #fff;
      }
    }
  }
`

const StyledGameDetailContainer = styled.div`
  height: 100%;
`

export default observer(GameDetail)
