import { baseFetch, persistentFetch } from 'src/fetch'
import { javaResultFilter, phpResultFilter } from 'src/fetch/resInterceptors'

/**
 * @description 微信登录地址
 * @method get
 */
export const getWechatLoginCode = baseFetch<Http.JavaApiResult<Http.WechatLoginCode>>({
  url: '/anon/wechat/gzh/getLoginQrcode',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})
/**
 * @description 微信登录状态查询
 * @method get
 */
export const getWechatLoginStatus = baseFetch<Http.JavaApiResult<Http.LoginResult>, { sceneId: string }>({
  url: '/anon/wechat/gzh/checkLogin',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 登录
 * @method post
 * @param  account: string
 * @param  password: string
 * @param  type: mobile
 */
export const handleLogin = baseFetch<Http.JavaApiResult<Http.LoginResult>, Http.LoginParams>({
  url: '/login',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 发送验证码
 * @method post
 * @param  mobile: string
 * @param  type: login
 */
export const fetchSmsCode = baseFetch<Http.JavaApiResult, Http.GetSmsParams>({
  url: '/verificationCode',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询用户会员信息
 * @method get
 */
export const fetchMemberInfo = baseFetch<Http.JavaApiResult<Http.TMemberResult>>({
  url: '/member',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询用户每周可免费订阅的次数
 * @method get
 */
export const fetchFreeSubscribeConfig = baseFetch<Http.JavaApiResult<Http.TFreeSubscribeConfig>>({
  url: '/anon/userGameSubscribe/freeSubscribeConfig',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询用户本周期已订阅次数
 * @method get
 */
export const fetchUserFreeSubscribeNum = baseFetch<Http.JavaApiResult<Http.TUserUseSubscribeNum>>({
  url: '/userGameSubscribe/user/subscribeNum',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询用户剩余的加速时长余额
 * @method get
 */
export const fetchUserRemainingSpeedBalance = baseFetch<Http.JavaApiResult<Http.MemberInfo>>({
  url: '/usergame/asset/speed',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
    // transformResponse(data, headers) {
    //   try {
    //     const d = JSON.parse(data)
    //     const result = {
    //       ...d,
    //       result: {
    //         ...d.result,
    //         remaining:
    //           d.result?.expireDate && headers?.date ? +new Date(d.result?.expireDate) - +new Date(headers.date) : null,
    //       },
    //     }
    //     return result
    //   } catch (error) {
    //     return data
    //   }
    // },
  },
})

/**
 * @description 获取用户云游戏剩余时长
 * @method get
 */
export const fetchUserGameCloudInfo = baseFetch<Http.JavaApiResult<TCloudInfo>, { realTime?: boolean }>(
  {
    url: '/api/user/game/cloud/info',
    method: 'get',
    options: {
      baseURL: process.env.SG_APP_JAVA_API_HOST,
      resInterceptors: javaResultFilter,
    },
  },
)

/** 查询当前用户云游戏状态 */
export const fetchCurrentGamePlayStatus = baseFetch<
  Http.JavaApiResult<Http.TUserGameStatus>,
  { realTime?: boolean }
>({
  url: '/current/game/play/status',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 取消资源排队 */
export const cancelUserGameResourceQueue = baseFetch<
  Http.JavaApiResult<Http.TUserGameStatus>
>({
  url: '/api/user/game/resource/queue/cancel',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 查询排队信息 */
export const queryUserGameResourceQueue = baseFetch<
  Http.JavaApiResult<Http.TResourceQueueInfo>
>({
  url: '/api/user/game/resource/queue/query',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 加入资源排队 */
export const enterUserGameResourceQueue = baseFetch<
  Http.JavaApiResult<Http.TJoinResourceQueueResponse>
>({
  url: '/api/user/game/resource/queue/enter',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description cdk兑换列表
 * @method get
 */
export const fetchExchangeCdkList = baseFetch<Http.JavaApiResult<Http.CdkExchangeListResult>, Http.BaseMultPageParams>({
  url: '/cdk/exchange/page',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 用户申请加速单号
 * @method post
 */
export const applySpeedOrderNo = baseFetch<Http.JavaApiResult<Http.SpeedOrderNoResult>, Http.ApplySpeedOrderNoParams>({
  url: '/user/speed/no/gen',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 兑换CDK
 * @method post
 */
export const fetchExchangeCDK = baseFetch<Http.JavaApiResult<Http.SpeedOrderNoResult>, { cdk: string }>({
  url: '/cdk/exchange',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询当前加速状态
 * @method get
 */
export const fetchCheckSpeedStatus = baseFetch<Http.JavaApiResult<Http.TSpeedStatus>, { speedNo: string }>({
  url: '/user/speed/state/query',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 加速状态上报
 */
export const reportSpeedStatus = baseFetch<Http.JavaApiResult<{ token: string }>, Http.TReportSpeedStatus>({
  url: '/user/speed/event/send',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 创建订单
 */
export const orderCreateHttp = baseFetch<Http.JavaApiResult<Http.TOrderCreateResult>, Http.TOrderCreatePayload>({
  url: '/order/create',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 发起支付
 */
export const paymentLaunchHttp = baseFetch<Http.JavaApiResult<string>, Http.TPaymentLaunchPayload>({
  url: '/pay/launch',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 检查订单是否支付
 */
export const orderCheckHttp = baseFetch<Http.JavaApiResult<{ payStatus: boolean }>, { orderNo: string }>({
  url: '/order/check',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 获取用户首充资格信息
 */
export const queryFirstRechargeQualification = baseFetch<Http.JavaApiResult<Http.FirstRechargeQualification>>({
  url: '/activity/firstRecharge/qualification',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 授权登录
 */
export const getAcctAuth = baseFetch<Http.JavaApiResult<Http.LoginResult>, { token: string; customerNo: string }>({
  url: '/anon/openapi/channel/user/login',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 领取活动赠送时长
 */
export const receiveActivityDuration = baseFetch<Http.JavaApiResult<boolean>, { activityNo: string }>({
  url: '/activity/receive/duration',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 查询是否使用红包
 */
export const hasUsedRedPacket = baseFetch<Http.JavaApiResult<boolean>, { activityNo: string }>({
  url: '/has/used/redPacket',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 用户实名认证
 */
export const realNameCertify = baseFetch<Http.JavaApiResult<boolean>, { realName: string; idCardNo: string }>({
  url: '/user/real-name/certify',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 提交人工实名信息
 */
export const realNameManual = baseFetch<
  Http.JavaApiResult<boolean>,
  {
    realName: string
    idCardNo: string
    certificatesType: TcertificatesType
    certificatesImage: string
  }
>({
  url: '/user/real-name/manual',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/**
 * @description 预结算
 */
export const getPreOrderAmtHttp = baseFetch<
  Http.JavaApiResult<{ payAmt: number }>,
  { goodsId: number; payMode: 'wechat_scan' | 'alipay_scan' | 'payermax' | 'paypal'; activityNo?: string }
>({
  url: '/anon/order/amt',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取支付相关配置 */
export const getPayMethodConfigHttp = baseFetch<Http.JavaApiResult<{ enableWechatPay: number; overPriceRate: number }>>(
  {
    url: '/anon/pay/config/get',
    method: 'get',
    options: {
      baseURL: process.env.SG_APP_JAVA_API_HOST,
      resInterceptors: javaResultFilter,
    },
  },
)

/** 获取用户可用抽奖次数 */
export const getAvailableDrawCountHttp = baseFetch<Http.JavaApiResult<number>, { activityNo: string }>({
  url: '/user/availableDrawCount',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 抽奖 */
export const activityReceiveHttp = baseFetch<
  Http.JavaApiResult<{ identity: string; describe: string }>,
  { activityNo: string; userId: number }
>({
  url: '/activity/receive',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 查询活动奖品发货状态 */
export const lotteryPrizeDrawHttp = baseFetch<Http.JavaApiResult<string>, { detailNo: string }>({
  url: '/activity/lottery/prize/draw',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 保存用户游戏修改器的配置 */
export const getCheatsConfigByTrainerIdHttp = baseFetch<
  Http.JavaApiResult<Modifier.TSaveCheatsConfigHttp['cheatsList']>,
  { trainerId: number }
>({
  url: '/cheats/config/get/by/trainerid',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 保存用户游戏修改器的配置 */
export const saveCheatsConfigHttp = baseFetch<Http.JavaApiResult<any>, Modifier.TSaveCheatsConfigHttp>({
  url: '/save/cheats/config/launch',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 修改用户昵称 */
export const updateNicknameHttp = baseFetch<Http.JavaApiResult<Modifier.IBaseUserInfo>, { newNickName: string }>({
  url: '/user/update/nickname',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 用户绑定邮箱、手机号 */
export const userBindIdentity = baseFetch<
  Http.JavaApiResult<Modifier.IBaseUserInfo>,
  Modifier.TUserBindIdentityPayload
>({
  url: '/user/bind/info',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

// 发送手机验证码
export const verificationPhoneCodeHttp = baseFetch<
  Http.JavaApiResult<string>,
  { mobile: string; action: 'LOGIN' | 'UP_PASS' | 'REG' | 'BIND' | 'CHECK_IDENTITY' }
>({
  url: '/anon/mobiel/verification/code',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

// 检查账号是否注册过
export const accountIsRegistryHttp = baseFetch<
  Http.JavaApiResult<boolean>,
  { account: string; accountType: 'email' | 'account' | 'mobile' }
>({
  url: '/anon/user/isExist',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

// 发送邮箱验证码
export const verificationEmailCodeHttp = baseFetch<
  Http.JavaApiResult<string>,
  { email: string; action: 'LOGIN' | 'UP_PASS' | 'REG' | 'BIND' | 'CHECK_IDENTITY' }
>({
  url: '/verificationCode',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 登录 */
export const loginHttp = baseFetch<
  Http.JavaApiResult<Modifier.TLoginResult>,
  { account: string; password: string; accountType: 'email' | 'account' }
>({
  url: '/login',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: data => javaResultFilter(data, { warnToast: false }),
  },
})

/** 获取用户基本信息 */
export const getUserBaseInfoHttp = baseFetch<Http.JavaApiResult<Modifier.IBaseUserInfo>>({
  url: '/user/baseInfo',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 注册 */
export const registerHttp = baseFetch<Http.JavaApiResult<string>, Modifier.TRegisterPayload>({
  url: '/register',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 通过邮箱、手机号、密码等方式校验用户身份 */
export const verificationIdentity = baseFetch<
  Http.JavaApiResult<{ ticket: string; account: string }>,
  Modifier.TVerificationIdentity
>({
  url: '/anon/user/check/identity',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 修改密码 */
export const userUpdatePassword = baseFetch<
  Http.JavaApiResult<Modifier.IBaseUserInfo>,
  { ticket: string; newPassword: string }
>({
  url: '/anon/user/update/password',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 用户换绑邮箱、手机号 */
export const userReplaceBindIdentity = baseFetch<
  Http.JavaApiResult<Modifier.IBaseUserInfo>,
  Modifier.TUserBindIdentityPayload & { ticket: string }
>({
  url: '/user/change/bind/info',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 兑换CDK */
export const redeemCDKHttp = baseFetch<Http.JavaApiResult<number[]>, { cdk: string }>({
  url: '/cdk/exchange',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 兑换CDK的历史记录 */
export const redeemCDKRecordHttp = baseFetch<
  Http.JavaApiResult<Http.BaseMultPageResult<Modifier.TRedeemCDKRecordResult>>
>({
  url: '/cdk/exchange/page',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 订阅资源 */
export const subscribeResourceHttp = baseFetch<
  Http.JavaApiResult<Http.BaseMultPageResult<Modifier.TRedeemCDKRecordResult>>,
  { resourceId: number | string }
>({
  url: '/userGameSubscribe/subscribe',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 取消订阅资源 */
export const unsubscribeResourceHttp = baseFetch<
  Http.JavaApiResult<Http.BaseMultPageResult<Modifier.TRedeemCDKRecordResult>>,
  { resourceId: number | string }
>({
  url: '/userGameSubscribe/unSubscribe',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取我订阅的资源类型下拉 */
export const getDropdownResourceTypeListByMySubHttp = baseFetch<Http.JavaApiResult<Resource.TResourceTypeItem[]>>({
  url: '/userGameSubscribe/dropDown/resourceType',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取我订阅的游戏模板下拉 */
export const getDropdownGameMasterListByMySubHttp = baseFetch<
  Http.JavaApiResult<Http.BaseMultPageResult<Resource.TGetGameMasterItem>>
>({
  url: '/userGameSubscribe/dropDown/gameLibraryMaster',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取母版下的资源列表 */
export const getResourceListByMySub = baseFetch<
  Http.JavaApiResult<Resource.TGetResourceListByGameMasterIdResult>,
  Resource.TGetResourceListByMySubPayload
>({
  url: '/userGameSubscribe/search',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 查询用户是否领取过 */
export const queryActivityHasReceiveDurationHttp = baseFetch<Http.JavaApiResult<boolean>, { activityNo: string }>({
  url: '/activity/has/receive/duration',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 领取会员时长 */
export const receiveActivityVipDurationHttp = baseFetch<Http.JavaApiResult<boolean>, { activityNo: string }>({
  url: '/activity/receive/duration',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 领取会员时长 */
export const userFeedbackHttp = baseFetch<Http.JavaApiResult<boolean>, Http.TUserFeedbackParams>({
  url: '/user/feed/back',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 启动修改 */
export const injectStartHttp = baseFetch<Http.JavaApiResult<{ startNo: string }>, { gameId: string | number; type:'cloud'|'client' }>({
  url: '/inject/start',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 修改心跳 */
export const injectHeartBeatHttp = baseFetch<
  Http.JavaApiResult<{
    injectResultCode: string | null
    injectResultDesc: string | null
    status: 'buffing'
  }>,
  { startNo: string | number }
>({
  url: '/inject/heart/beat/notice',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 停止修改 */
export const injectStatusChangeHttp = baseFetch<
  Http.JavaApiResult<boolean>,
  { startNo: string | number; injectStatusEnum: 'stop_buff'; code: string; message: string }
>({
  url: '/inject/status/change/notice',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 云游戏-主动停止云游戏计费 */
export const cloudgameStopHttp = baseFetch<
  Http.JavaApiResult<null>,
  Pick<Http.TCloudGameConnectQuery, 'gameCloudOrderNo'> & { reason?: string }
>({
  url: '/api/user/game/cloud/stop',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: (data?) => javaResultFilter(data),
  },
})


/** 试玩游戏-换取管家token */
export const loginGJByMobile = baseFetch<
  Http.JavaApiResult<{ token:string }>,
  { token: string; channel: string }
>({
  url: '/game/trial/anon/sgt/user/loginByMobile/v2',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 获取管家用户信息 */
export const getGJUserInfo = baseFetch<
  Http.BoxApiResult<{ cloudArchiveIdentify:string }>,
  {token:string}
>({
  url: '/game/trial/api/me',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: phpResultFilter,
  },
})

/** 试玩记录 */
export const getTrialRecord = baseFetch<
  Http.JavaApiResult<Http.BaseMultPageResult<ITrialRecord>>,
  {pageSize:number; pageIndex:number}
>({
  url: '/trial/game/play/record/get',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})


/** 根据启动订单查游戏详情 */
export const getUserOrderInfo = baseFetch<
  Http.BoxApiResult<{game_name:string;}>,
  {order_no:string}
>({
  url: '/game/trial/api/getUserOrderInfo',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: phpResultFilter,
  },
})

export const getPlayGameConfig = baseFetch<
  Http.JavaApiResult<TPlayGameConfig>,
  {gameId: number}
>({
  url: '/game/trial/api/get/playgameConfig',
  method: 'get',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: javaResultFilter,
  },
})

/** 绑定cs和target */
export const bindCsAndPayTargetHttp = baseFetch<Http.JavaApiResult<string>, {cs: string, url: string}>({
  url: '/pay/launch/mapping',
  method: 'post',
  options: {
    baseURL: process.env.SG_APP_JAVA_API_HOST,
    resInterceptors: (data?) => javaResultFilter(data),
  },
})