class CefTransfer {
  instance: Window['FuluApp'] | null
  UID = 0
  js2CppCallback = new Map()
  cpp2JsCallback = new Map<CEF.cpp2jsMethodType, Array<(response: any) => void>>()

  constructor() {
    this.instance = window.FuluApp
    this.receiveFromClient()
  }

  subscribeClientMsg<T = any>(method: CEF.cpp2jsMethodType, callback: (response: T) => void) {
    this.cpp2JsCallback.set(method, [...(this.cpp2JsCallback.get(method) || []), callback])
  }

  receiveFromClient() {
    if (this.instance) {
      this.instance!.RegisterCppNotifyCppFunc('cpp2js_client_msg', result => {
        console.debug('=> cpp2js_client_msg', result)
        const finalResult = JSON.parse(result)
        if (finalResult.method) {
          /** 客户端主动推送 */
          this.cpp2JsCallback.get(finalResult.method)?.forEach(callback => callback(finalResult.params))
        }
        if (finalResult.id) {
          /** 请求客户端，客户端返回的结果 */
          this.js2CppCallback.has(finalResult.id) && this.js2CppCallback.get(finalResult.id)(finalResult.params)
        }
      })
    }
  }

  sendToClient<T = any>(
    method: CEF.js2cppMethodType,
    params?: Record<string, any>,
    { timeout = 0 }: { timeout?: number } = {},
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.instance?.JSNotifyCppFunc) {
        return reject('not found "JSNotifyCppFunc"')
      }
      const uid = ++this.UID
      const sendData = {
        id: uid,
        method,
        params,
        jsonrpc: '2.0',
      }
      let timer: number | null = null
      if (timeout) {
        timer = window.setTimeout(() => {
          this.js2CppCallback.delete(uid)
          reject('timeout')
        }, timeout)
      }
      console.log('js2cpp_client_msg =>', sendData)
      this.js2CppCallback.set(uid, (res: T) => {
        if (timer) {
          window.clearTimeout(timer)
          timer = null
        }
        this.js2CppCallback.delete(uid)
        resolve(res)
      })
      this.instance.JSNotifyCppFunc('js2cpp_client_msg', JSON.stringify(sendData))
    })
  }
}

export default new CefTransfer()
