import { sessionStorageTool } from './storage'
import { SESSIONSTORAGE_KEYS } from 'src/constants/storage'
import { sha256 } from 'js-sha256'
import md5 from 'js-md5'

/** 手机号验证 */
export const mobilePattern = /^1\d{2}\s?\d{4}\s?\d{4}\s?$/

/** 获取短信验证码签名 */
/** 算法: 先反序、再sha256、再反序、再md5 */
export const signatureEncode = (str: string) => {
  const timeR = String(str).split('').reverse().join('')
  const timeSha256R = sha256(timeR).split('').reverse().join('')
  return md5(timeSha256R)
}

/** 秒转 hh:mm:ss */
export function formatTime(timestamp: number) {
  if (typeof timestamp !== 'number') {
    return ['00', '00', '00']
  }
  const second = (timestamp / 1000) | 0
  const h = (second / 60 / 60) | 0
  const m = ((second % (60 * 60)) / 60) | 0
  const s = second % 60 | 0
  return [h, m, s].map(a => a.toString().padStart(2, '0'))
}

/** 秒转 x小时x分钟x秒 */
export function formatTimeLocal(timestamp: number) {
  const unit=['小时','分钟','秒']
  const time= formatTime(timestamp*1000).map((value,i) => {
    return {
      value,
      unit:unit[i]
    }
  })
  let index= time.findIndex(v => v.value!=='00')
  time.splice(0,index)
  const temp= time.reverse()
  index = temp.findIndex(v => v.value!=='00')
  temp.splice(0,index)
  return temp.reverse().map((v,i)=>{
    return `${Number(v.value)}${v.unit}`
  }).join('')
}

/**
 * 判断当前版本是否大于指定版本
 * @param ver 指定版本
 */
export const CompareWithCurrentVersion = (ver: [number, number, number, number]) => {
  let indexViewUrl = sessionStorageTool.get(SESSIONSTORAGE_KEYS.INDEX_URL)
  if (!indexViewUrl) {
    sessionStorageTool.set(SESSIONSTORAGE_KEYS.INDEX_URL, location.href)
    indexViewUrl = location.href
  }
  const url = new URL(indexViewUrl)
  let version = url.searchParams.get('ver')
  if (version) {
    const CurrentVersion = version.split('.').map(d => Number(d))
    return CompareVersion(CurrentVersion, ver)
  }
  return false
}

/**
 * 比较两个版本大小
 * @param current 当前版本
 * @param ver 指定版本
 * @return boolean
 */
export const CompareVersion = (current: number[], ver: number[]) => {
  let VersionIsNew = false
  for (let i = 0; i < current.length; i += 1) {
    //当前版本大于指定版本
    if (current[i] > ver[i]) {
      VersionIsNew = true
      break
    } else if (current[i] < ver[i]) {
      VersionIsNew = false
      break
    }
  }
  return VersionIsNew
}


/**
 * 格式化数字
 * @param n 要格式化的数字
 * @param fractionDigits 保留的小数位
 */
export const toFixedOrInt=(n:string|number,fractionDigits: number=2)=>{
  const num=Number(n)
  if(isNaN(num)){
    return 0
  }
  return Number(num.toFixed(fractionDigits))
}

/** 计算文本长度 */
export const getTextWidth = (text: string, style?: Partial<{ fontSize: string; fontWeight: string }>) => {
  const DOM = document.createElement('span')
  if (style) {
    DOM.style.fontSize = style.fontSize || '12px'
    DOM.style.fontWeight = style.fontWeight || '400'
    DOM.style.whiteSpace = 'nowrap'
  }
  DOM.innerText = text
  document.body.appendChild(DOM)
  const width = DOM.offsetWidth
  document.body.removeChild(DOM)
  return width
}

/**
 根据身份证号码判断性别
 15位身份证号码：第7、8位为出生年份(两位数)，第9、10位为出生月份，第11、12位代表出生日
 18位身份证号码：第7、8、9、10位为出生年份(四位数)，第11、第12位为出生月份，
 第13、14位代表出生日期，第17位代表性别，奇数为男，偶数为女。
 */
//根据身份证号获取年龄
export const getAge = (identityCard: string): number => {
  const len = (identityCard + '').length
  let strBirthday = ''
  if (len === 18) {
    //处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday = identityCard.substr(6, 4) + '/' + identityCard.substr(10, 2) + '/' + identityCard.substr(12, 2)
  }
  if (len === 15) {
    let birthdayValue = ''
    birthdayValue = identityCard.charAt(6) + identityCard.charAt(7)
    if (parseInt(birthdayValue) < 10) {
      strBirthday =
        '20' + identityCard.substr(6, 2) + '/' + identityCard.substr(8, 2) + '/' + identityCard.substr(10, 2)
    } else {
      strBirthday =
        '19' + identityCard.substr(6, 2) + '/' + identityCard.substr(8, 2) + '/' + identityCard.substr(10, 2)
    }
  }
  //时间字符串里，必须是“/”
  const birthDate = new Date(strBirthday)
  const nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}
