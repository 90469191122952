import React from 'react'
import { CLOUD_GAME_EXIT_TYPE } from 'src/pages/game-detail/cloud-game/cloud-game-helper'
import { VipStore } from 'src/store'
import PostMessageController from 'src/pages/game-detail/cloud-game/post-message-controller'
import useCloudGameStart from 'src/pages/game-detail/cloud-game/cloud-game-action'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import CEFTransfer from 'src/CEFTransfer'



export const FrameOrigins = {
  // development: 'http://localhost:6789',
  development: 'https://cloud-game-universal.test.zuhaohao.com',
  test: 'https://cloud-game-universal.test.zuhaohao.com',
  preview: 'https://cloud-game-universal.prd.zuhaohao.com',
  production: 'https://cloudgame.haishagame.com',
}

type PostMessage = {
  message: any
  loaded: any
  set_full_screen: any
  'cloud-page-load-complete': any
  'hide-cloud-game-view': any
  'request-exit': any
  'full-screen-change': any
  'start-finished': any
  'start-progress': any
  switch_game: any
  user_panels: any
  'begin-cloud-time': any
  'request-unlock-pointer': any
  'view-system-tips': any
}


const clientPostMessage = new PostMessageController()

export const initPostMessage = () => {
  /** 切换全屏 */
  // clientPostMessage.subscribe('set_full_screen', () => {
  //   const status = CloudGameClientStore.windowStatus
  //   console.debug(
  //     `%c 接收到云游戏窗口切换全屏请求, current : ${status}  target: ${status === 2 ? 0 : 2}`,
  //     'font-size:20px;font-weight:bold;color:#7fea2d;',
  //   )
  //   changeWindowStatus(status => {
  //     clientPostMessage.post({code:'full-screen-change' ,status })
  //   })
  // })

  /** 退出游戏 */
  clientPostMessage.subscribe('hide-cloud-game-view', ({ data }) => {
    console.log('hide-cloud-game-view',data)
    const {reason, needRequest} = data
    const { stopCloudGame } = useCloudGameStart()
    stopCloudGame({ needRequest, reason: reason.reason })
    if (reason.code !== CLOUD_GAME_EXIT_TYPE.USER_OPTED_OUT.code) {
      if (reason.code === CLOUD_GAME_EXIT_TYPE.SDK_DISCONNECT) {
        setTimeout(() => {
          CloudGamePlayStore.cloudGameStartCrash = reason
        }, 1000)
      } else {
        CloudGamePlayStore.cloudGameStartCrash = reason
      }
    }
  })

  /** 云游戏页面加载完成 */
  clientPostMessage.subscribe('cloud_page_load_complete', () => {
    console.log('清除页面打开超时定时器',CloudGamePlayStore.cloudGamePageLoadTimer)
    CloudGamePlayStore.cloudPageStatus = true
    CloudGamePlayStore.cloudGamePageLoadTimer && clearTimeout(CloudGamePlayStore.cloudGamePageLoadTimer)
    CloudGamePlayStore.cloudGamePageLoadTimer = null
  })

  /** 点击退出游戏 */
  clientPostMessage.subscribe('request-exit', () => {
    CEFTransfer.sendToClient('showWindowTop')
    CloudGamePlayStore.visibleExitConfirmModal = true
  })

  /** 点击打开主界面 */
  clientPostMessage.subscribe('show-main-window', () => {
    console.log('show-main-window')
    CEFTransfer.sendToClient('showWindowTop')
  })

  /** 更新用户云游戏时长 */
  clientPostMessage.subscribe('update-user-cloud-time', (data) => {
    console.log('update-user-cloud-time',data?.data?.overtime)
    data?.data?.overtime && (VipStore.cloudGameRemainingTimeInRealTime=data.data.overtime)
  })
}

export default clientPostMessage
