type listener = (data: any) => void

interface Subscriptions {
  [key: string]: listener[]
}

type DataChannel = { code: number, msg: string, sendMessage: (message: any) => void }

/**
 * tcg自定义数据通道
 */
export class TcgDataChannel {

  dataChannel: DataChannel | null = null
  subscriptions: Subscriptions = {}

  constructor(port: number = 15000) {
    this.create(port)
  }

  // 接收云端数据的回调
  private onMessage(msg: any) {
    console.log('收到云端应用回传数据:', msg)
    if (typeof msg.code === 'string' && this.subscriptions[msg.code]) {
      this.subscriptions[msg.code].forEach((f) => f(msg))
    }
  }

  private async create(port: number) {
    // 定时重复创建直到成功
    const result = await new Promise<DataChannel>((resolve, reject) => {
      const timer = setInterval(async _ => {
        // 创建数据通道
        const ret = await TCGSDK.createCustomDataChannel({
          destPort: port,  //destPort: xxxx ，xxxx端口范围为10000～20000
          onMessage: this.onMessage
        })
        if (ret.code === 0) {
          resolve(ret)
          clearInterval(timer)
        }
      }, 2000)// 2秒间隔
    })
    console.log('createCustomDataChannel result', result)
    /*
    * 判断是否成功
    * result的结构{code: number, msg: string, sendMessage: Function }
    */
    if (result.code === 0) {
      // 随便发送一个绑定包，使云端应用的UDP服务能获得代理端口
      result.sendMessage('test')
    }
    this.dataChannel = result
  }

  send(data: any) {
    if (!this.dataChannel) {
      console.error('数据通道未连接成功')
      return
    }
    try {
      const msg = typeof data === 'string' ? data : JSON.stringify(data)
      console.log('dataChannel sendMessage', msg)
      this.dataChannel?.sendMessage(msg)
    } catch (e) {
      console.log('dataChannel send err', e)
    }
  }

  subscribe(code: string, listener: listener) {
    this.subscriptions[code] = (this.subscriptions[code] || []).filter((c) => c !== listener).concat(listener)
  }

}




