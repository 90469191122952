import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Input } from 'antd'
import { BaseModal } from 'src/components/base-modal'
import { BaseInput } from 'src/components/base-input'
import { ConfirmButton } from 'src/components/base-button'
import { ProtocolStore } from 'src/store/modifier'
import { FadeinImage } from 'src/components/base-image'
import CEFTransfer from 'src/CEFTransfer'
import commonDefaultProcessImg from 'src/assets/images/common/common-default-process.png'
import EllipsisIcon from 'src/assets/icons/modal/ellipsis.svg'
import styled from 'styled-components'
import classNames from 'classnames'

type TFindProcessModal = {
  processObject?: { _res: Function; _rej: Function; list: Modifier.TProcessItem[] }
}

let only = false
const FindProcessModal: FC<TFindProcessModal> = ({ processObject }) => {
  const [activeProcess, setActiveProcess] = useState<Modifier.TProcessItem>()
  const [filePath, setFilePath] = useState<string>('')
  const handleSelectedProcessFile = async (callback?: Function) => {
    if (only) {
      return
    }
    only = true
    const result = await CEFTransfer.sendToClient('selectFile')
    console.log(result, 'result')
    if (result && !result.canceled) {
      setFilePath(result.filePaths)
      // callback?.()
    }
    only = false
  }
  const handleSelectedPath = () => {
    if (filePath && processObject) {
      processObject._res({
        type: 'path',
        path: filePath,
        exeName: filePath.split('\\').pop()!,
      })
    }
    // else {
    //   handleSelectedProcessFile(handleSelectedPath)
    // }
  }
  useEffect(() => {
    return () => {
      setActiveProcess(undefined)
      setFilePath('')
    }
  }, [processObject])
  return (
    <StyledFindProcessModalContainer
      visible={!!processObject}
      width={450}
      onCancel={() => processObject?._rej('取消弹框')}
    >
      <div className='find-process-header'>
        <div className='title'>选择正确的游戏进程</div>
        <div className='extra' onClick={() => ProtocolStore.openProtocolModal('process-desc')}>
          游戏进程查找办法
        </div>
      </div>
      <div className='find-process-content'>
        {processObject?.list.map(d => {
          return (
            <div
              className={classNames('find-process-item', { active: activeProcess?.pid === d.pid })}
              key={d.pid}
              onClick={() => setActiveProcess(d)}
            >
              <div className='find-process-item-left'>
                <FadeinImage width={30} height={30} style={{minWidth: 30, marginRight: 12}} src={d.icoPath || commonDefaultProcessImg} type='notDefault' />
                <div className='find-process-item-name ellipsis'>{d.exeFile}</div>
              </div>
              <div className='find-process-item-right'>
                <ConfirmButton
                  type='primary'
                  onClick={() => processObject._res({ type: 'name', exeName: d.exeFile, pid: d.pid })}
                >
                  选择
                </ConfirmButton>
              </div>
            </div>
          )
        })}
      </div>
      <div className='find-process-help-row'>
        <div className='notfound'>未找到游戏进程？</div>
        <div className='manualfind' onClick={() => handleSelectedProcessFile()}>
          手动添加游戏文件
        </div>
      </div>
      <div className='find-process-footer'>
        <BaseInput readOnly value={filePath}>
          <div className='select-icon' onClick={() => handleSelectedProcessFile()}>
            <EllipsisIcon />
          </div>
        </BaseInput>
        <ConfirmButton disabled={!filePath} type='primary' onClick={() => handleSelectedPath()}>
          选择此路径
        </ConfirmButton>
      </div>
    </StyledFindProcessModalContainer>
  )
}

const StyledFindProcessModalContainer = styled(BaseModal)`
  .ant-modal-body {
    padding: 40px 40px 50px;
  }
  .find-process-footer {
    display: flex;
    margin-top: 20px;
    .select-icon {
      width: 47px;
      height: 30px;
      background: #585861;
      border-radius: 0px 4px 4px 0px;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${props => props.theme.color.text.$999999};
      cursor: pointer;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &:hover {
        filter: brightness(1.1);
      }
    }
    .sg-base-input {
      padding-right: 54px;
      padding-left: 10px;
    }
    .sg-base-input-warpper {
      width: 258px;
      height: 30px;
      border-radius: 4px;
    }
    .ant-btn-primary {
      width: 100px;
      height: 30px;
      margin-left: 12px;
      &:hover {
        width: 100px;
        height: 30px;
        margin-left: 12px;
      }
    }
  }
  .find-process-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: var(--text-color-FFFFFF);
    }
    .extra {
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.color.text.$C598FF};
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
  .find-process-content {
    margin-top: 24px;
    width: 370px;
    height: 351px;
    background: #44444a;
    border-radius: 6px;
    padding: 10px 0;
    overflow: overlay;
    .find-process-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0 20px;
      position: relative;
      &.active,
      &:hover {
        background: rgba(200, 152, 255, 0.2);
      }
      &.active {
        &::before {
          content: ' ';
          width: 2px;
          height: 40px;
          background: #d0a7ff;
          position: absolute;
          left: 0;
          top: 0;
        }
        .find-process-item-right {
          .ant-btn-primary {
            display: flex;
          }
        }
      }
      .find-process-item-left {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        color: var(--text-color-FFFFFF);
        max-width: 264px;
        .find-process-item-name {
          max-width: 216px;
        }
        .true-img {
          max-width: 30px;
          max-height: 30px;
          border-radius: 2px;
        }
      }
      .find-process-item-right {
        .ant-btn-primary {
          ${props => props.theme.flexCenter}
          width: 40px;
          height: 20px;
          font-size: 12px;
          padding: 0;
          display: none;
        }
      }
    }
  }
  .find-process-help-row {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 14px;
    .notfound {
      color: ${props => props.theme.color.text.$FFFFFF86};
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
    .manualfind {
      color: ${props => props.theme.color.text.$C598FF};
      cursor: pointer;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
`

export default observer(FindProcessModal)
