import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ConfirmButton } from 'src/components/base-button'
import { FadeinImage } from 'src/components/base-image'
import styled from 'styled-components'

const PlatformWelfare: FC<{
  platformWelfares: Game.TPlatformWelfare[]
  toReceivePlatformWelfare: (target: string, gameName: string) => void
}> = ({ platformWelfares, toReceivePlatformWelfare }) => {
  console.log(toReceivePlatformWelfare, 'toReceivePlatformWelfare')
  return (
    <StyledPlatformWelfareContainer>
      {platformWelfares.map(d => {
        return (
          <StyledPlatformWelfareItemBox key={d.gameName}>
            <div className='platform-welfare-left'>
              <FadeinImage className='platform-welfare-img' width={360} height={168} src={d.image} />
              <div className='platform-welfare-info'>
                <div className='platform-welfare-name'>{d.gameName}</div>
                <div className='platform-welfare-area-time'>
                  {d.country && <>限免地区：{d.country} </>}
                  限免截止日期：{d.permanent === 1 ? '永久免费' : d.endDate}
                </div>
                <div className='platform-welfare-desc'>{d.description}</div>
              </div>
            </div>
            <div className='platform-welfare-right'>
              <ConfirmButton onClick={() => toReceivePlatformWelfare(d.locationUrl, d.gameName)}>
                前往领取
              </ConfirmButton>
            </div>
          </StyledPlatformWelfareItemBox>
        )
      })}
    </StyledPlatformWelfareContainer>
  )
}

const StyledPlatformWelfareItemBox = styled.div`
  width: 100%;
  height: 168px;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  overflow: hidden;
  &:hover {
    filter: brightness(1.1);
  }
  .platform-welfare-left {
    display: flex;
    align-items: center;
    .platform-welfare-img {
      .true-img {
        object-fit: cover;
      }
    }
    .platform-welfare-info {
      flex: 1;
      margin-left: 40px;
      margin-right: 70px;
      .platform-welfare-name {
        font-size: 20px;
        font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
        line-height: 23px;
      }
      .platform-welfare-area-time {
        margin-top: 8px;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #c38cff;
        line-height: 24px;
      }
      .platform-welfare-desc {
        margin-top: 24px;
        width: 100%;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
`

const StyledPlatformWelfareContainer = styled.div`
  padding: 32px;
  height: 100%;
  overflow: auto;
`

export default observer(PlatformWelfare)
