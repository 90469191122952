import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { appStore, SpeedStore, VipStore } from 'src/store'
import { LoginStore } from 'src/store/modifier'
import { message } from 'antd'
import { fetchGameInfo, fetchThirdGameInfo } from 'src/apis/game-api'
import { MainTestButton } from 'src/components/base-button'
import { doStartSpeed, startSpeed } from 'src/tools/speed'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { useNavigate } from 'react-router-dom'
import { DOMID } from 'src/constants/common'
import CheckNode, { TAreaServer } from 'src/pages/mini/game-detail/speed/check-node'
import SpeedSuccess from 'src/pages/mini/game-detail/speed/speed-success'
import styled from 'styled-components'
import StartBtnIcon from 'src/assets/icons/my-game/start-btn.svg'
import PauseBtnIcon from 'src/assets/icons/my-game/pause-btn.svg'
import SwitchBtnIcon from 'src/assets/icons/my-game/switch-btn.svg'
import classNames from 'classnames'

type TBtnState = 'hover' | 'leave' | 'start' | 'speeding' | 'speeded' | 'normal'
type TDetailStatus = 'change-area-server'

const GameDetail: FC<{
  speedGames: Game.BranchAssociatedDataSpeedGame[]
  changeSearch: (state: Record<string, string>) => void
  gameLibraryName: string
  gamePlatformName: string
}> = ({ speedGames, changeSearch, gameLibraryName, gamePlatformName }) => {
  const Query = new URLSearchParams(location.search)

  const CheckNodeRef = useRef<{ onConfirm: () => { areaServerInfo: TAreaServer; nodeInfo: CEF.TNodeInfo } }>(null)
  const navigate = useNavigate()
  const [currentSpeedGame, setCurrentSpeedGame] = useState<Game.BranchAssociatedDataSpeedGame | undefined>(() => {
    const defaultSpeedGameNo = Query.get('speedGameNo')
    if (defaultSpeedGameNo && speedGames.length > 0 && speedGames.find(f => f.speedGameNo === defaultSpeedGameNo)) {
      return speedGames.find(f => f.speedGameNo === defaultSpeedGameNo)
    }
    // if (!defaultSpeedGameNo && !!speedGames[0]) {
    return speedGames[0]
    // }
    // return undefined
  })
  const [btnState, setBtnState] = useState<TBtnState>('normal')
  const [thirdInfo, setThirdInfo] = useState<Http.ThirdGameInfo>()
  const [gameInfo, setGameInfo] = useState<Http.GameInfo>()
  const [detailStatus, setDetailStatus] = useState<TDetailStatus>()
  const currentSpeedGameNo = currentSpeedGame ? currentSpeedGame.speedGameNo : ''
  const currentRelateIden = currentSpeedGame ? currentSpeedGame.relateIdens : ''
  const isCurrentSpeed = currentRelateIden === SpeedStore.startingSpeedInfo?.relateIdens
  const isLogin = !!LoginStore.userinfo
  const isSpeeding = isCurrentSpeed && SpeedStore.speedStatus === 'speeding' && SpeedStore.speedingInfo
  const isStarting = isCurrentSpeed && SpeedStore.speedStatus === 'starting'
  const loadInitData = async (relateIden: string, speedGameNo: string) => {
    const [thirdInfo, gameInfo] = await Promise.all([
      fetchThirdGameInfo({ speedGameNo }),
      fetchGameInfo({ speedGameNo: relateIden }),
    ])
    setThirdInfo(thirdInfo.result)
    setGameInfo(gameInfo.result)
  }

  const handleSetCurrentSpeedGame = (value: Game.BranchAssociatedDataSpeedGame) => {
    setCurrentSpeedGame(value)
    changeSearch({
      speedGameNo: value.speedGameNo,
    })
  }

  const handleStartSpeed = async (startType?: 'start' | 'change') => {
    if (!CheckNodeRef.current || !gameInfo || !thirdInfo) {
      return
    }
    if (startType === 'change') {
      SpeedStore.isSwitchSpeed = true
    }
    try {
      const { areaServerInfo, nodeInfo } = CheckNodeRef.current.onConfirm()
      await doStartSpeed({
        ...gameInfo,
        thirdInfo,
        gameLibraryName,
        gamePlatformName,
        areaInfo: areaServerInfo,
        nodeInfo,
        speedGameName: gameInfo?.name,
        startType,
        relateIdens: currentRelateIden,
        modelCallback: startType === 'change' ? () => setDetailStatus(undefined) : undefined,
        clientType: 'cloud'
      })
    } catch (error) {
      message.warn((error as Error).message)
    }
  }

  const changeBtnState = (state: TBtnState) => {
    setTimeout(() => {
      setBtnState(state)
    }, 10)
  }

  const handleStopSpeed = () => {
    imperativeConfirmModal({
      title: '停止加速提醒',
      content: '停止加速前请确认您已结束正在加速的游戏，直接停止可能造成您正在加速的游戏掉线！',
      cancelText: '取消',
      okText: '确认停止',
      onOk() {
        const speedInfo = SpeedStore.hasSpeeding()
        SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_STOP' })
        message.success('停止加速成功')
        if (
          speedInfo &&
          appStore.feedbackController.counter.speed === 0 &&
          appStore.feedbackController.visible === false
        ) {
          appStore.feedbackController.counter.speed = 1
          appStore.feedbackController.type = 'speed'
          appStore.feedbackController.visible = true
          appStore.feedbackController.context.platform = speedInfo.gamePlatformName || ''
          appStore.feedbackController.context.gameName = speedInfo.gameLibraryName || ''
          appStore.feedbackController.context.speedArea = speedInfo.speedAreaName || ''
          appStore.feedbackController.context.speedNode = speedInfo.speedNodeName || ''
          appStore.feedbackController.context.speedOperate = speedInfo.speedGameName || ''
        }
      },
    })
  }

  const LeftComponent = useMemo(() => {
    if (!thirdInfo || !currentSpeedGameNo) {
      return null
    }
    if (detailStatus === 'change-area-server') {
      return (
        <CheckNode
          onChangeDetailStatus={setDetailStatus}
          isSpeeding={true}
          ref={CheckNodeRef}
          speedGames={speedGames}
          setCurrentSpeedGame={handleSetCurrentSpeedGame}
          speedGameNo={currentSpeedGameNo}
          thirdGameNo={thirdInfo.thirdGameNo}
          gameLibraryName={gameLibraryName}
        />
      )
    }
    return isSpeeding ? (
      <SpeedSuccess onChangeDetailStatus={setDetailStatus} gameLibraryName={gameLibraryName} />
    ) : (
      <CheckNode
        onChangeDetailStatus={setDetailStatus}
        isSpeeding={false}
        ref={CheckNodeRef}
        speedGames={speedGames}
        setCurrentSpeedGame={handleSetCurrentSpeedGame}
        speedGameNo={currentSpeedGameNo}
        thirdGameNo={thirdInfo.thirdGameNo}
        gameLibraryName={gameLibraryName}
      />
    )
  }, [detailStatus, isSpeeding, CheckNodeRef, currentSpeedGameNo, thirdInfo?.thirdGameNo, speedGames, gameLibraryName])

  const mainButtonProps = useMemo(() => {
    if (isStarting) {
      return {
        children: (
          <StyledMainButtonContent>
            <StartBtnIcon className='icon' />
            <span>加 速 中</span>
          </StyledMainButtonContent>
        ),
        click: undefined,
        className: `start-btn-speeding`,
      }
    }
    if (isSpeeding && detailStatus === 'change-area-server') {
      return {
        children: (
          <StyledMainButtonContent>
            <SwitchBtnIcon className='icon' />
            <span>确 认 切 换</span>
          </StyledMainButtonContent>
        ),
        click: () => handleStartSpeed('change'),
        className: `start-btn-${btnState}`,
      }
    }
    if (isSpeeding && !detailStatus) {
      return {
        children: (
          <StyledMainButtonContent>
            <PauseBtnIcon className='icon' />
            <span>停 止 加 速</span>
          </StyledMainButtonContent>
        ),
        click: () => handleStopSpeed(),
        className: `start-btn-speeded`,
      }
    }

    if (SpeedStore.isSwitchSpeed && SpeedStore.checkedSpeedInfo?.speedGameNo === currentSpeedGameNo) {
      return {
        children: (
          <StyledMainButtonContent>
            <SwitchBtnIcon className='icon' />
            <span>切 换 中</span>
          </StyledMainButtonContent>
        ),
        click: undefined,
        className: `start-btn-speeding`,
      }
    }

    return {
      children: (
        <StyledMainButtonContent>
          <StartBtnIcon className='icon' />
          <span>一 键 加 速</span>
        </StyledMainButtonContent>
      ),
      click: () => handleStartSpeed(),
      className: `start-btn-${btnState}`,
    }
  }, [
    isStarting,
    isSpeeding,
    handleStartSpeed,
    btnState,
    detailStatus,
    SpeedStore.isSwitchSpeed,
    SpeedStore.checkedSpeedInfo,
    currentSpeedGameNo,
  ])

  useEffect(() => {
    if (currentRelateIden && currentSpeedGameNo) {
      loadInitData(currentRelateIden, currentSpeedGameNo)
    }
  }, [currentRelateIden, currentSpeedGameNo])

  // useEffect(() => {
  //   if (gameInfo?.image) {
  //     appStore.layoutBg = gameInfo.image
  //     return () => {
  //       appStore.layoutBg = null
  //     }
  //   }
  // }, [gameInfo])

  useEffect(() => {
    if (isCurrentSpeed && SpeedStore.startingSpeedInfo && SpeedStore.startingSpeedInfo.speedProgress > 0) {
      changeBtnState(SpeedStore.startingSpeedInfo?.speedProgress >= 100 ? 'speeded' : 'speeding')
    } else {
      changeBtnState('normal')
    }
  }, [SpeedStore.startingSpeedInfo?.speedProgress])

  // useEffect(() => {
  //   if (isLogin) {
  //     /** 单纯的刷新一下用户的会员状态 */
  //     VipStore.handleLoadVipInfo()
  //   }
  // }, [isLogin])

  if (!thirdInfo || !gameInfo || !currentSpeedGameNo) {
    return null
  }

  return (
    <StyledGameDetailContent>
      <StyledLeftContent id={DOMID.MINI_LAYOUT_CONTENT}>{LeftComponent}</StyledLeftContent>
      <StyledRightContent>
        <StyledMainButton onClick={mainButtonProps.click} className={classNames(mainButtonProps.className)}>
          {mainButtonProps.children}
        </StyledMainButton>
      </StyledRightContent>
    </StyledGameDetailContent>
  )
}


const StyledMainButton = styled(MainTestButton)`
  ${props => props.theme.flexCenter}
  .icon {
    margin-right: 16px;
  }
  .normal-row {
    ${props => props.theme.flexCenter}
  }
  .animate-normal-mark{
    z-index: initial;
  }
`

const StyledUtilsContainer = styled.div`
  margin-bottom: 14px;
  .util-item {
    ${props => props.theme.flexAlignCenter}
    justify-content: space-between;
    height: 22px;
    margin-bottom: 24px;
    .util-left {
      ${props => props.theme.flexAlignCenter}
      img {
        width: 24px;
      }
      .text {
        margin-left: 15px;
        font-size: 14px;
        line-height: 14px;
        color: ${props => props.theme.color.text.$F0F0F0};
      }
      .ecg-icon {
        path:nth-child(1) {
          transform: translateX(0px);
        }
        path:nth-child(2) {
          display: none;
          transform: translateX(-13px);
        }
        path:nth-child(3) {
          display: none;
          transform: translateX(13px);
        }
        &.active {
          path:nth-child(1) {
            animation: ecg1 1s linear 0s infinite;
          }
          path:nth-child(2) {
            display: block;
            animation: ecg2 1s linear 0s infinite;
          }
          path:nth-child(3) {
            display: block;
            animation: ecg3 1s linear 0s infinite;
          }
          @keyframes ecg1 {
            0% {
              transform: translateX(0px);
            }
            100% {
              transform: translateX(13px);
            }
          }
          @keyframes ecg2 {
            0% {
              transform: translateX(-13px);
            }
            100% {
              transform: translateX(0px);
            }
          }
          @keyframes ecg3 {
            0% {
              transform: translateX(13px);
            }
            100% {
              transform: translateX(26px);
            }
          }
        }
      }
      .wifi-icon {
        path:nth-child(2) {
          opacity: 0;
        }
        path:nth-child(3) {
          opacity: 0;
        }
        path:nth-child(4) {
          opacity: 0;
        }
        &.active {
          path:nth-child(1) {
            display: none;
          }
          path:nth-child(2) {
            animation: wifi3 1s linear 0s infinite;
          }
          path:nth-child(3) {
            animation: wifi2 1s linear 0s infinite;
          }
          path:nth-child(4) {
            animation: wifi1 1s linear 0s infinite;
          }
          @keyframes wifi1 {
            0% {
              opacity: 0;
            }
            19% {
              opacity: 0;
            }
            20% {
              opacity: 1;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes wifi2 {
            0% {
              opacity: 0;
            }
            39% {
              opacity: 0;
            }
            40% {
              opacity: 1;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes wifi3 {
            0% {
              opacity: 0;
            }
            59% {
              opacity: 0;
            }
            60% {
              opacity: 1;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
    .util-right {
      font-size: 14px;
      line-height: 14px;
      .sg-text-button-normal {
        ${props => props.theme.flexCenter}
      }
    }
  }
`

const StyledFQItem = styled.div`
  span {
    cursor: pointer;
    height: 34px;
    line-height: 34px;
    font-size: 12px;
    color: ${props => props.theme.color.text.$F0F0F0};
    position: relative;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${props => props.theme.color.bg.$999999};
    }
  }
`

const StyledMainButtonContent = styled.div`
  ${props => props.theme.flexAlignCenter}
  position: relative;
  z-index: 1;
`

const StyledFQContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  border-radius: 4px;
  padding: 4px 20px 14px;  
`

const StyledSpeedBalanceBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  font-size: 24px;
  line-height: 24px;
  font-family: Impact-Regular, Impact;
  color: ${props => props.theme.color.text.$10FAB8};
  font-weight: 400;
`

const StyledSectionFlexBox = styled.div`
  ${props => props.theme.flexAlignCenter}
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  margin-bottom: 16px;
  .buy-btn {
    ${props => props.theme.flexCenter}
    position: relative;
    width: 100px;
    height: 35px;
    background: rgba(195, 140, 255, 0.12);
    border-radius: 35px;
    font-size: 14px;
    color: var(--text-color-FFFFFF);
    padding-right: 10px;
    cursor: pointer;
    .arrows-icon {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      margin-left: 14px;
      &.arrows-hover-icon {
        opacity: 0;
        transition: right 300ms ease 0s;
      }
    }
    &:hover {
      color: ${props => props.theme.color.mainColor};
      .arrows-icon {
        margin-left: 14px;
        opacity: 0;
        &.arrows-hover-icon {
          opacity: 1;
          right: 10px;
        }
      }
    }
  }
`

const StyledVipFreeText = styled.div`
  .main {
    color: ${props => props.theme.color.mainColor};
  }
`

const StyledSectionBox = styled.div`
  margin-bottom: 30px;
  .title {
    ${props => props.theme.flexAlignCenter}
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 4px;
    color: var(--text-color-FFFFFF);
    position: relative;
    svg {
      margin-left: 10px;
    }
  }
  .title-extra {
    position: absolute;
    right: 0;
    bottom: 0;
    .sg-text-button-normal {
      font-size: 12px;
    }
  }
`

const StyledRightContent = styled.div`
  margin: 38px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLeftContent = styled.div`
  flex: 1;  
  margin: 38px 38px 0;
  overflow: hidden;
`

const StyledGameDetailContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`



export default observer(GameDetail)
