import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { Form, message } from 'antd'
import { verificationIdentity, userUpdatePassword, userReplaceBindIdentity } from 'src/apis/user-api'
import { EmailVerification, PhoneVerification, PWVerification } from 'src/components/base-verification'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { ConfirmButton, CancelButton } from 'src/components/base-button'
import { useIntl } from 'react-intl'
import Selector from 'src/components/base-selector'
import Step from 'src/components/base-steps'
import styled from 'styled-components'
import classNames from 'classnames'

type TReplaceBindModal = {
  visible: Modifier.TReplaceBindModalVisible | null
  verificationMethods: Modifier.TVerificationMethods[]
  userBaseInfo: Modifier.IBaseUserInfo | null
  onCancel: () => void
  onSuccess: () => void
}

const ReplaceBindModal: FC<TReplaceBindModal> = ({
  visible,
  verificationMethods,
  onCancel,
  userBaseInfo,
  onSuccess,
}) => {
  const [form] = Form.useForm()
  const [step, setStep] = useState<number>(0)
  const [verificationMethodValue, setVerificationMethodValue] = useState<number>(0)
  const [identity, setIdentity] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const currentVerificationMethod = verificationMethods[verificationMethodValue]
  const { formatMessage} = useIntl()
  const handleCancelModal = () => {
    onCancel()
    setStep(0)
    setIdentity(null)
    setVerificationMethodValue(0)
    form.resetFields()
  }
  const handleVerificationStep1 = async () => {
    if (!userBaseInfo) {
      return
    }
    const values = await form.validateFields()
    setLoading(true)
    let params: Modifier.TVerificationIdentity
    if (currentVerificationMethod.title === formatMessage({id:'bind.password'})) {
      params = {
        type: 'password',
        password: values.pw,
      }
    }
    if (currentVerificationMethod.title === formatMessage({id:'bind.email'}) && userBaseInfo.email) {
      params = {
        type: 'email',
        account: userBaseInfo.email,
        code: values.emailVerificationCode,
        action: 'CHECK_IDENTITY'
      }
    }
    if (currentVerificationMethod.title === formatMessage({id:'bind.mobile'}) && userBaseInfo.mobile) {
      params = {
        type: 'mobile',
        account: userBaseInfo.mobile,
        code: values.phoneVerificationCode,
        action: 'CHECK_IDENTITY'
      }
    }
    try {
      const { result: identity } = await verificationIdentity(params!)
      setIdentity(identity.ticket)
      setStep(1)
      form.resetFields()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleVerificationStep2 = async () => {
    if (!identity) {
      return
    }
    const values = await form.validateFields()
    setLoading(true)
    try {
      if (visible === formatMessage({id:'bind.password'})) {
        await userUpdatePassword({
          ticket: identity,
          newPassword: values.pw,
        })
        // 修改密码之后 帮他把当前记住的密码给清掉
        // 这里因为当前记住的账号一定是当前用户的 所以不用对比账号
        localStorageTool.remove(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD)
      }
      if (visible === formatMessage({id:'bind.mobile'})) {
        await userReplaceBindIdentity({
          type: 'mobile',
          ticket: identity,
          account: values.phone,
          code: values.phoneVerificationCode,
        })
      }
      if (visible === formatMessage({id:'bind.email'})) {
        await userReplaceBindIdentity({
          type: 'email',
          ticket: identity,
          account: values.email,
          code: values.emailVerificationCode,
        })
      }
      onSuccess()
      handleCancelModal()
      message.success(formatMessage({id:'bind.changeSuccess'}))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleChangedValues = async (changedValues: any) => {
    if (step === 1 && visible === formatMessage({id:'bind.password'}) && Object.keys(changedValues).includes('pw')) {
      await form.validateFields(['pwConfirm'])
    }
  }

  useEffect(() => {
    if (step === 0 && visible && userBaseInfo) {
      if (currentVerificationMethod.title === formatMessage({id:'bind.email'})) {
        console.log(userBaseInfo.email, 'userBaseInfo.email')
        form.setFieldsValue({
          email: userBaseInfo.email,
        })
      }
      if (currentVerificationMethod.title === formatMessage({id:'bind.mobile'})) {
        form.setFieldsValue({
          phone: userBaseInfo.mobile,
        })
      }
    }
  }, [step, visible, userBaseInfo, currentVerificationMethod])

  return (
    <StyledReplaceBindModalContainer
      visible={!!visible}
      title={visible === formatMessage({id:'bind.password'}) ? formatMessage({id:'bind.changePwd'}) : formatMessage({id:'bind.change'},{type:visible})}
      width={470}
      onCancel={() => handleCancelModal()}
    >
      <Step current={step} list={[
        { text: formatMessage({id:'bind.auth'}) },
        { text: formatMessage({id:'bind.setNew'},{type:visible})},
        { text: formatMessage({id:'bind.end'}) }
      ]} />
      <StyledBindModalContainer
        form={form}
        style={{ marginTop: 30 }}
        onValuesChange={changedValues => handleChangedValues(changedValues)}
      >
        {step === 0 && (
          <>
            <Selector
              style={{ marginBottom: 30 }}
              placeholder={formatMessage({id:'bind.authSelect'})}
              options={verificationMethods.map(d => formatMessage({id:'bind.authType'},{type:d.title}))}
              value={verificationMethodValue}
              onChange={setVerificationMethodValue}
            />
            {currentVerificationMethod.title === formatMessage({id:'bind.mobile'}) && (
              <PhoneVerification
                form={form}
                codeType='CHECK_IDENTITY'
                defaultPhone={userBaseInfo?.mobile}
                verificationCodePlaceholder='请输入您收到的手机验证码'
                verificationCodeEmptyMsg='验证码不能为空'
                verificationCodeErrorMsg='请填写正确格式的验证码'
              />
            )}
            {currentVerificationMethod.title === formatMessage({id:'bind.email'}) && (
              <EmailVerification
                form={form}
                codeType='CHECK_IDENTITY'
                defaultEmail={userBaseInfo?.email}
                verificationCodePlaceholder={formatMessage({id:'bind.email.placeholder'})}
                verificationCodeEmptyMsg={formatMessage({id:'bind.email.emptyMsg'})}
                verificationCodeErrorMsg={formatMessage({id:'bind.email.errorMsg'})}
              />
            )}
            {currentVerificationMethod.title === formatMessage({id:'bind.password'}) && (
              <PWVerification
                PWPlaceholder={formatMessage({id:'bind.pwd.placeholder'})}
                PWEmptyMsg={formatMessage({id:'bind.pwd.emptyMsg'})}
                PWErrorMsg={formatMessage({id:'bind.pwd.errorMsg'})}
                form={form}
              />
            )}
            <div className='bind-btns-row'>
              <CancelButton shape='circle' onClick={() => handleCancelModal()}>
                {formatMessage({id:'common.cancel'})}
              </CancelButton>
              <ConfirmButton
                type='primary'
                shape='circle'
                style={{ marginLeft: 10 }}
                onClick={() => handleVerificationStep1()}
                loading={loading}
              >
                {formatMessage({id:'bind.next'})}
              </ConfirmButton>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            {visible === formatMessage({id:'bind.mobile'}) && (
              <PhoneVerification
                phonePlaceholder='请填写想要绑定的新的手机号'
                phoneEmptyMsg='手机号不能为空'
                phoneErrorMsg='请填写正确格式的手机号'
                verificationCodePlaceholder='请输入您收到的手机验证码'
                verificationCodeEmptyMsg='验证码不能为空'
                verificationCodeErrorMsg='请填写正确格式的验证码'
                form={form}
                // verificationExistOptions={{
                //   hasExist: true,
                //   field: 'phone',
                //   callback: () => {
                //     message.warn('当前手机号已被其他账号绑定，确认绑定后系统将迁移绑定关系至当前账号')
                //   }
                // }}
                codeType='BIND'
                extraRules={[
                  {
                    validator(rules, value, callback) {
                      if (userBaseInfo?.mobile === value) {
                        return callback('换绑手机号不可与当前绑定手机一致')
                      }
                      callback()
                    },
                  },
                ]}
              />
            )}
            {visible === formatMessage({id:'bind.email'}) && (
              <EmailVerification
                emailPlaceholder={formatMessage({id:'change.email.placeholder'})}
                emailEmptyMsg={formatMessage({id:'change.email.emptyMsg'})}
                emailErrorMsg={formatMessage({id:'change.email.errorMsg'})}
                verificationCodePlaceholder={formatMessage({id:'change.email.placeholder2'})}
                verificationCodeEmptyMsg={formatMessage({id:'change.email.emptyMsg2'})}
                verificationCodeErrorMsg={formatMessage({id:'change.email.errorMsg2'})}
                form={form}
                // verificationExistOptions={{
                //   hasExist: true,
                //   field: 'email',
                //   callback: () => {
                //     message.warn('当前邮箱已被其他账号绑定，确认绑定后系统将迁移绑定关系至当前账号')
                //   }
                // }}
                codeType='BIND'
                extraRules={[
                  {
                    validator(rules, value, callback) {
                      if (userBaseInfo?.email === value) {
                        return callback(formatMessage({id:'change.email.same'}))
                      }
                      callback()
                    },
                  },
                ]}
              />
            )}
            {visible === formatMessage({id:'bind.password'}) && (
              <PWVerification
                form={form}
                isConfirm={true}
                PWPlaceholder={formatMessage({id:'change.pwd.placeholder'})}
                PWEmptyMsg={formatMessage({id:'change.pwd.emptyMsg'})}
                PWErrorMsg={formatMessage({id:'change.pwd.errMsg'})}
                PWConfirmPlaceholder={formatMessage({id:'change.pwd.placeholder2'})}
                PWConfirmEmptyMsg={formatMessage({id:'change.pwd.emptyMsg2'})}
                PWConfirmErrorMsg={formatMessage({id:'change.pwd.errMsg2'})}
              />
            )}
            <div className='bind-btns-row'>
              <CancelButton shape='circle' onClick={() => handleCancelModal()}>
                {formatMessage({id:'common.cancel'})}
              </CancelButton>
              <ConfirmButton
                type='primary'
                shape='circle'
                style={{ marginLeft: 10 }}
                onClick={() => handleVerificationStep2()}
                loading={loading}
              >
                {formatMessage({id:'common.confirm'})}
              </ConfirmButton>
            </div>
          </>
        )}
      </StyledBindModalContainer>
    </StyledReplaceBindModalContainer>
  )
}

const StyledBindModalContainer = styled(Form)`
  padding: 0 10px 10px;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      height: 44px;
      border-radius: 8px;
      padding: 0 20px;
      border: none;
      .ant-select-selection-search-input {
        height: 42px;
      }
      .ant-select-selection-item {
        line-height: 42px;
      }
      .ant-select-selection-placeholder {
        line-height: 42px;
      }
    }
  }
  .ant-select-dropdown {
    .ant-select-item {
      padding: 5px 20px;
    }
  }
  .ant-form-item {
    min-height: 68px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bind-btns-row {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
`

const StyledReplaceBindModalContainer = styled(BaseModal)`
  .sg-step-box:not(:last-child)::before{
    left: 66px;
  }
  .sg-step-content{
    width: 100px !important;
  }
`

export default observer(ReplaceBindModal)
