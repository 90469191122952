import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import BaseSwitch from 'src/components/base-switch'
import BaseSlider from 'src/components/base-slider'
import BaseNumberSelector from 'src/components/base-number-selector'
import ListIocn from 'src/assets/images/website-promotion1/list-icon.svg'
import styled from 'styled-components'

const PlatformAnimation: FC = () => {
  const [sliderValue, setSliderValue] = useState<number>(0)

  useEffect(() => {
    const scroll = () => {
      const num = parseInt(`${((window.pageYOffset - 200) / 1900)* 100}`)
      console.log(num)
      setSliderValue(num > 100 ? 100 : num)
    }
    window.addEventListener('scroll', scroll)
    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])
  return (
    <>
      <StyledEditCheatItemContainer>
        <div className='edit-cheat-title'><ListIocn />无限生命</div>
        <div className='edit-cheat-control'>
          <BaseSwitch check={false} />
        </div>
        <div className='edit-cheat-hotkeys'>
          <div className='edit-cheat-hotkey-item'>
            <div className='edit-cheat-hotkey-item-name'>切换</div>
            <div className='edit-cheat-hotkey-item-hotkeys'>
              <div className='edit-cheat-hotkey-item-hotkey'>F1</div>
            </div>
          </div>
        </div>
      </StyledEditCheatItemContainer>
      <StyledEditCheatItemContainer>
        <div className='edit-cheat-title'><ListIocn />无限耐力</div>
        <div className='edit-cheat-control'>
          <BaseSwitch check={true} />
        </div>
        <div className='edit-cheat-hotkeys'>
          <div className='edit-cheat-hotkey-item'>
            <div className='edit-cheat-hotkey-item-name'>切换</div>
            <div className='edit-cheat-hotkey-item-hotkeys'>
              <div className='edit-cheat-hotkey-item-hotkey'>F2</div>
            </div>
          </div>
        </div>
      </StyledEditCheatItemContainer>
      <StyledEditCheatItemContainer>
        <div className='edit-cheat-title'><ListIocn />设定移动速度</div>
        <div className='edit-cheat-control'>
          <BaseSlider
            disabled={true}
            value={sliderValue}
            max={100}
            min={0}
            step={1}
            onChange={() => {}}
          />
        </div>
        <div className='edit-cheat-hotkeys'>
          <div className='edit-cheat-hotkey-item'>
            <div className='edit-cheat-hotkey-item-name'>切换</div>
            <div className='edit-cheat-hotkey-item-hotkeys'>
              <div className='edit-cheat-hotkey-item-hotkey'>F3</div>
            </div>
          </div>
        </div>
      </StyledEditCheatItemContainer>
      <StyledEditCheatItemContainer>
        <div className='edit-cheat-title'><ListIocn />额外弹药</div>
        <div className='edit-cheat-control'>
          <BaseNumberSelector
            value={25}
            disabled={true}
            onChange={() => {}}
            max={100}
            min={0}
            hasSet={false}
          />
        </div>
        <div className='edit-cheat-hotkeys'>
          <div className='edit-cheat-hotkey-item'>
            <div className='edit-cheat-hotkey-item-name'>切换</div>
            <div className='edit-cheat-hotkey-item-hotkeys'>
              <div className='edit-cheat-hotkey-item-hotkey'>F5</div>
            </div>
          </div>
        </div>
      </StyledEditCheatItemContainer>
      <StyledEditCheatItemContainer>
        <div className='edit-cheat-title'><ListIocn />无限内存</div>
        <div className='edit-cheat-control'>
          <BaseSwitch check={false} />
        </div>
        <div className='edit-cheat-hotkeys'>
          <div className='edit-cheat-hotkey-item'>
            <div className='edit-cheat-hotkey-item-name'>切换</div>
            <div className='edit-cheat-hotkey-item-hotkeys'>
              <div className='edit-cheat-hotkey-item-hotkey'>F5</div>
            </div>
          </div>
        </div>
      </StyledEditCheatItemContainer>
    </>
  )
}

const StyledEditCheatItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px 30px 10px 40px;
  justify-content: space-between;
  width: 100%;
  &:hover {
    background: rgba(197, 152, 255, 0.08);
    .edit-cheat-hotkeys {
      .edit-cheat-hotkey-item {
        position: relative;
        .edit-cheat-hotkey-item-name {
          background: #C598FF;
          color: #17023B;
        }
        .edit-cheat-hotkey-item-hotkeys {
          .edit-cheat-hotkey-item-hotkey {
            border-color: #C598FF;
            color: #C598FF;
          }
        }
      }
    }
  }
  .edit-cheat-hotkeys {
    text-align: left;
    .edit-cheat-hotkey-item {
      display: flex;
      align-items: center;
      border-radius: 2px;
      overflow: hidden;
      cursor: pointer;
      &.disabled {
        user-select: none;
        pointer-events: none;
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
      &.editing,
      &:hover {
        .edit-cheat-hotkey-item-hotkeys .edit-cheat-hotkey-item-mask {
          opacity: 1;
        }
      }
      .edit-cheat-hotkey-item-name {
        height: 26px;
        padding: 0 6px;
        min-width: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.65);
        background: rgba(255,255,255,0.12);
      }
      .edit-cheat-hotkey-item-hotkeys {
        display: flex;
        align-items: center;
        position: relative;
        .edit-cheat-hotkey-item-hotkey {
          min-width: 38px;
          height: 26px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 14px;
          color: #FFFFFF;
          border: 1px solid #545458;
        }
        .edit-cheat-hotkey-item-mask {
          position: absolute;
          top: 1px;
          right: 1px;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 14px;
          color: #FFFFFF;
          opacity: 0;
          .edit-cheat-hotkey-item-editing {
            display: flex;
            align-items: center;
            div {
              width: 3px;
              height: 3px;
              background: #C598FF;
              border-radius: 50%;
              margin-right: 3px;
              animation: editing-indicator 0.5s infinite alternate;
            }
            div:nth-child(1) {
              animation-delay: 0s;
            }
            div:nth-child(2) {
              animation-delay: 0.25s;
            }
            div:nth-child(3) {
              animation-delay: 0.5s;
            }
          }
        }
      }
    }
  }
  .edit-cheat-title {
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.7);
    width: 195px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  .edit-cheat-control {
    flex: 1;
    .sg-switch {
      background: rgba(255,255,255,0.2);
      .handler {
        background: #999B9C;
        color: #000000;
      }
    }
    
  }
`

export default observer(PlatformAnimation)
