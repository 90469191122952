import { observable, action } from 'mobx'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { getGJUserInfo, getUserBaseInfoHttp, injectStatusChangeHttp, loginGJByMobile } from 'src/apis/user-api'
import { historyStack } from 'src/components/custom-router'
import { VipStore, SpeedStore, Activity230817Store, appStore } from 'src/store'
import { StartingStore, RunningStore, ClientLocalStorageStore } from 'src/store/modifier'
import { MtJsWebSDK } from '@mt0926/js-web-sdk'
import { emitter } from 'src/tools/emitter'
import { getAuth } from 'src/tools/auth'
import { reportBaiduOcpcByRegistry } from 'src/tools/report'
import { $t } from 'src/locales'
import SocketStore, { CLOUD_EVENTS } from 'src/pages/game-detail/cloud-game/socket'
import CEFTransfer from 'src/CEFTransfer'
import dayjs from 'dayjs'
// import { jump } from 'src/tools/common'

class LoginStore {
  /** 登录弹框是否显示 */
  @observable
  modalVisible: boolean = false

  // 登录弹框当前显示的tab
  @observable
  modalActiveKey: string = $t({ id: 'login.login' })

  /** 忘记密码弹框是否显示 */
  @observable
  forgetPWVisible: boolean = false

  // 用来刷新登录弹框上面记住的密码
  @observable
  reloadRemember: number = 0

  @observable
  userinfo: Modifier.TLoginResult | null = localStorageTool.get(LOCALSTORAGE_KEYS.AUTH_INFO)

  @observable
  userinfoGJ: IGJUserInfo | null = localStorageTool.get(LOCALSTORAGE_KEYS.AUTH_INFO_GJ)

  @observable
  userBaseInfo: Modifier.IBaseUserInfo | null = localStorageTool.get(LOCALSTORAGE_KEYS.USER_BASE_INFO)

  @action
  openForgetPWModal = () => {
    this.forgetPWVisible = true
  }

  @action
  cancelForgetPWModal = () => {
    this.forgetPWVisible = false
  }

  @action
  openLoginModal = () => {
    this.modalVisible = true
  }

  @action
  cancelLoginModal = () => {
    this.modalVisible = false
    this.modalActiveKey = $t({ id: 'login.login' })
    this.cancelForgetPWModal()
  }

  @action
  loginSuccess = async (loginResult: Modifier.TLoginResult) => {
    this.userinfo = loginResult
    this.getUserBaseInfo(true)
    
    localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO, loginResult, { expires: loginResult.expires })
    VipStore.handleLoadUserRemainingSpeedBalance()
    VipStore.handleLoadUserRemainingCloudGameBalance()
    VipStore.handleLoadVipInfo()
    VipStore.handleLoadUserFreeSubscribeNum()
    Activity230817Store.handleUserLogin(true)
    try {
      MtJsWebSDK.login(`${this.userinfo.userId}`)
    } catch (error) {}
  }

  @action
  getUserBaseInfo = async (afterLogin: boolean = false) => {
    if (!this.userinfo) {
      this.userBaseInfo = null
      return
    }
    const { result: userBaseInfo } = await getUserBaseInfoHttp()
    if (afterLogin) {
      // 当前时间 - 该用户注册时间
      const diff = (dayjs().valueOf() - dayjs(userBaseInfo.registerTime).valueOf())
      // 大于0 并且小于1分钟就上报
      const isNewUser = diff > 0 && diff < 1000 * 60 * 2
      isNewUser && emitter.emit('report-registry', {
        params: {
          userId: userBaseInfo.userId
        },
      })
      // reportBaiduOcpcByRegistry(loginResult.userId)
    }
    this.userBaseInfo = userBaseInfo
    localStorageTool.set(LOCALSTORAGE_KEYS.USER_BASE_INFO, userBaseInfo)
    localStorageTool.set(LOCALSTORAGE_KEYS.USER_ID, userBaseInfo.userId)
    SocketStore.dispatch(CLOUD_EVENTS.USER_UPDATE, { userId: userBaseInfo.userId })
  }

  //权换取管家token，试玩接游戏是后端转发调用的管家接口，须先换取管家token
  @action
  loginGJ = async () => {
    //授
    const { result } = await loginGJByMobile({
      channel: 'sgt',
      token: getAuth(),
    })
    localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO_GJ, result)
    const { data } = await getGJUserInfo({
      token: result.token,
    })
    this.userinfoGJ = {
      ...result,
      ...data,
    }
    localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO_GJ, this.userinfoGJ)
  }

  @action
  logout = () => {
    emitter.emit('beforeLogout')
    localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO, null)
    localStorageTool.set(LOCALSTORAGE_KEYS.AUTH_INFO_GJ, null)
    historyStack.clearStack()
    historyStack.history.replace('/')
    const startNo = StartingStore.currentStartNo || RunningStore.currentStartNo
    if (startNo) {
      injectStatusChangeHttp({
        startNo,
        injectStatusEnum: 'stop_buff',
        code: '19996',
        message: '用户退出登录',
      })
      CEFTransfer.sendToClient('stopBuff')
    }
    SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_LOGOUT' })
    StartingStore.clearStartingInfo()
    RunningStore.clearRunningInfo()
    ClientLocalStorageStore.setHoldHarmlessProtocolCache(null)
    this.userinfo = null
    this.userinfoGJ = null
    this.userBaseInfo = null
    Activity230817Store.clearCountdown()
    appStore.feedbackController.counter.modifier = 0
    appStore.feedbackController.counter.speed = 0
    VipStore.logout()
    SocketStore.dispatch(CLOUD_EVENTS.USER_LOG_OUT)
    emitter.emit('logout')
  }
}

export default new LoginStore()
