import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { FadeinImage } from 'src/components/base-image'
import { Activity230817Store } from 'src/store'
import { MtJsWebSDK } from '@mt0926/js-web-sdk'
import { message } from 'antd'
import { receiveActivityVipDurationHttp } from 'src/apis/user-api'
import { VipStore } from 'src/store'
import styled from 'styled-components'

import modalBgImg from 'src/assets/images/activity/23-08-17/modal-bg.png'
import privilegesIcon1Img from 'src/assets/images/activity/23-08-17/privileges-icon1.png'
import privilegesIcon2Img from 'src/assets/images/activity/23-08-17/privileges-icon2.png'
import privilegesIcon3Img from 'src/assets/images/activity/23-08-17/privileges-icon3.png'
import privilegesBg1Img from 'src/assets/images/activity/23-08-17/privileges-bg1.png'
import privilegesBg2Img from 'src/assets/images/activity/23-08-17/privileges-bg2.png'
import mainBtnImg from 'src/assets/images/activity/23-08-17/main-btn.png'
import closeBtnImg from 'src/assets/images/activity/23-08-17/close-btn.png'

const privilegesList = [
  {
    icon: privilegesIcon1Img,
    title: '加速器',
    bg: privilegesBg1Img,
    desc: (
      <>
        <span>全天</span>
        <span className='primary'>免费</span>
        <span>使用</span>
      </>
    ),
  },
  {
    icon: privilegesIcon2Img,
    title: '修改器',
    bg: privilegesBg1Img,
    desc: (
      <>
        <span>全天</span>
        <span className='primary'>免费</span>
        <span>使用</span>
      </>
    ),
  },
  {
    icon: privilegesIcon3Img,
    title: '海量游戏资源',
    bg: privilegesBg2Img,
    desc: (
      <>
        <span className='primary'>无限</span>
        <span>订阅使用</span>
      </>
    ),
  },
]

const Activity230817Modal: FC = () => {
  const loadingRef = useRef<boolean>(false)
  const handleReceiveDuration = async () => {
    loadingRef.current = true
    try {
      await Activity230817Store.loadConfig()
      if (!Activity230817Store.activity230817Valid) {
        message.success('活动已下线')
        Activity230817Store.clearCountdown()
        throw new Error('活动已下线')
      }
      await receiveActivityVipDurationHttp({ activityNo: Activity230817Store.ACTIVITY_NO })
      message.success('新人福利领取成功')
      Activity230817Store.clearCountdown()
      VipStore.handleLoadVipInfo()
      try {
        MtJsWebSDK.track('activity230817_btn_click', {
          activity230817_btn_result: 'ok',
        })
      } catch (error) {}
    } catch (error) {
      try {
        MtJsWebSDK.track('activity230817_btn_click', {
          activity230817_btn_result: (error as Error).message,
        })
      } catch (error) {}
    }
    loadingRef.current = false
  }

  useEffect(() => {
    if (Activity230817Store.modalVisible) {
      try {
        MtJsWebSDK.track('activity230817_modal_show')
      } catch (error) {}
    }
  }, [Activity230817Store.modalVisible])

  return (
    <StyledActivity230817Modal width={585} visible={Activity230817Store.modalVisible}>
      <div onClick={() => (Activity230817Store.modalVisible = false)} className='close-btn'>
        <FadeinImage type='notDefault' width={40} height={40} src={closeBtnImg} />
      </div>
      <StyledActivity230817Container>
        <div className='activity230817-text'>
          <span>再次感谢您的加入，特此赠送 MYBUFF 会员</span>
          <span className='primary'>{Activity230817Store.config?.activityConfig.givingMemberDay || '--'}</span>
          <span>天，畅享以下特权</span>
        </div>
        <div className='activity230817-privileges-box'>
          {privilegesList.map(d => {
            return (
              <div className='activity230817-privilege-item' key={d.title}>
                <FadeinImage type='notDefault' width={54} height={54} src={d.icon} />
                <div
                  className='activity230817-privilege-right-box'
                  style={{ background: `url(${d.bg}) no-repeat`, backgroundPosition: '-6px 8px' }}
                >
                  <div className='activity230817-privilege-title'>{d.title}</div>
                  <div className='activity230817-privilege-desc'>{d.desc}</div>
                </div>
              </div>
            )
          })}
        </div>
        <div className='activity230817-countdown-box'>
          {Activity230817Store.formatCountdown?.map((d, i) => {
            if (d.type === 'number') {
              return (
                <div key={`activity230817-countdown-number-${i}`} className='activity230817-countdown-number'>
                  {d.value}
                </div>
              )
            }
            return (
              <div key={`activity230817-countdown-number-${i}`} className='activity230817-countdown-symbol'>
                {d.value}
              </div>
            )
          })}
        </div>
        <div className='activity230817-main-btn' onClick={() => !loadingRef.current && handleReceiveDuration()} />
      </StyledActivity230817Container>
    </StyledActivity230817Modal>
  )
}

const StyledActivity230817Container = styled.div`
  background: url(${modalBgImg}) no-repeat;
  width: 585px;
  height: 350px;
  position: relative;
  .activity230817-countdown-box {
    position: absolute;
    top: 281px;
    left: 84px;
    height: 28px;
    display: flex;
    align-items: center;
    .activity230817-countdown-number {
      width: 28px;
      height: 28px;
      background: rgba(158, 105, 255, 0.15);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }
    .activity230817-countdown-symbol {
      font-size: 12px;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.4);
      line-height: 17px;
      margin: 0 6px;
    }
  }
  .activity230817-main-btn {
    width: 185px;
    height: 42px;
    background: url(${mainBtnImg}) no-repeat;
    position: absolute;
    top: 267px;
    left: 341px;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
  .activity230817-privileges-box {
    position: absolute;
    top: 173px;
    left: 52px;
    width: 474px;
    height: 74px;
    background: rgba(14, 14, 25, 0.25);
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    .activity230817-privilege-item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      .activity230817-privilege-right-box {
        .activity230817-privilege-title {
          font-size: 14px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
        }
        .activity230817-privilege-desc {
          font-size: 12px;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #ffffff;
          line-height: 17px;
          span {
            opacity: 0.5;
            &.primary {
              color: #f5b54d;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  .activity230817-text {
    display: flex;
    align-items: flex-end;
    font-size: 16px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    position: absolute;
    top: 138px;
    left: 52px;
    .primary {
      font-size: 29px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #c2a1ff;
      line-height: 21px;
      margin: 0 6px;
    }
  }
`

const StyledActivity230817Modal = styled(BaseModal)`
  .ant-modal-content {
    backdrop-filter: none;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
    background: transparent;
  }
  .ant-modal-close-x {
    display: none;
  }
  .close-btn {
    position: absolute;
    top: -16px;
    right: -40px;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
`

export default observer(Activity230817Modal)
