import React, { FC } from 'react'
import type { PaginationProps } from 'antd/es/pagination'
import { Pagination } from 'antd'
import styled from 'styled-components'


const SGPagination: FC<PaginationProps> = ({...props}) => {
  return (
    <StyledPagination 
      showQuickJumper={true} 
      showSizeChanger={false} 
      {...props}
    />
  )
}

const StyledPagination = styled(Pagination)`
  .ant-pagination-options {
    margin-left: 0;
  }
  .ant-pagination-item:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .ant-pagination-options-quick-jumper {
    font-size: 14px;
    line-height: 14px;
    color: #979797;
    input {
      height: 32px;
      width: 44px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      border: none;
      color: var(--text-color-FFFFFF);
      &:focus {
        box-shadow: none;
      }
    }
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    width: 32px;
    height: 32px;
  }
  .ant-pagination-item,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    font-size: 14px;
    color: var(--text-color-FFFFFF);
    line-height: 32px;
    border: none;
  }
  .ant-pagination-item-active,
  .ant-pagination-item-active:hover,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    background: #9677c0;
    color: var(--text-color-FFFFFF);
    font-size: 14px;
    font-weight: 500;
  }
  .ant-pagination-item a {
    color: var(--text-color-FFFFFF);
  }
  .ant-pagination-item-active a {
    color: var(--text-color-FFFFFF);
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--text-color-FFFFFF);
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--text-color-FFFFFF);
  }
  .ant-pagination-disabled .ant-pagination-item-link {
    opacity: 0.5;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: var(--text-color-FFFFFF);
    }
  }
`
export default SGPagination
