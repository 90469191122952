import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { LoginStore } from 'src/store/modifier'
import { checkOversea, formatHideMobile, formatTimeFixedOffset, sleep } from 'src/tools/common'
import { FadeinImage } from 'src/components/base-image'
import { TextButton } from 'src/components/base-button'
import { BaseInput } from 'src/components/base-input'
import { Button, message } from 'antd'
import { updateNicknameHttp } from 'src/apis/user-api'
import { useIntl } from 'react-intl'
import { appStore, VipStore } from 'src/store'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { useVipRemainingTime } from 'src/hooks'
import { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center/index'
import ReplaceBindModal from 'src/pages/user-center/replace-bind-modal'
import BindModal from 'src/pages/user-center/bind-modal'
import DefaultAvatarIcon from 'src/assets/icons/common/default-avatar.svg'
import QueueAnim from 'rc-queue-anim'
import dayjs from 'dayjs'
import styled from 'styled-components'
import EditIcon from 'src/assets/images/user-center/editicon.svg'
import VipIcon from 'src/assets/images/user-center/vipicon.svg'

const SelfInfo: FC = () => {
  const [bindModalVisible, setBindModalVisible] = useState<Modifier.TBindModalVisible | null>(null)
  const [replaceBindModalVisible, setReplaceBindModalVisible] = useState<Modifier.TReplaceBindModalVisible | null>(null)
  const [reload, setReload] = useState<number>(0)
  const [editNicknameLoading, setEditNicknameLoading] = useState(false)
  const [isEditNickname, setIsEditNickname] = useState(false)
  const [editInputValue, setEditInputValue] = useState('')
  const userInfo = LoginStore.userinfo
  const baseUserInfo = LoginStore.userBaseInfo
  const hasBindMobile = LoginStore.userBaseInfo?.bindMobile === 1
  const hasBindEmail = LoginStore.userBaseInfo?.bindEmail === 1
  const { formatMessage } =useIntl()
  const isMember = VipStore.memberinfo?.isMember
  const isOversea = checkOversea()
  const { remainingTimeStringArr: speedRemainingTimeStringArr } = useVipRemainingTime('speed')
  const { remainingTimeStringArr: cloudGameRemainingTimeStringArr } = useVipRemainingTime('cloudgame')

  const verificationMethods = useMemo(() => {
    let arr: Modifier.TVerificationMethods[] = []
    if (hasBindEmail) {
      arr.push({
        title: formatMessage({id:'bind.email'}),
        key: 'email',
      })
    }
    if (hasBindMobile) {
      arr.push({
        title: formatMessage({id:'bind.mobile'}),
        key: 'mobile',
      })
    }
    arr.push({
      title: formatMessage({id:'bind.password'}),
      key: 'password',
    })
    return arr
  }, [hasBindMobile, hasBindEmail])

  const handleOpenReplaceBindModal = (type: Modifier.TReplaceBindModalVisible) => {
    setReplaceBindModalVisible(type)
  }

  const handleCancelReplaceBindModal = () => {
    setReplaceBindModalVisible(null)
  }

  const handleOpenBindModal = (type: Modifier.TBindModalVisible) => {
    setBindModalVisible(type)
  }

  const handleCancelBindModal = () => {
    setBindModalVisible(null)
  }

  const handleBindSuccess = () => {
    setReload(value => value + 1)
  }

  const handleOpenEditNickname = () => {
    if (!baseUserInfo?.nickname) {
      return
    }
    setEditInputValue(baseUserInfo.nickname)
    setIsEditNickname(true)
  }

  const handleSaveEditNickname = async () => {
    if (!baseUserInfo) {
      return
    }
    const value = editInputValue.trim()
    if (!value) {
      return message.warn(formatMessage({id:'userCenter.nick.blank'}))
    }
    if (value === baseUserInfo.nickname) {
      return message.warn(formatMessage({id:'userCenter.nick.same'}))
    }
    try {
      setEditNicknameLoading(true)
      await sleep(2000)
      await updateNicknameHttp({
        newNickName: value,
      })
      setReload(value => value + 1)
      message.success(formatMessage({id:'userCenter.nick.editSuccess'}))
      handleCancelEditNickname()
    } catch (error) {
    } finally {
      setEditNicknameLoading(false)
    }
  }

  const handleCancelEditNickname = () => {
    if (!baseUserInfo?.nickname) {
      return
    }
    setEditInputValue(baseUserInfo.nickname)
    setIsEditNickname(false)
  }

  const logout = async () => {
    const isConfirm = await imperativeConfirmModal({
      zIndex:1001,
      title: formatMessage({id:'userCenter.logout1'}),
      content: (
        <>
          <div>{formatMessage({id:'userCenter.logout2'})}</div>
          <div>{formatMessage({id:'userCenter.logout3'})}</div>
        </>
      ),
      okText: formatMessage({id:'userCenter.logout4'}),
      cancelText: formatMessage({id:'common.cancel'}),
    })
    if (isConfirm.value) {
      // SpeedStore.stopSpeed({ notify: true, report: true, reason: 'USER_LOGOUT' }).finally(() => {
      LoginStore.logout()
      appStore.userCenterVisible = false
      // })
    }
  }

  useEffect(() => {
    LoginStore.getUserBaseInfo()
  }, [reload])

  return (
    <StyledSelfInfoContainer>

      <StyledBox>
        <div className="title">{formatMessage({id:'userCenter.user'})}</div>
        <StyledUserInfo>
          <div className="top"></div>
          <div className="user-info">
            <StyledAvatar>
              {userInfo?.avatar ? (
                <FadeinImage style={{fontSize: 0}} width={72} height={72} type="notDefault" src={userInfo.avatar}/>
              ) : (
                <DefaultAvatarIcon/>
              )}
              {isMember && <div className="icon">
                <VipIcon/>
              </div>}
            </StyledAvatar>
            {isEditNickname ? (
              <div className='user-info-right'>
                <BaseInput
                  disabled={editNicknameLoading}
                  maxLen={10}
                  className='edit-nickname'
                  value={editInputValue}
                  onChange={e => setEditInputValue(e.target.value)}
                />
                {!editNicknameLoading && (
                  <TextButton className='action-btn-1'  onClick={() => handleCancelEditNickname()}>
                    {formatMessage({id:'common.cancel'})}
                  </TextButton>
                )}
                <TextButton
                  className='action-btn-1'
                  loading={editNicknameLoading}
                  onClick={() => handleSaveEditNickname()}
                >
                  {formatMessage({id:'common.confirm'})}
                </TextButton>
              </div>
            ) : (
              <div className='user-info-right'>
                <div className='nick-name'>{baseUserInfo?.nickname}</div>
                <EditIcon className='edit-icon' onClick={()=>handleOpenEditNickname()}/>
              </div>
            )}
            <StyledBtn
              className='logout-btn'
              onClick={logout}
              type={'primary'}
            >
              {formatMessage({id:'userCenter.logout'})}
            </StyledBtn>
          </div>

          <div className="info">
            <StyledItemBox className="user-header-item-box">
              <div className="user-header-text">{formatMessage({id:'userCenter.vipStatus'})}</div>
              <div className="user-header-title">
                <div className="vip">
                  <div className="vip-status">
                    <span
                      className="main"
                      style={{color: isMember ? '#0DFAB8' : 'rgba(195, 140, 255, 1)'}}
                    >
                    {isMember ? formatMessage({id:'userCenter.subscribing'}) : formatMessage({id:'userCenter.notSubscribed'})}
                  </span>
                  </div>
                  {isMember && (
                    <div className="expire-time">
                      {formatMessage({id:'userCenter.expireDate'})}：{dayjs(formatTimeFixedOffset(dayjs(VipStore.memberinfo?.expireDate).valueOf())).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  )}
                  {!isMember && <StyledBtn
                    className="subscribe-btn"
                    type={'primary'}
                    onClick={()=>showUserCenter(NAV_KEY_MAPPING.VIP,'')}
                  >
                    {formatMessage({id:'userCenter.subscribe'}) }
                  </StyledBtn>}
                </div>
              </div>
            </StyledItemBox>
            <StyledItemBox className="user-header-item-box">
              <div className="user-header-text">{formatMessage({id:'userCenter.regTime'})}</div>
              <div className="user-header-title">
                {baseUserInfo?.registerTime ? dayjs(formatTimeFixedOffset(dayjs(baseUserInfo.registerTime).valueOf())).format('YYYY-MM-DD') : '--'}
              </div>
            </StyledItemBox>
            <StyledItemBox className="user-header-item-box">
              <div className="user-header-text">ID</div>
              <div className="user-header-title">{baseUserInfo?.userId}</div>
            </StyledItemBox>
          </div>
        </StyledUserInfo>
      </StyledBox>

      <StyledBox>
        <div className="title">{formatMessage({id:'userCenter.SafetyInformation'})}</div>
        <QueueAnim component={StyledSafetyInfoContainer}>
          <div className="safety-row" key="safety-row-account">
            <StyledItemBox>
              <div className="user-header-text">{formatMessage({id:'userCenter.account'})}</div>
              <div className="user-header-title">{baseUserInfo?.account}</div>
            </StyledItemBox>
          </div>
          <div className="safety-row" key="safety-row-pw">
            <StyledItemBox>
              <div className="user-header-text">{formatMessage({id:'userCenter.pwd'})}</div>
              <div className="user-header-title">{formatMessage({id:'userCenter.bound'})}</div>
            </StyledItemBox>
            <div className='action-box'>
                <StyledBtn type={'primary'} onClick={() => handleOpenReplaceBindModal(formatMessage({id:'bind.password'}))}>
                  {formatMessage({id:'userCenter.changePwd'})}
                </StyledBtn>
            </div>
          </div>
          {!isOversea && <div className='safety-row' key='safety-row-phone'>
            <StyledItemBox>
              <div className='user-header-text'>手机号</div>
              <div
                className='user-header-title'>{LoginStore.userBaseInfo?.mobile ? formatHideMobile(LoginStore.userBaseInfo.mobile) : '未绑定'}</div>
            </StyledItemBox>
            <div className='action-box'>
                <StyledBtn type={'primary'} onClick={() => (hasBindMobile ? handleOpenReplaceBindModal('手机') : handleOpenBindModal('手机'))}>
                  {hasBindMobile ? '换绑手机' : '绑定手机'}
                </StyledBtn>
            </div>
          </div>}
          <div className='safety-row' key='safety-row-email'>
            <StyledItemBox>
              <div className="user-header-text">{formatMessage({id:'userCenter.email'})}</div>
              <div className="user-header-title">{LoginStore.userBaseInfo?.email ? LoginStore.userBaseInfo?.email : '未绑定'}</div>
            </StyledItemBox>
            <div className='action-box'>
                <StyledBtn
                  type={'primary'}
                  onClick={() => (hasBindEmail ? handleOpenReplaceBindModal(formatMessage({id:'bind.email'})) : handleOpenBindModal(formatMessage({id:'bind.email'})))}
                >
                  {hasBindEmail ? formatMessage({id:'userCenter.changeEmail'}) : formatMessage({id:'userCenter.bindEmail'})}
                </StyledBtn>
            </div>
          </div>
        </QueueAnim>
      </StyledBox>

      <BindModal visible={bindModalVisible} onCancel={handleCancelBindModal} onSuccess={handleBindSuccess}/>
      <ReplaceBindModal
        visible={replaceBindModalVisible}
        verificationMethods={verificationMethods}
        onCancel={handleCancelReplaceBindModal}
        userBaseInfo={LoginStore.userBaseInfo}
        onSuccess={handleBindSuccess}
      />
    </StyledSelfInfoContainer>
  )
}

export const StyledBtn = styled(Button)`
  height: 32px;
  background: #7044CD;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #fff;
  min-width: 140px;
  border: none;

  &.ant-btn-primary:hover, &.ant-btn-primary:focus {
    background: #7749d8;
  }
`

const StyledUserInfo = styled.div`
  background: #1E1F22;  
  border-radius: 8px;
  padding-bottom: 16px;
  .top{
    height: 100px;
    background: #7044CD;
    border-radius: 8px 8px 0 0;
  }
  .user-info{
    display: flex;
    align-items: center;
    margin: -20px 16px 0;
  }
  .user-info-right{
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  .nick-name{
    color: #F2F3F5;
    font-size: 18px;
  }
  .edit-icon {
    cursor: pointer;
    margin-left: 8px;
  }
  .action-btn{
    margin-left: auto;    
  }
  .action-btn-1{
    margin-left: 10px;
  }
  .logout-btn{
    margin-top: 20px;
    margin-left: auto;
    margin-right: 16px;
  }
  .info{
    background: #2B2D31;
    border-radius: 8px;
    padding: 16px;
    margin: 20px 16px 0;
  }  
  .user-header-item-box{
    margin-bottom: 24px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  
`

export const StyledItemBox=styled.div`
  .user-header-text{
    font-size: 11px;
    color: #b5bac1;
    line-height: 16px;
    margin-bottom: 3px;
  }
  .user-header-title{
    color: #F2F3F5;
    font-size: 15px;
    line-height: 21px;
  }
  .vip{
    font-size: 15px;
    display: flex;
    align-items: center;
    .vip-status{
      margin-right:10px ;
    }
    .subscribe-btn{
      margin-left: auto;
    }
  }  
`

export const StyledBox=styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(78,80,88,0.48);
  margin-bottom: 24px;
  &:last-child{
    border-bottom: none;
  }
  .title{
    margin-bottom: 20px;
    color: #f2f3f5;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;    
  }
  
`

export const StyledSafetyInfoContainer = styled.div`
  .safety-row {
    ${props => props.theme.flexAlignCenter}
    width: 100%;
    margin-bottom: 24px;
    &:last-child{
      margin-bottom: 0;
    }
    padding-right: 20px;  
    .action-box {
      flex: 1;
      ${props => props.theme.flexAlignCenter}
      justify-content: flex-end;     
      margin-right: 12px;
    }
  }
`

const StyledAvatar = styled.div`
  ${props => props.theme.flexCenter}
  width: 72px;
  height: 72px;
  background: ${props => props.theme.color.bg.$E8B409};
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  font-size: 44px;
  border: 4px solid #000;
  box-sizing: content-box;
  margin-right: 18px;
  .true-img {
    border-radius: 50%;
  }
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #7044CD;
    border: 2px solid #000;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    bottom: -4px;
  }
`

const StyledBaseInfoContainer = styled.div`
  width: 100%;
  background-image: linear-gradient(90deg, #80218b 0%, #45216f 100%);
  background-color: ${props => props.theme.color.bg.$FFFFFF06};
  background-size: 100% 60px;
  background-repeat: no-repeat;
  border-radius: 4px;
  padding: 24px;
  .nick-row {
    ${props => props.theme.flexAlignCenter}
    justify-content: space-between;
    .nick-left {
      ${props => props.theme.flexAlignCenter}
      padding-top: 16px;
      .edit-nickname {
        margin-top: 24px;
      }
      .nick-name {
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        color: var(--text-color-FFFFFF);
        margin-left: 16px;
        line-height: 24px;
        margin-top: 24px;
      }
    }
    .nick-right {
      padding-top: 36px;
    }
  }
  .base-box {
    height: 176px;
    background: ${props => props.theme.color.bg.$FFFFFF06};
    padding: 24px;
    margin-top: 20px;
    border-radius: 4px;
    .base-item-box {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .label {
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        color: ${props => props.theme.color.text.$BBBBBB};
        line-height: 24px;
      }
      .value {
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        color: var(--text-color-FFFFFF);
        line-height: 24px;
        margin-top: 4px;
      }
    }
  }
`

const StyledSelfInfoContainer = styled.div`
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

export default observer(SelfInfo)
