import React, { FC } from 'react'
import type { ModalProps } from 'antd/es/modal'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import classNames from 'classnames'

interface IBaseModal extends ModalProps {}

const BaseModal: FC<IBaseModal> = ({ className, ...props }) => {
  return (
    <StyledBaseModalContainer
      className={classNames('app-nodrag', className)}
      keyboard={true}
      maskClosable={false}
      mask={true}
      centered={true}
      {...props}
    />
  )
}

const StyledBaseModalContainer = styled(Modal)`
  color: #C6C6C6;
  .ant-modal-content{
    background: #2F3237;
    border-radius: 8px;
  }
  .ant-modal-header{
    background: none;
    color: #fff;    
    border-radius: 8px 8px 0 0;
    padding: 40px 40px 16px;
    border-bottom: none;
  }
  .ant-modal-title{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
  }
  .ant-modal-body{
    padding: 0 40px 32px;
  }
  .ant-modal-footer{
    border: none;
    padding: 0 40px 40px;
    display: flex;
    justify-content: flex-end;
    .ant-btn{
      min-width: 180px;
      height: 42px;
      background: rgba(255,255,255,0.12);
      border-radius: 4px 4px 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
    .ant-btn-primary{
      background: #7044CD;
    }
    .ant-btn + .ant-btn:not(.ant-dropdown-trigger){
      margin-left: 16px;
    }
  }
  .ant-modal-close{
    color: #dcddde;
  }
  .ant-modal-close-x{
    font-size: 12px;
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
`

export default observer(BaseModal)
