import { observable } from 'mobx'
import { baseFetch } from 'src/fetch'
import { AxiosRequestConfig } from 'axios'

const persistentFetch = <R extends Record<string, any> = {}, P extends Record<string, any> = {}>({
  key,
  ...options
}: Fetch.PersistenFetchPayload<R, AxiosRequestConfig>): Fetch.PersistenFinalFetch<R, P> => {
  const finalFetch: Fetch.PersistenFinalFetch<R, P> = async (data, config?: AxiosRequestConfig) => {
    const result = await baseFetch<R, P>(options)(data)
    finalFetch.result = result
    return result
  }
  finalFetch.result = null
  Object.defineProperty(
    finalFetch,
    'result',
    observable(finalFetch, 'result', {
      value: null,
      writable: true,
      enumerable: true,
      configurable: true,
    }),
  )
  return finalFetch
}

export default persistentFetch
