import React, { FC } from 'react'
import RiotGameIcon from 'src/assets/icons/common/platform/RiotGameIcon.svg'
import SteamGameIcon from 'src/assets/icons/common/platform/SteamGameIcon.svg'
import OriginGameIcon from 'src/assets/icons/common/platform/OriginGameIcon.svg'
import UplayGameIcon from 'src/assets/icons/common/platform/UplayGameIcon.svg'
import EpicGameIcon from 'src/assets/icons/common/platform/EpicGameIcon.svg'
import BlizzardGameIcon from 'src/assets/icons/common/platform/BlizzardGameIcon.svg'

/********** 平台相关图片 */
import SteamIcon from 'src/assets/icons/platform/steam.svg'
import EpicIcon from 'src/assets/icons/platform/epic.svg'
import GogIcon from 'src/assets/icons/platform/gog.svg'
import OriginIcon from 'src/assets/icons/platform/origin.svg'
import RockstarIcon from 'src/assets/icons/platform/rockstar.svg'
import UplayIcon from 'src/assets/icons/platform/uplay.svg'
import UwpIcon from 'src/assets/icons/platform/uwp.svg'
import StandaloneIcon from 'src/assets/icons/platform/standalone.svg'

/*********** 修改项相关图标 */
import PlayerIcon from 'src/assets/icons/modifier-detail/player.svg'
import GameIcon from 'src/assets/icons/modifier-detail/game.svg'
import EnemiesIcon from 'src/assets/icons/modifier-detail/enemies.svg'
import InventoryIcon from 'src/assets/icons/modifier-detail/inventory.svg'
import StatsIcon from 'src/assets/icons/modifier-detail/stats.svg'
import WeaponsIcon from 'src/assets/icons/modifier-detail/weapons.svg'
import VehiclesIcon from 'src/assets/icons/modifier-detail/vehicles.svg'
import PhysicsIcon from 'src/assets/icons/modifier-detail/physics.svg'
import TeleportIcon from 'src/assets/icons/modifier-detail/teleport.svg'

/** 首页相关图标 */
import toolsSpeedImg from 'src/assets/images/home/tools-speed.png'
import toolsModifierImg from 'src/assets/images/home/tools-modifier.png'

/** 工具类型 */
export enum ToolsTypes {
  SPEED_GAME = 'SPEED_GAME',
  TRAINER_GAME = 'TRAINER_GAME',
  GAME_RESOURCE = 'GAME_RESOURCE',
  PLATFORM_WELFARE = 'PLATFORM_WELFARE',
  CLOUD_GAME = 'CLOUD_GAME',
  TRIAL_GAME = 'TRIAL_GAME'
}

export enum ToolsTypesName {
  SPEED_GAME = '支持加速',
  TRAINER_GAME = '支持修改',
  GAME_RESOURCE = '提供游戏资源',
  PLATFORM_WELFARE = '喜加一',
  CLOUD_GAME = '支持云游戏',
  TRIAL_GAME = '提供共享账号'
}

/** 工具类型的额外信息 */
export const TOOLS_EXTRA_INFO_MAP = {
  [ToolsTypes.SPEED_GAME]: {
    typeName: '加速器',
    textColor: 'rgba(0, 133, 255, 1)',
    bgColor: 'linear-gradient(45deg, #162F46 0%, #081017 100%)'
  },
  [ToolsTypes.GAME_RESOURCE]: {
    typeName: '',
    textColor: '',
    bgColor: '',
  },
  [ToolsTypes.TRAINER_GAME]: {
    typeName: '修改器',
    textColor: 'rgba(165, 45, 153, 1)',
    bgColor: 'linear-gradient(45deg, #542A52 0%, #251F25 100%)',
  },
  [ToolsTypes.PLATFORM_WELFARE]: {
    typeName: '',
    textColor: '',
    bgColor: '',
  },
}

/** 资源类型 */
export enum ResourceType {
  ARCHIVE = 'ARCHIVE',
  MOD = 'MOD',
  CHINESE_PATCH = 'CHINESE_PATCH',
  INFORMATION = 'INFORMATION'
}

export const RESOURCE_EXTRA_INFO_MAP = {
  [ResourceType.ARCHIVE]: {
    name: '存档',
    bgColor: 'rgba(195, 140, 255, 0.16)',
    textColor: '#C38CFF',
  },
  [ResourceType.MOD]: {
    name: 'MOD',
    bgColor: 'rgba(250, 198, 13, 0.16)',
    textColor: 'rgba(250, 198, 13, 1)',
  },
  [ResourceType.CHINESE_PATCH]: {
    name: '汉化包',
    bgColor: 'rgba(182, 240, 156, 0.16)',
    textColor: 'rgba(182, 240, 156, 1)',
  },
  [ResourceType.INFORMATION]: {
    name: '资讯',
    bgColor: 'rgba(182, 240, 156, 0.16)',
    textColor: 'rgba(182, 240, 156, 1)',
  },
}


/**游戏平台图标*/
export const PLATFORM_ICON: { [key: string]: React.ReactElement } = {
  steam: <SteamGameIcon />,
  origin: <OriginGameIcon />,
  uplay: <UplayGameIcon />,
  epic: <EpicGameIcon />,
  blizzard: <BlizzardGameIcon />,
  riot: <RiotGameIcon />,
}

/**平台名称*/
export const PlatformText: { [key: string]: string } = {
  1: 'Steam',
  2: 'Origin',
  4: 'Uplay',
  5: 'Epic',
  6: 'Blizzard',
  9: 'Riot',
  12: '手游',
}
/**账号类型*/
export const AccountType: { [key: number]: string } = {
  6: '卡密',
  8: '直冲',
  9: 'CDK',
  37: '加速器',
}
/**订单类型*/
export const OrderType: { [key in OrderType]: string } = {
  1: '独享',
  2: '直冲',
  3: '试玩账号',
  4: '试玩',
  6: '共享',
  7: '单机',
  8: '独享',
  9: '加速器',
  10: '升级独享',
  11: '集合包',
  12: 'CDK',
  13: '加速器',
  19: '换购卡',
  21: '会员',
  26: '换购',
  17: '礼包抽奖',
  18: 'VIP服务',
  23: '升级CDK',
  22: '代购',
  27: '开箱次数',
  31: '福袋集合包',
  32: '道具',
  34: '存档',
  37: '会员升级',
  38: '代充',
  39: '捆绑包',
  41: '加速器时长',
  42: '云游戏',
  43: '合约',
  47: '特价集合包',
  50: '汉化包',
  51: 'MOD',
  130: '首充',
}

export enum GameOrderStatus { //['未支付', '支付成功', '购买成功', '处理中', '购买失败', '维权中', '退款成功', '退款失败']
  '未支付' = 0,
  '支付成功' = 1,
  '购买成功' = 2,
  '处理中' = 3,
  '购买失败' = 4,
  '维权中' = 5,
  '退款成功' = 6,
  '退款失败' = 7,
  '取消维权' = 8,
  '已结束' = 9,
  '退款中' = 10,
}

//夏日促销活动编号
export const SUMMER_ACTIVITY_NO='AT5637158764841771947'
//开学促销活动编号
export const SCHOOL_ACTIVITY_NO='AT5652688787652931827'

export const SUMMER_ACTIVITY_GOODS_ID= ['preview', 'production'].includes(process.env.MODE || '') ? 1578283601 : 1578281166

export enum DOMID {
  LAYOUT_CONTENT = 'layoutContent',
  MINI_LAYOUT_CONTENT = 'miniLayoutContent'
}

/** 用来计算详情页高度 */
export const DETAIL_INDEX_HEIGHT = {
  GAME_NAME_LINE_HEIGHT: 42,
  GAME_EN_NAME_LINE_HEIGHT: 14,
  GAME_EN_NAME_MARGIN_TOP: 12,
  GAME_NAME_MARGIN_BOTTOM: 40,
  GAME_DETAIL_CONTAINER_PADDING: 40,
  GAME_DETAIL_TOOLS_TAB_HEIGHT: 98
}

// 用来计算加速器详情页的table高度的常量
export const SPEED_DETAIL_TABLE_Y = {
  SPEED_AREA_SECTION_ID: 'speed-area-section',
  SPEED_SERVER_SECTION_ID: 'speed-server-section',
  SPEED_GAME_SECTION_ID: 'speed-game-section',
  SPEED_AREA_SERVER_MAEGIN_BOTTOM: 30,
  SPEED_SECTION_TITLE_LINE_HEIGHT: 22,
  SPEED_SECTION_TITLE_MARGIN_BOTTOM: 12,
  SPEED_AREA_ITEM_CLASS: 'area-item',
  SPEED_SERVER_ITEM_CLASS: 'server-item',
  SPEED_GAME_ITEM_CLASS: 'speed-game-item'
}

// 用来计算修改器详情页的table高度的常量
export const MODIFIER_DETAIL_TABLE_Y = {
  // EDIT_HEADER_ID: 'edit-header',
  // EDIT_HEADER_ITEM_CLASS: 'detail-platform-item',
}

// 用来计算CDK兑换页面table高度的常量
export const GIFT_KEY_TABLE_Y = {
  CONTAINER_PADDING: 44,
  H1_LINE_HEIGHT: 20,
  H1_MARGIN_BOTTOM: 24,
  CONTENT_MARGIN_TOP: 60,
  CONTENT_PADDING_BOTTOM: 50,
  INPUT_HEIGHT: 36,
  PAGINATION_HEIGHT: 32,
  PAGINATION_MAEGIN_TOP: 32
}



// 游戏平台的映射
export const PLATFORM_MAPPING: Record<Modifier.TPlaformType, { key: Modifier.TPlaformType; Icon: FC; alias: string; sort: number }> = {
  steam: {
    key: 'steam',
    alias: 'Steam',
    Icon: SteamIcon,
    sort: 991,
  },
  epic: {
    key: 'epic',
    alias: 'Epic',
    Icon: EpicIcon,
    sort: 992,
  },
  origin: {
    key: 'origin',
    alias: 'Origin',
    Icon: OriginIcon,
    sort: 993,
  },
  uplay: {
    key: 'uplay',
    alias: 'Uplay',
    Icon: UplayIcon,
    sort: 994,
  },
  gog: {
    key: 'gog',
    alias: 'GOG',
    Icon: GogIcon,
    sort: 995,
  },
  uwp: {
    key: 'uwp',
    alias: 'UWP',
    Icon: UwpIcon,
    sort: 996,
  },
  rockstar: {
    key: 'rockstar',
    alias: 'RockStar',
    Icon: RockstarIcon,
    sort: 997,
  },
  itch: {
    key: 'itch',
    alias: 'Itch',
    Icon: UwpIcon,
    sort: 998,
  },
  standalone: {
    key: 'standalone',
    alias: 'Standalone',
    Icon: StandaloneIcon,
    sort: 999,
  },
}

export const SORT_BY_INITIALS: Record<string, number | undefined> = {
  a: 26,
  b: 25,
  c: 24,
  d: 23,
  e: 22,
  f: 21,
  g: 20,
  h: 19,
  i: 18,
  j: 17,
  k: 16,
  l: 15,
  m: 14,
  n: 13,
  o: 12,
  p: 11,
  q: 10,
  r: 9,
  s: 8,
  t: 7,
  u: 6,
  v: 5,
  w: 4,
  x: 3,
  y: 2,
  z: 1,
}


export const BUFF_ERROR_CODE = {
  1000: '注入成功',
  1001: '游戏进程未启动',
  1002: '用户鉴权失败',
  1003: '游戏DLL更新失败',
  1004: '创建通信管道失败',
  1005: '游戏DLL注入失败',
  1006: '调用Trainer的方法失败',
  1007: '缺少必要的调用参数',
  1008: '无效的管道',
  1009: '发送管道数据失败',
  1010: '参数错误',
  1011: '重新启动小蓝熊游戏失败',
  // 超时
  1012: 'Buff加持超时，请稍后重试',
  1014: '平台未登录',

  // 
  2000: '加持的进程被关闭'
}


export const EDIT_ITEM_CLASS_MAPPING: Record<Modifier.TCheatsCategory, { title: string; icon: FC; key: string }> = {
  player: {
    title: '玩家',
    icon: PlayerIcon,
    key: 'player',
  },
  stats: {
    title: '统计数据',
    icon: StatsIcon,
    key: 'stats',
  },
  game: {
    title: '游戏',
    icon: GameIcon,
    key: 'game',
  },
  enemies: {
    title: '敌人',
    icon: EnemiesIcon,
    key: 'enemies',
  },
  inventory: {
    title: '物品栏',
    icon: InventoryIcon,
    key: 'inventory',
  },
  weapons: {
    title: '武器',
    icon: WeaponsIcon,
    key: 'weapons',
  },
  vehicles: {
    title: '载具',
    icon: VehiclesIcon,
    key: 'vehicles',
  },
  physics: {
    title: '物理效果',
    icon: PhysicsIcon,
    key: 'physics',
  },
  teleport: {
    title: '瞬间移动',
    icon: TeleportIcon,
    key: 'teleport',
  },
}

export const CHEAT_TYPE_MAPPING: Record<Modifier.TCheatsType, { hotkeysName: string[] }> = {
  number: {
    hotkeysName: ['减少', '增加'],
  },
  toggle: {
    hotkeysName: ['切换'],
  },
  slider: {
    hotkeysName: ['减少', '增加'],
  },
  button: {
    hotkeysName: ['应用'],
  },
  selection: {
    hotkeysName: ['上一个', '下一个'],
  },
}

export const KEYCODE: Record<string, { text: string[] }> = {
  '9': {
    text: ['TAB', 'Tab'],
  },
  '13': {
    text: ['ENTER', 'Enter'],
  },
  '16': {
    text: ['SHIFT', 'LShift', 'RShift'],
  },
  '17': {
    text: ['CTRL', 'LControl', 'RControl'],
  },
  '18': {
    text: ['ALT', 'LAlt', 'RAlt'],
  },
  '20': {
    text: ['ESC', 'Escape'],
  },
  '32': {
    text: ['SPACE', 'Space'],
  },
  '33': {
    text: ['PGUP', 'PageUp'],
  },
  '34': {
    text: ['PGDN', 'PageDown'],
  },
  '35': {
    text: ['END', 'End'],
  },
  '36': {
    text: ['HOME', 'Home'],
  },
  '37': {
    text: ['←', 'Left'],
  },
  '38': {
    text: ['↑', 'Up'],
  },
  '39': {
    text: ['→', 'Right'],
  },
  '40': {
    text: ['↓', 'Down'],
  },
  '45': {
    text: ['INS', 'Insert'],
  },
  '46': {
    text: ['DEL', 'Delete'],
  },
  '48': {
    text: ['0', 'Key0'],
  },
  '49': {
    text: ['1', 'Key1'],
  },
  '50': {
    text: ['2', 'Key2'],
  },
  '51': {
    text: ['3', 'Key3'],
  },
  '52': {
    text: ['4', 'Key4'],
  },
  '53': {
    text: ['5', 'Key5'],
  },
  '54': {
    text: ['6', 'Key6'],
  },
  '55': {
    text: ['7', 'Key7'],
  },
  '56': {
    text: ['8', 'Key8'],
  },
  '57': {
    text: ['9', 'Key9'],
  },
  '65': {
    text: ['A'],
  },
  '66': {
    text: ['B'],
  },
  '67': {
    text: ['C'],
  },
  '68': {
    text: ['D'],
  },
  '69': {
    text: ['E'],
  },
  '70': {
    text: ['F'],
  },
  '71': {
    text: ['G'],
  },
  '72': {
    text: ['H'],
  },
  '73': {
    text: ['I'],
  },
  '74': {
    text: ['J'],
  },
  '75': {
    text: ['K'],
  },
  '76': {
    text: ['L'],
  },
  '77': {
    text: ['M'],
  },
  '78': {
    text: ['N'],
  },
  '79': {
    text: ['O'],
  },
  '80': {
    text: ['P'],
  },
  '81': {
    text: ['Q'],
  },
  '82': {
    text: ['R'],
  },
  '83': {
    text: ['S'],
  },
  '84': {
    text: ['T'],
  },
  '85': {
    text: ['U'],
  },
  '86': {
    text: ['V'],
  },
  '87': {
    text: ['W'],
  },
  '88': {
    text: ['X'],
  },
  '89': {
    text: ['Y'],
  },
  '90': {
    text: ['Z'],
  },
  '96': {
    text: ['数字键 0', 'Numpad0'],
  },
  '97': {
    text: ['数字键 1', 'Numpad1'],
  },
  '98': {
    text: ['数字键 2', 'Numpad2'],
  },
  '99': {
    text: ['数字键 3', 'Numpad3'],
  },
  '100': {
    text: ['数字键 4', 'Numpad4'],
  },
  '101': {
    text: ['数字键 5', 'Numpad5'],
  },
  '102': {
    text: ['数字键 6', 'Numpad6'],
  },
  '103': {
    text: ['数字键 7', 'Numpad7'],
  },
  '104': {
    text: ['数字键 8', 'Numpad8'],
  },
  '105': {
    text: ['数字键 9', 'Numpad9'],
  },
  '106': {
    text: ['MULTIPLY', 'Numpad10'],
  },
  '107': {
    text: ['ADD', 'NumpadAdd'],
  },
  // 有疑问
  // '108': {
  //   text: ['ENTER']
  // },
  '109': {
    text: ['SUBTRACT', 'NumpadSubtract'],
  },
  // 监听不到
  // '110': {
  //   text: ['DECIMAL', '']
  // },
  '111': {
    text: ['DIVIDE', 'NumpadDivide'],
  },
  '112': {
    text: ['F1'],
  },
  '113': {
    text: ['F2'],
  },
  '114': {
    text: ['F3'],
  },
  '115': {
    text: ['F4'],
  },
  '116': {
    text: ['F5'],
  },
  '117': {
    text: ['F6'],
  },
  '118': {
    text: ['F7'],
  },
  '119': {
    text: ['F8'],
  },
  '120': {
    text: ['F9'],
  },
  '121': {
    text: ['F10'],
  },
  '122': {
    text: ['F11'],
  },
  '123': {
    text: ['F12'],
  },
  '186': {
    text: [';', 'Semicolon']
  },
  '187': {
    text: ['+', 'Equal']
  },
  '188': {
    text: [',', 'Comma']
  },
  '189': {
    text: ['-', 'Minus']
  },
  '190': {
    text: ['.', 'Dot']
  },
  '191': {
    text: ['/', 'Slash']
  },
  '192': {
    text: ['`', 'Grave'],
  },
  '219': {
    text: ['[', 'LeftBracket']
  },
  '220': {
    text: ['\\', 'BackSlash']
  },
  '221': {
    text: [']', 'RightBracket']
  },
  '222': {
    text: ["'", 'Apostrophe;']
  }
}
