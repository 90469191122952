import { observable, action } from 'mobx'
import { ToolsTypes } from 'src/constants/common'

class Config {
  @observable
  channelConfig: { defaultTool: ToolsTypes } | null = null

  @observable
  customerQQGroupUrl: string | null = null

  @observable
  freePlayConfig: { sort: number; id: number }[] | null = null

  @observable
  securitySoftwareConfig: { name: string; process: string; icon: string }[] = []

  @observable
  homeBannerList: { title: string; desc: string; btnText: string; img: string; jumpType: string; jumpValue: string }[] =
    []

  @observable
  homeResourceList: (Game.Resource & {
    gameLibraryMasterCnName: string
    gameLibraryMasterId: string | number
    supportGameVersion: string
    platformIcon: string
  })[] = []

  @observable
  homeToolsList: {
    iden: string
    name: string
    type: ToolsTypes
    platformName: string
    platformIcon: string
    mainType: 'game' | 'platform'
    mainId: string
  }[] = []

  @observable
  trialFaq: { title: string; url: string; goodsId: string }[] = []

  @observable
  speedFaq: { title: string; url: string; goodsId: string }[] = []

  @observable
  cloudGameFaq: { title: string; url: string; goodsId: string }[] = []

  @observable
  cloudGameAccountMode: { defaultMode: string } | undefined = undefined

  @observable
  baiduOcpcConfig:
    | { token: string; type: 'baidu' | 'haoduofen'; bdvid: string; logidUrl: string; channel: string }
    | undefined = undefined
}

export const ConfigStore = new Config()
