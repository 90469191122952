export type TAttr = {
  origin: number
  target: number
}

export const useEaseOutQuadAnimation = (k: number = 0.05, damping: number = 0.1, epsilon: number = 10) => {
  let animateKey: number | null = null

  const handleEaseOutQuadAnimation = (attrList: TAttr[], callback: (args: number[]) => void) => {
    animateKey && window.cancelAnimationFrame(animateKey)
    // const k = 0.05
    // const damping = 0.1
    // const epsilon = 10
    // 存放每个属性的当前值和速度
    let values = attrList.map(attr => ({ value: attr.origin, velocity: 0 }))

    function calculate() {
      const currentValues = []
      for (let i = 0; i < attrList.length; i++) {
        let attr = attrList[i]
        let value = values[i].value
        let velocity = values[i].velocity

        let dx = attr.target - value
        let ax = k * dx
        velocity = damping * (velocity + ax)
        value += velocity

        currentValues.push(value) // 将当前值保存在 currentValues 数组中
        values[i].value = value
        values[i].velocity = velocity
      }
      return currentValues
    }
    function animate() {
      const currentValues = calculate()
      callback(currentValues) // 在每次计算时调用回调函数返回计算出的当前值

      if (values.every((v, i) => Math.abs(attrList[i].target - v.value) < epsilon)) {
        // 动画完成时调用回调函数
        callback(values.map(v => v.value))
      } else {
        animateKey = window.requestAnimationFrame(() => {
          animate()
        })
      }
    }
    animate()
  }

  return {
    handleEaseOutQuadAnimation,
  }
}
