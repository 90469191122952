import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Slider, Tooltip, message } from 'antd'
import { ConfirmButton } from 'src/components/base-button'
import { CHEAT_TYPE_MAPPING, KEYCODE } from 'src/constants/common'
import { sum, subtract } from 'src/tools/common'
import QuestionIcon from 'src/assets/icons/modifier-detail/question.svg'
import Selector from 'src/components/base-selector'
import NumberSelector from 'src/components/base-number-selector'
import Switch from 'src/components/base-switch'
import SGSlider from 'src/components/base-slider'
import styled from 'styled-components'
import classNames from 'classnames'

const EditCheatItem: FC<{
  cheat: Modifier.TCheats
  disabled: boolean
  cheatsValue?: Modifier.TCheatsValue
  onChange: (uuid: string, value: number) => void
  startEditHotkeys: (uuid: string, index: number) => void
  currentEditHotkeys?: { uuid: string; index: number }
}> = ({ cheat, cheatsValue, onChange, startEditHotkeys, currentEditHotkeys, disabled }) => {
  const hotkeys = useMemo(() => {
    const cheatType = CHEAT_TYPE_MAPPING[cheat.type]
    console.log(cheatType, 'cheatType')
    console.log(cheat.type, 'cheat.type')
    return cheatType.hotkeysName.map((d, i) => {
      return {
        name: d,
        hotkeys:
          !cheatsValue || !cheatsValue.hotkeys[i] || cheatsValue.hotkeys[i].length === 0
            ? ['未设置']
            : cheatsValue.hotkeys[i].map(k => KEYCODE[k]?.text?.[0]),
      }
    })
  }, [cheat, cheatsValue])

  const cheatDescription = useMemo(() => {
    return cheatsValue?.zhCnDescription || cheatsValue?.description
  }, [cheatsValue])

  const cheatInstructions = useMemo(() => {
    return cheatsValue?.zhCnInstructions || cheatsValue?.instructions
  }, [cheatsValue])

  const CheatDescriptionContent = useMemo(() => {
    return (
      <StyledDescriotionContainer>
        {cheatDescription && <div>{cheatDescription}</div>}
        {cheatInstructions && (
          <div>
            <div>修改功能说明</div>
            {cheatInstructions}
          </div>
        )}
      </StyledDescriotionContainer>
    )
  }, [cheatDescription, cheatInstructions])

  const control = useMemo(() => {
    if (!cheatsValue) {
      return null
    }
    const verifyArsg =
      !!cheat.args &&
      typeof cheat.args.step === 'number' &&
      typeof cheat.args.max === 'number' &&
      typeof cheat.args.min === 'number'
    switch (cheat.type) {
      case 'toggle':
        return <Switch check={!!cheatsValue.value} onChange={e => onChange(cheat.uuid, e ? 1 : 0)} />
      case 'slider':
        if (!verifyArsg) {
          return null
        }
        return (
          <SGSlider
            disabled={disabled}
            value={cheatsValue.value!}
            max={cheat.args!.max!}
            min={cheat.args!.min!}
            step={cheat.args!.step!}
            onChange={value => {
              onChange(cheat.uuid, value)
            }}
          />
        )
      case 'button':
        return (
          <StyledBaseButton onClick={() => onChange(cheat.uuid, 0)} shape='circle'>
            应用
          </StyledBaseButton>
        )
      case 'number':
        const handleChange = (type: 'add' | 'sub' | 'direct', value?: number) => {
          if (!verifyArsg) {
            return
          }
          if (type === 'add' && cheatsValue.value !== cheat.args!.max) {
            const finalValue = Math.min(sum(cheatsValue.value!, cheat.args!.step!), cheat.args!.max!)
            onChange(cheat.uuid, finalValue)
            return
          }
          if (type === 'sub' && cheatsValue.value !== cheat.args!.min) {
            const finalValue = Math.max(subtract(cheatsValue.value!, cheat.args!.step!), cheat.args!.min!)
            onChange(cheat.uuid, finalValue)
            return
          }
          if (type === 'direct' && typeof value === 'number') {
            onChange(cheat.uuid, value)
            return
          }
        }
        return (
          <NumberSelector
            value={cheatsValue.value!}
            disabled={disabled}
            onChange={handleChange}
            max={cheat.args!.max!}
            min={cheat.args!.min!}
            hasSet={cheat.args?.button === 'set'}
          />
        )
      case 'selection':
        return (
          <Selector
            placeholder='...'
            disabled={disabled}
            options={cheatsValue.args?.options || []}
            value={cheatsValue.value}
            onChange={value => onChange(cheat.uuid, value)}
          />
        )
    }
  }, [cheat, cheatsValue?.value, disabled])
  return (
    <StyledEditCheatItemContainer
      onClick={() => disabled && message.warn('请先给游戏「一键加Buff」成功后，再进行游戏修改')}
    >
      <div className='edit-cheat-title'>
        <div className='edit-cheat-text'>{cheat.zhCnName || cheat.name}</div>
        {(cheatDescription || cheatInstructions) && (
          <Tooltip
            placement='bottom'
            color={'rgba(97, 97, 101, 0.8)'}
            overlayInnerStyle={{
              borderRadius: '4px',
            }}
            title={CheatDescriptionContent}
          >
            <QuestionIcon className='edit-cheat-description' />
          </Tooltip>
        )}
      </div>
      <div className='edit-cheat-control'>{control}</div>
      <div className='edit-cheat-hotkeys'>
        {hotkeys.map((d, i) => {
          const isEditing = currentEditHotkeys?.uuid === cheat.uuid && currentEditHotkeys.index === i
          return (
            <div
              className={classNames('edit-cheat-hotkey-item', { editing: isEditing, disabled })}
              key={`hotkey-${i}`}
              onClick={() => startEditHotkeys(cheat.uuid, i)}
            >
              <div className='edit-cheat-hotkey-item-name'>{d.name}</div>
              <div className='edit-cheat-hotkey-item-hotkeys'>
                {d.hotkeys.map((j, index) => {
                  const isLast = index === d.hotkeys.length - 1
                  return (
                    <div className={classNames('edit-cheat-hotkey-item-hotkey', { isLast })} key={`hotkey-${i}-${j}`}>
                      {j}
                    </div>
                  )
                })}
                <div className={classNames('edit-cheat-hotkey-item-mask')}>
                  {isEditing ? (
                    <Tooltip overlay='重新选择快捷键' visible={true} placement='bottomRight'>
                      <div className='edit-cheat-hotkey-item-editing'>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>修改</div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </StyledEditCheatItemContainer>
  )
}

const StyledDescriotionContainer = styled.div`
  font-size: 12px;
  color: var(--text-color-FFFFFF);
  div:nth-child(2) {
    border-top: 1px solid #979797;
    margin-top: 1px;
  }
`

const StyledBaseButton = styled(ConfirmButton)`
  width: 40px !important;
  height: 20px !important;
  font-size: 12px;
  line-height: 12px;
  color: ${props => props.theme.color.text.$1E1E20};
  background: ${props => props.theme.color.bg.$EE921A};
  border-color: ${props => props.theme.color.bg.$EE921A};
  &:focus,
  &:hover {
    color: ${props => props.theme.color.text.$1E1E20};
    background: ${props => props.theme.color.bg.$EE921A};
    border-color: ${props => props.theme.color.bg.$EE921A};
    filter: brightness(0.9);
  }
`

const StyledEditCheatItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px 12px 10px 8px;
  &:hover {
    background: rgba(197, 152, 255, 0.08);
    .edit-cheat-hotkeys {
      .edit-cheat-hotkey-item {
        position: relative;
        .edit-cheat-hotkey-item-name {
          background: ${props => props.theme.color.text.$C598FF};
          color: #17023b;
        }
        .edit-cheat-hotkey-item-hotkeys {
          .edit-cheat-hotkey-item-hotkey {
            border-color: ${props => props.theme.color.text.$C598FF};
            color: ${props => props.theme.color.text.$C598FF};
          }
        }
      }
    }
  }
  .edit-cheat-hotkeys {
    width: 265px;
    text-align: left;
    .edit-cheat-hotkey-item {
      display: flex;
      align-items: center;
      border-radius: 4px 0 0 4px;
      overflow: hidden;
      cursor: pointer;
      &.disabled {
        user-select: none;
        pointer-events: none;
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
      &.editing,
      &:hover {
        .edit-cheat-hotkey-item-hotkeys .edit-cheat-hotkey-item-mask {
          opacity: 1;
        }
      }
      .edit-cheat-hotkey-item-name {
        height: 26px;
        padding: 0 6px;
        min-width: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        color: ${props => props.theme.color.text.$FFFFFF86};
        background: ${props => props.theme.color.bg.$444648};
      }
      .edit-cheat-hotkey-item-hotkeys {
        display: flex;
        align-items: center;
        position: relative;
        .edit-cheat-hotkey-item-hotkey {
          min-width: 38px;
          height: 26px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 14px;
          color: var(--text-color-FFFFFF);
          border: 1px solid #545458;
          &.isLast {
            border-radius: 0 4px 4px 0;
          }
        }
        .edit-cheat-hotkey-item-mask {
          position: absolute;
          top: 1px;
          right: 1px;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 14px;
          color: var(--text-color-FFFFFF);
          opacity: 0;
          .edit-cheat-hotkey-item-editing {
            display: flex;
            align-items: center;
            div {
              width: 3px;
              height: 3px;
              background: ${props => props.theme.color.text.$C598FF};
              border-radius: 50%;
              margin-right: 3px;
              animation: editing-indicator 0.5s infinite alternate;
            }
            div:nth-child(1) {
              animation-delay: 0s;
            }
            div:nth-child(2) {
              animation-delay: 0.25s;
            }
            div:nth-child(3) {
              animation-delay: 0.5s;
            }
          }
        }
      }
    }
  }
  .edit-cheat-title {
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.color.text.$FFFFFF86};
    width: 195px;
    display: flex;
    align-items: center;
    .edit-cheat-text {
      max-width: 150px;
    }
    .edit-cheat-description {
      font-size: 16px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
  .edit-cheat-control {
    flex: 1;
  }
`

export default observer(EditCheatItem)
