import React, { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { BaseModal } from 'src/components/base-modal'
import { ProtocolStore } from 'src/store/modifier'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
// import { PROTOCOL_CONTENT, WINDOWS_DEFENDER, FIND_PROCESS, VIP_PROTOCOL } from 'src/constants/protocol'
import styled from 'styled-components'
import classNames from 'classnames'

const ProtocolModal: FC = () => {
  const CurViewConfig = useMemo(() => {
    if (!ProtocolStore.protocolKey) {
      return null
    }
    return ProtocolStore.protocolConfig[ProtocolStore.protocolKey]
  }, [ProtocolStore.protocolKey, ProtocolStore.protocolConfig])

  const handleEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
      cefOpenUrl(e.target.href)
    }
  }
  const handleDragStartEventDelegation = (e: any) => {
    if (e.target.nodeName === 'A') {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  useEffect(() => {
    return () => {
      const contentDOM = document.getElementById('protocol-content')
      if (contentDOM) {
        contentDOM.scrollTop = 0
      }
    }
  }, [ProtocolStore.modalVisible])

  return (
    <StyledProtocolModalContainer
      zIndex={1011}
      visible={ProtocolStore.modalVisible}
      width={600}
      onCancel={() => ProtocolStore.cancelProtocolModal()}
    >
      <div className='protocol-title'>{CurViewConfig?.title}</div>
      <div
        id='protocol-content'
        className='protocol-content'
        onClick={handleEventDelegation}
        onDragStart={handleDragStartEventDelegation}
      >
        <div dangerouslySetInnerHTML={{ __html: CurViewConfig?.content || '' }} />
      </div>
    </StyledProtocolModalContainer>
  )
}

const StyledProtocolModalContainer = styled(BaseModal)`
  .ant-modal-body {
    padding: 40px;
  }
  .protocol-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: var(--text-color-FFFFFF);
  }
  .protocol-content {
    max-height: 520px;
    margin-top: 30px;
    padding-right: 30px;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: ${props => props.theme.color.text.$999999};
    overflow: overlay;
    img {
      max-width: 100% !important;
      height: auto !important;
      width: auto !important;
    }
  }
  a {
    color: ${props => props.theme.color.mainColor} !important;
    &:hover {
      color: ${props => props.theme.color.text.$D8B3FF} !important;
    }
  }
`

export default observer(ProtocolModal)
