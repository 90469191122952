import Socket from '@wfpack/socket'
import { stringify } from 'querystring'
import { sha256 } from 'js-sha256'
import { localStorageTool, sessionStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS } from 'src/constants/storage'
import md5 from 'js-md5'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'

export interface SendData {
  packetNo: string
  messageGroup: 'GAME_CLOUD'
  messageCode: string
  systemId: typeof systemId
  data: any
}
export interface MessageData<T = any, C = string> {
  code: C
  prompt: string
  result: T
}

function UUID() {
  const generatorUUID = (formatString: string) => {
    return formatString.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
  return generatorUUID('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx').replace(/-/g, 'x')
}

const systemId = 'sgt'
const query = new URLSearchParams(location.search)
const promotionID = query?.get('SetupName')?.split('@')?.[1]
const clientMac = query?.get('mac') || localStorage.getItem('pc_mac') || ''
const disableWs = query?.get('disableWs') || ''
const disableHost = ['^https?://login', '^https?://speed-ball', '^https?://localhost:9014']

/** ws url */
export const getWsUrlWithQuery = (query: Record<string, string | number>) => {
  return `${process.env.SG_APP_WSS_SERVICE}/v1?${stringify(query)}`
}

/** 参数签名 */
export const getQuerySign = (options: Record<string, string | number>) => {
  const keys: string[] = Object.keys(options).sort()
  const uuStr = options.nonce + '' + options.currentTime
  const source = keys
    .filter(k => k !== 'sign')
    .map(k => `${k}=${options[k]}`)
    .join('&')
  return md5(
    sha256(source + uuStr)
      .split('')
      .reverse()
      .join('') + uuStr,
  )
}

type UserType = 'user_id' | 'promotion_id' | 'uuid'

const getFirstPageChannel = () => {
  let indexViewUrl = sessionStorageTool.get(SESSIONSTORAGE_KEYS.INDEX_URL)
  if (!indexViewUrl) {
    sessionStorageTool.set(SESSIONSTORAGE_KEYS.INDEX_URL, location.href)
    indexViewUrl = location.href
  }
  /**无效的域名渠道*/
  const ignoreHostChannel = ['www', 'localhost']
  const url = new URL(indexViewUrl)
  /*通过域名获取渠道*/
  const HostChannel = url.hostname.split('.')[0] || ''
  const finalHostChannel = HostChannel.substring(HostChannel.indexOf('-')).replace('-', '')
  /*通过 URL 查询字段获取渠道*/
  const finalChannel =
    url.searchParams.get('channel') ||
    (ignoreHostChannel.includes(HostChannel) ? undefined : finalHostChannel) ||
    'hgspeed'
  return finalChannel
}

/** ws参数 */
export const getConnectQuery = (type: UserType = 'uuid', userGuid?: string | number) => {
  if (type !== 'user_id' && promotionID) {
    type = 'promotion_id'
    userGuid = promotionID
  }
  const option = {
    systemId: systemId,
    channel: getFirstPageChannel(),
    userType: type, // 用户类型(user_id: 已登录, 用户id, promotion_id：未登录，推广标识, uuid：未登录, 连接uuid流水号)
    userGuid: userGuid|| UUID(),
    deviceType: 'pc',
    deviceId: clientMac,
    currentTime: 0,
    nonce: '',
    sign: '',
  }
  option.nonce = UUID()
  option.currentTime = Date.now()
  option.sign = getQuerySign(option)
  if (!option.deviceId) {
    let cachedDeviceId = localStorage.getItem('deviceId')
    if (!cachedDeviceId) {
      cachedDeviceId = option.sign.substring(8, 24)
      localStorage.setItem('deviceId', cachedDeviceId)
    }
    option.deviceId = cachedDeviceId
  }
  option.sign = getQuerySign(option)
  return {
    url: getWsUrlWithQuery(option),
    immediate: true,
  }
}
export const getWsQuery = () => {
  let wsQuery = getConnectQuery()
  const userId = localStorageTool.get(LOCALSTORAGE_KEYS.USER_ID)
  if (userId) {
    wsQuery = getConnectQuery('user_id', userId)
  } else if (promotionID) {
    wsQuery = getConnectQuery('promotion_id', promotionID)
  }
  return wsQuery
}

export enum CLOUD_EVENTS {
  /** ws重试失败 */
  WS_DESTROYED = 'connect-max',
  /** 用户信息更新 */
  USER_UPDATE = 'userUpdate',
  /** 用户退出登录 */
  USER_LOG_OUT = 'userLogout',
}

export enum OTHER_MESSAGE {
  /** 优惠券到账通知 */
  ACTIVITY_PRESENT = 'ACTIVITY_PRESENT',
}

export enum CLOUD_MESSAGE {
  /** 通知linkUid */
  NOTICE_LINK_UID = 'msgpipe001',
  /**
   * 退出云游戏
   * 1. "cloud_game_stop_by_no_time", "时长用完,停止计费并退出云游戏通知"
   * 2. "cloud_game_wss_disconnect", "wss断开,退出云游戏通知"
   * 3. "cloud_game_quit_by_fwg", "反外挂通知云游戏退出"
   * 4. "cloud_game_start_time_out", 启动游戏超时
   **/
  EXIT_CLOUD_GAME = 'cloud_game_desktop_quit',
  /** k开始计费通知 */
  BEGIN_CLOUD_TIME = 'begin_cloud_time_advice',
  /** 云游戏桌面显示通知 */
  START_GAME_SUCCESS = 'cloud_game_desktop_show',
  /** 云游戏桌面隐藏通知 */
  HIDE_CLOUD_GAME = 'cloud_game_desktop_hide',
  /** 云游戏启动超时通知 */
  START_TIME_OUT = 'cloud_game_start_time_out',
  /** 云游戏资源释放完成通知 */
  CLOUD_GAME_RELEASE_ADVICE = 'cloud_game_release_advice',
  /** 云游戏启动进度 */
  CLOUD_GAME_START_PROGRESS = 'cloud_game_start_progress_advice',
  /**
   * 云游戏加速状态通知
   *  SPEED_SUCCESS("加速成功"),
   *  SPEED_FAILED("加速失败"),
   */
  CLOUD_GAME_SPEED_ADVICE = 'cloud_game_speed_advice',
  /** 云游戏存档保存通知 */
  CLOUD_GAME_CLOUD_HISTORY_SAVE = 'cloud_game_cloud_history_save',
  /** 云游戏时长不足5分钟 */
  CLOUD_GAME_ADVICE_CHARGE = 'cloud_game_advice_charge',
  /** 云游戏挂盘完成 */
  GAME_CLOUD_HANG_FINISHED_NOTICES = 'game_cloud_hang_finished_notices',
  /** 其它端游戏状态变化 */
  GAME_STATUS_CHANGE_NOTICES = 'game_status_change_notices',
  /** 其它端退出游戏 */
  CLOUD_GAME_QUIT_BY_HAND = 'cloud_game_quit_by_hand',
  /** 多人互动 */
  CLOUD_GAME_MULTIPLAYER_NOTICES = 'game_cloud_room_notices',
}

/** 云游戏启动进度明细 */
type TGameStatProcess = 'PLATFORM_PULL' | 'PLATFORM_LOGIN' | 'GAME_PULL'

/** 退出云游戏明细CODE */
type TExitTypeCode =
  | 'cloud_game_stop_by_no_time'
  | 'cloud_game_wss_disconnect'
  | 'cloud_game_quit_by_fwg'
  | 'cloud_game_start_time_out'

/** 其它端游戏状态变化明细CODE */
type TUserGameStatusChange = 'notice_status_playing' | 'notice_status_queueing' | 'notice_status_free'

interface EventMap {
  [OTHER_MESSAGE.ACTIVITY_PRESENT]: { code: string; result: { gifts: { bizNo: string }[] } }
  [CLOUD_EVENTS.USER_UPDATE]: { userId: string }
  [CLOUD_EVENTS.USER_LOG_OUT]: void
  [CLOUD_EVENTS.WS_DESTROYED]: void
  [CLOUD_MESSAGE.NOTICE_LINK_UID]: MessageData
  [CLOUD_MESSAGE.EXIT_CLOUD_GAME]: MessageData<MessageData<null, TExitTypeCode> & { detailCode: string }>
  [CLOUD_MESSAGE.BEGIN_CLOUD_TIME]: MessageData
  [CLOUD_MESSAGE.START_GAME_SUCCESS]: MessageData
  [CLOUD_MESSAGE.HIDE_CLOUD_GAME]: MessageData
  [CLOUD_MESSAGE.START_TIME_OUT]: MessageData
  [CLOUD_MESSAGE.CLOUD_GAME_START_PROGRESS]: MessageData<MessageData<null, TGameStatProcess>>
  [CLOUD_MESSAGE.CLOUD_GAME_RELEASE_ADVICE]: MessageData
  [CLOUD_MESSAGE.CLOUD_GAME_SPEED_ADVICE]: MessageData
  [CLOUD_MESSAGE.CLOUD_GAME_CLOUD_HISTORY_SAVE]: MessageData
  [CLOUD_MESSAGE.CLOUD_GAME_ADVICE_CHARGE]: MessageData
  [CLOUD_MESSAGE.GAME_CLOUD_HANG_FINISHED_NOTICES]: MessageData
  [CLOUD_MESSAGE.GAME_STATUS_CHANGE_NOTICES]: MessageData<
    MessageData<null, TUserGameStatusChange> & { event: 'END_GAME'; gameId: string }
  >
  [CLOUD_MESSAGE.CLOUD_GAME_QUIT_BY_HAND]: MessageData<MessageData<null, 'handOutByOtherTerminal'>>
}

const isDisabled = disableWs || disableHost.some(s => new RegExp(s).test(location.href)) || !window.FuluApp

// const url = isDisabled ?'':getWsQuery().url//
// console.log('SocketStore isDisabled',isDisabled,'url=', url)

const SocketStore = Socket<EventMap, SendData, MessageData>(isDisabled ?'':getWsQuery().url)

const messageWhiteList = [
  CLOUD_MESSAGE.GAME_STATUS_CHANGE_NOTICES,
  CLOUD_MESSAGE.CLOUD_GAME_QUIT_BY_HAND,
  CLOUD_MESSAGE.CLOUD_GAME_MULTIPLAYER_NOTICES,
  CLOUD_MESSAGE.NOTICE_LINK_UID,
]

SocketStore.interceptor.message.use(function (response) {
  if (
    (response?.result?.gameCloudOrderNo && response.result.gameCloudOrderNo === CloudGamePlayStore.gameCloudNo) ||
    messageWhiteList.includes(response.code as CLOUD_MESSAGE)
  ) {
    if (['test','development'].includes(process.env.MODE)) {
      console.group(`ws => ${response.code}`)
      console.log(response)
      console.groupEnd()
    }
    SocketStore.dispatch(response.code, response)
  }
  return response
})

// registerClearAuthStatusFn(() => SocketStore.dispatch(CLOUD_EVENTS.USER_LOG_OUT))
// registerClearAuthStatusFn(() => resetMultiplayerStore())

export default SocketStore
