import { observable, action } from 'mobx'

class WindowLoadingStore {
  @observable
  status: 'prefetch-update' | 'updating' | 'prefetch-data' | 'none' = 'prefetch-update'
  // @observable
  // promise: {
  //   _res: Function
  //   _rej: Function
  // } | null = null
}

export default new WindowLoadingStore()
