import { observable, action } from 'mobx'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { GameStore, ClientLocalStorageStore } from 'src/store/modifier'
import { SORT_BY_INITIALS } from 'src/constants/common'
import { notifyNewlyAddInstallGamesNotify } from 'src/components/base-notification'
import { message } from 'antd'
import CEFTransfer from 'src/CEFTransfer'

class InstalledGameStore {
  /** 已安装的游戏id数组 */
  @observable
  installedGameIdsList: number[] = []

  // 已安装的 game 列表
  @observable
  installedGameList: Modifier.TInstalledGame[] = []

  // 已安装的 title 列表(用于左侧菜单)
  @observable
  installedSimpleTitleList: Modifier.TLeftTitleInfo[] = []

  // 未安装的 title 列表(用于左侧菜单)
  @observable
  notInstalledSimpleTitleList: Modifier.TLeftTitleInfo[] = []

  isInstalled(id: number) {
    return !!this.installedGameIdsList?.find(f => f === id)
  }

  computedSortWeight(origin: {id: number, name: string}) {
    const latelyLaunchGameIds = ClientLocalStorageStore.getLatelyLaunchGameIds()
    let temp = latelyLaunchGameIds.findIndex(f => f === origin.id)
    if (temp !== -1) {
      return temp + 27
    }

    let initials = origin.name[0]
    if (!initials) {
      return temp
    }

    initials = initials.toLowerCase()
    return SORT_BY_INITIALS[initials] || -1
  }

  parsingInstalledGameList = (titleList: Modifier.TTitle[], installedGameIdList: number[]) => {
    const installedGameList: typeof this.installedGameList = []
    const finalTitleList: Modifier.TLeftTitleInfo[] = titleList.map(title => {
      let currentTitleTheInstalledGameList: typeof this.installedGameList = []
      GameStore.parsingGameSimpleListByTitle(title).forEach(game => {
        const isInstalled = installedGameIdList.includes(game.id)
        isInstalled &&
          currentTitleTheInstalledGameList.push({
            ...game,
            thumbnail: title.thumbnail,
            zhCnName: title.zhCnName,
            name: title.name,
          })
      })
      installedGameList.push(...currentTitleTheInstalledGameList)
      return {
        ...title,
        defaultGameId: currentTitleTheInstalledGameList.length > 0 ? currentTitleTheInstalledGameList[0].id : null
      }
    })

    return {
      installedTitleList: finalTitleList.filter(f => !!f.defaultGameId),
      notInstalledTitleList: finalTitleList.filter(f => !f.defaultGameId),
      installedGameList,
      finalTitleList
    }
  }

  listen = async () => {
    try {
      const params = await CEFTransfer.sendToClient('getMyGames')
      const recordInstallGameIds = ClientLocalStorageStore.getRecordInstallModifierGameIds()
      this.installedGameIdsList = params.map(Number)
      // this.installedGameIdsList =  [1, 2, 3, 4, 5, 6, 8 ]
      if (!GameStore.titleList) {
        return
      }
      ClientLocalStorageStore.setRecordInstallModifierGameIds(this.installedGameIdsList)
      // 新增加的game id
      // const newlyAddGameIds = recordInstallGameIds && this.installedGameIdsList.filter(f => !recordInstallGameIds.includes(f))
      const { installedTitleList, notInstalledTitleList, installedGameList } = this.parsingInstalledGameList(
        GameStore.titleList,
        // [1, 2, 3, 4, 5, 6, 8 ]
        this.installedGameIdsList
      )
      // 为null的话 则代表是第一次打开客户端 第一次扫描， 第一次扫描的游戏不需要进行通知
      // if (newlyAddGameIds) {
      //   const newlyAddGames = installedGameList.filter(f => newlyAddGameIds?.includes(f.id))
      //   if (newlyAddGames.length > 0) {
      //     notifyNewlyAddInstallGamesNotify(newlyAddGames)
      //   }
      // }
      this.installedSimpleTitleList = installedTitleList
      this.notInstalledSimpleTitleList = notInstalledTitleList
      this.installedGameList = installedGameList
    } catch (error) {}
    setTimeout(async () => {
      this.listen()
    }, 5000)
  }
}

export default new InstalledGameStore()
