import React from 'react'
import {
  syncUserInfoFromCache,
  loadProtocolByCMS,
  httpAdAnalyse,
  loadGlobalCmsConfig,
  loadIsWaitingForWhite,
  handleInitApiBaseURL,
  handleSetGlobalZoom,
  loadServerTimeOffset,
  cryptoByAES,
  loadChannelConfig,
  saveAuthInfo,
  initI18n,
  checkOversea,
  openUrl,
} from 'src/tools/common'
import { ConfirmButton } from 'src/components/base-button'
import { clientOpenReport } from 'src/apis/common-api'
import { reportLogHttp, cmsModDataByModId } from 'src/apis/cms-api'
import { openNotification } from 'src/components/base-notification'
import { message, MessageArgsProps } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ClientLocalStorageStore, InstalledGameStore, GameStore as ModifierGameStore } from 'src/store/modifier'
import { GameStore, Activity230817Store } from 'src/store'
import { VipStore } from 'src/store'
import { FadeinImage } from 'src/components/base-image'
import { emitter } from 'src/tools/emitter'
import { reportBaiduOcpcByRegistry } from 'src/tools/report'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { cefOpenUrl } from 'src/CEFTransfer/cefController'
import MessageSuccessIcon from 'src/assets/icons/notification/success.svg'
import CEFTransfer from 'src/CEFTransfer'
import warnImg from 'src/assets/images/message/warn-icon.png'
import successImg from 'src/assets/images/message/success-icon.png'
import WindowController from 'src/CEFTransfer/window-controller'
import initSocket from 'src/pages/game-detail/cloud-game/cloud-socket-store'
import TrialController from 'src/CEFTransfer/trialController'

const poolTempNotify = async () => {
  const { result } = await cmsModDataByModId({ modId: 'page_temp_notify_config' })
  const config: {
    btnText?: string
    desc?: string
    img?: string
    key?: string
    target?: string
    title?: string
  } | null = result.datas?.[0]?.properties
  if (config && config.btnText && config.desc && config.img && config.key && config.target && config.title) {
    const cache: null | string[] = localStorageTool.get(LOCALSTORAGE_KEYS.RECORD_TEMP_NOTIFY_IDS) || []
    const hasCache = !!cache && cache.includes(config.key)
    if (!hasCache) {
      let newCache = [cache, config.key]
      localStorageTool.set(LOCALSTORAGE_KEYS.RECORD_TEMP_NOTIFY_IDS, newCache)
      await openNotification(
        <div>
          <div
            style={{
              fontWeight: 700,
              fontSize: 20,
              lineHeight: '20px',
            }}
          >
            {config.title}
          </div>
          <div style={{ display: 'flex', marginTop: 24 }}>
            <div style={{ width: 126, height: 66, borderRadius: '4px', overflow: 'hidden' }}>
              <FadeinImage
                width={126}
                height={66}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                src={config.img}
              />
            </div>
            <div
              style={{
                marginLeft: 24,
                width: 360,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                fontSize: 14,
                lineHeight: '22px',
                color: '#C6C6C6',
              }}
            >
              {config.desc}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32 }}>
            <ConfirmButton
              style={{ width: 180, height: 42, marginLeft: 20, fontSize: 16, fontWeight: 700 }}
              onClick={() => cefOpenUrl(config.target!)}
            >
              {config.btnText}
            </ConfirmButton>
          </div>
        </div>,
        undefined,
        {
          styled: {
            width: 590,
          },
        },
      )
    }
  }
  setTimeout(poolTempNotify, 1000 * 60 * 10)
}

const bootstrap = () => {
  /** 初始化国际化 */
  initI18n()

  handleInitApiBaseURL()
  emitter.on('report-log', ({ params }: any) => {
    reportLogHttp(params)
  })
  emitter.on('report-registry', ({ params }: any) => {
    reportBaiduOcpcByRegistry(params.userId)
  })
  /** 设置全局dpi */
  handleSetGlobalZoom()
  /** 页面打开上报 */
  clientOpenReport()
  /** 页面打开上报1 */
  emitter.emit('report-log', {
    params: {
      encrypt: cryptoByAES(
        JSON.stringify({
          type: 'client',
          action: 'client-open',
        }),
      ),
    },
  })
  /** 判断是否过白中 */
  loadIsWaitingForWhite()
  /** 添加广告分析头 */
  httpAdAnalyse()
  /** 初始化云游戏socket */
  initSocket()
  /** 同步本地用户信息 */
  syncUserInfoFromCache()
  /** 加载CMS协议配置 */
  loadProtocolByCMS()
  /** 加载全局CMS配置 */
  loadGlobalCmsConfig().then(() => {
    GameStore.listenInstalled()
  })
  /** 加载渠道配置 */
  loadChannelConfig()
  /** 初始化服务端时间 */
  loadServerTimeOffset()
  /** 初始化cef window相关功能 */
  WindowController.init()
  /** 初始化cef 本地上号相关功能 */
  TrialController.init()
  /** 初始化activity230817活动配置 */
  Activity230817Store.loadConfig()

  CEFTransfer.sendToClient('getAutoRun').then(({ autoRun }: { autoRun: boolean }) => {
    ClientLocalStorageStore.setSystemAutoOpen(autoRun)
  })

  CEFTransfer.sendToClient('getDownloadPath').then(({ path }: { path: string }) => {
    ClientLocalStorageStore.setSystemResourceFilePath(path)
  })

  ModifierGameStore.listenTitleListUpdate()
  ModifierGameStore.refreshAllTitleList().then(() => {
    // InstalledGameStore.listen()
  })

  GameStore.getAllData().then(() => {
    GameStore.listenInstalled()
  })
  VipStore.handleLoadVipFreeConfig()
  VipStore.handleLoadTrainerFreeConfig()
  VipStore.handleLoadCloudGameFreeConfig()
  VipStore.handleCloudGameGiveFreeTime()
  VipStore.handleLoadFreeSubscribeConfig()

  initMessage()

  poolTempNotify()
}

const initMessage = async () => {
  message.config({
    top: 14,
    maxCount: 1,
  })

  message.warn = message.warning = (content, duration, onClose) => {
    if (Object.prototype.toString.call(content) === '[object Object]' && !!(content as MessageArgsProps)?.content) {
      return message.open({
        icon: (
          <FadeinImage
            width={24}
            height={24}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            src={warnImg}
          />
        ),
        ...(content as MessageArgsProps),
        className: `sg-message-container sg-message-container-warn ${(content as MessageArgsProps)?.className || ''}`,
        type: 'warning',
      })
    }
    if (typeof duration === 'function') {
      onClose = duration
      duration = undefined
    }
    return message.open({
      className: 'sg-message-container sg-message-container-warn',
      icon: (
        <FadeinImage
          width={24}
          height={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          src={warnImg}
        />
      ),
      type: 'warning',
      content,
      duration,
      onClose,
    })
  }

  message.success = (content, duration, onClose) => {
    if (Object.prototype.toString.call(content) === '[object Object]' && !!(content as MessageArgsProps)?.content) {
      return message.open({
        icon: (
          <FadeinImage
            width={24}
            height={24}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            src={successImg}
          />
        ),
        ...(content as MessageArgsProps),
        className: `sg-message-container sg-message-container-success ${
          (content as MessageArgsProps)?.className || ''
        }`,
        type: 'success',
      })
    }
    if (typeof duration === 'function') {
      onClose = duration
      duration = undefined
    }
    return message.open({
      className: 'sg-message-container sg-message-container-success',
      icon: (
        <FadeinImage
          width={24}
          height={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          src={successImg}
        />
      ),
      type: 'success',
      content,
      duration,
      onClose,
    })
  }
}

export const bootstrapCloudPage = () => {
  initMessage()
}

/**
 * 云游戏 sgt-mini端的bootstrap，去掉一些不必要的东西
 */
export const bootstrapMini = () => {
  //保存token
  saveAuthInfo()
  handleInitApiBaseURL()
  // emitter.on('report-log', ({ params }: any) => {
  //   reportLogHttp(params)
  // })
  /** 设置全局dpi */
  handleSetGlobalZoom()
  /** 页面打开上报 */
  // clientOpenReport()
  /** 页面打开上报1 */
  // emitter.emit('report-log', {
  //   params: {
  //     encrypt: cryptoByAES(
  //       JSON.stringify({
  //         type: 'client',
  //         action: 'client-open',
  //       }),
  //     ),
  //   },
  // })
  /** 判断是否过白中 */
  loadIsWaitingForWhite()
  /** 添加广告分析头 */
  httpAdAnalyse()
  /** 初始化云游戏socket */
  // initSocket()
  /** 同步本地用户信息 */
  // syncUserInfoFromCache()
  /** 加载CMS协议配置 */
  loadProtocolByCMS()
  /** 加载全局CMS配置 */
  loadGlobalCmsConfig()
  /** 加载渠道配置 */
  loadChannelConfig()
  /** 初始化服务端时间 */
  loadServerTimeOffset()
  /** 初始化cef window相关功能 */
  WindowController.init()
  /** 初始化cef 本地上号相关功能 */
  // TrialController.init()
  /** 初始化activity230817活动配置 */
  // Activity230817Store.loadConfig()

  // CEFTransfer.sendToClient('getAutoRun').then(({ autoRun }: { autoRun: boolean }) => {
  //   ClientLocalStorageStore.setSystemAutoOpen(autoRun)
  // })

  // CEFTransfer.sendToClient('getDownloadPath').then(({ path }: { path: string }) => {
  //   ClientLocalStorageStore.setSystemResourceFilePath(path)
  // })

  ModifierGameStore.listenTitleListUpdate()
  ModifierGameStore.refreshAllTitleList({ timeout: 10000 })
    .then(() => {
      // InstalledGameStore.listen()
    })
    .catch(e => message.warn(`refreshAllTitleList error(${e?.message || e})`))

  // GameStore.getAllData().then(() => {
  //   GameStore.listenInstalled()
  // })
  // VipStore.handleLoadVipFreeConfig()
  // VipStore.handleLoadTrainerFreeConfig()
  // VipStore.handleLoadCloudGameFreeConfig()
  // VipStore.handleCloudGameGiveFreeTime()
  // VipStore.handleLoadFreeSubscribeConfig()

  initMessage()
}

export default bootstrap
