import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Progress, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import { appStore } from 'src/store'
import { checkHasErr, gameStartStep } from './game-start-step'
import { HideCheckModal } from 'src/tools/common'
import { useIntl } from 'react-intl'
import CheckedIcon from 'src/assets/icons/common/checked.svg'
import ErrIcon from 'src/assets/icons/common/erricon.svg'
import SyncIcon from 'src/assets/icons/common/sync.svg'

const SystemMap = {
  '0': 'Win2000',
  '1': 'WinXP',
  '2': 'WinXP_PRO',
  '3': 'Win2003',
  '4': 'Win2003_R2',
  '5': 'WinHOME',
  '6': 'WinVISTA',
  '7': 'Win2008',
  '8': 'Win2008_R2',
  '9': 'Win7',
  '10': 'Win2012',
  '11': 'Win8',
  '12': 'Win2012_R2',
  '13': 'Win8.1',
  '14': 'Win2016',
  '15': 'Win10',
  '16': 'Win11',
  '100': '其他',
}



const GameStartProgress = () => {

  const {formatMessage} = useIntl()

  const stepInfo = [
    {
      title: (
        <>
          {formatMessage({id:'start.step1'})}
        </>
      ),
      desc: null,
    },
    {
      title: (
        <>
          {formatMessage({id:'start.step2'})}
        </>
      ),
      desc: <>{formatMessage({id:'start.step2Desc'})}</>,
    },
    {
      title: (
        <>
          {formatMessage({id:'start.step3'})}
        </>
      ),
      desc: null,
    },
    {
      title: (
        <>
          {formatMessage({id:'start.step4'})}
        </>
      ),
      desc: (
        <>
          {formatMessage({id:'start.step4Desc'})}
        </>
      ),
    },
  ]

  const envCheckInfo = [
    {
      normal: formatMessage({id:'env.os'}),
      err: formatMessage({id:'env.osLow'}),
      getErrTips(value: number) {
        const text = SystemMap[String(value) as keyof typeof SystemMap]
        return (
          <>
            {formatMessage({id: 'env.osLowDesc'},{
              text,
              span:(t)=><span>{t}</span>
            })}
          </>
        )
      },
    },
    {
      normal: formatMessage({id:'env.network'}),
      err: formatMessage({id:'env.networkLow'}),
      getErrTips() {
        return (
          <>
            {formatMessage({id:'env.networkLowDesc'},{
              span:(t)=><span>{t}</span>
            })}
          </>
        )
      },
    },
    {
      normal: formatMessage({id:'env.cpu'}),
      err: formatMessage({id:'env.cpuLow'}),
      getErrTips(value: number) {
        return (
          <>
            {formatMessage({id:'env.cpuLowDesc'},{
              text:Math.floor(Number(value)),
              span:(t)=><span>{t}</span>
            })}
          </>
        )
      },
    },
    {
      normal: formatMessage({id:'env.memory'}),
      err: formatMessage({id:'env.memoryLow'}),
      getErrTips(value: number) {
        return (
          <>
            {formatMessage({id:'env.memoryLowDesc'},{
              text:Math.floor(Number(value) * 100) / 100,
              span:(t)=><span>{t}</span>
            })}
          </>
        )
      },
    },
  ]

  const currentStepIndex = Math.max(appStore.gameStartStep - 1, 0)
  const currentStepData = stepInfo[currentStepIndex]

  const stop = () => {
    gameStartStep.checkEnvStop()
    HideCheckModal()
  }

  return (
    <>
      <StyledProgress
        percent={appStore.gameStartProgress}
        status="active"
        strokeColor={'rgba(112,68,205,0.12)'}
        strokeWidth={77}
        trailColor="transparent"
        showInfo={false}
      />
      <StyledGameStartProgress>
        <StyledStartStep>{currentStepIndex + 1}</StyledStartStep>
        <StyledStartInfo>
          <div className="title">
            {currentStepData.title}
            <span className="dot">...</span>
          </div>
          {(currentStepData.desc || currentStepIndex === 0) && (
            <div className="desc">
              {currentStepIndex === 0 ? (
                <ul className="env">
                  {envCheckInfo.map((value, index) => {
                    const hasErr = checkHasErr(index)
                    const checkData = appStore.gameEnvStatus[index]
                    return (
                      <li key={index}>
                        {hasErr ? value.err : value.normal}
                        {typeof checkData !== 'undefined' ? (
                          hasErr ? (
                            <StyledTooltip
                              title={<StyledErrTips>{value?.getErrTips(checkData)}</StyledErrTips>}
                              arrowPointAtCenter={true}
                              color={'#121314'}
                              overlayClassName={'env-err-tooltip'}
                            >
                              <ErrIcon className="err" />
                            </StyledTooltip>
                          ) : (
                            <CheckedIcon className="ok" />
                          )
                        ) : (
                          <SyncIcon className="ing" />
                        )}{' '}
                      </li>
                    )
                  })}
                </ul>
              ) : (
                currentStepData.desc
              )}
            </div>
          )}
        </StyledStartInfo>
        {/*{appStore.gameStartHasEnvError && <StyledEndGame onClick={stop}>结束启动</StyledEndGame>}*/}
        <StyledGlobal />
      </StyledGameStartProgress>
    </>
  )
}

const StyledEndGame = styled.div`
  width: 68px;
  height: 24px;
  background: #4f462f;
  border-radius: 4px;
  font-size: 12px;
  color: rgba(217, 217, 217, 1);
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 11px;
  transition: all 0.3s;
  &:hover {
    background: #6b5f40;
  }
`

const StyledTooltip = styled(Tooltip)``

const StyledGlobal = createGlobalStyle`
  .env-err-tooltip {
    max-width: unset;
  }

  .ant-tooltip-inner {
    border-radius: 4px;
  }
`

const StyledErrTips = styled.div`
  color: #f5f5f7;
  font-size: 12px;
  white-space: nowrap;

  > span {
    color: #C48424;
  }
`

const StyledProgress = styled(Progress)`
  position: absolute;
  //width: calc(100% - 170px);
  left: 0;
  top: 0;
  z-index: -1;

  .ant-progress-inner,
  .ant-progress-bg,
  .ant-progress-bg,
  &.ant-progress-status-active .ant-progress-bg:before {
    border-radius: 0;
  }
  .ant-progress-bg {
    //transform: skewX(-10deg) translateX(-5px);
  }
`
const StyledStartStep = styled.div`
  width: 68px;
  font-family: Impact, serif;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.12);
  text-align: center;
  line-height: 1;
`

const StyledStartInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
  font-size: 12px;

  .title {
    color: #C19CFE;
    display: flex;
    font-size: 16px;
    line-height: 1;
    
    .dot {
      width: 0;
      overflow: hidden;
      animation: dot 2s linear infinite;
    }
    @keyframes dot {
      0% {
        width: 0;
      }
      50% {
        width: 3px;
      }
      100% {
        width: 9px;
      }
    }
  }

  .desc {
    margin-top: 12px;
    color: #96989D;
    line-height: 16px;
    font-size: 12px;
    > span {
      color: var(--main-color);
    }
  }

  .env {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    li {
      margin-right: 20px;
      display: flex;
      align-items: center;
      height: 16px;
      >svg {
        margin-left: 4px;
      }
    }

    

    .ok {
      color: #3ea72a;
    }

    .err {
      color: #942a2a;
      cursor: pointer;
    }

    .ing {
      color: #757575;
      animation: loading 1.6s linear infinite;
      @keyframes loading {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`

const StyledGameStartProgress = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 400px);
  //>*{
  //  z-index: 1;
  //}
`

export default observer(GameStartProgress)
