import qs from 'qs'
import fetch from 'src/fetch/fetch'
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

const BaseFetch = <R extends Record<string, any> = {}, P extends Record<string, any> = {}>({
  url,
  method = 'get',
  options: { type = 'json', resInterceptors, ...options } = {},
}: Fetch.BaseFetchPayload<R, AxiosRequestConfig>): Fetch.FinalFetch<R, P> => {
  const fetchMethod = method.toLowerCase()
  if (fetchMethod !== 'post' && fetchMethod !== 'get') {
    throw new Error('暂不支持除了post和get以外的请求, 需要的得另外配置')
  }
  const targetUrl = url

  const requestField = fetchMethod === 'post' ? 'data' : 'params'

  const finalFetch: Fetch.FinalFetch<R, P> = async (
    data?,
    config?: AxiosRequestConfig,
    interceptorsConfig?: { warnToast?: boolean },
  ) => {
    const finalData = type === 'form' ? qs.stringify(data) : data

    const requestPayload = { [requestField]: finalData }

    const presetHeader: AxiosRequestHeaders = type === 'form' ? { 'content-type': 'multipart/form-data' } : {}
    const finalHeader = {
      ...presetHeader,
      ...(options?.headers || {}),
      ...(config?.headers || {}),
    }

    const isWebHost = !!window.baseURL && options.baseURL?.includes('https://web.mybuff.com')
    const isCMSHost = !!window.cmsBaseURL && options.baseURL?.includes('https://api.mybuff.com')
    let baseURL = options.baseURL
    baseURL = isWebHost ? options.baseURL!.replace('https://web.mybuff.com', window.baseURL!) : baseURL
    baseURL = isCMSHost ? options.baseURL!.replace('https://api.mybuff.com', window.cmsBaseURL!) : baseURL

    const result: R = await fetch.request({
      url: targetUrl,
      method: fetchMethod,
      ...requestPayload,
      ...(options || {}),
      ...(config || {}),
      ...{
        baseURL,
      },
      headers: finalHeader,
    })
    return resInterceptors ? resInterceptors(result, { warnToast: interceptorsConfig?.warnToast }) : result
  }
  return finalFetch
}

export default BaseFetch
