import { observable, action, computed } from 'mobx'
import { subtract, sum, notifyOpenVipByModifier, showFeedback } from 'src/tools/common'
import { emitter } from 'src/tools/emitter'
import { message } from 'antd'
import { appStore } from 'src/store'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { saveCheatsConfigHttp, injectHeartBeatHttp, injectStatusChangeHttp } from 'src/apis/user-api'
import { VipStore } from 'src/store'
// import { getAdminGlobalConfig, reportLogHttp } from 'src/apis/cms-api'
import { cryptoByAES, getFirstPageChannel } from 'src/tools/common'
import CEFTransfer from 'src/CEFTransfer'

// 正在运行中的状态
class RunningStore {
  @observable
  userId: number | null = null
  @observable
  titleInfo: Modifier.TTitle | null = null
  @observable
  trainerInfo: Modifier.TGame | null = null
  @observable
  gameSimpleInfo: Modifier.TGameSimple | null = null
  @observable
  cheatsNeedSave: boolean = false
  @observable
  cheatsValues: Record<string, Modifier.TCheatsValue> | null = null
  @observable
  gameLibraryName: string = ''
  @observable
  gamePlatformName: string = ''

  @observable
  currentStartNo: string = ''
  heartBeatTimer: null | number = null

  @computed
  get hasRunning() {
    return !!this.titleInfo
  }

  @action
  async clearHeartBeat() {
    this.heartBeatTimer && window.clearTimeout(this.heartBeatTimer)
  }

  @action
  async startHeartBeat(startNo: string) {
    if (!this.hasRunning) {
      return false
    }
    const { result } = await injectHeartBeatHttp({ startNo })
    if (this.currentStartNo !== startNo) {
      return false
    }
    if (result.status === 'buffing') {
      this.clearHeartBeat()
      this.heartBeatTimer = window.setTimeout(() => this.startHeartBeat(startNo), 10000)
    } else {
      if (result.injectResultCode === '20004') {
        notifyOpenVipByModifier()
      }
      this.normalStopInjectBuff(false, true)
    }
    return result.status === 'buffing'
  }

  @action
  async clearRunningInfo() {
    this.clearHeartBeat()
    if (this.titleInfo && this.trainerInfo && this.userId) {
      emitter.emit('report-log', {
        params: {
          encrypt: cryptoByAES(
            JSON.stringify({
              userId: this.userId,
              gameId: this.trainerInfo.gameId,
              channel: getFirstPageChannel(),
              code: 1000,
              type: 'inject',
              action: 'stop-inject',
            }),
          ),
        },
      })
    }
    this.userId = null
    this.titleInfo = null
    this.trainerInfo = null
    this.gameSimpleInfo = null
    this.cheatsNeedSave = false
    this.cheatsValues = null
    this.currentStartNo = ''
    this.unListenHotkeysHit()
  }

  @action
  // 修改热键
  handleHotkeysChange(uuid: string, index: number, hotkeys: number[]) {
    if (!this.cheatsValues) {
      return
    }
    // 这里做修改可能导致不会自动响应
    this.cheatsValues[uuid].hotkeys[index] = hotkeys
    // 这里重新赋值是为了触发响应
    this.cheatsValues[uuid].hotkeys = [...this.cheatsValues[uuid].hotkeys]
    this.cheatsValues[uuid] = {
      ...this.cheatsValues[uuid],
    }
    this.cheatsValues = {
      ...this.cheatsValues,
    }
  }

  // 修改值
  handleCheatsValuesChange(type: 'keyboard', uuid: string, index: number): void
  handleCheatsValuesChange(type: 'mouse', uuid: string, value: number): void
  @action
  handleCheatsValuesChange(type: 'keyboard' | 'mouse', uuid: string, value: number) {
    if (!this.cheatsValues) {
      return
    }
    const currentCheatsValue = this.cheatsValues[uuid]
    if (type === 'keyboard') {
      switch (currentCheatsValue.type) {
        case 'number':
        case 'slider':
          currentCheatsValue.value =
            value === 1
              ? Math.min(currentCheatsValue.args!.max!, sum(currentCheatsValue.value!, currentCheatsValue.args!.step!))
              : Math.max(
                  currentCheatsValue.args!.min!,
                  subtract(currentCheatsValue.value!, currentCheatsValue.args!.step!),
                )
          CEFTransfer.sendToClient('sendCommand', {
            target: currentCheatsValue.target,
            value: currentCheatsValue.value,
          })
          break
        case 'selection':
          if (currentCheatsValue.value === undefined) {
            // value原本没有值的话 就直接用第一个或者最后一个
            currentCheatsValue.value = value === 1 ? currentCheatsValue.args!.options!.length - 1 : 0
          } else {
            let finalValue: number
            if (value === 1 && currentCheatsValue.value === 0) {
              // 按键为跳转前一个， 但是当前已经为0
              finalValue = currentCheatsValue.args!.options!.length - 1
            } else if (value === 1) {
              // 按键为跳转前一个
              finalValue = currentCheatsValue.value - 1
            } else if (value === 0 && currentCheatsValue.value === currentCheatsValue.args!.options!.length - 1) {
              // 按键为跳转后一个， 但是当前已经为最后一个
              finalValue = 0
            } else {
              finalValue = currentCheatsValue.value + 1
            }
            currentCheatsValue.value = finalValue
          }
          CEFTransfer.sendToClient('sendCommand', {
            target: currentCheatsValue.target,
            value: currentCheatsValue.value,
          })
          break
        case 'toggle':
          currentCheatsValue.value = currentCheatsValue.value === 0 ? 1 : 0
          CEFTransfer.sendToClient('sendCommand', {
            target: currentCheatsValue.target,
            value: currentCheatsValue.value,
          })
          break
        case 'button':
          CEFTransfer.sendToClient('sendCommand', { target: currentCheatsValue.target, value: 1 })
          break
      }
    }
    if (type === 'mouse') {
      switch (currentCheatsValue.type) {
        case 'number':
        case 'slider':
          currentCheatsValue.value = value
          CEFTransfer.sendToClient('sendCommand', {
            target: currentCheatsValue.target,
            value: currentCheatsValue.value,
          })
          break
        case 'selection':
          currentCheatsValue.value = value
          CEFTransfer.sendToClient('sendCommand', {
            target: currentCheatsValue.target,
            value: currentCheatsValue.value,
          })
          break
        case 'toggle':
          currentCheatsValue.value = value
          CEFTransfer.sendToClient('sendCommand', {
            target: currentCheatsValue.target,
            value: currentCheatsValue.value,
          })
          break
        case 'button':
          CEFTransfer.sendToClient('sendCommand', { target: currentCheatsValue.target, value: 1 })
          break
      }
    }

    // 这里做修改可能导致不会自动响应
    this.cheatsValues[uuid] = currentCheatsValue
    // 这里重新赋值是为了触发响应
    this.cheatsValues[uuid] = {
      ...this.cheatsValues[uuid],
    }
    this.cheatsValues = {
      ...this.cheatsValues,
    }
  }

  handleHotkeysHit = (params: string[]) => {
    if (!this.cheatsValues) {
      return
    }
    Object.values(this.cheatsValues).forEach(d => {
      d.hotkeys.forEach((keys, i) => {
        const hit = keys.length === params.length && !keys.find(f => !params.includes(`${f}`))
        if (hit) {
          this.handleCheatsValuesChange('keyboard', d.uuid, i)
        }
      })
    })
  }

  // 开启监听客户端传递过来的按键
  listenHotkeysHit() {
    if (!this.cheatsValues) {
      return
    }

    // 在这里先把上次保存的数据恢复起来
    Object.values(this.cheatsValues).forEach(d => {
      switch (d.type) {
        case 'number':
        case 'slider':
          if (!d.args) {
            break
          }
          if (d.value !== d.args.min) {
            CEFTransfer.sendToClient('sendCommand', { target: d.target, value: d.value })
          }
          break
        case 'toggle':
        case 'selection':
          if (d.value !== 0) {
            CEFTransfer.sendToClient('sendCommand', { target: d.target, value: d.value })
          }
          break
      }
    })
    emitter.on('hotkeysHit', this.handleHotkeysHit)
  }

  // 关闭监听客户端传递过来的按键
  unListenHotkeysHit() {
    emitter.off('hotkeysHit', this.handleHotkeysHit)
  }

  async normalStopInjectBuff(needFeedback?: boolean, heartBeatNotValid?: boolean) {
    if (!this.cheatsValues || !this.trainerInfo) {
      return message.warn('环境错误')
    }
    try {
      if (!heartBeatNotValid) {
        const isConfirm = await imperativeConfirmModal({
          title: '提示',
          content: '尊敬的MyBuff用户，请再次确认您要结束当前游戏的Buff加持',
          okText: '取消',
          cancelText: '确认停止',
        })
        if (isConfirm.value || isConfirm.target === 'close') {
          throw '用户取消结束'
        }
      }
      if (this.cheatsNeedSave) {
        const currentCheatsValues = Object.values(this.cheatsValues!)
          .filter(f => f.type !== 'selection')
          .map(d => {
            return {
              uuid: d.uuid,
              hotkeys: d.hotkeys,
              val: d.value,
            }
          })
        await saveCheatsConfigHttp({ trainerId: this.trainerInfo!.id, cheatsList: currentCheatsValues })
      }
      if (!heartBeatNotValid) {
        injectStatusChangeHttp({
          startNo: this.currentStartNo,
          injectStatusEnum: 'stop_buff',
          code: '19995',
          message: '正常结束',
        })
      }
      CEFTransfer.sendToClient('stopBuff')
      const gameLibraryName = this.gameLibraryName
      const gamePlatformName = this.gamePlatformName
      this.clearRunningInfo()
      VipStore.handleLoadVipInfo()
      VipStore.handleLoadTrainerFreeConfig()
      message.success('Buff加持结束，感谢您的使用')
      if (needFeedback) {
        showFeedback('modifier', {
          platform: gamePlatformName,
          gameName: gameLibraryName
        }, true)
      }
    } catch (error) {}
  }
}

export default new RunningStore()
