import { observable } from 'mobx'

const appStore = observable<Store.AppStore>({
  contactVisible: false,
  userCenterVisible:false,
  userCenterActive:null,
  userCenterReferer:'',
  currentDetail:null,
  leftElement:null,
  countryCode: 'CN',
  languageCode: 'zh-CN',
  serverTimeOffset: null,

  windowStatus: 0,
  isInWhiteList: false,
  vipgoodsRecommendConfig: null,
  speeding: false,

  bodySize: null,

  loginVisible: false,
  protocolConfig: {},
  currentProtocolType: '',
  vipGoodsList: [],
  clientAuthLoginFlag: false,
  realNameAuthModal: false,
  artificialAuthModal: false,

  mySubscribeListReload: 0,

  layoutBg: null,
  layoutBgType: null,

  feedbackController: {
    visible: false,
    type: null,
    counter: {
      modifier: 0,
      speed: 0,
      trial: 0,
      cloudGame: 0,
    },
    context: {},
  },
  /** 云存档下载完成 */
  downComplete: 0,
  /** 存档保存成功 */
  saveComplete: 0,
  archivesProgressObjMap: new Map(),
  newSaveArchive: {
    /**保存存档弹窗是否显示*/
    newSaveArchiveVisible: false,
    /** 新的保存存档的名称 */
    newSaveArchiveGameName: null,
    /** 保存存档的大小 单位字节 */
    newSaveArchiveSaveSize: null,
    /** 保存存档的时间 单位毫秒 */
    newSaveArchiveSaveTime: null,
    /** 保存存档订单号 */
    newSaveArchiveGameOrderNo: null,
    /** 保存存档订ID (云游戏模式使用)*/
    newSaveArchiveSaveId: null,
    /** 保存存档订KEY (云游戏模式使用) */
    newSaveArchiveSaveKey: null,
    /** 游戏存档弹出框 */
    newGameArchiveVisible: false,
    /** 客户端通知的保存存档模式 */
    newGameArchiveSaveMode: 'local',
  },
  modalVisibleList: [],
  gameUpdatedCompleted: false,
  GameCheckStep: 0,
  GameCheckErrorStep: -1,
  GameCheckErrorStepMessage: '', //游戏检测错误步骤消息
  platformInstallStatus: {},
  CheckModalVisible: false,
  StartGameTimeoutConfig: {default: 300},
  startGameOrderInfo: 0,
  startProblemVisible: false,
  archivesProgressObj: null,
  gameEnvStatus: {},
  gameStartStep: 0, //游戏启动进度
  gameStartProgress: 0, //游戏启动进度
  gameStartHasEnvError: false, //环境检测是否有异常
  autoContinueCountdown: 0, //自动开始下一步倒计时
  GameArchiveVisible: false,
  GamePlatformConfig: {},
  minimizeGameCheckVisible: false
})

export default appStore
