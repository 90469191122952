import { observable, action, computed } from 'mobx'
import { PLATFORM_MAPPING } from 'src/constants/common'
import { message } from 'antd'
import CEFTransfer from 'src/CEFTransfer'

class GameStore {
  firstFetchTitleList: boolean = true

  @observable
  titleList: Modifier.TTitle[] | null = null

  @observable
  activeTitleId: number | null = null

  @observable
  activeGameId: number | null = null

  @observable
  activeTrainerInfo: Modifier.TGame | null = null

  // 当前选中的title信息
  @computed
  get activeTitleInfo() {
    return this.titleList?.find(d => d.id === this.activeTitleId)
  }

  // 当前title下面的game的简单信息列表，
  // 因为服务端返回的数据是一个类数组， 所以在这里转成数组
  @computed
  get activeGameSimpleList() {
    if (this.activeTitleInfo) {
      return this.parsingGameSimpleListByTitle(this.activeTitleInfo)
    }
    return []
  }

  // 当前选中的title下面的game的简单信息
  @computed
  get activeGameSimpleInfo() {
    return this.activeGameSimpleList.find(d => d.id === this.activeGameId)
  }

  @action
  changeActiveTitle(id: number | null, gameId?: number) {
    console.log(this.titleList?.find(f => Object.values(f.games).find(v => !v.trainer)), 'this.titleList111111111')
    this.activeTitleId = id
    if (!id || !this.activeTitleInfo) {
      // 假如是清空activeTitle， 则对应的 也清空 activeGameInfo
      this.changeActiveGame(null)
      return
    }
    const activeGameId = gameId || this.activeGameSimpleList.find(f => !!f.trainer)?.id || null
    this.changeActiveGame(activeGameId)
  }

  @action
  async changeActiveGame(id: number | null) {
    if (id === this.activeGameId && this.activeTrainerInfo?.id === id) {
      return
    }
    // 先清空
    this.activeGameId = null
    this.activeTrainerInfo = null

    // 再根据清空赋值， 假如条件不存在则不赋值
    if (id) {
      const params = await CEFTransfer.sendToClient('getGameDetails', { gameid: id })
      if (params === null) {
        this.activeTrainerInfo = null
        return
      }
      if (!params.gameDesc.trainer) {
        return message.warn('该游戏没有修改项')
      }
      const finalActiveGameInfo = {
        ...params.gameDesc.trainer,
        fwgType: params.gameBrief.fwgType,
        needReadHelp: params.gameBrief.needReadHelp,
      }
      this.activeGameId = id
      this.activeTrainerInfo = finalActiveGameInfo as Modifier.TGame
    }
  }

  @action
  refreshAllTitleList = async (options?: { timeout: number }) => {
    const { titles } = await CEFTransfer.sendToClient(
      'getGameList',
      this.firstFetchTitleList ? { bNeedFirstUpdate: 1 } : {},
        {timeout: options?.timeout }
    )
    // const titles = this.titleList ? this.titleList : (await CEFTransfer.sendToClient(
    //   'getGameList',
    //   this.firstFetchTitleList ? { bNeedFirstUpdate: 1 } : {},
    // )).titles
    this.firstFetchTitleList = false
    if (!titles) {
      return message.warn('数据获取错误')
    }
    this.handleParsingTitleLiist(Object.values(titles))
  }

  @action
  handleParsingTitleLiist = (titles: Modifier.TTitle[]) => {
    const finalTitleList = titles.filter(d => {
      if (d.shelves !== 'up') {
        return false
      }
      const games = Object.values(d.games).filter(f => !!f.trainer && f.shelves === 'up')
      if (games.length === 0) {
        return false
      }
      d.games = {}
      games.forEach(j => {
        d.games[j.id] = j
      })
      return true
    })
    this.titleList = finalTitleList.sort((a, b) => (a.sort ?? 999) - (b.sort ?? 999))
  }

  parsingGameSimpleListByTitle(title: Modifier.TTitle) {
    if (!title.games) {
      return []
    }
    /**
     * 假如没有trainer的话， 在wemod里面 会展示这个平台， 但是会告诉用户没有这个修改器
     * 我们目前直接当做没有这个平台处理
     */
    return Object.values(title.games)
      .filter(f => !!f.trainer)
      .sort(this.simpleGamePlaftormSort)
  }

  findDefaultGameIdByTitle = (titleId: number) => {
    const title = this.titleList?.find(f => f.id === titleId)
    if (!title) {
      return null
    }
    console.log(this.parsingGameSimpleListByTitle(title), 'this.parsingGameSimpleListByTitle(title)')
    return this.parsingGameSimpleListByTitle(title).find(f => !!f.trainer)?.id || null
  }

  findTitleByGameId = (gameId: number) => {
    return this.titleList?.find(f => f.games[gameId])
  }

  //根据appId查找title和gameId
  findTitleByAppId = (appId: number, platform: string) => {
    let gameId = 0
    const title = this.titleList?.find(v => {
      return Object.values(v.games).find(j => {
        if (j.correlationIds?.includes(`${platform}:${appId}`)) {
          gameId = j.id
          return true
        }
        return false
      })
    })
    return { gameId, title }
  }

  simpleGamePlaftormSort = (a: Modifier.TGameSimple, b: Modifier.TGameSimple) => {
    if (a.sort && a.sort !== 0 && b.sort && b.sort !== 0) {
      return PLATFORM_MAPPING[a.platformId].sort - PLATFORM_MAPPING[b.platformId].sort
    }
    return (a.sort ?? PLATFORM_MAPPING[a.platformId].sort) - (b.sort ?? PLATFORM_MAPPING[b.platformId].sort)
  }

  listenTitleListUpdate = () => {
    CEFTransfer.subscribeClientMsg('updateGameList', props => {
      try {
        this.handleParsingTitleLiist(Object.values(props.titles))
      } catch (error) {}
    })
    // if (!window.wbAPI) {
    //   return
    // }
    // window.wbAPI.subscriptionClient2js('updateGameList', ({ params }) => {
    //   if (params === 'fail') {
    //     return message.warn('数据获取错误')
    //   }
    //   this.handleParsingTitleLiist(Object.values(params.titles))
    // })
  }
}

export default new GameStore()
