import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PLATFORM_MAPPING } from 'src/constants/common'
import styled from 'styled-components'
import classNames from 'classnames'

const PlatformAnimation: FC = () => {
  const [platformAnimationIndex, setPlatformAnimationIndex] = useState<number>(0)
  useEffect(() => {
    let timer: number | null = window.setInterval(() => {
      setPlatformAnimationIndex(i => {
        i += 1
        if (i === 7) {
          i = 0
        }
        return i
      })
    }, 2000)
    return () => {
      timer && clearInterval(timer)
      timer = null
    }
  }, [])
  return (
    <StyledPlatformAnimationContainer>
      {Object.keys(PLATFORM_MAPPING).map((k: unknown, i) => {
        const key = k as keyof typeof PLATFORM_MAPPING
        if (['itch', 'standalone'].includes(key)) {
          return null
        }
        const Icon = PLATFORM_MAPPING[key].Icon
        return (
          <StyledPlatformItem
            className={classNames({ active: i === platformAnimationIndex })}
            dir={i < 3 ? 'right' : 'left'}
          >
            <Icon />
            <div className='shadow-box' />
          </StyledPlatformItem>
        )
      })}
    </StyledPlatformAnimationContainer>
  )
}

const StyledPlatformItem = styled.div<{ dir: 'left' | 'right' }>`
  font-size: 0;
  color: #707477;
  position: relative;
  transform: translate(0, 0);
  transition: transform 300ms ease 0s;
  svg {
    font-size: 23px;
    line-height: 23px;
    position: relative;
    z-index: 1;
    left: 1px;
  }
  &:not(:last-child) {
    margin-right: 47px;
  }
  .shadow-box {
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    transition: height 300ms ease 0s, width 300ms ease 0s, opacity 200ms ease 0s;
    ${({ dir }) => {
      if (dir === 'left') {
        return `
          top: 0;
          left: 0;
        `
      }
      return `
        top: 0;
        right: 0;
      `
    }}
    &::before {
      content: '';
      display: block;
      width: 280px;
      height: 45px;
      position: absolute;
      bottom: 0px;
      ${({ dir }) => {
        if (dir === 'left') {
          return `
            transform: rotate(45deg);
            right: 32px;
            transform-origin: bottom right;
            background: linear-gradient(94deg, #A66FFF 0%, rgba(166,111,255,0) 95%);
          `
        }
        return `
          transform: rotate(-45deg);
          left: 32px;
          transform-origin: bottom left;
          background: linear-gradient(274deg, #A66FFF 0%, rgba(166,111,255,0) 95%);
        `
      }}
      z-index: -1;
      pointer-events: none;
    }
  }
  &.active {
    color: #FFFFFF;
    ${({ dir }) => {
      if (dir === 'left') {
        return `
          transform: translate(-100px, -100px);
        `
      }
      return `
        transform: translate(100px, -100px);
      `
    }}
    svg {
      font-size: 35px;
      line-height: 35px;
    }
    .shadow-box {
      width: 205px;
      height: 205px;
      opacity: 0.52;
    }
  }
`

const StyledPlatformAnimationContainer = styled.div`
  height: 165px;
  width: 100%;
  display: flex;
  align-items: flex-end;
`

export default observer(PlatformAnimation)
