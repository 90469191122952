import React, { FC } from 'react'
import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'
import classNames from 'classnames'

const CancelButton: FC<ButtonProps> = ({ children, ...props }) => {
  return <StyledCancelButton {...props} className={classNames('sg-cancel-btn', props.className)}>{children}</StyledCancelButton>
}

export default CancelButton

const StyledCancelButton = styled(Button)`
  width: 108px;
  height: 36px;
  border-radius: 4px;
  font-size: 13px;
  color: ${props => props.theme.color.text.$BBBBBB};
  background: ${props => props.theme.color.bg.$FFFFFF06};
  box-shadow: none;
  border-color: transparent;
  &:before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.color.text.$BBBBBB};
    background: ${props => props.theme.color.bg.$FFFFFF06};
    border-color: transparent;
    filter: brightness(1.4);
    &::after {
      box-shadow: none !important;
    }
  }
`
