import React, { FC, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { appStore, SpeedStore, VipStore } from 'src/store'
import { DOMID } from 'src/constants/common'
import { TAttr, useEaseOutQuadAnimation } from 'src/hooks/useEaseOutQuadAnimation'
import { FadeinImage } from 'src/components/base-image'
import { emitter } from 'src/tools/emitter'
import { ClientLocalStorageStore, StartingStore } from 'src/store/modifier'
import { initPostMessage } from 'src/pages/game-detail/cloud-game/client-post-message'
import { stopCloudGame } from 'src/pages/game-detail/cloud-game/cloud-game-action'
import { handleStopGame } from 'src/hooks/use-handle-game-start'
import { checkOversea, getSetupName } from 'src/tools/common'
import QueueSuccessModal from 'src/pages/game-detail/cloud-game/queue-success-modal'
import QueueInModal from 'src/pages/game-detail/cloud-game/queue-in-modal'
import ResourceDetailModal from 'src/pages/game-detail/resource/detail-modal'
import ResourceInformationModal from 'src/pages/game-detail/resource/information-modal'
import styled, { createGlobalStyle } from 'styled-components'
import routesUS from 'src/routes/index-en'
import routesCN from 'src/routes'
import ProtocolModal from 'src/components/protocol-modal'
import LayoutHeaderNew from 'src/layout/components/layout-header'
import logoImg from 'src/assets/images/common/logo.png'
import CrashesModal from 'src/components/cloud-game/CrashesModal'
import CloudGamePlayStore from 'src/store/cloud-game-play-store'
import ConfirmExitCloudGame from 'src/components/cloud-game/ConfirmExitCloudGame'
import BalanceInsufficientModal from 'src/components/cloud-game/BalanceInsufficientModal'
import ForceQuitModal from 'src/components/cloud-game/ForceQuitModal'
import NewSaveArchiveModal from 'src/pages/game-detail/trial/save-archive-modal'
import TrialQueueSuccessModal from 'src/pages/game-detail/trial/trial-queue-success-modal'
import TrialQueueInModal from 'src/pages/game-detail/trial/trial-queue-in-modal'
import Aside from 'src/layout/components/aside'
import UserCenterModal, { NAV_KEY_MAPPING, showUserCenter } from 'src/pages/user-center'

const NewLayout: FC = () => {
  const location = useLocation()
  const auraRef = useRef<HTMLDivElement>(null)
  const renderRoutes = useRoutes(checkOversea() ? routesUS : routesCN)
  const { handleEaseOutQuadAnimation } = useEaseOutQuadAnimation()
  const navigate = useNavigate()
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!auraRef.current) {
      return
    }
    const attrList: TAttr[] = []
    const transform = window.getComputedStyle(auraRef.current).getPropertyValue('transform') // 获取 transform 样式值
    const width = parseFloat(window.getComputedStyle(auraRef.current).getPropertyValue('width')) // 获取 transform 样式值
    let [, , , , tx, ty]: any =
      transform !== 'none' ? transform.match(/matrix\(([^\)]+)\)/)?.[1].split(',') : ['0', '0', '0', '0', '0', '0']
    const translateX = parseFloat(tx.trim())
    const translateY = parseFloat(ty.trim())
    const targetX = e.clientX - width / 2
    const targetY = e.clientY - width / 2
    attrList.push({
      origin: translateX,
      target: targetX,
    })
    attrList.push({
      origin: translateY,
      target: targetY,
    })
    handleEaseOutQuadAnimation(attrList, ([tx, ty]) => {
      if (!auraRef.current) {
        return
      }
      auraRef.current.style.transform = `translateX(${tx}px) translateY(${ty}px)`
    })
  }

  // useEffect(() => {
  //   if (!navigator.onLine) {
  //     imperativeConfirmModal({
  //       title: '网络连接断开',
  //       content: '当前无网络连接，请网络恢复后重试',
  //       okText: '我知道了',
  //       btns: ['ok'],
  //     })
  //   }
  //   window.addEventListener('offline', () => {
  //     if (!navigator.onLine) {
  //       imperativeConfirmModal({
  //         title: '网络连接断开',
  //         content: '当前无网络连接，请网络恢复后重试',
  //         okText: '我知道了',
  //         btns: ['ok'],
  //       })
  //       SpeedStore.stopSpeed({ notify: true, report: false, reason: 'NETWORK_DISCONNECT' })
  //     }
  //   })
  // }, [])
  const crashVisible = !!CloudGamePlayStore.cloudGameStartCrash

  const closeCloudErrorModal = () => {
    CloudGamePlayStore.cloudErrorModalType = undefined
  }

  const toRechargeCloudTime = () => {
    closeCloudErrorModal()
    //前往充值
    navigate(`/cloudgame-recharge`)
  }

  const crash = useMemo(() => {
    if (crashVisible) {
      return CloudGamePlayStore.cloudGameStartCrash
    }
    return null
  }, [crashVisible])

  // useEffect(() => {
  //   if (!window.WebGLBackground) {
  //     return
  //   }
  //   var starfield

  //   var pauseButton = document.createElement('button')
  //   pauseButton.id = 'pauseButton'
  //   pauseButton.title = 'Stop animations'

  //   var opening = 828
  //   var editor = 1446

  //   starfield = new window.WebGLBackground({
  //     // Canvas and button are mandatory options
  //     canvas: document.querySelector('#starfield'),
  //     button: document.querySelector('#animate-center'),

  //     // Starfield background color. Defaults to #080f24.
  //     backgroundColor: 'rgb(18, 18, 18)',
  //     // backgroundColor: '#080f24',

  //     // Whether the starfield should constantly be centered on the button element. Default is true.
  //     followButton: true,

  //     // Controls the number of stars. Default is 1500.
  //     starCount: 500,

  //     // The range of page scroll values (in pixels) at which the stars should be completely visible and completely hidden.
  //     // The stars will have full opacity before the first scroll threshold, interpolate opacity between first and second threshold,
  //     // then be completely hidden after the second treshold.
  //     // Default is [window.innerHeight, window.innerHeight * 2].
  //     // starsScrollRange: [500, 1500],
  //     starsScrollRange: [opening, editor],

  //     // Same as above, but for the nebula clouds.
  //     // Suggestion: we think it looks better if the stars fade out just a little after the clouds.
  //     // cloudsScrollRange: [500, 1250],
  //     cloudsScrollRange: [opening, editor - 100],

  //     // Intensity of the starfield in idle mode ('intensity' affects speed, length and thickness of stars).
  //     // Defaults to 0. (We suggest leaving this to zero).
  //     idleIntensity: 0,

  //     // Intensity of the starfield when pressing down a mouse button.
  //     // Defaults to 1. Put the same value as idle here to disable the acceleration on click.
  //     clickIntensity: 0,

  //     // Intensity of the starfield when the mouse cursor is hovering the specified button element.
  //     // Defaults to 1.5.
  //     buttonIntensity: 1.5,
  //   })
  // }, [])

  useEffect(() => {
    if (SpeedStore.speedStatus === 'starting' || !!StartingStore.titleInfo) {
      if (!window.mybuff) {
        window.mybuff = {}
      }
      window.mybuff.speeding = true
      appStore.speeding = true
      return () => {
        if (!window.mybuff) {
          window.mybuff = {}
        }
        window.mybuff.speeding = false
        appStore.speeding = false
        emitter.emit('end-speeding')
      }
    }
  }, [SpeedStore.speedStatus === 'starting', !!StartingStore.titleInfo])

  /**
   * 首次打开根据文件名跳转详情页
   * MyBuff_sgtdefault_1.0.0.346-l2457a@1lln@mybuff
   * 取文件名中的 'l2457a' ,l代表是浏览，2457为id，a为要跳转的tab
   */
  const checkFirstPageJump = async () => {
    if (!ClientLocalStorageStore.getIsFirstOpen()) {
      return
    }
    const setupName = getSetupName()
    if (!setupName) {
      return
    }
    const arr = setupName.split('_')
    const result = arr[arr.length - 1].match(/l(\d+)([a-zA-Z]?)/)
    const lid = result?.[1]
    const tabType = result?.[2]
    if (!lid) {
      return
    }
    let params = new URLSearchParams()
    switch (tabType) {
      //跳转修改器
      case 'a':
        params.set('entry', 'modifier')
        params.set('trainerGameId', lid)
        break
      default:
        params.set('branchId', lid)
    }
    navigate(`/game-detail?type=game&refer=firstJump&${params.toString()}`)
  }

  useEffect(() => {
    checkFirstPageJump()
    initPostMessage()
    emitter.on('beforeLogout', () => {
      //退出登录，结束游戏
      handleStopGame(false)
      stopCloudGame({
        needRequest: true,
        reason: '用户退出登录',
        needArchive: false,
        needCloseCloudGameWindow: true
      })
    })
    const handleSaveBodySize = () => {
      appStore.bodySize = {
        height: document.body.clientHeight,
        width: document.body.clientWidth,
      }
    }
    handleSaveBodySize()
    window.addEventListener('resize', handleSaveBodySize)

    //记录首次打开标识
    ClientLocalStorageStore.setNotFirstOpen('1')
    return () => {
      window.addEventListener('resize', handleSaveBodySize)
      emitter.off('beforeLogout')
    }
  }, [])

  if (location.pathname === '/cloudgame-page') {
    return null
  }

  console.log(location, 'location')

  const ref=useRef<HTMLDivElement>(null)
  useEffect(() => {
    appStore.leftElement = ref.current
  }, [])

  return (
    <StyledLayoutView>
      {checkOversea() && <StyledGlobalStyle/>}
      <Aside/>
      <StyledLayoutLeft ref={ref} />
      <StyledLayoutRight>
        {/* 客户端头部 */}
        <LayoutHeaderNew />
        {/* 页面区域 */}
        <StyledLayoutContent id={DOMID.LAYOUT_CONTENT}>{renderRoutes}</StyledLayoutContent>
      </StyledLayoutRight>
      <ProtocolModal />
      <ResourceDetailModal />
      <ResourceInformationModal />
      <QueueSuccessModal />
      <TrialQueueSuccessModal />
      <QueueInModal />
      <TrialQueueInModal />

      <ConfirmExitCloudGame />
      <BalanceInsufficientModal
        visible={CloudGamePlayStore.cloudErrorModalType === 'balance'}
        onCancel={closeCloudErrorModal}
        onOk={toRechargeCloudTime}
      />
      <ForceQuitModal
        visible={CloudGamePlayStore.cloudErrorModalType === 'force_quit'}
        onCancel={() => {
          closeCloudErrorModal()
          !VipStore.memberinfo?.isMember && showUserCenter(NAV_KEY_MAPPING.VIP)
        }}
        onOk={toRechargeCloudTime}
      />
      <CrashesModal
        visible={crashVisible}
        content={crash}
        onCancel={() => (CloudGamePlayStore.cloudGameStartCrash = null)}
      />
      <NewSaveArchiveModal/>
      <UserCenterModal/>
    </StyledLayoutView>
  )
}

export default observer(NewLayout)


const Loading = () => {
  return (
    <StyledLoading>
      <Spin indicator={<LoadingOutlined />}></Spin>
    </StyledLoading>
  )
}

const StyledGlobalStyle =createGlobalStyle`
  html, body, #app{
    font-family: Arial, Helvetica, sans-serif;
  }
`

const StyledDetailBg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  .detail-bg-top {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    .bg-img {
      width: 100%;
      transition: opacity 1000ms ease 0s;
    }
    /* .speed-bg-transition {
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 150px;
      width: 100%;
      background: linear-gradient(180deg, rgba(10, 10, 21, 0) 0%, rgba(10, 10, 21, 1) 100%);
    } */
    .modifier-bg-transition {
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(9, 9, 9, 0) 0%, rgba(9, 9, 9, 1) 100%);
    }
  }

  .bg-base-pain .true-img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 523px;
    filter: blur(140px);
    /* backdrop-filter: blur(120px); */
    opacity: 0.3 !important;
  }
`

const StyledAuraContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;
  .aura {
    position: absolute;
    top: 0;
    left: 0;
    /* transition: transform 300ms ease 0s; */
    will-change: transform;
    width: 65vw;
    height: 65vw;
    max-height: 1280px;
    max-width: 1280px;
    border-radius: 50%;
    opacity: 0.3;
    filter: blur(100px);
    background: linear-gradient(
      0deg,
      rgba(235, 86, 97, 0.4),
      rgba(237, 122, 77, 0.2),
      rgba(246, 195, 76, 0.4),
      rgba(121, 231, 202, 0.2),
      rgba(101, 175, 249, 0.4),
      rgba(161, 99, 247, 0.2),
      rgba(235, 86, 97, 0.4),
      rgba(237, 122, 77, 0.2)
    );
    background-size: 100% 600%;
    animation: GradientAnimation 20s linear infinite;
  }
  @keyframes GradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
`

const StyledLoading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledLayoutView = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  user-select: none;
  position: relative;
  z-index: 0;  
`

const StyledLayoutLeft = styled.section`
  //width: 240px;
  height: 100%;
  background: #26282B;
`

const StyledLayoutRight = styled.section`
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
`

const StyledLayoutContent = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  background: #2B2D31;
`

const StyledClientVersion = styled.div`
  ${props => props.theme.flexAlignCenter}
  height: 22px;
  margin: 14px 20px 20px;
  user-select: none;
  .name {
    font-size: 16px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-weight: 500;
    color: var(--text-color-FFFFFF);
    line-height: 16px;
    margin-left: 9px;
  }
`
