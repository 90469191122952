import React from 'react'

export default {
  'common.cancel':'Cancel',
  'common.confirm':'Confirm',
  'common.notify':'Notify',
  'common.known':'I know',
  'common.subscribe':'Subscribe',
  'common.hours':' hours ',
  'common.minutes':' minutes ',
  'common.seconds':' seconds ',
  'common.sendTime':'Resend after {count} seconds',

  //试玩详情页
  'detail.trial.description':'Shared account description：',
  'detail.trial.description2':'MYBUFF provides you with a {platformName} account that purchased the <span>{gameName}</span>，You can use this account to play <span>{gameName}</span>',
  'detail.trial.step1':'Step1: ',
  'detail.trial.step1Content':'Apply for a shared account',
  'detail.trial.step2':'Step2: ',
  'detail.trial.step2Content':'MYBUFF assigns an available shared account',
  'detail.trial.step3':'Step3: ',
  'detail.trial.step3Content':'MYBUFF uses a shared account to automatically log in to the {platformName}',
  'detail.trial.step4':'Step4: ',
  'detail.trial.step4Content':'Instand、Download、 Play the game on {platformName} after logging in',
  'detail.trial.tipsTitle':'Precautions：',
  'detail.trial.tipsContent1':'Please do not recharge in the shared account',
  'detail.trial.tipsContent2':'Please read the <span>Minimum Configuration Requirements</span> carefully before using a shared account to avoid being unable to play the game normally.',
  'detail.trial.tags':'Tags:',
  'detail.trial.supportArchive':'Support cloud archiving',
  'detail.trial.notSupportArchive':'Unsupported cloud archiving',
  'detail.trial.minimum':'MINIMUM SYSTEM REQUIREMENTS',
  'detail.trial.os':'OS：',
  'detail.trial.cpu':'Processor：',
  'detail.trial.ram':'Memory：',
  'detail.trial.gpu':'Graphics：',
  'detail.trial.directory':'Game platform installation directory',
  'detail.trial.directoryTips':'System not detected your local installation {platformName}.Please go to <span>download</span> or manually specify the game platform installation directory.',
  'detail.trial.start': 'Start applying a shared account',
  'detail.trial.queueing': 'Queuing…',
  'detail.trial.stop': 'Stop useing a shared account',
  'detail.trial.stop2': 'Stop useing a shared account',
  'detail.trial.starting': 'Starting up...',
  'detail.trial.save': 'Save...',
  'detail.trial.skip': 'Skip and proceed',
  'detail.trial.select': 'Select a cloudarchive to use',
  'detail.tools':'Tools & Services',
  'detail.platform':'PLATFORM',

  'start.step1':'Step1: Checking the local environment and network link status',
  'start.step2':'Step2: Starting game platform',
  'start.step3':'Step3: Starting the game security component',
  'start.step4':'Step4: Entering account & password to  log in platform',
  'start.step2Desc':'Precautions：During the startup process of the game platform, if an update is triggered, please wait patiently. After the platform update is completed, the subsequent startup process will automatically proceed.',
  'start.step4Desc':'The program will automatically log in to the platform and start the game, please do not perform other operations.',

  'env.os':'OS version',
  'env.network':'Network detection',
  'env.cpu':'CPU usage',
  'env.memory':'Available memory remaining',

  'env.osLow':'OS version is too low',
  'env.networkLow':'Unstable network connection',
  'env.cpuLow':'cpu usage is too high',
  'env.memoryLow':'Insufficient available memory remaining',

  'env.osLowDesc':'The current OS is <span>{text}</span>, and this game may not be started normally.',
  'env.networkLowDesc':'You are currently using <span>Wi-Fi</span> for network connection, which may cause the game to fail to start normally. It is recommended to use a network cable instead.',
  'env.cpuLowDesc':'The current CPU usage is <span>{text}%</span>. Excessive CPU usage may cause the game to crash when starting.',
  'env.memoryLowDesc':'The current remaining available memory is <span>{text}GB</span>. Insufficient remaining memory may cause the game to crash when starting.',

  'detail.save.name': 'Archive name',
  'detail.save.type': 'Archive type',
  'detail.save.time': 'Create time',
  'detail.save.size': 'Size',
  'detail.save.operate': 'Operate',
  'detail.save.noUse': 'Play without using save files',
  'detail.save.save': 'save',

  'detail.tab.trial': 'Shared account',

  'vip.day':'day',
  'vip.days':'days',
  'vip.hour':'hour',
  'vip.hours':'hours',

  'queue.notification':'Shared account queue notification',
  'queue.tips':'The game is so popular! Queuing to receive shared account',
  'queue.fontUser':'Currently in the <div>{fontUserDom}</div> place in the collection queue',
  'queue.cancel':'Cancel',

  'queue.success.account':'You have successfully applied for a shared account',
  'queue.success.play':'Let\'s go play games！',
  'queue.success.enter':'Enter',
  'queue.success.refuse':'User refused to enter the game',
  'queue.confirmCancel':'Confirm cancel queueing?',
  'queue.confirmCancel2':'After confirmation, you will be removed from the queue',
  'queue.change':`Currently in the {gameName} queue, No.{frontUser}. You have been waiting for {time}. Do you want to cancel and start {gameName2}?`,

  'trial.notifyVip1':'Using a shared account is an exclusive privilege for MYBUFF members',
  'trial.notifyVip2':' ',
  'trial.openVip':'Go to activate',

  'trial.starting':'Sharing account is logging in',
  'trial.queueing':'No. {frontUser} in the queue',
  'trial.queueSuccess':'Queueing succeeded',
  'trial.using':'Shared account using',
  'trial.saving':'Saving',

  'trial.stop':'Tips for stopping using shared accounts',
  'trial.stop2':'Please confirm again that you want to end the use of this shared account. After confirmation, we will directly close your running game process and log out of the shared account. {save}',
  'trial.stop3':'After the end, please pay attention to save the archive',

  'trial.change':'Tips for switching shared accounts',
  'trial.change2':'You are using a shared <strong>{gameName}</strong> account. Are you sure you want to switch to a shared account in <strong>{nextGameName}</strong> ? {save}',
  'trial.change3':'<br></br><br></br><span>Note: Games ended in this way will not be saved in the cloud.</span>',
  'trial.crash':'Game crashes unexpectedly',
  'trial.crash2':'The trial game crashed due to unknown reasons. We sincerely apologize for the inconvenience caused to you.',

  'trial.vip.expiration':'Subscription expiration reminder',
  'trial.vip.expiration2':'Your membership has expired and we have automatically revoked your permission to use your shared account.',
  'trial.vip.expiration3':'Member rights are about to expire reminder',
  'trial.vip.expiration4':'Your membership rights are about to expire. After expiration, we will directly reclaim your right to use the trial game account and close the game you are currently playing, which may cause the loss of your game progress!',

  'save.title':'Archive information being saved',
  'save.yes':'Confirm to save',
  'save.no':'Do not save',
  'save.gameName':'Save game：',
  'save.time':'Create time：',
  'save.size':'File size：',
  'save.name':'Cloud save naming',
  'save.placeholder':'Please fill in the name of the custom cloud archive',
  'save.notBlank':'The archive name cannot be empty',
  'save.nameRule':'The archive name cannot contain spaces><:"\'/\\|?*',
  'save.success':'Successfully saved',
  'save.fail':'Failed to save',
  'save.local':'Local archive',
  'save.cloud':'Cloud archive',
  'save.auto':'Automatically saved',
  'save.renameSuccess':'Rename successfully',
  'save.renameFail':'Rename failure',
  'save.deleteSuccess':'Successfully deleted',
  'save.deleteFail':'Failed to delete',
  'save.deleteLocal':'Please confirm again that you want to delete this archive, you will not be able to retrieve this archive progress after deletion!',
  'save.deleteLocalCloud':'Please confirm again that you want to delete this archive, after deleting it, you need to download it to the local again before you can use it!',
  'save.delete2':'Please confirm again that you want to delete this archive, you will not be able to retrieve this archive progress after deletion!',

  'userCenter.user':'User',
  'userCenter.sysSetting':'System settings',
  'userCenter.cdk':'CDK redeem',
  'userCenter.vip':'Member subscription',

  'userCenter.edit':'Edit',
  'userCenter.regTime':'Registration date',
  'userCenter.SafetyInformation':'Safety Information',
  'userCenter.account':'Account',
  'userCenter.pwd':'Password',
  'userCenter.bound':'Bound',
  'userCenter.changePwd':'Change password',
  'userCenter.email':'E-mail',
  'userCenter.changeEmail':'Change email',
  'userCenter.bindEmail':'Bind email',
  'userCenter.vipStatus':'Member status',
  'userCenter.subscribing':'Subscribing',
  'userCenter.notSubscribed':'Not subscribed',
  'userCenter.expireDate':'Expire date',
  'userCenter.subscribe':'Subscribe',
  'userCenter.logout':'Log out',
  'userCenter.logout1':'Account switching',
  'userCenter.logout2':'Please confirm again that you want to log out of your current account',
  'userCenter.logout3':'After logging out, certain functions that depend on the client will not take effect',
  'userCenter.logout4':'Log out',

  'userCenter.nick.editSuccess':'Modified successfully',
  'userCenter.nick.blank':'The new nickname cannot be empty',
  'userCenter.nick.same':'The new nickname is consistent with the old one without modification',

  'bind.mobile':'mobile',
  'bind.email':'email',
  'bind.password':'password',
  'bind.change':'change {type}',
  'bind.changePwd':'change password',
  'bind.auth':'Authentication',
  'bind.setNew':'Change {type}',
  'bind.end':'Accomplish',
  'bind.authSelect':'Selection authentication type',
  'bind.authType':'{type} authentication',
  'bind.changeSuccess':'Successfully changed',
  'bind.next':'Next',

  'bind.email.placeholder':'Email  verification',
  'bind.email.emptyMsg':'Verification code can not be empty',
  'bind.email.errorMsg':'Please enter the correctly formatted verification code',

  'bind.pwd.placeholder':'Please enter the login password for the current account',
  'bind.pwd.emptyMsg':'Login password can not be empty',
  'bind.pwd.errorMsg':'The password must be 8-16 characters long (a combination of letters and numbers)',

  'email.send':'Get code',
  'email.resend':'Resend verification code',

  'change.pwd.placeholder':'Enter a new password',
  'change.pwd.emptyMsg':'Enter a new password',
  'change.pwd.errMsg':'The password must be 8-16 characters long (a combination of letters and numbers)',
  'change.pwd.placeholder2':'Repeatedly enter the new password',
  'change.pwd.emptyMsg2':'Repeatedly enter the new password',
  'change.pwd.errMsg2':'Repeatedly enter the correctly formatted new password',
  'change.pwd.notSame':'The passwords entered are inconsistent',

  'reset.pwd.confirm':'Account confirmation',
  'reset.pwd.reset':'Reset password',
  'reset.pwd.email':'Email  Address',
  'reset.pwd.noAccount':'The current email is not associated with any MyBuff account. Please enter a valid email',
  'reset.pwd.authType':'{type} verification for binding',
  'reset.pwd.account':'Account',
  'reset.pwd.confirmReset':'Confirm change',
  'reset.pwd.loginAccount':'Login to account',
  'reset.pwd.newPwd':'New password',
  'reset.pwd.login':'Login directly',

  'change.email.placeholder':'Enter a new email address',
  'change.email.emptyMsg':'Enter a new email address',
  'change.email.errorMsg':'Please enter the correctly formatted email',
  'change.email.placeholder2':'Email verification',
  'change.email.emptyMsg2':'Enter email verification code',
  'change.email.errorMsg2':'Please enter the correctly formatted verification code',
  'change.email.same':'The email cannot be the same as the current bound email',

  'login.login':'Log in',
  'login.reg':'Register',
  'login.forgetPwd':'Forget password',
  'login.success':'Login successful',
  'login.email.placeholder':'Email address',
  'login.email.emptyMsg':'Enter your email address',
  'login.email.errMsg':'Please enter the correct email address',
  'login.pwd.placeholder':'Password',
  'login.pwd.emptyMsg':'Enter your password',
  'login.pwd.errMsg':'The password must be 8-20 characters long (a combination of letters and numbers)',
  'login.pwd.remember':'Remember password',
  'login.pwd.forget':'Forget password?',
  'login.toLogin':'Log in',

  'login.reg.success':'Registered successfully',
  'login.reg.used':'The email is already in use, please log in directly',
  'login.sentSuccess':'Successfully sent',
  'login.reg.agreement':'By registering, you have accepted <span>{name}</span>',
  'login.reg.create':'Register Now',
  'login.reg.agreementRead':'Please read and agree to the {name}',

  'sysSettings.common':'Common settings',
  'sysSettings.boot':'Automatically start at boot',
  'sysSettings.download':'Resource download settings',
  'sysSettings.directory':'Default download resource directory',
  'sysSettings.closed':'when the window is closed',
  'sysSettings.hide':'Hide to taskbar tray',
  'sysSettings.exit':'Exit main program',

  'cdk.redeem':'CDK redeem',
  'cdk.placeholder':'Enter redeem code',
  'cdk.btn':'Redeem now',
  'cdk.record':'Redeem Record',
  'cdk.noRecord':'No redeem record yet',
  'cdk.time':'Redeem Time',
  'cdk.key':'Redeem Code',
  'cdk.goods':'Redeem Item',
  'cdk.success':'Successful redeem',
  'cdk.empty':'CDK cannot be empty',

  'platform.err':'Incorrect game platform path, please re-identify',
  'platform.success':'Game platform recognition success',

  'update.hasUpdate':'Software update detected',
  'update.restart':'Restart software',
  'update.closing':'The software is shutting down {i}S...',
  'update.lose':'Please wait patiently for the client to close. Forcing the client to close may cause data loss.',
  'update.force':'Necessary update detected for MYBUFF',
  'update.forceTips':'The system has detected that there is a very necessary security update. Please continue to use the various functions of MYBUFF after the restart and the update is completed. We apologize for the inconvenience caused to you.',

  'cloudArchiveTips.title':'Game startup prompts',
  'cloudArchiveTips.desc':'The current game does not support cloud archiving for the time being. Game progress may be lost due to account changes during the second game. We hope you will be informed!',
  'cloudArchiveTips.notRemind':'Don\'t prompt again next time you start',
  'cloudArchiveTips.next':'Confirm Continue',

  'service.title':'Service guarantee:',
  'service.name1':'Official purchase authentic account',
  'service.desc1':'All game accounts are registered through official channels; all games are purchased and activated through the official store',
  'service.name2':'You will not be disconnected due to other people during use',
  'service.desc2':'null',
  'service.name3':'Automatically change the account number if it is incorrect',
  'service.desc3':'null',
  'service.name4':'Support online mode',
  'service.desc4':'It only takes effect when the current game supports online mode.',

  'payModal.product':'Products',
  'payModal.timeout':'Payment timed out',
  'payModal.noPay':'You have not completed the payment',
  'payModal.payType':'Payment method',
  'payModal.total':'Total： ',
  'payModal.payNow':'Pay Now',
  'payModal.refresh':'Refresh',
  'payModal.agreement':'By paying，you agree to <span>"{title}"</span>',
  'payModal.tips':'Payment',
  'payModal.complete':'Complete Payment',
  'payModal.cancel':'Cancel',
  'payModal.tipContent':'You will pay on the newly-opened webpage. Before the payment is complete,please do not close the window. When the payment is successful,If there is any problem during the payment process, please contact the following customer support email: support@mybuff.com <span>[Copy]</span>',
  'payModal.success':'Purchase',
}