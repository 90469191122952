import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import CloseNotify from 'src/components/speed-modal/close-notice'
// import SettingsModal from 'src/components/speed-modal/settings'
import SettingsModal1 from 'src/components/speed-modal/settings1'

const SpeedModal: FC = () => {
  return (
    <>
      {/* 这个 CloseNotify 理论上是需要的功能，但是这期没有用，暂时先注释 */}
      {/* <CloseNotify /> */}
      {/* <SettingsModal /> */}
      <SettingsModal1 />
    </>
  )
}

export default observer(SpeedModal)
