import React from 'react'
import { VipStore, SpeedStore } from 'src/store'
import { message } from 'antd'
import { applyMemberValid, openUrl, sleep, applyRepairValid } from 'src/tools/common'
import { applySpeedOrderNo, reportSpeedStatus } from 'src/apis/user-api'
import { imperativeConfirmModal } from 'src/components/base-modal'
import { handleCloseClient } from 'src/CEFTransfer/cefController'
import { FadeinImage } from 'src/components/base-image'
import arrowImg from 'src/assets/images/common/arrow.png'
import styled from 'styled-components'
import speedController from 'src/CEFTransfer/speedController'
import CEFTransfer from 'src/CEFTransfer'
import WindowController from 'src/CEFTransfer/window-controller'

export const promiseModal: {
  resolve: Function
  reject: Function
} = {
  resolve: () => {},
  reject: () => {},
}

export const speedExitPromise: {
  resolve: Function | null
  reject: Function | null
} = {
  resolve: null,
  reject: null,
}

const waitSpeedExit = () => {
  return new Promise<undefined>((resolve, reject) => {
    let timer: number | null = window.setTimeout(() => {
      console.error('exit timeout')
      resolve(undefined)
    }, 15000)

    const speedExitResolve = () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      resolve(undefined)
    }
    const speedExitReject = () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      reject(undefined)
    }

    speedExitPromise.resolve = speedExitResolve
    speedExitPromise.reject = speedExitReject
  }).finally(() => {
    speedExitPromise.resolve = null
    speedExitPromise.reject = null
  })
}

let starting = false

/** 启动加速 */
export const doStartSpeed = async ({
  gameLibraryName,
  gamePlatformName,
  speedGameNo,
  relateIdens,
  speedGameName,
  areaInfo,
  nodeInfo,
  status,
  thirdInfo,
  gameLibraryBranchId,
  gamePlatformId,
  startType = 'start',
  modelCallback,
  clientType = 'client'
}: {
  relateIdens: string,
  gamePlatformName: string,
  gameLibraryName: string,
  speedGameNo: string
  speedGameName: string
  gameLibraryBranchId: number | null
  gamePlatformId: number | null
  areaInfo: CEF.TAreaInfo & CEF.TServeInfo
  nodeInfo: CEF.TNodeInfo
  thirdInfo: Http.ThirdGameInfo
  modelCallback?: () => void
  status?: boolean
  startType?: 'start' | 'change'
  clientType?: 'client' | 'cloud'
}) => {
  let isNeedExecCallback = false
  try {
    speedExitPromise.reject?.()
    /** 当前是否在加速中 */
    const speedInfo = SpeedStore.hasSpeeding()
    if (startType === 'start') {
      if (!!speedInfo && `${relateIdens}` === `${speedInfo.relateIdens}`) {
        return openUrl({ url: '/', target: 'push' })
      }
      if (status === false) {
        message.warn('当前游戏已下架，无法提供加速服务')
        return
      }
    }
    SpeedStore.checkedSpeedInfo = {
      thirdGameNo: `${thirdInfo.thirdGameNo}`,
      speedGameNo: `${speedGameNo}`,
      speedGameName: `${speedGameName}`,
      relateIdens,
      gameLibraryName,
      gamePlatformName,
      gameLibraryBranchId,
      gamePlatformId,
      clientType,
      speedNo: '',
      speedAreaId: '',
      speedAreaName: '',
      speedServerId: '',
      speedServerName: '',
      speedNodeId: '',
      speedNodeName: '',
      nodeDelay: '',
      nodeLoss: '',
      speedTime: 0,
      speedProgress: 0,
      speedBytes: '',
    }
    if (starting) {
      await sleep(800)
    }
    starting = true
    if (areaInfo && nodeInfo && SpeedStore.checkedSpeedInfo) {
      const { areaID, areaName, serverID, serverName } = areaInfo
      const { nodeID, nodeName } = nodeInfo
      SpeedStore.checkedSpeedInfo.speedAreaId = areaInfo.areaID
      SpeedStore.checkedSpeedInfo.speedAreaName = areaInfo.areaName
      SpeedStore.checkedSpeedInfo.speedServerId = areaInfo.serverID
      SpeedStore.checkedSpeedInfo.speedServerName = areaInfo.serverName
      SpeedStore.checkedSpeedInfo.speedNodeId = nodeInfo.nodeID
      SpeedStore.checkedSpeedInfo.speedNodeName = nodeInfo.nodeName
      let speedExitPromise: Promise<undefined> | null = null
      if (startType === 'start') {
        const currentSpeedInfo = SpeedStore.hasSpeeding()
        if (!!currentSpeedInfo) {
          const StyledMultipleStartNoticeContent = styled.div`
            color: #b2b2b2;
            font-size: 14px;
            .light {
              color: #fff;
            }
          `
          const isConfirm = await imperativeConfirmModal({
            title: '启动加速提醒',
            content: (
              <StyledMultipleStartNoticeContent>
                <span>当前</span>
                <span className='light'> {currentSpeedInfo?.speedGameName} </span>
                <span>正处于加速中，现在启动</span>
                <span className='light'> {SpeedStore?.checkedSpeedInfo?.speedGameName} </span>
                <span>的加速将会有可能造成</span>
                <span className='light'> {currentSpeedInfo?.speedGameName} </span>
                <span>的掉线，请再次确认变更加速的游戏</span>
              </StyledMultipleStartNoticeContent>
            ),
            okText: '确认切换',
            cancelText: '取消',
          })
          if (!isConfirm.value) {
            throw new Error('用户取消切换')
          }
          SpeedStore.isSwitchSpeed = true
          await SpeedStore.stopSpeed({ notify: true, report: true, reason: 'CHANGE_SPEED_GAME' })
          isNeedExecCallback = true
          speedExitPromise = waitSpeedExit()
        }
      }
      // if (location.pathname !== '/') {
      //   openUrl({ url: '/' })
      // }
      if (startType == 'change') {
        const StyledSwitchAreaServerContainer = styled.div`
          .switch-row {
            ${props => props.theme.flexAlignCenter}
            justify-content: space-between;
            .switch-arrow {
              width: 16px;
              height: 16px;
            }
            .switch-item {
              width: 246px;
              height: 76px;
              padding: 12px 16px;
              background: ${props => props.theme.color.bg.$19191950};
              .switch-name {
                font-size: 14px;
                font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
                font-weight: bold;
                color: var(--text-color-FFFFFF);
                line-height: 24px;
              }
              .switch-areaserver {
                margin-top: 4px;
                font-size: 14px;
                font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
                line-height: 24px;
                &.old {
                  color: ${props => props.theme.color.text.$DF9FFC};
                }
                &.new {
                  color: ${props => props.theme.color.text.$10FAB8};
                }
              }
            }
          }
          .switch-tips {
            margin-top: 16px;
          }
        `
        const isConfirm = await imperativeConfirmModal({
          title: '切换区服提醒',
          width: 600,
          content: (
            <StyledSwitchAreaServerContainer>
              <div className='switch-row'>
                <div className='switch-item'>
                  <div className='switch-name'>当前使用</div>
                  <div className='switch-areaserver old'>
                    {SpeedStore.speedingInfo?.speedAreaName} {SpeedStore.speedingInfo?.speedServerName} -{' '}
                    {SpeedStore.speedingInfo?.speedNodeName}
                  </div>
                </div>
                <FadeinImage className='switch-arrow' type='notDefault' src={arrowImg} />
                <div className='switch-item'>
                  <div className='switch-name'>切换区服</div>
                  <div className='switch-areaserver new'>
                    {SpeedStore.checkedSpeedInfo?.speedAreaName} {SpeedStore.checkedSpeedInfo?.speedServerName} -{' '}
                    {SpeedStore.checkedSpeedInfo?.speedNodeName}
                  </div>
                </div>
              </div>
              <div className='switch-tips'>切换过程中游戏可能存在掉线情况，望知悉！</div>
            </StyledSwitchAreaServerContainer>
          ),
          okText: '确认切换',
          cancelText: '取消',
        })
        if (!isConfirm.value) {
          throw new Error('用户取消切换')
        }
        await SpeedStore.stopSpeed({ notify: true, report: true, reason: 'CHANGE_SPEED_NODE' })
        isNeedExecCallback = true
        speedExitPromise = waitSpeedExit()
      }
      const { result: orderInfo } = await doApplySpeedOrderNo({
        speedGameNo: relateIdens,
        thirdGameNo: thirdInfo.thirdGameNo,
        speedArea: areaID,
        speedAreaName: areaName,
        speedServer: serverID,
        speedServerName: serverName,
        speedNode: nodeID,
        speedNodeName: nodeName,
        clientVersion: 'v2',
        type: clientType,
      })
      SpeedStore.speedStatus = 'starting'
      SpeedStore.checkedSpeedInfo.speedNo = orderInfo.speedNo
      /** 随机一个初始启动进度 */
      SpeedStore.checkedSpeedInfo.speedProgress = (Math.random() * 10 + 5) | 0
      SpeedStore.startingSpeedInfo = SpeedStore.checkedSpeedInfo
      SpeedStore.checkedSpeedInfo = null
      startSpeedProgress()
      if (speedExitPromise) {
        await speedExitPromise
      }
      const speedResult = await speedController.cefStartSpeed({
        gameID: `${thirdInfo.thirdGameNo}`,
        userID: orderInfo.speedNo,
        token: orderInfo.speedObject,
        areaID: `${areaID}`,
        serverID: `${serverID}`,
        nodeID: `${nodeID}`,
        platform: thirdInfo.platform?.toLocaleLowerCase() as TPlatform,
      })
      try {
        const clientSpeedSuccess = speedResult.code === '1000'
        if (!clientSpeedSuccess) {
          throw new Error(speedResult.msg)
        }
        if (!SpeedStore.startingSpeedInfo) {
          throw new Error('数据异常')
        }
        // 这里失败会直接报错 然后走下面的catch逻辑， 这个声明变量只是说明这句代码用来干嘛，
        const serverSpeedSuccess = await reportSpeedStatus({
          speedNo: SpeedStore.startingSpeedInfo.speedNo,
          event: 'begin',
        })
        modelCallback?.()
        SpeedStore.startingSpeedInfo && SpeedStore.speedSuccess(SpeedStore.startingSpeedInfo)
      } catch (error) {
        message.warn(speedResult.msg)
        await SpeedStore.stopSpeed({
          report: true,
          notify: false,
          reason: {
            type: 'speedFail',
            code: speedResult.ybcode || 10000,
            reason: speedResult.msg || '启动加速失败',
            message: '',
          },
        })
      }
    }
  } catch (error) {
    console.error(error)
  }
  isNeedExecCallback && modelCallback?.()
  SpeedStore.isSwitchSpeed = false
  starting = false
}

export const startSpeed = applyRepairValid(applyMemberValid(doStartSpeed))

/**
 * @description 申请加速单号
 */
export const doApplySpeedOrderNo = async (params: Http.ApplySpeedOrderNoParams) => {
  try {
    return await applySpeedOrderNo(params)
  } catch (error) {
    return Promise.reject(error)
  }
}

let progressTimer: number | null = null
/**
 * @description 加速进度模拟
 */
export const startSpeedProgress = () => {
  stopSpeedProgress()
  if (!SpeedStore.startingSpeedInfo) {
    return
  }
  if (SpeedStore.speedStatus === 'speeding') {
    return (SpeedStore.startingSpeedInfo.speedProgress = 100)
  }
  progressTimer = window.setInterval(() => {
    if (!SpeedStore.startingSpeedInfo) {
      return
    }
    if (SpeedStore.startingSpeedInfo.speedProgress < 99) {
      SpeedStore.startingSpeedInfo.speedProgress = Math.min(
        (SpeedStore.startingSpeedInfo.speedProgress + (Math.random() * 10 + 1)) | 0,
        99,
      )
    } else {
      stopSpeedProgress()
    }
  }, 500)
}
/**
 * @description 停止进度
 */
export const stopSpeedProgress = () => {
  if (progressTimer) {
    window.clearInterval(progressTimer)
    progressTimer = null
  }
}

/**
 * @description 客户端退出
 */
export const exitApp = async () => {
  const customKey = `exitApp-${Math.random()}`
  CEFTransfer.sendToClient('closeSaveFileDialog')
  await WindowController.beforeAppClose(customKey, true)
  // try {
  //   await SpeedStore.stopSpeed({ report: true, notify: false, reason: 'EXIT_CLIENT' })
  // } catch (e) {
  //   console.log('exitApp e', e)
  // }
  // console.log('exitApp')
  handleCloseClient()
}

/**
 * @description 客户端请求退出
 */
export const requestExitApp = async () => {
  exitApp()

  /****************************
   * // //没有处于加速中，直接退出
    // if (!SpeedStore.hasSpeeding()) {
    //   return exitApp()
    // }
    // //处于加速中，显示确认弹窗
    // CEFTransfer.sendToClient('showWindowTop')
    // const result = await showSpeedModal('close-notice')
    // result ? handleMiniClient() : exitApp()
   * 
   * @TODO 
   * 这个逻辑应该是需要的， 第一期为了简单， 暂时先注释
   * 
   * 
   */
}
/** 显示加速弹窗 */
export const showSpeedModal = <T extends any = any>(type: any, params?: any) => {
  return new Promise<T>((resolve, reject) => {
    promiseModal.resolve = resolve
    promiseModal.reject = reject
    SpeedStore.speedModalType = type
    SpeedStore.speedModalParmas = params
  })
}
