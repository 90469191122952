import React, { FC, useEffect } from 'react'
import { ImperativeConfirmModal } from 'src/components/base-modal'
import { VipStore } from 'src/store'
import { observer } from 'mobx-react'
import { formatTimeLocal } from 'src/tools/helper'
import { LoginStore } from 'src/store/modifier'

const ForceQuitModal: FC<{visible:boolean; onCancel:()=>void; onOk:()=>void}> = ({ visible,onOk,onCancel }) => {

  useEffect(()=>{
    if(visible && LoginStore.userinfo){
      VipStore.handleLoadUserRemainingCloudGameBalance()
    }
  },[visible,LoginStore.userinfo])

  return (
    <ImperativeConfirmModal options={ visible ? {
      title: '可用云游戏时长不足提醒',
      content: <div>
        你剩余的可用云游戏时长不足，请前往充值后使用<br/>
        {
          !VipStore.memberinfo?.isMember && <>
                开通 MYBUFF 会员，每周免费赠送 {formatTimeLocal(VipStore.cloudGameGiveTime?.vipUserGiveTime??0)}云游戏时长
            </>
        }
      </div>,
      key: 'confirm-modal-force-quit',
      okText: '前往充值',
      cancelText:VipStore.memberinfo?.isMember ? '我知道了':'开通会员',
      btns: ['cancel', 'ok',],
      _ok: onOk,
      _cancel:onCancel,
    }:null } />
  )
}

export default observer(ForceQuitModal)
