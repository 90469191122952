import React, { FC, useEffect } from 'react'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { observer } from 'mobx-react'
import { handleMiniClient } from 'src/CEFTransfer/cefController'
import { localStorageTool } from 'src/tools/storage'
import { LOCALSTORAGE_KEYS } from 'src/constants/storage'
import { LoginStore } from 'src/store/modifier'
import routes from 'src/routes'
import styled from 'styled-components'
import CEFTransfer from 'src/CEFTransfer'
import CloseIcon from 'src/assets/icons/layout/closeMenu.svg'
import ProtocolModal from 'src/components/protocol-modal'

console.log('href',location.href)

const LayoutMini: FC = () => {
  const renderRoutes = useRoutes(routes)
  const navigate = useNavigate()

  useEffect(()=>{
    //监听切换工具
    CEFTransfer.subscribeClientMsg('switchPage', data => {
      console.log('switchPage', data)
      const path = data?.path
      path && navigate(path, {
        replace: true
      })
    })
  },[])

  return <>
    <StyledLayoutMini>
      <div className="drag"></div>
      <div className="close no-drag" onClick={handleMiniClient}><CloseIcon/></div>
      {renderRoutes}
    </StyledLayoutMini>
    <ProtocolModal/>
  </>
}


const StyledLayoutMini=styled.div`
  min-height: 100vh;
  background: #2E2C2C;
  .drag{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    z-index: 1;
  }
  .close{
    position: fixed;
    z-index: 2;
    width: 16px;
    height: 16px;
    right: 16px;
    top: 16px;
    color: #7b7b7b;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      color: #fff;
    }
  }
`

export default observer(LayoutMini)