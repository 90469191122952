import { observable, action } from 'mobx'
// import { history } from 'src/tools/common'
import { message } from 'antd'

class HistoryStore {
  constructor(current: string) {
    this.current = current
  }
  /** 当前页面是什么地方跳转进来的 */
  @observable
  form: string | null = null

  @observable
  current: string | null = null

  @action
  listen = async () => {
    // history.listen((e) => {
    //   this.form = this.current
    //   this.current = `${e.location.pathname}${e.location.search}`
    //   if (!navigator?.onLine) {
    //     message.warn('当前无网络连接')
    //   }
    // })
  }
}

// export default new HistoryStore(`${history.location.pathname}${history.location.search}`)
export default new HistoryStore(`asdfsdf`)
